import PropTypes from 'prop-types';
import React from 'react';

import Constants from './Constants.js';
import FilterSelectBox from '../record_table/FilterSelectBox.jsx';

const I18n = window.I18n; // i18n-js

/**
 * ポリシーログの結果によるフィルタリングを行うためのセレクトボックスコンポーネント。
 */
export default class ComplianceTypeSelectBox extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {Object} emitter EventEmitter
   */
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    const options = { scope: `${Constants.I18N_SCOPE}.compliance_type_select_box` };
    this.allText = I18n.t('all', options);
    this.compliantText = I18n.t('compliant', options);
    this.nonCompliantText = I18n.t('non_compliant', options);
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <FilterSelectBox
        emitter={this.props.emitter}
        name="compliance_type"
        onChange={this.handleChange}
      >
        <option value={Constants.COMPLIANCE_TYPE.ALL}>
          {this.allText}
        </option>
        <option value={Constants.COMPLIANCE_TYPE.NON_COMPLIANT}>
          {this.nonCompliantText}
        </option>
        <option value={Constants.COMPLIANCE_TYPE.COMPLIANT}>
          {this.compliantText}
        </option>
      </FilterSelectBox>
    );
  }

  /**
   * 選択肢が変化した際の処理を行います。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleChange(newValue) {
    this.props.emitter.emit(Constants.EVENT_CHANGE_COMPLIANCE_TYPE, newValue);
  }
}
