/**
 * TODO: 機能フラグ v27_job_workflow_improvement が不要になったらこのコンポーネントを削除する
 */

import { TriggerJob, CronJob, ScheduleJob, WebhookJob, SQSJob } from "./types";
import { OldCronTriggerDetails } from "./OldCronTriggerDetails";
import { OldHTTPTriggerDetails } from "./OldHTTPTriggerDetails";
import { OldSQSTriggerDetails } from "./OldSQSTriggerDetails";
import { OldScheduleTriggerDetails } from "./OldScheduleTriggerDetails";
import { OldImmediateExecutionTriggerDetails } from "./OldImmediateExecutionTriggerDetails";

type Props = {
  job: TriggerJob | undefined;
};

/**
 * ジョブワークフロー新規作成・編集画面で表示するジョブ詳細用のコンポーネント
 * @public
 */
export const JobWorkflowFirstJobDetail = (props: Props): JSX.Element | null => {
  const triggerJob = props.job;

  if (triggerJob == undefined) {
    return null;
  }

  switch (triggerJob.rule_type) {
    case "cron":
      return OldCronTriggerDetails(triggerJob as CronJob);
    case "webhook":
      return OldHTTPTriggerDetails(triggerJob as WebhookJob);
    case "sqs_v2":
      return OldSQSTriggerDetails(triggerJob as SQSJob);
    case "schedule":
      return OldScheduleTriggerDetails(triggerJob as ScheduleJob);
    case "immediate_execution":
      return OldImmediateExecutionTriggerDetails();
    default:
      return null;
  }
};
