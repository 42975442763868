import React from 'react';

import Constants from './Constants.js';

const I18n = window.I18n; // i18n-js

/**
 * ジョブの指定に関するヒント
 */
export default class StepsHint extends React.Component {
  /**
  * @public
  * @return {ReactElement}
  */
  render() {
    return (
      <section className="ca-callout ca-callout--warning" style={{ marginBottom: '20px' }}>
        <ul style={{ margin: 0, paddingLeft: '2em' }}>
          <li>
            {I18n.t('hint_for_steps.line1', { scope: Constants.I18N_SCOPE })}
          </li>
          <li>
            {I18n.t('hint_for_steps.line2', { scope: Constants.I18N_SCOPE })}
          </li>
        </ul>
      </section>
    );
  }
}
