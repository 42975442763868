import React from "react";

import { ErrorArea } from "./ErrorArea";
import { ErrorArea2 } from "../groups/ErrorArea2";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.google_cloud_accounts.name_input_field";

type Props = {
  value: string;
  disabled: boolean;
  onChange: (newValue: string) => void;
  errors: string[];
  /** エラー表示に使うコンポーネントをErrorAreaではなくErrorArea2にする */
  useError2?: boolean;
};

/**
 * アカウント名フィールド
 *
 * LABELとINPUTが縦に並んだ状態のフォームフィールドです。
 */
const NameInputField: React.FC<Props> = (props) => {
  return (
    <div className="form-group">
      <label className="ca-group__aws-account-form__label" htmlFor="GoogleCloudAccountForm-name">
        {I18n.t("name", { scope: I18N_SCOPE })}
        <span className="ca-group__aws-account-form__required-mark">*</span>
      </label>
      <input
        className="form-control"
        disabled={props.disabled}
        id="GoogleCloudAccountForm-name"
        onChange={(event): void => props.onChange(event.target.value)}
        placeholder={I18n.t("name_placeholder", { scope: I18N_SCOPE })}
        type="text"
        value={props.value}
      />
      {!props.useError2 && <ErrorArea errors={props.errors} />}
      {props.useError2 && <ErrorArea2 errors={props.errors} />}
    </div>
  );
};

export { NameInputField };
