import EventEmitter from "events";
import React from "react";
import PropTypes from "prop-types";

import Constants from "./Constants.js";
import { ProviderIcon } from "../ProviderIcon";
import StepCircle from "./StepCircle.jsx";
import StepsHint from "./StepsHint.jsx";
import { TriggerCircle } from "./TriggerCircle";
import { WorkflowTriggerJobDetail } from "./WorkflowTriggerJobDetail";

const I18n = window.I18n; // i18n-js

/**
 * ジョブワークフロー編集フォームコンテナ
 */
export default class JobWorkflowEditFormContainer extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {object} group ジョブワークフローのグループ
   * @property {object} jobWorkflow 編集対象のジョブワークフロー
   * @property {string} jobWorkflowPath ジョブワークフロー作成リクエストの送信先パス
   * @property {string, null} selectedWorkflowTriggerJobId
   *   ワークフロートリガージョブとして現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId1
   *   後続ジョブ1として現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId2
   *   後続ジョブ2として現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId3
   *   後続ジョブ3として現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId4
   *   後続ジョブ4として現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId5
   *   後続ジョブ5として現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId6
   *   後続ジョブ6として現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId7
   *   後続ジョブ7として現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId8
   *   後続ジョブ8として現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId9
   *   後続ジョブ9として現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId10
   *  後続ジョブ10として現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId11
   * 後続ジョブ11として現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId12
   * 後続ジョブ12として現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId13
   * 後続ジョブ13として現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId14
   * 後続ジョブ14として現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId15
   * 後続ジョブ15として現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId16
   * 後続ジョブ16として現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId17
   * 後続ジョブ17として現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId18
   * 後続ジョブ18として現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId19
   * 後続ジョブ19として現在設定されているジョブID
   * @property {string, null} selectedFollowingJobId20
   * 後続ジョブ20として現在設定されているジョブID
   * @property {Object} triggerJobsForWorkflowTriggerJobs ワークフロートリガージョブの選択肢となるジョブ群のオブジェクト
   * @property {Object} triggerJobsForFollowingJobs 後続ジョブの選択肢となるジョブ群のオブジェクト
   */
  static get propTypes() {
    return {
      group: PropTypes.object.isRequired,
      jobWorkflow: PropTypes.object,
      jobWorkflowPath: PropTypes.string.isRequired,
      selectedWorkflowTriggerJobId: PropTypes.string,
      selectedFollowingJobId1: PropTypes.string,
      selectedFollowingJobId2: PropTypes.string,
      selectedFollowingJobId3: PropTypes.string,
      selectedFollowingJobId4: PropTypes.string,
      selectedFollowingJobId5: PropTypes.string,
      selectedFollowingJobId6: PropTypes.string,
      selectedFollowingJobId7: PropTypes.string,
      selectedFollowingJobId8: PropTypes.string,
      selectedFollowingJobId9: PropTypes.string,
      selectedFollowingJobId10: PropTypes.string,
      selectedFollowingJobId11: PropTypes.string,
      selectedFollowingJobId12: PropTypes.string,
      selectedFollowingJobId13: PropTypes.string,
      selectedFollowingJobId14: PropTypes.string,
      selectedFollowingJobId15: PropTypes.string,
      selectedFollowingJobId16: PropTypes.string,
      selectedFollowingJobId17: PropTypes.string,
      selectedFollowingJobId18: PropTypes.string,
      selectedFollowingJobId19: PropTypes.string,
      selectedFollowingJobId20: PropTypes.string,
      triggerJobsForWorkflowTriggerJobs: PropTypes.object,
      triggerJobsForFollowingJobs: PropTypes.object,
    };
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    const workflowTriggerJob = props.triggerJobsForWorkflowTriggerJobs[props.selectedWorkflowTriggerJobId];
    let isExpiredWorkflowTriggerJob = false;

    if (workflowTriggerJob && workflowTriggerJob.rule_type == "schedule") {
      isExpiredWorkflowTriggerJob = workflowTriggerJob.expired_schedule_job;
    }

    this.state = {
      active: props.jobWorkflow.active ? "1" : "0",
      isExpiredWorkflowTriggerJob: isExpiredWorkflowTriggerJob, // ワークフロートリガージョブがExpireしているスケジュールトリガーかどうか
      isLoadingJobs: false, // グループに属するジョブ情報の取得中かどうか
      isSubmitting: false, // フォームを送信中かどうか
      isSubmitted: false, // フォーム送信を完了したかどうか
      jobWorkflowStateVisibilityValue: workflowTriggerJob.rule_type == "immediate_execution" ? "hidden" : "visible",
      name: props.jobWorkflow.name, // ジョブワークフロー名
      submitErrorMessage: "", // フォーム送信時のエラーメッセージ
      workflowTriggerJobId: props.selectedWorkflowTriggerJobId,
      followingJobId1: props.selectedFollowingJobId1,
      followingJobId2: props.selectedFollowingJobId2,
      followingJobId3: props.selectedFollowingJobId3,
      followingJobId4: props.selectedFollowingJobId4,
      followingJobId5: props.selectedFollowingJobId5,
      followingJobId6: props.selectedFollowingJobId6,
      followingJobId7: props.selectedFollowingJobId7,
      followingJobId8: props.selectedFollowingJobId8,
      followingJobId9: props.selectedFollowingJobId9,
      followingJobId10: props.selectedFollowingJobId10,
      followingJobId11: props.selectedFollowingJobId11,
      followingJobId12: props.selectedFollowingJobId12,
      followingJobId13: props.selectedFollowingJobId13,
      followingJobId14: props.selectedFollowingJobId14,
      followingJobId15: props.selectedFollowingJobId15,
      followingJobId16: props.selectedFollowingJobId16,
      followingJobId17: props.selectedFollowingJobId17,
      followingJobId18: props.selectedFollowingJobId18,
      followingJobId19: props.selectedFollowingJobId19,
      followingJobId20: props.selectedFollowingJobId20,
    };

    this.handleChangeActive = this.handleChangeActive.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeTriggerJob = this.handleChangeTriggerJob.bind(this);
    this.handleClickDelete = this.handleClickDelete.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);

    this.initEventEmitter();
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    let formClasses = "ca-job-workflow__form ca-job-workflow__form--edit";

    if (this.state.isExpiredWorkflowTriggerJob) {
      formClasses += " ca-job-workflow__form--expired-schedule";
    }

    return (
      <React.Fragment>
        <form className={formClasses} onSubmit={this.handleSubmitForm}>
          <section className="ca-job-workflow__form-fieldset-wrapper">
            <fieldset className="ca-job-workflow__form-fieldset">
              <h2 className="ca-job-workflow__form-fieldset-heading">
                <i aria-hidden className="fa fa-pencil-square-o marginR5"></i>
                {I18n.t("basic_settings", { scope: Constants.I18N_SCOPE })}
              </h2>
              <div className="ca-job-workflow__form-fields">
                <div
                  className="
                  form-group
                  ca-job-workflow__form-field
                "
                >
                  <label
                    htmlFor="ca-job-workflow-name-field"
                    className="
                      ca-job-workflow__form-label
                      ca-job-workflow__form-label--required
                    "
                  >
                    {I18n.t("name", { scope: Constants.I18N_SCOPE })}:
                  </label>
                  <div className="ca-job-workflow__form-control">
                    <input
                      className="form-control"
                      id="ca-job-workflow-name-field"
                      onChange={this.handleChangeName}
                      type="text"
                      value={this.state.name}
                    />
                  </div>
                </div>
                <div
                  className="
                  form-group
                  ca-job-workflow__form-field
                "
                >
                  <label htmlFor="ca-job-workflow-group-id-field" className="ca-job-workflow__form-label">
                    {I18n.t("group", { scope: Constants.I18N_SCOPE })}
                  </label>
                  <div className="ca-job-workflow__form-control">{this.props.group.name}</div>
                </div>
                <div
                  className="form-group ca-job-workflow__form-field"
                  style={{ visibility: this.state.jobWorkflowStateVisibilityValue }}
                >
                  <label
                    className="
                      ca-job-workflow__form-label
                      ca-job-workflow__form-label--required
                    "
                  >
                    {I18n.t("state", { scope: Constants.I18N_SCOPE })}
                  </label>
                  <div className="ca-job-workflow__form-control">
                    <label>
                      <input
                        type="radio"
                        name="state"
                        value="1"
                        checked={this.state.active == "1"}
                        className="marginR5"
                        onChange={() => this.setState({ active: "1" })}
                      />
                      {I18n.t("active", { scope: Constants.I18N_SCOPE })}
                    </label>
                    <label className="marginL20">
                      <input
                        type="radio"
                        name="state"
                        value="0"
                        checked={this.state.active == "0"}
                        className="marginR5"
                        onChange={() => this.setState({ active: "0" })}
                      />
                      {I18n.t("inactive", { scope: Constants.I18N_SCOPE })}
                    </label>
                  </div>
                </div>
              </div>
            </fieldset>
          </section>

          <section className="ca-job-workflow__form-fieldset-wrapper">
            <fieldset className="ca-job-workflow__form-fieldset">
              <h2 className="ca-job-workflow__form-fieldset-heading">
                <i aria-hidden className="fa fa-dashboard marginR5"></i>
                {I18n.t("job_settings", { scope: Constants.I18N_SCOPE })}
              </h2>
              <div className="ca-job-workflow__form-notice">
                <StepsHint />
              </div>
              {this.renderJobWorkflowTriggerJob()}
              <div className="ca-job-workflow__form-fields">
                {this.renderWorkflowTriggerJobStep()}
                {this.renderFollowingStep(1)}
                {this.renderFollowingStep(2)}
                {this.renderFollowingStep(3)}
                {this.renderFollowingStep(4)}
                {this.renderFollowingStep(5)}
                {this.renderFollowingStep(6)}
                {this.renderFollowingStep(7)}
                {this.renderFollowingStep(8)}
                {this.renderFollowingStep(9)}
                {this.renderFollowingStep(10)}
                {this.renderFollowingStep(11)}
                {this.renderFollowingStep(12)}
                {this.renderFollowingStep(13)}
                {this.renderFollowingStep(14)}
                {this.renderFollowingStep(15)}
                {this.renderFollowingStep(16)}
                {this.renderFollowingStep(17)}
                {this.renderFollowingStep(18)}
                {this.renderFollowingStep(19)}
                {this.renderFollowingStep(20)}
              </div>
            </fieldset>
          </section>

          <section className="ca-job-workflow__form-fieldset-wrapper">
            <fieldset className="ca-job-workflow__form-fieldset">
              {this.state.submitErrorMessage && (
                <p className="ca-job-workflow__form-error-message">{this.state.submitErrorMessage}</p>
              )}

              <div className="ca-btn-block ca-btn-block--no-border">
                <div className="ca-btn-block__primary">
                  <button className="btn btn-primary btn-lg" disabled={!this.isFormFilled()} type="submit">
                    <i className="fa fa-check-circle fa-lg"></i>{" "}
                    {I18n.t(this.state.isSubmitting ? "submitting" : "update", { scope: Constants.I18N_SCOPE })}
                  </button>
                </div>
                <div className="ca-btn-block__secondary">
                  <a className="ca-btn-block__link text-danger" href="#" onClick={(e) => this.handleClickDelete(e)}>
                    <span className="fa fa-trash"></span>
                    {I18n.t("button_to_delete", { scope: Constants.I18N_SCOPE })}
                  </a>
                </div>
              </div>
            </fieldset>
          </section>
        </form>
      </React.Fragment>
    );
  }

  /**
   * ワークフロートリガージョブの詳細を返します。
   *
   * @private
   * @return {ReactElement}
   */
  renderJobWorkflowTriggerJob() {
    const job =
      this.state.workflowTriggerJobId !== ""
        ? this.props.triggerJobsForWorkflowTriggerJobs[this.state.workflowTriggerJobId]
        : undefined;
    return <WorkflowTriggerJobDetail job={job} />;
  }

  /**
   * ワークフロートリガージョブのセレクトボックスを返します。
   *
   * @private
   * @return {ReactElement}
   */
  renderWorkflowTriggerJobStep() {
    const oldFirstJobExists = Object.values(this.props.triggerJobsForWorkflowTriggerJobs).some(
      (job) => job.action_type !== "no_action"
    );

    return (
      <div className="ca-job-workflow__form-trigger">
        <label className="ca-job-workflow__form-trigger__label">
          {I18n.t("specify_workflow_trigger_job", { scope: Constants.I18N_SCOPE })}
        </label>
        <div className="ca-job-workflow__form-field" data-qa="step-trigger">
          <div className="ca-job-workflow__form-step-row">
            <React.Fragment>
              <TriggerCircle job={this.props.triggerJobsForWorkflowTriggerJobs[this.state.workflowTriggerJobId]} />
            </React.Fragment>
            <select
              className="form-control ca-job-workflow__form-control ca-job-workflow__form-step-select"
              onChange={(e) => this.handleChangeTriggerJob(0, e.target.value)}
              value={this.state.workflowTriggerJobId}
            >
              <option value="">{I18n.t("blank_option", { scope: Constants.I18N_SCOPE })}</option>
              {Object.keys(this.props.triggerJobsForWorkflowTriggerJobs).map((id) => {
                const job = this.props.triggerJobsForWorkflowTriggerJobs[id];
                return (
                  <option key={job.id} value={job.id} disabled={job.action_type !== "no_action"}>
                    {job.name}
                  </option>
                );
              })}
            </select>

            <div>
              <a
                href="#"
                onClick={(e) => {
                  this.handleChangeTriggerJob(0, "");
                  e.preventDefault();
                }}
              >
                <i className="fa fa-trash-o ca-job-workflow__form-step-trash-icon"></i>
              </a>
            </div>

            {this.state.workflowTriggerJobId && (
              <React.Fragment>
                <div className="ca-job-workflow__form-step-action-type">
                  <ProviderIcon
                    identifier={
                      this.props.triggerJobsForWorkflowTriggerJobs[this.state.workflowTriggerJobId].provider_identifier
                    }
                  />
                  {this.props.triggerJobsForWorkflowTriggerJobs[this.state.workflowTriggerJobId].action_type_i18n}
                </div>

                <div className="ca-job-workflow__form-step-link">
                  <a
                    className="marginL10"
                    href={this.props.triggerJobsForWorkflowTriggerJobs[this.state.workflowTriggerJobId].show_url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i aria-hidden className="fa fa-external-link"></i>{" "}
                    {I18n.t("link_to_job", { scope: Constants.I18N_SCOPE })}
                  </a>
                </div>
              </React.Fragment>
            )}
            {/* ジョブワークフローが所属するグループ内に旧先頭ジョブが1件も存在しなくなったら「旧先頭ジョブは選択できません」を表示しない */}
            {/* TODO: 一括マイグレーションが完了し、機能フラグ v27_job_workflow_improvement が不要になったら削除する */}
            {oldFirstJobExists && (
              <div className="ca-job-workflow__form-step-msg">
                <i className="fa-solid fa-circle-exclamation marginR5"></i>
                <span>{I18n.t("old_first_job_not_selectable", { scope: Constants.I18N_SCOPE })}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  /**
   * 後続ジョブのセレクトボックスを返します。
   *
   * @private
   * @param {number} step 後続ジョブのステップ番号 (1〜20)
   * @return {ReactElement} 指定されたステップに対応する後続ジョブのセレクトボックスのReact要素を返します。
   */
  renderFollowingStep(step) {
    const currentValue = this.state[`followingJobId${step}`];
    window.console.log(currentValue);
    let rowClasses = "ca-job-workflow__form-step-row";
    if (step == 20) {
      rowClasses = `${rowClasses} ca-job-workflow__form-step-row--last`;
    }

    return (
      <div className="ca-job-workflow__form-field" data-qa={`step-${step}`}>
        <div className={rowClasses}>
          <StepCircle active={currentValue != ""} step={step} />

          <select
            className="form-control ca-job-workflow__form-control ca-job-workflow__form-step-select"
            value={currentValue}
            onChange={(e) => this.handleChangeTriggerJob(step, e.target.value)}
          >
            <option value="">{I18n.t("blank_option", { scope: Constants.I18N_SCOPE })}</option>

            {currentValue && (
              <option key={currentValue} value={currentValue}>
                {this.props.triggerJobsForFollowingJobs[currentValue].name}
              </option>
            )}

            {this.availableFollowingJobIds().map((id) => {
              const job = this.props.triggerJobsForFollowingJobs[id];
              return (
                <option key={job.id} value={job.id}>
                  {job.name}
                </option>
              );
            })}
          </select>

          <div>
            <a
              href="#"
              onClick={(e) => {
                this.handleChangeTriggerJob(step, "");
                e.preventDefault();
              }}
            >
              <i className="fa fa-trash-o ca-job-workflow__form-step-trash-icon"></i>
            </a>
          </div>

          {currentValue && (
            <React.Fragment>
              <div className="ca-job-workflow__form-step-action-type">
                <ProviderIcon identifier={this.props.triggerJobsForFollowingJobs[currentValue].provider_identifier} />
                {this.props.triggerJobsForFollowingJobs[currentValue].action_type_i18n}
              </div>
              <div className="ca-job-workflow__form-step-link">
                <a
                  className="marginL10"
                  href={this.props.triggerJobsForFollowingJobs[currentValue].show_url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i aria-hidden className="fa fa-external-link"></i>{" "}
                  {I18n.t("link_to_job", { scope: Constants.I18N_SCOPE })}
                </a>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  /**
   * フォームで選択可能な後続ジョブIDのリストを返します。
   *
   * フォームですでに選択されている後続ジョブIDを除いた
   * 選択可能なジョブIDの配列を返します。
   *
   * @private
   * @return {string[]} 選択可能な後続ジョブIDの配列を返します。
   */
  availableFollowingJobIds() {
    const ids = Object.keys(this.props.triggerJobsForFollowingJobs);
    return ids.filter((id) => {
      return (
        id != this.state.followingJobId1 &&
        id != this.state.followingJobId2 &&
        id != this.state.followingJobId3 &&
        id != this.state.followingJobId4 &&
        id != this.state.followingJobId5 &&
        id != this.state.followingJobId6 &&
        id != this.state.followingJobId7 &&
        id != this.state.followingJobId8 &&
        id != this.state.followingJobId9 &&
        id != this.state.followingJobId10 &&
        id != this.state.followingJobId11 &&
        id != this.state.followingJobId12 &&
        id != this.state.followingJobId13 &&
        id != this.state.followingJobId14 &&
        id != this.state.followingJobId15 &&
        id != this.state.followingJobId16 &&
        id != this.state.followingJobId17 &&
        id != this.state.followingJobId18 &&
        id != this.state.followingJobId19 &&
        id != this.state.followingJobId20
      );
    });
  }

  /**
   * ワークフローの状態の現在値を変更します。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleChangeActive(event) {
    this.setState({ active: event.target.value });
  }

  /**
   * ジョブワークフロー名の現在値を変更します。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }

  /**
   * 指定されたステップのジョブを変更します。
   *
   * 指定されたのがワークフロートリガージョブかつ、予定日時に未来の日時が無い
   * スケジュールトリガーだった場合は編集フォームに警告を表示します。
   *
   * @private
   * @param {string} step ステップ(1〜10)
   * @param {string} triggerJobId 変更後のジョブID
   */
  handleChangeTriggerJob(step, triggerJobId) {
    const key = step === 0 ? "workflowTriggerJobId" : `followingJobId${step}`;
    this.setState({ [key]: triggerJobId });

    if (step !== 0) {
      return;
    }

    const job = this.props.triggerJobsForWorkflowTriggerJobs[triggerJobId];

    let active = this.state.active;
    let isExpiredWorkflowTriggerJob = false;
    let jobWorkflowStateVisibilityValue = "visible";

    if (job && job.rule_type == "schedule") {
      isExpiredWorkflowTriggerJob = job.expired_schedule_job;
    }

    if (job && job.rule_type == "immediate_execution") {
      // ジョブワークフローの状態はワークフロートリガージョブと連動するため、
      // 編集中の (実行中ではない) ワークフロートリガージョブが手動トリガーの場合は状態が常に「停止」になる。
      active = "0";
      // ワークフロートリガージョブが手動トリガーの場合は、ユーザーが状態を認識する必要がないため、状態を表示しない
      jobWorkflowStateVisibilityValue = "hidden";
    }

    this.setState({
      active: active,
      isExpiredWorkflowTriggerJob: isExpiredWorkflowTriggerJob,
      jobWorkflowStateVisibilityValue: jobWorkflowStateVisibilityValue,
    });
  }

  /**
   * 削除処理を行います。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleClickDelete(event) {
    event.preventDefault();

    const result = window.confirm(I18n.t("confirm_delete", { scope: Constants.I18N_SCOPE }));
    if (result == false) {
      return;
    }

    window.jQuery
      .ajax({
        method: "DELETE",
        dataType: "json",
        url: this.props.jobWorkflowPath,
        beforeSend: () => {
          this.setState({
            isSubmitting: true,
            isSubmitted: false,
          });
        },
      })
      .done((data) => {
        this.setState(
          {
            // isSubmitting: false,
            isSubmitted: true,
          },
          () => {
            window.location.href = data.url;
          }
        );
      })
      .fail((data) => {
        const error = I18n.t("delete_failed", { scope: Constants.I18N_SCOPE });
        const json = data.responseJSON;
        const message = `${error}: ${json.error}`;
        this.setState({ isSubmitting: false });
        alert(message);
      });
  }

  /**
   * フォームを送信します。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleSubmitForm(event) {
    event.preventDefault();
    window.jQuery
      .ajax({
        method: "PATCH",
        data: {
          name: this.state.name,
          active: this.state.active,
          first_job_id: this.state.workflowTriggerJobId,
          following_job_ids: [
            this.state.followingJobId1,
            this.state.followingJobId2,
            this.state.followingJobId3,
            this.state.followingJobId4,
            this.state.followingJobId5,
            this.state.followingJobId6,
            this.state.followingJobId7,
            this.state.followingJobId8,
            this.state.followingJobId9,
            this.state.followingJobId10,
            this.state.followingJobId11,
            this.state.followingJobId12,
            this.state.followingJobId13,
            this.state.followingJobId14,
            this.state.followingJobId15,
            this.state.followingJobId16,
            this.state.followingJobId17,
            this.state.followingJobId18,
            this.state.followingJobId19,
            this.state.followingJobId20,
          ],
        },
        dataType: "json",
        url: this.props.jobWorkflowPath,
        beforeSend: () => {
          this.setState({
            isSubmitting: true,
            isSubmitted: false,
          });
        },
      })
      .done((data) => {
        this.setState(
          {
            isSubmitted: true,
          },
          () => {
            window.location.href = data.url;
          }
        );
      })
      .fail((data) => {
        const error = I18n.t("update_failed", { scope: Constants.I18N_SCOPE });
        const json = data.responseJSON;
        const message = `${error} ${json.error}`;
        this.setState({ isSubmitting: false, submitErrorMessage: message });
      });
  }

  /**
   * フォームが更新可能な状態かどうかを返します。
   *
   * @private
   * @return {boolean}
   */
  isFormFilled() {
    return (
      this.state.name != "" &&
      this.state.active != "" &&
      this.state.workflowTriggerJobId != "" &&
      this.state.followingJobId1 != ""
    );
  }

  /**
   * EventEmitterを初期化します。
   *
   * 各コンポーネント内で発生するイベントとイベントハンドラ関数の割り当てを行います。
   * 生成されたEventEmitterオブジェクトは this.emitter にアサインされます。
   *
   * @private
   */
  initEventEmitter() {
    this.emitter = new EventEmitter();
    this.emitter.on(Constants.EVENT_CHANGE_ACTIVE, this.handleChangeActive);
    this.emitter.on(Constants.EVENT_CHANGE_NAME, this.handleChangeName);
    this.emitter.on(Constants.EVENT_CHANGE_TRIGGER_JOB, this.handleChangeTriggerJob);
    this.emitter.on(Constants.EVENT_SUBMIT_FORM, this.handleSubmitForm);
  }
}
