import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";

import { AccessKeyIdInputField } from "../../aws_account/AccessKeyIdInputField";
import { SecretAccessKeyInputField } from "../../aws_account/SecretAccessKeyInputField";
import { AwsAccountNameInputField } from "../../aws_account/AwsAccountNameInputField";
import { EbsBackupCheckInputField } from "../../aws_account/EbsBackupCheckInputField";
import { ErrorArea } from "../../aws_account/ErrorArea";
import ManualLink from "../../ManualLink";
import { SubmitButton } from "../../aws_account/SubmitButton";
import { showSuccessToast } from "../../toast";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.group_aws_account.iam_user_aws_account_dialog";

export const NewAwsAccountWithIamUserButton: React.FC<{
  group: {
    id: number;
  };
  onCreated: () => void;
}> = (props) => {
  useEffect((): void => {
    ReactModal.setAppElement("body");
  }, []);

  const defaultErrors = {
    accessKeyId: [],
    secretAccessKey: [],
    awsAccountName: [],
    base: [],
    ebsBackupCheckEnabled: [],
    groupId: [],
  };

  const [accessKeyId, setAccessKeyId] = useState("");
  const [secretAccessKey, setSecretAccessKey] = useState("");
  const [awsAccountName, setAwsAccountName] = useState("");
  const [ebsBackupCheckEnabled, setEbsBackupCheckEnabled] = useState(true);
  const [errors, setErrors] = useState(defaultErrors);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [isTransmitting, setIsTransmitting] = useState(false);

  const closeDialog = (): void => {
    setAccessKeyId("");
    setSecretAccessKey("");
    setAwsAccountName("");
    setEbsBackupCheckEnabled(true);
    setErrors(defaultErrors);
    setDialogVisible(false);
    setIsTransmitting(false);
  };

  const isFormInputFilled = (): void => {
    return accessKeyId !== "" || secretAccessKey !== "" || awsAccountName !== "";
  };

  const confirmAndClose = (): void => {
    if (isFormInputFilled()) {
      if (!window.confirm(I18n.t("discard_alert", { scope: I18N_SCOPE }))) {
        return;
      }
    }

    closeDialog();
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const params = {
      // Railsに渡すパラメーターのケースはLintで指摘されても直せないので一時的に無効化します。
      account_type: "iam_user",
      suppress_flash: true,
      aws_account: {
        access_key_id: accessKeyId,
        ebs_backup_check_enabled: ebsBackupCheckEnabled,
        name: awsAccountName,
        secret_access_key: secretAccessKey,
      },
    };

    jQuery
      .ajax({
        beforeSend: () => {
          setErrors(defaultErrors);
          setIsTransmitting(true);
        },
        url: `/groups/${props.group.id}/aws_accounts`,
        method: "POST",
        dataType: "json",
        data: params,
      })
      .done(() => {
        props.onCreated();
        closeDialog();
        showSuccessToast(I18n.t("succeeded", { scope: I18N_SCOPE }));
      })
      .fail((data) => {
        let errorResponse = data.responseJSON?.errors;
        if (!errorResponse) {
          errorResponse = { base: [I18n.t("error", { message: data.statusText, scope: I18N_SCOPE })] };
        }

        setErrors({
          ...errors,
          accessKeyId: errorResponse.access_key_id || [],
          secretAccessKey: errorResponse.secret_access_key || [],
          awsAccountName: errorResponse.name || [],
          base: errorResponse.base || [],
          ebsBackupCheckEnabled: errorResponse.ebs_backup_check_enabled || [],
          groupId: errorResponse.group || [],
        });
      })
      .always(() => {
        setIsTransmitting(false);
      });
  };

  const handleChangeEbsBackupCheckEnabled = (ebsBackupCheckEnabled: string): boolean => {
    setEbsBackupCheckEnabled(ebsBackupCheckEnabled === "true");
  };

  const isSubmittable = (): boolean => {
    return accessKeyId !== "" && secretAccessKey !== "" && awsAccountName !== "";
  };

  return (
    <div className="inline-block">
      <button
        type="button"
        className="ca-link-button marginR20"
        onClick={(): void => {
          setDialogVisible(true);
        }}
      >
        <span className="font-12px">{I18n.t("add_as_iam_user", { scope: I18N_SCOPE })}</span>
      </button>

      <ReactModal
        isOpen={dialogVisible}
        onRequestClose={confirmAndClose}
        overlayClassName="ca-group-aws-account-modal__overlay"
        className="ca-group-aws-account-modal__content"
        role="dialog"
      >
        <div className="text-left">
          <h2>
            <span className="fa fa-key" />
            &nbsp;{I18n.t("heading", { scope: I18N_SCOPE })}
          </h2>
          <div className="marginB10 ca-callout ca-callout--warning">
            <p>{I18n.t("description", { scope: I18N_SCOPE })}</p>
            <ManualLink
              linkText={I18n.t("common.manual.text.aws_account.iam_user")}
              url={I18n.t("common.manual.url.aws_account.iam_user")}
            />
          </div>
        </div>
        <form onSubmit={onSubmit}>
          <div className="ca-group-aws-accounts-new-iam-role-form__fields">
            <div className="ca-group-aws-accounts-new-iam-role-form__fields-row">
              <div className="ca-group-aws-accounts-new-iam-role-form__fields-left-col">
                <div className="ca-group-aws-accounts-new-iam-role-form__field-heading">
                  {I18n.t("step1", { scope: I18N_SCOPE })}
                </div>
              </div>
              <div className="ca-group-aws-accounts-new-iam-role-form__fields-right-col">
                <p className="ca-group-aws-accounts-new-iam-role-form__field-prompt">
                  {I18n.t("prompt", { scope: I18N_SCOPE })}
                </p>
                <AccessKeyIdInputField
                  accessKeyId={accessKeyId}
                  disabled={false}
                  onChange={setAccessKeyId}
                  errors={errors.accessKeyId}
                />
                <SecretAccessKeyInputField
                  secretAccessKey={secretAccessKey}
                  disabled={false}
                  onChange={setSecretAccessKey}
                  errors={errors.secretAccessKey}
                />
                <AwsAccountNameInputField
                  awsAccountName={awsAccountName}
                  disabled={false}
                  onChange={setAwsAccountName}
                  errors={errors.awsAccountName}
                />
                <EbsBackupCheckInputField
                  ebsBackupCheckEnabled={ebsBackupCheckEnabled}
                  disabled={false}
                  onChange={handleChangeEbsBackupCheckEnabled}
                  errors={errors.ebsBackupCheckEnabled}
                />
              </div>
            </div>
          </div>
          <div className="ca-btn-block__center marginT20">
            <SubmitButton
              disabled={!isSubmittable()}
              label={I18n.t("submit", { scope: I18N_SCOPE })}
              pending={isTransmitting}
              pendingLabel={I18n.t("transmitting", { scope: I18N_SCOPE })}
            />
            <ErrorArea errors={errors.base} />
            <ErrorArea errors={errors.groupId} />
          </div>
        </form>
      </ReactModal>
    </div>
  );
};
