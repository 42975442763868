import React from "react";
import PropTypes from "prop-types";

import Constants from "./Constants.js";
import ErrorMessages from "./ErrorMessages.jsx";
import { GroupSelector2 } from "../GroupSelector2.tsx";

const I18n = window.I18n; // i18n-js

/**
 * トリガージョブフォームでグループ選択をするためのコンポーネントで、以下の責務を持ちます。
 *
 * - グループ選択用UIの表示
 * - グループを選択/変更した際に、フォーム内の
 *   AWS アカウント ( SELECT 要素 ) を選択/変更したグループに紐付くものに更新
 * - グループを選択/変更した際に、フォーム内の
 *   複数AWSアカウント ( SELECT 要素 ) を選択/変更したグループに紐付くものに更新
 * - グループを選択/変更した際に、フォーム内の
 *   Google Cloudアカウント ( SELECT 要素 ) を選択/変更したグループに紐付くものに更新
 *
 */
export default class TriggerJobFormGroupSelector extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {string} awsAccountSelector グループを変更した際に更新する AWS アカウントの SELECT 要素
   * @property {string} awsAccountsSelector グループを変更した際に更新する複数AWSアカウントの SELECT 要素
   * @property {bool} disabled グループのセレクトボックスを無効化するかどうか
   * @property {string} googleCloudAccountSelector グループを変更した際に更新するGoogle Cloudアカウントの SELECT 要素
   * @property {Object[]} groups グループの配列
   * @property {string} noAwsAccountAlertIdValue 「AWSアカウントの登録が必要です」アラートのID属性値
   * @property {string} noAwsAccountsAlertIdValue 複数AWSアカウント用の「AWSアカウントの登録が必要です」アラートのID属性値
   * @property {string} noGoogleCloudAccountAlertIdValue 「Google Cloudアカウントの登録が必要です」アラートのID属性値
   * @property {number} selectedGroupIds 選択状態とするグループのID
   * @property {string} sqsAccountSelector グループを変更した際に更新する SQS AWS アカウントの SELECT 要素
   */
  static get propTypes() {
    return {
      awsAccountSelector: PropTypes.string.isRequired,
      awsAccountsSelector: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      googleCloudAccountSelector: PropTypes.string.isRequired,
      groups: PropTypes.arrayOf(PropTypes.object).isRequired,
      noAwsAccountAlertIdValue: PropTypes.string.isRequired,
      noAwsAccountsAlertIdValue: PropTypes.string.isRequired,
      noGoogleCloudAccountAlertIdValue: PropTypes.string.isRequired,
      selectedGroupId: PropTypes.number,
      sqsAwsAccountSelector: PropTypes.string.isRequired,
    };
  }

  /**
   * @return {Object}
   */
  static get defaultProps() {
    return {
      disabled: false,
    };
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);

    this.awsAccountSelectors = [`#${this.props.awsAccountSelector}`, `#${this.props.sqsAwsAccountSelector}`];
    this.awsAccountsSelectors = [`#${this.props.awsAccountsSelector}`];
    this.googleCloudAccountSelectors = [`#${this.props.googleCloudAccountSelector}`];
    this.i18n_text = {
      no_assignment_group: I18n.t("no_assignment_group", { scope: Constants.I18N_SCOPE }),
    };
  }

  /**
   * コンポーネントがマウントされた際の処理を行います。
   * @public
   */
  componentDidMount() {
    this.groupIdHiddenInput = window.jQuery(`#${Constants.GROUP_INPUT_TAG_ID}`);

    if (this.props.selectedGroupId) {
      this.refreshGroupIdHiddenInput(this.props.selectedGroupId);
    }
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return (
      <React.StrictMode>
        <div className="ca-trigger-job-form">
          <GroupSelector2
            disabled={this.props.disabled}
            onChange={this.handleOnChange}
            options={this.props.groups}
            selectedGroupId={this.props.selectedGroupId}
          />
          <input type="hidden" id={Constants.GROUP_INPUT_TAG_ID} name={Constants.GROUP_INPUT_TAG_NAME} />
          <ErrorMessages messages={this.errorMessages()} />
        </div>
      </React.StrictMode>
    );
  }

  /**
   * エラーメッセージを返します。
   *
   * @private
   * @return {string[]}
   */
  errorMessages() {
    return this.props.groups.length === 0 ? [this.i18n_text.no_assignment_group] : [];
  }

  /**
   * グループ選択のセレクトボックスが変更された際に呼び出されるイベントハンドラ。
   *
   * @private
   * @param {Object[]} groups 現在選択されているグループ
   */
  handleOnChange(groups) {
    // グループが何も選択されていない状態の場合は何もしない
    if (groups.length === 0) return null;

    const groupId = groups[0].id;

    // フォーム内のAWSアカウント(SELECT要素)を更新する
    this.refreshAwsAccountSelector(groupId);
    this.refreshAwsAccountsSelector(groupId);
    this.refreshGoogleCloudAccountSelector(groupId);
    this.refreshGroupIdHiddenInput(groupId);

    // グループIDに紐づくIAMロールの更新リンクを更新する
    const iamRoleUpdateLinks = [$("#js-iam-role-update-link"), $("#js-aws-accounts-iam-role-update-link")];
    iamRoleUpdateLinks.forEach(($iamRoleUpdateLink) => {
      $iamRoleUpdateLink.attr("href", `/groups/${groupId}/aws_accounts`);
    });
  }

  /**
   * ページ内にある「AWSアカウントの登録が必要です」アラートを隠します。
   * @private
   */
  hideNoAwsAccountAlert() {
    window.jQuery(`#${this.props.noAwsAccountAlertIdValue}`).hide();
  }

  /**
   * ページ内にある複数AWSアカウント用の「AWSアカウントの登録が必要です」アラートを隠します。
   * @private
   */
  hideNoAwsAccountsAlert() {
    window.jQuery(`#${this.props.noAwsAccountsAlertIdValue}`).hide();
  }

  /**
   * ページ内にある「Google Cloudアカウントの登録が必要です」アラートを隠します。
   * @private
   */
  hideNoGoogleCloudAccountAlert() {
    window.jQuery(`#${this.props.noGoogleCloudAccountAlertIdValue}`).hide();
  }

  /**
   * グループ選択のセレクトボックスが変更された際に
   * フォーム内のAWSアカウント(SELECT要素)を更新します。
   *
   * @private
   * @param {string} groupId 対象のグループ ID
   */
  refreshAwsAccountSelector(groupId) {
    const errorText = I18n.t("no_aws_account", { scope: Constants.I18N_SCOPE });
    const errorOption = `<option value=''>${errorText}</option>`;
    let options = [];

    window.jQuery
      .ajax({
        beforeSend: () => {
          this.hideNoAwsAccountAlert();
        },
        data: { group_id: groupId },
        dataType: "json",
        url: "/aws_accounts",
      })
      .done((data) => {
        if (data.aws_accounts.length === 0) {
          this.showNoAwsAccountAlert();
          return options.push(errorOption);
        }

        data.aws_accounts.forEach((awsAccount) => {
          if (Object.hasOwn(awsAccount, "is_latest_permission")) {
            options.push(
              `<option value='${awsAccount.id}' data-is-latest-permission='${awsAccount.is_latest_permission}'>${awsAccount.name}</option>`
            );
          } else {
            options.push(`<option value='${awsAccount.id}'>${awsAccount.name}</option>`);
          }
        });
      })
      .fail(() => {
        options.push(errorOption);
      })
      .always(() => {
        this.refreshAwsAccountSelectorOptions(options);
      });
  }

  /**
   * フォーム内のAWSアカウント(SELECT要素)を更新します。
   *
   * @private
   * @param {string[]} AWSアカウントの配列
   */
  refreshAwsAccountSelectorOptions(options) {
    this.awsAccountSelectors.forEach((tag) => {
      window.jQuery(tag).html(options).trigger("change");
    });
  }

  /**
   * フォーム内の複数AWSアカウントセレクトボックスの選択肢を、対象のグループに紐づくものに更新します。
   * 選択肢は「#111122223333: テストAWSアカウント」のような書式となります。
   *
   * @private
   * @param {string} groupId 対象のグループ ID
   */
  refreshAwsAccountsSelector(groupId) {
    const errorText = I18n.t("no_aws_account", { scope: Constants.I18N_SCOPE });
    const errorOption = `<option value=''>${errorText}</option>`;
    let options = [];

    window.jQuery
      .ajax({
        beforeSend: () => {
          this.hideNoAwsAccountsAlert();
        },
        data: { group_id: groupId },
        dataType: "json",
        url: "/aws_accounts",
      })
      .done((data) => {
        if (data.aws_accounts.length === 0) {
          this.showNoAwsAccountsAlert();
          return options.push(errorOption);
        }

        data.aws_accounts.forEach((awsAccount) => {
          // AWSアカウントの情報をもとに「#111122223333: テストAWSアカウント」のような書式の選択肢を生成する
          // NOTE: 書式を変更した場合は AwsAccountDecorator#as_multiple_select_option にも変更を反映すること。
          options.push(
            `<option value='${awsAccount.id}' data-is-latest-permission="${awsAccount.is_latest_permission}">#${awsAccount.account_number}: ${awsAccount.name}</option>`
          );
        });
      })
      .fail(() => {
        options.push(errorOption);
      })
      .always(() => {
        this.refreshAwsAccountsSelectorOptions(options);
      });
  }

  /**
   * フォーム内の複数AWSアカウント(SELECT要素)を更新します。
   *
   * @private
   * @param {string[]} 複数AWSアカウントセレクトボックスの選択肢の配列
   */
  refreshAwsAccountsSelectorOptions(options) {
    this.awsAccountsSelectors.forEach((tag) => {
      window.jQuery(tag).html(options).trigger("change");
    });
  }

  /**
   * グループ選択のセレクトボックスが変更された際に
   * フォーム内のGoogle Cloudアカウント(SELECT要素)を更新します。
   *
   * @private
   * @param {string} groupId 対象のグループ ID
   */
  refreshGoogleCloudAccountSelector(groupId) {
    const errorText = I18n.t("no_google_cloud_account", { scope: Constants.I18N_SCOPE });
    const errorOption = `<option value=''>${errorText}</option>`;
    let options = [];

    window.jQuery
      .ajax({
        beforeSend: () => this.hideNoGoogleCloudAccountAlert(),
        data: { group_id: groupId },
        dataType: "json",
        url: "/google_cloud_accounts",
      })
      .done((data) => {
        if (data.length === 0) {
          this.showNoGoogleCloudAccountAlert();
          return options.push(errorOption);
        }

        // Select2プレースホルダー表示用に空の選択肢が必要
        options.push("<option></option>");

        data.forEach((google_cloud_account) => {
          options.push(`<option value='${google_cloud_account.id}'>${google_cloud_account.name}</option>`);
        });
      })
      .fail(() => {
        options.push(errorOption);
      })
      .always(() => {
        this.refreshGoogleCloudAccountSelectorOptions(options);
      });
  }

  /**
   * フォーム内のGoogle Cloudアカウント(SELECT要素)を更新します。
   *
   * @private
   * @param {string[]} Google Cloudアカウントの配列
   */
  refreshGoogleCloudAccountSelectorOptions(options) {
    this.googleCloudAccountSelectors.forEach((tag) => {
      window.jQuery(tag).html(options).trigger("change");
    });
  }

  /**
   * 選択中のグループIDをあらわすフォーム内の <input type="hidden"> の要素を更新します。
   *
   * @private
   * @param {string} groupId 選択中のグループ ID
   */
  refreshGroupIdHiddenInput(groupId) {
    this.groupIdHiddenInput.val(groupId).trigger("change");
  }

  /**
   * ページ内にある「AWSアカウントの登録が必要です」アラートを表示します。
   * @private
   */
  showNoAwsAccountAlert() {
    window.jQuery(`#${this.props.noAwsAccountAlertIdValue}`).show();
  }

  /**
   * ページ内にある複数AWSアカウント用の「AWSアカウントの登録が必要です」アラートを表示します。
   * @private
   */
  showNoAwsAccountsAlert() {
    window.jQuery(`#${this.props.noAwsAccountsAlertIdValue}`).show();
  }

  /**
   * ページ内にある「Google Cloudアカウントの登録が必要です」アラートを表示します。
   * @private
   */
  showNoGoogleCloudAccountAlert() {
    window.jQuery(`#${this.props.noGoogleCloudAccountAlertIdValue}`).show();
  }
}
