import React from "react";

import { FontAwesomeIcon } from "./FontAwesomeIcon";

const I18n = window.I18n; // i18n-js

/** リロードボタン */
export const ReloadButton: React.FC<{
  /** 通信中かどうか(アイコンのアニメーション制御に利用される) */
  isTransmitting: boolean;
  /** ボタンがクリックされた際のコールバック */
  onClick: () => void;
}> = (props) => {
  return (
    <button className="btn" onClick={(): void => props.onClick()} title={I18n.t("javascript.reload_button.tooltip")}>
      <FontAwesomeIcon isSpin={props.isTransmitting} name="sync" style="regular" />
    </button>
  );
};
