import React from "react";

const ProviderIdentifier = {
  AWS: "aws",
  GOOGLE_CLOUD: "google_cloud",
  NO: "no",
} as const;
type ProviderIdentifier = typeof ProviderIdentifier[keyof typeof ProviderIdentifier];

/** プロバイダーアイコン */
export const ProviderIcon: React.FC<{
  /** プロバイダ識別子("aws", "google_cloud", "no") */
  identifier: ProviderIdentifier;
}> = (props) => {
  switch (props.identifier) {
    case "aws":
      return <AwsIcon />;
    case "google_cloud":
      return <GoogleIcon />;
    case "no":
      return null;
  }
};

/** AWSプロバイダーアイコン */
const AwsIcon: React.FC = () => {
  // 画像ファイルは public/images/provider_logos 以下にあるものを参照する
  return <img alt="AWS" className="ca-provider-icon__overview-table" src="/images/provider_logos/aws.svg" />;
};

/** Googleプロバイダーアイコン */
const GoogleIcon: React.FC = () => {
  // 画像ファイルは public/images/provider_logos 以下にあるものを参照する
  return (
    <img
      alt="Google Cloud"
      className="ca-provider-icon__overview-table"
      src="/images/provider_logos/google_cloud.svg"
    />
  );
};
