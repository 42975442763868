import React from 'react';
import PropTypes from 'prop-types';

import Constants from '../policy_set_form/Constants.js';
import Policy from './Policy.jsx';
import PricingSection from '../policy_set_form/PricingSection.jsx';

/**
 * JSONで表現されたグループをあらわす仮想的な型。
 * @typedef {Object} Group
 * @property {string} color グループカラー
 * @property {string} name グループ名
 */

/**
 * JSONで表現されたポリシーをあらわす仮想的な型。
 * @typedef {Object} Policy
 * @property {string} description ポリシーの説明
 * @property {boolean} enabled 有効になっているかどうか
 * @property {number[]} failedPostProcessIds 失敗時の後処理のID
 * @property {number} id ポリシーID
 * @property {string} name ポリシー名
 * @property {Object} parameters ポリシーパラメーター(JSON)
 * @property {number[]} succeededPostProcessIds 成功時の後処理のID
 * @property {number} templateId ポリシーテンプレートID
 * @property {Object} templateParameters ポリシーテンプレートパラメーターの定義(JSON)
 */

/**
 * JSONで表現された後処理をあらわす仮想的な型。
 * @typedef {Object} PostProcess
 * @property {?Group} group 後処理が属するグループ(共通後処理の場合は null となる)
 * @property {number} id 後処理ID
 * @property {string} name 後処理名
 * @property {Object} parameters パラメーター(JSON)
 * @property {string} service サービス
 */

// ポリシーセット詳細表示コンポーネント
//
// ポリシーセット詳細における各ポリシー情報の表示を行います。
//
class PolicySet extends React.Component {
  /**
   * プロパティ定義を返します
   *
   * @public
   * @property {number} enabledPolicyCount 有効になっているポリシーの数
   * @property {Group} group ポリシーセットが属するグループ
   * @property {?boolean} hideDisabled 無効になっているポリシーを隠すかどうか
   * @property {Array<Policy>} policies ポリシーセットに含まれるポリシー
   * @property {Array<PostProcess>} postProcesses ポリシーセットに含まれるポリシーに設定されている後処理
   * @property {Object} triggerTypes ポリシーIDをキーにしたポリシー毎の
   *   トリガータイプ(Periodic/configuration change)の情報
   */
  static get propTypes() {
    return({
      enabledPolicyCount: PropTypes.number.isRequired,
      group: PropTypes.object.isRequired,
      hideDisabled: PropTypes.bool,
      policies: PropTypes.arrayOf(PropTypes.object).isRequired,
      postProcesses: PropTypes.arrayOf(PropTypes.object).isRequired,
      triggerTypes: PropTypes.object.isRequired,
    });
  }

  /**
   * プロパティ定義のデフォルト値を返します
   *
   * @public
   * @property {?boolean} hideDisabled 無効になっているポリシーを隠すかどうか
   */
  static get defaultProps() {
    return({
      hideDisabled: false,
    });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <React.StrictMode>
        {
          this.policiesToDisplay().map((policy) => {
            return(
              <Policy
                key={policy.id}
                failedPostProcessIds={policy.failedPostProcessIds}
                group={this.props.group}
                policy={policy}
                postProcesses={this.props.postProcesses}
                succeededPostProcessIds={policy.succeededPostProcessIds}
                triggerType={this.props.triggerTypes[policy.templateId]}
              />
            );
          })
        }
      </React.StrictMode>
    );
  }

  /**
   * 表示すべきすべてのポリシーを返します。
   * showDisabled プロパティが真の場合はすべてのポリシーを、そうでなければ有効になっているポリシーのみを返します。
   *
   * @private
   * @return {Array<Policy>}
   */
  policiesToDisplay() {
    return this.props.hideDisabled
      ? this.props.policies.filter(policy => policy.enabled)
      : this.props.policies;
  }
}

export default PolicySet;
