import PropTypes from 'prop-types';
import React from 'react';

import Constants from './Constants.js';

const I18n = window.I18n; // i18n-js

/**
 * フォーム送信ボタン。
 */
export default class SubmitButton extends React.Component {
  /**
   * プロパティの定義を返します。
   *
   * @return {Object}
   * @property {boolean} disabled ボタンが無効かどうか
   */
  static get propTypes() {
    return({
      disabled: PropTypes.bool.isRequired,
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);

    this.buttonText = I18n.t('submit_button', { scope: Constants.I18N_SCOPE });
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <button
        className="btn btn-primary btn-lg"
        disabled={this.props.disabled}
        type="submit"
      >
        <i className="fa fa-check-circle" aria-hidden="true"></i>
        &nbsp;
        {this.buttonText}
      </button>
    );
  }
}
