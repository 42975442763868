import React from "react";

import { AwsAccount } from "./types";
import { Group } from "./types";
import SortableTableHeaderCell from "../../SortableTableHeaderCell";

import { DeleteAwsAccountButton } from "./DeleteAwsAccountButton";
import { EbsBackupCheckSelector } from "./EbsBackupCheckSelector";
import { EditAwsAccountButton } from "./EditAwsAccountButton";
import { UpdateIamRoleButton } from "./UpdateIamRoleButton";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.group_aws_account";

export const GroupAwsAccountTable: React.FC<{
  awsAccounts: AwsAccount[];
  group: Group;
  errorMessage: string;
  sortColumn: string;
  sortDirection: string;
  onSortClick: (column: string) => void;
  reload: () => void;
}> = (props) => {
  const renderButtons = (awsAccount: AwsAccount): React.ReactElement => {
    return (
      <React.Fragment>
        <EditAwsAccountButton
          awsAccount={awsAccount}
          onUpdated={props.reload}
        />
        <DeleteAwsAccountButton awsAccount={awsAccount} onDeleted={props.reload} />
      </React.Fragment>
    );
  };

  const renderIamRolePermission = (awsAccount: AwsAccount): React.ReactElement => {
    if (awsAccount.isIamRole) {
      if (awsAccount.isLatestPermission) {
        // IAMロールが最新の状態の場合
        return (
          <React.Fragment>
            <i className="fa-solid fa-check ca-aws-account__aws-accounts-table__icon-check"></i>
            {I18n.t("latest", { scope: I18N_SCOPE })}
          </React.Fragment>
        );
      }

      // IAMロールが最新の状態ではない場合
      return <UpdateIamRoleButton awsAccount={awsAccount} group={props.group} onUpdated={props.reload} />;
    }

    // IAMユーザーの場合
    return <React.Fragment>{"---"}</React.Fragment>;
  };

  const renderAwsAccount = (awsAccount: AwsAccount): React.ReactElement => {
    const identity: string = awsAccount.isIamRole ? awsAccount.accountNumber : awsAccount.maskedAccessKeyId;
    const accountType: string = awsAccount.isIamRole
      ? I18n.t("iam_role", { scope: I18N_SCOPE })
      : I18n.t("iam_user", { scope: I18N_SCOPE });
    return (
      <tr key={awsAccount.id}>
        <React.Fragment>
          <td className="ca-aws-account__aws-accounts-table__id">{awsAccount.id}</td>
          <td>
            <span className="ca-aws-account__aws-accounts-table__account-name">{awsAccount.name}</span>
            <span className="ca-aws-account__aws-accounts-table__account-identity">({identity})</span>
          </td>
          <td className="text-center">{awsAccount.jobCount}</td>
          <td className="ca-aws-account__aws-accounts-table__account-type visible-lg">{accountType}</td>
          <td>{renderIamRolePermission(awsAccount)}</td>
          <td>
            <div className="ca-aws-account__aws-accounts-table__buttons">
              <EbsBackupCheckSelector awsAccount={awsAccount} onUpdated={props.reload} />
              {renderButtons(awsAccount)}
            </div>
          </td>
        </React.Fragment>
      </tr>
    );
  };

  const renderAwsAccounts = (awsAccounts: AwsAccount[]): React.ReactElement[] => {
    if (props.errorMessage !== "") {
      return [
        <tr key="0">
          <td colSpan={5}>Error: {props.errorMessage}</td>
        </tr>,
      ];
    }

    if (awsAccounts.length === 0) {
      return [
        <tr key="0">
          <td colSpan={5}>{I18n.t("no_aws_accounts", { scope: I18N_SCOPE })}</td>
        </tr>,
      ];
    }

    return awsAccounts.map(renderAwsAccount);
  };

  return (
    <React.StrictMode>
      <table className="table vertical-middle ca-table-header-no-bordered" data-qa="group-aws-accounts-table">
        <thead>
          <tr>
            <SortableTableHeaderCell
              column="aws_accounts.id"
              active={props.sortColumn == "aws_accounts.id"}
              callback={props.onSortClick}
              direction={props.sortDirection}
              text={I18n.t("ca_aws_account_id", { scope: I18N_SCOPE })}
              classNameForHeaderCell="
                ca-group-table-header--clickable
              "
            />
            <SortableTableHeaderCell
              column="aws_accounts.name"
              active={props.sortColumn == "aws_accounts.name"}
              callback={props.onSortClick}
              direction={props.sortDirection}
              text={I18n.t("account_name", { scope: I18N_SCOPE })}
              caption={I18n.t("account_identifier", { scope: I18N_SCOPE })}
              classNameForHeaderCell="
                ca-group-table-header--clickable
              "
            />
            <SortableTableHeaderCell
              column="trigger_jobs_count"
              active={props.sortColumn == "trigger_jobs_count"}
              callback={props.onSortClick}
              direction={props.sortDirection}
              text={I18n.t("job_count", { scope: I18N_SCOPE })}
              textAlignCenter={true}
              classNameForHeaderCell="
                ca-group-table-header--clickable
              "
            />
            <th className="visible-lg">{I18n.t("iam_type", { scope: I18N_SCOPE })}</th>
            <th>
              {I18n.t("iam_role_permission_0", { scope: I18N_SCOPE })}
              <br />
              {I18n.t("iam_role_permission_1", { scope: I18N_SCOPE })}
              <a
                href={I18n.t("common.manual.url.aws_account.iam_role_update")}
                target="_blank"
                rel="noreferrer"
                className="marginL5"
              >
                <i className="fa fa-question-circle"></i>
              </a>
            </th>
            <th className="ca-aws-account__aws-accounts-table__button-cell-header">
              {I18n.t("ebs_backup_check_0", { scope: I18N_SCOPE })}
              <br />
              {I18n.t("ebs_backup_check_1", { scope: I18N_SCOPE })}
              <a
                href={I18n.t("common.manual.url.about.ebs_backup_check")}
                target="_blank"
                rel="noreferrer"
                className="marginL5"
              >
                <i className="fa fa-question-circle"></i>
              </a>
            </th>
          </tr>
        </thead>
        <tbody>{renderAwsAccounts(props.awsAccounts)}</tbody>
      </table>
    </React.StrictMode>
  );
};
