import React from 'react';
import PropTypes from 'prop-types';

import withBootstrapTooltip from '../withBootstrapTooltip.js';

import Constants from './Constants.js';
import { ReloadButton } from "../ReloadButton.tsx";

import RequestTableRow from './RequestTableRow.jsx';

const I18n = window.I18n; // i18n-js

/**
 * リスト作成リクエストテーブル
 */
class RequestTable extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   */
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
      errorMessage: PropTypes.string.isRequired,
      generateRequests: PropTypes.arrayOf(PropTypes.object).isRequired,
      isPending: PropTypes.bool.isRequired,
      isReadOnly: PropTypes.bool.isRequired,
      tooltipRef: PropTypes.func,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.emptyText = I18n.t('empty_for_table', { scope: Constants.I18N_SCOPE });
    this.infoText = I18n.t('info_for_table', { scope: Constants.I18N_SCOPE });
    this.pendingText = I18n.t('fetch_pending', { scope: Constants.I18N_SCOPE });
    this.requestedAtText = I18n.t('requested_at', { scope: Constants.I18N_SCOPE });

    const modelScope = 'activerecord.attributes.workspaces_list_generate_request';
    this.awsAccountText = I18n.t('aws_account', { scope: modelScope });
    this.regionText = I18n.t('region', { scope: modelScope });
    this.resultText = I18n.t('result', { scope: modelScope });
    this.serialNumberText = I18n.t('serial_number', { scope: modelScope });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <div
        className="ca-workspaces-lists-table panel"
        ref={this.props.tooltipRef}
      >
        <div className="ca-table-headroom">
          <div className="ca-table-headroom__primary">
            <div className="ca-workspaces-lists-table__info">
              <i className="fa fa-info-circle marginR5" aria-hidden="true"></i>
              {this.infoText}
            </div>
          </div>
          <div className="ca-table-headroom__secondary">
            <ReloadButton
              isTransmitting={this.props.isPending}
              onClick={() => this.props.emitter.emit(Constants.EVENT_RELOAD)}
            />
          </div>
        </div>
        <table className="table ca-table-header-no-bordered">
          <thead>
            <tr>
              <th className={"text-center"}>{this.serialNumberText}</th>
              <th>{this.requestedAtText}</th>
              <th>{this.awsAccountText}</th>
              <th>{this.regionText}</th>
              <th className={"text-center"}>{this.resultText}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.props.isPending &&
              <tr>
                <td colSpan="6">
                  <p>{this.pendingText}</p>
                </td>
              </tr>
            }
            {!this.props.isPending && this.props.errorMessage != '' &&
              <tr>
                <td colSpan="6">
                  <p>
                    {I18n.t(
                      'error_for_table',
                      {
                        scope: Constants.I18N_SCOPE,
                        message: this.props.errorMessage,
                      }
                    )}
                  </p>
                </td>
              </tr>
            }
            {!this.props.isPending && this.props.generateRequests.length == 0 &&
              <tr>
                <td colSpan="6">
                  <p>{this.emptyText}</p>
                </td>
              </tr>
            }
            {!this.props.isPending && this.props.generateRequests.map((genreq) => {
              return(
                <RequestTableRow
                  emitter={this.props.emitter}
                  generateRequest={genreq}
                  isReadOnly={this.props.isReadOnly}
                  key={genreq.id}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withBootstrapTooltip(RequestTable);
