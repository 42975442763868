import React from 'react';
import PropTypes from 'prop-types';

// マニュアル表示用コンポーネント
// マニュアルリンク用のアイコンと別ウィンドウで表示させるリンクを表示します。
//
class ManualLink extends React.Component {
  static get propTypes() {
    return ({
      linkText: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    });
  }

  render() {
    return (
      <React.StrictMode>
        <span className="manual-link">
          <a
            className="manual-link__link"
            href={this.props.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="manual-link__icon fa fa-book" aria-hidden="true"></i>
            {this.props.linkText}
          </a>
        </span>
      </React.StrictMode>
    );
  }
}

export default ManualLink;
