import React, { useEffect, useState } from "react";

const LAST_VIEWD_AT_STORAGE_KEY = "announcements.last_viewed_at";

// お知らせ一覧最終閲覧日時
function lastViewedAtString(): string {
  // 最後に参照したお知らせがない場合は空文字を設定しサーバー側で全てが未読と判断させる
  return localStorage.getItem(LAST_VIEWD_AT_STORAGE_KEY) ?? "";
}

export const AnnouncementsContainer: () => JSX.Element = () => {
  const [html, setHTML] = useState("");

  useEffect(() => {
    // useEffectでasyncを扱うときは関数呼び出しにする必要あり
    (async (): Promise<void> => {
      // HTMLを取得してレンダリング
      try {
        const res = await fetch(`/announcements?last_viewed_at=${encodeURIComponent(lastViewedAtString())}`);
        setHTML(await res.text());
      } catch (e) {
        // ブラウザ側のネットワーク等の問題で TypeError: Failed to fetch が発生する場合があるため
        // エラーが発生した場合には処理を終了する
        console.error(e);
        return;
      }

      // お知らせ一覧内のリンクを開いてもお知らせ一覧は消えないようにする
      jQuery("[data-announcements-container] .dropdown-menu").on("click", (e) => e.stopPropagation());

      // お知らせ一覧表示のイベントリスナー
      jQuery("[data-announcements-container]")
        .on("shown.bs.dropdown", () => {
          // お知らせ一覧の表示で既読時刻をUTCでlocalStorageに保存する
          localStorage.setItem(LAST_VIEWD_AT_STORAGE_KEY, new Date().toISOString());

          // お知らせアイコンに表示されている未読マークとアニメーションを削除して
          // デザインを塗りつぶしから通常のアウトラインアイコンに戻す
          jQuery("[data-announcements-container] .ca-notification__icon-unread")
            .removeClass("ca-notification__icon-unread fa-solid fa-shake")
            .addClass("ca-notification__icon fa-regular");
        })
        .on("hidden.bs.dropdown", () => {
          // お知らせ一覧を閉じたタイミングで全てのお知らせの未読マークを非表示にする
          jQuery("[data-announcements-container] li.ca-notification__list-unread").removeClass(
            "ca-notification__list-unread"
          );
        });
    })();
  }, []);

  return <div data-announcements-container dangerouslySetInnerHTML={{ __html: html }}></div>;
};
