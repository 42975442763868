import React, { SyntheticEvent, useState, useEffect } from "react";

type actionType = [string, string];
type actionTypes = [string, actionType[]];

type option = {
  name: string;
  value: string;
};

type providerTypeOption = option;

type groupedActionTypesOption = {
  provider_type: string;
  actions: actionTypes[];
};

type column = "provider_type" | "action_type";

type selectedValueChange = {
  column: column;
  value: string;
};

/**
 * ジョブ一覧のジョブ詳細検索のメニューに表示するプロバイダとアクションのセレクトボックス
 * ２つのセレクトボックスは以下のように連携して動作します
 * - プロバイダが選択されていない場合、アクションは選択できない
 * - プロバイダが選択済みの場合、プロバイダに属するアクションのみが選択可能になる
 * - アクションが選択された状態でプロバイダが変更された場合、アクションの選択がリセットされる
 */
export const ProviderAndActionSelector: React.FC<{
  providerTypeValue: string;
  actionTypeValue: string;
  workflowTypeValue: string;
  providerTypeOptions: providerTypeOption[];
  actionTypeOptions: groupedActionTypesOption[];
}> = (props) => {
  const [selectedValues, setSelectedValues] = useState({
    actionType: props.actionTypeValue,
    providerType: props.providerTypeValue,
  });

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    // ワークフロー種別が「ワークフロートリガージョブ」の場合、プロバイダ・アクションの選択を無効化する
    const checkAndDisableProviderAndAction = (workflowType: string) => {
      if (workflowType === "workflow_trigger_job") {
        setIsDisabled(true);
        setSelectedValues({ providerType: "", actionType: "" });
      } else {
        setIsDisabled(false);
      }
    };

    // 初期レンダリング時にワークフロー種別が「ワークフロートリガージョブ」の場合、プロバイダ・アクションの選択を無効化する
    checkAndDisableProviderAndAction(props.workflowTypeValue);

    // ワークフロー種別が変更された場合に判定を行う
    const handleWorkflowTypeChangeEvent = (event: CustomEvent) => {
      checkAndDisableProviderAndAction(event.detail.workflowType);
    };

    document.addEventListener("kanrinmaru:workflow_type_changed", handleWorkflowTypeChangeEvent);

    return () => {
      document.removeEventListener("kanrinmaru:workflow_type_changed", handleWorkflowTypeChangeEvent);
    };
  }, [props.workflowTypeValue]);

  const actionTypeOptions = props.actionTypeOptions.find((actions) => {
    return actions.provider_type === selectedValues.providerType;
  })?.actions ?? [];

  const I18n = window.I18n; // i18n-js
  const i18nScope = { scope: "javascript.job_table.detailed_search_input" };
  const allActionText = I18n.t("all_action", i18nScope);
  const allProviderText = I18n.t("all_provider_type", i18nScope);

  /**
   * セレクトボックスの選択値が変更された場合に子コンポーネントから呼び出されるコールバック関数
   */
  const handleOnChange = ({ column, value }: selectedValueChange): void => {
    if (column === "provider_type") {
      if (value === "" || value !== selectedValues.providerType) {
        // プロバイダが指定されない場合や他のプロバイダが指定された場合はアクションの選択をリセットする
        return setSelectedValues({ providerType: value, actionType: "" });
      }
      return setSelectedValues({ ...selectedValues, providerType: value });
    }

    return setSelectedValues({ ...selectedValues, actionType: value });
  };

  return (
    <div className="display-flex">
      {/* プロバイダのセレクトボックス */}
      <SearchSelector
        callback={handleOnChange}
        blankText={allProviderText}
        options={props.providerTypeOptions}
        column={"provider_type"}
        selectedValue={selectedValues.providerType}
        disabled={isDisabled} // カスタムイベントに基づいて無効化
      />
      <span
        className="ca-job-search-input__detailed-search-container__group-icon fa fa-caret-right"
        aria-hidden="true"
      ></span>
      {/* アクションのセレクトボックス */}
      <GroupedSearchSelector
        callback={handleOnChange}
        blankText={allActionText}
        disabled={isDisabled || actionTypeOptions.length === 0} // カスタムイベントに基づいて無効化
        groupedOptions={actionTypeOptions}
        column={"action_type"}
        selectedValue={selectedValues.actionType == "" ? "" : selectedValues.actionType}
      />
    </div>
  );
};

const SearchSelector: React.FC<{
  callback: (selectedValueChange: selectedValueChange) => void;
  blankText: string;
  options: option[];
  column: column;
  selectedValue: string;
  disabled?: boolean;
}> = (props) => {
  /*
   * セレクトボックスのコールバック関数
   * 変更された要素の名前と値を整形して、 Props のコールバック関数を呼び出します
   */
  const handleOnChange = (event: SyntheticEvent): void => {
    event.preventDefault();
    const target = event.target as HTMLInputElement;
    const targetValue = target.value;
    const selectedValueChange: selectedValueChange = {
      value: targetValue as string,
      column: props.column,
    };
    props.callback(selectedValueChange);
  };

  return (
    <>
      <select
        className="ca-job-search-input__detailed-search-container__selector form-control"
        name={props.column} // rails のフォームヘルパーで作成したボタンで submit するために必要
        onChange={handleOnChange}
        value={props.selectedValue}
        disabled={props.disabled} // カスタムイベントに基づいて無効化
      >
        <option key={0} value="">
          {props.blankText}
        </option>
        {props.options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          );
        })}
      </select>
    </>
  );
};

/**
 * exampleのようなArrayを渡すと、optgroupを使ってグルーピングしたセレクトボックスを返します。
 *
 * @example
 * [
 *   "EC2", [
 *     ["EC2: インスタンスを起動", "start_instances"],
 *     ["EC2: インスタンスを停止", "stop_instances"],
 *   ],
 *   "RDS", [
 *     ["RDS: DBインスタンスを起動", "start_rds_instances"],
 *     ["RDS: DBインスタンスを停止", "stop_rds_instances"],
 *   ]
 * ]
 */
const GroupedSearchSelector: React.FC<{
  callback: (selectedValueChange: selectedValueChange) => void;
  blankText: string;
  disabled: boolean;
  groupedOptions: actionTypes[];
  column: column;
  selectedValue: string;
}> = (props) => {
  /*
   * セレクトボックスのコールバック関数
   * 変更された要素の名前と値を整形して、Propsのコールバック関数を呼び出します
   */
  const handleOnChange = (event: SyntheticEvent): void => {
    event.preventDefault();
    const target = event.target as HTMLInputElement;
    const targetValue = target.value;
    const selectedValueChange: selectedValueChange = {
      value: targetValue as string,
      column: props.column,
    };
    props.callback(selectedValueChange);
  };

  const options = props.groupedOptions?.map((groupedAction) => {
    return (
      <optgroup key={groupedAction[0]} label={groupedAction[0]}>
        {groupedAction[1].map((actionType) => {
          return(
            <option key={actionType[1]} value={actionType[1]}>
              {actionType[0]}
            </option>
          );
        })}
      </optgroup>
    );
  });

  return (
    <>
      <select
        className="ca-job-search-input__detailed-search-container__selector form-control"
        name={props.column} // rails のフォームヘルパーで作成したボタンで submit するために必要
        disabled={props.disabled}
        onChange={handleOnChange}
        value={props.selectedValue}
      >
        <option key={0} value="">
          {props.blankText}
        </option>
        {options}
      </select>
    </>
  );
};

