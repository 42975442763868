import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';

const _ = window._; // Underscore.js
const I18n = window.I18n; // i18n-js

// 入力欄の数
const FIELD_COUNT = 10;

// ポリシーの文字列リスト型パラメーター入力フィールドコンポーネント
class PolicyParameterStringsField extends React.Component {
  /**
   * デフォルトのプロパティ値を返します。
   * React内部から呼び出されます。
   *
   * @return {object}
   */
  static get defaultProps() {
    return({
      help: '',
      maxLength: null,
      readOnly: false,
      required: false
    });
  }

  static get propTypes() {
    return({
      emitter: PropTypes.object,
      help: PropTypes.string,
      initialValues: PropTypes.arrayOf(PropTypes.string).isRequired,
      label: PropTypes.string.isRequired,
      maxLength: PropTypes.number,
      name: PropTypes.string.isRequired,
      policyTemplateId: PropTypes.number.isRequired,
      readOnly: PropTypes.bool,
      required: PropTypes.bool
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      values: this.props.initialValues
    };
    this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.values.join() !== this.state.values.join()
        || nextProps.help !== this.props.help
        || nextProps.label !== this.props.label
        || nextProps.name !== this.props.name
        || nextProps.policyTemplateId !== this.props.policyTemplateId
    );
  }

  render() {
    let requirement = null;
    if (this.props.required) {
      requirement = I18n.locale === "ja" ? "(必須)" : "(Required)";
    }

    return(
      <div className="form-group">
        <div className="row">
          <div className="col-xs-6">
            <label className="control-label">
              {this.props.label} {requirement}
            </label>
            {this.renderInputFields()}
            {this.renderHelp()}
          </div>
        </div>
      </div>
    );
  }

  renderHelp() {
    if (this.props.help === '') {
      return null;
    }

    return(
      <p className="help-block">
        {this.props.help}
      </p>
    );
  }

  renderInputFields() {
    const fieldCountRange = _.range(FIELD_COUNT);
    return fieldCountRange.map((value, index) => {
      if (this.props.readOnly && !this.props.initialValues[index]) {
        return null;
      }
      return(
        <input
          className="form-control policy-parameter-string-field__input"
          key={index}
          onChange={this.handleChange}
          readOnly={this.props.readOnly}
          required={index == 0 && this.props.required}
          type="text"
          value={this.state.values[index] || ""}
          data-index={index}
        />
      );
    });
  }

  handleChange(event) {
    if (this.props.readOnly) {
      return;
    }

    const value = event.target.value;
    const index = Number(event.target.getAttribute('data-index'));

    let newValues = _.clone(this.state.values);
    newValues[index] = value;
    this.setState({ values: newValues }, () => {
      this.props.emitter.emit(
        Constants.EVENT_CHANGE_POLICY_PARAMETER,
        {
          policyTemplateId: this.props.policyTemplateId,
          name: this.props.name,
          type: Constants.PARAMETER_FIELD_TYPE_STRINGS,
          value: value,
          index: index
        }
      );
    });
  }
}

export default PolicyParameterStringsField;
