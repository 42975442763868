import React from "react";
import PropTypes from "prop-types";

import PolicySetTableRow from "./PolicySetTableRow.jsx";


const I18n = window.I18n;
const I18N_SCOPE = "javascript.post_process_policy_sets";

/**
 * ポリシーセットテーブルコンポーネント
 *
 * このコンポーネントの描画結果はTABLE要素となります。
 */
export default class PolicySetTable extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @property {string} errorMessage テーブル内に表示するエラーメッセージ
   * @property {Object[]} policySets ポリシーセットの配列(内容についてはindex.json.jbuilderを参照)
   */
  static get propTypes() {
    return {
      errorMessage: PropTypes.string,
      policySets: PropTypes.array.isRequired,
    };
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return (
      <table className="table vertical-middle ca-post-process-policy-sets-table ca-table-header-no-bordered">
        <thead>
          <tr>
            <th className="ca-post-process-policy-sets__policy-set-name">
              {I18n.t("policy_set_name", {scope: I18N_SCOPE})}
            </th>
            <th className="ca-post-process-policy-sets__enabled-on">
              {I18n.t("enable_on", {scope: I18N_SCOPE})}
            </th>
            <th className="ca-post-process-policy-sets__policy-name">
              {I18n.t("policy_name", {scope: I18N_SCOPE})}
            </th>
          </tr>
        </thead>
        <tbody>{this.getPolicySetRows()}</tbody>
      </table>
    );
  }

  /**
   * テーブルに表示するポリシーセットのポリシーセットテーブル行コンポーネントを配列で返します。
   * @return {ReactElement}
   */
  getPolicySetRows() {
    const isPolicySetsExist =
      this.props.errorMessage == null && this.props.policySets.length > 0;

    if (isPolicySetsExist) {
      return this.props.policySets.map(policySet => {
        return <PolicySetTableRow key={policySet.id} policySet={policySet} />;
      });
    }

    const message = this.props.errorMessage || I18n.t("no_policy_sets", {scope: I18N_SCOPE});
    return (
      <tr><td colSpan="3">{message}</td></tr>
    );
  }
}
