import React from 'react';
import PropTypes from 'prop-types';

const I18n = window.I18n; // i18n-js
const I18N_SCOPE = 'javascript.tfa_settings.dialog_open_button';

/**
 * ダイアログオープンボタン
 * 二要素認証の状態に応じたボタンを表示します。
 */
export default class DialogOpenButton extends React.Component {
  /**
   * propTypes
   * @return {object}
   * @property {object} emitter EventEmitter
   * @property {bool} enabled 二要素認証が有効化されているかどうか
   * @property {?bool} simple UIをシンプルにするかどうか(組織ユーザー用の場合にtrue、デフォルトはfalse)
   */
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
      enabled: PropTypes.bool.isRequired,
      simple: PropTypes.bool,
    });
  }

  /**
   * デフォルトのプロパティを返します。
   *
   * @public
   * @return {Object}
   * @property {bool} simple UIをシンプルにするかどうか(組織ユーザー用の場合にtrue)
   */
  static get defaultProps() {
    return({
      simple: false,
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);

    this.toDisableText = I18n.t('to_disable', { scope: I18N_SCOPE });
    this.toEnableText = I18n.t('to_enable', { scope: I18N_SCOPE });
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return this.props.simple ? this.renderSimple() : this.renderRich();
  }

  /**
   * @return {ReactElement}
   */
  renderRich() {
    const className = this.props.enabled ? 'btn-danger' : 'btn-default';
    const value = this.props.enabled ? this.toDisableText : this.toEnableText;

    return(
      <input
        className={`btn btn-sm ${className} qa-tfa-settings-dialog-open-button`}
        onClick={() => { this.props.emitter.emit('openDialog'); }}
        type="button"
        style={{width: '130px'}}
        value={value}
      />
    );
  }

  /**
   * @return {ReactElement}
   */
  renderSimple() {
    const text = this.props.enabled ? this.toDisableText : this.toEnableText;
    const buttonClasses = `
      btn
      btn-default
      ca-organization-user-account__button
      ca-organization-user-account__button--${I18n.locale}
    `;

    return(
      <button
        className={buttonClasses}
        onClick={() => { this.props.emitter.emit('openDialog'); }}
      >
        {text}
      </button>
    );
  }
}
