import React from "react";

/** SubmitButtonのプロパティ */
type Props = {
  /** 無効かどうか */
  disabled: boolean;
  /** ボタンのラベル */
  label: string;
  /** 送信中状態にするかどうか */
  pending: boolean;
  /** 送信中状態の場合のボタンのラベル */
  pendingLabel: string;
};

/**
 * 送信ボタン
 *
 * 状態に応じて送信ボタンまたは送信中ボタン(操作不可)をBUTTON要素で表示します。
 */
const SubmitButton: React.FC<Props> = ({ disabled = false, pending = false, label, pendingLabel }) => {
  if (pending) {
    return <PendingButton label={pendingLabel} />;
  }

  return <Button disabled={disabled} label={label} />;
};

/**
 * 送信ボタン
 */
const Button: React.FC<{
  /** 無効かどうか */
  disabled: boolean;
  /** ボタンのラベル */
  label: string;
}> = ({ disabled, label }) => {
  const className = disabled ? "btn-default" : "btn-success";

  return (
    <button className={`btn ${className} btn-lg btn-continue`} disabled={disabled} type="submit">
      {label}
    </button>
  );
};

/**
 * 送信中ボタン
 */
const PendingButton: React.FC<{
  /** ボタンのラベル */
  label: string;
}> = ({ label }) => {
  return (
    <button className={`btn btn-success btn-lg btn-continue`} disabled={true} type="submit">
      {label}
    </button>
  );
};

export { SubmitButton };
