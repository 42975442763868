import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';

const I18n = window.I18n; // i18n-js

/**
 * グループ選択UI
 */
export default class GroupSelector extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   */
  static get propTypes() {
    return({
      currentGroupId: PropTypes.number.isRequired,
      emitter: PropTypes.object.isRequired,
      groups: PropTypes.arrayOf(PropTypes.object).isRequired,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <div className="ca-workspaces-lists-group-selector">
        <label
          className="ca-workspaces-lists-group-selector__label"
          htmlFor="ca-workspaces-lists-group-selector"
        >
          {I18n.t('label_for_group', { scope: Constants.I18N_SCOPE })}
        </label>
        <select
          className="ca-workspaces-lists-group-selector__select"
          id="ca-workspaces-lists-group-selector"
          onChange={this.handleChange}
          value={this.props.currentGroupId}
        >
          {this.props.groups.map(group => {
            return <option key={group.id} value={group.id}>{group.name}</option>;
          })}
        </select>
      </div>
    );
  }

  /**
   * グループが変更された際の処理を行います。
   *
   * @private
   */
  handleChange(event) {
    this.props.emitter.emit(Constants.EVENT_CHANGE_CURRENT_GROUP, event.target.value);
  }
}
