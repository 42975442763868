import React from 'react';
import PropTypes from 'prop-types';

import Dialog from './Dialog.jsx';
import DialogOpenButton from './DialogOpenButton.jsx';

/**
 * 後処理を新規作成するUIコンポーネント
 */
export default class PostProcessSettings extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {Object} emitter EventEmitterオブジェクト
   * @property {string} defaultLanguage - 言語設定欄がある場合のデフォルトの言語(例 "ja")
   * @property {string} defaultTimeZone - タイムゾーン設定がある場合のデフォルトのタイムゾーン(例 "Tokyo")
   * @property {Array<Number>} groupIds グループIDの配列
   * @property {array[]} groups グループの配列
   * @property {boolean} isSlackIntegrated - Slackが外部サービスとして連携済みかどうか
   * @property {boolean} isUserCanManageIntegrations - ユーザーが外部サービス連携を管理できるかどうか
   * @property {string} loadingImagePath ローディング中に表示する画像のURLパス
   * @property {string} postProcessType 成功時の後処理か失敗時の後処理かをあらわす文字列
   *                                    "completed" もしくは "failed"
   * @property {string} postProcessesUrl 後処理の更新時にXHRでアクセスするURL
   * @property {array} regions リージョンの配列
   * @property {array} services サービスの配列
   * @property {bool} showDialog 後処理作成ダイアログの表示状態
   * @property {string} sqsQueuesPath SQSキュー名を取得するためのパス
   * @property {Array<Array>} timeZones - タイムゾーンの選択肢
   */
  static get propTypes() {
    return({
      defaultLanguage: PropTypes.string.isRequired,
      defaultTimeZone: PropTypes.string.isRequired,
      emitter: PropTypes.object.isRequired,
      groupIds: PropTypes.arrayOf(PropTypes.number),
      groups: PropTypes.arrayOf(PropTypes.array),
      isSlackIntegrated: PropTypes.bool.isRequired,
      isUserCanManageIntegrations: PropTypes.bool.isRequired,
      loadingImagePath: PropTypes.string.isRequired,
      postProcessType: PropTypes.string.isRequired,
      postProcessesUrl: PropTypes.string.isRequired,
      regions: PropTypes.arrayOf(PropTypes.array).isRequired,
      services: PropTypes.arrayOf(PropTypes.array).isRequired,
      showDialog: PropTypes.bool.isRequired,
      sqsQueuesPath: PropTypes.string.isRequired,
      timeZones: PropTypes.arrayOf(PropTypes.array).isRequired,
    });
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const { postProcessType, ...dialogProps } = this.props;

    return(
      <div style={{display: "inline"}}>
        <DialogOpenButton
          emitter={this.props.emitter}
          postProcessType={postProcessType}
        />
        <Dialog {...dialogProps} />
      </div>
    );
  }
}
