import React from 'react';
import PropTypes from 'prop-types';

import Paginator from './Paginator.js';

const $ = window.jQuery;

/**
 * ページングナビゲーションコンポーネント
 *
 * ステート
 * prevPageAvailable - 前のページが存在するかどうか。
 * nextPageAvailable - 次のページが存在するかどうか。
 *
 * プロパティ
 * callback    - リンクがクリックされた際に呼び出す関数。
 *               クリックされたページ番号が引数として渡されます。
 * currentPage - 現在のページ番号。
 * perPage     - 1ページあたりの表示件数。
 * totalItems  - アイテムの総数。
 */
export default class Pagination extends React.Component {
  /**
   * propTypes
   * @property {}
   */
  static get propTypes() {
    return({
      callback: PropTypes.func.isRequired,
      currentPage: PropTypes.number.isRequired,
      perPage: PropTypes.number.isRequired,
      totalItems: PropTypes.number.isRequired
    });
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);
    this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <div className="text-center">
        <ul className="pagination">
          {this.getPreviousLink()}
          {this.getPaginationPages()}
          {this.getNextLink()}
        </ul>
      </div>
    );
  }

  /**
   * 次のページへのリンクのコンポーネントを返します。
   * @return {ReactElement}
   */
  getNextLink() {
    const paginator = this.getPaginator();
    if (paginator.isNextPageExist()) {
      return this.getEnabledNextLink(paginator.nextPageNum());
    }
    return this.getDisabledNextLink();
  }

  /**
   * 無効な状態の次のページへのリンクのコンポーネントを返します。
   * @return {ReactElement}
   */
  getDisabledNextLink() {
    return(
      <li className="disabled">
        <a href="#" rel="next"><span className="fa fa-chevron-right"></span></a>
      </li>
    );
  }

  /**
   * 有効な状態の次のページへのリンクのコンポーネントを返します。
   * @param {number} num 次のページのページ番号。
   * @return {ReactElement}
   */
  getEnabledNextLink(num) {
    return(
      <li>
        <a
          href="#"
          rel="next"
          onClick={this.handleLinkClick}
          data-num={num}
        ><span className="fa fa-chevron-right"></span></a>
      </li>
    );
  }

  /**
   * ページング用に表示する各ページ番号のコンポーネントを配列で返します。
   * @return {array}
   */
  getPaginationPages() {
    return this.getPaginator().relevantPageNums().map((num) => {
      return(
        <PaginationPage
          key={num}
          num={num}
          currentPage={this.props.currentPage}
          callback={this.props.callback}
        />
      );
    });
  }

  /**
   * プロパティの値を元にPaginatorを生成して返します。
   * @return {Paginator}
   */
  getPaginator() {
    return new Paginator(this.props.totalItems, this.props.perPage, this.props.currentPage);
  }

  /**
   * 前のページへのリンクのコンポーネントを返します。
   * @return {ReactElement}
   */
  getPreviousLink() {
    const paginator = this.getPaginator();
    if (paginator.isPreviousPageExist()) {
      return this.getEnabledPreviousLink(paginator.previousPageNum());
    }
    return this.getDisabledPreviousLink();
  }

  /**
   * 無効な状態の前のページへのリンクのコンポーネントを返します。
   * @return {ReactElement}
   */
  getDisabledPreviousLink() {
    return(
      <li className="disabled">
        <a href="#" rel="prev"><span className="fa fa-chevron-left"></span></a>
      </li>
    );
  }

  /**
   * 有効な状態の前のページへのリンクのコンポーネントを返します。
   * @param {number} num
   * @return {ReactElement}
   */
  getEnabledPreviousLink(num) {
    return(
      <li>
        <a
          href="#"
          rel="prev"
          onClick={this.handleLinkClick}
          data-num={num}
        ><span className="fa fa-chevron-left"></span></a>
      </li>
    );
  }

  /**
   * リンクがクリックされた際に呼び出されるイベントハンドラ。
   * 前のページへのリンク、次のページへのリンク、各ページへのリンクの
   * いずれのリンクがクリックされた場合も、この関数が呼び出されます。
   *
   * @param {SyntheticEvent} event
   */
  handleLinkClick(event) {
    let exactTarget = null;
    event.preventDefault();
    if (event.target.tagName == 'A') {
      exactTarget = event.target;
    } else {
      exactTarget = $(event.target).closest('a')[0];
    }
    this.props.callback(parseInt($(exactTarget).attr('data-num')));
  }
}

/**
 * ページングナビゲーションの各ページ番号リンクコンポーネント
 * 1つのページのリンクをあらわします。
 *
 * プロパティ
 * num         - コンポーネントが対応するページ番号。
 * currentPage - 現在のページ番号。
 * callback    - リンクがクリックされた際に呼び出す関数。
 *               引数にページ番号が渡される。
 */
class PaginationPage extends React.Component {
  /**
   * propTypes
   * @property {}
   */
  static get propTypes() {
    return({
      callback: PropTypes.func.isRequired,
      currentPage: PropTypes.number.isRequired,
      num: PropTypes.number.isRequired
    });
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    let relations = '';
    if (this.isPreviousPage()) {
      relations = 'prev';
    } else if (this.isNextPage()) {
      relations = 'next';
    }

    if (this.isCurrentPage()) {
      return(
        <li className="active">
          <span>{this.props.num}</span>
        </li>
      );
    }

    return(
      <li>
        <a
          href="#"
          rel={relations}
          onClick={this.handleClick}
        >{this.props.num}</a>
      </li>
    );
  }

  /**
   * リンクがクリックされた際に呼び出されるイベントハンドラ。
   * @param {SyntheticEvent} event
   */
  handleClick(event) {
    event.preventDefault();
    this.props.callback(this.props.num);
  }

  /**
   * このコンポーネントのページ番号が現在のページかどうかを返します。
   * @return {bool}
   */
  isCurrentPage() {
    return(this.props.num == this.props.currentPage);
  }

  /**
   * このコンポーネントのページ番号が現在のページの次のページかどうかを返します。
   * @return {bool}
   */
  isNextPage() {
    return((this.props.num - 1) == this.props.currentPage);
  }

  /**
   * このコンポーネントのページ番号が現在のページの前のページかどうかを返します。
   * @return {bool}
   */
  isPreviousPage() {
    return((this.props.num + 1) == this.props.currentPage);
  }
}
