import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";

import alertMessage from "../../AlertMessage.js";
import { useBootstrapTooltip } from "../../BootstrapTooltip";
import { showSuccessToast } from "../../toast";

import { AwsAccount } from "./types";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.groups.delete_aws_account_button";

/**
 * AWSアカウント一覧画面で表示するAWSアカウント削除ボタン
 */
export const DeleteAwsAccountButton: React.FC<{
  awsAccount: AwsAccount;
  onDeleted: () => void;
}> = (props): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isTransmitting, setIsTransmitting] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const deleteGroup = (): void => {
    jQuery
      .ajax({
        beforeSend: () => {
          setErrorMessage("");
          setIsTransmitting(true);
        },
        dataType: "json",
        method: "DELETE",
        url: props.awsAccount.deleteUrl,
      })
      .done(() => {
        props.onDeleted();
        setShowDialog(false);
        showSuccessToast(I18n.t("succeeded", { scope: I18N_SCOPE }));
      })
      .fail((jqXHR, textStatus) => {
        let error = "";

        if (jqXHR.status == 422 && Object.prototype.hasOwnProperty.call(jqXHR.responseJSON, "error")) {
          // バリデーションエラーかつレスポンスJSONに error プロパティが存在する場合
          error = jqXHR.responseJSON.error;
        } else {
          // 500エラーなどの場合
          error = I18n.t("error", { message: textStatus, scope: I18N_SCOPE });
        }

        setErrorMessage(error);
      })
      .always(() => setIsTransmitting(false));
  };

  const closeDialog = (): void => {
    setShowDialog(false);
    setErrorMessage("");
  };

  if (props.awsAccount.isDeletable) {
    return (
      <EnabledButton
        awsAccount={props.awsAccount}
        errorMessage={errorMessage}
        isTransmitting={isTransmitting}
        onCancel={(): void => closeDialog()}
        onClick={(): void => setShowDialog(true)}
        onClose={(): void => closeDialog()}
        onDelete={(): void => deleteGroup()}
        showDialog={showDialog}
      />
    );
  } else {
    return <DisabledButton />;
  }
};

/**
 * グループ削除不可の時に表示する削除ボタン
 * クリックをするとフラッシュメッセージ表示部にアラートメッセージを出す
 */
const DisabledButton = (): JSX.Element => {
  const handleOnClick = (): void => {
    alertMessage(I18n.t("not_deletable", { scope: I18N_SCOPE }));
  };

  return (
    <div className="inline-block" onClick={handleOnClick}>
      <button className="btn btn-default marginL5 ca-aws-account__delete-button" disabled type="button">
        <span className="fa fa-unlock-keyhole" />
      </button>
    </div>
  );
};

/**
 * グループ削除ボタン
 * クリックするとグループ削除確認ダイアログが表示される
 */
const EnabledButton: React.FC<{
  awsAccount: AwsAccount;
  errorMessage: string;
  isTransmitting: boolean;
  onCancel: () => void;
  onClick: () => void;
  onClose: () => void;
  onDelete: () => void;
  showDialog: boolean;
}> = (props): JSX.Element => {
  useEffect(() => {
    ReactModal.setAppElement("body");
  }, []);

  const tooltipRef = useBootstrapTooltip<HTMLButtonElement>();

  return (
    <div className="inline-block">
      <button
        className="btn btn-default marginL5 ca-aws-account__delete-button"
        data-placement="bottom"
        data-toggle="tooltip"
        onClick={props.onClick}
        ref={tooltipRef}
        title={I18n.t("delete_tooltip", { scope: I18N_SCOPE })}
        type="button"
      >
        <span className="fa fa-unlock-keyhole" />
      </button>
      <ReactModal
        isOpen={props.showDialog}
        onRequestClose={props.onClose}
        overlayClassName="ca-delete-group-modal__overlay"
        className="ca-delete-group-modal__content"
        role="dialog"
      >
        <div>
          <p>{I18n.t("confirm", { scope: I18N_SCOPE })}</p>
          <p>
            <strong>{props.awsAccount.name}</strong>
          </p>
          {props.errorMessage !== "" && (
            <p>
              <label className="error">{props.errorMessage}</label>
            </p>
          )}
        </div>
        <div className="ca-delete-group-modal__content-buttons">
          <button type="button" className="btn btn-cancel btn-negative" onClick={props.onCancel}>
            <span className="fa fa-ban marginR5"></span>
            {I18n.t("cancel", { scope: I18N_SCOPE })}
          </button>
          <button
            className="btn btn-danger btn-delete"
            disabled={props.isTransmitting}
            onClick={props.onDelete}
            type="button"
          >
            <span className="fa fa-unlock-keyhole marginR5"></span>
            {I18n.t("delete", { scope: I18N_SCOPE })}
          </button>
        </div>
      </ReactModal>
    </div>
  );
};
