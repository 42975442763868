import EventEmitter from "events";
import PropTypes from "prop-types";
import React from "react";
import ReactModal from "react-modal";

const $ = window.jQuery;
const I18n = window.I18n;

/**
 * ユーザーをグループから除外するボタン
 *
 * @return {ReactElement}
 */
export default class RemoveUserButton extends React.Component {
  /**
   * プロパティ定義を返します
   *
   * @property {object} group グループ情報オブジェクト
   * @property {object} reloadEmitter グループ一覧をリロードするイベントを発火するオブジェクト。
   * @property {number} userId 除外対象のユーザーID
   * @property {number} userName 除外対象のユーザー名
   */
  static get propTypes() {
    return {
      group: PropTypes.object.isRequired,
      reloadEmitter: PropTypes.object.isRequired,
      userId: PropTypes.number.isRequired,
      userName: PropTypes.string.isRequired
    };
  }

  /**
   * コンストラクタ
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.state = {
      showRemoveUserDialog: false
    };

    this.emitter = new EventEmitter();
    this.emitter.on("closeDialog", this.handleCloseDialog.bind(this));
    this.emitter.on("openDialog", this.handleOpenDialog.bind(this));
  }

  /**
   * ユーザー除外ダイアログを閉じるハンドラ
   */
  handleCloseDialog() {
    this.setState({ showRemoveUserDialog: false });
  }

  /**
   * ユーザー除外ダイアログを開くハンドラ
   */
  handleOpenDialog() {
    this.setState({ showRemoveUserDialog: true });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return (
      <div>
        <button
          className="btn btn-default"
          type="button"
          onClick={() => {
            this.emitter.emit("openDialog");
          }}
        >
          <span className="font-12px">{I18n.t("javascript.user_groups.remove_user")}</span>
        </button>
        <RemoveUserDialog
          emitter={this.emitter}
          group={this.props.group}
          reloadEmitter={this.props.reloadEmitter}
          userId={this.props.userId}
          userName={this.props.userName}
          showRemoveUserDialog={this.state.showRemoveUserDialog}
        />
      </div>
    );
  }
}

/**
 * ユーザーをグループから除外する時に表示するダイアログ
 */
class RemoveUserDialog extends React.Component {
  /**
   * プロパティ定義を返します
   *
   * @property {object} emitter グループの開閉を制御するイベントエミッター
   * @property {object} group グループ情報オブジェクト
   * @property {object} reloadEmitter グループ一覧をリロードするイベントを発火するオブジェクト。
   * @property {number} userId 除外対象のユーザーID
   * @property {number} userName 除外対象のユーザー名
   * @property {bool} emitter グループの開閉フラグ
   */
  static get propTypes() {
    return {
      emitter: PropTypes.object.isRequired,
      group: PropTypes.object.isRequired,
      reloadEmitter: PropTypes.object.isRequired,
      userId: PropTypes.number.isRequired,
      userName: PropTypes.string.isRequired,
      showRemoveUserDialog: PropTypes.bool.isRequired
    };
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);

    this.removeUser = this.removeUser.bind(this);

    ReactModal.setAppElement("body");
  }

  /**
   * グループからユーザーを除外します
   * サーバーにリクエストを送り、その後ダイアログのクローズとグループの更新を行います
   */
  removeUser() {
    const url = `/groups/${this.props.group.id}/user_assignments/${this.props.userId}`;
    $.ajax({
      url: url,
      method: "DELETE",
      dataType: "json"
    }).then(() => {
      this.props.emitter.emit("closeDialog");
      this.props.reloadEmitter.emit("reload");
    });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return (
      <ReactModal
        isOpen={this.props.showRemoveUserDialog}
        onRequestClose={() => {
          this.props.emitter.emit("closeDialog");
        }}
        overlayClassName="ca-remove-user-modal__overlay"
        className="ca-remove-user-modal__content"
        role="dialog"
      >
        <div>
          <p>{I18n.t("javascript.user_groups.remove_user_confirm", { group_name: this.props.group.name })}</p>
          <p>
            <strong>{this.props.userName}</strong>
          </p>
        </div>

        <div className="ca-remove-user-modal__content-buttons">
          <button
            type="button"
            className="btn btn-cancel btn-negative"
            onClick={() => {
              this.props.emitter.emit("closeDialog");
            }}
          >
            <span className="fa fa-ban marginR5" />Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger btn-delete"
            onClick={this.removeUser}
          >
            <span className="fa fa-trash-o marginR5" />Remove
          </button>
        </div>
      </ReactModal>
    );
  }
}
