import React from "react";
import PropTypes from "prop-types";

import Constants from "./Constants.js";

/**
 * ソート可能なテーブルヘッダセルコンポーネント
 *
 * プロパティ
 * active       - 現在選択中のカラムかどうか。
 * callback     - リンクがクリックされた際に呼び出される関数。
 *                引数にカラムの識別子が渡される。
 * className    - リンクのキャプションに設定するclass属性値。オプション。
 * classNameForHeaderCell - THタグのclass属性値に設定する文字列。オプション。
 * subClassName - 2つ目のリンクのキャプションに設定するclass属性値。オプション。
 * column       - カラムの識別子。callbackの引数として使われる。
 * direction    - 現在のソート方向。
 *                Constants.DIRECTION.ASC または Constants.DIRECTION.DESC が設定される。
 * icon         - ヘッダーカラムの表示をアイコンにする場合のアイコン名
 * text         - リンクのキャプションとなる文字列。
 * subText      - リンクのキャプションとなる2つ目の文字列。オプション。
 * caption      - リンクの下部に灰色で表示する文字列。オプション。
 *
 * subText と subClassName は2つのキャプションで1つのリンクを構成するケースに
 * 対応するためのものです。
 *
 * Examples
 *
 *   <SortableTableHeaderCell column="name"
 *                            active={true}
 *                            callback={this.handleClick}
 *                            direction={Constants.DIRECTION.ASC}
 *                            text="名前" />
 *
 * このコンポーネントの描画結果はTH要素となります。
 * active と direction の値に応じたアイコンが表示され、リンクがクリックされると
 * カラムの識別子を引数に callback が呼ばれます。
 */
class SortableTableHeaderCell extends React.Component {
  /**
   * propTypes
   * @property {}
   */
  static get propTypes() {
    return {
      active: PropTypes.bool.isRequired,
      callback: PropTypes.func.isRequired,
      className: PropTypes.string,
      classNameForHeaderCell: PropTypes.string,
      subClassName: PropTypes.string,
      column: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired,
      icon: PropTypes.string,
      text: PropTypes.string.isRequired,
      caption: PropTypes.string,
      subText: PropTypes.string,
      colspan: PropTypes.number,
      textAlignCenter: PropTypes.bool,
    };
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    let iconClasses = "marginL5 sort-icon fa";
    if (this.props.active && this.isAscending()) {
      iconClasses += " fa-sort-up";
    } else if (this.props.active && !this.isAscending()) {
      iconClasses += " fa-sort-down";
    } else if (!this.props.active) {
      iconClasses += " fa fa-sort";
    }

    const captionBlock = this.props.caption && (
      <div className="ca-table-header-cell__text__caption">{this.props.caption}</div>
    );

    const headerClasses = this.props.classNameForHeaderCell || "col-name sort";
    const headerIconClass = "fa " + this.props.icon;
    const colspan = this.props.colspan || 1;
    const cellClass = this.props.textAlignCenter ? "ca-table-header-cell__text-center" : "ca-table-header-cell__text";

    if (this.props.icon) {
      return (
        <th className={headerClasses} onClick={this.handleClick} colSpan={colspan}>
          <span className={this.props.className}>
            <span
              className="ca-group-users-header__icon"
              data-placement="top"
              data-toggle="tooltip"
              title={this.props.text}
            >
              <i className={headerIconClass} aria-hidden="true" />
            </span>
          </span>
          <span className={iconClasses}></span>
        </th>
      );
    } else if (this.props.subText) {
      return (
        <th className={headerClasses} onClick={this.handleClick} colSpan={colspan}>
          <div className={cellClass}>
            <div>
              <span className={this.props.className}>{this.props.text}</span>
              <span className="fa fa-arrow-circle-right fa-sm"></span>
              <span className={this.props.subClassName}>
                <span>{this.props.subText}</span>
              </span>
            </div>
            <div>
              <span className={iconClasses}></span>
            </div>
          </div>
        </th>
      );
    } else {
      return (
        <th className={headerClasses} onClick={this.handleClick} colSpan={colspan}>
          <div className={cellClass}>
            <div>
              <span className={this.props.className}>{this.props.text}</span>
              {captionBlock}
            </div>
            <div>
              <span className={iconClasses}></span>
            </div>
          </div>
        </th>
      );
    }
  }

  /**
   * テーブルヘッダセルがクリックされた際に呼び出されるイベントハンドラ。
   * @param {SyntheticEvent} event
   */
  handleClick(event) {
    event.preventDefault();
    this.props.callback(this.props.column);
  }

  /**
   * ソート方向が昇順かどうかを返します。
   * @return {bool}
   */
  isAscending() {
    return this.props.direction == Constants.DIRECTION.ASC;
  }
}

export default SortableTableHeaderCell;
