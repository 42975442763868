import React from "react";

import PropTypes from "prop-types";

const $ = jQuery;
const _ = window._;

export default class GroupColorSelector extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {string} selectedColor 選択された色
   */
  static get propTypes() {
    return({
      selectedColor: PropTypes.string
    });
  }

  /**
   * コンストラクタ
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    let colors = {
      "#aa0441": { selected: false },
      "#aa671d": { selected: false },
      "#4c7a34": { selected: false },
      "#005bb1": { selected: false },
      "#740493": { selected: false },
      "#232323": { selected: false },
      "#e93578": { selected: false },
      "#ff6624": { selected: false },
      "#72bb53": { selected: false },
      "#00b1f8": { selected: false },
      "#a658ef": { selected: false },
      "#606060": { selected: false },
      "#ff96bb": { selected: false },
      "#ffa834": { selected: false },
      "#aedd94": { selected: false },
      "#7adbf6": { selected: false },
      "#b892fc": { selected: false },
      "#c0c0c0": { selected: false },
      "#ffd7f7": { selected: false },
      "#ffe3ae": { selected: false },
      "#e2ee79": { selected: false },
      "#c9f1fd": { selected: false },
      "#dacafb": { selected: false },
      "#ebebeb": { selected: false }
    };
    _.each(colors, (v, k) => {
      if(k === this.props.selectedColor) {
        v.selected = true;
        return;
      }
    });

    this.state = colors;
  }

  /**
   * 選択された色を切り替える
   *
   * @property {string} key カラーコード
   */
  toggleSelected(key) {
    let colors = this.state;
    _.each(colors, (v) => {
      v.selected = false;
    });
    colors[key] = { selected: true };
    this.setState(colors);
  }

  /**
   * 選択された色を返す
   *
   * @return {string} 選択された色のカラーコード
   */
  selectedColor() {
    let color = "";
    _.each(this.state, (v, k)=>{
      if(v.selected === true) {
        color = k;
        return;
      }
    });
    return color;
  }

  /**
   * カラーパレットを閉じる
   */
  closeColorSelector() {
    $("#ca-group-color-selector").collapse("hide");
  }

  /**
   * 色を選択のためにクリックした時のハンドラ
   * 選択された色の切り替えと、カラーパレットを閉じる処理を行う
   *
   * @property {string} color カラーコード
   */
  handleOnClick(color) {
    this.toggleSelected(color);
    this.closeColorSelector();
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const colors = _.map(this.state, ((v, color) => {
      let colorClass = "fa fa-circle";
      if(v.selected) {
        colorClass = "fa fa-check-circle";
      }
      return(<i key={color} className={colorClass} aria-hidden="true" style={{color: color}} onClick={()=>{ this.handleOnClick(color); }}/>);
    }));

    return(
      <React.StrictMode>
        <div className="ca-group-color-selector">
          <button type="button" className="form-control inline-block ca-group-color-selector__btn"
                  data-toggle="collapse" data-target="#ca-group-color-selector" aria-controls="ca-group-color-selector">
            <span className="fa fa-briefcase" aria-hidden="true" style={{color: this.selectedColor()}}></span>
          </button>
          <div className="collapse ca-group-color-selector__picker" id="ca-group-color-selector">
            <div className="well ca-group-color-selector__picker__inner">
              <div>{colors.slice(0, 6)}</div>
              <div>{colors.slice(6, 12)}</div>
              <div>{colors.slice(12, 18)}</div>
              <div>{colors.slice(18)}</div>
            </div>
          </div>
          <input type="hidden" value={this.selectedColor()} name="group[color]"/>
        </div>
      </React.StrictMode>
    );
  }
}
