import React from "react";
import { TriggerJob, WebhookJob } from "./types";
import { OldFirstJobMigrationMessage } from "./OldFirstJobMigrationMessage";

const I18n = window.I18n; // i18n-js

/**
 * HTTPトリガーの詳細を表示します。
 * @public
 */
export const HTTPTriggerDetails = (job: WebhookJob): JSX.Element => {
  const location = window.location;
  const rootURL =
    location.origin ?? location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");

  return (
    <div className="ca-workflow-trigger">
      <div className="ca-workflow-trigger__icon-area">
        <div className="ca-workflow-trigger__icon">
          <i className="fa-duotone fa-solid fa-bullseye-pointer"></i>
        </div>
        {I18n.t("common.rule_types_abb.webhook")}
        <br />
        {I18n.t("activerecord.attributes.trigger_job.rule_type")}
      </div>
      <div className="ca-workflow-trigger__info-area">
        {/* TODO: 機能フラグ v27_job_workflow_improvement が不要になったら OldFirstJobMigrationMessage を削除する */}
        <OldFirstJobMigrationMessage job={job as TriggerJob} />
        <div className="ca-workflow-trigger__item">
          <div className="ca-workflow-trigger__label">URL</div>
          <div className="ca-workflow-trigger__data">{`${rootURL}/trigger/${job.token}`}</div>
        </div>

        <div className="ca-workflow-trigger__item">
          <div className="ca-workflow-trigger__label">{I18n.t("activerecord.attributes.trigger_job.access_token")}</div>
          <div className="ca-workflow-trigger__data">{job.access_token}</div>
        </div>

        <div className="ca-workflow-trigger__webhook_hint marginT5">
          <span className="marginR10">{I18n.t("javascript.job_workflow_form.webhook_edit_hint")}</span>
          <a
            className="ca-workflow-trigger__webhook_hint__link"
            href={job.show_url}
            rel="noopener noreferrer"
            target="_blank"
          >
            <i aria-hidden className="fa fa-external-link"></i>{" "}
            <span className="">{I18n.t("javascript.job_workflow_form.link_to_job")}</span>
          </a>
        </div>
      </div>
    </div>
  );
};
