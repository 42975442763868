import EventEmitter from 'events';
import React from 'react';
import PropTypes from 'prop-types';

import TFASettings from './TFASettings.jsx';

const jQuery = window.jQuery;

/**
 * 二要素認証設定コンテナコンポーネント
 *
 * 二要素認証の現在の設定状況の表示と有効化・無効化処理に関するビジネスロジックを含みます。
 * UIの表示に関しては内包する子コンポーネントが責務を持ちます。
 */
export default class TFASettingsContainer extends React.Component {
  /**
   * propTypes
   *
   * @return {object}
   * @property {bool} enabled 二要素認証が有効化されているかどうかの初期状態
   * @property {string} qrCodeUrl 認証コードのURL
   * @property {string} resourceUrl 二要素認証の設定を操作するためのURL
   * @property {?bool} simple UIをシンプルにするかどうか(組織ユーザー用の場合にtrue、デフォルトはfalse)
   */
  static get propTypes() {
    return({
      enabled: PropTypes.bool.isRequired,
      otpSecretKeyUrl: PropTypes.string.isRequired,
      qrCodeUrl: PropTypes.string.isRequired,
      resourceUrl: PropTypes.string.isRequired,
      simple: PropTypes.bool,
    });
  }

  /**
   * デフォルトのプロパティを返します。
   *
   * @public
   * @return {Object}
   * @property {bool} simple UIをシンプルにするかどうか(組織ユーザー用の場合にtrue)
   */
  static get defaultProps() {
    return({
      simple: false,
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);

    this.state = {
      // 二要素認証が有効化されているかどうか
      enabled: this.props.enabled,
      // エラーが発生したかどうか
      error: false,
      // OTPナンバー
      otpSecretKey: null,
      // QRコードをHTMLで表現した文字列(QRコードのロード中はnullとなる)
      qrCodeHtml: null,
      // ダイアログが表示状態かどうか
      showDialog: false,
    };

    this.emitter = new EventEmitter();
    this.emitter.on('openDialog', this.handleOpenDialog.bind(this));
    this.emitter.on('closeDialog', this.handleCloseDialog.bind(this));
    this.emitter.on('fetchQrCode', this.handleFetchQrCode.bind(this));
    this.emitter.on('enableTFA', this.handleEnable.bind(this));
    this.emitter.on('disableTFA', this.handleDisable.bind(this));
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <React.StrictMode>
        <TFASettings emitter={this.emitter} simple={this.props.simple} {...this.state}/>
      </React.StrictMode>
    );
  }

  /**
   * ダイアログを非表示状態にします。
   *
   * @access {private}
   */
  handleCloseDialog() {
    this.setState({ showDialog: false });
  }

  /**
   * 二要素認証を無効化します。
   *
   * @access {private}
   */
  handleDisable() {
    this.requestDisable().done(() => {
      this.setState({
        enabled: false,
        error: false,
        showDialog: false,
      });
    }).fail(() => {
      this.setState({ error: true });
    });
  }

  /**
   * 二要素認証を有効化します。
   *
   * @access {private}
   * @param {string} authCode ユーザーが入力した確認コード
   */
  handleEnable(authCode) {
    this.requestEnable(authCode).done(() => {
      this.setState({
        enabled: true,
        error: false,
        qrCodeHtml: null,
        showDialog: false,
      });
    }).fail(() => {
      this.setState({ error: true });
    });
  }

  /**
   * 新しいQRコードとOTPシークレットキーを取得します。
   *
   * @access {private}
   */
  handleFetchQrCode() {
    // 現在のQRコード・シークレットキーをステートから削除した後で新しい情報をサーバーから取得する
    this.setState({
      qrCodeHtml: null,
      otpSecretKey: null,
    }, () => {
      this.requestQrCode().done((data) => {
        this.setState({ qrCodeHtml: data });

        // QRコード生成時にシークレットキーが生成されるため、QRコードリクエスト成功後に実行する
        this.requestOtpSecretKey().done((data) => {
          this.setState({ otpSecretKey: data.otp_secret_key });
        });
      });
    });
  }

  /**
   * ダイアログを表示状態にします。
   *
   * @access {private}
   */
  handleOpenDialog() {
    this.setState({
      showDialog: true,
      error: false,
    });
  }

  /**
   * 二要素認証を無効化するためのリクエストを作成して返します。
   *
   * @access {private}
   * @return {object} jqXHR
   */
  requestDisable() {
    return jQuery.ajax({
      url: this.props.resourceUrl,
      method: 'DELETE',
    });
  }

  /**
   * 二要素認証を有効化するためのリクエストを作成して返します。
   *
   * @access {private}
   * @param {string} authCode 確認コード
   * @return {object} jqXHR
   */
  requestEnable(authCode) {
    return jQuery.ajax({
      url: this.props.resourceUrl,
      data: { auth_code: authCode },
      method: 'POST',
    });
  }

  /**
   * サーバーからQRコードを取得するリクエストを作成して返します。
   *
   * @access {private}
   * @return {object} jqXHR
   */
  requestQrCode() {
    return jQuery.ajax({
      url: this.props.qrCodeUrl,
      dataType: 'html',
      method: 'GET',
    });
  }

  /**
   * サーバーからシークレットキーを取得するリクエストを作成して返します。
   *
   * @access {private}
   * @return {object} jqXHR
   */
  requestOtpSecretKey() {
    return jQuery.ajax({
      url: this.props.otpSecretKeyUrl,
      dataType: 'json',
      method: 'GET',
    });
  }
}
