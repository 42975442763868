import EventEmitter from "events";
import React from "react";
import PropTypes from "prop-types";

import EditUserDialog from "./EditUserDialog.jsx";
import withBootstrapTooltip from "../../withBootstrapTooltip.js";

const I18n = window.I18n;

/**
 * リンクになっている編集ボタン。ボタンの右側に他のボタンがあることを期待しています。
 *
 * @return {ReactElement}
 */
class EditUserButton extends React.Component {
  /**
   * プロパティ定義を返します
   *
   * @public
   * @property {boolean} dateLabelFeatureEnabled 組織に対してカレンダー管理機能の機能フラグが有効かどうか
   * @property {number} groupId メンバーを追加するグループのID
   * @property {boolean} inventoryAvailable 組織がインベントリ機能を利用可能かどうか
   * @property {boolean} policySetsAvailable 組織が構成レビュー機能を利用可能かどうか
   * @property {object} reloadEmitter グループ一覧をリロードするイベントを発火するオブジェクト。EditUserDialogで利用します
   * @tooltipRef {?function(element: DOMNode)} tooltipRef
   * @property {object} user ユーザー情報が入ったオブジェクト
   */
  static get propTypes() {
    return {
      dateLabelFeatureEnabled: PropTypes.bool.isRequired,
      groupId: PropTypes.number.isRequired,
      inventoryAvailable: PropTypes.bool.isRequired,
      policySetsAvailable: PropTypes.bool.isRequired,
      reloadEmitter: PropTypes.object.isRequired,
      tooltipRef: PropTypes.func,
      user: PropTypes.object.isRequired
    };
  }

  /**
   * コンストラクタ
   */
  constructor(props) {
    super(props);

    this.state = {
      showEditUserDialog: false
    };

    this.emitter = new EventEmitter();
    this.emitter.on("closeDialog", this.handleCloseDialog.bind(this));
    this.emitter.on("openDialog", this.handleOpenDialog.bind(this));
  }

  handleCloseDialog() {
    this.setState({ showEditUserDialog: false });
  }

  handleOpenDialog() {
    this.setState({ showEditUserDialog: true });
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return (
      <div className="marginR5" ref={this.props.tooltipRef}>
        <button
          className="btn"
          type="button"
          data-toggle="tooltip"
          data-placement="bottom"
          title={I18n.t("edit", { scope: "javascript.group_user" })}
          onClick={() => {
            this.emitter.emit("openDialog");
          }}
        >
          <span className="fa fa-pencil" />
        </button>
        <EditUserDialog
          dateLabelFeatureEnabled={this.props.dateLabelFeatureEnabled}
          emitter={this.emitter}
          groupId={this.props.groupId}
          inventoryAvailable={this.props.inventoryAvailable}
          policySetsAvailable={this.props.policySetsAvailable}
          reloadEmitter={this.props.reloadEmitter}
          showEditUserDialog={this.state.showEditUserDialog}
          user={this.props.user}
        />
      </div>
    );
  }
}

export default withBootstrapTooltip(EditUserButton);
