import React from "react";
import PropTypes from "prop-types";
import EventEmitter from "events";

import Constants from "../../../Constants.js";

import UserGroupsTable from "./UserGroupsTable.jsx";

const $ = window.jQuery;
const _ = window._; // Underscore.js
const I18n = window.I18n;

/**
 * ユーザーグループテーブルを含むコンテナコンポーネント
 *
 * このコンポーネントはマウント時およびソート条件が変更された場合のみ
 * サーバーからユーザーが所属するすべてのグループのJSONを取得します。
 */
export default class UserGroupsTableContainer extends React.Component {
  /**
   * プロパティ定義を返します
   *
   * @public
   * @property {bool} calendarFeatureEnabled カレンダー機能が有効かどうか
   * @property {boolean} policySetsAvailable 組織が構成レビュー機能を利用可能かどうか
   * @property {number} userId 対象となるユーザーのID
   * @property {string} userName ユーザー名
   */
  static get propTypes() {
    return {
      calendarFeatureEnabled: PropTypes.bool.isRequired,
      policySetsAvailable: PropTypes.bool.isRequired,
      userId: PropTypes.number.isRequired,
      userName: PropTypes.string.isRequired,
      userRemovable: PropTypes.bool,
    };
  }

  /**
   * デフォルトのプロパティ値を返します。
   * React内部から呼び出されます。
   *
   * @return {object}
   */
  static get defaultProps() {
    return {
      userRemovable: true
    };
  }

  /**
   * オブジェクトを初期化します
   */
  constructor(props) {
    super(props);

    this.emitter = new EventEmitter();
    this.emitter.on("reload", () => { this.loadUserGroups(this.props.userId); });

    this.state = {
      errorMessage: null,
      groups: [],
      totalItems: 0
    };

    // 設定保存処理を最大で1秒間に1回しか呼ばないようにしたバージョンを用意する
    this.throttledSaveSettingsFunc = _.throttle(this.saveSettings, 1000);
  }

  /**
   * コンポーネントがマウントされた際の処理を行います。
   */
  componentDidMount() {
    this.loadUserGroups(this.props.userId);
  }

  /**
   * コンポーネントが更新された際の処理を行います。
   */
  componentDidUpdate() {
    // BootstrapのTooltipを有効化
    $('.ca-user-groups-table').find('[data-toggle="tooltip"]').tooltip();
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return (
      <React.StrictMode>
        <React.Fragment>
          <UserGroupsTable
            calendarFeatureEnabled={this.props.calendarFeatureEnabled}
            errorMessage={this.state.errorMessage}
            policySetsAvailable={this.props.policySetsAvailable}
            reloadEmitter={this.emitter}
            sortColumn={this.state.sortColumn}
            userId={this.props.userId}
            userName={this.props.userName}
            groups={this.state.groups}
            userRemovable={this.props.userRemovable}
          />
        </React.Fragment>
      </React.StrictMode>
    );
  }

  /**
   * ユーザーの所属するグループを取得します。
   */
  loadUserGroups(userId) {
    // グループのJSONを取得するためのURL
    const jsonUrl = `/organization/users/${userId}/groups.json`;

    $.ajax({
      type: "GET",
      url: jsonUrl
    })
      .done(data => {
        const requiredKeys = ["total", "groups"];
        const invalidFormat = requiredKeys.find(key => {
          data[key] == undefined;
        });

        if (invalidFormat) {
          this.setState({
            errorMessage: I18n.t(
              "javascript.user_groups_table.invalid_server_response"
            ),
            groups: [],
            totalItems: 0
          });
          return;
        }

        let newState = {
          errorMessage: null,
          groups: data.groups,
          totalItems: data.total
        };

        this.setState(newState);
      })
      .fail(() => {
        this.setState({
          groups: [],
          errorMessage: Constants.DATA_LOAD_ERROR_MESSAGE,
          totalItems: 0
        });
      });
  }
}
