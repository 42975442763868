import React, { useState } from "react";

import { CredentialsJsonInputField } from "../google_cloud_accounts/CredentialsJsonInputField";
import { NameInputField } from "../google_cloud_accounts/NameInputField";
import ManualLink from "../ManualLink.jsx";

import { CreateGroupFormDataWithGoogleServiceAccount, ErrorMessages } from "./types";

import { ProviderAccountTypeSelectorForGroup } from "./ProviderAccountTypeSelectorForGroup";
import { ErrorArea2 } from "./ErrorArea2";
import { GroupColorSelector2 } from "./GroupColorSelector2";
import { GroupNameInputField } from "./GroupNameInputField";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.new_group_with_google_service_account";

/**
 * フォームの各フィールドに対するエラーメッセージを含むオブジェクトの型
 */
type FormErrors = {
  base: ErrorMessages;
  googleCloudAccountName: ErrorMessages;
  googleCloudAccounts: ErrorMessages;
  googleServiceAccountCredentialsJson: ErrorMessages;
  groupColor: ErrorMessages;
  groupName: ErrorMessages;
};

/**
 * エラーメッセージ用ステートの初期状態およびリセット後の状態
 */
const emptyErrors: FormErrors = {
  base: [],
  googleCloudAccountName: [],
  googleCloudAccounts: [],
  googleServiceAccountCredentialsJson: [],
  groupColor: [],
  groupName: [],
};

/**
 * グループ新規作成フォーム (Googleサービスアカウント方式)
 *
 * 以下の責務を持ちます。
 *
 * - グループ新規作成HTMLフォーム(Googleサービスアカウント方式用)の表示
 * - グループ作成リクエストのサーバーサイドへの送信
 *   - グループ作成成功時のリダイレクト
 *   - グループ作成失敗時のエラーメッセージ表示
 *
 */
export const NewGroupWithGoogleServiceAccountForm: React.FC<{
  group: {
    color: string;
    name: string;
  };
}> = (props) => {
  // グループのカラー
  const [groupColor, setGroupColor] = useState(props.group.color || "#aa0441");
  // グループの名前
  const [groupName, setGroupName] = useState(props.group.name || "");
  // Google Cloudアカウントのアクセスキー
  const [googleServiceAccountCredentialsJson, setGoogleServiceAccountCredentialsJson] = useState("");
  // Google Cloudアカウント名
  const [googleCloudAccountName, setGoogleCloudAccountName] = useState("");
  // 各フィールド毎のエラーメッセージ
  const [errors, setErrors] = useState(Object.assign({}, emptyErrors));
  // フォームを送信中かどうか
  const [isTransmitting, setIsTransmitting] = useState(false);

  /**
   * フォームをXHRで送信してグループの新規作成を行います。
   *
   * 処理に成功した場合は、サーバーから指示されたURLにリダイレクトします。
   * 処理に失敗した場合は、バリデーションエラーまたはエラーメッセージを設定します。
   */
  const handleSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();

    setErrors(emptyErrors);
    setIsTransmitting(true);

    const data: CreateGroupFormDataWithGoogleServiceAccount = {
      // ここで記述している各リクエストパラメーター名は、サーバー側Groupモデルの
      // accepts_nested_attributes_for設定に準じたもの。
      group: {
        name: groupName,
        color: groupColor,
        google_cloud_accounts_attributes: [
          {
            name: googleCloudAccountName,
            google_service_account_attributes: {
              credentials_json: googleServiceAccountCredentialsJson,
            },
          },
        ],
      },
    };

    jQuery
      .ajax({
        url: "/groups",
        method: "POST",
        dataType: "json",
        data: data,
      })
      .done((data) => {
        window.location.href = data.url;
      })
      .fail((jqXHR, textStatus) => {
        const newErrors = Object.assign({}, emptyErrors);

        if (jqXHR.status == 422 && Object.prototype.hasOwnProperty.call(jqXHR.responseJSON, "errors")) {
          // バリデーションエラーかつレスポンスJSONに errors プロパティが存在する場合
          const rawErrors = jqXHR.responseJSON.errors;
          // レスポンスJSONに含まれるエラーメッセージのキーはGroupモデルの定義に
          // 準じたキーになっているため、個別に読み替えて変数に格納する。
          newErrors.base = rawErrors.base || [];
          newErrors.googleServiceAccountCredentialsJson =
            rawErrors["google_cloud_accounts.google_service_account.credentials"] || [];
          newErrors.googleCloudAccountName = rawErrors["google_cloud_accounts.name"] || [];
          newErrors.googleCloudAccounts = rawErrors.google_cloud_accounts || [];
          newErrors.groupColor = rawErrors.color || [];
          newErrors.groupName = rawErrors.name || [];
        } else {
          // 500エラーなどの場合
          newErrors.base = [I18n.t("error", { message: textStatus, scope: I18N_SCOPE })];
        }
        setErrors(newErrors);
        setIsTransmitting(false);
      });
  };

  /**
   * フォームが送信可能かどうかを返します。
   */
  const isSubmittable = (): boolean => {
    return (
      groupName != "" && groupColor != "" && googleServiceAccountCredentialsJson != "" && googleCloudAccountName != ""
    );
  };

  return (
    <React.StrictMode>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="panel clearfix padding30">
          <h2 className="marginB0 font-18px">
            <span className="fa fa-drivers-license-o fa-lg"></span>
            {I18n.t("group_heading", { scope: I18N_SCOPE })}
          </h2>

          <div className="ca-group-form">
            <table className="table vertical-middle ca-group-form__table">
              <tbody>
                <tr>
                  <th className="ca-group-form__table-header">
                    <label htmlFor="NewGroupWithGoogleServiceAccountForm-groupName" style={{ fontWeight: "bold" }}>
                      {I18n.t("activerecord.attributes.group.name")}
                      <span className="required">*</span>
                    </label>
                  </th>
                  <td>
                    <GroupNameInputField
                      defaultValue={props.group.name}
                      id="NewGroupWithGoogleServiceAccountForm-groupName"
                      onAvailabilityChecked={(isAvailable): void => {
                        // グループ名の利用可否に応じてグループ名のエラーメッセージを設定する
                        const newErrors = Object.assign({}, errors);
                        if (isAvailable) {
                          newErrors.groupName = [];
                        } else {
                          newErrors.groupName = [I18n.t("group_name_unavailable", { scope: I18N_SCOPE })];
                        }
                        setErrors(newErrors);
                      }}
                      onChange={(value): void => setGroupName(value)}
                    />
                    <ErrorArea2 errors={errors.groupName} />
                  </td>
                </tr>
                <tr>
                  <th className="ca-group-form__table-header">
                    {I18n.t("activerecord.attributes.group.color")}
                    <span className="required">*</span>
                  </th>
                  <td>
                    <GroupColorSelector2
                      onChange={(color): void => {
                        setGroupColor(color);
                      }}
                      selectedColor={groupColor}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="ca-group-form-hr"></div>

          <h2 className="marginB0 font-18px">
            <span className="fa fa-key fa-lg"></span>
            {I18n.t("google_cloud_account_heading", { scope: I18N_SCOPE })}
          </h2>

          <div className="ca-group-form">
            <div className="marginB20 ca-callout ca-callout--warning">
              <div
                dangerouslySetInnerHTML={{
                  __html: I18n.t("google_cloud_account_description_html", { scope: I18N_SCOPE }),
                }}
              ></div>
              <div className="marginT10">
                <ManualLink
                  linkText={I18n.t("manual.text", { scope: I18N_SCOPE })}
                  url={I18n.t("manual.url", { scope: I18N_SCOPE })}
                />
              </div>
            </div>

            <div className="marginB20">
              <ProviderAccountTypeSelectorForGroup
                defaultValue="google_service_account"
                groupColor={groupColor}
                groupName={groupName}
              />
            </div>

            <div className="ca-group__aws-account-form">
              <div className="ca-group__aws-account-form__row">
                <div className="ca-group__aws-account-form__left-col">
                  <div className="ca-group__aws-account-form__heading">{I18n.t("step1", { scope: I18N_SCOPE })}</div>
                </div>
                <div className="ca-group__aws-account-form__right-col" style={{ maxWidth: "400px" }}>
                  <p className="ca-group__aws-account-form__prompt">
                    {I18n.t("credentials_prompt", { scope: I18N_SCOPE })}
                  </p>
                  <CredentialsJsonInputField
                    disabled={false}
                    errors={errors.googleServiceAccountCredentialsJson}
                    name="credentials_json"
                    onChange={(value): void => {
                      setGoogleServiceAccountCredentialsJson(value);
                    }}
                    placeholder=""
                    required={true}
                    useError2={true}
                    value={googleServiceAccountCredentialsJson}
                  />
                  <NameInputField
                    disabled={false}
                    errors={errors.googleCloudAccountName}
                    onChange={(value): void => {
                      setGoogleCloudAccountName(value);
                    }}
                    useError2={true}
                    value={googleCloudAccountName}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="ca-btn-block marginT10">
            <div className="ca-btn-block__center">
              <SubmitButton
                disabled={!isSubmittable()}
                label={I18n.t("submit", { scope: I18N_SCOPE })}
                pending={isTransmitting}
                pendingLabel={I18n.t("pending", { scope: I18N_SCOPE })}
              />
              <ErrorArea2 errors={errors.base} />
              <ErrorArea2 errors={errors.googleCloudAccounts} />
            </div>
          </div>
        </div>
      </form>
    </React.StrictMode>
  );
};

// 以下のコンポーネントは app/javascript/src/google_cloud_accounts/SubmitButton.tsx の
// コンポーネントにアイコン表示を加えるために、定義を複製してHTML部分を改変したもの。

/**
 * 送信ボタン
 *
 * 状態に応じて送信ボタンまたは送信中ボタン(操作不可)をBUTTON要素で表示します。
 */
const SubmitButton: React.FC<{
  /** 無効かどうか */
  disabled: boolean;
  /** ボタンのラベル */
  label: string;
  /** 送信中状態にするかどうか */
  pending: boolean;
  /** 送信中状態の場合のボタンのラベル */
  pendingLabel: string;
}> = ({ disabled = false, pending = false, label, pendingLabel }) => {
  if (pending) {
    return (
      <button className={`btn btn-success btn-lg btn-continue`} disabled={true} type="submit">
        {pendingLabel}
      </button>
    );
  }

  return (
    <button
      className={`btn ${disabled ? "btn-default" : "btn-primary"} btn-lg btn-continue`}
      disabled={disabled}
      type="submit"
    >
      <span className="fa fa-check-circle fa-lg"></span>&nbsp;
      {label}
    </button>
  );
};
