import React, { SyntheticEvent, useState } from "react";

type option = {
  name: string;
  value: string;
};

type workflowTypeOption = option;
type ruleTypeOption = option;

type column = "workflow_type" | "rule_type";

type selectedValueChange = {
  column: column;
  value: string;
};

/**
 * ワークフロー種別とルールタイプのセレクトボックスを表示するコンポーネント
 * ワークフロー種別が「後続ジョブ」の場合、ルールタイプのセレクトボックスは無効化（disable）される
 */
export const WorkflowAndTriggerSelector: React.FC<{
  workflowTypeValue: string;
  ruleTypeValue: string;
  workflowTypeOptions: workflowTypeOption[];
  ruleTypeOptions: ruleTypeOption[];
}> = (props) => {
  const [selectedValues, setSelectedValues] = useState({
    workflowType: props.workflowTypeValue,
    ruleType: props.ruleTypeValue,
  });

  const isRuleTypeDisabled = selectedValues.workflowType === "following_job";

  const I18n = window.I18n; // I18n-js
  const i18nScope = { scope: "javascript.job_table.detailed_search_input" };
  const allTypeText = I18n.t("all_type", i18nScope);
  const allTriggerText = I18n.t("all_trigger", i18nScope);

  /**
   * ワークフロー種別が変更された場合の処理
   */
  const handleWorkflowTypeChange = ({ value }: selectedValueChange): void => {
    const event = new CustomEvent("kanrinmaru:workflow_type_changed", {
      detail: { workflowType: value },
    });
    document.dispatchEvent(event);

    // ワークフロー種別が変更された場合、ルールタイプの選択をリセットする
    setSelectedValues({ workflowType: value, ruleType: "" });
  };

  /**
   * ルールタイプが変更された場合の処理
   */
  const handleRuleTypeChange = ({ value }: selectedValueChange): void => {
    setSelectedValues({ ...selectedValues, ruleType: value });
  };

  /**
   * セレクトボックスの選択値が変更された場合に呼び出されるコールバック関数
   */
  const handleOnChange = ({ column, value }: selectedValueChange): void => {
    if (column === "workflow_type") {
      handleWorkflowTypeChange({ column, value });
    } else {
      handleRuleTypeChange({ column, value });
    }
  };

  return (
    <div className="display-flex display-flex__with-gap30">
      {/* ワークフロー種別のセレクトボックス */}
      <SearchSelector
        callback={handleOnChange}
        blankText={allTypeText}
        options={props.workflowTypeOptions}
        column={"workflow_type"}
        selectedValue={selectedValues.workflowType}
      />
      {/* ルールタイプのセレクトボックス */}
      <SearchSelector
        callback={handleOnChange}
        blankText={allTriggerText}
        options={props.ruleTypeOptions}
        column={"rule_type"}
        selectedValue={selectedValues.ruleType == "" ? "" : selectedValues.ruleType}
        disabled={isRuleTypeDisabled}
      />
    </div>
  );
};

/**
 * 汎用的なセレクトボックスコンポーネント
 */
const SearchSelector: React.FC<{
  callback: (selectedValueChange: selectedValueChange) => void;
  blankText: string;
  options: option[];
  column: column;
  selectedValue: string;
  disabled?: boolean;
}> = (props) => {
  /*
   * セレクトボックスのコールバック関数
   */
  const handleOnChange = (event: SyntheticEvent): void => {
    event.preventDefault();
    const target = event.target as HTMLSelectElement;
    const selectedValueChange: selectedValueChange = {
      value: target.value,
      column: props.column,
    };
    props.callback(selectedValueChange);
  };

  return (
    <select
      className="ca-job-search-input__detailed-search-container__selector form-control"
      name={props.column}
      onChange={handleOnChange}
      value={props.selectedValue}
      disabled={props.disabled}
    >
      <option key={0} value="">
        {props.blankText}
      </option>
      {props.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
  );
};
