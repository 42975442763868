import React from "react";
import PropTypes from "prop-types";

import GroupIconTip from "../GroupIconTip.jsx";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.post_process_policy_sets";

/**
 * ポリシーセットテーブル行コンポーネント。
 * 行をクリックしたときのポリシー一覧の取得・表示もこのコンポーネントで扱います。
 *
 * ステート
 * policySet           - 1つのポリシーセットのデータを持つオブジェクト。
 *                       初期値としてpolicySetプロパティの値が使われる。
 *                       状態の変更が行われた際にはサーバーからのレスポンスに含まれる最新の状態で更新される。
 * policies            - ポリシーセットに紐付いていて、後処理が割当たっているポリシーの一覧
 * policiesListVisible - ポリシー一覧を表示するかどうかのフラグ
 */
export default class PolicySetTableRow extends React.Component {
  /**
   * propTypes
   * @property {Object} policySet 1つのポリシーセットのデータを持つオブジェクト。
   */
  static get propTypes() {
    return {
      policySet: PropTypes.object.isRequired
    };
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);
    this.state = {
      policySet: this.props.policySet,
      policies: [],
      policiesListVisible: false
    };
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const policySet = this.state.policySet;
    let listArrorClass = "fa fa-chevron-down font-18px marginR5";
    if (this.state.policiesListVisible) {
      listArrorClass = "fa fa-chevron-up font-18px marginR5";
    }

    return (
      <React.Fragment>
        <tr>
          <td className="text-left">
            <GroupIconTip group={policySet.group} />
            {policySet.url ?
              <a href={policySet.url}>{policySet.name}</a> :
              policySet.name
            }
          </td>
          <td className="ca-post-process-policy-sets__assigned-count" onClick={() => this.policySetClick()}>
            <span className={listArrorClass} />
            {I18n.t("assigned_count", {scope: I18N_SCOPE})} {policySet.assigned_count}
          </td>
          <td></td>
        </tr>
        {this.renderPoliciesList()}
      </React.Fragment>
    );
  }

  /**
   * ポリシー一覧を描画します。
   * @return {ReactElement}
   */
  renderPoliciesList() {
    if (!this.state.policiesListVisible) { return; }
    if (this.state.errorMessage) {
      return(<tr key="0" className="no-borders"><td colSpan="3">{this.state.errorMessage}</td></tr>);
    }

    if (this.state.policies.length <= 0) { return; }

    return this.state.policies.map((policy) => {
      let enableOnCompliant = null;
      let enableOnNotCompliant = null;

      if (policy.enable_on_compliant) {
        enableOnCompliant = <span className="ca-post-process-policy-sets__compliant">{I18n.t("on_compliant", {scope: I18N_SCOPE})}</span>;
      }
      if (policy.enable_on_not_compliant) {
        enableOnNotCompliant = <span className="ca-post-process-policy-sets__not-compliant">{I18n.t("on_not_compliant", {scope: I18N_SCOPE})}</span>;
      }

      return(
        <tr key={`policy-${policy.id}`} className="no-borders">
          <td className="paddingT0"></td>
          <td className="paddingT0">
            {enableOnCompliant}{enableOnNotCompliant}
          </td>
          <td className="paddingT0">{policy.name}</td>
        </tr>
      );
    });
  }

  /**
   * ポリシーセット行をクリックしたときのフラグ制御とデータ取得を行います。
   */
  policySetClick() {
    this.setState({ policiesListVisible: !this.state.policiesListVisible });
    if (this.state.policies.length <= 0) { this.loadPolicies(); }
  }

  /**
   * ポリシーセットに紐付いていて、後処理が割当たっているポリシーの一覧を取得します。
   */
  loadPolicies() {
    $.ajax({
      type: "GET",
      url: this.props.policySet.policies_path
    })
      .done(data => {
        // レスポンスのJSONフォーマットをチェックする
        const requiredKeys = ["policies"];
        let invalidFormat = false;
        requiredKeys.forEach(key => {
          if (data[key] == undefined) {
            invalidFormat = true;
          }
        });

        if (invalidFormat) {
          this.setState({
            policies: [],
            errorMessage: I18n.t("invalid_server_response", {scope: I18N_SCOPE})
          });
          return;
        }

        let newState = {
          errorMessage: null,
          policies: data.policies,
        };

        this.setState(newState);
      })
      .fail(() => {
        this.setState({
          policies: [],
          errorMessage: I18n.t("load_error", {scope: I18N_SCOPE})
        });
      });
  }
}
