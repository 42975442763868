import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';

const I18n = window.I18n; // i18n-js

/**
 * プロフィール画像リセットボタン
 *
 * プロフィール画像情報をリセットする操作を行うためのボタンです。
 * 以下の責務を持ちます。
 *
 * - ボタンのルック＆フィール
 * - EventEmitterへのイベントの送信 (EVENT_RESET_IMAGE)
 *
 * プロフィール画像情報のリセット操作が具体的にどのような処理となるかについての責務は持ちません。
 */
export default class ResetImageButton extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {bool} disabled ボタンが無効かどうか
   * @property {Object} emitter EventEmitter
   */
  static get propTypes() {
    return({
      disabled: PropTypes.bool.isRequired,
      emitter: PropTypes.object.isRequired,
    });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <button
        className="btn"
        disabled={this.props.disabled}
        onClick={this.handleClick.bind(this)}
        type="button">
        <i className="fa fa-trash-o"></i> {I18n.t('reset_image', { scope: Constants.I18N_SCOPE })}
      </button>
    );
  }

  /**
   * ボタンがクリックされた際の処理を行います。
   *
   * @private
   * @param {Event} event
   */
  handleClick(event) {
    event.preventDefault();
    this.props.emitter.emit(Constants.EVENT_RESET_IMAGE);
  }
}
