import PropTypes from 'prop-types';
import React from 'react';

import Constants from './Constants.js';
import CreateModal from './CreateModal.jsx';
import DeleteModal from './DeleteModal.jsx';
import DisplayModal from './DisplayModal.jsx';
import RecreateModal from './RecreateModal.jsx';
import ResultModal from './ResultModal.jsx';
import Spinner from './Spinner.jsx';

const I18n = window.I18n; // i18n-js

/**
 * APIキー設定UIコンポーネント。
 *
 * このコンポーネントはAPIキー設定のルック＆フィールについて責務を持ちます。
 *
 * 表示するコンテンツは、垂直レイアウトで上段(プライマリ)と下段(セカンダリ)に分かれており、
 * それぞれの中でさらに対象ユーザーの状態に応じてボタンやラベルの表示状況が変化します。
 * また、状況に応じて各モーダルダイアログの表示も行います。
 */
export default class ApiKeySettings extends React.Component {
  /**
   * プロパティの定義を返します。
   *
   * @return {Object}
   * @property {boolean} apiEnabled ユーザーがAPIを利用可能かどうか
   * @property {string} apiKey 現在のAPIキー(生成されていない場合は空文字列)
   * @property {?string} contentForResultModal 結果表示モーダルダイアログに表示するコンテンツ
   * @property {boolean} createModalVisible APIキー作成モーダルダイアログが表示中かどうか
   * @property {boolean} deleteModalvisible APIキー削除モーダルダイアログが表示中かどうか
   * @property {boolean} displayModalVisible APIキー表示モーダルダイアログが表示中かどうか
   * @property {Object} emitter EventEmitterのインスタンス
   * @property {boolean} isLoading 通信中かどうか
   * @property {boolean} recreateModalVisible APIキー再作成モーダルダイアログが表示中かどうか
   * @property {boolean} resultModalVisible 結果表示モーダルダイアログが表示中かどうか
   * @property {boolean} userActive ユーザーのアカウント状態が「利用可能」かどうか
   */
  static get propTypes() {
    return({
      apiEnabled: PropTypes.bool.isRequired,
      apiKey: PropTypes.string.isRequired,
      contentForResultModal: PropTypes.string,
      createModalVisible: PropTypes.bool.isRequired,
      deleteModalvisible: PropTypes.bool.isRequired,
      displayModalVisible: PropTypes.bool.isRequired,
      emitter: PropTypes.object.isRequired,
      isLoading: PropTypes.bool.isRequired,
      recreateModalVisible: PropTypes.bool.isRequired,
      resultModalVisible: PropTypes.bool.isRequired,
      userActive: PropTypes.bool.isRequired,
    });
  }

  /**
   * コンストラクタ。
   *
   * @override
   */
  constructor(props) {
    super(props);

    this.texts = {
      api_unavailable: I18n.t('api_unavailable', { scope: Constants.I18N_SCOPE }),
      create_api_key: I18n.t('create_api_key', { scope: Constants.I18N_SCOPE }),
      delete_api_key: I18n.t('delete_api_key', { scope: Constants.I18N_SCOPE }),
      display_api_key: I18n.t('display_api_key', { scope: Constants.I18N_SCOPE }),
      no_api_key_text: I18n.t('no_api_key', { scope: Constants.I18N_SCOPE }),
      recreate_api_key: I18n.t('recreate_api_key', { scope: Constants.I18N_SCOPE }),
    };
  }

  /**
   * @override
   * @return {ReactElement}
   */
  render() {
    return(
      <React.Fragment>
        <div className="ca-button-layout ca-button-layout--vertical">
          <div className="ca-button-layout__primary">
            {this.isPrimaryContentsExists() && this.renderPrimaryContents()}
          </div>
          <div className="ca-button-layout__secondary">
            {this.isSecondaryContentsExists() && this.renderSecondaryContents()}
          </div>
        </div>

        {this.props.createModalVisible &&
          <CreateModal emitter={this.props.emitter} />
        }
        {this.props.displayModalVisible &&
          <DisplayModal
            apiKey={this.props.apiKey}
            emitter={this.props.emitter}
          />
        }
        {this.props.recreateModalVisible &&
          <RecreateModal emitter={this.props.emitter} />
        }
        {this.props.deleteModalvisible &&
          <DeleteModal emitter={this.props.emitter} />
        }
        {this.props.resultModalVisible &&
          <ResultModal
            content={this.props.contentForResultModal}
            emitter={this.props.emitter}
          />
        }

        <Spinner isLoading={this.props.isLoading} />
      </React.Fragment>
    );
  }

  /**
   * プライマリのコンテンツを返します。
   *
   * @private
   * @return {ReactElement}
   */
  renderPrimaryContents() {
    return(
      <div className="ca-button-layout ca-button-layout--horizontal">
        <div className="ca-button-layout__primary">
          {this.isNeedToShowApiKey() &&
            <span style={{marginRight: '2em'}}>
              {this.getMaskedApiKey()}
            </span>
          }
          {this.isNeedToShowNoApiKeyMessage() &&
            <span style={{marginRight: '2em'}}>
              {this.texts.no_api_key_text}
            </span>
          }
        </div>
        <div className="ca-button-layout__secondary">
          {this.isNeedToShowApiUnavailable() &&
            <span className="ca-organization-user-account__hint">
              {this.texts.api_unavailable}
            </span>
          }
        </div>
      </div>
    );
  }

  /**
   * セカンダリのコンテンツを返します。
   *
   * @private
   * @return {ReactElement}
   */
  renderSecondaryContents() {
    return(
      <div style={{marginTop: '1em'}}>
        {this.isNeedToShowCreateButton() &&
          <button
            className={`
              btn
              btn-default
              ca-organization-user-account__button
              ca-organization-user-account__button--${I18n.locale}
            `}
            onClick={() => this.props.emitter.emit(Constants.EVENT_OPEN_CREATE_MODAL)}
          >{this.texts.create_api_key}</button>
        }
        {this.isNeedToShowDisplayButton() &&
          <button
            className={`
              btn
              btn-secondary
              ca-organization-user-account__button
              ca-organization-user-account__button--${I18n.locale}
            `}
            onClick={() => this.props.emitter.emit(Constants.EVENT_OPEN_DISPLAY_MODAL)}
            style={{marginRight: '1em'}}
          >{this.texts.display_api_key}</button>
        }
        {this.isNeedToShowRecreateButton() &&
          <button
            className={`
              btn
              btn-default
              ca-organization-user-account__button
              ca-organization-user-account__button--${I18n.locale}
            `}
            onClick={() => this.props.emitter.emit(Constants.EVENT_OPEN_RECREATE_MODAL)}
            style={{marginRight: '1em'}}
          >{this.texts.recreate_api_key}</button>
        }
        {this.isNeedToShowDeleteButton() &&
          <button
            className={`
              btn
              btn-danger
              ca-organization-user-account__button
              ca-organization-user-account__button--${I18n.locale}
            `}
            onClick={() => this.props.emitter.emit(Constants.EVENT_OPEN_DELETE_MODAL)}
          >{this.texts.delete_api_key}</button>
        }
      </div>
    );
  }

  /**
   * APIキーをマスクした文字列を返します。
   *
   * @private
   * @return {?string} マスクされたAPIキーまたはnull
   */
  getMaskedApiKey() {
    if (this.props.apiKey.length === 0) {
      return null;
    }

    const pre = this.props.apiKey.slice(0, 3);
    const pad = '*'.repeat(26);
    const post = this.props.apiKey.slice(-3);
    return `${pre}${pad}${post}`;
  }

  /**
   * APIキーを表示する必要があるかどうかを返します。
   *
   * @private
   * @return {boolean}
   */
  isNeedToShowApiKey() {
    return this.props.apiKey !== '';
  }

  /**
   * API利用不可のメッセージを表示する必要があるかどうかを返します。
   *
   * @private
   * @return {boolean}
   */
  isNeedToShowApiUnavailable() {
    return !this.props.apiEnabled || !this.props.userActive;
  }

  /**
   * APIキーを生成するボタンを表示する必要があるかどうかを返します。
   *
   * @private
   * @return {boolean}
   */
  isNeedToShowCreateButton() {
    return this.props.apiKey === '' && this.props.apiEnabled && this.props.userActive;
  }

  /**
   * APIキーを削除するボタンを表示する必要があるかどうかを返します。
   *
   * @private
   * @return {boolean}
   */
  isNeedToShowDeleteButton() {
    return this.props.apiKey !== '';
  }

  /**
   * APIキーを表示するボタンを表示する必要があるかどうかを返します。
   *
   * @private
   * @return {boolean}
   */
  isNeedToShowDisplayButton() {
    return this.props.apiKey !== '';
  }

  /**
   * APIキーが生成されていないことをあらわすメッセージを表示する必要があるかどうかを返します。
   *
   * @private
   * @return {boolean}
   */
  isNeedToShowNoApiKeyMessage() {
    return this.props.apiKey === '' && this.props.apiEnabled && this.props.userActive;
  }

  /**
   * APIキーを再生成するボタンを表示する必要があるかどうかを返します。
   *
   * @private
   * @return {boolean}
   */
  isNeedToShowRecreateButton() {
    return this.props.apiKey !== '' && this.props.apiEnabled && this.props.userActive;
  }

  /**
   * プライマリーコンテンツとして表示すべきコンテンツがあるかどうかを返します。
   * コンテンツ間のスペーシングを柔軟に制御するために使われます。
   *
   * @private
   * @return {boolean}
   */
  isPrimaryContentsExists() {
    return this.props.apiKey !== ''
      || this.isNeedToShowApiUnavailable()
      || this.isNeedToShowNoApiKeyMessage();
  }

  /**
   * セカンダリーコンテンツとして表示すべきコンテンツがあるかどうかを返します。
   * コンテンツ間のスペーシングを柔軟に制御するために使われます。
   *
   * @private
   * @return {boolean}
   */
  isSecondaryContentsExists() {
    return this.isNeedToShowDisplayButton()
      || this.isNeedToShowRecreateButton()
      || this.isNeedToShowDeleteButton()
      || this.isNeedToShowCreateButton();
  }
}
