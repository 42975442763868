import PropTypes from 'prop-types';
import React from 'react';

import Constants from './Constants.js';
import GlobalConstants from '../Constants.js';
import RecentLogsLinkContainer from './RecentLogsLinkContainer.jsx';

const I18n = window.I18n; // i18n-js
const jQuery = window.jQuery;

/**
 * 最近のログのドロップダウンメニューUIコンポーネント。
 * ドロップダウンメニュー全体のUI表示およびイベントハンドラの呼び出しを行います。
 */
class RecentLogsDropdownMenu extends React.Component {
  /**
   * プロパティ。
   *
   * @property {function()} onShowDropdown ドロップダウンメニューが開かれた時に呼び出すイベントハンドラ
   * @property {Object[]} policyLogs ポリシーログの配列
   * @property {string} policyLogsMessage ポリシーログに関するメッセージ
   * @property {Object[]} triggerJobLogs トリガージョブログの配列
   * @property {string} triggerJobLogsMessage トリガージョブログに関するメッセージ
   */
  static get propTypes() {
    return ({
      onShowDropdown: PropTypes.func.isRequired,
      policyLogs: PropTypes.arrayOf(PropTypes.object).isRequired,
      policyLogsMessage: PropTypes.string.isRequired,
      triggerJobLogs: PropTypes.arrayOf(PropTypes.object).isRequired,
      triggerJobLogsMessage: PropTypes.string.isRequired,
    });
  }

  constructor(props) {
    super(props);

    this.menuNameText = I18n.t('menu_name', { scope: Constants.I18N_SCOPE });
    this.policyLogText = I18n.t('policy_log', { scope: Constants.I18N_SCOPE });
    this.triggerJobLogText = I18n.t('trigger_job_log', { scope: Constants.I18N_SCOPE });
    this.viewAllText = I18n.t('view_all', { scope: Constants.I18N_SCOPE });
  }

  componentDidMount() {
    // ドロップダウンメニューが開く際にイベントハンドラを呼び出す
    jQuery('[data-recent-logs-container]').on('show.bs.dropdown', () => {
      this.props.onShowDropdown();
    });
  }

  componentWillUnmount() {
    // ドロップダウンメニューに関するイベントハンドラの設定を取り除く
    jQuery('[data-recent-logs-container]').off('show.bs.dropdown');
  }

  render() {
    return(
      <div data-recent-logs-container>
        <a
          className="btn btn-link dropdown-toggle"
          data-toggle="dropdown"
        >
          <i className="dropdown-toggle__icon fa fa-bar-chart"></i>
          <span className="dropdown-toggle__txt">{this.menuNameText}</span>
        </a>
        <ul
          className="dropdown-menu pull-right ca-logs-menu"
          role="menu"
        >
          <li className="dropdown-header ca-logs-menu__header">
            <div className="ca-logs-menu__header-title">{this.triggerJobLogText}</div>
            <div>
              <a
                className="ca-logs-menu__header-link"
                href={GlobalConstants.URL.TRIGGER_LOGS}
              >{this.viewAllText}</a>
            </div>
          </li>
          <RecentLogsLinkContainer
            message={this.props.triggerJobLogsMessage}
            logs={this.props.triggerJobLogs}
          />

          <li className="dropdown-header ca-logs-menu__header">
            <div className="ca-logs-menu__header-title">{this.policyLogText}</div>
            <div>
              <a
                className="ca-logs-menu__header-link"
                href={GlobalConstants.URL.POLICY_LOGS}
              >{this.viewAllText}</a>
            </div>
          </li>
          <RecentLogsLinkContainer
            message={this.props.policyLogsMessage}
            logs={this.props.policyLogs}
          />
        </ul>
      </div>
    );
  }
}

export default RecentLogsDropdownMenu;
