import React from "react";
import PropTypes from "prop-types";
import GroupSelector from '../GroupSelector.jsx';

const I18n = window.I18n; // i18n-js
const I18N_SCOPE = "javascript.post_process_table.group_selection_field";

/**
 * グループ選択フィールド
 *
 * onChangeプロパティに渡されたコールバック関数にはグループのIDが渡されます。
 *
 * @todo GroupSelectorコンポーネントにまとめる
**/
class GroupSelectionField extends React.Component {
  /**
   * propTypes
   * @return {Object}
   * @property {func} onChange - 値が変化した際に呼び出されるコールバック関数
   * @property {Array<Object>} groups - `[{id: 1, name: "hoge"}]` のようなグループ一覧
   * @property {string} value 現在選択されているグループのID
  **/
  static get propTypes() {
    return({
      onChange: PropTypes.func.isRequired,
      groups: PropTypes.arrayOf(PropTypes.object).isRequired,
      value: PropTypes.string,
    });
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);

    this.state = {
      options: this.getGroupOptions(),
      selectedIds: this.getSelectedIds()
    };

    this.handleOnChangeGroup = this.handleOnChangeGroup.bind(this);
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <div className="ca-post-process-table__selector">
        <GroupSelector
          disabled={false}
          onChange={this.handleOnChangeGroup}
          options={this.state.options}
          selectedIds={this.state.selectedIds}
          placeholder={I18n.t("search_placeholder", { scope: I18N_SCOPE })}
        />
      </div>
    );
  }

  /**
   * GroupSelectorコンポーネントに渡す選択状態の値の整形
   * @private
   */
  getSelectedIds() {
    let selectedIds = [];
    if (this.props.value !== '') {
      selectedIds = [Number(this.props.value)];
    }
    return selectedIds;
  }

  /**
   * グループ選択のセレクトボックスの選択候補を返す
   * @private
   * @return {Object[]} idおよびnameプロパティを持つオブジェクトの配列
   */
  getGroupOptions() {
    const sharedByGroups = {name: I18n.t("shared_by_groups", { scope: I18N_SCOPE }), id: 0};
    return [sharedByGroups].concat(this.props.groups);
  }

  /**
   * グループ選択のセレクトボックスが変更された際に呼び出されるイベントハンドラ。
   * @private
   * @param {Object[]} groups 現在選択されているグループ
   */
  handleOnChangeGroup(groups) {
    // グループが何も選択されていない状態の場合はすべて表示とする
    if(groups.length) {
      this.props.onChange(groups[0].id);
    } else {
      this.props.onChange('');
    }
  }
}

export default GroupSelectionField;
