import PropTypes from "prop-types";
import React from "react";

import Constants from "./Constants.js";
import EbsBackupCheckResultsTableRow from "./EbsBackupCheckResultsTableRow.jsx";
import EmptyRow from '../record_table/EmptyRow.jsx';
import PendingRow from '../record_table/PendingRow.jsx';
import SortableTableHeaderCell from "../SortableTableHeaderCell.jsx";
import withBootstrapTooltip from '../withBootstrapTooltip.js';

const I18n = window.I18n; // i18n-js

/**
 * EBSバックアップ監視結果テーブルコンポーネント
 *
 * 監視結果一覧を1つのテーブルとして表示します。
 * 表示されるDOMはTABLEタグとなります。
 */
class EbsBackupCheckResultsTable extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {function} callback SortableTableHeaderCellのコールバック関数
   * @property {array} checkResults 表示するポリシーログの配列
   * @property {boolean} pending 通信中かどうか
   * @property {string} sortColumn テーブルのソート列
   * @property {string} sortDirection テーブルのソート順
   * @property {function(element: DOMNode)} tooltipRef
   */
  static get propTypes() {
    return({
      callback: PropTypes.func.isRequired,
      checkResults: PropTypes.array.isRequired,
      pending: PropTypes.bool.isRequired,
      sortColumn: PropTypes.string.isRequired,
      sortDirection: PropTypes.string.isRequired,
      tooltipRef: PropTypes.func,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    const options = { scope: `${Constants.I18N_SCOPE}` };
    this.awsAccountName = I18n.t("aws_account_name", options);
    this.backupExistEbsCount = I18n.t("backup_exist_ebs_count", options);
    this.backupCheckSettings = I18n.t("backup_check_settings", options);
    this.backupNotExistEbsCount = I18n.t("backup_not_exist_ebs_count", options);
    this.checkFailed = I18n.t("check_failed", options);
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <table
        className="table vertical-middle ca-table-header-no-bordered  ca-backup-check-results-table"
        ref={this.props.tooltipRef}
      >
        <thead>
          <tr>
            <SortableTableHeaderCell
              active={this.props.sortColumn == "aws_account_name"}
              callback={this.props.callback}
              classNameForHeaderCell="ca-backup-check-volumes-table-header--clickable ca-backup-check-results-table-header__aws-account-name text-center"
              column="aws_account_name"
              direction={this.props.sortDirection}
              text={this.awsAccountName}
            />
            <SortableTableHeaderCell
              active={this.props.sortColumn == "backup_not_exist_ebs_count"}
              callback={this.props.callback}
              classNameForHeaderCell="ca-backup-check-volumes-table-header--clickable ca-backup-check-results-table-header__backup-not-exist-ebs-count text-center"
              column="backup_not_exist_ebs_count"
              direction={this.props.sortDirection}
              text={this.backupNotExistEbsCount}
            />
            <SortableTableHeaderCell
              active={this.props.sortColumn == "backup_exist_ebs_count"}
              callback={this.props.callback}
              classNameForHeaderCell="ca-backup-check-volumes-table-header--clickable ca-backup-check-results-table-header__backup-exist-ebs-count text-center"
              column="backup_exist_ebs_count"
              direction={this.props.sortDirection}
              text={this.backupExistEbsCount}
            />
            <SortableTableHeaderCell
              active={this.props.sortColumn == "error"}
              callback={this.props.callback}
              classNameForHeaderCell="ca-backup-check-volumes-table-header--clickable ca-backup-check-results-table-header__check-failed text-center"
              column="error"
              direction={this.props.sortDirection}
              text={this.checkFailed}
            />
            <th className="ca-backup-check-results-table-header__button"></th>
          </tr>
        </thead>
        <tbody>
          {this.renderTableRows()}
        </tbody>
      </table>
    );
  }

  /**
   * データに応じてテーブルの行を返します。
   *
   * @private
   * @return {ReactElement}
   */
  renderTableRows() {
    if (this.props.pending) {
      return <PendingRow columns={5} />;
    }

    if (this.props.checkResults.length == 0) {
      return <EmptyRow columns={5} />;
    }

    return(
      this.props.checkResults.map(result => <EbsBackupCheckResultsTableRow key={result.id} checkResult={result} />)
    );
  }
}

// コンポーネント内でTooltipを使えるようにしてから外部にエクスポートする
export default withBootstrapTooltip(EbsBackupCheckResultsTable);
