import React from "react";
import { useToggle } from "react-use";

import { Group } from "./types";
import { NewDialog } from "./NewDialog";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.group_google_cloud_account.new_button";

/** NewButtonのプロパティ */
type Props = {
  /** Google Cloudアカウントの作成対象となるグループ */
  group: Group;
  /** 作成完了後に実行させる処理 */
  onCreated: () => void;
};

/**
 * Google Cloudアカウント追加ボタン
 *
 * ボタンの表示とダイアログの表示制御を行います。
 */
const NewButton: React.FC<Props> = (props): JSX.Element => {
  /** ダイアログの開閉状態 */
  const [isDialogOpen, toggleDialog] = useToggle(false);

  return (
    <div className="inline-block">
      <Button onClick={(): void => toggleDialog(true)} />

      <NewDialog
        group={props.group}
        isOpen={isDialogOpen}
        onCancel={(): void => toggleDialog(false)}
        onCreated={(): void => {
          props.onCreated();
          toggleDialog(false);
        }}
      />
    </div>
  );
};

/**
 * 追加ボタン
 */
const Button: React.FC<{
  /** ボタン押下時に実行させる処理 */
  onClick: () => void;
}> = (props): JSX.Element => {
  return (
    <button type="button" className="btn btn-default" onClick={props.onClick}>
      <span className="fa fa-plus-square marginR5" />
      <span className="font-12px">{I18n.t("add", { scope: I18N_SCOPE })}</span>
    </button>
  );
};

export { NewButton };
