import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import { GroupAwsAccountTable } from "./GroupAwsAccountTable.tsx";
import { NewAwsAccountWithIamUserButton } from "./NewAwsAccountWithIamUserButton.tsx";
import { NewAwsAccountWithIamRoleButton } from "./NewAwsAccountWithIamRoleButton.tsx";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.group_aws_account";

export const GroupAwsAccountTableContainer: React.FC<{
  group: {
    id: number;
    color: string;
    name: string;
  };
}> = (props) => {
  const [awsAccounts, setAwsAccounts] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [sort, setSort] = useState({ column: "aws_account.name", order: "asc" });

  const getInvertedSortOrder = (): string => {
    return sort.order === "asc" ? "desc" : "asc";
  };

  const loadAwsAccounts = (): void => {
    jQuery
      .ajax({
        url: `/groups/${props.group.id}/aws_accounts`,
        method: "GET",
        dataType: "json",
        data: `order=${sort.column} ${sort.order}`,
      })
      .done((data) => {
        setAwsAccounts(data.aws_accounts);
        setErrorMessage("");
      })
      .fail((data) => {
        setAwsAccounts([]);
        setErrorMessage(data.statusText);
      });
  };

  const onSortClick = (column: string): void => {
    if (column === sort.column) {
      setSort({ column: sort.column, order: getInvertedSortOrder() });
    } else {
      setSort({ column: column, order: "asc" });
    }
  };

  useEffect(() => {
    loadAwsAccounts();
  }, [sort]);

  return (
    <React.StrictMode>
      <React.Fragment>
        <div className="table-actions">
          <div className="ca-group-user__group-name font-16px marginB30">
            <span className="fa fa-briefcase group-icon" style={{ color: props.group.color }} />
            {props.group.name}
          </div>
          <div className="ca-table-headroom">
            <div className="ca-table-headroom__primary">
              <span className="ca-search-box__metainfo">
                {I18n.t("aws_account_count", {
                  number: awsAccounts.length,
                  scope: I18N_SCOPE,
                })}
              </span>
            </div>
            <div className="ca-table-headroom__secondary">
              <NewAwsAccountWithIamUserButton group={props.group} onCreated={loadAwsAccounts} />
              <NewAwsAccountWithIamRoleButton
                group={props.group}
                onCreated={loadAwsAccounts}
              />
            </div>
          </div>
        </div>
        <GroupAwsAccountTable
          awsAccounts={awsAccounts}
          group={props.group}
          errorMessage={errorMessage}
          onSortClick={onSortClick}
          reload={loadAwsAccounts}
          sortColumn={sort.column}
          sortDirection={sort.order}
        />
        <ToastContainer
          autoClose={5000}
          closeOnClick
          draggable={false}
          hideProgressBar={false}
          newestOnTop={false}
          pauseOnFocusLoss
          pauseOnHover
          position="top-center"
          rtl={false}
        />
      </React.Fragment>
    </React.StrictMode>
  );
};
