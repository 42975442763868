// ユーザーのパスワード設定関連コンポーネントで利用する定数

export default {
  // EventEmitterで扱うイベントの識別子
  EVENT_CHANGE_CURRENT_PASSWORD: 'changeCurrentPassword',
  EVENT_CHANGE_NEW_PASSWORD: 'changeNewPassword',
  EVENT_CHANGE_NEW_PASSWORD_CONFIRMATION: 'changeNewPasswordConfirmation',
  EVENT_CLOSE_DIALOG: 'closeDialog',
  EVENT_OPEN_DIALOG: 'openDialog',
  EVENT_UPDATE_PASSWORD: 'updatePassword',

  // I18nメッセージリソースのスコープ
  I18N_SCOPE: 'javascript.password_settings',
};
