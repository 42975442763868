import React from "react";
import ReactModal from "react-modal";
import { Typeahead, Token } from "react-bootstrap-typeahead";
import PropTypes from "prop-types";

import { showErrorToast, showSuccessToast } from "../../toast";

const $ = window.jQuery;
const I18n = window.I18n;
const I18N_SCOPE = "javascript.group_user.add_group_user_modal";

/**
 * グループにユーザーを追加するダイアログ
 * 表示されるダイアログの定義と実際の追加処理を行います
 */
export default class AddGroupUserDialog extends React.Component {
  /**
   * プロパティ定義を返します
   *
   * @public
   * @property {boolean} dateLabelFeatureEnabled 組織に対してカレンダー機能の機能フラグが有効かどうか
   * @property {object} emitter ダイアログの開閉をするイベントを発火するオブジェクト
   * @property {number} groupId メンバーを追加するグループのID
   * @property {object[]} groupUsers グループに所属しているユーザーの配列。登録候補から除外するのに利用します
   * @property {boolean} inventoryAvailable 組織がインベントリ機能を利用可能かどうか
   * @property {boolean} policySetsAvailable 組織が構成レビュー機能を利用可能かどうか
   * @property {object} reloadEmitter グループ一覧をリロードするイベントを発火するオブジェクト。追加処理後にイベントを発火し、グループ一覧を更新します
   * @property {bool} showAddGroupUserDialog メンバー追加ダイアログの開閉を制御します。trueの時ダイアログが表示されます
   */
  static get propTypes() {
    return {
      dateLabelFeatureEnabled: PropTypes.bool.isRequired,
      emitter: PropTypes.object.isRequired,
      groupId: PropTypes.number.isRequired,
      groupUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
      inventoryAvailable: PropTypes.bool.isRequired,
      policySetsAvailable: PropTypes.bool.isRequired,
      reloadEmitter: PropTypes.object.isRequired,
      showAddGroupUserDialog: PropTypes.bool.isRequired
    };
  }

  /**
   * コンストラクタ
   *
   * ステート
   * users - 追加するユーザーを保持します
   * appliableUsers - 追加可能なユーザーを保持します
   * jobPermission - 追加されるユーザーのジョブ操作権限を保持します
   * policySetPermission - 追加されるユーザーのポリシーセット操作権限を保持します
   */
  constructor(props) {
    super(props);

    this.state = this.initialStates();

    this.closeDialog = this.closeDialog.bind(this);
    this.handleGenerateLabelKey = this.handleGenerateLabelKey.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTypeaheadOnChange = this.handleTypeaheadOnChange.bind(this);
    this.handleUpdateCalendarPermission = this.handleUpdateCalendarPermission.bind(
      this
    );
    this.handleUpdateInventoryPermission = this.handleUpdateInventoryPermission.bind(
      this
    );
    this.handleUpdateJobPermission = this.handleUpdateJobPermission.bind(this);
    this.handleUpdatePolicySetPermission = this.handleUpdatePolicySetPermission.bind(
      this
    );

    ReactModal.setAppElement("body");
  }

  /*
   * コンポーネントがマウントされたタイミングで追加可能なユーザーを取得
   */
  componentDidMount() {
    this.loadAppliableUsers();
  }

  /*
   * コンポーネントが更新されたタイミングで追加可能なユーザーを取得
   */
  componentDidUpdate(prevProps) {
    // ユーザーが追加/削除されたタイミングで追加可能なユーザーを再取得
    if (this.props.groupUsers.length !== prevProps.groupUsers.length) {
      this.loadAppliableUsers();
    }

    // モーダルの表示のタイミングで追加可能なユーザーを再取得
    if (!prevProps.showAddGroupUserDialog && this.props.showAddGroupUserDialog) {
      this.loadAppliableUsers();
    }
  }

  /*
   * ダイアログを閉じる処理を行う
   */
  closeDialog() {
    this.setState(this.initialStates());
    this.props.emitter.emit("closeDialog");
  }

  /*
   * 入力に応じてTypeaheadで利用するラベルキーを作成します
   */
  handleGenerateLabelKey(option) {
    return `${option.department} ${option.full_name} (${option.email})`;
  }

  /*
   * render メソッド内で利用するハンドラーメソッド
   * インベントリ権限を操作するselectのonChangeで利用する
   */
  handleUpdateCalendarPermission(event) {
    this.setState({ dateLabelPermission: event.target.value });
  }

  /*
   * render メソッド内で利用するハンドラーメソッド
   * インベントリ権限を操作するselectのonChangeで利用する
   */
  handleUpdateInventoryPermission(event) {
    this.setState({ inventoryPermission: event.target.value });
  }

  /*
   * render メソッド内で利用するハンドラーメソッド
   * ジョブ権限を操作するselectのonChangeで利用する
   */
  handleUpdateJobPermission(event) {
    this.setState({ jobPermission: event.target.value });
  }

  /*
   * render メソッド内で利用するハンドラーメソッド
   * ポリシーセット権限を操作するselectのonChangeで利用する
   */
  handleUpdatePolicySetPermission(event) {
    this.setState({ policySetPermission: event.target.value });
  }

  /*
   * stateの初期値を返す
   *
   * 一度初期化されたオブジェクトが使い回されるのでダイアログを閉じた際にも
   * state の初期化を行うため constructor 外に初期値を持ち
   * ダイアログを閉じる際に利用出来るようにする
   *
   * @return {Object} stateの初期値となるオブジェクト
   */
  initialStates() {
    return {
      users: [],
      appliableUsers: [],
      dateLabelPermission: "read_write",
      jobPermission: "read_write",
      policySetPermission: "read_write",
      inventoryPermission: this.props.inventoryAvailable ? "read_write" : ""
    };
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    const options = { scope: I18N_SCOPE };

    return (
      <ReactModal
        isOpen={this.props.showAddGroupUserDialog}
        onRequestClose={this.closeDialog}
        overlayClassName="ca-add-group-user-modal__overlay"
        className="ca-add-group-user-modal__content text-left"
        role="dialog"
      >
        <div className="marginB30">
          <h2>
            <span className="fa fa-users" />
            &nbsp;
            {I18n.t("title", options)}
          </h2>
        </div>
        <div className="ca-add-group-user-modal__typeahead">
          <Typeahead
            multiple
            emptyLabel={I18n.t("empty_label", options)}
            id="AddGroupUserDialog"
            renderToken={this.renderToken}
            labelKey={this.handleGenerateLabelKey}
            options={this.state.appliableUsers}
            onChange={this.handleTypeaheadOnChange}
            placeholder={I18n.t("placeholder", options)}
          />
        </div>
        <div className="marginT30">
          <div className="marginB10 ca-add-group-user-modal__content-form">
            <div className="ca-add-group-user-modal__content-label">
              <span>{I18n.t("trigger_job", options)}</span>
            </div>

            <div className="ca-add-group-user-modal__content-select">
              <select
                className="form-control inline-block"
                onChange={this.handleUpdateJobPermission}
              >
                <option value="read_write">
                  {I18n.t("job_read_write", options)}
                </option>
                <option value="read_only">
                  {I18n.t("read_only", options)}
                </option>
              </select>
            </div>
          </div>

          {this.props.policySetsAvailable && (
            <div className="marginB10 ca-add-group-user-modal__content-form">
              <div className="ca-add-group-user-modal__content-label">
                <span>{I18n.t("policy_set", options)}</span>
              </div>

              <div className="ca-add-group-user-modal__content-select">
                <select className="form-control inline-block" onChange={this.handleUpdatePolicySetPermission}>
                  <option value="read_write">{I18n.t("policy_set_read_write", options)}</option>
                  <option value="read_only">{I18n.t("read_only", options)}</option>
                </select>
              </div>
            </div>
          )}

          <div className="marginB10 ca-add-group-user-modal__content-form">
            <div className="ca-add-group-user-modal__content-label">
              <span>{I18n.t("inventory.workspaces", options)}</span>
            </div>

            <div className="ca-add-group-user-modal__content-select">
              <select
                className="form-control inline-block ca-inventory-permission"
                onChange={this.handleUpdateInventoryPermission}
                disabled={!this.props.inventoryAvailable}
                defaultValue="read_write"
              >
                <option value="">
                  {I18n.t("inventory.workspaces_permission_null", options)}
                </option>
                {this.props.inventoryAvailable &&
                 <React.Fragment>
                   <option value="read_write">
                     {I18n.t("inventory.workspaces_read_write", options)}
                   </option>
                   <option value="read_only">
                     {I18n.t("inventory.workspaces_read_only", options)}
                   </option>
                 </React.Fragment>
                }
              </select>
              {!this.props.inventoryAvailable &&
               <p className="ca-add-group-user-modal__content__prompt marginT5">
                 {I18n.t("inventory.workspaces_permission_note", options)}
               </p>
              }
            </div>
          </div>

          {this.props.dateLabelFeatureEnabled &&
            <div className="marginB10 ca-edit-group-user-modal__content-form">
              <div className="ca-edit-group-user-modal__content-label">
                <span>{I18n.t("calendar", options)}</span>
              </div>

              <div className="ca-edit-group-user-modal__content-select">
                <select
                  className="form-control inline-block"
                  onChange={this.handleUpdateCalendarPermission}
                  defaultValue={this.state.dateLabelPermission}
                >
                  <option value="read_write">
                    {I18n.t("calendar_read_write", options)}
                  </option>
                  <option value="read_only">
                    {I18n.t("read_only", options)}
                  </option>
                </select>
              </div>
            </div>
          }

          <div className="marginT20 text-center">
            <button
              type="button"
              className="btn btn-primary btn-lg"
              disabled={this.state.users.length == 0}
              onClick={this.handleSubmit}
            >
              <span className="fa fa-check-circle" />
              {I18n.t("add", options)}
            </button>
          </div>
        </div>
      </ReactModal>
    );
  }

  /**
   * Typeaheadで選択した項目の表示方法
   */
  renderToken(user, props, index) {
    return (
      <Token key={index} onRemove={props.onRemove} option={user}>
        {`${user.department} ${user.full_name}`}
      </Token>
    );
  }

  /**
   * Typeaheadの項目が変更された時のイベント
   */
  handleTypeaheadOnChange(users) {
    this.setState({ users: users });
  }

  /**
   * サブミットイベント
   * グループに対してユーザーの追加を行う
   */
  handleSubmit() {
    $.ajax({
      type: "POST",
      url: `/groups/${this.props.groupId}/user_assignments.json`,
      data: {
        user_ids: this.state.users.map(user => user.id),
        calendar_permission: this.state.dateLabelPermission,
        job_permission: this.state.jobPermission,
        policy_set_permission: this.state.policySetPermission,
        inventory_permission: this.state.inventoryPermission
      }
    })
      .done(() => {
        this.closeDialog();
        this.props.reloadEmitter.emit("reloadGroupUsers");
        showSuccessToast(I18n.t("succeeded", { scope: I18N_SCOPE }));
      })
      .fail((jqXHR) => {
        this.closeDialog();
        const error = `status=${jqXHR.status} statusText=${jqXHR.statusText}`;
        showErrorToast(I18n.t("failed", { error: error, scope: I18N_SCOPE }));
      });
  }

  /**
   * 追加対象となるユーザーを取得し、stateにいれる
   * すでにグループに追加済みのユーザーは対象外とする
   */
  loadAppliableUsers() {
    $.ajax({
      type: "GET",
      url: "/organization/users.json"
    }).done(data => {
      const groupUserIds = this.props.groupUsers.map(elem => elem.id);
      const users = data.users.filter(elem => {
        return !groupUserIds.includes(elem.id);
      });
      this.setState({ appliableUsers: users });
    });
  }
}
