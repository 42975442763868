import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import ErrorMessages from '../ErrorMessages.jsx';

const I18n = window.I18n; // i18n-js

// Webhook URL入力フィールド
class WebhookUrlField extends React.Component {
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
      value: PropTypes.string.isRequired
    });
  }

  /**
   * エラーがあるかどうかを返します。
   *
   * @return {boolean}
   */
  hasError() {
    return this.props.errors.length > 0;
  }

  render() {
    const errorClass = this.hasError() ? 'error' : '';
    const label = I18n.t('activerecord.attributes.post_process.webhook_url');
    const prompt = I18n.t('webhook_url_prompt', { scope: Constants.I18N_SCOPE });

    return(
      <div className="form-group">
        <div className="post-process-setting">
          <div className="post-process-setting__label">
            <div className="post-process-setting__label__text">
              <label htmlFor="PostProcess-webhookUrl">
                {label}
              </label>
              <span className="required">*</span>
            </div>
          </div>
          <div className="post-process-setting__form">
            <input
              id="PostProcess-webhookUrl"
              className={`form-control ${errorClass}`}
              onChange={event => this.props.emitter.emit(Constants.EVENT_CHANGE_FORM_VALUE, 'webhookUrl', event.target.value)}
              type="url"
              value={this.props.value}
            />
            <div className="post-process-setting__prompt">{prompt}</div>
            <ErrorMessages messages={this.props.errors} />
          </div>
        </div>
      </div>
    );
  }
}

export default WebhookUrlField;
