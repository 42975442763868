import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import ResetImageButton from './ResetImageButton.jsx';
import SelectImageButton from './SelectImageButton.jsx';
import UserAvatar from '../UserAvatar.jsx';
import withBootstrapTooltip from '../withBootstrapTooltip.js';

const I18n = window.I18n; // i18n-js

/**
 * プロフィール画像アップローダー
 *
 * 以下の責務を持ちます。
 *
 * - プロフィール画像アップローダーのUI
 */
class ProfileImageUploader extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {Object} emitter EventEmitter
   * @property {?string} profileImageUrl プレビュー用のプロフィール画像URL
   * @property {func} tooltipRef ツールチップ表示に使う要素参照用関数
   * @property {Object} user 表示するユーザー(full_name, profile_image_url, initial)
   */
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
      profileImageUrl: PropTypes.string,
      tooltipRef: PropTypes.func.isRequired,
      user: PropTypes.object.isRequired,
    });
  }

  /**
   * デフォルトのプロパティを返します。
   *
   * @public
   * @return {Object}
   * @property {?string} profileImageUrl プレビュー画像URLのデフォルト値
   */
  static get defaultProps() {
    return({
      profileImageUrl: null,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.i18n_text = {
      hint: I18n.t('hint', { scope: Constants.I18N_SCOPE }),
    };
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <div
        className="ca-profile-image-uploader"
        ref={this.props.tooltipRef}
      >
        <div className="ca-profile-image-uploader__avatar-pane">
          <UserAvatar
            size="large"
            user={{
              full_name: this.props.user.full_name,
              initial: this.props.user.initial,
              profile_image_url: this.props.profileImageUrl,
            }}
          />
        </div>
        <div>
          <div className="ca-profile-image-uploader__buttons">
            {/* UppyのFileInputプラグインが生成するINPUT要素のコンテナ */}
            <div id={Constants.FILE_INPUT_TARGET_ID} style={{display: 'none'}}></div>

            <SelectImageButton emitter={this.props.emitter} />
            <ResetImageButton
              disabled={this.props.profileImageUrl === null}
              emitter={this.props.emitter}
            />
          </div>

          <p className="ca-profile-image-uploader__hint">
            {this.i18n_text.hint}
          </p>

          {/* UppyのStatusBarプラグインが生成する進捗表示のコンテナ */}
          <div id={Constants.STATUS_BAR_ID}></div>
        </div>
      </div>
    );
  }
}

export default withBootstrapTooltip(ProfileImageUploader);
