import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useToggle } from "react-use";

import { showSuccessToast } from "../../toast";
import ManualLink from "../../ManualLink";

import { CfnStackIdInputField } from "../../aws_account/CfnStackIdInputField";
import { IamRoleUpdateButton } from "../../aws_account/IamRoleUpdateButton";

import { AwsAccountWithIamRole } from "./types";
import { Group } from "./types";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.groups.aws_accounts.update_iam_role_dialog";

/**
 * フォームの各フィールドに対するエラーメッセージを含むオブジェクトの型
 */
type FormErrors = {
  updateIamRoleButton: string[];
  cfnStackId: string[];
};

/**
 * useUpdateIamRoleLogicフックの戻り値の型
 */
type UseUpdateIamRoleLogicResult = {
  /**
   * 送信するCFnスタックID
   */
  cfnStackId: string;
  /**
   * 送信するCFnテンプレートキャッシュID
   */
  cfnTemplateCacheId: number;
  /**
   * フォームのエラーメッセージ
   */
  errors: FormErrors;
  /**
   * キャンセル操作時に呼び出す関数
   */
  handleCancel: () => void;
  /**
   * クイック作成リンクを取得するボタンをクリックした際に呼び出す関数
   */
  handleUpdateIamRoleButtonClick: () => void;
  /**
   * フォーム送信時に呼び出す関数
   */
  handleSubmit: (event: React.SyntheticEvent) => void;
  /**
   * クイック作成リンク取得の通信中かどうかのフラグ
   */
  isPendingUpdateIamRoleButton: boolean;
  /**
   * フォーム送信中かどうかのフラグ
   */
  isPendingSubmitButton: boolean;
  /**
   * CFnスタックIDを更新するための関数
   */
  setCfnStackId: (cfnStackId: string) => void;
};

/**
 * エラーメッセージ用ステートの初期状態およびリセット後の状態
 */
const emptyErrors: FormErrors = {
  updateIamRoleButton: [],
  cfnStackId: [],
};

/**
 * AWSアカウント(IAMロール方式)の権限を最新化するビジネスロジックのHook
 *
 * @param group 権限最新化する対象のAWSアカウントが所属しているグループ
 * @param awsAccount 権限最新化する対象のAWSアカウント
 * @param cancelCallback キャンセル操作時に呼び出すコールバック
 * @param updatedCallback 権限最新化完了時に呼び出すコールバック
 */
const useUpdateIamRoleLogic = (
  awsAccount: AwsAccountWithIamRole,
  group: Group,
  cancelCallback: () => void,
  updatedCallback: () => void
): UseUpdateIamRoleLogicResult => {
  // 送信するCFnスタックID
  const [cfnStackId, setCfnStackId] = useState("");
  // 送信するCFnテンプレートキャシュID
  const [cfnTemplateCacheId, setCfnTemplateCacheId] = useState(0);
  // クイック作成リンクを取得するボタンを無効化するかどうかのフラグ
  const [isPendingUpdateIamRoleButton, setIsPendingUpdateIamRoleButton] = useState(false);
  // フォーム送信ボタンを無効化するかどうかのフラグ
  const [isPendingSubmitButton, setIsPendingSubmitButton] = useToggle(false);
  // 各フィールド毎のエラーメッセージ
  const [errors, setErrors] = useState(emptyErrors);

  /**
   * AWSアカウント(IAMロール方式)の権限最新化をキャンセルする関数
   *
   * ダイアログを閉じる操作が行われた場合に実行されます。
   */
  const handleCancel = (): void => {
    cancelCallback();
    setCfnTemplateCacheId(0);
    setCfnStackId("");
    setErrors(emptyErrors);
  };

  /**
   * CFnスタックのクイック作成リンクを取得します。
   * リクエスト成功時にレスポンスで指定されたURLを新しいタブで表示します。
   */
  const handleUpdateIamRoleButtonClick = (): void => {
    jQuery
      .ajax({
        beforeSend: () => {
          setErrors(emptyErrors);
          setIsPendingUpdateIamRoleButton(true);
        },
        // Railsに渡すパラメーターのケースはLintで指摘されても直せないので一時的に無効化します。
        data: {
          group_id: group.id,
          aws_account_id: awsAccount.id,
        },
        dataType: "json",
        method: "POST",
        url: "/iam_role_update",
      })
      .done((data) => {
        setCfnTemplateCacheId(data.cfn_template_cache_id);
        if (window.open(data.iam_role_update_url, "_blank") == null) {
          window.console.log("IAM role update URL window blocked.");
        }
      })
      .fail(() => {
        const newErrors = Object.assign({}, emptyErrors);
        newErrors.updateIamRoleButton = [I18n.t("internal_server_error", { scope: I18N_SCOPE })];
        setErrors(newErrors);
      })
      .always(() => setIsPendingUpdateIamRoleButton(false));
  };

  /**
   * フォームの送信処理を行う関数
   */
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    jQuery
      .ajax({
        beforeSend: () => {
          setErrors(emptyErrors);
          setIsPendingSubmitButton(true);
        },
        data: {
          // Railsに渡すパラメーターのケースはLintで指摘されても直せないので一時的に無効化します。
          cfn_stack_id: cfnStackId,
          cfn_template_cache_id: cfnTemplateCacheId,
        },
        dataType: "json",
        method: "PATCH",
        url: `/groups/${group.id}/aws_accounts/${awsAccount.id}/update_cfn_template_cache`,
      })
      .done(() => {
        updatedCallback();
        setCfnTemplateCacheId(0);
        setCfnStackId("");
        setErrors(emptyErrors);
        showSuccessToast(I18n.t("succeeded", { scope: I18N_SCOPE }));
      })
      .fail((jqXHR, textStatus) => {
        const newErrors = Object.assign({}, emptyErrors);

        if (jqXHR.status == 422 && Object.prototype.hasOwnProperty.call(jqXHR.responseJSON, "error_code")) {
          // CFnスタックのステータスに問題がある、かつレスポンスJSONに error_code プロパティが存在する場合
          const errorCode = jqXHR.responseJSON.error_code;
          newErrors.cfnStackId = [I18n.t(errorCode, { scope: "javascript.aws_account.iam_role.error_code" })];
        } else {
          // 500エラーなどの場合
          newErrors.cfnStackId = [I18n.t("error", { message: textStatus, scope: I18N_SCOPE })];
        }
        setErrors(newErrors);
      })
      .always(() => setIsPendingSubmitButton(false));
  };

  return {
    cfnStackId,
    cfnTemplateCacheId,
    errors,
    isPendingUpdateIamRoleButton,
    isPendingSubmitButton,
    handleCancel,
    handleUpdateIamRoleButtonClick,
    handleSubmit,
    setCfnStackId,
  };
};

/**
 * AWSアカウント(IAMロール方式)の権限最新化ダイアログ
 *
 * 以下の責務を持ちます。
 *
 * - 権限最新化ダイアログ内のコンテンツの表示
 * - キャンセル操作時のコールバック呼び出し(ダイアログ表示/非表示の制御はしない)
 * - ダイアログ内のフォーム操作時のリクエスト送信
 * - 権限の最新化に成功した際のコールバック呼び出し(ダイアログ表示/非表示の制御はしない)
 * - 権限の最新化に成功際のToast UIの表示
 * - 権限の最新化に失敗した際のエラーメッセージの表示
 */
export const UpdateIamRoleDialog: React.FC<{
  awsAccount: AwsAccountWithIamRole;
  group: Group;
  isOpen: boolean;
  onCancel: () => void;
  onUpdated: () => void;
}> = (props): JSX.Element => {
  const {
    cfnStackId,
    cfnTemplateCacheId,
    errors,
    isPendingUpdateIamRoleButton,
    isPendingSubmitButton,
    handleCancel,
    handleUpdateIamRoleButtonClick,
    handleSubmit,
    setCfnStackId,
  } = useUpdateIamRoleLogic(props.awsAccount, props.group, props.onCancel, props.onUpdated);

  useEffect(() => {
    ReactModal.setAppElement("body");
  }, []);

  /**
   * フォームが送信可能な状態かどうかを返す
   */
  const isSubmittable = (): boolean => {
    return cfnStackId != "";
  };

  return (
    <ReactModal
      className="ca-group-aws-account-modal__content"
      isOpen={props.isOpen}
      onRequestClose={handleCancel}
      overlayClassName="ca-group-aws-account-modal__overlay"
      role="dialog"
    >
      <div className="text-left">
        <Heading />
        <Description />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="ca-group-aws-accounts-update-iam-role-form__fields">
          <div className="ca-group-aws-accounts-update-iam-role-form__fields-row">
            <div className="ca-group-aws-accounts-update-iam-role-form__fields-left-col">
              <div className="ca-group-aws-accounts-update-iam-role-form__field-heading">
                {I18n.t("step1", { scope: I18N_SCOPE })}
              </div>
            </div>
            <div className="ca-group-aws-accounts-update-iam-role-form__fields-right-col">
              {I18n.t("step1_note", { scope: I18N_SCOPE, awsAccountNumber: props.awsAccount.accountNumber })}
            </div>
          </div>

          <div className="ca-group-aws-accounts-update-iam-role-form__fields-row">
            <div className="ca-group-aws-accounts-update-iam-role-form__fields-left-col">
              <div className="ca-group-aws-accounts-update-iam-role-form__field-heading">
                {I18n.t("step2", { scope: I18N_SCOPE })}
              </div>
            </div>
            <div className="ca-group-aws-accounts-update-iam-role-form__fields-right-col">
              <IamRoleUpdateButton
                disabled={isPendingUpdateIamRoleButton}
                errors={errors.updateIamRoleButton}
                onClick={handleUpdateIamRoleButtonClick}
              />
              <p className="ca-group__aws-account-form__note">
                {I18n.t("create_iam_role_note", { scope: I18N_SCOPE })}
              </p>
            </div>
          </div>

          <div className="ca-group-aws-accounts-update-iam-role-form__fields-row">
            <div className="ca-group-aws-accounts-update-iam-role-form__fields-left-col">
              <div className="ca-group-aws-accounts-update-iam-role-form__field-heading">
                {I18n.t("step3", { scope: I18N_SCOPE })}
              </div>
            </div>
            <div className="ca-group-aws-accounts-update-iam-role-form__fields-right-col">
              <p>{I18n.t("step3_note", { scope: I18N_SCOPE })}</p>
              <div className="ca-group-aws-accounts-update-iam-role-form__create-failed-manual">
                <ManualLink
                  linkText={I18n.t("common.manual.text.aws_account.iam_role_update_failed")}
                  url={I18n.t("common.manual.url.aws_account.iam_role_update_failed")}
                />
              </div>
              <CfnStackIdInputField
                disabled={cfnTemplateCacheId == 0}
                errors={errors.cfnStackId}
                name="cfn_stack_id"
                onChange={(value): void => {
                  setCfnStackId(value);
                }}
                placeholder=""
                required={true}
                value={cfnStackId}
              />
            </div>
          </div>
        </div>
        <div className="ca-btn-block__center marginT20">
          <SubmitButton disabled={!isSubmittable()} pending={isPendingSubmitButton} />
        </div>
      </form>
    </ReactModal>
  );
};

/**
 * フォームの説明
 */
const Description: React.FC = (): JSX.Element => {
  return (
    <React.Fragment>
      <p
        dangerouslySetInnerHTML={{
          __html: I18n.t("description", { scope: I18N_SCOPE }),
        }}
      ></p>
      <div className="marginB10 ca-callout ca-callout--warning">
        <ManualLink
          linkText={I18n.t("common.manual.text.aws_account.iam_role_update")}
          url={I18n.t("common.manual.url.aws_account.iam_role_update")}
        />
      </div>
    </React.Fragment>
  );
};

/**
 * フォームの見出し
 */
const Heading: React.FC = (): JSX.Element => {
  return (
    <h2>
      <span className="fa fa-key" />
      &nbsp;{I18n.t("heading", { scope: I18N_SCOPE })}
    </h2>
  );
};

/**
 * フォーム送信ボタン
 */
const SubmitButton: React.FC<{
  disabled: boolean;
  pending: boolean;
}> = (props): JSX.Element => {
  const label = I18n.t("submit", { scope: I18N_SCOPE });

  return (
    <button className="btn btn-primary btn-lg btn-warning" disabled={props.disabled || props.pending} type="submit">
      <span className="fa fa-check-circle fa-lg"></span>&nbsp;
      {label}
    </button>
  );
};
