import React, { useState } from "react";

import { showErrorToast, showSuccessToast } from "../../toast";

import { AwsAccount } from "./types";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.groups.aws_accounts.ebs_backup_check_selector";

/**
 * EBSバックアップチェック変更用セレクトボックス
 *
 * 以下の責務を持ちます。
 *
 * - EBSバックアップチェック変更用のSELECTタグの表示
 * - 設定変更時のサーバーへのリクエスト送信
 * - リクエスト成功時のSELECTタグの現在値の変更
 * - リクエスト失敗時のエラーメッセージの表示
 */
export const EbsBackupCheckSelector: React.FC<{
  /**
   * 対象のAWSアカウント
   */
  awsAccount: AwsAccount;
  /**
   * 変更に成功した後に呼び出すコールバック
   */
  onUpdated: () => void;
}> = (props): JSX.Element => {
  /**
   * EBSバックアップチェックの現在値と変更用の関数
   */
  const [value, setValue] = useState<boolean>(props.awsAccount.ebsBackupCheckEnabled);

  /**
   * サーバーに送信するデータを生成する関数
   */
  const buildData = (): object => {
    return { ebs_backup_check_enabled: (!value).toString() };
  };

  /**
   * EBSバックアップチェックの設定が変更された際に実行する処理
   */
  const handleOnChange = (event): void => {
    const oldValue = value;
    const newValue = event.target.value;

    setValue(newValue);

    jQuery
      .ajax({
        data: buildData(),
        dataType: "json",
        method: "PATCH",
        url: props.awsAccount.updateEbsBackupCheckEnabledUrl,
      })
      .done(() => {
        showSuccessToast(I18n.t("succeeded", { scope: I18N_SCOPE }));
        props.onUpdated();
      })
      .fail((jqXHR, textStatus) => {
        setValue(oldValue);
        const message = `${jqXHR.status} ${jqXHR?.responseJSON?.error ?? textStatus}`;
        showErrorToast(I18n.t("error", { message: message, scope: I18N_SCOPE }));
      });
  };

  // selectタグのaria-label属性は、テスト時にセレクトボックスを特定するために利用されています。
  return (
    <>
      <select
        aria-label={I18n.t("label", { scope: I18N_SCOPE })}
        className="ca-aws-account__aws-accounts-table__select form-control"
        onChange={handleOnChange}
        value={value.toString()}
      >
        <option value="true">✓ {I18n.t("enable_ebs_backup_check_true", { scope: I18N_SCOPE })}</option>
        <option value="false">✗ {I18n.t("enable_ebs_backup_check_false", { scope: I18N_SCOPE })}</option>
      </select>
    </>
  );
};
