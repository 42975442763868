import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';

const I18n = window.I18n; // i18n-js

/**
 * プロフィール画像選択ボタン
 *
 * プロフィール画像ファイルを選択する操作を行うためのボタンです。
 * 以下の責務を持ちます。
 *
 * - ボタンのルック＆フィール
 * - EventEmitterへのイベントの送信 (EVENT_SELECT_IMAGE)
 *
 * プロフィール画像の選択が具体的にどのような処理となるかについての責務は持ちません。
 */
export default class SelectImageButton extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {Object} emitter EventEmitter
   */
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
    });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <button
        className="ca-profile-image-uploader__upload-button btn btn-default"
        onClick={this.handleClick.bind(this)}
        type="button"
      >
        <i className="fa fa-refresh"></i> {I18n.t('select_image', { scope: Constants.I18N_SCOPE })}
      </button>
    );
  }

  /**
   * ボタンがクリックされた際の処理を行います。
   *
   * @private
   * @param {Event} event
   */
  handleClick(event) {
    event.preventDefault();
    this.props.emitter.emit(Constants.EVENT_SELECT_IMAGE);
  }
}
