import PropTypes from 'prop-types';
import React from 'react';

/**
 * ポリシーログの結果アイコン。
 */
export default class ResultIcon extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {string} classes FontAwesome用のclass属性値("fa"は不要)
   */
  static get propTypes() {
    return({
      classes: PropTypes.string.isRequired,
    });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return <span className={`fa ${this.props.classes}`} aria-hidden="true"></span>;
  }
}
