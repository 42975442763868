import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import Modal from './Modal.jsx';

const I18n = window.I18n; // i18n-js

/**
 * 結果表示モーダルダイアログ。
 */
export default class ResultModal extends React.Component {
  /**
   * プロパティの定義を返します。
   *
   * @return {Object}
   * @property {string} content 表示するコンテンツ
   * @property {Object} emitter EventEmitterのインスタンス
   */
  static get propTypes() {
    return({
      content: PropTypes.string.isRequired,
      emitter: PropTypes.object.isRequired,
    });
  }

  /**
   * コンストラクタ。
   *
   * @override
   */
  constructor(props) {
    super(props);

    this.cancelText = I18n.t('result_modal.cancel', { scope: Constants.I18N_SCOPE });
  }

  /**
   * @override
   * @return {ReactElement}
   */
  render() {
    return(
      <Modal
        content={
          <div className="api-key-modal__highlight">
            <div className="modal-content">
              {this.props.content}
            </div>
          </div>
        }
        id="result-modal"
        isOpen={true}
        needsPositiveButton={false}
        negativeButtonClass="btn btn-sm"
        negativeButtonValue={this.cancelText}
        onClose={() => this.props.emitter.emit(Constants.EVENT_CLOSE_MODAL)}
      />
    );
  }
}
