import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';

const I18n = window.I18n; // i18n-js

// ポリシートグルコンポーネント
// ポリシーセットの有効ボタンのON/OFFを一括変更します。
class PoliciesAllToggle extends React.Component {
  static get propTypes() {
    return ({
      emitter: PropTypes.object.isRequired
    });
  }

  constructor(props) {
    super(props);
    this.toAllOnOff = this.toAllOnOff.bind(this);
  }

  render() {
    return (
      <div className="policy-all-toggle clearfix">
        <div className="all-toggle">
          <div className="btn btn-default toggle-on" onClick={ () => this.toAllOnOff(true) }>
            {I18n.t('javascript.policy_set_form.label_for_alltoggle_on')}
          </div>
          <div className="btn btn-default toggle-off" onClick={ () => this.toAllOnOff(false) }>
            {I18n.t('javascript.policy_set_form.label_for_alltoggle_off')}
          </div>
        </div>
      </div>
    );
  }

  toAllOnOff(allEnabled) {
    this.props.emitter.emit(Constants.EVENT_ALL_TOGGLE_POLICY_ENABLE, allEnabled);
  }
}

export default PoliciesAllToggle;
