// 後処理割り当て関連コンポーネントで利用する定数

export default {
  // EventEmitterで扱うイベントの識別子
  EVENT_CHANGE_POST_PROCESS_SELECTOR: 'changePostProcessSelector',
  EVENT_CLICK_DELETE_BUTTON: 'clickDeleteButton',
  EVENT_CLICK_REFRESH_BUTTON: 'clickRefreshButton',
  EVENT_OPEN_DIALOG: 'openDialog',
  EVENT_CLOSE_DIALOG: 'closeDialog',

  // グループIDがセットされているINPUT要素のID
  GROUP_INPUT_ID: 'trigger_job_group_id',

  // I18nメッセージリソースのスコープ
  I18N_SCOPE: 'javascript.post_process_assignments_panel',

  URL_NEW_POST_PROCESS: '/post_processes/new',

  // 後処理割り当てパネルのヘッダー部に表示するアイコンのクラス
  POST_PROCESS_ICON: {
    COMPLETED: 'fa-check-circle',
    FAILED: 'fa-times'
  },

  // 後処理における成功時と失敗地の識別子
  POST_PROCESS_TYPE: {
    COMPLETED: 'completed',
    FAILED: 'failed'
  },

  // ジョブ作成/編集フォーム要素のID
  JOB_FORM_ID: 'make-form',
};
