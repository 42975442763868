import React from 'react';
import PropTypes from 'prop-types';

/**
 * サーバーとの通信中に表示するローディングアイコン。
 */
export default class Spinner extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @return {Object}
   * @property {boolean} isLoading サーバーと通信中かどうか
   */
  static get propTypes() {
    return({
      isLoading: PropTypes.bool.isRequired,
    });
  }

  /**
   * @override
   * @return {ReactElement}
   */
  render() {
    return this.props.isLoading
      ? <i id="loading" className="fa fa-spinner fa-spin fa-5x api-key-loading-spinner"></i>
      : null;
  }
}
