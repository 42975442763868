/**
 * TODO: 機能フラグ v27_job_workflow_improvement が不要になったらこのコンポーネントを削除する
 */

import React from "react";

const I18n = window.I18n; // i18n-js

/**
 * 手動トリガーの詳細を表示します。
 * @public
 */
export const OldImmediateExecutionTriggerDetails = (): JSX.Element => {
  return (
    <div className="ca-workflow-trigger">
      <div className="ca-workflow-trigger__icon-area">
        <div className="ca-workflow-trigger__icon">
          <i className="fa-duotone fa-solid fa-bullseye-pointer"></i>
        </div>
        {I18n.t("common.rule_types_abb.immediate_execution")}
        <br />
        {I18n.t("activerecord.attributes.trigger_job.rule_type")}
      </div>
      <div className="ca-workflow-trigger__info-area">
        <div className="ca-workflow-trigger__item">
          <div className="ca-workflow-trigger__label">{I18n.t("javascript.job_workflow_form.execution_timing")}</div>
          <div className="ca-workflow-trigger__data">
            {I18n.t("javascript.job_workflow_form.execution_timing_description")}
          </div>
        </div>
      </div>
    </div>
  );
};
