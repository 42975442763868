import React from "react";

import Icon from "../Icon";

import { ErrorArea } from "./ErrorArea";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.aws_account.iam_role";

/**
 * IAMロール最新化用CFnスタック作成ページを表示するボタン
 */
export const IamRoleUpdateButton: React.FC<{
  disabled: boolean;
  errors: string[];
  onClick: () => void;
}> = (props) => {
  return (
    <div className="ca-group__aws-account-form__right-col">
      <button className="btn btn-default" disabled={props.disabled} onClick={props.onClick} type="button">
        <Icon name="external-link" />
        <span className="marginL5">{I18n.t("update_iam_role", { scope: I18N_SCOPE })}</span>
      </button>
      <ErrorArea errors={props.errors} />
    </div>
  );
};
