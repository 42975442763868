import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import ErrorMessages from '../ErrorMessages.jsx';

const _ = window._; // Underscore.js
const I18n = window.I18n; // i18n-js
const I18N_SCOPE = `${Constants.I18N_SCOPE}.region_field`;

// リージョン選択フィールドコンポーネント
//
// 対象が新規レコードの場合はリージョンのセレクトボックスを表示し、既存レコードの場合は
// 現在のリージョン名の表示のみを行います。
//
class RegionField extends React.Component {
  static get propTypes() {
    return({
      // セレクトボックスの選択肢となるリージョン
      regions: PropTypes.arrayOf(PropTypes.array).isRequired,
      emitter: PropTypes.object.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
      // セレクトボックスの初期値(既存レコードの場合はレコードに設定されているリージョン識別子)
      initialValue: PropTypes.string.isRequired,
      // 対象が新規レコードかどうか
      newRecord: PropTypes.bool.isRequired
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.initialValue
    };
    this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // regionsプロパティまたはerrorsプロパティ、valueステートが変化した場合のみ再描画を行う
    return (
      nextProps.regions.join() !== this.props.regions.join()
        || nextProps.errors.join() !== this.props.errors.join()
        || nextState.value !== this.state.value
    );
  }

  /**
   * エラーがあるかどうかを返します。
   *
   * @return {boolean}
   */
  hasError() {
    return this.props.errors.length > 0;
  }

  render() {
    const errorClasses = this.hasError() ? 'has-error' : '';
    const label = I18n.t('activerecord.attributes.policy_set.region');

    return(
      <div className={`form-group ${errorClasses}`}>
        <div className="row">
          <div className="col-xs-6">
            <label htmlFor="policy_set-region">
              {label}
            </label>
            {this.props.newRecord ? this.renderContentForNewRecord() : this.renderContentForExistingRecord()}
          </div>
        </div>
      </div>
    );
  }

  /**
   * 既存レコード用のコンテンツを表示します。
   */
  renderContentForExistingRecord() {
    const region = _.find(this.props.regions, item => item[1] === this.props.initialValue);
    const name = region[0];
    const identifier = region[1];

    return(<p>{name} ({identifier})</p>);
  }

  /**
   * 新規レコード用のコンテンツを表示します。
   */
  renderContentForNewRecord() {
    return(
      <div>
        <select id="policy_set-region"
          className="form-control"
          onChange={this.handleChange}
          required={true}
          value={this.state.value}>
          {this.renderOptions()}
        </select>
        <ErrorMessages messages={this.props.errors} />
      </div>
    );
  }

  renderOptions() {
    const blankOption = [I18n.t('blank_option', { scope: I18N_SCOPE }), ''];
    const options = [blankOption].concat(this.props.regions);

    return(options.map((item) => {
      const label = item[0];
      const value = item[1];
      return(
        <option key={value} value={value}>{label}</option>
      );
    }));
  }

  handleChange(event) {
    const value = event.target.value;
    this.setState({ value: value }, () => {
      this.props.emitter.emit(Constants.EVENT_CHANGE_REGION, value);
    });
  }
}

export default RegionField;
