// APIキー設定関連コンポーネントで利用する定数

export default {
  // EventEmitterで扱うイベントの識別子

  // Ajax関連
  EVENT_CREATE_API_KEY: 'createApiKey',
  EVENT_DELETE_API_KEY: 'deleteApiKey',
  EVENT_RECREATE_API_KEY: 'recreateApiKey',

  // モーダルダイアログ関連
  EVENT_CLOSE_MODAL: 'closeModal',
  EVENT_OPEN_CREATE_MODAL: 'openCreateModal',
  EVENT_OPEN_DELETE_MODAL: 'openDeleteModal',
  EVENT_OPEN_DISPLAY_MODAL: 'openDisplayModal',
  EVENT_OPEN_RECREATE_MODAL: 'openRecreateModal',
  EVENT_OPEN_RESULT_MODAL: 'openResultModal',

  // I18nメッセージリソースのスコープ
  I18N_SCOPE: 'javascript.api_key_settings',
};
