import React from 'react';
import PropTypes from 'prop-types';
import EventEmitter from 'events';

import Constants from './Constants.js';
import PolicySetForm from './PolicySetForm.jsx';

const $ = window.jQuery;
const _ = window._; // Underscore.js

// ポリシーセットフォームコンテナ
// ポリシーセットフォームに関するロジックについての責務を持ちます。
// フォームのルック＆フィールに関する責務は子コンポーネントに委譲します。
//
// プロパティ:
// awsAccounts - AWSアカウントの選択肢。Array。
// group - 選択されたグループ。idとnameプロパティのみを持つObject。
// policies - Array
// policySet - 編集するポリシーセット。Object。idプロパティがnullの場合は新規作成となる。
// policyTemplates - ポリシーセットテンプレートに含まれるポリシーテンプレートの配列。Array。
// regions - リージョンの選択肢。Array
// url - フォームの送信先URL。
//
class PolicySetFormContainer extends React.Component {
  /**
   * デフォルトのプロパティ値を返します。
   * React内部から呼び出されます。
   *
   * @return {object}
   */
  static get defaultProps() {
    return({
      policySet: null
    });
  }

  /**
   * プロパティのバリデーション定義を返します。
   * React内部から呼び出されます。
   *
   * @return {object}
   */
  static get propTypes() {
    return({
      awsAccounts: PropTypes.arrayOf(PropTypes.array).isRequired,
      completedAssignments: PropTypes.object.isRequired,
      defaultLanguage: PropTypes.string.isRequired,
      defaultTimeZone: PropTypes.string.isRequired,
      failedAssignments: PropTypes.object.isRequired,
      group: PropTypes.object.isRequired,
      groups: PropTypes.arrayOf(PropTypes.array).isRequired,
      isSlackIntegrated: PropTypes.bool.isRequired,
      isUserCanManageIntegrations: PropTypes.bool.isRequired,
      loadingImagePath: PropTypes.string.isRequired,
      overseas: PropTypes.bool.isRequired,
      policies: PropTypes.arrayOf(PropTypes.object).isRequired,
      policySet: PropTypes.object.isRequired,
      policyTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
      postProcesses: PropTypes.arrayOf(PropTypes.object).isRequired,
      regions: PropTypes.arrayOf(PropTypes.array).isRequired,
      url: PropTypes.string.isRequired,
      triggerTypes: PropTypes.object.isRequired,
      postProcessesUrl: PropTypes.string.isRequired,
      regionSet: PropTypes.arrayOf(PropTypes.array).isRequired,
      services: PropTypes.arrayOf(PropTypes.array).isRequired,
      sqsQueuesPath: PropTypes.string.isRequired,
      timeZones: PropTypes.arrayOf(PropTypes.array).isRequired,
    });
  }

  /**
   * コンストラクタ。
   * React内部から呼び出されます。
   */
  constructor(props) {
    super(props);
    this.bindEventHandlers();
    this.initEventEmitter();
    this.initState(props);
  }

  /**
   * イベントハンドラ用関数を初期化します。
   *
   * 各イベントハンドラ関数の内部で、コンポーネント自身を this として参照できるようにします。
   */
  bindEventHandlers() {
    this.handleAddFailedPostProcessAssignment = this.handleAddFailedPostProcessAssignment.bind(this);
    this.handleAddSucceededPostProcessAssignment = this.handleAddSucceededPostProcessAssignment.bind(this);
    this.handleDeleteFailedPostProcessAssignment = this.handleDeleteFailedPostProcessAssignment.bind(this);
    this.handleDeleteSucceededPostProcessAssignment = this.handleDeleteSucceededPostProcessAssignment.bind(this);
    this.handleChangeAwsAccountId = this.handleChangeAwsAccountId.bind(this);
    this.handleChangePolicyParameter = this.handleChangePolicyParameter.bind(this);
    this.handleChangePolicySetName = this.handleChangePolicySetName.bind(this);
    this.handleChangeRegion = this.handleChangeRegion.bind(this);
    this.handleSubmitFormEvent = this.handleSubmitFormEvent.bind(this);
    this.handleTogglePolicyEnable = this.handleTogglePolicyEnable.bind(this);
    this.handleAllTogglePolicyEnable = this.handleAllTogglePolicyEnable.bind(this);
  }

  /**
   * EventEmitterを初期化します。
   *
   * 各コンポーネント内で発生するイベントとイベントハンドラ関数の割り当てを行います。
   * 生成されたEventEmitterオブジェクトは this.emitter にアサインされます。
   */
  initEventEmitter() {
    this.emitter = new EventEmitter;
    this.emitter.on(Constants.EVENT_ADD_FAILED_POST_PROCESS_ASSIGNMENT, this.handleAddFailedPostProcessAssignment);
    this.emitter.on(Constants.EVENT_ADD_SUCCEEDED_POST_PROCESS_ASSIGNMENT, this.handleAddSucceededPostProcessAssignment);
    this.emitter.on(Constants.EVENT_DELETE_FAILED_POST_PROCESS_ASSIGNMENT, this.handleDeleteFailedPostProcessAssignment);
    this.emitter.on(Constants.EVENT_DELETE_SUCCEEDED_POST_PROCESS_ASSIGNMENT, this.handleDeleteSucceededPostProcessAssignment);
    this.emitter.on(Constants.EVENT_CHANGE_AWS_ACCOUNT_ID, this.handleChangeAwsAccountId);
    this.emitter.on(Constants.EVENT_CHANGE_POLICY_PARAMETER, this.handleChangePolicyParameter);
    this.emitter.on(Constants.EVENT_CHANGE_POLICY_SET_NAME, this.handleChangePolicySetName);
    this.emitter.on(Constants.EVENT_CHANGE_REGION, this.handleChangeRegion);
    this.emitter.on(Constants.EVENT_SUBMIT_FORM, this.handleSubmitFormEvent);
    this.emitter.on(Constants.EVENT_TOGGLE_POLICY_ENABLE, this.handleTogglePolicyEnable);
    this.emitter.on(Constants.EVENT_ALL_TOGGLE_POLICY_ENABLE, this.handleAllTogglePolicyEnable);
  }

  /**
   * コンポーネントのステートを初期化します。
   */
  initState(props) {
    let state = {
      awsAccountId: '',
      awsAccountIdErrors: [],
      baseErrors: [],
      completedAssignments: props.completedAssignments,
      failedAssignments: props.failedAssignments,
      failedPostProcessIds: {}, // policyTemplateIdをキー、失敗時の後処理IDのArrayを値とするObject
      parameterValidities: {}, // パラメーターがフロントエンドバリデーションにおいて妥当かどうか(各パラメーターを識別するキーとboolean、trueなら妥当)
      pending: false, // フォームを送信中かどうか
      policyEnables: {}, // policyTemplateIdをキーに各ポリシーの状態を格納したObject
      policyParameters: {}, // policyTemplateIdをキーにしたObject。各プロパティはパラメーター名と値をプロパティとして持つObject
      policySetName: '',
      policySetNameErrors: [],
      region: '',
      regionErrors: [],
      succeededPostProcessIds: {} // policyTemplateIdをキー、成功時の後処理IDのArrayを値とするObject
    };
    // policyEnables は以下のような構造
    /*
    policyEnables: {
      1: true,
      2: true,
      3: false
    }
    */
    // policyParameter は以下のような構造
    /*
    policyParameters: {
      1: {
        "AccountNumber": "...",
        "ExternalId": "..."
      },
      2: {
        "AccountNumber": "...",
        "ExternalId": "..."
      },
      3: {
        "AccountNumber": "...",
        "ExternalId": "...",
        "AdminGroupNames": ["...", "..."]
      }
    }
    */

    this.props.policies.forEach((policy) => {
      const templateId = policy.policy_template_id;
      // policyEnables の値を初期化
      state.policyEnables[templateId] = policy.enable;
      // policyParameters, succeededPostProcesses, failedPostProcesses の値を初期化
      if (policy.id) {
        // ポリシーセットの編集時 (policyのプロパティはsnake caseであることに注意)
        state.policyParameters[templateId] = policy.parameters;
        state.succeededPostProcessIds[templateId] = _.map(policy.completed_post_processes, obj => obj.id);
        state.failedPostProcessIds[templateId] = _.map(policy.failed_post_processes, obj => obj.id);
      } else {
        // ポリシーセットの新規作成時
        const template = _.find(this.props.policyTemplates, (item) => item.id === templateId);
        let params = {};
        template.parameters.fields.forEach((parameter) => {
          if (parameter.type === Constants.PARAMETER_FIELD_TYPE_STRINGS) {
            params[parameter.name] = ["", "", "", "", ""];
          } else {
            params[parameter.name] = "";
          }
        });
        state.policyParameters[templateId] = params;

        state.succeededPostProcessIds[templateId] = [];
        state.failedPostProcessIds[templateId] = [];
      }
    });

    // プロパティに指定されたポリシーセットがIDを持っている場合は編集用にその値をステートにロードする
    if (this.props.policySet.id) {
      const policySet = this.props.policySet;
      // プロパティに設定されたオブジェクトのプロパティはsnake caseであることに注意
      state.awsAccountId = policySet.aws_account_id.toString();
      state.policySetName = policySet.name;
      state.region = policySet.region;
    }

    this.state = state;
  }

  /**
   * コンポーネントを描画します。
   * React内部から呼び出されます。
   */
  render() {
    return(
      <PolicySetForm
        {...this.state}
        awsAccounts={this.props.awsAccounts}
        completedAssignments={this.state.completedAssignments}
        defaultLanguage={this.props.defaultLanguage}
        defaultTimeZone={this.props.defaultTimeZone}
        emitter={this.emitter}
        failedAssignments={this.state.failedAssignments}
        group={this.props.group}
        groups={this.props.groups}
        isSlackIntegrated={this.props.isSlackIntegrated}
        isUserCanManageIntegrations={this.props.isUserCanManageIntegrations}
        loadingImagePath={this.props.loadingImagePath}
        newRecord={this.isNewRecord()}
        policyTemplates={this.props.policyTemplates}
        postProcesses={this.props.postProcesses}
        regions={this.props.regions}
        triggerTypes={this.props.triggerTypes}
        postProcessesUrl={this.props.postProcessesUrl}
        regionSet={this.props.regionSet}
        services={this.props.services}
        sqsQueuesPath={this.props.sqsQueuesPath}
        timeZones={this.props.timeZones}
        url={this.props.url}
        overseas={this.props.overseas}
      />
    );
  }

  /**
   * ポリシーに失敗時の後処理が追加された際に呼び出されるイベントハンドラ。
   *
   * @param {number} policyTemplateId
   * @param {Object[]} assignments - 現在の後処理割り当ての配列
   */
  handleAddFailedPostProcessAssignment(policyTemplateId, assignments) {
    let newValue = this.state.failedPostProcessIds;
    if (newValue[policyTemplateId] === undefined) {
      newValue[policyTemplateId] = [];
    }
    newValue[policyTemplateId] = assignments.map((entry) => entry.id);
    this.setState({ failedPostProcessIds: newValue });
  }

  /**
   * ポリシーに成功時の後処理が追加された際に呼び出されるイベントハンドラ。
   *
   * @param {number} policyTemplateId
   * @param {Object[]} assignments - 現在の後処理割り当ての配列
   */
  handleAddSucceededPostProcessAssignment(policyTemplateId, assignments) {
    let newValue = this.state.succeededPostProcessIds;
    if (newValue[policyTemplateId] === undefined) {
      newValue[policyTemplateId] = [];
    }
    newValue[policyTemplateId] = assignments.map((entry) => entry.id);
    this.setState({ succeededPostProcessIds: newValue });
  }

  /**
   * ポリシーから失敗時の後処理が削除された際に呼び出されるイベントハンドラ。
   *
   * @param {number} policyTemplateId
   * @param {Object[]} assignments - 現在の後処理割り当ての配列
   */
  handleDeleteFailedPostProcessAssignment(policyTemplateId, assignments) {
    let newValue = this.state.failedPostProcessIds;
    newValue[policyTemplateId] = assignments.map((entry) => entry.id);
    this.setState({ failedPostProcessIds: newValue });
  }

  /**
   * ポリシーから成功時の後処理が削除された際に呼び出されるイベントハンドラ。
   *
   * @param {number} policyTemplateId
   * @param {Object[]} assignments - 現在の後処理割り当ての配列
   */
  handleDeleteSucceededPostProcessAssignment(policyTemplateId, assignments) {
    let newValue = this.state.succeededPostProcessIds;
    newValue[policyTemplateId] = assignments.map((entry) => entry.id);
    this.setState({ succeededPostProcessIds: newValue });
  }

  /**
   * AWSアカウントIDの値が変更された際に呼び出されるイベントハンドラ。
   *
   * @param {object} value - 新しい値
   */
  handleChangeAwsAccountId(value) {
    this.setState({ awsAccountId: value });
  }

  /**
   * パラメーターの値が変更された際に呼び出されるイベントハンドラ。
   * payloadには以下のキーと値が含まれます。
   *
   * policyTemplateId - パラメーターが含まれるポリシーテンプレートのID
   * name  - パラメーターの識別名(PolicyTemplate.parameters.fields.*.name の値)
   * type  - パラメーターの型(Constants.PARAMETER_FIELD_TYPE_* の値)
   * validity - 値が妥当かどうか(trueなら妥当)
   * value - 新しい値
   * index - パラメーターの型がリストの場合のインデックス
   *
   * @param {object} payload - 変更されたパラメーターに関する情報(name, type, value)
   */
  handleChangePolicyParameter(payload) {
    const {policyTemplateId, name, type, validity, value, index} = payload;
    let newValue = this.state.policyParameters;
    let newParameterValidities = this.state.parameterValidities;

    if (type === Constants.PARAMETER_FIELD_TYPE_STRINGS) {
      // パラメーターが文字列リスト型の場合にstateに設定する値
      const identifier = `${policyTemplateId}-${name}-${index}`;
      newValue[policyTemplateId][name][index] = value;
      newParameterValidities[identifier] = validity;
    } else {
      // パラメーターが文字列型の場合にstateに設定する値
      const identifier = `${policyTemplateId}-${name}`;
      newValue[policyTemplateId][name] = value;
      newParameterValidities[identifier] = validity;
    }

    this.setState({
      parameterValidities: newParameterValidities,
      policyParameters: newValue
    });
  }

  /**
   * ポリシーセット名の値が変更された際に呼び出されるイベントハンドラ。
   *
   * @param {object} value - 新しい値
   */
  handleChangePolicySetName(value) {
    this.setState({ policySetName: value });
  }

  /**
   * リージョンの値が変更された際に呼び出されるイベントハンドラ。
   *
   * @param {object} value - 新しい値
   */
  handleChangeRegion(value) {
    this.setState({ region: value });
  }

  /**
   * フォームの送信時に呼び出されるイベントハンドラ。
   */
  handleSubmitFormEvent() {
    // ステートを通信中にしてからAjaxを実行します
    this.setState({
      baseErrors: [],
      pending: true
    }, this.postRequest);
  }

  /**
   * ポリシーの有効状態が切り替えられた時に呼び出されるイベントハンドラ。
   *
   * @param {number} policyTemplateId
   */
  handleTogglePolicyEnable(policyTemplateId) {
    let newValue = this.state.policyEnables;
    newValue[policyTemplateId] = !newValue[policyTemplateId];
    this.setState({ policyEnables: newValue });
  }

  handleAllTogglePolicyEnable(val) {
    let enables = this.state.policyEnables;
    for (const key in enables) {
      enables[key] = val;
    }
    this.setState({ policyEnables: enables });
  }

  /**
   * 対象の後処理が新しいレコードかどうかを返します。
   *
   * @return {boolean}
   */
  isNewRecord() {
    return this.props.policySet && this.props.policySet.id ? false : true;
  }

  /**
   * ポリシーセットを作成または更新するPOSTリクエストを行います。
   */
  postRequest() {
    let method = null;
    let data = {}; // JSON文字列としてリクエストボディに指定するデータのObject

    if (this.isNewRecord()) {
      // 新規レコードの作成時
      method = 'POST';
      data.policy_set = {
        aws_account_id: this.state.awsAccountId,
        name: this.state.policySetName,
        region: this.state.region
      };
    } else {
      // 既存レコードの更新時
      method = 'PATCH';
      data.policy_set = {
        name: this.state.policySetName
      };
    }

    // 作成時・更新時で共通の値
    data.group_id = this.props.group.id;
    data.policy_set_template_id = this.props.policySet.policy_set_template_id;
    data.policies = Object.keys(this.state.policyParameters).map(templateId => {
      // 各ポリシーのパラメーターについて、サーバーに送信できる形に値を調整する
      let policyParameters = $.extend(true, {}, this.state.policyParameters[templateId]);
      for (const key in policyParameters) {
        if (Array.isArray(policyParameters[key])) {
          // 値が空の配列の場合はダミーの空文字列を持つ配列が値として送信されるようにする
          // これを行わないと、disabledなポリシーに含まれるstrings型のパラメーターの値が
          // JSONとしてサーバーに送信される際にnullとなってしまう
          policyParameters[key] = this.arrayForRailsAction(policyParameters[key]);
        }
      }

      return({
        enable: this.state.policyEnables[templateId],
        policy_template_id: templateId,
        parameters: policyParameters,
        completed_post_process_ids: this.arrayForRailsAction(this.state.succeededPostProcessIds[templateId]),
        failed_post_process_ids: this.arrayForRailsAction(this.state.failedPostProcessIds[templateId])
      });
    });

    // policy_sets#create または policy_sets#update にJSONをPOSTする
    fetch(this.props.url, {
      credentials: 'same-origin', // Cookieを送信する
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') // RailsのCSRFチェックを通すため
      },
      body: JSON.stringify(data)
    })
    .then(response => {
      if (!response.ok) {
        // ステータスコードが4xx/5xxの場合は、レスポンスJSONから取り出したエラーメッセージを
        // 付与した例外を発生させる
        return response.json().then((error) => {
          const exception = new Error(error.errors.base);
          exception.messages = error.errors;
          throw exception;
        });
      }
      return response;
    })
    .then(response => response.json()) // レスポンスボディをJSONとしてパースする
    .then(data => window.location = data.url) // リクエスト成功時はレスポンスで指定されたURLにリダイレクトする
    .catch(exception => {
      // リクエストが失敗した場合は例外オブジェクトからエラーメッセージを取り出してstateに
      // 設定することで、Reactコンポーネントにエラーを表示する
      // exception.messages.XXX の値は常に Array であることに注意
      this.setState({
        pending: false,
        awsAccountIdErrors: exception.messages.awsAccountId || [],
        baseErrors: exception.messages.base || [],
        policySetNameErrors: exception.messages.name || [],
        regionErrors: exception.messages.region || []
      });
    });
  }

  /**
   * 指定された配列をRailsのアクションにJSONとして送信した際に、セキュリティの警告および
   * データの強制変換が行われないようにしたものを返します。
   *
   * Railsのアクションに空のarrayを含むJSONをパラメーターとしてPOSTすると、
   * セキュリティ保護のためにパラメーターの値がnilに書き換えられてしまうため、
   * 値が空になるarrayの場合には [""] のようにダミーの要素を含めた状態で送信するようにします。
   * See: http://guides.rubyonrails.org/security.html#unsafe-query-generation
   *
   * @param {array} value - 配列
   */
  arrayForRailsAction(value) {
    return value.length > 0 ? value : [""];
  }
}

export default PolicySetFormContainer;
