import React from 'react';
import PropTypes from 'prop-types';

const I18n = window.I18n; // i18n-js

/**
 * ユーザーアバターコンポーネント。
 *
 * ユーザーのプロフィール画像登録状況と言語設定に応じて、アバターとなるHTMLを
 * 表示するためのコンポーネントです。
 */
export default class UserAvatar extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {String} size 表示サイズ("medium" または "large"、デフォルトは"medium")
   * @property {Object} user 表示するユーザー(full_name, profile_image_url, initial)
   * @property {boolean} withTooltip Tooltip用の属性を含めるかどうか(デフォルトはtrue)
   */
  static get propTypes() {
    return({
      size: PropTypes.string,
      user: PropTypes.object,
      withTooltip: PropTypes.bool,
    });
  }

  /**
   * デフォルトのプロパティを返します。
   *
   * @public
   * @return {Object}
   * @property {boolean} withTooltip Tooltip用の属性を含めるかどうか
   */
  static get defaultProps() {
    return({
      size: "medium",
      user: {
        full_name: I18n.t('common.deleted_user'),
        initial: "?"
      },
      withTooltip: true,
    });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    let attrs = {
      className: 'ca-avatar',
    };

    if (this.props.size === 'large') {
      attrs.className += ' ca-avatar--large';
    }

    if (this.props.withTooltip) {
      // Bootstrapのtooltip用に属性を設定する
      attrs['data-toggle'] = 'tooltip';
      attrs['data-placement'] = 'bottom';
      attrs.title = this.props.user.full_name;
    }

    if (this.props.user.profile_image_url) {
      attrs.alt = this.props.user.full_name;
      attrs.src = this.props.user.profile_image_url;
      return <img {...attrs} />;
    }

    attrs.className += ' ca-avatar--text';
    return(
      <span {...attrs}>
        {this.props.user.initial}
      </span>
    );
  }
}
