import React from "react";
import PropTypes from "prop-types";

import Constants from "./Constants.js";
import ErrorMessages from "../ErrorMessages.jsx";

const I18n = window.I18n; // i18n-js

// Slackタイムゾーン入力フィールド
//
// 入力欄の値が変更されると、emitterプロパティに指定されたEventEmitterに
// Constants.EVENT_CHANGE_FORM_VALUE イベントを送信します。
//
class SlackTimeZoneField extends React.Component {
  /**
   * プロパティのバリデーション定義を返します。
   * React内部から呼び出されます。
   *
   * @return {object}
   * @property {object} emitter - EventEmitter
   * @property {Array<string>} errors - エラーメッセージ
   * @property {Array<Array<string>>} timeZones
   *   - タイムゾーンの選択肢の情報をあらわす二次元配列。各要素は `["Asia/Tokyo", "Tokyo"]` のような配列。
   * @property {string} value - 現在値
   */
  static get propTypes() {
    return {
      emitter: PropTypes.object.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
      timeZones: PropTypes.arrayOf(PropTypes.array).isRequired,
      value: PropTypes.string.isRequired,
    };
  }

  /**
   * 表示すべきエラーメッセージがあるかどうかを返します。
   *
   * @return {boolean}
   */
  hasError() {
    return this.props.errors.length > 0;
  }

  /**
   * コンポーネントを描画します。
   * React内部から呼び出されます。
   *
   * @return {ReactElement}
   */
  render() {
    const label = I18n.t("activerecord.attributes.post_process.slack_time_zone");

    return(
      <div className="form-group">
        <div className="post-process-setting">
          <div className="post-process-setting__label">
            <div className="post-process-setting__label__text">
              <label htmlFor="PostProcess-slackTimeZone">
                {label}
              </label>
              <span className="required">*</span>
            </div>
          </div>
          {this.renderSelectField()}
        </div>
      </div>
    );
  }

  /**
   * タイムゾーンのセレクトボックスを含むDIVを返します。
   *
   * @return {ReactElement}
   */
  renderSelectField() {
    const errorClass = this.hasError() ? "error" : "";

    return (
      <div className="post-process-setting__form">
        <select id="PostProcess-slackTimeZone"
          className={`form-control ${errorClass}`}
          onChange={(event) => {
            this.props.emitter.emit(Constants.EVENT_CHANGE_FORM_VALUE, "slackTimeZone", event.target.value);
          }}
          value={this.props.value}
        >
          {this.renderOptions()}
        </select>
        <ErrorMessages messages={this.props.errors} />
      </div>
    );
  }

  /**
   * タイムゾーンのセレクトボックスに含まれる選択肢の配列を返します。
   *
   * @return {Array<ReactElement>}
   */
  renderOptions() {
    return this.props.timeZones.map((item) => {
      const label = item[0];
      const value = item[1];
      return (
        <option key={value} value={value}>
          {label}
        </option>
      );
    });
  }
}

export default SlackTimeZoneField;
