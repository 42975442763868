import Modal from 'react-modal';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../Icon.jsx';

import Constants from './Constants.js';

const I18n = window.I18n; // i18n-js

/**
 * 削除ボタン
 */
export default class DeleteButton extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {number} generateRequestId 削除対象のリスト作成リクエストID
   * @property {function(event: SyntheticEvent, ?param)} onClick onClickイベントで呼び出す関数
   * @property {number} serialNumber 削除対象のリスト作成リクエストの通し番号
   */
  static get propTypes() {
    return({
      generateRequestId: PropTypes.number.isRequired,
      onClick: PropTypes.func.isRequired,
      serialNumber: PropTypes.number.isRequired,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClickDeleteButton = this.handleClickDeleteButton.bind(this);

    this.labelText = I18n.t('label_for_delete_button', { scope: Constants.I18N_SCOPE });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <React.Fragment>
        <button
          className="btn ca-workspaces-lists-table__delete-button"
          onClick={this.openModal}
        >
          <Icon name="trash-o" />
          <span className="sr-only">{this.labelText}</span>
        </button>

        <Modal
          appElement={document.getElementById('workspaces-lists-container')}
          className="ca-workspaces-lists-delete-modal__content"
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          overlayClassName="ca-workspaces-lists-delete-modal__overlay"
          role="dialog"
        >
          <div className="ca-workspaces-lists-delete-modal__content-message">
            <p>
              {I18n.t("delete_confirmation", { scope: Constants.I18N_SCOPE })}
            </p>
            <p>
              <strong>
                {I18n.t(
                  "delete_list_info",
                  {
                    scope: Constants.I18N_SCOPE,
                    id: this.props.serialNumber,
                  }
                )}
              </strong>
            </p>
          </div>
          <div className="ca-workspaces-lists-delete-modal__content-buttons">
            <button
              className="btn btn-cancel btn-negative"
              onClick={this.closeModal}
              type="button"
            >
              <i className="fa fa-ban marginR5" aria-hidden="true"></i>
              Cancel
            </button>
            <button
              className="btn btn-danger btn-delete"
              onClick={this.handleClickDeleteButton}
              type="button"
            >
              <i className="fa fa-trash-o marginR5" aria-hidden="true"></i>
              Delete
            </button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }

  /**
   * @private
   */
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  /**
   * @private
   */
  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  /**
   * @private
   */
  handleClickDeleteButton(event) {
    event.preventDefault();
    this.props.onClick(this.props.generateRequestId);
    this.closeModal();
  }
}
