import React from 'react';
import PropTypes from 'prop-types';

// エラーメッセージ表示コンポーネント
// 文字列の配列をもとにエラーメッセージを表示します。
//
//   <ErrorMessages messages={["Error message 1", "Error message 2"]} />
//
// エラーメッセージをHTMLとしてそのまま表示する場合は isHtml プロパティに true を指定します。
//
//   <ErrorMessages messages={["<strong>Error message</strong>"]} isHtml={true} />
//
// エラーメッセージをHTMLとしてそのまま表示しつつ行頭のアイコンも表示させたい場合は
// isHtmlWithIcon プロパティに true を指定します。
//
//   <ErrorMessages messages={["<strong>Error message</strong>"]} isHtmlWithIcon={true} />
//
// 出力されるULタグのclass属性値を追加したい場合は classes プロパティを指定します。
//
//   <ErrorMessages classes="marginT5" messages={["Error"]} />
//
class ErrorMessages extends React.Component {
  static get defaultProps() {
    return({
      classes: null,
      isHtml: false,
      isHtmlWithIcon: false,
    });
  }

  static get propTypes() {
    return({
      classes: PropTypes.string,
      isHtml: PropTypes.bool,
      isHtmlWithIcon: PropTypes.bool,
      messages: PropTypes.arrayOf(PropTypes.string).isRequired
    });
  }

  render() {
    if (this.props.messages.length === 0) {
      return null;
    }

    let errorMessages = null;
    if (this.props.isHtml) {
      errorMessages = this.props.messages.map((message) => <li key={message} dangerouslySetInnerHTML={{__html: message}}/>);
    } else if (this.props.isHtmlWithIcon) {
      const icon = '<i class="fa fa-exclamation-triangle"></i>';
      errorMessages = this.props.messages.map((message) => {
        const html = `${icon}${message}`;
        return <li key={message} dangerouslySetInnerHTML={{ __html: html }} />;
      });
    } else {
      errorMessages = this.props.messages.map((message) => <li key={message}><i className="fa fa-exclamation-triangle"></i>{message}</li>);
    }

    return(
      <ul className={`ca-error-messages ${this.props.classes}`}>
        {errorMessages}
      </ul>
    );
  }
}

export default ErrorMessages;
