// 後処理フォーム関連コンポーネントで利用する定数

export default {
  // EventEmitterで扱うイベントの識別子
  EVENT_CHANGE_FORM_VALUE: 'changeFormValue',
  EVENT_CHANGE_GROUP: 'changeGroup',
  EVENT_CHANGE_SERVICE: 'changeService',
  EVENT_CHANGE_SQS_AWS_ACCOUNT_ID: 'changeSqsAwsAccountId',
  EVENT_CHANGE_SQS_REGION: 'changeSqsRegion',
  EVENT_SUBMIT_FORM: 'submitForm',

  // I18nメッセージリソースのスコープ
  I18N_SCOPE: 'javascript.post_process_form',

  // バリデーション用正規表現(クライアントサイドでの簡易バリデーションのため)
  REGEXP_EMAIL: /^[^@]+@[^.]+\..+$/i,
  REGEXP_WEBHOOK_URL: /^https?:\/\/.+\..+$/i,

  // サービスの識別子
  SERVICE_EMAIL: 'email',
  SERVICE_SLACK: "slack",
  SERVICE_SQS: 'sqs',
  SERVICE_WEBHOOK: 'webhook'
};
