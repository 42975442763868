import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import UserTableSearchBox from './UserTableSearchBox.jsx';

const I18n = window.I18n; // i18n-js

/**
 * ユーザーテーブルヘッドルーム
 *
 * ユーザー一覧テーブルの上に表示する領域のコンポーネントで、以下の責務を持ちます。
 *
 * - ユーザー追加ボタンによるページ遷移
 */
export default class UserTableHeadroom extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {Object} emitter EventEmitterオブジェクト
   * @property {string} newUserPath ユーザー追加ボタンの遷移先URLパス
   * @property {number} totalUsers ユーザーの件数として表示する数
   */
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
      newUserPath: PropTypes.string.isRequired,
      totalUsers: PropTypes.number.isRequired,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.i18n_text = {
      add_user: I18n.t('add_user', { scope: Constants.I18N_SCOPE }),
    };
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <div className="ca-table-headroom">
        <div className="ca-table-headroom__primary">
          <UserTableSearchBox
            emitter={this.props.emitter}
            total={this.props.totalUsers}
          />
        </div>
        <div className="ca-table-headroom__secondary">
          {this.renderAddUserButton()}
        </div>
      </div>
    );
  }

  /**
   * ユーザー追加ボタンを返します。
   *
   * @private
   * @return {ReactElement}
   */
  renderAddUserButton() {
    return(
      <a
        className="btn btn-highlight"
        href={this.props.newUserPath}
      >
        <span className="fa fa-plus-square"></span> {this.i18n_text.add_user}
      </a>
    );
  }
}
