// ポリシーセットフォーム関連コンポーネントで利用する定数

export default {
  // EventEmitterで扱うイベントの識別子
  EVENT_ADD_FAILED_POST_PROCESS_ASSIGNMENT: 'addFailedPostProcessAssignment',
  EVENT_ADD_SUCCEEDED_POST_PROCESS_ASSIGNMENT: 'addSucceededPostProcessAssignment',
  EVENT_DELETE_FAILED_POST_PROCESS_ASSIGNMENT: 'deleteFailedPostProcessAssignment',
  EVENT_DELETE_SUCCEEDED_POST_PROCESS_ASSIGNMENT: 'deleteSucceededPostProcessAssignment',
  EVENT_CHANGE_AWS_ACCOUNT_ID: 'changeAwsAccountId',
  EVENT_CHANGE_POLICY_PARAMETER: 'changePolicyParameter',
  EVENT_CHANGE_POLICY_SET_NAME: 'changePolicySetName',
  EVENT_CHANGE_REGION: 'changeRegion',
  EVENT_SUBMIT_FORM: 'submitForm',
  EVENT_TOGGLE_POLICY_ENABLE: 'togglePolicyEnable',
  EVENT_ALL_TOGGLE_POLICY_ENABLE: 'toggleAllPolicyEnable',

  // I18nメッセージリソースのスコープ
  I18N_SCOPE: 'javascript.policy_set_form',

  // 入力欄を表示せずに値を空文字列のままにするパラメーター名の配列
  // このパラメーターの値は Policy モデルの after_validation で設定される。
  IMPLICIT_PARAMETER_NAMES: ['AccountNumber', 'ExternalId'],

  // パラメーター入力欄が文字列型の場合の識別子
  PARAMETER_FIELD_TYPE_STRING: 'string',
  PARAMETER_FIELD_TYPE_STRINGS: 'strings',

  // ポリシーの単価
  POLICY_UNIT_PRICE_JPY: 500,
  POLICY_UNIT_PRICE_USD: 5,

  URL_NEW_POST_PROCESS: '/post_processes/new'
};
