import React from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.group";

/**
 * グループ削除のダイアログです
 * DeleteGroup から利用されることを想定しています
 *
 * このコンポーネント内でサーバーにグループ削除のリクエストを送ります
 */
export default class DeleteGroupDialog extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {Object} emitter EventEmitterオブジェクト
   * @property {number} groupId グループId
   * @property {string} groupId グループ名
   * @property {boolean} showDeleteGroupDialog ダイアログが表示中かどうか
   */
  static get propTypes() {
    return {
      emitter: PropTypes.object.isRequired,
      groupId: PropTypes.number.isRequired,
      groupName: PropTypes.string.isRequired,
      showDeleteGroupDialog: PropTypes.bool.isRequired,
    };
  }

  /**
   * コンストラクタ
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
    };

    this.deleteGroup = this.deleteGroup.bind(this);
  }

  /**
   * @override
   */
  componentDidMount() {
    ReactModal.setAppElement("body");
  }

  /**
   * ajaxでサーバーにグループの削除リクエストを投げる
   *
   * @private
   */
  deleteGroup() {
    const groupPath = "/groups/";
    const url = groupPath + this.props.groupId;
    jQuery
      .ajax({
        url: url,
        method: "DELETE",
        dataType: "json",
      })
      .then(
        (data) => {
          window.location = data.url;
        },
        (data) => {
          if (data.status == 404) {
            // 既に削除済みだった場合はグループ一覧画面に遷移する
            window.location = groupPath;
          }
          this.setState({ errorMessage: data.responseJSON.message });
        }
      );
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    let error = "";
    if (this.state.errorMessage !== "") {
      error = (
        <p>
          <label className="error">{this.state.errorMessage}</label>
        </p>
      );
    }

    return (
      <ReactModal
        isOpen={this.props.showDeleteGroupDialog}
        onRequestClose={() => {
          this.props.emitter.emit("closeDialog");
        }}
        overlayClassName="ca-delete-group-modal__overlay"
        className="ca-delete-group-modal__content"
        role="dialog"
      >
        <div>
          <p>{I18n.t("confirm", { scope: I18N_SCOPE })}</p>
          <p>
            <strong>{this.props.groupName}</strong>
          </p>
          {error}
        </div>
        <div className="ca-delete-group-modal__content-buttons">
          <button
            type="button"
            className="btn btn-cancel btn-negative"
            onClick={() => {
              this.props.emitter.emit("closeDialog");
            }}
          >
            <span className="fa fa-ban marginR5"></span>Cancel
          </button>
          <button type="button" className="btn btn-danger btn-delete" onClick={this.deleteGroup}>
            <span className="fa fa-unlock-keyhole marginR5"></span>Delete
          </button>
        </div>
      </ReactModal>
    );
  }
}
