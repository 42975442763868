import EventEmitter from 'events';
import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import WorkSpacesLists from './WorkSpacesLists.jsx';

const I18n = window.I18n; // i18n-js

/**
 * WorkSpacesリスト一覧コンテナ
 */
export default class WorkSpacesListsContainer extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {number} defaultGroupId デフォルトで選択状態とするグループのID
   * @property {object} groups グループIDをキー、グループを値とするオブジェクト
   * @property {object[]} regions リージョンの配列
   * @property {string} resourcesPath WorkSpaces生成リクエストリソースのURLパス
   * @property {number[]} writableGroupIds 更新権限のあるグループIDの配列
   */
  static get propTypes() {
    return({
      defaultGroupId: PropTypes.number.isRequired,
      groups: PropTypes.object.isRequired,
      regions: PropTypes.arrayOf(PropTypes.object).isRequired,
      resourcesPath: PropTypes.string.isRequired,
      writableGroupIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.state = {
      currentAwsAccountId: '',
      currentGroupId: props.defaultGroupId,
      currentRegion: '',
      errorMessage: '',
      generateRequests: [],
      isPending: true,
      isSubmitted: false,
      submitErrorMessage: '',
    };

    this.handleChangeCurrentAwsAccountId = this.handleChangeCurrentAwsAccountId.bind(this);
    this.handleChangeCurrentGroup = this.handleChangeCurrentGroup.bind(this);
    this.handleChangeCurrentRegion = this.handleChangeCurrentRegion.bind(this);
    this.handleDeleteList = this.handleDeleteList.bind(this);
    this.handleReload = this.handleReload.bind(this);
    this.handleSubmitRequestForm = this.handleSubmitRequestForm.bind(this);

    this.initEventEmitter();
  }

  /**
   * コンポーネントがマウントされた際の処理を行います。
   * @public
   */
  componentDidMount() {
    this.fetchGenerateRequests();
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return (
      <React.StrictMode>
        <WorkSpacesLists
          currentAwsAccountId={this.state.currentAwsAccountId}
          currentGroupId={this.state.currentGroupId}
          currentRegion={this.state.currentRegion}
          emitter={this.emitter}
          errorMessage={this.state.errorMessage}
          generateRequests={this.state.generateRequests}
          groups={this.props.groups}
          isPending={this.state.isPending}
          isSubmitted={this.state.isSubmitted}
          regions={this.props.regions}
          submitErrorMessage={this.state.submitErrorMessage}
          writableGroupIds={this.props.writableGroupIds}
        />
      </React.StrictMode>
    );
  }

  /**
   * サーバーにリスト作成リクエストの削除リクエストを送信し、コンポーネントの状態を更新します。
   *
   * @private
   */
  deleteGenerateRequest(generateRequestId) {
    window.jQuery.ajax({
      method: 'POST',
      dataType: 'json',
      data: { _method: 'DELETE' },
      url: `${this.props.resourcesPath}/${generateRequestId}`,
      beforeSend: () => {
        this.setState({
          errorMessage: '',
          isPending: true,
        });
      },
    })
    .done((data) => {
      this.setState({
        isPending: false,
        generateRequests: data.workspaces_list_generate_requests,
      });
    })
    .fail(() => {
      this.setState({
        errorMessage: I18n.t('delete_failed', { scope: Constants.I18N_SCOPE }),
        isPending: false,
      });
    });
  }

  /**
   * サーバーからリスト作成リクエスト一覧のJSONを取得して、コンポーネントの状態を更新します。
   *
   * @private
   */
  fetchGenerateRequests() {
    window.jQuery.ajax({
      data: { group_id: this.state.currentGroupId },
      dataType: 'json',
      url: this.props.resourcesPath,
      beforeSend: () => {
        this.setState({
          errorMessage: '',
          isPending: true,
        });
      },
    })
    .done((data) => {
      this.setState({
        isPending: false,
        generateRequests: data.workspaces_list_generate_requests,
      });
    })
    .fail(() => {
      this.setState({
        errorMessage: I18n.t('fetch_failed', { scope: Constants.I18N_SCOPE }),
        isPending: false,
        generateRequests: [],
      });
    });
  }

  /**
   * リクエストフォーム内の現在選択中のAWSアカウントを変更します。
   *
   * @private
   * @param {string} awsAccountId 変更後のAWSアカウントID
   */
  handleChangeCurrentAwsAccountId(awsAccountId) {
    this.setState({ currentAwsAccountId: awsAccountId });
  }

  /**
   * 現在選択中のグループを変更します。
   *
   * @private
   * @param {number} groupId 変更後のグループID
   */
  handleChangeCurrentGroup(groupId) {
    this.setState({
      currentAwsAccountId: '',
      currentGroupId: Number(groupId),
      currentRegion: '',
      isSubmitted: false,
    }, this.fetchGenerateRequests);
  }

  /**
   * リクエストフォーム内の現在選択中のリージョンを変更します。
   *
   * @private
   * @param {string} region 変更後のリージョン識別子
   */
  handleChangeCurrentRegion(region) {
    this.setState({ currentRegion: region });
  }

  /**
   * 指定されたリスト作成リクエストの削除を行います。
   *
   * @private
   * @param {number} listId WorkSpacesリスト作成リクエストID
   */
  handleDeleteList(generateRequestId) {
    this.deleteGenerateRequest(generateRequestId);
  }

  /**
   * リスト作成リクエスト一覧の表示内容を更新します。
   */
  handleReload() {
    this.fetchGenerateRequests();
  }

  /**
   * リスト作成リクエストフォームを送信します。
   *
   * @private
   */
  handleSubmitRequestForm() {
    window.jQuery.ajax({
      method: 'POST',
      data: {
        aws_account_id: this.state.currentAwsAccountId,
        group_id: this.state.currentGroupId,
        region: this.state.currentRegion,
      },
      dataType: 'json',
      url: this.props.resourcesPath,
      beforeSend: () => {
        this.setState({
          isSubmitting: true,
        });
      },
    })
    .done(() => {
        this.setState(
          {
            isSubmitted: true,
          },
          () => {
            this.emitter.emit(Constants.EVENT_RELOAD);
          }
        );
      })
    .fail(() => {
      this.setState({
        submitErrorMessage: I18n.t('submit_failed', { scope: Constants.I18N_SCOPE }),
      });
    });
  }

  /**
   * EventEmitterを初期化します。
   *
   * 各コンポーネント内で発生するイベントとイベントハンドラ関数の割り当てを行います。
   * 生成されたEventEmitterオブジェクトは this.emitter にアサインされます。
   *
   * @private
   */
  initEventEmitter() {
    this.emitter = new EventEmitter;
    this.emitter.on(Constants.EVENT_CHANGE_CURRENT_AWS_ACCOUNT_ID, this.handleChangeCurrentAwsAccountId);
    this.emitter.on(Constants.EVENT_CHANGE_CURRENT_GROUP, this.handleChangeCurrentGroup);
    this.emitter.on(Constants.EVENT_CHANGE_CURRENT_REGION, this.handleChangeCurrentRegion);
    this.emitter.on(Constants.EVENT_DELETE_LIST, this.handleDeleteList);
    this.emitter.on(Constants.EVENT_RELOAD, this.handleReload);
    this.emitter.on(Constants.EVENT_SUBMIT_REQUEST_FORM, this.handleSubmitRequestForm);
  }
}
