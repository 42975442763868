const _ = window._; // Underscore.js

/**
 * ページ番号に関する演算を行うクラス
 */
export default class Paginator {
  /**
   * コンストラクタ。
   * @param {number} totalItems アイテムの総数
   * @param {number} perPage 1ページあたりのアイテム数
   * @param {number} currentPage 現在のページ番号
   */
  constructor(totalItems, perPage, currentPage) {
    this.totalItems = totalItems;
    this.perPage = perPage;
    this.currentPage = currentPage;
    this.window = 2; // 現在ページの他に前後に表示するページ番号の数
  }

  /**
   * 現在のページ番号を1つ減少します。
   * 最初のページに達している場合は何もしません。
   * 減少した後のページ番号を返します。
   * @return {number}
   */
  decrement() {
    if (this.isPreviousPageExist()) {
      return --this.currentPage;
    }
    return this.currentPage;
  }

  /**
   * 現在のページ番号を1つ増加します。
   * 最後のページに達している場合は何もしません。
   * 増加した後のページ番号を返します。
   * @return {number}
   */
  increment() {
    if (this.isNextPageExist()) {
      return ++this.currentPage;
    }
    return this.currentPage;
  }

  /**
   * 最初のページかどうかを返します。
   * @return {bool}
   */
  isFirstPage() {
    return(this.currentPage == 1);
  }

  /**
   * 最後のページかどうかを返します。
   * @return {bool}
   */
  isLastPage() {
    return(this.currentPage == this.totalPages());
  }

  /**
   * numが現在ページの次のページに該当するかどうかを返します。
   * @param {number} num ページ番号
   * @return {bool}
   */
  isNextPage(num) {
    if (this.isLastPage()) {
      return false;
    }
    return((this.currentPage + 1) == num);
  }

  /**
   * 次のページが存在するかどうかを返します。
   * @return {bool}
   */
  isNextPageExist() {
    return(this.currentPage < this.totalPages());
  }

  /**
   * numが現在ページの前のページに該当するかどうかを返します。
   * @param {number} num ページ番号
   * @return {bool}
   */
  isPreviousPage(num) {
    if (this.isFirstPage()) {
      return false;
    }
    return((this.currentPage - 1) == num);
  }

  /**
   * 前のページが存在するかどうかを返します。
   * @return {bool}
   */
  isPreviousPageExist() {
    return(this.currentPage > 1);
  }

  /**
   * 次のページのページ番号を返します。
   * 現在のページが最終ページの場合はnullを返します。
   * ページ番号またはnullを返します。
   * @return {number}
   */
  nextPageNum() {
    if (this.isNextPageExist()) {
      return(this.currentPage + 1);
    }
    return null;
  }

  /**
   * 前のページのページ番号を返します。
   * 現在のページが1ページ目の場合はnullを返します。
   * ページ番号またはnullを返します。
   * @return {number}
   */
  previousPageNum() {
    if (this.isPreviousPageExist()) {
      return(this.currentPage - 1);
    }
    return null;
  }

  /**
   * 描画するページ番号の配列を返します。
   */
  relevantPageNums() {
    const total = this.totalPages();
    let from = null;
    let to = null;
    if ((this.currentPage - this.window) <= 1) {
      from = 1;
      to = (this.window * 2) + 1;
    } else if (this.currentPage <= (total - this.window)) {
      from = this.currentPage - this.window;
      to = this.currentPage + this.window;
    } else {
      from = total - (this.window * 2);
      to = total;
    }
    const pages = _.range(from, to + 1);
    return _.chain(pages)
      .reject((num) => (num < 1) || (num > total))
      .sortBy((num) => num)
      .value();
  }

  /**
   * 総ページ数を返します。
   * @return {number}
   */
  totalPages() {
    let pages = parseInt(this.totalItems / this.perPage, 10);
    const surplus = this.totalItems % this.perPage;
    if (surplus > 0) {
      // アイテム数に余りがある場合は総ページ数に1を加える
      pages++;
    }
    return pages;
  }
}
