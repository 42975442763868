import EventEmitter from 'events';
import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import PostProcessAssignmentsPanel from './PostProcessAssignmentsPanel.jsx';

const I18n = window.I18n; // i18n-js
const _ = window._; // Underscore.js

/**
 * 後処理割り当てを表現するValueObject
 *
 * 「成功時の後処理割り当て」のように複数の「後処理割り当て」を1つのオブジェクトで表現し、
 * それらをまとめて比較するためのクラスです。
 *
 * 2つのAssignmentオブジェクトの比較においては、含まれる後処理IDがすべて一致しても
 * その順序が異なる場合は「異なる」と判断されます。後処理IDとその順序が完全に一致する場合のみ
 * 「等しい」と判断されます。
 */
class Assignments {
  /**
   * コンストラクタ。
   *
   * @param assignments {object[]} 後処理割り当ての配列
   */
  constructor(assignments) {
    this.assignments = assignments;
    this.idsString = assignments.map((a) => a.id).toString(); // "1,2,3" のようなstring
  }

  /**
   * other と比較して、後処理の割り当てが異なるかどうかを返します。
   *
   * @param other {Assignments} 比較対象
   */
  isNotSame(other) {
    return !this.isSame(other);
  }

  /**
   * other と比較して、後処理の割り当てが等しいかどうかを返します。
   *
   * @param other {Assignments} 比較対象
   */
  isSame(other) {
    return this.toString() == other.toString();
  }

  /**
   * 割り当てられている後処理のIDをカンマ区切りの文字列で返します。
   *
   * @return {string}
   */
  toString() {
    return this.idsString;
  }
}

/**
 * 後処理追加コンテナ
 *
 * 後処理割り当て要素の起点となるコンポーネントで、以下の責務を持ちます。
 *
 * - サーバーサイドから後処理一覧のJSONをXHRで取得する
 * - 後処理一覧のセレクトボックスの値が変更された際の処理
 * - 後処理追加ボタンがクリックされた際の処理
 * - 後処理更新ボタンがクリックされた際の処理
 *
 * 後処理の割り当てが変更されると、ジョブフォームに以下のようなカスタムイベントが送信されます。
 *
 * - イベント名: changePostProcessAssignments
 * - 第1引数: jQuery.Eventオブジェクト
 * - 第2引数: "completed_post_process_ids" または "failed_post_process_ids"
 * - 第3引数: "1,2,3" のような後処理IDをカンマ区切りで結合したstring
 *
 */
export default class PostProcessAssignmentsPanelContainer extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {Object[]} completedAssignments 割り当て済みとする成功時の後処理の配列
   * @property {string} defaultLanguage - 言語設定欄がある場合のデフォルトの言語(例 "ja")
   * @property {string} defaultTimeZone - タイムゾーン設定がある場合のデフォルトのタイムゾーン(例 "Tokyo")
   * @property {Object[]} failedInitialAssignments 割り当て済みとする失敗時の後処理の配列
   * @property {Array<Number>} groupIds グループIDの配列
   * @property {array[]} groups グループの配列
   * @property {boolean} isSlackIntegrated - Slackが外部サービスとして連携済みかどうか
   * @property {boolean} isUserCanManageIntegrations - ユーザーが外部サービス連携を管理できるかどうか
   * @property {sring} loadingImagePath ローディング中に表示する画像のURLパス
   * @property {Object[]} postProcesses 後処理の配列
   * @property {string} postProcessesUrls 後処理の更新時にXHRでアクセスするURL
   * @property {array[]} regions リージョンの配列
   * @property {number} selectedGroupId 選択されている状態とするグループID
   * @property {array[]} services サービスの配列
   * @property {string} sqsQueuesPath SQSキュー名を取得するためのパス
   * @property {Array<Array>} timeZones - タイムゾーンの選択肢
   */
  static get propTypes() {
    return({
      completedAssignments: PropTypes.arrayOf(PropTypes.object),
      defaultLanguage: PropTypes.string.isRequired,
      defaultTimeZone: PropTypes.string.isRequired,
      failedAssignments: PropTypes.arrayOf(PropTypes.object),
      groupIds: PropTypes.arrayOf(PropTypes.number),
      groups: PropTypes.arrayOf(PropTypes.array),
      isSlackIntegrated: PropTypes.bool.isRequired,
      isUserCanManageIntegrations: PropTypes.bool.isRequired,
      loadingImagePath: PropTypes.string.isRequired,
      postProcesses: PropTypes.arrayOf(PropTypes.object),
      postProcessesUrl: PropTypes.string.isRequired,
      regions: PropTypes.arrayOf(PropTypes.array).isRequired,
      selectedGroupId: PropTypes.number,
      services: PropTypes.arrayOf(PropTypes.array).isRequired,
      sqsQueuesPath: PropTypes.string.isRequired,
      timeZones: PropTypes.arrayOf(PropTypes.array).isRequired,
    });
  }

  /**
   * デフォルトのプロパティを返します。
   *
   * @public
   * @return {Object}
   */
  static get defaultProps() {
    return({
      completedAssignments: [],
      failedAssignments: [],
      groups: [],
      postProcesses: [],
      selectedGroupId: null
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.state = {
      completedAssignments: this.props.completedAssignments,
      failedAssignments: this.props.failedAssignments,
      refreshButtonDisabled: false,
      postProcesses: this.props.postProcesses,
      selectedGroupId: this.props.selectedGroupId,
      showDialog: false
    };

    this.initEventEmitter();
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.i18n_text = {
      completed: I18n.t('completed', { scope: Constants.I18N_SCOPE }),
      failed: I18n.t('failed', { scope: Constants.I18N_SCOPE })
    };
  }

  /**
   * コンポーネントがマウントされた際の処理を行います。
   * @public
   */
  componentDidMount() {
    window.jQuery(`#${Constants.JOB_FORM_ID}`).on('submit', this.handleFormSubmit);
    this.fetchPostProcesses();
    this.setGroupIdReloadEvent();
  }

  /**
   * コンポーネントが更新された後の処理を行います。
   * @public
   */
  componentDidUpdate(_prevProps, prevState) {
    const $form = window.jQuery(`#${Constants.JOB_FORM_ID}`);
    const currentCompleted = new Assignments(this.state.completedAssignments);
    const prevCompleted = new Assignments(prevState.completedAssignments);
    const currentFailed = new Assignments(this.state.failedAssignments);
    const prevFailed = new Assignments(prevState.failedAssignments);

    if (currentCompleted.isNotSame(prevCompleted)) {
      // 成功時の割り当て内容が変化している場合はフォームにカスタムイベントを送信する
      $form.trigger("changePostProcessAssignments", ["completed_post_process_ids", currentCompleted.toString()]);
    }

    if (currentFailed.isNotSame(prevFailed)) {
      // 失敗時の割り当て内容が変化している場合はフォームにカスタムイベントを送信する
      $form.trigger("changePostProcessAssignments", ["failed_post_process_ids", currentFailed.toString()]);
    }
  }

  /**
   * 共通後処理と選択状態になっているグループに属する後処理のみに絞り込みます。
   *
   * @private
   * @return {Object[]}
   */
  getFilteredPostProcesses() {
    const postProcesses = this.state.postProcesses.filter((postProcess) => {
        return(postProcess.shared_by_group || this.state.selectedGroupId == postProcess.group_id);
    });

    return(postProcesses);
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    const filteredPostProcesses = this.getFilteredPostProcesses();
    const {
      completedAssignments,
      failedAssignments,
      postProcesses,
      selectedGroupId,
      ...commonProps
    } = this.props;

    return(
      <React.StrictMode>
        <div className="row post-process-assignment-panel">
          <PostProcessAssignmentsPanel
            assignments={this.state.completedAssignments}
            emitter={this.emitter}
            groupIds={this.props.groupIds}
            postProcessType={Constants.POST_PROCESS_TYPE.COMPLETED}
            postProcesses={filteredPostProcesses}
            refreshButtonDisabled={this.state.refreshButtonDisabled}
            showDialog={this.state.showDialog}
            title={this.i18n_text.completed}
            titleIcon={Constants.POST_PROCESS_ICON.COMPLETED}
            {...commonProps}
          />
          <PostProcessAssignmentsPanel
            assignments={this.state.failedAssignments}
            emitter={this.emitter}
            groupIds={this.props.groupIds}
            postProcessType={Constants.POST_PROCESS_TYPE.FAILED}
            postProcesses={filteredPostProcesses}
            refreshButtonDisabled={this.state.refreshButtonDisabled}
            showDialog={this.state.showDialog}
            title={this.i18n_text.failed}
            titleIcon={Constants.POST_PROCESS_ICON.FAILED}
            {...commonProps}
          />
        </div>
      </React.StrictMode>
    );
  }

  /**
   * セレクトボックスで選択された後処理を割り当て済み後処理に追加して、コンポーネントの状態を更新します。
   *
   * @private
   * @param postProcessType {string} 成功時の後処理か失敗時の後処理かをあらわす文字列
   *                                 "completed" もしくは "failed"
   * @param postProcessId {number} 選択された後処理ID
   */
  handleChangeSelector(postProcessType, postProcessId) {
    const postProcess = this.state.postProcesses.find((postProcess) => {
      return(postProcess.id === postProcessId);
    });
    let completedAssignments = this.state.completedAssignments;
    let failedAssignments = this.state.failedAssignments;

    if (postProcessType == Constants.POST_PROCESS_TYPE.COMPLETED) {
      completedAssignments = _.uniq(completedAssignments.concat(postProcess), (assignment) => {
        return(assignment.id);
      });
    } else if (postProcessType == Constants.POST_PROCESS_TYPE.FAILED) {
      failedAssignments = _.uniq(failedAssignments.concat(postProcess), (assignment) => {
        return(assignment.id);
      });
    }

    this.setState({
      completedAssignments: completedAssignments,
      failedAssignments: failedAssignments
    });
  }

  /**
   * 後処理作成ダイアログを非表示状態にします。
   *
   * @private
   */
  handleCloseDialog() {
    this.setState({ showDialog: false }, this.fetchPostProcesses);
  }

  /**
   * 後処理作成ダイアログを表示状態にします。
   *
   * @private
   */
  handleOpenDialog() {
    this.setState({ showDialog: true });
  }

  /**
   * 指定された後処理を割り当て済み後処理から取り除いて、コンポーネントの状態を更新します。
   *
   * @private
   * @param postProcessType {string} 成功時の後処理か失敗時の後処理かをあらわす文字列
   *                                 "completed" もしくは "failed"
   * @param postProcessId {number} 選択された後処理ID
   */
  handleDeleteButton(postProcessType, postProcessId) {
    let completedAssignments = this.state.completedAssignments;
    let failedAssignments = this.state.failedAssignments;

    if (postProcessType == Constants.POST_PROCESS_TYPE.COMPLETED) {
      completedAssignments = _.reject(completedAssignments, (assignment) => {
        return(assignment.id === postProcessId);
      });
    } else if (postProcessType == Constants.POST_PROCESS_TYPE.FAILED) {
      failedAssignments = _.reject(failedAssignments, (assignment) => {
        return(assignment.id === postProcessId);
      });
    }

    this.setState({
      completedAssignments: completedAssignments,
      failedAssignments: failedAssignments
    });
  }

  /**
   * 成功時と失敗時の後処理割り当てのステートを元に、フォームに<input type="hidden">な要素を追加します。
   *
   * @private
   */
  handleFormSubmit() {
    const form = `#${Constants.JOB_FORM_ID}`;

    this.state.completedAssignments.forEach((assignment) => {
      window.jQuery('<input>').attr(
        { type: 'hidden', name: 'completed_post_process_id[]', value: assignment.id }
      ).appendTo(form);
    });
    this.state.failedAssignments.forEach((assignment) => {
      window.jQuery('<input>').attr(
        { type: 'hidden', name: 'failed_post_process_id[]', value: assignment.id }
      ).appendTo(form);
    });
  }

  /**
   * サーバーから後処理一覧のJSONを取得して、コンポーネントの状態を更新します。
   *
   * @private
   */
  fetchPostProcesses() {
    window.jQuery.ajax({
      url: this.props.postProcessesUrl,
      method: 'GET',
      dataType: 'json',
      beforeSend: () => {
        this.setState({
          refreshButtonDisabled: true
        });
      }
    }).done((data) => {
      this.setState({
        postProcesses: data.post_processes,
        refreshButtonDisabled: false
      });
    }).fail(() => {
      alert("Couldn't fetch Post Processes.");
      this.setState({
        postProcesses: [],
        refreshButtonDisabled: false
      });
    });
  }

  /**
   * EventEmitterを初期化します。
   *
   * 各コンポーネント内で発生するイベントとイベントハンドラ関数の割り当てを行います。
   * 生成されたEventEmitterオブジェクトは this.emitter にアサインされます。
   *
   * @private
   */
  initEventEmitter() {
    this.emitter = new EventEmitter;
    this.emitter.on(Constants.EVENT_CHANGE_POST_PROCESS_SELECTOR, this.handleChangeSelector.bind(this));
    this.emitter.on(Constants.EVENT_CLICK_DELETE_BUTTON, this.handleDeleteButton.bind(this));
    this.emitter.on(Constants.EVENT_CLICK_REFRESH_BUTTON, this.fetchPostProcesses.bind(this));
    this.emitter.on(Constants.EVENT_OPEN_DIALOG, this.handleOpenDialog.bind(this));
    this.emitter.on(Constants.EVENT_CLOSE_DIALOG, this.handleCloseDialog.bind(this));
  }

  /**
   * グループのセレクトボックス変更時のイベントをセットします。
   *
   * @private
   */
  setGroupIdReloadEvent() {
    window.jQuery(`#${Constants.GROUP_INPUT_ID}`).on('change', () => {
      const selectedGroupId = Number($(`#${Constants.GROUP_INPUT_ID}`).val());
      const ruleTypeSectionBlock = window.jQuery('#rule_type_section');

      // ジョブ作成途中でグループのセレクトボックスが変更された場合に
      // ステップ2(トリガーの選択)以降を非表示にする
      if (ruleTypeSectionBlock.is(':visible')) {
        ruleTypeSectionBlock.trigger("triggerJobForm:groupIdChanged");
      }

      // 現在選択されているグループIDの再設定と
      // 成功/失敗時の後処理割り当てをリセットする
      this.setState({
        completedAssignments: [],
        failedAssignments: [],
        selectedGroupId: selectedGroupId
      });
    });
  }
}
