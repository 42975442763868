// WorkSpacesリスト関連コンポーネントで利用する定数

export default {
  // EventEmitterで扱うイベントの識別子
  EVENT_CHANGE_CURRENT_AWS_ACCOUNT_ID: 'changeCurrentAwsAccountId',
  EVENT_CHANGE_CURRENT_GROUP: 'changeCurrentGroup',
  EVENT_CHANGE_CURRENT_REGION: 'changeCurrentRegion',
  EVENT_DELETE_LIST: 'deleteList',
  EVENT_RELOAD: "reload",
  EVENT_SUBMIT_REQUEST_FORM: 'submitRequestForm',

  // I18nメッセージリソースのスコープ
  I18N_SCOPE: 'javascript.workspaces_list',
};
