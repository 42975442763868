import React, { useState } from "react";

import alertMessage from "../AlertMessage.js";

import { OnDemandRunnableJob } from "./types";
import RunJobOnDemandDialog from "./RunJobOnDemandDialog";

const I18n = window.I18n; // i18n-js

/**
 * 手動トリガー以外のジョブの「今すぐ実行」ボタンおよび確認ダイアログ
 */
export const RunJobOnDemandButton: React.FC<{
  isDisable: boolean;
  job: OnDemandRunnableJob;
}> = (props) => {
  const [dialogOpened, setDialogOpened] = useState(false);

  const scope = "javascript.job_table.run_job_on_demand_button";
  const disabledText = I18n.t("disabled", { scope: scope });
  const labelText = I18n.t("label", { scope: scope });

  return (
    <>
      <button
        className={
          props.isDisable
            ? "btn btn-warning ca-control-button__run-now ca-job_workflows__workflow-button--disabled"
            : "btn btn-warning ca-control-button__run-now"
        }
        onClick={(): void => {
          props.isDisable ? alertMessage(disabledText) : setDialogOpened(true);
        }}
      >
        <i aria-hidden className="far fa-play-circle"></i> {labelText}
      </button>

      <RunJobOnDemandDialog
        job={props.job}
        onClose={(): void => setDialogOpened(false)}
        showRunOnDemandDialog={dialogOpened}
      />
    </>
  );
};
