import React from 'react';
import PropTypes from 'prop-types';

const I18n = window.I18n; // i18n-js

export default class UserTypeIcon extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {string} userType ユーザー種類(owner, admin, member)
   */
  static get propTypes() {
    return({
      userType: PropTypes.string.isRequired,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    const text = I18n.t(this.props.userType, { scope: 'common.user_types' });
    const iconClassNames = this.props.userType === 'member' ? 'fa fa-user' : 'fa fa-flag';

    return(
      <span
        className={`ca-user-type-icon ca-user-type-icon--${this.props.userType}`}
        data-placement="bottom"
        data-toggle="tooltip"
        title={text}
      >
        <i className={iconClassNames} aria-hidden="true"></i>
        <span className="sr-only">{text}</span>
      </span>
    );
  }
}
