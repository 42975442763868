// サインイン中ユーザー情報クラス
//
// サインイン中のユーザーに関する情報をMETAタグから取得するためのユーティリティクラスです。
// 以下のようにして利用します。
//
//   import CurrentUserInfo from '.path/to/CurrentUserInfo.js';
//
//   const userInfo = new CurrentUserInfo();
//   window.console.log(`組織ID: ${userInfo.getOrganizationId()}`);
//   window.console.log(`ユーザーID: ${userInfo.getUserId()}`);
//   window.console.log(`ユーザー種類: ${userInfo.getUserType()}`);
//
// 注意: このクラスで取得できる値はいずれもクライアント側で容易に偽装可能なため、信頼できない値が入っている
// 可能性があります。そのような場合に、データベースなどに致命的な問題が発生しないことを必ず考慮して設計してください。
//
export default class CurrentUserInfo {
  /**
   * インスタンスを初期化します。
   *
   * @public
   */
  constructor() {
    this.organizationId = null;
    this.userId = null;
    this.userUserType = null;
  }

  /**
   * サインイン中のユーザーが属する組織のIDを文字列で返します。
   *
   * @public
   * @return {?string} 組織IDまたはnull
   */
  getOrganizationId() {
    return this.fetchAndCacheContent("ca-organization-id");
  }

  /**
   * サインイン中のユーザーのIDを文字列で返します。
   *
   * @public
   * @return {?string} ユーザーIDまたはnull
   */
  getUserId() {
    return this.fetchAndCacheContent("ca-user-id");
  }

  /**
   * サインイン中のユーザーのユーザー種類を文字列で返します。
   *
   * @public
   * @return {?string} ユーザー種類またはnull
   */
  getUserType() {
    // 他の関数との整合性としては getUserUserType が適切だが、利用側からは冗長に見えるため
    // 関数名は getUserType としている
    return this.fetchAndCacheContent("ca-user-user-type");
  }

  /**
   * 指定されたMETAタグのcontent属性値を返します。
   * 戻り値はインスタンス変数にキャッシュされるた2回目以降の呼び出しではDOMへの参照は行われません。
   * なお、取得した値が null だった場合は2回目以降もDOMへの参照を行います。
   *
   * @private
   * @param {string} name 値を取得する対象となるMETAタグのname属性値
   */
  fetchAndCacheContent(name) {
    if (this[name] == null) {
      // インスタンス変数にキャッシュされた値がnullの場合は、常にDOMからの取得を試みる
      this[name] = this.getContentFromMetaTag(name);
    }
    return this[name];
  }

  /**
   * 指定されたMETAタグのcontent属性値を取得し、文字列として返します。
   * METAタグが取得できない場合は null を返します。
   *
   * @private
   * @param {string} name 値を取得する対象となるMETAタグのname属性値
   * @return {?string} content属性値またはnull
   */
  getContentFromMetaTag(name) {
    const meta = document.querySelector(`meta[name="${name}"]`);
    if (meta === null) {
      return null;
    }
    return meta.getAttribute("content");
  }
}
