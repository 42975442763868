import PropTypes from 'prop-types';
import React from 'react';

/**
 * エラーメッセージ表示コンポーネント。
 */
export default class ErrorMessages extends React.Component {
  /**
   * プロパティの定義を返します。
   *
   * @return {Object}
   * @property {string[]} messages エラーメッセージの配列
   */
  static get propTypes() {
    return({
      messages: PropTypes.arrayOf(PropTypes.string).isRequired,
    });
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <div style={{marginBottom:'30px'}}>
        <div className="ca-callout ca-callout--danger">
          {this.props.messages.map((msg) => <span key={msg}>{msg}<br/></span>)}
        </div>
      </div>
    );
  }
}
