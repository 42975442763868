import EventEmitter from 'events';
import React from 'react';
import PropTypes from 'prop-types';

import ApiKeySettings from './ApiKeySettings.jsx';
import Constants from './Constants.js';

const jQuery = window.jQuery;
const I18n = window.I18n; // i18n-js

/**
 * APIキー設定コンテナコンポーネント。
 *
 * このコンポーネントはサーバーとの通信およびUIイベントのハンドリングのみを行い、
 * ルック＆フィールについては責務を持ちません。
 */
export default class ApiKeySettingsContainer extends React.Component {
  /**
   * プロパティの定義を返します。
   *
   * @return {Object}
   * @property {boolean} apiEnabled ユーザーがAPIを利用可能かどうか
   * @property {string} apiKey APIキー(生成されていない場合は空文字列)
   * @property {string} createUrl APIキーの作成時にリクエストするURL
   * @property {string} deleteUrl APIキーの削除時にリクエストするURL
   * @property {string} recreateUrl APIキーの再作成時にリクエストするURL
   * @property {boolean} userActive ユーザーのアカウント状態が「利用可能」かどうか
   */
  static get propTypes() {
    return({
      apiEnabled: PropTypes.bool.isRequired,
      apiKey: PropTypes.string.isRequired,
      createUrl: PropTypes.string.isRequired,
      deleteUrl: PropTypes.string.isRequired,
      recreateUrl: PropTypes.string.isRequired,
      userActive: PropTypes.bool.isRequired,
    });
  }

  /**
   * コンストラクタ。
   *
   * @override
   */
  constructor(props) {
    super(props);

    // 定数
    this.createSuccessText = I18n.t('api_key_created', { scope: Constants.I18N_SCOPE });
    this.createFailText = I18n.t('api_key_not_created', { scope: Constants.I18N_SCOPE });
    this.deleteSuccessText = I18n.t('api_key_deleted', { scope: Constants.I18N_SCOPE });
    this.deleteFailText = I18n.t('api_key_not_deleted', { scope: Constants.I18N_SCOPE });
    this.recreateSuccessText = I18n.t('api_key_recreated', { scope: Constants.I18N_SCOPE });
    this.recreateFailText = I18n.t('api_key_not_recreated', { scope: Constants.I18N_SCOPE });

    // イベント処理用ハンドラにインスタンスをbind
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCreateApiKey = this.handleCreateApiKey.bind(this);

    this.handleDeleteApiKey = this.handleDeleteApiKey.bind(this);
    this.handleRecreateApiKey = this.handleRecreateApiKey.bind(this);

    this.handleOpenCreateModal = this.handleOpenCreateModal.bind(this);
    this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this);
    this.handleOpenRecreateModal = this.handleOpenRecreateModal.bind(this);
    this.handleOpenResultModal = this.handleOpenResultModal.bind(this);
    this.handleOpenDisplayModal = this.handleOpenDisplayModal.bind(this);

    this.emitter = this.initEventEmitter();
    this.state = this.getInitialState(props);
  }

  /**
   * ステートの初期値を返します。
   *
   * @return {Object}
   * @property {string} apiKey 現在のAPIキー
   * @property {?string} contentForResultModal 結果表示モーダルダイアログに表示するコンテンツ
   * @property {boolean} createModalVisible APIキー作成モーダルダイアログが表示中かどうか
   * @property {boolean} deleteModalvisible APIキー削除モーダルダイアログが表示中かどうか
   * @property {boolean} displayModalVisible APIキー表示モーダルダイアログが表示中かどうか
   * @property {boolean} isLoading 通信中かどうか
   * @property {boolean} recreateModalVisible APIキー再作成モーダルダイアログが表示中かどうか
   * @property {boolean} resultModalVisible 結果表示モーダルダイアログが表示中かどうか
   */
  getInitialState(props) {
    return(
      {
        apiKey: props.apiKey,
        contentForResultModal: null,
        createModalVisible: false,
        deleteModalvisible: false,
        displayModalVisible: false,
        isLoading: false,
        recreateModalVisible: false,
        resultModalVisible: false,
      }
    );
  }

  /**
   * EventEmitterを初期化して返します。
   * 各コンポーネント内で発生するイベントとイベントハンドラ関数の割り当てを行います。
   *
   * @private
   * @return {EventEmitter}
   */
  initEventEmitter() {
    const emitter = new EventEmitter;

    emitter.on(Constants.EVENT_CREATE_API_KEY, this.handleCreateApiKey);
    emitter.on(Constants.EVENT_DELETE_API_KEY, this.handleDeleteApiKey);
    emitter.on(Constants.EVENT_RECREATE_API_KEY, this.handleRecreateApiKey);
    emitter.on(Constants.EVENT_CLOSE_MODAL, this.handleCloseModal);
    emitter.on(Constants.EVENT_OPEN_CREATE_MODAL, this.handleOpenCreateModal);
    emitter.on(Constants.EVENT_OPEN_DELETE_MODAL, this.handleOpenDeleteModal);
    emitter.on(Constants.EVENT_OPEN_DISPLAY_MODAL, this.handleOpenDisplayModal);
    emitter.on(Constants.EVENT_OPEN_RECREATE_MODAL, this.handleOpenRecreateModal);
    emitter.on(Constants.EVENT_OPEN_RESULT_MODAL, this.handleOpenResultModal);

    return emitter;
  }

  /**
   * @override
   * @return {ReactElement}
   */
   render() {
     return(
       <React.StrictMode>
         <ApiKeySettings
           apiEnabled={this.props.apiEnabled}
           apiKey={this.state.apiKey}
           contentForResultModal={this.state.contentForResultModal}
           createModalVisible={this.state.createModalVisible}
           deleteModalvisible={this.state.deleteModalvisible}
           displayModalVisible={this.state.displayModalVisible}
           emitter={this.emitter}
           isLoading={this.state.isLoading}
           recreateModalVisible={this.state.recreateModalVisible}
           resultModalVisible={this.state.resultModalVisible}
           userActive={this.props.userActive}
         />
       </React.StrictMode>
     );
   }

   /**
    * Ajaxリクエストを送り、その結果をStateに格納する。
    *
    * @private
    * @param {Object} settings jQuery.ajaxに渡すオプション
    * @param {Object} messages 結果として表示するメッセージを保持するコンテナ
    * @param {string} messages.success リクエスト成功時に表示するメッセージ
    * @param {string} messages.fail リクエスト失敗時に表示するメッセージ
    */
  requestAndUpdateState(settings, messages) {
    jQuery.ajax(settings)
    .done((data) => {
      this.setState({
        isModalOpen: false,
        isLoading: false,
        apiKey: data.api_key
      }, () => {
        this.emitter.emit(Constants.EVENT_OPEN_RESULT_MODAL, messages['success']);
      });
    })
    .fail(() => {
      this.setState({
        isModalOpen: false,
        isLoading: false
      }, () => {
        this.emitter.emit(Constants.EVENT_OPEN_RESULT_MODAL, messages['fail']);
      });
    });
  }

  /**
   * モーダルダイアログを閉じるイベントが発生した際の処理を行います。
   *
   * @private
   */
  handleCloseModal() {
    this.setState({
      contentForResultModal: null,
      createModalVisible: false,
      deleteModalvisible: false,
      displayModalVisible: false,
      recreateModalVisible: false,
      resultModalVisible: false,
    });
  }

  /**
   * APIキー作成イベントが発生した際の処理を行います。
   *
   * @private
   */
  handleCreateApiKey() {
    const messages = {
      success: this.createSuccessText,
      fail: this.createFailText
    };
    const settings = {
      type: "POST",
      url: this.props.createUrl,
      dataType: "json",
      beforeSend: () => { this.setState({ isLoading: true }); }
    };
    this.requestAndUpdateState(settings, messages);
  }

  /**
   * APIキー削除イベントが発生した際の処理を行います。
   *
   * @private
   */
  handleDeleteApiKey() {
    const messages = {
      success: this.deleteSuccessText,
      fail: this.deleteFailText
    };
    const settings = {
      type: "DELETE",
      url: this.props.deleteUrl,
      dataType: "json",
      beforeSend: () => { this.setState({ isLoading: true }); }
    };
    this.requestAndUpdateState(settings, messages);
  }

  /**
   * APIキー作成モーダルダイアログを開くイベントが発生した際の処理を行います。
   *
   * @private
   */
  handleOpenCreateModal() {
    this.setState({
      createModalVisible: true,
     });
  }

  /**
   * APIキー削除モーダルダイアログを開くイベントが発生した際の処理を行います。
   *
   * @private
   */
  handleOpenDeleteModal() {
    this.setState({
      deleteModalvisible: true,
    });
  }

  /**
   * APIキー表示モーダルダイアログを開くイベントが発生した際の処理を行います。
   *
   * @private
   */
  handleOpenDisplayModal() {
    this.setState({
      displayModalVisible: true,
    });
  }

  /**
   * APIキー再作成モーダルダイアログを開くイベントが発生した際の処理を行います。
   *
   * @private
   */
  handleOpenRecreateModal() {
    this.setState({
      recreateModalVisible: true,
    });
  }

  /**
   * 結果表示モーダルダイアログを開くイベントが発生した際の処理を行います。
   *
   * @private
   * @param {string} content 表示するコンテンツ
   */
  handleOpenResultModal(content) {
    this.setState({
      contentForResultModal: content,
      resultModalVisible: true,
    });
  }

  /**
   * APIキー再作成イベントが発生した際の処理を行います。
   *
   * @private
   */
  handleRecreateApiKey() {
    const messages = {
      success: this.recreateSuccessText,
      fail: this.recreateFailText
    };
    const settings = {
      type: "POST",
      url: this.props.recreateUrl,
      dataType: "json",
      beforeSend: () => { this.setState({ isLoading: true }); }
    };
    this.requestAndUpdateState(settings, messages);
  }
}
