import React from "react";

/**
 * FontAwesomeのアイコンに設定できるスタイル
 */
type FontAwesomeIconStyle = "solid" | "regular" | "light" | "duotone" | "brands";

/**
 * スタイルに応じたCSSクラス名を返す。
 */
const classForStyle = (style: FontAwesomeIconStyle): string => {
  switch (style) {
    case "solid":
      return "fas";
    case "regular":
      return "far";
    case "light":
      return "fal";
    case "duotone":
      return "fad";
    case "brands":
      return "fab";
  }
};

/**
 * Font Awesomeアイコン。
 * 必要に応じてツールチップを設定可能。
 */
export const FontAwesomeIcon: React.FC<{
  /** FontAwesomeアイコンをアニメーションさせるかどうか("fa-spin"に対応) */
  isSpin?: boolean;
  /** アイコン名(FontAwesomeの "fa-XXX" のXXXの部分) */
  name: string;
  /** FontAwesomeアイコンのスタイル */
  style: FontAwesomeIconStyle;
  /** ツールチップ用文字列。未指定の場合はツールチップが表示されない。 */
  tooltip?: string;
}> = ({ name, isSpin = false, style, tooltip }) => {
  if (tooltip === undefined) {
    return <Icon isSpin={isSpin} name={name} style={style}></Icon>;
  }

  return (
    <span data-placement="bottom" data-toggle="tooltip" title={tooltip}>
      <Icon isSpin={isSpin} name={name} style={style}></Icon>
    </span>
  );
};

/**
 * Font Awesomeアイコンのアイコン部分。
 */
const Icon: React.FC<{
  /** FontAwesomeアイコンをアニメーションさせるかどうか("fa-spin"に対応) */
  isSpin: boolean;
  /** FontAwesomeアイコンのアイコン名("fa-XXX" のXXXの部分) */
  name: string;
  /** FontAwesomeアイコンのスタイル */
  style: FontAwesomeIconStyle | null;
}> = (props) => {
  const nameClass = `fa-${props.name}`;
  const styleClass = props.style === null ? "" : classForStyle(props.style);
  const spinClass = props.isSpin ? "fa-spin" : "";
  const classes = `${nameClass} ${styleClass} ${spinClass}`;

  return <i aria-hidden className={classes}></i>;
};
