import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';

import Constants from './Constants.js';
import FormDialogContent from './FormDialogContent.jsx';
import ResultDialogContent from './ResultDialogContent.jsx';

/**
 * パスワード設定ダイアログ
 *
 * モーダルダイアログの表示制御および、プロパティの値に応じたコンテンツの表示を行います。
 * 実際のコンテンツの生成は子コンポーネントで実装されており、このコンポーネントでは状態に
 * 応じてそれらを呼び分ける処理だけを行います。
 */
export default class Dialog extends React.Component {
  /**
   * プロパティの定義を返します。
   *
   * @return {Object}
   * @property {boolean} completed パスワード更新が完了したかどうか
   * @property {string} currentPassword 現在のパスワードの入力欄の値
   * @property {boolean} dialogVisible フォームのモーダルダイアログが表示中かどうか
   * @property {Object} emitter EventEmitterオブジェクト
   * @property {string[]} errorMessages エラーメッセージの配列
   * @property {boolean} isLoading 通信中かどうか
   * @property {string} newPassword 新しいパスワードの入力欄の値
   * @property {string} newPasswordConfirmation 確認用パスワードの入力欄の値
   */
  static get propTypes() {
    return({
      completed: PropTypes.bool.isRequired,
      currentPassword: PropTypes.string.isRequired,
      emitter: PropTypes.object.isRequired,
      dialogVisible: PropTypes.bool.isRequired,
      errorMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
      isLoading: PropTypes.bool.isRequired,
      newPassword: PropTypes.string.isRequired,
      newPasswordConfirmation: PropTypes.string.isRequired,
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);

    ReactModal.setAppElement("body");
  }

  /**
   * @return {ReactElement}
   */
  render() {
    let classes = 'ca-password-settings-dialog';
    if (this.props.completed) {
      classes += ' ca-password-settings-dialog--center';
    }

    return(
      <ReactModal
        className={classes}
        contentLabel={'Content Label'}
        isOpen={this.props.dialogVisible}
        onRequestClose={() => { this.props.emitter.emit(Constants.EVENT_CLOSE_DIALOG); }}
        overlayClassName="ca-password-settings-dialog__overlay"
        role="dialog"
      >
        {!this.props.completed &&
          <FormDialogContent
            completed={this.props.completed}
            currentPassword={this.props.currentPassword}
            emitter={this.props.emitter}
            errorMessages={this.props.errorMessages}
            isLoading={this.props.isLoading}
            newPassword={this.props.newPassword}
            newPasswordConfirmation={this.props.newPasswordConfirmation}
          />
        }
        {this.props.completed &&
          <ResultDialogContent
            emitter={this.props.emitter}
          />
        }
      </ReactModal>
    );
  }
}
