import React from "react";
import PropTypes from "prop-types";

import Constants from "./Constants.js";
import { Typeahead } from "react-bootstrap-typeahead";

const I18n = window.I18n; // i18n-js

/**
 * 後処理一覧のセレクトボックス
 *
 * 後処理一覧のセレクトボックスを表示するUIコンポーネントで、以下の責務を持ちます。
 *
 * - 後処理一覧のセレクトボックスを表示
 * - セレクトボックスの選択状態を変更した場合にEventEmitterに
 *   イベント(EVENT_CHANGE_POST_PROCESS_SELECTOR)を通知する
 *
 */
export default class PostProcessSelector extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {Object} emitter EventEmitterオブジェクト
   * @param {string} postProcessType 成功時の後処理か失敗時の後処理かをあらわす文字列
   *                                 "completed" もしくは "failed"
   * @property {Object[]} postProcesses 後処理の配列
   */
  static get propTypes() {
    return {
      emitter: PropTypes.object.isRequired,
      postProcessType: PropTypes.string.isRequired,
      postProcesses: PropTypes.arrayOf(PropTypes.object).isRequired,
    };
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);
    this.state = {
      selectedPostProcess: [],
    };
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return <div>{this.renderPostProcessesSelector()}</div>;
  }

  /**
   * 後処理一覧のセレクトボックスを表示します。
   *
   * @private
   * @return {ReactElement}
   */
  renderPostProcessesSelector() {
    return (
      <div>
        <Typeahead
          id={`PostProcessSelector-${this.props.postProcessType}`}
          labelKey="name"
          emptyLabel={I18n.t("no_post_processes", { scope: Constants.I18N_SCOPE })}
          placeholder={I18n.t("please_select", { scope: Constants.I18N_SCOPE })}
          onChange={(selected) => {
            this.setState({ selectedPostProcess: selected }, () => {
              if (selected.length > 0) {
                this.props.emitter.emit(
                  Constants.EVENT_CHANGE_POST_PROCESS_SELECTOR,
                  this.props.postProcessType,
                  selected[0].id
                );
              }
              this.setState({ selectedPostProcess: [] });
            });
          }}
          options={this.props.postProcesses.map((postProcess) => {
            return { id: postProcess.id, name: postProcess.name };
          })}
          selected={this.state.selectedPostProcess}
        />
      </div>
    );
  }
}
