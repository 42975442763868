import React from 'react';
import { CSSTransition } from "react-transition-group";
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import PolicyParameterStringField from './PolicyParameterStringField.jsx';
import PolicyParameterStringsField from './PolicyParameterStringsField.jsx';
import PolicyTriggerType from '../PolicyTriggerType.jsx';
import PostProcessAssignmentsPanelContainer from './PostProcessAssignmentsPanelContainer.jsx';

const _ = window._; // Underscore.js
const I18n = window.I18n; // i18n-js

// ポリシー編集フィールドセットコンポーネント
class PolicyFieldSet extends React.Component {
  static get propTypes() {
    return({
      completedAssignments: PropTypes.arrayOf(PropTypes.object).isRequired,
      defaultLanguage: PropTypes.string.isRequired,
      defaultTimeZone: PropTypes.string.isRequired,
      emitter: PropTypes.object.isRequired,
      enabled: PropTypes.bool.isRequired,
      failedAssignments: PropTypes.arrayOf(PropTypes.object).isRequired,
      failedPostProcessIds: PropTypes.arrayOf(PropTypes.number).isRequired, // TODO: Deprecate
      group: PropTypes.object.isRequired,
      groups: PropTypes.arrayOf(PropTypes.array).isRequired,
      handleUpdatePostProcesses: PropTypes.func.isRequired,
      isSlackIntegrated: PropTypes.bool.isRequired,
      isUserCanManageIntegrations: PropTypes.bool.isRequired,
      loadingImagePath: PropTypes.string.isRequired,
      parameters: PropTypes.object.isRequired,
      policyTemplate: PropTypes.object.isRequired,
      postProcesses: PropTypes.arrayOf(PropTypes.object).isRequired,
      succeededPostProcessIds: PropTypes.arrayOf(PropTypes.number).isRequired, // TODO: Deprecate
      triggerType: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      postProcessesUrl: PropTypes.string.isRequired,
      regionSet: PropTypes.arrayOf(PropTypes.array).isRequired,
      services: PropTypes.arrayOf(PropTypes.array).isRequired,
      sqsQueuesPath: PropTypes.string.isRequired,
      timeZones: PropTypes.arrayOf(PropTypes.array).isRequired,
      url: PropTypes.string.isRequired
    });
  }

  constructor(props) {
    super(props);
    this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this);

    // ポリシーの有効状態切り替え時に適用するCSSトランジションのオプション
    this.cssTransitionOptions = {
      classNames: "policy-fieldset-fields",
      appear: true,
      unmountOnExit: true,
      timeout: {
        appear: 500,
        enter: 300,
        exit: 300,
      }
    };
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.enabled !== this.props.enabled
        || nextProps.failedPostProcessIds.join() !== this.props.failedPostProcessIds.join()
        || nextProps.parameters !== this.props.parameters
        || nextProps.policyTemplate !== this.props.policyTemplate
        || nextProps.postProcesses.join() !== this.props.postProcesses.join()
        || nextProps.succeededPostProcessIds.join() !== this.props.succeededPostProcessIds.join()
    );
  }

  render() {
    const heading = I18n.locale === "ja" ? this.props.policyTemplate.name_ja : this.props.policyTemplate.name_en;

    return(
      <div className="policy-fieldset">
        {this.renderToggleSwitch()}
        <fieldset className="policy-fieldset__fieldset">
          <legend>
            <PolicyTriggerType
              triggerType={this.props.triggerType}
            />
          </legend>
          <div className="policy-fieldset__div">
            <span className="policy-fieldset__span">
              {heading}
            </span>
          </div>
          {this.renderContents()}
        </fieldset>
      </div>
    );
  }

  renderContents() {
    const description = I18n.locale === "ja" ? this.props.policyTemplate.description_ja : this.props.policyTemplate.description_en;

    return(
      <CSSTransition
        {...this.cssTransitionOptions}
        in={this.props.enabled}
      >
        <div>
          <p>{description}</p>
          {this.renderParameterFields()}
          {this.renderPostProcessAssignmentsPanel()}
        </div>
      </CSSTransition>
    );
  }

  renderParameterFields() {
    // 明示的に入力欄を表示する必要があるパラメーターのみを抽出する
    // パラメーター名が暗黙的パラメーターに該当する場合は入力欄を表示しない
    const explicitParameterFields = _.filter(
      this.props.policyTemplate.parameters.fields,
      field => !_.contains(Constants.IMPLICIT_PARAMETER_NAMES, field.name)
    );

    return(
      explicitParameterFields.map((field) => {
        const label = I18n.locale === "ja" ? field.label.ja : field.label.en;

        // バリデーション用属性をプロパティとしてコンポーネントに渡すためのオブジェクトを作成する
        let validationAttrs = { required: field.required };
        if (field.maxLength) { validationAttrs.maxLength = field.maxLength; }

        if (field.type === Constants.PARAMETER_FIELD_TYPE_STRING) {
          return(
            <PolicyParameterStringField
              emitter={this.props.emitter}
              initialValue={this.props.parameters[field.name]}
              key={field.name}
              label={label}
              name={field.name}
              policyTemplateId={this.props.policyTemplate.id}
              {...validationAttrs}
            />
          );
        }

        if (field.type === Constants.PARAMETER_FIELD_TYPE_STRINGS) {
          return(
            <PolicyParameterStringsField
              emitter={this.props.emitter}
              initialValues={this.props.parameters[field.name]}
              key={field.name}
              label={label}
              name={field.name}
              policyTemplateId={this.props.policyTemplate.id}
              {...validationAttrs}
            />
          );
        }
      })
    );
  }

  renderPostProcessAssignmentsPanel() {
    return(
      <div className="policy-fieldset__post-process-assignments-panel">
        <div className="row">
          <PostProcessAssignmentsPanelContainer
            completedAssignments={this.props.completedAssignments}
            defaultLanguage={this.props.defaultLanguage}
            defaultTimeZone={this.props.defaultTimeZone}
            succeededPostProcessIds={this.props.succeededPostProcessIds}
            emitter={this.props.emitter}
            failedAssignments={this.props.failedAssignments}
            groups={this.props.groups}
            isSlackIntegrated={this.props.isSlackIntegrated}
            isUserCanManageIntegrations={this.props.isUserCanManageIntegrations}
            loadingImagePath={this.props.loadingImagePath}
            policyTemplateId={this.props.policyTemplate.id}
            postProcesses={this.props.postProcesses}
            postProcessesUrl={this.props.postProcessesUrl}
            regions={this.props.regionSet}
            selectedGroupId={this.props.group.id}
            services={this.props.services}
            sqsQueuesPath={this.props.sqsQueuesPath}
            timeZones={this.props.timeZones}
          />
        </div>
      </div>
    );
  }

  renderToggleSwitch() {
    const toggleStateClass = this.props.enabled ? 'on' : 'off';

    return(
      <div
        className={`pull-right toggle-switch ${toggleStateClass}`}
        onClick={() => this.props.emitter.emit(Constants.EVENT_TOGGLE_POLICY_ENABLE, this.props.policyTemplate.id)}
      >
        <span className="toggle-button"></span>
      </div>
    );
  }
}

export default PolicyFieldSet;
