import React from "react";

import { useToggle } from "react-use";

import { AwsAccount } from "./types";
import { Group } from "./types";
import { UpdateIamRoleDialog } from "./UpdateIamRoleDialog";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.group_aws_account";

/**
 * AWSアカウント一覧画面で表示するIAMロール方式のAWSアカウント更新ボタン
 *
 * 以下の責務を持ちます。
 *
 * - 更新ボタンの表示
 * - 更新ダイアログの表示制御
 */
export const UpdateIamRoleButton: React.FC<{
  awsAccount: AwsAccount;
  group: Group;
  onUpdated: () => void;
}> = (props): JSX.Element => {
  /**
   * ダイアログの表示状態をあらわすステート
   */
  const [isDialogOpen, toggleDialog] = useToggle(false);

  return (
    <div className="inline-block">
      <UpdateButton onClick={(): void => toggleDialog(true)} />

      {props.awsAccount.isIamRole && (
        <UpdateIamRoleDialog
          awsAccount={props.awsAccount}
          group={props.group}
          isOpen={isDialogOpen}
          onCancel={(): void => toggleDialog(false)}
          onUpdated={(): void => {
            props.onUpdated();
            toggleDialog(false);
          }}
        />
      )}
    </div>
  );
};

/**
 * 更新してくださいボタン
 */
const UpdateButton: React.FC<{
  onClick: () => void;
}> = (props): JSX.Element => {
  return (
    <button
      type="button"
      className="btn btn-danger ca-aws-account__aws-accounts-table__btn-update"
      onClick={props.onClick}
    >
      <span>{I18n.t("iam_role_update", { scope: I18N_SCOPE })}</span>
    </button>
  );
};
