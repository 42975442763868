import React from 'react';
import PropTypes from 'prop-types';

// import React from 'react';
import Constants from './Constants.js';
import ErrorMessages from '../ErrorMessages.jsx';

const I18n = window.I18n; // i18n-js

// サービス選択フィールド
//
// プロパティ:
// emitter - EventEmitterオブジェクト。
// services - サービスの選択肢。`[["Eメール", "email"], ["SQS", "sqs"]]`のような構造のArray。
// value - 現在の値。
//
class ServiceSelectionField extends React.Component {
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
      services: PropTypes.arrayOf(PropTypes.array).isRequired,
      value: PropTypes.string.isRequired
    });
  }

  /**
   * エラーがあるかどうかを返します。
   *
   * @return {boolean}
   */
  hasError() {
    return this.props.errors.length > 0;
  }

  render() {
    const errorClass = this.hasError() ? 'error' : '';
    const label = I18n.t('activerecord.attributes.post_process.service');

    return(
      <div className="form-group">
        <div className="post-process-setting">
          <div className="post-process-setting__label">
            <div className="post-process-setting__label__text">
              <label htmlFor="PostProcess-service">
                {label}
              </label>
              <span className="required">*</span>
            </div>
          </div>
          <div className="post-process-setting__form">
            <select id="PostProcess-service"
              className={`form-control ${errorClass}`}
              onChange={event => this.props.emitter.emit(Constants.EVENT_CHANGE_SERVICE, event.target.value)}
              value={this.props.value}>
              {this.renderOptions()}
            </select>
            <ErrorMessages classes="marginT5" isHtmlWithIcon={true} messages={this.props.errors} />
          </div>
        </div>
      </div>
    );
  }

  renderOptions() {
    const options = this.props.services;

    return(options.map((item) => {
      const label = item[0];
      const value = item[1];
      return(
        <option key={value} value={value}>{label}</option>
      );
    }));
  }
}

export default ServiceSelectionField;
