import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useToggle } from "react-use";

import { showSuccessToast } from "../../toast";
import ManualLink from "../../ManualLink";

import { CredentialsJsonInputField } from "../../google_cloud_accounts/CredentialsJsonInputField";
import { NameInputField } from "../../google_cloud_accounts/NameInputField";
import { SubmitButton } from "../../google_cloud_accounts/SubmitButton";

import { GoogleCloudAccount } from "./types";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.group_google_cloud_account.edit_dialog";

type GoogleCloudAccountForEdit = GoogleCloudAccount & {
  credentialsJson: string;
};

/**
 * アカウントの更新時に送信するデータ
 * groups/google_cloud_accounts#update の仕様と合わせる。
 */
type UpdateAccountData = {
  google_cloud_account: {
    name: string;
    google_service_account_attributes: {
      credentials_json?: string;
    };
  };
};

type UseEditAccountLogicResult = {
  account: GoogleCloudAccountForEdit;
  handleRequestClose: () => void;
  handleSubmit: (event: React.SyntheticEvent) => void;
  isTransmitting: boolean;
  serverError: string;
  setAccount: (account: GoogleCloudAccountForEdit) => void;
  validationErrors: FormErrors;
};

/** Google Cloudアカウント追加フォームのエラーメッセージ */
type FormErrors = {
  /** アクセスキーのエラーメッセージ */
  credentialsJson: string[];
  /** アカウント名のエラーメッセージ */
  name: string[];
  /** フォーム全体に関するエラーメッセージ */
  base: string[];
  /** グループのエラーメッセージ */
  groupId: string[];
};

/** エラーメッセージの初期状態 */
const defaultErrors: FormErrors = {
  /** アクセスキーのエラーメッセージ */
  credentialsJson: [],
  /** アカウント名のエラーメッセージ */
  name: [],
  /** フォーム全体のエラーメッセージ */
  base: [],
  /** グループのエラーメッセージ */
  groupId: [],
};

const useEditAccountLogic = (
  target: GoogleCloudAccount,
  cancelCallback: () => void,
  updatedCallback: () => void
): UseEditAccountLogicResult => {
  const [account, setAccount] = useState<GoogleCloudAccountForEdit>({
    ...target,
    credentialsJson: "",
  });
  const [isTransmitting, toggleTransmitting] = useToggle(false);
  const [serverError, setServerError] = useState("");
  const [validationErrors, setValidationErrors] = useState(defaultErrors);

  const handleRequestClose = (): void => {
    cancelCallback();
    setAccount({ ...target, credentialsJson: "" });
    setValidationErrors(defaultErrors);
    setServerError("");
  };

  const buildData = (): UpdateAccountData => {
    const data: UpdateAccountData = {
      google_cloud_account: {
        name: account.name,
        google_service_account_attributes: {},
      },
    };
    if (account.credentialsJson !== "") {
      // シークレットアクセスキーは「値が空文字列」かどうかではなく「リクエストパラメーターに
      // 含まれていないかどうか」で「変更なし」として処理されるかどうかが決まる。
      // そのため、値が入力されている場合にのみリクエストパラメーターに含めるようにする。
      data.google_cloud_account.google_service_account_attributes.credentials_json = account.credentialsJson;
    }

    return data;
  };

  /**
   * フォームの送信処理を行う関数
   *
   * 編集中のGoogle Cloudアカウントをサーバーサイドに反映し、レスポンスに応じた処理を行います。
   * リクエストは、「編集中のアカウントの updateUrl プロパティ」に設定されているURLに対して
   * PATCHメソッドで行われます。
   */
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    jQuery
      .ajax({
        beforeSend: () => {
          setValidationErrors(defaultErrors);
          setServerError("");
          toggleTransmitting(true);
        },
        data: buildData(),
        dataType: "json",
        method: "PATCH",
        url: target.updateUrl,
      })
      .done(() => {
        updatedCallback();
        showSuccessToast(I18n.t("succeeded", { name: account.name, scope: I18N_SCOPE }));
      })
      .fail((jqXHR, textStatus) => {
        if (jqXHR.status == 422) {
          let errorResponse = jqXHR.responseJSON?.errors;
          if (!errorResponse) {
            errorResponse = { base: [I18n.t("error", { message: jqXHR.statusText, scope: I18N_SCOPE })] };
          }
          setValidationErrors({
            ...validationErrors,
            credentialsJson: errorResponse["google_service_account.credentials"] || [],
            name: errorResponse.name || [],
            groupId: errorResponse.group || [],
          });
        } else {
          setServerError(I18n.t("error", { message: textStatus, scope: I18N_SCOPE }));
        }
      })
      .always(() => toggleTransmitting(false));
  };

  return { account, setAccount, isTransmitting, serverError, validationErrors, handleRequestClose, handleSubmit };
};

type Props = {
  account: GoogleCloudAccount;
  isOpen: boolean;
  onCancel: () => void;
  onUpdated: () => void;
};

/**
 * 編集ダイアログ
 *
 * Google Cloudアカウント一覧の編集ボタン押下時に表示される編集ダイアログ。
 */
const EditDialog: React.FC<Props> = (props): JSX.Element => {
  const { account, setAccount, serverError, validationErrors, isTransmitting, handleRequestClose, handleSubmit } =
    useEditAccountLogic(props.account, props.onCancel, props.onUpdated);

  useEffect(() => {
    ReactModal.setAppElement("body");
  }, []);

  /**
   * フォームが送信可能な状態かどうかを返す
   */
  const isSubmittable = (): boolean => {
    return account.name !== "";
  };

  return (
    <ReactModal
      className="ca-group-aws-account-modal__content"
      isOpen={props.isOpen}
      onRequestClose={handleRequestClose}
      overlayClassName="ca-group-aws-account-modal__overlay"
      role="dialog"
    >
      <div className="text-left">
        <Heading />
      </div>
      <div className="text-left marginB20">
        <GoogleCloudAccountManualLink />
      </div>

      <form onSubmit={handleSubmit} style={{ borderTop: "1px dashed #ddd", paddingTop: "20px" }}>
        <div>
          <div
            style={{
              border: "1px solid #ddd",
              borderRadius: "5px",
              color: "#9b9b9b",
              marginBottom: "20px",
              padding: "10px 20px",
              textAlign: "left",
            }}
          >
            <dl>
              <dt style={{ float: "left", lineHeight: "1.8", width: "100px" }}>
                {I18n.t("masked_private_key_id", { scope: I18N_SCOPE })}
              </dt>
              <dd style={{ lineHeight: "1.8", marginLeft: "100px", overflowWrap: "anywhere" }}>{account.identity}</dd>
              <dt style={{ float: "left", lineHeight: "1.8", width: "100px" }}>
                {I18n.t("email", { scope: I18N_SCOPE })}
              </dt>
              <dd style={{ lineHeight: "1.8", marginLeft: "100px", overflowWrap: "anywhere" }}>{account.email}</dd>
            </dl>
          </div>

          <div className="form-group text-left">
            <CredentialsJsonInputField
              disabled={false}
              errors={validationErrors.credentialsJson}
              name="credentials_json"
              onChange={(value): void => setAccount({ ...account, credentialsJson: value })}
              placeholder={I18n.t("credentials_json_placeholder", { scope: I18N_SCOPE })}
              required={false}
              value=""
            />
            <NameInputField
              disabled={false}
              errors={validationErrors.name}
              onChange={(value): void => setAccount({ ...account, name: value })}
              value={account.name}
            />
          </div>
        </div>

        <div className="ca-btn-block__center marginT30">
          {serverError != "" && <ServerError error={serverError} />}
          <SubmitButton
            disabled={!isSubmittable()}
            label={I18n.t("submit", { scope: I18N_SCOPE })}
            pending={isTransmitting}
            pendingLabel={I18n.t("transmitting", { scope: I18N_SCOPE })}
          />
        </div>
      </form>
    </ReactModal>
  );
};

/**
 * IAMユーザー方式でAWSアカウントを登録するマニュアルへのリンク
 */
const GoogleCloudAccountManualLink: React.FC = (): JSX.Element => {
  return (
    <ManualLink
      linkText={I18n.t("manual.text", { scope: I18N_SCOPE })}
      url={I18n.t("manual.url", { scope: I18N_SCOPE })}
    />
  );
};

const Heading: React.FC = (): JSX.Element => {
  return (
    <h2>
      <span className="fa fa-key" />
      &nbsp;
      {I18n.t("heading", { scope: I18N_SCOPE })}
    </h2>
  );
};

/**
 * サーバーエラーメッセージ
 *
 * バリデーションエラー以外のエラーメッセージを表示します。
 */
const ServerError: React.FC<{ error: string }> = (props): JSX.Element => {
  return (
    <p>
      <label className="error">{props.error}</label>
    </p>
  );
};

export { EditDialog };
