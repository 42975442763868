import React from 'react';
import PropTypes from 'prop-types';
import withBootstrapTooltip from './withBootstrapTooltip.js';

const I18n = window.I18n; // i18n-js
const I18N_SCOPE = { scope: 'javascript.group_icon' };


// グループアイコン表示コンポーネント
//
// プロパティ:
// group - グループオブジェクト。指定されない場合はグループ共通アイコンを表示します
//
class GroupIconTip extends React.Component {
  static get propTypes() {
    return({
      group: PropTypes.object,
      tooltipRef: PropTypes.func,
    });
  }

  render() {
    let class_name, color, name;

    if (this.props.group) {
      class_name = "ca-group-icon__icon fa fa-briefcase";
      color = { color: this.props.group.color };
      name = this.props.group.name;
    } else {
      class_name = "ca-group-icon__icon fa fa-sitemap";
      color = { color: "#919191" };
      name = I18n.t("shared", I18N_SCOPE);
    }

    return (
      <React.StrictMode>
        <span className="ca-group-icon" ref={this.props.tooltipRef}>
          <i
            aria-hidden
            className={class_name}
            data-toggle="tooltip"
            data-placement="bottom"
            title={name}
            style={color}
            ></i>
          <span className="sr-only">{name}</span>
        </span>
      </React.StrictMode>
    );
  }
}

export default withBootstrapTooltip(GroupIconTip);
