import React, { useState } from "react";

import { AccountType } from "./types";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.provider_account_type_selector";

type ProviderAccountTypeSelectorProps = {
  // SELECTタグのclass属性値
  className?: string;
  // デフォルトの選択値
  defaultValue: AccountType;
  // Googleサービスアカウント方式が選択された際に呼び出されるコールバック
  onGoogleServiceAccountSelected: () => void;
  // IAMロール方式が選択された際に呼び出されるコールバック
  onIamRoleSelected: () => void;
  // IAMユーザー方式が選択された際に呼び出されるコールバック
  onIamUserSelected: () => void;
};

/**
 * プロバイダアカウント登録方式の選択UI
 *
 * 以下の責務を持ちます。
 *
 * - 登録方式を切り替えるセレクトボックスの表示
 * - 選択値が変更された場合に内容が失われることを確認するダイアログを表示する
 * - 選択値が変更され、確認ダイアログのOKが押された場合にコールバック関数を呼び出す
 */
export const ProviderAccountTypeSelector: React.FC<ProviderAccountTypeSelectorProps> = ({
  className = "ca-groups-provider-account-type-selector form-control",
  defaultValue,
  onGoogleServiceAccountSelected,
  onIamRoleSelected,
  onIamUserSelected,
}): JSX.Element => {
  const [currentValue, setCurrentValue] = useState<AccountType>(defaultValue);

  const confirmationText = I18n.t("unsaved_warning", { scope: I18N_SCOPE });
  const googleServiceAccountLabel = I18n.t("google_service_account", { scope: I18N_SCOPE });
  const iamRoleLabel = I18n.t("iam_role", { scope: I18N_SCOPE });
  const iamUserLabel = I18n.t("iam_user", { scope: I18N_SCOPE });

  /**
   * 方式の変更操作が行われた場合に、確認のダイアログを表示してコールバック関数を呼び出します。
   * ダイアログでキャンセルが行われた場合は現在値を変更しません。
   */
  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    if (!confirm(confirmationText)) {
      return;
    }

    const nextValue = event.target.value as AccountType;

    setCurrentValue(nextValue);

    if (nextValue == "iam_role") {
      onIamRoleSelected();
    } else if (nextValue == "iam_user") {
      onIamUserSelected();
    } else if (nextValue == "google_service_account") {
      onGoogleServiceAccountSelected();
    } else {
      // nextValue(AccountType型)のとりうる値をif文で網羅していることを担保するためのelse。
      const _: never = nextValue;
      console.error(`${_} is unexpected value`);
    }
  };

  return (
    <select className={className} name="provider_account_type" onChange={handleOnChange} value={currentValue}>
      <optgroup label="AWS">
        <option value="iam_role">{iamRoleLabel}</option>
        <option value="iam_user">{iamUserLabel}</option>
      </optgroup>
      <optgroup label="Google Cloud">
        <option value="google_service_account">{googleServiceAccountLabel}</option>
      </optgroup>
    </select>
  );
};
