import React, { SyntheticEvent, useState } from "react";

type option = {
  name: string;
  value: number;
};

type groupOption = option;

type providerAccountOption = option & {
  group_id: number;
};

type groupedOption = {
  column: string;
  name: string;
  options: providerAccountOption[];
};

type column = "provider_account_id" | "group_id";

type selectedValueChange = {
  column: column;
  value: string;
};

/**
 * ジョブ一覧のジョブ詳細検索のメニューに表示するグループとプロバイダアカウントのセレクトボックス
 * ２つのセレクトボックスは以下のように連携して動作します
 * - グループが選択されていない場合、プロバイダアカウントは選択できない
 * - グループが選択済みの場合、グループに属するプロバイダアカウントのみが選択可能になる
 * - プロバイダアカウントが選択された状態でグループが変更された場合、プロバイダアカウントの選択がリセットされる
 */
export const GroupAndProviderAccountSelector: React.FC<{
  groupValue: string;
  providerAccountValue: string;
  groupOptions: groupOption[];
  groupedProviderAccountOptions: groupedOption[];
}> = (props) => {
  const [selectedValues, setSelectedValues] = useState({
    providerAccountId: props.providerAccountValue,
    groupId: props.groupValue,
  });

  const I18n = window.I18n; // i18n-js
  const i18nScope = { scope: "javascript.job_table.detailed_search_input" };
  const allProviderAccountText = I18n.t("all_provider_account", i18nScope);
  const allGroupText = I18n.t("all_group", i18nScope);

  /**
   * 指定されたプロバイダアカウントの配列を、指定されたグループIDで絞込んだ結果を返します。
   *
   * @param {string} options プロバイダアカウントの配列
   * @param {string} groupId グループID
   * @returns {providerAccountOption[]} 絞り込まれたプロバイダアカウントの配列
   */
  const filterOptionsByGroupId = (options: providerAccountOption[], groupId: string): providerAccountOption[] => {
    return options.filter((option) => {
      return String(option.group_id) === groupId;
    });
  };

  /**
   * 選択されたグループに所属するプロバイダアカウントを絞り込む
   */
  const filteredGroupedProviderAccountOptions = props.groupedProviderAccountOptions.flatMap((provider) => {
    const filteredAccounts = filterOptionsByGroupId(provider.options, selectedValues.groupId);

    if (filteredAccounts.length <= 0) {
      return [];
    }

    return [
      {
        column: provider.column,
        name: provider.name,
        options: filterOptionsByGroupId(provider.options, selectedValues.groupId),
      },
    ];
  });

  /**
   * セレクトボックスの選択値が変更された場合に子コンポーネントから呼び出されるコールバック関数
   */
  const handleOnChange = ({ column, value }: selectedValueChange): void => {
    if (column === "group_id") {
      if (value === "" || value !== selectedValues.groupId) {
        // グループが指定されない場合や他のグループが指定された場合はプロバイダアカウントの選択をリセットする
        return setSelectedValues({ groupId: value, providerAccountId: "" });
      }
      return setSelectedValues({ ...selectedValues, groupId: value });
    }

    return setSelectedValues({ ...selectedValues, providerAccountId: value });
  };

  return (
    <div className="display-flex">
      {/* グループのセレクトボックス */}
      <SearchSelector
        callback={handleOnChange}
        blankText={allGroupText}
        options={props.groupOptions}
        column={"group_id"}
        selectedValue={selectedValues.groupId}
      />
      <span
        className="ca-job-search-input__detailed-search-container__group-icon fa fa-caret-right"
        aria-hidden="true"
      ></span>
      {/* プロバイダアカウントのセレクトボックス */}
      <GroupedSearchSelectorWithPrefix
        callback={handleOnChange}
        blankText={allProviderAccountText}
        disabled={filteredGroupedProviderAccountOptions.length === 0}
        groupedOptions={filteredGroupedProviderAccountOptions}
        column={"provider_account_id"}
        selectedValue={selectedValues.groupId == "" ? "" : selectedValues.providerAccountId}
      />
    </div>
  );
};

const SearchSelector: React.FC<{
  callback: (selectedValueChange: selectedValueChange) => void;
  blankText: string;
  options: option[];
  column: column;
  selectedValue: string;
}> = (props) => {
  /*
   * セレクトボックスのコールバック関数
   * 変更された要素の名前と値を整形して、 Props のコールバック関数を呼び出します
   */
  const handleOnChange = (event: SyntheticEvent): void => {
    event.preventDefault();
    const target = event.target as HTMLInputElement;
    const targetValue = target.value;
    const selectedValueChange: selectedValueChange = {
      value: targetValue as string,
      column: props.column,
    };
    props.callback(selectedValueChange);
  };

  return (
    <>
      <select
        className="ca-job-search-input__detailed-search-container__selector form-control"
        name={props.column} // rails のフォームヘルパーで作成したボタンで submit するために必要
        onChange={handleOnChange}
        value={props.selectedValue}
      >
        <option key={0} value="">
          {props.blankText}
        </option>
        {props.options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          );
        })}
      </select>
    </>
  );
};

/**
 * exampleのようなmapを渡すと、optgroupを使ってグルーピングしたセレクトボックスを返します。
 * また、個々のoptionに付与されるvalueにはcolumnがプレフィックスとして含まれています。
 * @example
 * [
 *   {
 *     name: "AWS",
 *     column: "aws_account_id",
 *     options: [
 *       { name: "Test AWS Account 1", value: "1", group_id: 1 },
 *       { name: "Test AWS Account 2", value: "2", group_id: 2 },
 *       { name: "Test AWS Account 3", value: "3", group_id: 3 },
 *     ]
 *   },
 *   {
 *     name: "GoogleCloud",
 *     column: "google_cloud_account_id",
 *     options: [
 *       { name: "Test Google Cloud Account 1", value: "1", group_id: 1 },
 *       { name: "Test Google Cloud Account 2", value: "2", group_id: 2 },
 *       { name: "Test Google Cloud Account 3", value: "3", group_id: 3 },
 *     ]
 *   },
 * ]
 */
const GroupedSearchSelectorWithPrefix: React.FC<{
  callback: (selectedValueChange: selectedValueChange) => void;
  blankText: string;
  disabled: boolean;
  groupedOptions: groupedOption[];
  column: column;
  selectedValue: string;
}> = (props) => {
  /*
   * セレクトボックスのコールバック関数
   * 変更された要素の名前と値を整形して、 Props のコールバック関数を呼び出します
   */
  const handleOnChange = (event: SyntheticEvent): void => {
    event.preventDefault();
    const target = event.target as HTMLInputElement;
    const targetValue = target.value;
    const selectedValueChange: selectedValueChange = {
      value: targetValue as string,
      column: props.column,
    };
    props.callback(selectedValueChange);
  };

  return (
    <>
      <select
        className="ca-job-search-input__detailed-search-container__selector form-control"
        name={props.column} // rails のフォームヘルパーで作成したボタンで submit するために必要
        disabled={props.disabled}
        onChange={handleOnChange}
        value={props.selectedValue}
      >
        <option key={0} value="">
          {props.blankText}
        </option>
        {props.groupedOptions.map((provider) => {
          return (
            <optgroup key={provider.column} label={provider.name}>
              {provider.options.map((account) => {
                return (
                  <option key={`${provider.column}-${account.value}`} value={`${provider.column}-${account.value}`}>
                    {account.name}
                  </option>
                );
              })}
            </optgroup>
          );
        })}
      </select>
    </>
  );
};
