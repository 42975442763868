import PropTypes from 'prop-types';
import React from 'react';

import Constants from './Constants.js';
import ErrorMessages from './ErrorMessages.jsx';
import PasswordField from './PasswordField.jsx';
import SubmitButton from './SubmitButton.jsx';

const I18n = window.I18n; // i18n-js

/**
 * パスワード設定フォーム用ダイアログコンテンツ。
 *
 * ダイアログ内にコンテンツとして表示される、パスワードの変更を行うためのフォームを
 * あらわしたコンポーネントです。
 * フォームのルック＆フィールおよび各種イベント(入力値の変化、フォームの送信)発生時に
 * EventEmitterのイベントを発生させる責務を持ちます。
 */
export default class FormDialogContent extends React.Component {
  /**
   * プロパティの定義を返します。
   *
   * @return {Object}
   * @property {boolean} completed パスワード更新が完了したかどうか
   * @property {string} currentPassword 現在のパスワードの入力欄の値
   * @property {Object} emitter EventEmitterオブジェクト
   * @property {string[]} errorMessages エラーメッセージの配列
   * @property {boolean} isLoading 通信中かどうか
   * @property {string} newPassword 新しいパスワードの入力欄の値
   * @property {string} newPasswordConfirmation 確認用パスワードの入力欄の値
   */
  static get propTypes() {
    return({
      completed: PropTypes.bool.isRequired,
      currentPassword: PropTypes.string.isRequired,
      emitter: PropTypes.object.isRequired,
      errorMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
      isLoading: PropTypes.bool.isRequired,
      newPassword: PropTypes.string.isRequired,
      newPasswordConfirmation: PropTypes.string.isRequired,
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);

    this.currentPasswordText = I18n.t('current_password', { scope: Constants.I18N_SCOPE });
    this.dialogHeadingText = I18n.t('dialog_heading', { scope: Constants.I18N_SCOPE });
    this.newPasswordText = I18n.t('new_password', { scope: Constants.I18N_SCOPE });
    this.newPasswordConfirmationText = I18n.t('new_password_confirmation', { scope: Constants.I18N_SCOPE });

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <section>
        <h1 className="ca-password-settings-dialog__heading">{this.dialogHeadingText}</h1>

        <form onSubmit={this.handleSubmit}>
          <fieldset className="ca-password-settings-dialog__fields">
            <PasswordField
              autoFocus={true}
              emitter={this.props.emitter}
              inputId="ca-password-settings-current-password"
              label={this.currentPasswordText}
              onChange={(newValue) => {
                this.props.emitter.emit(Constants.EVENT_CHANGE_CURRENT_PASSWORD, newValue);
              }}
              required={true}
              value={this.props.currentPassword}
            />

            <PasswordField
              emitter={this.props.emitter}
              inputId="ca-password-settings-new-password"
              label={this.newPasswordText}
              minLength={8}
              onChange={(newValue) => {
                this.props.emitter.emit(Constants.EVENT_CHANGE_NEW_PASSWORD, newValue);
              }}
              required={true}
              value={this.props.newPassword}
            />

            <PasswordField
              emitter={this.props.emitter}
              inputId="ca-password-settings-new-password-confirmation"
              label={this.newPasswordConfirmationText}
              minLength={8}
              onChange={(newValue) => {
                this.props.emitter.emit(Constants.EVENT_CHANGE_NEW_PASSWORD_CONFIRMATION, newValue);
              }}
              required={true}
              value={this.props.newPasswordConfirmation}
            />
          </fieldset>

          {this.props.errorMessages.length > 0 &&
            <ErrorMessages messages={this.props.errorMessages} />
          }

          <div className="text-center">
            <SubmitButton disabled={this.props.isLoading} />
          </div>
        </form>
      </section>
    );
  }

  /**
   * フォームが送信された際の処理を行います。
   *
   * @param {Object} event SyntheticEvent
   */
  handleSubmit(event) {
    this.props.emitter.emit(Constants.EVENT_UPDATE_PASSWORD);
    event.preventDefault();
  }
}
