import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactModal from "react-modal";

import { DuplicableJob } from "./types";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.duplicate_job_dialog";

/**
 * ジョブの複製を行うダイアログ。
 *
 * このコンポーネント内では以下のようなリクエストを発行します。
 *
 *   POST /trigger_jobs/:id/duplicate
 *
 */
const DuplicateJobDialog: React.FC<{
  isOpen: boolean; // ダイアログが表示されているかどうか
  job: DuplicableJob; // 対象のジョブ
  onClose: () => void; // ダイアログを閉じる際に呼び出されるコールバック
}> = (props) => {
  // 長すぎるジョブ名は末尾に「...」を付与して省略する
  const truncatedName = props.job.name.length > 100 ? props.job.name.slice(0, 100) + "..." : props.job.name;

  // サーバーと通信中かどうかをあらわすフラグ
  const [isTransmitting, setIsTransmitting] = useState(false);

  // 複製のXHRが失敗した場合のエラーメッセージ
  const [errorMessage, setErrorMessage] = useState("");

  const confirmText = useMemo(() => I18n.t("confirm", { scope: I18N_SCOPE }), [I18n.locale]);

  // ダイアログを閉じる操作が行われた際のコールバック
  const handleCloseRequest = useCallback(() => {
    props.onClose();
    setIsTransmitting(false);
    setErrorMessage("");
  }, [props.onClose]);

  // OKボタンが押された際のコールバック
  const handleOkClick = useCallback(() => {
    jQuery
      .ajax({
        url: props.job.duplicate_url,
        method: "POST",
        dataType: "json",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        headers: { "X-CSRF-Token": jQuery("meta[name='csrf-token']").attr("content") },
        beforeSend: () => {
          setIsTransmitting(true);
          setErrorMessage("");
        },
      })
      .done((_data, _textStatus, jqXHR) => {
        // 複製に成功した場合は、Locationヘッダに設定されたURLにリダイレクトする
        const location = jqXHR.getResponseHeader("Location") || "/";
        window.location.href = location;
      })
      .fail((data) => {
        // 複製に失敗した場合は画面遷移をせず、エラーメッセージの表示のみを行う
        setIsTransmitting(false);
        setErrorMessage(data.responseJSON.error);
      });
  }, [props.job.duplicate_url]);

  useEffect(() => {
    ReactModal.setAppElement("body");
  }, []);

  return (
    <ReactModal
      className="ca-duplicate-job-modal__content"
      isOpen={props.isOpen}
      onRequestClose={handleCloseRequest}
      overlayClassName="ca-duplicate-job-modal__overlay"
      role="dialog"
    >
      <div>
        <p>{confirmText}</p>
        <p className="text-break">
          <strong>{truncatedName}</strong>
        </p>
        {errorMessage && (
          <p className="ca-duplicate-job-modal__error">
            <label className="error">{errorMessage}</label>
          </p>
        )}
      </div>
      <div className="ca-duplicate-job-modal__buttons">
        <CancelButton onClick={handleCloseRequest} />
        <OkButton isTransmitting={isTransmitting} onClick={handleOkClick} />
      </div>
    </ReactModal>
  );
};

// ダイアログ内に表示するキャンセルボタン
const CancelButton: React.FC<{
  onClick: () => void; // クリック時に呼び出されるコールバック
}> = (props) => {
  return (
    <button className="btn btn-cancel btn-negative" onClick={props.onClick} type="button">
      <span className="fa fa-ban marginR5"></span>Cancel
    </button>
  );
};

// ダイアログ内に表示するOKボタン
const OkButton: React.FC<{
  isTransmitting: boolean; // サーバーと通信中かどうか
  onClick: () => void; // クリック時に呼び出されるコールバック
}> = (props) => {
  return (
    <button className="btn btn-danger" disabled={props.isTransmitting} onClick={props.onClick} type="button">
      <span className="fa fa-check marginR5"></span>OK
    </button>
  );
};

export default DuplicateJobDialog;
