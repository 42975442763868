import PropTypes from "prop-types";
import React from "react";

import Head from "./EbsBackupCheckResultsTableHead.jsx";
import Pagination from "../Pagination.jsx";
import Table from "./EbsBackupCheckResultsTable.jsx";

/**
 * AWSアカウントごとのEBSバックアップ監視結果テーブルのコンテナ
 *
 * 監視結果一覧テーブルの起点となり、以下の処理を行います。
 *
 * - 監視結果一覧JSONをサーバーから取得
 * - ページネーションイベント・ソートイベントの処理
 */
export default class EbsBackupCheckResultsTableContainer extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {number} perPage 1ページに表示する件数
   * @property {string} resultsUrl 監視結果一覧を取得するURL(もしくはパス)
   */
  static get propTypes() {
    return({
      perPage: PropTypes.number,
      resultsUrl: PropTypes.string.isRequired,
    });
  }

  /**
   * デフォルトのプロパティを返します。
   *
   * @public
   * @return {Object}
   * @property {number} perPage 1ページに表示する件数
   */
  static get defaultProps() {
    return({ perPage: 30 });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.state = {
      checkResults: [],
      currentPage: 1,
      pending: true,
      sortColumn: "error",
      sortDirection: "desc",
      totalCount: 1,
      totalPages: 1,
    };

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeSortOrder = this.handleChangeSortOrder.bind(this);
    this.handleFetchRecordsDone = this.handleFetchRecordsDone.bind(this);
    this.handleFetchRecordsFail = this.handleFetchRecordsFail.bind(this);
    this.prepareForFetchRecords = this.prepareForFetchRecords.bind(this);
  }

  /**
   * コンポーネントがマウントされた際の処理を行います。
   * @public
   */
  componentDidMount() {
    this.fetchRecords();
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <section>
        <Head
          checkedAt={this.state.checkedAt}
        />
        <Table
          callback={this.handleChangeSortOrder}
          checkResults={this.state.checkResults}
          pending={this.state.pending}
          sortColumn={this.state.sortColumn}
          sortDirection={this.state.sortDirection}
        />
        {this.isDataFetched() && this.isRecordExists() &&
          <Pagination
            callback={this.handleChangePage}
            currentPage={this.state.currentPage}
            perPage={this.props.perPage}
            totalItems={this.state.totalCount}
          />
        }
      </section>
    );
  }

  /**
   * 現在のソート条件を適用した一覧をサーバーから非同期に取得します
   * @private
   */
  fetchRecords() {
    const params = {
      beforeSend: this.prepareForFetchRecords,
      data: {
        order: `${this.state.sortColumn} ${this.state.sortDirection}`,
        page: this.state.currentPage,
        per: this.props.perPage,
      },
      dataType: "json",
      url: this.props.resultsUrl,
    };

    jQuery.ajax(params)
      .done(this.handleFetchRecordsDone)
      .fail(this.handleFetchRecordsFail);
  }

  /**
   * サーバーからレコードを取得完了した場合に行う処理を実行します。
   * @private
   * @param {Object} data ebs_backup_check_results#index が返すJSONレスポンス
   */
  handleFetchRecordsDone(data) {
    this.setState({
      pending: false,
      checkedAt: data.checked_at,
      checkResults: data.ebs_backup_check_results,
      totalCount: data.total_count,
      totalPages: data.total_pages,
    });
  }

  /**
   * サーバーからレコードを取得失敗した場合に行う処理を実行します。
   * @private
   */
  handleFetchRecordsFail() {
    this.setState({
      currentPage: 1,
      errorMessage: "Error!",
      pending: false,
      checkResults: [],
      totalCount: 0,
      totalPages: 1,
    });
  }

  /**
   * 表示するページを変更します。
   *
   * @private
   * @param {number} page 表示すべきページのページ番号
   */
  handleChangePage(page) {
    this.setState({
      currentPage: page,
    }, () => this.fetchRecords());
  }

  /**
   * ソート条件を変更します。
   *
   * @private
   * @param {string} column ソート対象のテーブル列
   */
  handleChangeSortOrder(column) {
    const newState = {
      currentPage: 1
    };

    if (column == this.state.sortColumn) {
      newState.sortDirection = this.invertSortDirection();
    } else {
      newState.sortColumn = column;
      newState.sortDirection = "asc";
    }

    this.setState(newState, () => {
      this.fetchRecords();
    });
  }

  /**
   * ソート順を反転します。
   *
   * @private
   * @return {string}
   */
  invertSortDirection() {
    if (this.state.sortDirection == "asc") { return "desc"; }
    return "asc";
  }

  /**
   * レコードの取得が完了しているかどうかを返します。
   *
   * @private
   * @return {boolean}
   */
  isDataFetched() {
    return !this.state.pending;
  }

  /**
   * 表示すべきレコードが1件以上存在するかどうかを返します。
   *
   * @private
   * @return {boolean}
   */
  isRecordExists() {
    return this.state.checkResults.length > 0;
  }

  /**
   * サーバーからレコードを取得する前に行う処理を実行します。
   * @private
   */
  prepareForFetchRecords() {
    this.setState({
      errorMessage: null,
      pending: true,
      checkResults: [],
      totalCount: 0,
      totalPages: 1,
    });
  }
}
