import React from 'react';
import PropTypes from 'prop-types';
import RecentLogsLinks from './RecentLogsLinks.jsx';

class RecentLogsLinkContainer extends React.Component {
  /**
   * プロパティ。
   *
   * @property {string} message ログ情報の代わりに表示するメッセージ
   * @property {array} logs ログ情報の配列
   */
  static get propTypes() {
    return ({
      message: PropTypes.string.isRequired,
      logs: PropTypes.array.isRequired,
    });
  }

  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.message) {
      return (
        <li className="dropdown-menu__list--info">
          <span>{this.props.message}</span>
        </li>
      );
    }

    return this.props.logs.map((log) => {
      return <RecentLogsLinks
        key={log.id}
        name={log.name}
        statusIconStyle={log.status_icon_style}
        timeAgo={log.time_ago}
        url={log.log_url}
      />;
    });
  }
}

export default RecentLogsLinkContainer;
