import EventEmitter from "events";
import React from 'react';
import PropTypes from 'prop-types';

import Constants from '../Constants.js';
import GroupSelectionField from './GroupSelectionField.jsx';
import Pagination from '../Pagination.jsx';
import PostProcessTable from './PostProcessTable.jsx';
import { ReloadButton } from "../ReloadButton.tsx";

const $ = window.jQuery;
const _ = window._; // Underscore.js
const I18n = window.I18n; // i18n-js
const I18N_SCOPE = { scope: 'javascript.post_process_table' };

/**
 * 後処理テーブルを含むコンテナコンポーネント
 *
 * このコンポーネントはマウント時およびソート条件が変更された場合のみ、サーバーからすべてのジョブの
 * JSONを取得します。
 * それ以外の操作（ページングなど）が行われた場合は既に取得済みのJSONをもとに表示するジョブを決定します。
 *
 * ステート
 * currentPage   - 現在のページ番号
 *                 初期値は 1
 * postProcesses - ユーザーのすべての後処理の配列
 *                 source プロパティのURLから返されるJSONの post_processes キーの値が設定される
 *                 初期値は空の配列
 * errorMessage  - エラーが発生した場合のエラーメッセージ
 *                 初期値は null
 * perPage       - 1ページあたりの表示件数
 *                 初期値は 10
 * searchInput   - 検索フィールドに入力された文字列
 *                 初期値は空文字列
 * selectedGroup - 選択されているグループ
 *                 初期値は空文字
 * sortColumn    - ソート列
 *                 初期値は updated_at
 * sortDirection - ソートの方向
 *                 初期値は desc
 */
export default class PostProcessTableContainer extends React.Component {
  /**
   * propTypes
   * @return {Object}
   * @property {Array<Object>} groups - groups - `[{id: 1, name: "hoge"}]` のようなグループ一覧
   * @property {boolean} policySetsAvailable 組織が構成レビュー機能を利用可能かどうか
   * @property {boolean} writable - 後処理作成ボタンを表示するかどうか
   * @property {string} source - 後処理のJSONを取得するためのURL
  **/
  static get propTypes() {
    return({
      groups: PropTypes.arrayOf(PropTypes.object).isRequired,
      policySetsAvailable: PropTypes.bool.isRequired,
      writable: PropTypes.bool,
      source: PropTypes.string
    });
  }

  /**
   * デフォルトのプロパティ値を返します。
   * React内部から呼び出されます。
   *
   * @return {object}
   */
  static get defaultProps() {
    return({
      source: Constants.URL.POST_PROCESSES_SOURCE
    });
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);
    this.state = this.initialState();

    // 設定保存処理を最大で1秒間に1回しか呼ばないようにしたバージョンを用意する
    this.throttledSaveSettingsFunc = _.throttle(this.saveSettings, 1000);

    this.handleClickSortColumn = this.handleClickSortColumn.bind(this);
    this.handleGroupSelectorChange = this.handleGroupSelectorChange.bind(this);
    this.handlePaginationPageClick = this.handlePaginationPageClick.bind(this);
    this.handleSearchInputFieldChange = this.handleSearchInputFieldChange.bind(this);

    this.emitter = new EventEmitter();
    this.emitter.on("reload", () => { this.loadAllPostProcesses(); });
  }

  /**
   * ステートの初期値を返します。
   *
   * @return {object}
   */
  initialState() {
    // デフォルト値(Cookieに設定が存在しない場合はこれらの値が使われる)
    let currentPage = 1;
    let perPage = 30;
    let searchInput = '';
    let selectedGroup = '';
    let sortColumn = 'updated_at';
    let sortDirection = 'desc';

    // Cookieに設定がある場合はその値をロードする
    $.cookie.json = true;
    const settings = $.cookie(Constants.COOKIE.POST_PROCESS_DASHBOARD_SETTINGS);
    if ($.isPlainObject(settings)) {
      if (settings.current_page != null) {
        currentPage = Number(settings.current_page);
      }
      if (settings.per_page != null) {
        perPage = Number(settings.per_page);
      }
      if (settings.search_input != null) {
        searchInput = settings.search_input;
      }
      if (settings.selected_group != null) {
        selectedGroup = settings.selected_group;
      }
      if (settings.sort_column != null) {
        sortColumn = settings.sort_column;
      }
      if (settings.sort_direction != null) {
        sortDirection = settings.sort_direction;
      }
    }

    return({
      currentPage: currentPage,
      errorMessage: null,
      isTransmitting: false, // 通信中かどうか
      perPage: perPage,
      postProcesses: [],
      searchInput: searchInput,
      selectedGroup: selectedGroup,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
    });
  }

  /**
   * コンポーネントがマウントされた際の処理を行います。
   */
  componentDidMount() {
    this.loadAllPostProcesses();
  }

  /**
   * @return {ReactElement}
   */
  render() {
    // 現在のページに表示するジョブだけを取得する
    const postProcesses = this.getPostProcessesForCurrentPage();
    const currentPagePostProcesses = postProcesses.currentPostProcesses;

    // キーワードによる絞り込みがされていない場合はページングナビゲーションを表示する
    let pagination = null;
    if (this.state.searchInput == '' && currentPagePostProcesses.length > 0) {
      pagination =
        <Pagination
          totalItems={postProcesses.totalCount}
          perPage={this.state.perPage}
          currentPage={this.state.currentPage}
          callback={this.handlePaginationPageClick}
        />;
    }

    let postProcessCount =
      this.state.searchInput == "" && this.state.selectedGroup === ""
        ? this.state.postProcesses.length
        : currentPagePostProcesses.length;

    return(
      <React.StrictMode>
        <div className="ca-post-process-table clearfix">
          <div className="ca-post-process-table__search">
            <GroupSelectionField
              onChange={this.handleGroupSelectorChange}
              groups={this.props.groups}
              value={String(this.state.selectedGroup)}
            />
            <SearchInputField
              onChange={this.handleSearchInputFieldChange}
              value={this.state.searchInput}
              total={this.state.postProcesses.length}
            />
            <span className="ca-search-box__metainfo">
              {I18n.t('search_total', { total: postProcessCount, scope: I18N_SCOPE['scope'] })}
            </span>
            {this.renderAddProcessButton()}
            <div className="marginL10">
              <ReloadButton isTransmitting={this.state.isTransmitting} onClick={() => this.emitter.emit("reload")} />
            </div>
          </div>
          <PostProcessTable
            postProcesses={currentPagePostProcesses}
            errorMessage={this.state.errorMessage}
            onSort={this.handleClickSortColumn}
            policySetsAvailable={this.props.policySetsAvailable}
            reloadEmitter={this.emitter}
            sortColumn={this.state.sortColumn}
            sortDirection={this.state.sortDirection}
          />
          {pagination}
        </div>
      </React.StrictMode>
    );
  }

  /**
   * 後処理追加ボタンを返します
   *
   * @return {ReactElement}
   */
  renderAddProcessButton() {
    if(this.props.writable) {
      return(<AddPostProcessButton/>);
    }
  }

  /**
   * ソート用カラムがクリックされた際の処理を行います。
   *
   * @private
   * @param {string} column カラム名
   */
  handleClickSortColumn(column) {
    let newState = {};

    // ソート時は常に先頭ページを表示(ログ一覧の画面仕様に準拠)
    newState.currentPage = 1;

    if (column == this.state.sortColumn) {
      // クリックされたカラムが現在のソート用カラムと同じ場合は、ソート方向の切り替えと
      // なるため sortDirection のみを更新する
      newState.sortDirection =
        this.state.sortDirection == 'asc' ? 'desc' : 'asc';
    } else {
      // ソート用カラムとソート方向(昇順)を更新する
      newState.sortColumn = column;
      newState.sortDirection = 'asc';
    }

    this.setState(newState, () => {
      this.loadAllPostProcesses();
      this.saveSettings();
    });
  }

  handleGroupSelectorChange(group) {
    this.setState(
      {
        selectedGroup: group,
        currentPage: 1,
      },
      () => this.saveSettings());
  }

  /**
   * ページングナビゲーションのページ番号がクリックされた際の処理を実行します。
   * @param {number} page ページ番号
   */
  handlePaginationPageClick(page) {
    this.setState({ currentPage: page }, () => this.saveSettings());
  }

  /**
   * 検索フィールドの値が変更された際の処理を実行します。
   * @param {string} newValue 新しい値
   */
  handleSearchInputFieldChange(newValue) {
    // state更新後に呼び出す関数が saveSettings ではないのは設定保存処理の発生頻度を抑えるため。
    // 代わりに、呼び出し頻度を最大で1秒間に1回に制限した設定保存関数を渡す。
    this.setState({ searchInput: newValue }, this.throttledSaveSettingsFunc);
  }

  /**
   * 現在のソート条件を使ってすべての後処理をサーバーから取得します。
   */
  loadAllPostProcesses() {
    // ジョブのJSONを取得する際のソート条件を 'name asc' のような文字列として生成する
    const orderParam = `${this.state.sortColumn} ${this.state.sortDirection}`;

    // ジョブのJSONを取得するためのURL
    const jsonUrl = `${this.props.source}?order=${orderParam}`;

    $.ajax({
      type: "GET",
      url: jsonUrl,
      beforeSend: () => {
        this.setState({ isTransmitting: true });
      },
    }).done((data) => {
      // レスポンスのJSONフォーマットをチェックする
      const requiredKeys = ['total', 'post_processes'];
      let invalidFormat = false;
      requiredKeys.forEach((key) => {
        if (data[key] == undefined) {
          invalidFormat = true;
        }
      });

      if (invalidFormat) {
        this.setState({
          currentPage: 1,
          isTransmitting: false,
          postProcesses: [],
          errorMessage: I18n.t('invalid_server_response', I18N_SCOPE),
        });
        return;
      }

      let newState = {
        errorMessage: null,
        isTransmitting: false,
        postProcesses: data.post_processes,
      };

      // 現在ページが1以外の場合は、現在ページが超過しないかチェックする
      if (this.state.currentPage > 1) {
        // 取得したジョブの数が現在ページに表示する件数よりも少ない場合は
        // 現在ページを1にする(Cookiesに設定が保存されていた場合に対応するため)
        const idxOfCurrentPagePostProcess = ((this.state.currentPage - 1) * this.state.perPage) + 1;
        if (data.total < idxOfCurrentPagePostProcess) {
          newState.currentPage = 1;
        }
      }

      this.setState(newState);
    }).fail(() => {
      this.setState({
        currentPage: 1,
        isTransmitting: false,
        postProcesses: [],
        errorMessage: I18n.t('load_failed', I18N_SCOPE),
      });
    });
  }

  /**
   * サーバーから取得した後処理を検索フィールドの入力値で絞り込んだうえで、
   * 現在のページに表示する範囲の後処理だけを返します。
   * @return {array}
   */
  getPostProcessesForCurrentPage() {
    let postProcesses = this.state.postProcesses;
    let pagination = true;
    let totalCount = 0;

    // 検索フィールドに入力がある場合は名前で絞り込む
    if (this.state.searchInput != '') {
      // 検索フィールドの入力値を正規表現に変換する
      const escapedKeyword = this.state.searchInput.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
      const regexp = new RegExp(escapedKeyword, 'i');
      // 名前が正規表現にマッチする後処理だけに絞り込む
      postProcesses = postProcesses.filter((postProcess) => regexp.test(postProcess.name));
      // 名前で絞り込んだ場合はページングを行わない
      pagination = false;
    }

    if (this.state.selectedGroup) {
      postProcesses = postProcesses.filter((postProcess) =>
        !postProcess.shared_by_group && postProcess.group.id === this.state.selectedGroup
      );
    } else {
      const SHARED_BY_GROUP_OPTION_VALUE = 0; //共通後処理の選択値

      if (this.state.selectedGroup === SHARED_BY_GROUP_OPTION_VALUE) {
        postProcesses = postProcesses.filter((postProcess) => postProcess.shared_by_group);
      }
    }

    // 全体件数
    totalCount = postProcesses.length;

    if (pagination) {
      // ジョブを現在のページに表示する範囲だけに絞り込む
      const start = this.state.perPage * (this.state.currentPage - 1);
      const end = this.state.perPage * this.state.currentPage;
      postProcesses = postProcesses.slice(start, end);
    }

    return {
      currentPostProcesses: postProcesses,
      totalCount: totalCount
    };
  }

  /**
   * 現在の設定をCookieに保存します。
   */
  saveSettings() {
    // 選択されたページをCookieに保存する
    $.cookie(Constants.COOKIE.POST_PROCESS_DASHBOARD_SETTINGS, {
      current_page: this.state.currentPage,
      per_page: this.state.perPage,
      search_input: this.state.searchInput,
      selected_group: this.state.selectedGroup,
      sort_column: this.state.sortColumn,
      sort_direction: this.state.sortDirection
    });
  }
}

/**
 * 検索フィールドコンポーネント
 */
class SearchInputField extends React.Component {
  /**
   * propTypes
   * @property {}
   */
  static get propTypes() {
    return({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired,
    });
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);
    this.placeholder = I18n.t('search_placeholder', I18N_SCOPE);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <div className="ca-search-box">
        <i className="ca-search-box__icon fa fa-search"></i>
        <input
          className="ca-search-box__input ca-search-box__input--small"
          onChange={this.handleOnChange}
          placeholder={this.placeholder}
          type="search"
          value={this.props.value}
        />
      </div>
    );
  }

  /**
   * 入力欄の値が変わった際に呼び出されるイベントハンドラ。
   * @param {SyntheticEvent} event
   */
  handleOnChange(event) {
    this.props.onChange($(event.target).val());
  }
}

/**
 * 後処理の追加ボタン
 * aタグ要素を返します
 *
 * @return {ReactElement}
 */
class AddPostProcessButton extends React.Component {
  render() {
    const url = Constants.URL.NEW_POST_PROCESS;
    const addButtonTitle = I18n.t('add_post_process', I18N_SCOPE);

    return(
      <div className="ca-post-process-table__create">
        <a href={url} className="btn btn-highlight">
          <span className="fa fa-plus-square marginR5"></span>
          <span className="font-12px">{addButtonTitle}</span>
        </a>
      </div>
    );
  }
}
