/**
 * TODO: 機能フラグ v27_job_workflow_improvement が不要になったらこのコンポーネントを削除する
 */

import EventEmitter from "events";
import React from "react";
import PropTypes from "prop-types";
import Select2 from "react-select2-wrapper";

import Constants from "./Constants.js";
import { SelectFilterNote } from "../SelectFilterNote";
import { JobWorkflowFirstJobDetail } from "./JobWorkflowFirstJobDetail";
import { ProviderIcon } from "../ProviderIcon";
import StepCircle from "./StepCircle.jsx";
import StepsHint from "./StepsHint.jsx";

const I18n = window.I18n; // i18n-js

/**
 * ジョブワークフロー作成フォームコンテナ
 */
export default class OldJobWorkflowNewFormContainer extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {object[]} groups グループの配列
   * @property {string} jobWorkflowPath ジョブワークフロー作成リクエストの送信先パス
   * @property {string} triggerJobsPath ジョブ一覧取得リクエストの送信先パス
   */
  static get propTypes() {
    return {
      groups: PropTypes.arrayOf(PropTypes.object).isRequired,
      jobWorkflowPath: PropTypes.string.isRequired,
      triggerJobsPath: PropTypes.string.isRequired,
    };
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.state = {
      firstJobs: {}, // 先頭ジョブ候補となるジョブ(ジョブIDがキー)
      followingJobs: {}, // 後続ジョブ候補となるジョブ情報の配列(ジョブIDがキー)
      groupId: "", // グループIDの現在値
      isExpiredFirstJob: false, // 先頭ジョブがExpireしているスケジュールトリガーかどうか
      isLoadingJobs: false, // グループに属するジョブ情報の取得中かどうか
      isSubmitting: false, // フォームを送信中かどうか
      isSubmitted: false, // フォーム送信を完了したかどうか
      name: "", // ジョブワークフロー名
      submitErrorMessage: "", // フォーム送信時のエラーメッセージ
      triggerJobId1: "", // ステップ1で選択されたジョブID
      triggerJobId2: "", // ステップ2で選択されたジョブID
      triggerJobId3: "", // ステップ3で選択されたジョブID
      triggerJobId4: "", // ステップ4で選択されたジョブID
      triggerJobId5: "", // ステップ5で選択されたジョブID
      triggerJobId6: "", // ステップ6で選択されたジョブID
      triggerJobId7: "", // ステップ7で選択されたジョブID
      triggerJobId8: "", // ステップ8で選択されたジョブID
      triggerJobId9: "", // ステップ9で選択されたジョブID
      triggerJobId10: "", // ステップ10で選択されたジョブID
      triggerJobId11: "", // ステップ11で選択されたジョブID
      triggerJobId12: "", // ステップ12で選択されたジョブID
      triggerJobId13: "", // ステップ13で選択されたジョブID
      triggerJobId14: "", // ステップ14で選択されたジョブID
      triggerJobId15: "", // ステップ15で選択されたジョブID
      triggerJobId16: "", // ステップ16で選択されたジョブID
      triggerJobId17: "", // ステップ17で選択されたジョブID
      triggerJobId18: "", // ステップ18で選択されたジョブID
      triggerJobId19: "", // ステップ19で選択されたジョブID
      triggerJobId20: "", // ステップ20で選択されたジョブID
    };

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeGroup = this.handleChangeGroup.bind(this);
    this.handleChangeTriggerJob = this.handleChangeTriggerJob.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);

    this.initEventEmitter();
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    let formClasses = "ca-job-workflow__form ca-job-workflow__form--new";

    if (this.state.isExpiredFirstJob) {
      formClasses += " ca-job-workflow__form--expired-schedule";
    }

    return (
      <React.Fragment>
        <form className={formClasses} onSubmit={this.handleSubmitForm}>
          <section className="ca-job-workflow__form-fieldset-wrapper">
            <fieldset className="ca-job-workflow__form-fieldset">
              <h2 className="ca-job-workflow__form-fieldset-heading">
                <i aria-hidden className="fa fa-pencil-square-o marginR5"></i>
                {I18n.t("basic_settings", { scope: Constants.I18N_SCOPE })}
              </h2>

              <div className="ca-job-workflow__form-fields">
                <div
                  className="
                  form-group
                  ca-job-workflow__form-field
                "
                >
                  <label
                    htmlFor="ca-job-workflow-name-field"
                    className="
                      ca-job-workflow__form-label
                      ca-job-workflow__form-label--required
                    "
                  >
                    {I18n.t("name", { scope: Constants.I18N_SCOPE })}
                  </label>
                  <div className="ca-job-workflow__form-control">
                    <input
                      className="form-control"
                      id="ca-job-workflow-name-field"
                      onChange={this.handleChangeName}
                      type="text"
                      value={this.state.name}
                    />
                  </div>
                </div>

                <div
                  className="
                  form-group
                  ca-job-workflow__form-field
                "
                >
                  <label
                    htmlFor="ca-job-workflow-group-id-field"
                    className="
                      ca-job-workflow__form-label
                      ca-job-workflow__form-label--required
                    "
                  >
                    {I18n.t("group", { scope: Constants.I18N_SCOPE })}
                  </label>
                  <div className="ca-job-workflow__form-control">
                    {SelectFilterNote()}
                    <Select2
                      className="form-control"
                      id="ca-job-workflow-group-id-field"
                      onSelect={this.handleChangeGroup}
                      value={this.state.groupId}
                      data={this.props.groups.map((group) => {
                        return { id: group.id, text: group.name };
                      })}
                      options={{
                        dropdownAdapter: window.jQuery.fn.select2.amd.require("ForceDropDownAdapter"),
                        forceDropdownPosition: "below",
                        language: window.jQuery.fn.select2.amd.require(`select2/i18n/${window.I18n.locale}`),
                        placeholder: I18n.t("blank_option", { scope: Constants.I18N_SCOPE }),
                        theme: "kanrinmaru-v2",
                      }}
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          </section>

          <section className="ca-job-workflow__form-fieldset-wrapper">
            <fieldset className="ca-job-workflow__form-fieldset">
              <h2 className="ca-job-workflow__form-fieldset-heading">
                <i aria-hidden className="fa fa-dashboard marginR5"></i>
                {I18n.t("job_settings", { scope: Constants.I18N_SCOPE })}
              </h2>
              <div className="ca-job-workflow__form-notice">
                <StepsHint />
                {this.renderJobWorkflowTriggerJob()}
              </div>
              <div className="ca-job-workflow__form-fields">
                {this.renderFirstStep()}
                {this.renderFollowingStep(2)}
                {this.renderFollowingStep(3)}
                {this.renderFollowingStep(4)}
                {this.renderFollowingStep(5)}
                {this.renderFollowingStep(6)}
                {this.renderFollowingStep(7)}
                {this.renderFollowingStep(8)}
                {this.renderFollowingStep(9)}
                {this.renderFollowingStep(10)}
                {this.renderFollowingStep(11)}
                {this.renderFollowingStep(12)}
                {this.renderFollowingStep(13)}
                {this.renderFollowingStep(14)}
                {this.renderFollowingStep(15)}
                {this.renderFollowingStep(16)}
                {this.renderFollowingStep(17)}
                {this.renderFollowingStep(18)}
                {this.renderFollowingStep(19)}
                {this.renderFollowingStep(20)}
              </div>
            </fieldset>
          </section>

          <section className="ca-job-workflow__form-fieldset-wrapper">
            <fieldset className="ca-job-workflow__form-fieldset text-center">
              {this.state.submitErrorMessage && (
                <p className="ca-job-workflow__form-error-message">{this.state.submitErrorMessage}</p>
              )}

              <button className="btn btn-primary btn-lg" disabled={!this.isFormFilled()} type="submit">
                <i className="fa fa-check-circle fa-lg"></i>{" "}
                {I18n.t(this.state.isSubmitting ? "submitting" : "submit", { scope: Constants.I18N_SCOPE })}
              </button>
            </fieldset>
          </section>
        </form>
      </React.Fragment>
    );
  }

  renderJobWorkflowTriggerJob() {
    const job = this.state.triggerJobId1 !== "" ? this.state.firstJobs[this.state.triggerJobId1] : undefined;
    return <JobWorkflowFirstJobDetail job={job} />;
  }

  renderFirstStep() {
    return (
      <div className="ca-job-workflow__form-field" data-qa={`step-1`}>
        <div className="ca-job-workflow__form-step-row">
          <StepCircle active={this.state.triggerJobId1 != ""} step={1} />
          <select
            className="form-control ca-job-workflow__form-control ca-job-workflow__form-step-select"
            onChange={(e) => this.handleChangeTriggerJob(1, e.target.value)}
            value={this.state.triggerJobId1}
          >
            <option value="">{I18n.t("blank_option", { scope: Constants.I18N_SCOPE })}</option>
            {Object.keys(this.state.firstJobs).map((id) => {
              const job = this.state.firstJobs[id];
              return (
                <option key={job.id} value={job.id}>
                  {job.name}
                </option>
              );
            })}
          </select>

          <div>
            <a
              href="#"
              onClick={(e) => {
                this.handleChangeTriggerJob(1, "");
                e.preventDefault();
              }}
            >
              <i className="fa fa-trash-o ca-job-workflow__form-step-trash-icon"></i>
            </a>
          </div>

          {this.state.triggerJobId1 && (
            <React.Fragment>
              <div className="ca-job-workflow__form-step-action-type">
                <ProviderIcon identifier={this.state.firstJobs[this.state.triggerJobId1].provider_identifier} />
                {this.state.firstJobs[this.state.triggerJobId1].action_type_i18n}
              </div>
              <div className="ca-job-workflow__form-step-link">
                <a
                  className="marginL10"
                  href={this.state.firstJobs[this.state.triggerJobId1].show_url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i aria-hidden className="fa fa-external-link"></i>{" "}
                  {I18n.t("link_to_job", { scope: Constants.I18N_SCOPE })}
                </a>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  renderFollowingStep(step) {
    const currentValue = this.state[`triggerJobId${step}`];
    let rowClasses = "ca-job-workflow__form-step-row";
    if (step == 1) {
      rowClasses = `${rowClasses} ca-job-workflow__form-step-row--first`;
    } else if (step == 10) {
      rowClasses = `${rowClasses} ca-job-workflow__form-step-row--last`;
    }

    return (
      <div className="ca-job-workflow__form-field" data-qa={`step-${step}`}>
        <div className={rowClasses}>
          <StepCircle active={currentValue != ""} step={step} />
          <select
            className="form-control ca-job-workflow__form-control ca-job-workflow__form-step-select"
            value={currentValue}
            onChange={(e) => this.handleChangeTriggerJob(step, e.target.value)}
          >
            <option value="">{I18n.t("blank_option", { scope: Constants.I18N_SCOPE })}</option>
            {currentValue && (
              <option key={currentValue} value={currentValue}>
                {this.state.followingJobs[currentValue].name}
              </option>
            )}
            {this.availableFollowingJobIds().map((id) => {
              const job = this.state.followingJobs[id];
              return (
                <option key={job.id} value={job.id}>
                  {job.name}
                </option>
              );
            })}
          </select>

          <div>
            <a
              href="#"
              onClick={(e) => {
                this.handleChangeTriggerJob(step, "");
                e.preventDefault();
              }}
            >
              <i className="fa fa-trash-o ca-job-workflow__form-step-trash-icon"></i>
            </a>
          </div>

          {currentValue && (
            <React.Fragment>
              <div className="ca-job-workflow__form-step-action-type">
                <ProviderIcon identifier={this.state.followingJobs[currentValue].provider_identifier} />
                {this.state.followingJobs[currentValue].action_type_i18n}
              </div>
              <div className="ca-job-workflow__form-step-link">
                <a
                  className="marginL10"
                  href={this.state.followingJobs[currentValue].show_url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i aria-hidden className="fa fa-external-link"></i>{" "}
                  {I18n.t("link_to_job", { scope: Constants.I18N_SCOPE })}
                </a>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  availableFollowingJobIds() {
    const ids = Object.keys(this.state.followingJobs);
    return ids.filter((id) => {
      return (
        id != this.state.triggerJobId2 &&
        id != this.state.triggerJobId3 &&
        id != this.state.triggerJobId4 &&
        id != this.state.triggerJobId5 &&
        id != this.state.triggerJobId6 &&
        id != this.state.triggerJobId7 &&
        id != this.state.triggerJobId8 &&
        id != this.state.triggerJobId9 &&
        id != this.state.triggerJobId10 &&
        id != this.state.triggerJobId11 &&
        id != this.state.triggerJobId12 &&
        id != this.state.triggerJobId13 &&
        id != this.state.triggerJobId14 &&
        id != this.state.triggerJobId15 &&
        id != this.state.triggerJobId16 &&
        id != this.state.triggerJobId17 &&
        id != this.state.triggerJobId18 &&
        id != this.state.triggerJobId19 &&
        id != this.state.triggerJobId20
      );
    });
  }

  /**
   * 現在選択中のグループに属するワークフロー専用ジョブの情報を取得します。
   *
   * @private
   */
  fetchTriggerJobs() {
    window.jQuery
      .ajax({
        data: {
          group_id: this.state.groupId,
        },
        dataType: "json",
        url: this.props.triggerJobsPath,
        beforeSend: () => {
          this.setState({ isLoadingJobs: true });
        },
      })
      .done((data) => {
        this.setState({
          firstJobs: data.first_jobs,
          followingJobs: data.following_jobs,
          isLoadingJobs: false,
        });
      })
      .fail(() => {
        this.setState({
          errorMessage: I18n.t("fetch_failed", { scope: Constants.I18N_SCOPE }),
          firstJobs: {},
          followingJobs: {},
          isLoadingJobs: false,
        });
      });
  }

  /**
   * 現在選択中のグループを変更します。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleChangeGroup(event) {
    const groupId = event.target.value;
    const newValue = {
      groupId: groupId,
      // グループに由来する以下のステートを初期化する
      firstJobs: {},
      followingJobs: {},
      triggerJobId1: "",
      triggerJobId2: "",
      triggerJobId3: "",
      triggerJobId4: "",
      triggerJobId5: "",
      triggerJobId6: "",
      triggerJobId7: "",
      triggerJobId8: "",
      triggerJobId9: "",
      triggerJobId10: "",
      triggerJobId11: "",
      triggerJobId12: "",
      triggerJobId13: "",
      triggerJobId14: "",
      triggerJobId15: "",
      triggerJobId16: "",
      triggerJobId17: "",
      triggerJobId18: "",
      triggerJobId19: "",
      triggerJobId20: "",
    };

    if (groupId != "") {
      this.setState(newValue, this.fetchTriggerJobs);
    } else {
      // グループが未選択になった場合はジョブ情報の非同期取得は行わない
      this.setState(newValue);
    }
  }

  /**
   * ジョブワークフロー名の現在値を変更します。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }

  /**
   * 指定されたステップのジョブを変更します。
   *
   * 指定されたのが先頭ジョブかつ、予定日時に未来の日時が無い
   * スケジュールトリガーだった場合は編集フォームに警告を表示します。
   *
   * @private
   * @param {string} step ステップ(1〜10)
   * @param {string} triggerJobId 変更後のジョブID
   */
  handleChangeTriggerJob(step, triggerJobId) {
    const key = `triggerJobId${step}`;
    this.setState({ [key]: triggerJobId });

    if (step != 1) {
      // 先頭ジョブ以外の場合
      return;
    }

    const job = this.state.firstJobs[triggerJobId];
    let isExpiredMessage = false;

    if (job && job.rule_type == "schedule") {
      isExpiredMessage = job.expired_schedule_job;
    }

    this.setState({
      isExpiredFirstJob: isExpiredMessage,
    });
  }

  /**
   * フォームを送信します。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleSubmitForm(event) {
    event.preventDefault();
    window.jQuery
      .ajax({
        method: "POST",
        data: {
          name: this.state.name,
          group_id: this.state.groupId,
          first_job_id: this.state.triggerJobId1,
          following_job_ids: [
            this.state.triggerJobId2,
            this.state.triggerJobId3,
            this.state.triggerJobId4,
            this.state.triggerJobId5,
            this.state.triggerJobId6,
            this.state.triggerJobId7,
            this.state.triggerJobId8,
            this.state.triggerJobId9,
            this.state.triggerJobId10,
            this.state.triggerJobId11,
            this.state.triggerJobId12,
            this.state.triggerJobId13,
            this.state.triggerJobId14,
            this.state.triggerJobId15,
            this.state.triggerJobId16,
            this.state.triggerJobId17,
            this.state.triggerJobId18,
            this.state.triggerJobId19,
            this.state.triggerJobId20,
          ],
        },
        dataType: "json",
        url: this.props.jobWorkflowPath,
        beforeSend: () => {
          this.setState({
            isSubmitting: true,
            isSubmitted: false,
          });
        },
      })
      .done((data) => {
        this.setState(
          {
            // isSubmitting: false,
            isSubmitted: true,
          },
          () => {
            window.location.href = data.url;
          }
        );
      })
      .fail((data) => {
        const error = I18n.t("submit_failed", { scope: Constants.I18N_SCOPE });
        const json = data.responseJSON;
        const message = `${error} ${json.error}`;
        this.setState({ isSubmitting: false, submitErrorMessage: message });
      });
  }

  isFormFilled() {
    return (
      this.state.name != "" &&
      this.state.groupId != "" &&
      this.state.triggerJobId1 != "" &&
      this.state.triggerJobId2 != ""
    );
  }

  /**
   * EventEmitterを初期化します。
   *
   * 各コンポーネント内で発生するイベントとイベントハンドラ関数の割り当てを行います。
   * 生成されたEventEmitterオブジェクトは this.emitter にアサインされます。
   *
   * @private
   */
  initEventEmitter() {
    this.emitter = new EventEmitter();
    this.emitter.on(Constants.EVENT_CHANGE_GROUP, this.handleChangeGroup);
    this.emitter.on(Constants.EVENT_CHANGE_NAME, this.handleChangeName);
    this.emitter.on(Constants.EVENT_CHANGE_TRIGGER_JOB, this.handleChangeTriggerJob);
    this.emitter.on(Constants.EVENT_SUBMIT_FORM, this.handleSubmitForm);
  }
}
