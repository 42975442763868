// ユーザーテーブル関連コンポーネントで利用する定数

export default {
  // ソート用カラム名
  COLUMN: {
    CREATED_AT_TIME: 'created_at_time',
    DEPARTMENT: 'department',
    EMAIL: 'email',
    FULL_NAME: 'full_name',
    USER_TYPE: 'user_type',
  },

  // ソート方向
  DIRECTION: {
    ASC: 'asc',
    DESC: 'desc'
  },

  // EventEmitterで扱うイベントの識別子
  EVENT_CHANGE_CURRENT_PAGE: 'changeCurrentPage',
  EVENT_CHANGE_SEARCH_INPUT: 'changeSearchInput',
  EVENT_CHANGE_SORT_COLUMN: 'changeSortColumn',

  // I18nメッセージリソースのスコープ
  I18N_SCOPE: 'javascript.user_table',
};
