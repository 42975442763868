import React from "react";

const I18n = window.I18n; // i18n-js

export const SelectFilterNote: React.FC = () => {
  return (
    <div className="help-block marginB5 ca-select-filter-note">
      <i className="fa-sharp fa-solid fa-badge-check"></i>
      {I18n.t("common.notes.select_filter")}
    </div>
  );
};
