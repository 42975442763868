import React from "react";
import PropTypes from "prop-types";

import withBootstrapTooltip from "../withBootstrapTooltip.js";

import GroupIconTip from "../GroupIconTip.jsx";
import { ProviderIcon } from "../ProviderIcon";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.post_process_trigger_jobs";

/**
 * 割当ジョブテーブル行コンポーネント
 */
class TriggerJobTableRow extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @property {Object} triggerJob 1つのトリガージョブのデータを持つオブジェクト。
   * @property {?function(element: DOMNode)} tooltipRef
   */
  static get propTypes() {
    return {
      triggerJob: PropTypes.object.isRequired,
      tooltipRef: PropTypes.func,
    };
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const triggerJob = this.props.triggerJob;
    const I18nTriggerJobActive = `${I18N_SCOPE}.active`;

    let disabledClass = null;
    let enableOnCompleted = null;
    let enableOnFailed = null;
    let jobStateText = null;
    let jobWorkflowData = null;
    let forWorkflowIcon = null;

    if (triggerJob.active === "false") {
      disabledClass = "ca-post-process-jobs__disabled";
    }
    if (triggerJob.for_workflow) {
      jobStateText = I18n.t("no_rule", {scope: I18N_SCOPE});
      forWorkflowIcon =
        <i
          className="fas fa-chart-network"
          data-toggle="tooltip"
          data-placement="bottom"
          title={I18n.t("for_workflow", {scope: I18N_SCOPE})}
          ></i>;
    } else {
      jobStateText = I18n.t(triggerJob.active, {scope: I18nTriggerJobActive});
    }

    jobWorkflowData =
      <td ref={this.props.tooltipRef}>
        {forWorkflowIcon}
      </td>;

    if (triggerJob.enable_on_completed) {
      enableOnCompleted =
        <span className="ca-post-process-jobs__completed">
          {I18n.t("enumerize.post_process_assignment.enabled_on.completed")}
        </span>;
    }
    if (triggerJob.enable_on_failed) {
      enableOnFailed =
        <span className="ca-post-process-jobs__failed">
          {I18n.t("enumerize.post_process_assignment.enabled_on.failed")}
        </span>;
    }

    return (
      <tr className={disabledClass}>
        <td>
          <ProviderIcon identifier={triggerJob.provider_identifier} />
        </td>
        <td>
          <div className="name-col">
            <GroupIconTip group={triggerJob.group} />
            {triggerJob.url ?
              <a href={triggerJob.url}>{triggerJob.name}</a> :
              triggerJob.name
            }
          </div>
        </td>
        {jobWorkflowData}
        <td className="ca-post-process-jobs__trigger-action__data">
          <span className="trigger-name">{I18n.t(triggerJob.rule_type, {scope: "common.rule_types_abb"})}</span>
          <span className="fa fa-arrow-circle-right fa-sm"></span>
          <span className="action-name">{I18n.t(triggerJob.action_type, {scope: "common.action_types"})}</span>
        </td>
        <td>{jobStateText}</td>
        <td>
          {enableOnCompleted}
          {enableOnFailed}
        </td>
      </tr>
    );
  }
}

// TriggerJobTableRow コンポーネント内で Tooltip を使えるようにしてから外部にエクスポートする
export default withBootstrapTooltip(TriggerJobTableRow);
