import React from "react";
import PropTypes from "prop-types";

import Constants from "../Constants.js";
import DashboardSpinner from "../DashboardSpinner.jsx";
import Pagination from "../Pagination.jsx";
import SortableTableHeaderCell from "../SortableTableHeaderCell.jsx";

import DeleteGroupLink from "./DeleteGroupLink.jsx";

const $ = window.jQuery;
const _ = window._; // Underscore.js
const I18n = window.I18n;
const I18N_SCOPE = "javascript.group_table";

// グループ一覧のテーブルに表示される列の数
const TABLE_COLUMNS = 7;

/**
 * グループテーブルを含むコンテナコンポーネント
 *
 * このコンポーネントはマウント時およびソート条件が変更された場合のみ、サーバーからすべてのグループの
 * JSONを取得します。
 * それ以外の操作（ページングなど）が行われた場合は既に取得済みのJSONをもとに表示するジョブを決定します。
 *
 * プロパティ
 * source      - 後処理のJSONを取得するためのURL、オプション
 *               デフォルト値は Constants.URL.GROUPS_SOURCE
 *
 * ステート
 * currentPage   - 現在のページ番号
 *                 初期値は 1
 * groups - ユーザーのすべての後処理の配列
 *          source プロパティのURLから返されるJSONの groups キーの値が設定される
 *          初期値は空の配列
 * errorMessage  - エラーが発生した場合のエラーメッセージ
 *                 初期値は null
 * perPage       - 1ページあたりの表示件数
 *                 初期値は 10
 * searchInput   - 検索フィールドに入力された文字列
 *                 初期値は空文字列
 * totalItems    - ユーザーのすべてのジョブの数
 *                 source プロパティのURLから返されるJSONの total キーの値が設定される
 *                 初期値は 0
 */
export default class GroupTableContainer extends React.Component {
  /**
   * プロパティ定義を返します
   *
   * @public
   * @property {string} source グループの取得に利用するURL
   */
  static get propTypes() {
    return {
      source: PropTypes.string,
    };
  }

  /**
   * デフォルトのプロパティ値を返します。
   * React内部から呼び出されます。
   *
   * @return {Object}
   * @property {string} source グループの取得に利用するURL
   */
  static get defaultProps() {
    return {
      source: Constants.URL.GROUPS_SOURCE,
    };
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);
    this.state = this.initialState();

    // 設定保存処理を最大で1秒間に1回しか呼ばないようにしたバージョンを用意する
    this.throttledSaveSettingsFunc = _.throttle(this.saveSettings, 1000);

    this.handleItemsPerPageChange = this.handleItemsPerPageChange.bind(this);
    this.handlePaginationPageClick = this.handlePaginationPageClick.bind(this);
    this.handleSearchInputFieldChange = this.handleSearchInputFieldChange.bind(this);
    this.handleSortLinkClick = this.handleSortLinkClick.bind(this);
  }

  /**
   * 初期ステートを作成して返します
   */
  initialState() {
    // デフォルト値(Cookieに設定が存在しない場合はこれらの値が使われる)
    let currentPage = 1;
    let perPage = 30;
    let searchInput = "";
    let sortColumn = "groups.name";
    let sortDirection = Constants.DIRECTION.ASC;

    // Cookieに設定がある場合はその値をロードする
    $.cookie.json = true;
    const settings = $.cookie(Constants.COOKIE.GROUP_SETTINGS);
    if ($.isPlainObject(settings)) {
      if (settings.current_page != null) {
        currentPage = Number(settings.current_page);
      }
      if (settings.search_input != null) {
        searchInput = settings.search_input;
      }
      if (settings.sort_column != null) {
        sortColumn = settings.sort_column;
      }
      if (settings.sort_direction != null) {
        sortDirection = settings.sort_direction;
      }
    }

    return {
      currentPage: currentPage,
      errorMessage: null,
      groups: [],
      perPage: perPage,
      searchInput: searchInput,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      totalItems: 0,
    };
  }

  /**
   * コンポーネントがマウントされた際の処理を行います。
   */
  componentDidMount() {
    this.loadAllGroups();
  }

  /**
   * @return {ReactElement}
   */
  render() {
    // 現在のページに表示するグループだけを取得する
    const currentPageGroups = this.getGroupsForCurrentPage();

    // キーワードによる絞り込みがされていない場合はページングナビゲーションを表示する
    let pagination = null;
    if (this.state.searchInput == "" && currentPageGroups.length > 0) {
      pagination = (
        <Pagination
          totalItems={this.state.totalItems}
          perPage={this.state.perPage}
          currentPage={this.state.currentPage}
          callback={this.handlePaginationPageClick}
        />
      );
    }

    // グループの件数
    // 絞り込みがされていない場合はグループ全体の件数
    // 絞り込みされている場合は絞り込んだ結果の件数にする
    let groupCount = this.state.searchInput == "" ? this.state.groups.length : currentPageGroups.length;

    // ローディング中(Ajax通信中)に表示するDashboardSpinnerに渡すpropsを定義する
    const spinOptions = {
      lines: 9,
      length: 3,
      width: 3,
      radius: 5,
      top: "15px",
      left: "220px",
    };

    return (
      <React.StrictMode>
        <div className="clearfix">
          <div className="ca-table-headroom marginB15">
            <div className="ca-table-headroom__primary">
              <SearchInputField onChange={this.handleSearchInputFieldChange} value={this.state.searchInput} />
              <span className="ca-search-box__metainfo">
                {I18n.t("group_count", {
                  number: groupCount,
                  scope: I18N_SCOPE,
                })}
              </span>
            </div>
            <div className="ca-table-headroom__secondary">
              <AddGroupButton />
            </div>
          </div>
          <DashboardSpinner spinOptions={spinOptions} />
          <GroupTable
            groups={currentPageGroups}
            errorMessage={this.state.errorMessage}
            sortColumn={this.state.sortColumn}
            sortDirection={this.state.sortDirection}
            callback={this.handleSortLinkClick}
          />
          {pagination}
        </div>
      </React.StrictMode>
    );
  }

  /**
   * 現在のソート方向を逆転したものを返します。
   * コンポーネントの状態は変更しません。
   * @return {string}
   */
  getInvertedSortDirection() {
    if (this.state.sortDirection == Constants.DIRECTION.ASC) {
      return Constants.DIRECTION.DESC;
    }
    return Constants.DIRECTION.ASC;
  }

  /**
   * 1ページあたりの表示件数が変更された際の処理を実行します。
   * @param {number} value 変更後の表示件数。
   */
  handleItemsPerPageChange(value) {
    this.setState(
      {
        perPage: value,
        currentPage: 1,
      },
      () => this.saveSettings()
    );
  }

  /**
   * ページングナビゲーションのページ番号がクリックされた際の処理を実行します。
   * @param {number} page ページ番号
   */
  handlePaginationPageClick(page) {
    this.setState({ currentPage: page }, () => this.saveSettings());
  }

  /**
   * 検索フィールドの値が変更された際の処理を実行します。
   * @param {string} newValue 新しい値
   */
  handleSearchInputFieldChange(newValue) {
    // state更新後に呼び出す関数が saveSettings ではないのは設定保存処理の発生頻度を抑えるため。
    // 代わりに、呼び出し頻度を最大で1秒間に1回に制限した設定保存関数を渡す。
    this.setState({ searchInput: newValue }, this.throttledSaveSettingsFunc);
  }

  /**
   * 特定のカラムでソートするリンクがクリックされた際の処理を実行します。
   * @param {string} column クリックされたカラムの識別子
   */
  handleSortLinkClick(column) {
    let newState = {
      currentPage: 1,
    };

    if (column == this.state.sortColumn) {
      newState.sortDirection = this.getInvertedSortDirection();
    } else {
      newState.sortColumn = column;
      newState.sortDirection = Constants.DIRECTION.ASC;
    }

    // ステートを更新した後でサーバーからジョブのJSONを取得する
    this.setState(newState, () => {
      this.loadAllGroups();
      this.saveSettings();
    });
  }

  /**
   * 現在のソート条件を使ってすべてのグループをサーバーから取得します。
   */
  loadAllGroups() {
    // ジョブのJSONを取得する際のソート条件を 'name asc' のような文字列として生成する
    const orderParam = `${this.state.sortColumn} ${this.state.sortDirection}`;

    // グループのJSONを取得するためのURL
    const jsonUrl = `${this.props.source}?order=${orderParam}`;

    $.ajax({
      type: "GET",
      url: jsonUrl,
    })
      .done((data) => {
        // レスポンスのJSONフォーマットをチェックする
        const requiredKeys = ["total", "groups"];
        let invalidFormat = false;
        requiredKeys.forEach((key) => {
          if (data[key] == undefined) {
            invalidFormat = true;
          }
        });

        if (invalidFormat) {
          this.setState({
            currentPage: 1,
            groups: [],
            errorMessage: I18n.t("invalid_server_response", { scope: I18N_SCOPE }),
            totalItems: 0,
          });
          return;
        }

        let newState = {
          errorMessage: null,
          groups: data.groups,
          totalItems: data.total,
        };

        // 現在ページが1以外の場合は、現在ページが超過しないかチェックする
        if (this.state.currentPage > 1) {
          // 取得したジョブの数が現在ページに表示する件数よりも少ない場合は
          // 現在ページを1にする(Cookiesに設定が保存されていた場合に対応するため)
          const idxOfCurrentGroups = (this.state.currentPage - 1) * this.state.perPage + 1;
          if (data.total < idxOfCurrentGroups) {
            newState.currentPage = 1;
          }
        }

        this.setState(newState);
      })
      .fail(() => {
        this.setState({
          currentPage: 1,
          groups: [],
          errorMessage: Constants.DATA_LOAD_ERROR_MESSAGE,
          totalItems: 0,
        });
      });
  }

  /**
   * サーバーから取得したグループを検索フィールドの入力値で絞り込んだうえで、
   * 現在のページに表示する範囲の後処理だけを返します。
   * @return {array}
   */
  getGroupsForCurrentPage() {
    let groups = this.state.groups;
    let pagination = true;

    // 検索フィールドに入力がある場合は名前で絞り込む
    if (this.state.searchInput != "") {
      // 検索フィールドの入力値を正規表現に変換する
      const escapedKeyword = this.state.searchInput.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
      const regexp = new RegExp(escapedKeyword, "i");
      // 名前が正規表現にマッチする後処理だけに絞り込む
      groups = groups.filter((group) => regexp.test(group.name));
      // 名前で絞り込んだ場合はページングを行わない
      pagination = false;
    }

    if (pagination) {
      // ジョブを現在のページに表示する範囲だけに絞り込む
      const start = this.state.perPage * (this.state.currentPage - 1);
      const end = this.state.perPage * this.state.currentPage;
      groups = groups.slice(start, end);
    }

    return groups;
  }

  /**
   * 現在の設定をCookieに保存します。
   */
  saveSettings() {
    // 選択されたページをCookieに保存する
    $.cookie(Constants.COOKIE.GROUP_SETTINGS, {
      current_page: this.state.currentPage,
      per_page: this.state.perPage,
      search_input: this.state.searchInput,
      sort_column: this.state.sortColumn,
      sort_direction: this.state.sortDirection,
    });
  }
}

/**
 * グループテーブルコンポーネント
 *
 * プロパティ
 * errorMessage - エラーメッセージまたは null
 * groups - ジョブの配列
 *
 * このコンポーネントの描画結果はTABLE要素となります。
 */
class GroupTable extends React.Component {
  /**
   * propTypes
   * @property {}
   */
  static get propTypes() {
    return {
      errorMessage: PropTypes.any,
      groups: PropTypes.array.isRequired,
      sortColumn: PropTypes.string.isRequired,
      sortDirection: PropTypes.string.isRequired,
      callback: PropTypes.func.isRequired,
    };
  }

  constructor(props) {
    super(props);

    this.nameText = I18n.t("activerecord.attributes.group.name");
    this.groupIDText = I18n.t("group_id", { scope: I18N_SCOPE });
    this.membersCountText = I18n.t("members_count", { scope: I18N_SCOPE });
    this.jobsCountText = I18n.t("jobs_count", { scope: I18N_SCOPE });
    this.policiesCountText = I18n.t("policy_sets_count", { scope: I18N_SCOPE });
    this.postProcessesCountText = I18n.t("post_processes_count", { scope: I18N_SCOPE });
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const sortByName = this.props.sortColumn == "groups.name";
    const sortByID = this.props.sortColumn == "groups.id";
    const sortByMembersCount = this.props.sortColumn == "user_assignments_count";
    const sortByJobsCount = this.props.sortColumn == "trigger_jobs_count";
    const sortByPoliciesCount = this.props.sortColumn == "policy_sets_count";
    const sortByPostProcessesCount = this.props.sortColumn == "post_processes_count";

    // THEADタグ内のTHを増減する場合は TABLE_COLUMNS 定数も更新すること
    return (
      <table className="table vertical-middle ca-group-table ca-table-header-no-bordered">
        <thead>
          <tr>
            <SortableTableHeaderCell
              column="groups.id"
              active={sortByID}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.groupIDText}
              classNameForHeaderCell="
                ca-group-table-header--clickable
                text-center
              "
            />
            <SortableTableHeaderCell
              column="groups.name"
              active={sortByName}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.nameText}
              classNameForHeaderCell="
                ca-group-table-header--clickable
                ca-group-table-header__name
                text-left
              "
            />
            <SortableTableHeaderCell
              column="user_assignments_count"
              active={sortByMembersCount}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.membersCountText}
              textAlignCenter={true}
              classNameForHeaderCell="
                ca-group-table-header--clickable
                ca-group-table-header__count
                text-center
              "
            />
            <SortableTableHeaderCell
              column="trigger_jobs_count"
              active={sortByJobsCount}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.jobsCountText}
              textAlignCenter={true}
              classNameForHeaderCell="
                ca-group-table-header--clickable
                ca-group-table-header__count
                text-center
              "
            />
            <SortableTableHeaderCell
              column="policy_sets_count"
              active={sortByPoliciesCount}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.policiesCountText}
              textAlignCenter={true}
              classNameForHeaderCell="
                ca-group-table-header--clickable
                ca-group-table-header__count
                text-center
              "
            />
            <SortableTableHeaderCell
              column="post_processes_count"
              active={sortByPostProcessesCount}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.postProcessesCountText}
              textAlignCenter={true}
              classNameForHeaderCell="
                ca-group-table-header--clickable
                ca-group-table-header__count
                text-center
              "
            />
            <th className="ca-group-table-header ca-group-table-header__buttons" />
          </tr>
        </thead>
        <tbody>{this.getGroupRows()}</tbody>
      </table>
    );
  }

  /**
   * テーブルに表示するグループのグループテーブル行コンポーネントを配列で返します。
   * @return {array}
   */
  getGroupRows() {
    const isGroupsExist = this.props.errorMessage == null && this.props.groups.length > 0;

    if (isGroupsExist) {
      return this.props.groups.map((group) => {
        return <GroupRow key={group.id} group={group} />;
      });
    }

    const message =
      this.props.errorMessage != null ? this.props.errorMessage : I18n.t("no_groups", { scope: I18N_SCOPE });
    return (
      <tr key="0">
        <td colSpan={TABLE_COLUMNS}>{message}</td>
      </tr>
    );
  }
}

/**
 * グループテーブル行コンポーネント
 *
 * プロパティ
 * group - 1つのグループのデータを持つオブジェクト。
 *         サーバーから取得したグループのJSONデータの groups キーに対応する配列の1要素が設定される。
 *
 * ステート
 * group - 1つのグループのデータを持つオブジェクト。
 *         初期値としてgroupプロパティの値が使われる。
 *         状態の変更が行われた際にはサーバーからのレスポンスに含まれる最新の状態で更新される。
 */
class GroupRow extends React.Component {
  /**
   * propTypes
   * @property {}
   */
  static get propTypes() {
    return {
      group: PropTypes.object.isRequired,
    };
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);
    this.state = { group: this.props.group };
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const group = this.state.group;

    return (
      <tr>
        <td className="text-center ca-group-table-body__id">{group.id}</td>
        <td className="text-left">
          <span className="fa fa-briefcase font-18px ca-group-table__group-icon" style={{ color: group.color }} />
          <a href={group.edit_url}>{group.name}</a>
        </td>
        <td className="text-center">{group.user_assignments_count}</td>
        <td className="text-center">{group.trigger_jobs_count}</td>
        <td className="text-center">{group.policy_sets_count}</td>
        <td className="text-center">{group.post_processes_count}</td>
        <td className="text-right">
          <div className="dropdown">
            <div
              className="dropdown-toggle ca-job-control-button__toggle"
              data-toggle="dropdown"
              id="dropdownMenu1"
              aria-haspopup="true"
              aria-expanded="true"
              aria-hidden="true"
            >
              <i className="far fa-chevron-down ca-job-control-button__toggle-icon" aria-hidden="true"></i>
            </div>
            <ul
              className="dropdown-menu dropdown-menu-right pull-right text-left ca-job-control-button__dropdown"
              aria-labelledby="dropdownMenu1"
            >
              <li>
                <a href={group.edit_url}>
                  <i className="fas fa-id-card" aria-hidden="true"></i>
                  {I18n.t("edit_info", { scope: I18N_SCOPE })}
                </a>
              </li>
              <li>
                <a href={group.aws_accounts_url}>
                  <i className="fas fa-key-skeleton" aria-hidden="true"></i>
                  {I18n.t("edit_aws_accounts", { scope: I18N_SCOPE })}
                </a>
              </li>
              <li>
                <a href={group.google_cloud_accounts_url}>
                  <i className="fas fa-key-skeleton" aria-hidden="true"></i>
                  {I18n.t("edit_google_cloud_accounts", { scope: I18N_SCOPE })}
                </a>
              </li>
              <li>
                <a href={group.member_url}>
                  <i className="fas fa-user-friends" aria-hidden="true"></i>
                  {I18n.t("edit_member", { scope: I18N_SCOPE })}
                </a>
              </li>
              <li>
                <DeleteGroupLink groupId={group.id} groupName={group.name} disabled={!group.deletable} />
              </li>
            </ul>
          </div>
        </td>
      </tr>
    );
  }
}

/**
 * 検索フィールドコンポーネント
 */
class SearchInputField extends React.Component {
  /**
   * propTypes
   * @property {}
   */
  static get propTypes() {
    return {
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired,
    };
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);
    this.placeholder = I18n.t("search_placeholder", { scope: I18N_SCOPE });
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return (
      <div className="ca-search-box inline-block">
        <i className="ca-search-box__icon fa fa-search" />
        <input
          className="ca-search-box__input"
          onChange={this.handleOnChange}
          placeholder={this.placeholder}
          type="search"
          value={this.props.value}
        />
      </div>
    );
  }

  /**
   * 入力欄の値が変わった際に呼び出されるイベントハンドラ。
   * @param {SyntheticEvent} event
   */
  handleOnChange(event) {
    this.props.onChange($(event.target).val());
  }
}

/**
 * グループの追加ボタン
 * aタグ要素を返します
 *
 * @return {ReactElement}
 */
class AddGroupButton extends React.Component {
  render() {
    const url = Constants.URL.NEW_GROUP_WITH_IAM_ROLE;
    const addButtonTitle = I18n.t("add_group", { scope: I18N_SCOPE });

    return (
      <a href={url} className="btn btn-highlight">
        <span className="fa fa-plus-square fa-super" />
        <span className="font-12px">{addButtonTitle}</span>
      </a>
    );
  }
}
