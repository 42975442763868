import React from 'react';

const I18n = window.I18n; // i18n-js

/**
 * お問い合わせくださいリンク
 * クリックするとUserVoiceのウィジェットをcontactモードで開きます。
 *
 * コンポーネントのマウント時およびアンマウント時に、window.UserVoice でアクセスできる
 * UserVoiceウィジェットを利用して処理を行っています。同ウィジェットの利用方法については
 * UserVoiceのドキュメントを参照してください。
 * https://developer.uservoice.com/docs/widgets/options/
 */
export default class SupportLink extends React.Component {
  /**
   * @override
   */
  constructor(props) {
    super(props);

    this.linkText = I18n.t('javascript.support_link.text');
    this.linkStyle = {
      display: 'block',
      textDecoration: 'underline',
    };
  }

  /**
   * @override
   */
  componentDidMount() {
    // UserVoiceウィジェットを問い合わせモードで開く
    window.UserVoice.push([
      'addTrigger',
      this.element,
      {
        mode: 'contact',
        target: false,
      },
    ]);
  }

  /**
   * @override
   */
  componentWillUnmount() {
    // UserVoiceウィジェットを閉じる
    window.UserVoice.push(['removeTrigger', this.element]);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <a
        href="#"
        ref={element => this.element = element}
        style={this.linkStyle}
      >
        {this.linkText}
      </a>
    );
  }
}
