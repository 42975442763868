import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';

const I18n = window.I18n; // i18n-js
const Underscore = window._; // Underscore.js

/**
 * ユーザーテーブル絞り込みボックス
 *
 * ユーザー一覧をキーワードで絞り込むためのUIコンポーネントで、以下の責務を持ちます。
 *
 * - 絞り込み用UIの表示
 * - 絞り込み用文字列が変更された場合にEventEmitterに
 *   イベント(EVENT_CHANGE_SEARCH_INPUT)を通知する
 */
export default class UserTableSearchBox extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {Object} emitter EventEmitterオブジェクト
   * @property {number} total 全件数として表示する数値(0の場合は表示を行わない)
   */
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
      total: PropTypes.number.isRequired,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.state = { value: '' };

    this.handleChange = this.handleChange.bind(this);
    this.emitChangeEvent = this.emitChangeEvent.bind(this);

    // 入力値の変更を上位のコンポーネントに伝える頻度を最小でも200ms毎に制限する
    this.emitChangeEvent = Underscore.throttle(this.emitChangeEvent, 200);

    this.i18n_text = {
      search_placeholder: I18n.t('search_placeholder', { scope: Constants.I18N_SCOPE }),
    };
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <div className="ca-search-box">
        <i className="ca-search-box__icon fa fa-search"></i>
        <input
          className="ca-search-box__input"
          onChange={this.handleChange}
          placeholder={this.i18n_text.search_placeholder}
          type="search"
          value={this.state.value}
        />
        <span className="ca-search-box__metainfo">
          {this.props.total > 0 ? `( ${this.getSearchTotalText()} )` : null}
        </span>
      </div>
    );
  }

  /**
   * 絞り込み用文字列の変更をEventEmitterに通知します。
   *
   * @private
   */
  emitChangeEvent() {
    this.props.emitter.emit(
      Constants.EVENT_CHANGE_SEARCH_INPUT,
      this.state.value
    );
  }

  /**
   * 該当する全件数を国際化した表記で返します。
   *
   * @private
   * @return {string}
   */
  getSearchTotalText() {
    return I18n.t(
      'search_total',
      { scope: Constants.I18N_SCOPE, total: this.props.total }
    );
  }

  /**
   * 絞り込み用文字列が変更された場合の処理を行います。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleChange(event) {
    this.setState({ value: event.target.value }, this.emitChangeEvent);
  }
}
