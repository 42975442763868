import PropTypes from 'prop-types';
import React from 'react';

import ManualLink from '../ManualLink.jsx';

import GroupSelector from './GroupSelector.jsx';
import RequestForm from './RequestForm.jsx';
import RequestTable from './RequestTable.jsx';
import SubmittedRequestForm from './SubmittedRequestForm.jsx';

const I18n = window.I18n; // i18n-js

/**
 * WorkSpacesリスト一覧
 */
export default class WorkSpacesLists extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   */
  static get propTypes() {
    return({
      currentAwsAccountId: PropTypes.string.isRequired,
      currentGroupId: PropTypes.number.isRequired,
      currentRegion: PropTypes.string.isRequired,
      emitter: PropTypes.object.isRequired,
      errorMessage: PropTypes.string.isRequired,
      generateRequests: PropTypes.arrayOf(PropTypes.object).isRequired,
      groups: PropTypes.object.isRequired,
      isPending: PropTypes.bool.isRequired,
      isSubmitted: PropTypes.bool.isRequired,
      regions: PropTypes.arrayOf(PropTypes.object).isRequired,
      submitErrorMessage: PropTypes.string.isRequired,
      writableGroupIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.manualLinkText = I18n.t("common.manual.text.workspaces_list_generate_requests.overview");
    this.manualUrl = I18n.t("common.manual.url.workspaces_list_generate_requests.overview");
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    const currentGroup = this.props.groups[this.props.currentGroupId];
    const currentGroupIsWritable = this.props.writableGroupIds.indexOf(currentGroup.id) > -1;
    const groups = Object.values(this.props.groups);

    return(
      <div className="ca-workspaces-lists">
        <div className="marginB10">
          <ManualLink
            linkText={this.manualLinkText}
            url={this.manualUrl}
          />
        </div>
        <GroupSelector
          currentGroupId={this.props.currentGroupId}
          emitter={this.props.emitter}
          groups={groups}
        />
        {!this.props.isSubmitted &&
          <RequestForm
            awsAccounts={currentGroup.aws_accounts}
            currentAwsAccountId={this.props.currentAwsAccountId}
            currentRegion={this.props.currentRegion}
            disabled={!currentGroupIsWritable}
            emitter={this.props.emitter}
            isSubmitted={this.props.isSubmitted}
            regions={this.props.regions}
            submitErrorMessage={this.props.submitErrorMessage}
          />
        }
        {this.props.isSubmitted &&
          <SubmittedRequestForm errorMessage={this.props.submitErrorMessage} />
        }
        <RequestTable
          emitter={this.props.emitter}
          errorMessage={this.props.errorMessage}
          generateRequests={this.props.generateRequests}
          isPending={this.props.isPending}
          isReadOnly={!currentGroupIsWritable}
        />
      </div>
    );
  }
}
