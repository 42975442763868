import React from 'react';
import { CSSTransition } from "react-transition-group";
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import EmailRecipientField from './EmailRecipientField.jsx';
import GroupSelectionField from './GroupSelectionField.jsx';
import PostProcessNameField from './PostProcessNameField.jsx';
import ServiceSelectionField from './ServiceSelectionField.jsx';
import SlackChannelNameField from "./SlackChannelNameField.jsx";
import SlackLanguageField from "./SlackLanguageField.jsx";
import SlackTimeZoneField from "./SlackTimeZoneField.jsx";
import SqsAwsAccountIdField from './SqsAwsAccountIdField.jsx';
import SqsQueueSelectField from './SqsQueueSelectField.jsx';
import SqsRegionField from './SqsRegionField.jsx';
import SubmitButton from './SubmitButton.jsx';
import WebhookHeaderField from './WebhookHeaderField.jsx';
import WebhookUrlField from './WebhookUrlField.jsx';
import ManualLink from '../ManualLink.jsx';

const I18n = window.I18n; // i18n-js

// 後処理フォーム
// 後処理フォームの表示に関する責務を持ちます。
class PostProcessForm extends React.Component {
  static get defaultProps() {
    return({
      classesForPostProcessNameField: null,
      heading: null,
    });
  }

  static get propTypes() {
    return({
      awsAccountPending: PropTypes.bool.isRequired,
      awsAccounts: PropTypes.arrayOf(PropTypes.array).isRequired,
      classesForPostProcessNameField: PropTypes.string,
      commonErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
      emailRecipient: PropTypes.string.isRequired,
      emailRecipientErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
      emitter: PropTypes.object.isRequired,
      groupId: PropTypes.string.isRequired,
      group: PropTypes.object,
      groupErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
      groupIds: PropTypes.arrayOf(PropTypes.number),
      groups: PropTypes.arrayOf(PropTypes.array),
      heading: PropTypes.string,
      isSlackIntegrated: PropTypes.bool.isRequired,
      loadingImagePath: PropTypes.string,
      newRecord: PropTypes.bool.isRequired,
      pending: PropTypes.bool.isRequired,
      postProcessName: PropTypes.string.isRequired,
      postProcessNameErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
      regions: PropTypes.arrayOf(PropTypes.array).isRequired,
      service: PropTypes.string.isRequired,
      services: PropTypes.arrayOf(PropTypes.array).isRequired,
      slackChannelName: PropTypes.string.isRequired,
      slackChannelNameErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
      slackLanguage: PropTypes.string.isRequired,
      slackLanguageErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
      slackTimeZone: PropTypes.string.isRequired,
      slackTimeZoneErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
      sqsAwsAccountId: PropTypes.string.isRequired,
      sqsAwsAccountIdErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
      sqsQueue: PropTypes.string.isRequired,
      sqsQueueErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
      sqsQueuesPath: PropTypes.string.isRequired,
      sqsRegion: PropTypes.string.isRequired,
      sqsRegionErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
      timeZones: PropTypes.arrayOf(PropTypes.array).isRequired,
      webhookAuthorizationHeader: PropTypes.string.isRequired,
      webhookAuthorizationHeaderErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
      webhookUrl: PropTypes.string.isRequired,
      webhookUrlErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
    });
  }

  constructor(props) {
    super(props);

    // 子コンポーネントの表示状態が切り替わる際に適用するCSSトランジションのオプション
    this.cssTransitionOptions = {
      classNames: "post-process-fields",
      appear: true,
      unmountOnExit: true,
      timeout: {
        appear: 500,
        enter: 300,
      }
    }

    this.i18n = {
      email: {
        title: I18n.t('panel_title.email', { scope: Constants.I18N_SCOPE })
      },
      slack: {
        title: I18n.t("panel_title.slack", { scope: Constants.I18N_SCOPE }),
        manualText: I18n.t("slack", { scope: "common.manual.text.post_processes" }),
        manualUrl: I18n.t("slack", { scope: "common.manual.url.post_processes" }),
        submitLabel: I18n.t(
          this.props.newRecord ? "submit_button.label_for_create" : "submit_button.label_for_update",
          { scope: Constants.I18N_SCOPE }
        ),
        pendingLabel: I18n.t("submit_button.label_for_pending", { scope: Constants.I18N_SCOPE })
      },
      sqs: {
        title: I18n.t('panel_title.sqs', { scope: Constants.I18N_SCOPE }),
        manual_text: I18n.t('sqs', { scope: 'common.manual.text.post_processes' }),
        manual_url: I18n.t('sqs', { scope: 'common.manual.url.post_processes' })
      },
      webhook: {
        title: I18n.t('panel_title.webhook', { scope: Constants.I18N_SCOPE }),
        manual_text: I18n.t('webhook', { scope: 'common.manual.text.post_processes' }),
        manual_url: I18n.t('webhook', { scope: 'common.manual.url.post_processes' })
      }
    }

  }

  render() {
    return(
      <div>
        {this.renderHeading()}
        <PostProcessNameField
          classesForColumn={this.props.classesForPostProcessNameField}
          emitter={this.props.emitter}
          errors={this.props.postProcessNameErrors}
          value={this.props.postProcessName}
        />
        <GroupSelectionField
          emitter={this.props.emitter}
          errors={this.props.groupErrors}
          group={this.props.group}
          groupIds={this.props.groupIds}
          groups={this.props.groups}
          value={this.props.groupId}
        />
        <ServiceSelectionField
          emitter={this.props.emitter}
          errors={this.props.commonErrors}
          services={this.props.services}
          value={this.props.service}
        />
        {this.renderFormForEmail()}
        {this.renderFormForSlack()}
        {this.renderFormForSqs()}
        {this.renderFormForWebhook()}
      </div>
    );
  }

  renderHeading() {
    if (this.props.heading === null) {
      return;
    }

    return(
      <div className="post-process-form-title">
        <h2>
          <span className="fa fa-cog fa-lg marginR5"></span>
          {this.props.heading}
        </h2>
      </div>
    );
  }

  /**
   * サービスとしてEメールが選択された場合の入力フォームを返します。
   */
  renderFormForEmail() {
    const isEmailSelected = this.props.service == Constants.SERVICE_EMAIL

    return(
      <CSSTransition
        {...this.cssTransitionOptions}
        in={isEmailSelected}
      >
        <div>
          <hr />
          <div className="post-process-setting__header">
            <h2 className="post-process-setting__header__title">
              <span className="fa fa-cog fa-lg post-process-setting__header__icon"></span>
              {this.i18n.email.title}
            </h2>
          </div>
          <EmailRecipientField
            emitter={this.props.emitter}
            errors={this.props.emailRecipientErrors}
            value={this.props.emailRecipient}
          />
          <hr />
          <div className="post-process-setting">
            <SubmitButton
              emitter={this.props.emitter}
              emitterEvent={Constants.EVENT_SUBMIT_FORM}
              label={
                I18n.t(
                  this.props.newRecord ? 'submit_button.label_for_create' : 'submit_button.label_for_update',
                  { scope: Constants.I18N_SCOPE }
                )
              }
              pending={this.props.pending}
              pendingLabel={I18n.t('submit_button.label_for_pending', { scope: Constants.I18N_SCOPE })}
            />
          </div>
        </div>
      </CSSTransition>
    );
  }

  /**
   * サービスとしてSlackが選択された場合の入力フォームを返します。
   */
  renderFormForSlack() {
    const isSlackSelected = this.props.service == Constants.SERVICE_SLACK

    return (
      <CSSTransition
        {...this.cssTransitionOptions}
        in={isSlackSelected}
      >
        <div>
          <hr />
          <div className="post-process-setting__header">
            <h2 className="post-process-setting__header__title">
              <span className="fa fa-cog fa-lg post-process-setting__header__icon"></span>
              {this.i18n.slack.title}
            </h2>
            <ManualLink linkText={this.i18n.slack.manualText} url={this.i18n.slack.manualUrl} />
          </div>
          <SlackLanguageField
            emitter={this.props.emitter}
            errors={this.props.slackLanguageErrors}
            value={this.props.slackLanguage}
          />
          <SlackTimeZoneField
            emitter={this.props.emitter}
            errors={this.props.slackTimeZoneErrors}
            timeZones={this.props.timeZones}
            value={this.props.slackTimeZone}
          />
          <SlackChannelNameField
            emitter={this.props.emitter}
            errors={this.props.slackChannelNameErrors}
            value={this.props.slackChannelName}
          />
          <hr />
          <div className="post-process-setting">
            <SubmitButton
              disabled={
                !this.props.isSlackIntegrated ||
                this.props.slackChannelName == "" ||
                this.props.slackLanguage == "" ||
                this.props.slackTimeZone == ""
              }
              emitter={this.props.emitter}
              emitterEvent={Constants.EVENT_SUBMIT_FORM}
              label={this.i18n.slack.submitLabel}
              pending={this.props.pending}
              pendingLabel={this.i18n.slack.pendingLabel}
            />
          </div>
        </div>
      </CSSTransition>
    );
  }

  /**
   * サービスとしてSQSが選択された場合の入力フォームを返します。
   */
  renderFormForSqs() {
    const isSQSSelected = this.props.service == Constants.SERVICE_SQS

    return(
      <CSSTransition
        {...this.cssTransitionOptions}
        in={isSQSSelected}
      >
        <div>
          <hr />
          <div className="post-process-setting__header">
            <h2 className="post-process-setting__header__title">
              <span className="fa fa-cog fa-lg post-process-setting__header__icon"></span>
              {this.i18n.sqs.title}
            </h2>
            <ManualLink
              linkText={this.i18n.sqs.manual_text}
              url={this.i18n.sqs.manual_url}
            />
          </div>
          <SqsAwsAccountIdField
            awsAccounts={this.props.awsAccounts}
            emitter={this.props.emitter}
            errors={this.props.sqsAwsAccountIdErrors}
            loadingImagePath={this.props.loadingImagePath}
            pending={this.props.awsAccountPending}
            value={this.props.sqsAwsAccountId}
          />
          <SqsRegionField
            emitter={this.props.emitter}
            errors={this.props.sqsRegionErrors}
            regions={this.props.regions}
            value={this.props.sqsRegion}
          />
          <SqsQueueSelectField
            awsAccountId={this.props.sqsAwsAccountId}
            emitter={this.props.emitter}
            errors={this.props.sqsQueueErrors}
            preselectedValue={this.props.sqsQueue}
            region={this.props.sqsRegion}
            sqsQueuesPath={this.props.sqsQueuesPath}
          />
          <hr />
          <div className="post-process-setting">
            <SubmitButton
              emitter={this.props.emitter}
              emitterEvent={Constants.EVENT_SUBMIT_FORM}
              label={
                I18n.t(
                  this.props.newRecord ? 'submit_button.label_for_create' : 'submit_button.label_for_update',
                  { scope: Constants.I18N_SCOPE }
                )
              }
              pending={this.props.pending}
              pendingLabel={I18n.t('submit_button.label_for_pending', { scope: Constants.I18N_SCOPE })}
            />
          </div>
        </div>
      </CSSTransition>
    );
  }

  /**
   * サービスとしてWebhookが選択された場合の入力フォームを返します。
   */
  renderFormForWebhook() {
    const isWebhookSelected = this.props.service == Constants.SERVICE_WEBHOOK

    return(
      <CSSTransition
        {...this.cssTransitionOptions}
        in={isWebhookSelected}
      >
        <div>
          <hr />
          <div className="post-process-setting__header">
            <h2 className="post-process-setting__header__title">
              <span className="fa fa-cog fa-lg post-process-setting__header__icon"></span>
              {this.i18n.webhook.title}
            </h2>
            <ManualLink
              linkText={this.i18n.webhook.manual_text}
              url={this.i18n.webhook.manual_url}
            />
          </div>
          <WebhookUrlField
            emitter={this.props.emitter}
            errors={this.props.webhookUrlErrors}
            value={this.props.webhookUrl}
          />
          <div className="margin30" />
          <WebhookHeaderField
            emitter={this.props.emitter}
            errors={this.props.webhookAuthorizationHeaderErrors}
            attribute="webhook_authorization_header"
            value={this.props.webhookAuthorizationHeader}
          />
          <hr />
          <div className="post-process-setting">
            <SubmitButton
              emitter={this.props.emitter}
              emitterEvent={Constants.EVENT_SUBMIT_FORM}
              label={
                I18n.t(
                  this.props.newRecord ? 'submit_button.label_for_create' : 'submit_button.label_for_update',
                  { scope: Constants.I18N_SCOPE }
                )
              }
              pending={this.props.pending}
              pendingLabel={I18n.t('submit_button.label_for_pending', { scope: Constants.I18N_SCOPE })}
            />
          </div>
        </div>
      </CSSTransition>
    );
  }
}

export default PostProcessForm;
