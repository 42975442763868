import React from 'react';
import PropTypes from 'prop-types';

// 送信ボタン
//
// pending プロパティが true の場合は送信中の表示になります。
//
// ボタンがクリックされると emitter_event プロパティの値がイベントとして EventEmitter
// に送信されます。
//
class SubmitButton extends React.Component {
  static get defaultProps() {
    return({
      disabled: false,
      pending: false
    });
  }

  static get propTypes() {
    return({
      disabled: PropTypes.bool,
      emitter: PropTypes.object.isRequired,
      emitterEvent: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      pending: PropTypes.bool,
      pendingLabel: PropTypes.string.isRequired
    });
  }

  render() {
    if (this.props.pending) {
      return this.renderPendingButton();
    }
    return this.renderButton();
  }

  /**
   * 通常表示のボタンを返します。
   */
  renderButton() {
    const buttonClass = this.props.disabled ? 'btn-default' : 'btn-warning';

    return(
      <button
        className={`btn ${buttonClass} btn-lg btn-continue`}
        disabled={this.props.disabled}
        onClick={() => this.props.emitter.emit(this.props.emitterEvent)}
        type="button"
      >
        <i className="fa fa-lg fa-check-circle"></i>
        {this.props.label}
      </button>
    );
  }

  /**
   * 送信中表示のボタンを返します。
   */
  renderPendingButton() {
    return(
      <button
        className={`btn btn-warning btn-lg btn-continue`}
        disabled={true}
        type="button"
      >
        <i className="fa fa-lg fa-check-circle"></i>
        {this.props.pendingLabel}
      </button>
    );
  }
}

export default SubmitButton;
