import PropTypes from "prop-types";
import React from "react";

import Constants from "./Constants.js";

const I18n = window.I18n; // i18n-js

/**
 * EBSバックアップ監視結果テーブルの最終チェック日時UI
 *
 * 見た目の制御を行います。
 * 最終チェック日時が渡されていない場合はハイフンを表示します。
 */

export default class EbsBackupCheckResultsTableHead extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {string} checkedAt 最終チェック日時
   */
  static get propTypes() {
    return({
      checkedAt: PropTypes.string,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    const options = { scope: `${Constants.I18N_SCOPE}` };
    this.lastCheckDateText = I18n.t("last_check_date", options);
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    const checkedAt = this.props.checkedAt ? this.props.checkedAt : "---";

    return(
      <div className="ca-record-header">
        <div className="ca-backup-check-results">
          <span className="ca-backup-check-results__text marginR15">{this.lastCheckDateText}</span>
          <span className="ca-backup-check-results__datetime">{checkedAt}</span>
        </div>
      </div>
    );
  }
}
