export default {
  // 評価結果による絞り込みを行う際のセレクトボックスの値
  COMPLIANCE_TYPE: {
    ALL: '',
    COMPLIANT: 'compliant',
    NON_COMPLIANT: 'non_compliant',
  },

  EVENT_CHANGE_COMPLIANCE_TYPE: 'changeComplianceType',

  I18N_SCOPE: 'javascript.policy_log_table',
};
