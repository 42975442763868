import React, { Component } from 'react'
import PropTypes from 'prop-types';

const I18n = window.I18n; // i18n-js

const MAX_TAGS_LENGTH = 10;

class TriggerJobFormAdditionalTagsForm extends Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {Object[]} additionalTags 作成イメージに追加するタグ
   */
  static get propTypes() {
    return({
      additionalTags: PropTypes.arrayOf(PropTypes.object).isRequired,
    });
  }

  constructor(props) {
    super(props);

    const additionalTags = (() => {
      if (props.additionalTags.length == 0) { // 初期状態で入力欄を2つ表示するため、データを作成しておく
        return [{ key: "", value: "" }, { key: "", value: "" }];
      } else {
        return props.additionalTags;
      }
    })();

    this.state = {
      additionalTags: additionalTags,
    };
  }

  componentDidMount() {
    // ジョブ編集画面のDiff機能はDOMContentLoaded後に実行されるため
    // Reactを利用している場合は、初期データの再取得を要求するカスタムイベントをトリガーする
    const $ = window.jQuery;
    if ($(".edit_trigger_job#make-form").length > 0) {
      $(".edit_trigger_job#make-form").trigger("kanrinmaru:diff_update_initial_data_requested");
    }
  }

  /**
   * 複数タグのinputタグでエンターキーの操作を受け付けないようにします
   *
   * @private
   * @param {KeyboardEvent} e キーボードイベント
   */
  ignoreEnterKeyPress(e) {
    // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
    if (e.key === "Enter") {
      e.preventDefault();
    }
  }

  /**
   * 複数タグのキーの入力をstateに反映します
   *
   * @private
   * @param {KeyboardEvent} e キーボードイベント
   * @param {Number} index 更新対象のインデックス
   */
  handleChangeKey(e, index) {
    const tags = [...this.state.additionalTags];
    tags[index].key = e.target.value;

    this.setState({ additionalTags: tags });
  }

  /**
   * 複数タグのバリューの入力をstateに反映します
   *
   * @private
   * @param {KeyboardEvent} e キーボードイベント
   * @param {Number} index 更新対象のインデックス
   */
  handleChangeValue(e, index) {
    const tags = [...this.state.additionalTags];
    tags[index].value = e.target.value;

    this.setState({
      additionalTags: tags,
    });
  }

  /**
   * 複数タグの入力フォームを削除を行います
   *
   * @private
   * @param {MouseEvent} e クリックイベント
   * @param {Number} index 削除対象のインデックス
   */
  handleDelete(e, index) {
    e.preventDefault();

    const tags = [...this.state.additionalTags];
    tags.splice(index, 1)
    this.setState({
      additionalTags: tags,
    }, () => {
      // 入力フォームが削除されたことを編集フォームにカスタムイベントで通知し、
      // 差分表示が更新されるようにする
      window.jQuery("#make-form[data-edit]").trigger("deleteAdditionalTag");
    });
  }

  /**
   * 複数タグの入力フォームの追加メソッド
   * 入力フォームの数が MAX_TAGS_LENGTH 以下であれば追加、それ以外は追加しません
   *
   * @private
   * @param {MouseEvent} e クリックイベント
   */
  addInputForm(e) {
    e.preventDefault();

    this.setState((prevState) => ({
      additionalTags: ((prevState) => {
        if (prevState.additionalTags.length < MAX_TAGS_LENGTH) {
          return [...prevState.additionalTags, { key: "", value: "" }];
        } else {
          return prevState.additionalTags;
        }
      })(prevState)
    }));
  }

  render() {
    return(
      <div className={`form-row additional-tags marginT15 js-AdditionalTagsForm`}>
        <label className="additional-tags__label">key</label>
        <label className="additional-tags__label">value</label>
        {this.state.additionalTags.map((tag, idx) => {
          return (
            <div className="form-row marginB10" key={`form-row-${idx}`}>
              <input
                key={`tag-key-${idx}`}
                className="form-control inline-block input-sm additional-tags__input marginR15"
                name={`trigger_job[additional_tags][][key]`}
                type="text"
                value={tag.key}
                onChange={e => this.handleChangeKey(e, idx)}
                onKeyPress={e => this.ignoreEnterKeyPress(e)}
              />

              <input
                key={`tag-value-${idx}`}
                className="form-control inline-block input-sm additional-tags__input marginR15"
                name={`trigger_job[additional_tags][][value]`}
                type="text"
                value={tag.value}
                onChange={e => this.handleChangeValue(e, idx)}
                onKeyPress={e => this.ignoreEnterKeyPress(e)}
              />

              {(idx !== 0) && ( // 2つ目以降の入力欄でのみ削除ボタンを表示
                <span className="additional-tags__remove_button" onClick={(e) => this.handleDelete(e, idx)}>
                  <span className="fa fa-times"/>
                </span>
              )}
            </div>
          );
        })}
        {(this.state.additionalTags.length < MAX_TAGS_LENGTH) && (
          <div
            className="btn btn-default additional-tags__add_button marginT5"
            onClick={e => this.addInputForm(e)}>
            <span className="fa fa-plus-square marginR5"/>
            {I18n.t('add', { scope: "javascript.trigger_job_form.create_image.additional_tags" })}
          </div>
        )}
      </div>
    );
  }
}

export default TriggerJobFormAdditionalTagsForm;
