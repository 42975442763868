import React from 'react';
import PropTypes from 'prop-types';

/**
 * エラーメッセージ表示用のUIコンポーネントで、以下の責務を持ちます。
 *
 * - 文字列の配列をもとにエラーメッセージを表示
 */
export default class ErrorMessages extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {Object[]} messages 表示するエラーメッセージの配列
   */
  static get propTypes() {
    return({
      messages: PropTypes.arrayOf(PropTypes.string).isRequired
    });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    if (this.props.messages.length === 0) {
      return null;
    }

    let errorMessages = null;
    errorMessages = this.props.messages.map((message) => {
      return(<li className="ca-trigger-job-form__error" key={message}>{message}</li>);
    });

    return(
      <ul className="ca-trigger-job-form__error-area">
        {errorMessages}
      </ul>
    );
  }
}
