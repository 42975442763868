import React from "react";
import { TriggerJob } from "./types";

export const TriggerCircle: React.FC<{ job: TriggerJob | undefined }> = ({ job }) => {
  const baseClasses = "ca-job-workflow__step-circle ca-job-workflow__step-circle--workflow-trigger";
  const inactiveClass = job ? "" : "ca-job-workflow__step-circle--inactive";
  const classes = `${baseClasses} ${inactiveClass}`;
  const triggerIcon = "far fa-duotone fa-solid fa-bullseye-pointer";

  return (
    <span className={classes}>
      <i className={triggerIcon} aria-hidden="true"></i>
    </span>
  );
};
