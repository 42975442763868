// プロフィール画像アップローダー関連コンポーネントで利用する定数

export default {
  // EventEmitterで扱うイベントの識別子
  EVENT_RESET_IMAGE: 'resetImage',
  EVENT_SELECT_IMAGE: 'selectImage',
  EVENT_UPDATE_PREVIEW_IMAGE: 'updatePreviewImage',

  // ファイルアップロード用INPUT要素のコンテナとなる要素のID
  FILE_INPUT_TARGET_ID: 'js-file-input-target',

  // I18nメッセージリソースのスコープ
  I18N_SCOPE: 'javascript.profile_image_uploader',

  // ファイルアップロード時の進捗表示要素のコンテナとなる要素のID
  STATUS_BAR_ID: 'js-status-bar-target',
};
