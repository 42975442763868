const alertMessage = (message) => {
  // TODO: DOM操作を行わずに済むようリファクタリングする
  const alert = `<div id="js-alert-message" class="alert alert-danger alert-dismissable">
              <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
              <span class="alert-danger__icon"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
              ${message}
              </div>`;
  $("#js-flash-container").prepend(alert);
};

export default alertMessage;
