import React from 'react';
import PropTypes from 'prop-types';

// ステップ番号表示コンポーネント
export default class StepCircle extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {bool} active アクティブかどうか
   * @property {number} step ステップ番号
   */
  static get propTypes() {
    return({
      active: PropTypes.bool.isRequired,
      step: PropTypes.number.isRequired,
    });
  }

  render() {
    const classes = this.props.active
      ? "ca-job-workflow__step-circle"
      : "ca-job-workflow__step-circle ca-job-workflow__step-circle--inactive";

    return <span className={classes}>{this.props.step}</span>;
  }
}
