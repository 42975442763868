import React from "react";

import Constants from "../../Constants.js";
import Pagination from "../../Pagination.jsx";

import EbsBackupVolumeTable from "./EbsBackupVolumeTable.jsx";
import EbsBackupNotExistsTableContainer from "./EbsBackupNotExistsTableContainer.jsx";

const $ = window.jQuery;
const I18n = window.I18n;

/**
 * バックアップ済みボリューム一覧を表示するテーブルを含むコンポーネントです
 */
export default class EbsBackupExistsTableContainer extends EbsBackupNotExistsTableContainer {
  /**
   * 初期ステートを作成して返します
   */
  initialState() {
    // デフォルト値(Cookieに設定が存在しない場合はこれらの値が使われる)
    let currentPage = 1;
    let perPage = 30;
    let sortColumn = "snapshot_start_time";
    let sortDirection = Constants.DIRECTION.ASC;

    // Cookieに設定がある場合はその値をロードする
    $.cookie.json = true;
    const settings = $.cookie(Constants.COOKIE.EBS_BACKUP_EXISTS_SETTINGS);
    if ($.isPlainObject(settings)) {
      if (settings.current_page != null) {
        currentPage = Number(settings.current_page);
      }
      if (settings.sort_column != null) {
        sortColumn = settings.sort_column;
      }
      if (settings.sort_direction != null) {
        sortDirection = settings.sort_direction;
      }
    }

    return {
      currentPage: currentPage,
      ebsBackupCheckResults: [],
      errorMessage: null,
      perPage: perPage,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      totalItems: 0
    };
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    // 現在のページに表示するバックアップ済みボリュームだけを取得する
    const currentPageEbsBackupCheckResults = this.getEbsBackupCheckResultsForCurrentPage();

    let pagination = null;
    if (currentPageEbsBackupCheckResults.length > 0) {
      pagination = (
        <Pagination
          totalItems={this.state.totalItems}
          perPage={this.state.perPage}
          currentPage={this.state.currentPage}
          callback={this.handlePaginationPageClick}
        />
      );
    }

    return (
      <React.StrictMode>
        <React.Fragment>
          <div className="table-actions">
            <div className="ca-backup-check-volumes-last-check">
              <span className="ca-backup-check-volumes-last-check__text">
                {this.lastCheckDateText}
              </span>
              <span className="ca-backup-check-volumes-last-check__datetime marginL20">
                {this.props.lastCheckDate}
              </span>
            </div>
          </div>
          <EbsBackupVolumeTable
            callback={this.handleSortLinkClick}
            ebsBackupCheckResults={currentPageEbsBackupCheckResults}
            errorMessage={this.state.errorMessage}
            emptyResultMessage={I18n.t("javascript.ebs_backup_exists_results.no_results")}
            isAwsAccountUnitError={this.props.isAwsAccountUnitError}
            sortColumn={this.state.sortColumn}
            sortDirection={this.state.sortDirection}
          />
          {pagination}
        </React.Fragment>
      </React.StrictMode>
    );
  }

  /**
   * サーバーからバックアップ済みボリューム一覧のJSONを取得して、
   * コンポーネントの状態を更新します
   *
   * @private
   */
  loadEbsBackupCheckResults() {
    // バックアップ済みボリューム一覧のJSONを取得する際のソート条件を 'name asc' のような文字列として生成する
    const orderParam = `${this.state.sortColumn} ${this.state.sortDirection}`;

    // バックアップ済みボリューム一覧のJSONを取得するためのURL
    const jsonUrl = `${this.props.source}?order=${orderParam}`;

    $.ajax({
      dataType: "json",
      type: "GET",
      url: jsonUrl,
    })
      .done(data => {
        let newState = {
          errorMessage: null,
          ebsBackupCheckResults: data.ebs_backup_exists_results,
          totalItems: data.ebs_backup_exists_results.length,
        };

        // 現在ページが1以外の場合は、現在ページが超過しないかチェックする
        if (this.state.currentPage > 1) {
          // 取得したジョブの数が現在ページに表示する件数よりも少ない場合は
          // 現在ページを1にする(Cookiesに設定が保存されていた場合に対応するため)
          const idxOfCurrentResults =
            (this.state.currentPage - 1) * this.state.perPage + 1;
          if (data.total < idxOfCurrentResults) {
            newState.currentPage = 1;
          }
        }

        this.setState(newState);
      })
      .fail(() => {
        this.setState({
          errorMessage: Constants.DATA_LOAD_ERROR_MESSAGE,
          ebsBackupCheckResults: [],
        });
      });
  }

  /**
   * 現在の設定をCookieに保存します。
   */
  saveSettings() {
    // 選択されたページをCookieに保存する
    $.cookie(Constants.COOKIE.EBS_BACKUP_EXISTS_SETTINGS, {
      current_page: this.state.currentPage,
      per_page: this.state.perPage,
      search_input: this.state.searchInput,
      sort_column: this.state.sortColumn,
      sort_direction: this.state.sortDirection,
    });
  }
}
