import React from 'react';
import PropTypes from 'prop-types';

const jQuery = window.jQuery;
const I18n = window.I18n; // i18n-js

const I18N_SCOPE = "javascript.webhook_post_process_test_button";

/**
 * Webhook後処理テストリクエスト送信ボタンコンポーネント
 *
 * 対象のWebhook後処理を表すRails側のURLをもとに、Railsのアクション経由でテストリクエスト
 * の送信を行い、その結果を表示します。
 */
class WebhookPostProcessTestButton extends React.Component {
  /**
   * プロパティのバリデーション定義を返します。
   * React内部から呼び出されます。
   *
   * @return {object}
   */
  static get propTypes() {
    return({
      // テストリクエスト送信用のRails側アクションのURL
      url: PropTypes.string.isRequired,
    });
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);
    this.buttonLabel = I18n.t("button_label", { scope: I18N_SCOPE });
    this.buttonLabelForPending = I18n.t("pending", { scope: I18N_SCOPE });
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.state = {
      isPending: false, // リクエスト送信中かどうか (boolean)
      result: null, // テストリクエストが成功したかどうか (boolean)
      resultMessage: null, // テストリクエスト結果のメッセージ (string)
    };
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <React.StrictMode>
        <div className="webhook-post-process-test-button">
          <button
            className="btn btn-lg btn-continue"
            disabled={this.state.isPending}
            onClick={this.handleButtonClick}
            type="button"
          >
            {this.state.isPending ? this.buttonLabelForPending : this.buttonLabel}
          </button>
          {this.renderResult()}
        </div>
      </React.StrictMode>
    );
  }

  /**
   * @return {ReactElement}
   */
  renderResult() {
    if (this.state.resultMessage == null) {
      return;
    }

    const resultClass = this.state.result ?
      "webhook-post-process-test-button__result--success" :
      "webhook-post-process-test-button__result--failure";

    return(
      <span
        className={`webhook-post-process-test-button__result ${resultClass}`}
      >
        {this.state.resultMessage}
      </span>
    );
  }

  /**
   * ボタンがクリックされた際の処理を行います。
   */
  handleButtonClick() {
    this.setState({
      isPending: true,
      resultMessage: null,
    }, this.sendTestRequest);
  }

  /**
   * テストリクエスト送信用のRails側アクションに対してリクエストを行い、
   * 結果に応じてステートを更新します。
   */
  sendTestRequest() {
    const options = {
      credentials: 'same-origin', // Cookieを送信する
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': jQuery('meta[name="csrf-token"]').attr('content') // RailsのCSRFチェックを通すため
      },
    };
    fetch(this.props.url, options)
    .then(response => {
      // この response オブジェクトは、Railsの post_processes#test_request アクションが
      // 返すレスポンスであり、ユーザー側のWebhook URLのレスポンスではないことに注意。
      //
      //   WebhookPostProcessTestButton <-- response -- Rails <-- Webhook URL
      //
      // response オブジェクトのステータスコードの意味は以下のようになります。
      //
      // - 200: Webhook URLへのリクエストが成功してステータスコード200が返されたのでテスト成功扱い
      // - 422: Webhook URLへのリクエストが成功してステータスコード200以外が返されたのでテスト失敗扱い
      // - その他: Webhook URLへのリクエストに失敗したのでテスト失敗扱い
      //
      // ステータスコードが422の場合は response.body にJSONとして格納されているエラー
      // メッセージをユーザーに表示します。
      //
      // ステータスコードがその他の場合は内部的なエラーでありシステム管理者が対応すべき事態
      // なので、response のステータスコードのみをユーザーに表示します。
      //
      if (response.status === 200) {
        this.setState({
          isPending: false,
          result: true,
          resultMessage: I18n.t("success", { scope: I18N_SCOPE }),
        });
        return response;
      } else if (response.status === 422) {
        return response.json().then((json) => { throw new Error(json.error); });
      } else {
        throw new Error(`Internal Server Error: status=${response.status}`);
      }
    })
    .catch(exception => {
      this.setState({
        isPending: false,
        result: false,
        resultMessage: I18n.t("failure", { scope: I18N_SCOPE, message: exception.message }),
      });
    });
  }
}

export default WebhookPostProcessTestButton;
