import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";

import { StackInfoInputField } from "../../aws_account/StackInfoInputField";
import { AwsAccountNameInputField } from "../../aws_account/AwsAccountNameInputField";
import { EbsBackupCheckInputField } from "../../aws_account/EbsBackupCheckInputField";
import { ErrorArea } from "../../aws_account/ErrorArea";
import { IamRoleSetupButton } from "../../aws_account/IamRoleSetupButton";
import ManualLink from "../../ManualLink";
import { SubmitButton } from "../../aws_account/SubmitButton";
import { showSuccessToast } from "../../toast";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.group_aws_account.iam_role_aws_account_dialog";

export const NewAwsAccountWithIamRoleButton: React.FC<{
  group: {
    id: number;
  };
  onCreated: () => void;
}> = (props) => {
  useEffect((): void => {
    ReactModal.setAppElement("body");
  }, []);

  const defaultErrors = {
    awsAccountName: [],
    base: [],
    createIamRoleButton: [],
    ebsBackupCheckEnabled: [],
    externalId: [],
    groupId: [],
    stackInfo: [],
  };

  const [awsAccountName, setAwsAccountName] = useState("");
  const [cfnTemplateCacheId, setCfnTemplateCacheId] = useState("");
  const [stackInfo, setStackInfo] = useState("");
  const [ebsBackupCheckEnabled, setEbsBackupCheckEnabled] = useState(true);
  const [errors, setErrors] = useState(defaultErrors);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [externalId, setExternalId] = useState("");
  const [isPendingAwsRedirection, setIsPendingAwsRedirection] = useState(false);
  const [isTransmitting, setIsTransmitting] = useState(false);

  const onIamRoleCreateButtonClick = (): void => {
    jQuery
      .ajax({
        beforeSend: () => {
          setErrors(defaultErrors);
          setIsPendingAwsRedirection(true);
        },
        // Railsに渡すパラメーターなので一時的に無効化
        data: { group_id: props.group.id },
        dataType: "json",
        method: "POST",
        url: "/iam_role_setup",
      })
      .done((data) => {
        setCfnTemplateCacheId(data.cfn_template_cache_id);
        setExternalId(data.external_id);
        if (window.open(data.iam_role_setup_url, "_blank") == null) {
          window.console.log("IAM role setup URL window blocked.");
        }
      })
      .fail(() => {
        setErrors({ ...errors, createIamRoleButton: [I18n.t("internal_server_error", { scope: I18N_SCOPE })] });
      })
      .always(() => {
        setIsPendingAwsRedirection(false);
      });
  };

  const isCreateIamRoleButtonClicked = (): boolean => {
    return externalId !== "";
  };

  const closeDialog = (): void => {
    setAwsAccountName("");
    setCfnTemplateCacheId("");
    setStackInfo("");
    setEbsBackupCheckEnabled(true);
    setErrors(defaultErrors);
    setDialogVisible(false);
    setExternalId("");
    setIsPendingAwsRedirection(false);
    setIsTransmitting(false);
  };

  const confirmAndClose = (): void => {
    if (isCreateIamRoleButtonClicked()) {
      if (!window.confirm(I18n.t("discard_alert", { scope: I18N_SCOPE }))) {
        return;
      }
    }

    closeDialog();
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const params = {
      // Railsに渡すパラメーターのケースはLintで指摘されても直せないので一時的に無効化します。
      account_type: "iam_role",
      suppress_flash: true,
      aws_account: {
        name: awsAccountName,
        cfn_template_cache_id: cfnTemplateCacheId,
        ebs_backup_check_enabled: ebsBackupCheckEnabled,
        iam_role_attributes: {
          external_id: externalId,
        },
      },
      stack_info: stackInfo,
    };

    jQuery
      .ajax({
        beforeSend: () => {
          setErrors(defaultErrors);
          setIsTransmitting(true);
        },
        url: `/groups/${props.group.id}/aws_accounts`,
        method: "POST",
        dataType: "json",
        data: params,
      })
      .done(() => {
        props.onCreated();
        closeDialog();
        showSuccessToast(I18n.t("succeeded", { scope: I18N_SCOPE }));
      })
      .fail((data) => {
        let errorResponse = data.responseJSON?.errors;
        if (!errorResponse) {
          errorResponse = { base: [I18n.t("error", { message: data.statusText, scope: I18N_SCOPE })] };
        }

        setErrors({
          ...errors,
          awsAccountName: errorResponse.name || [],
          base: errorResponse.base || [],
          ebsBackupCheckEnabled: errorResponse.ebs_backup_check_enabled || [],
          externalId: errorResponse.external_id || [],
          groupId: errorResponse.group || [],
          stackInfo: errorResponse.iam_role || [],
        });
      })
      .always(() => {
        setIsTransmitting(false);
      });
  };

  const handleChangeEbsBackupCheckEnabled = (ebsBackupCheckEnabled: string): boolean => {
    setEbsBackupCheckEnabled(ebsBackupCheckEnabled === "true");
  };

  const isSubmittable = (): boolean => {
    return cfnTemplateCacheId !== "" && externalId !== "" && stackInfo !== "" && awsAccountName !== "";
  };

  return (
    <div className="inline-block">
      <button
        type="button"
        className="btn btn-default"
        onClick={(): void => {
          setDialogVisible(true);
        }}
      >
        <span className="fa fa-plus-square">&nbsp;</span>
        <span className="font-12px">{I18n.t("add_as_iam_role", { scope: I18N_SCOPE })}</span>
      </button>
      <ReactModal
        isOpen={dialogVisible}
        onRequestClose={confirmAndClose}
        overlayClassName="ca-group-aws-account-modal__overlay"
        className="ca-group-aws-account-modal__content"
        role="dialog"
      >
        <div className="text-left">
          <h2>
            <span className="fa fa-key" />
            &nbsp;{I18n.t("heading", { scope: I18N_SCOPE })}
          </h2>
          <p>{I18n.t("description", { scope: I18N_SCOPE })}</p>
          <div className="marginB10 ca-callout ca-callout--warning">
            <ManualLink
              linkText={I18n.t("common.manual.text.aws_account.iam_role")}
              url={I18n.t("common.manual.url.aws_account.iam_role")}
            />
          </div>
        </div>
        <form onSubmit={onSubmit}>
          <div className="ca-group-aws-accounts-new-iam-role-form__fields">
            <div className="ca-group-aws-accounts-new-iam-role-form__fields-row">
              <div className="ca-group-aws-accounts-new-iam-role-form__fields-left-col">
                <div className="ca-group-aws-accounts-new-iam-role-form__field-heading">
                  {I18n.t("step1", { scope: I18N_SCOPE })}
                </div>
              </div>
              <div className="ca-group-aws-accounts-new-iam-role-form__fields-right-col">
                <p>{I18n.t("step1_note", { scope: I18N_SCOPE })}</p>
              </div>
            </div>
            <div className="ca-group-aws-accounts-new-iam-role-form__fields-row">
              <div className="ca-group-aws-accounts-new-iam-role-form__fields-left-col">
                <div className="ca-group-aws-accounts-new-iam-role-form__field-heading">
                  {I18n.t("step2", { scope: I18N_SCOPE })}
                </div>
              </div>
              <div className="ca-group-aws-accounts-new-iam-role-form__fields-right-col">
                <IamRoleSetupButton
                  disabled={isPendingAwsRedirection}
                  errors={errors.createIamRoleButton}
                  onClick={onIamRoleCreateButtonClick}
                />
              </div>
            </div>

            <div className="ca-group-aws-accounts-new-iam-role-form__fields-row">
              <div className="ca-group-aws-accounts-new-iam-role-form__fields-left-col">
                <div className="ca-group-aws-accounts-new-iam-role-form__field-heading">
                  {I18n.t("step3", { scope: I18N_SCOPE })}
                </div>
              </div>
              <div className="ca-group-aws-accounts-new-iam-role-form__fields-right-col">
                <p>{I18n.t("step3_note", { scope: I18N_SCOPE })}</p>
                <StackInfoInputField
                  stackInfo={stackInfo}
                  disabled={!isCreateIamRoleButtonClicked()}
                  onChange={setStackInfo}
                  errors={errors.stackInfo}
                />
                <AwsAccountNameInputField
                  awsAccountName={awsAccountName}
                  disabled={!isCreateIamRoleButtonClicked()}
                  onChange={setAwsAccountName}
                  errors={errors.awsAccountName}
                />
                <EbsBackupCheckInputField
                  ebsBackupCheckEnabled={ebsBackupCheckEnabled}
                  disabled={!isCreateIamRoleButtonClicked()}
                  onChange={handleChangeEbsBackupCheckEnabled}
                  errors={errors.ebsBackupCheckEnabled}
                />
              </div>
            </div>
          </div>
          <div className="ca-btn-block__center marginT20">
            <SubmitButton
              disabled={!isSubmittable()}
              label={I18n.t("submit", { scope: I18N_SCOPE })}
              pending={isTransmitting}
              pendingLabel={I18n.t("transmitting", { scope: I18N_SCOPE })}
            />
            <ErrorArea errors={errors.base} />
            <ErrorArea errors={errors.groupId} />
          </div>
        </form>
      </ReactModal>
    </div>
  );
};
