import React from "react";
import PropTypes from "prop-types";

import { ErrorArea } from "./ErrorArea.jsx";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.aws_account.iam_user";

// アクセスキーの入力フォームコンポーネント
export class SecretAccessKeyInputField extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {?string} secretAccessKey アクセスキー
   * @property {boolean} disabled 入力欄が有効か否か
   * @property {function(secretAccessKey: string)} onChange - アクセスキーの入力値が変化した際に呼び出されるコールバック関数
   * @property {Object} errors 各入力欄の下に表示するエラーメッセージの配列をプロパティ値として持つオブジェクト
   */
  static get propTypes() {
    return {
      secretAccessKey: PropTypes.string,
      disabled: PropTypes.bool.isRequired,
      onChange: PropTypes.func.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    };
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return (
      <div className="form-group">
        <label className="ca-group__aws-account-form__label" htmlFor="IamUserAwsAccountForm-secret-access-key">
          {I18n.t("secret_access_key", { scope: I18N_SCOPE })}
          <span className="ca-group__aws-account-form__required-mark">*</span>
        </label>
        <input
          className="form-control ca-group__aws-account-form__input-text"
          disabled={this.props.disabled}
          id="IamUserAwsAccountForm-secret-access-key"
          onChange={(event) => this.props.onChange(event.target.value)}
          type="password"
          value={this.props.secretAccessKey}
        />
        <ErrorArea errors={this.props.errors} />
      </div>
    );
  }
}
