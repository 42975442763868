import EventEmitter from "events";
import React from "react";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";

import Constants from "../../Constants.js";

import GroupUserTable from "./GroupUserTable.jsx";
import AddGroupUserButton from "./AddGroupUserButton.jsx";

const $ = window.jQuery;
const _ = window._; // Underscore.js
const I18n = window.I18n;

/**
 * グループメンバー一覧を表示するテーブルを含むコンポーネントです
 * グループメンバー追加ボタンなど、テーブルタグの外側にある要素を含みます
 */
export default class GroupUserTableContainer extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @property {boolean} dateLabelFeatureEnabled 組織に対してカレンダー機能の機能フラグが有効かどうか
   * @property {string} groupColor グループのカラーコード
   * @property {number} groupId グループのID
   * @property {string} groupName グループの名前
   * @property {boolean} inventoryAvailable 組織がインベントリ機能を利用可能かどうか
   * @property {boolean} policySetsAvailable 組織が構成レビュー機能を利用可能かどうか
   * @property {string} source グループユーザーを取得するURL
   */
  static get propTypes() {
    return {
      dateLabelFeatureEnabled: PropTypes.bool.isRequired,
      groupColor: PropTypes.string.isRequired,
      groupId: PropTypes.number.isRequired,
      groupName: PropTypes.string.isRequired,
      inventoryAvailable: PropTypes.bool.isRequired,
      policySetsAvailable: PropTypes.bool.isRequired,
      source: PropTypes.string,
    };
  }

  /**
   * コンポーネントを初期化します
   *
   * ステート
   * errorMessage - エラーメッセージを保持します
   * sortColumn - ソート対象となるカラムを保持します
   * sortDirection - ソート方向を保持します
   * users - ユーザー情報が入ったオブジェクトの配列を保持します
   * totalItems - 取得したユーザー数を保持します
   *
   * @public
   * @property {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    let sortColumn = "user_assignments.created_at";
    let sortDirection = Constants.DIRECTION.ASC;

    $.cookie.json = true;
    const settings = $.cookie(Constants.COOKIE.GROUP_USER_SETTINGS);
    if ($.isPlainObject(settings)) {
      if (settings.sort_column != null) {
        sortColumn = settings.sort_column;
      }
      if (settings.sort_direction != null) {
        sortDirection = settings.sort_direction;
      }
    }

    this.state = {
      errorMessage: null,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      users: [],
      totalItems: 0
    };

    // 設定保存処理を最大で1秒間に1回しか呼ばないようにしたバージョンを用意する
    this.throttledSaveSettingsFunc = _.throttle(this.saveSettings, 1000);

    this.handleSortLinkClick = this.handleSortLinkClick.bind(this);

    this.emitter = new EventEmitter();
    this.emitter.on("reloadGroupUsers", () => {
      this.loadGroupUsers();
    });
  }

  /**
   * コンポーネントがマウントされた際の処理を行います。
   * @public
   */
  componentDidMount() {
    this.loadGroupUsers();
  }

  /**
   * 現在のソート方向を逆転したものを返します。
   * コンポーネントの状態は変更しません。
   * @return {string}
   */
  getInvertedSortDirection() {
    if (this.state.sortDirection == Constants.DIRECTION.ASC) {
      return Constants.DIRECTION.DESC;
    }
    return Constants.DIRECTION.ASC;
  }

  /**
   * 特定のカラムでソートするリンクがクリックされた際の処理を実行します
   *
   * @property {string} column クリックされたカラムの識別子
   */
  handleSortLinkClick(column) {
    let newState = {};

    if (column == this.state.sortColumn) {
      newState.sortDirection = this.getInvertedSortDirection();
    } else {
      newState.sortColumn = column;
      newState.sortDirection = Constants.DIRECTION.ASC;
    }

    this.setState(newState, () => {
      this.loadGroupUsers();
      this.saveSettings();
    });
  }

  /**
   * 現在の設定をCookieに保存します。
   */
  saveSettings() {
    // 選択されたページをCookieに保存する
    $.cookie(Constants.COOKIE.GROUP_USER_SETTINGS, {
      sort_column: this.state.sortColumn,
      sort_direction: this.state.sortDirection
    });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return (
      <React.StrictMode>
        <React.Fragment>
          <ToastContainer
            autoClose={5000}
            closeOnClick
            draggable={false}
            hideProgressBar={false}
            newestOnTop={false}
            pauseOnFocusLoss
            pauseOnHover
            position="top-center"
            rtl={false}
          />
          <div className="table-actions">
            <div className="ca-group-user__group-name font-16px marginB30">
              <span
                className="fa fa-briefcase group-icon"
                style={{ color: this.props.groupColor }}
              />
              {this.props.groupName}
            </div>
            <div className="ca-table-headroom">
              <div className="ca-table-headroom__primary">
                <span className="ca-search-box__metainfo">
                  {I18n.t("javascript.group_user.user_count", {
                    number: this.state.totalItems
                  })}
                </span>
              </div>
              <div className="ca-table-headroom__secondary">
                <AddGroupUserButton
                  dateLabelFeatureEnabled={this.props.dateLabelFeatureEnabled}
                  groupId={this.props.groupId}
                  groupUsers={this.state.users}
                  inventoryAvailable={this.props.inventoryAvailable}
                  policySetsAvailable={this.props.policySetsAvailable}
                  reloadEmitter={this.emitter}
                />
              </div>
            </div>
          </div>
          <GroupUserTable
            dateLabelFeatureEnabled={this.props.dateLabelFeatureEnabled}
            groupId={this.props.groupId}
            inventoryAvailable={this.props.inventoryAvailable}
            policySetsAvailable={this.props.policySetsAvailable}
            users={this.state.users}
            reloadEmitter={this.emitter}
            sortColumn={this.state.sortColumn}
            sortDirection={this.state.sortDirection}
            callback={this.handleSortLinkClick}
          />
        </React.Fragment>
      </React.StrictMode>
    );
  }

  /**
   * サーバーからグループユーザー一覧のJSONを取得して、コンポーネントの状態を更新します
   * @private
   */
  loadGroupUsers() {
    // ジョブのJSONを取得する際のソート条件を 'name asc' のような文字列として生成する
    const orderParam = `${this.state.sortColumn} ${this.state.sortDirection}`;

    // グループユーザーのJSONを取得するためのURL
    const jsonUrl = `${this.props.source}?order=${orderParam}`;

    $.ajax({
      dataType: "json",
      type: "GET",
      url: jsonUrl
    })
      .done(data => {
        const requiredKeys = ["total", "users"];
        let invalidFormat = false;
        requiredKeys.forEach(key => {
          if (data[key] == undefined) {
            invalidFormat = true;
          }
        });

        if (invalidFormat) {
          this.setState({
            errorMessage: I18n.t(
              "javascript.group_user.invalid_server_response"
            ),
            totalItems: 0,
            users: []
          });
          return;
        }

        let newState = {
          errorMessage: null,
          users: data.users,
          totalItems: data.total
        };

        this.setState(newState);
      })
      .fail(() => {
        this.setState({
          errorMessage: Constants.DATA_LOAD_ERROR_MESSAGE,
          totalItems: 0,
          users: []
        });
      });
  }
}
