import React from 'react';
import PropTypes from 'prop-types';

import PolicyFieldSet from './PolicyFieldSet.jsx';
import PoliciesAllToggle from './PoliciesAllToggle.jsx';

// ポリシーセクションコンポーネント
// ポリシーセットに含まれる全ポリシーの入力フィールドを集約します。
class Policies extends React.Component {
  static get propTypes() {
    return({
      completedAssignments: PropTypes.object.isRequired,
      defaultLanguage: PropTypes.string.isRequired,
      defaultTimeZone: PropTypes.string.isRequired,
      emitter: PropTypes.object.isRequired,
      failedAssignments: PropTypes.object.isRequired,
      failedPostProcessIds: PropTypes.object.isRequired, // TODO: Deprecate
      group: PropTypes.object.isRequired,
      groups: PropTypes.arrayOf(PropTypes.array).isRequired,
      isSlackIntegrated: PropTypes.bool.isRequired,
      isUserCanManageIntegrations: PropTypes.bool.isRequired,
      loadingImagePath: PropTypes.string.isRequired,
      policyEnables: PropTypes.object.isRequired,
      policyParameters: PropTypes.object.isRequired,
      policyTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
      postProcesses: PropTypes.arrayOf(PropTypes.object).isRequired,
      succeededPostProcessIds: PropTypes.object.isRequired, // TODO: Deprecate
      triggerTypes: PropTypes.object.isRequired,
      postProcessesUrl: PropTypes.string.isRequired,
      regionSet: PropTypes.arrayOf(PropTypes.array).isRequired,
      services: PropTypes.arrayOf(PropTypes.array).isRequired,
      sqsQueuesPath: PropTypes.string.isRequired,
      timeZones: PropTypes.arrayOf(PropTypes.array).isRequired,
      url: PropTypes.string.isRequired
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      postProcesses: this.props.postProcesses
    };
    this.handleUpdatePostProcesses = this.handleUpdatePostProcesses.bind(this);
  }

  render() {
    return(
      <div>
        <PoliciesAllToggle
          emitter={this.props.emitter}
        />
        {this.renderPolicyTemplates()}
      </div>
    );
  }

  renderPolicyTemplates() {
    return(
      this.props.policyTemplates.map((template) => {
        const templateId = template.id;
        return(
          <PolicyFieldSet
            completedAssignments={this.props.completedAssignments[templateId]}
            defaultLanguage={this.props.defaultLanguage}
            defaultTimeZone={this.props.defaultTimeZone}
            emitter={this.props.emitter}
            enabled={this.props.policyEnables[templateId]}
            failedAssignments={this.props.failedAssignments[templateId]}
            failedPostProcessIds={this.props.failedPostProcessIds[templateId]}
            group={this.props.group}
            groups={this.props.groups}
            handleUpdatePostProcesses={this.handleUpdatePostProcesses}
            isSlackIntegrated={this.props.isSlackIntegrated}
            isUserCanManageIntegrations={this.props.isUserCanManageIntegrations}
            key={templateId}
            loadingImagePath={this.props.loadingImagePath}
            parameters={this.props.policyParameters[templateId]}
            policyTemplate={template}
            postProcesses={this.state.postProcesses}
            succeededPostProcessIds={this.props.succeededPostProcessIds[templateId]}
            triggerType={this.props.triggerTypes[templateId]}
            postProcessesUrl={this.props.postProcessesUrl}
            regionSet={this.props.regionSet}
            services={this.props.services}
            sqsQueuesPath={this.props.sqsQueuesPath}
            timeZones={this.props.timeZones}
            url={this.props.url}
          />
        );
      })
    );
  }

  // 引数に指定された後処理を元に、後処理のステートを更新します。
  //
  // @param postProcessId [Number]
  handleUpdatePostProcesses(postProcesses) {
    this.setState({
      postProcesses: postProcesses
    });
  }
}

export default Policies;
