import React, { useRef, useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import TypeaheadRef from "react-bootstrap-typeahead/types/core/Typeahead";

import { Option, OptionFromServer, useInputValue } from "./multiple_typeahead_utils";

/** AWSアカウント入力欄のセレクター */
const awsAccountIdSelector = "#trigger_job_aws_account_id";
/** リージョン入力欄のセレクター */
const regionSelector = "#run_ecs_tasks_fargate_action_value_section #trigger_job_region";
/** VPC ID入力欄のセレクター */
const vpcIdSelector = "#run_ecs_tasks_fargate_action_value_section #js-trigger_job_ecs_awsvpc_vpc";
/** トリガージョブフォームのform要素のid属性値 */
const formId = "make-form";
/** フォームのECS用サブネット入力欄となるINPUTタグのname属性に設定する値 */
const inputTagName = "trigger_job[ecs_awsvpc_subnets][]";

/** プロパティ */
type Props = {
  /** セレクトボックスのプレースホルダー */
  placeholder: string;
  /** プリセット値とするサブネットIDが属するVPC ID */
  preset_ecs_awsvpc_vpc: string;
  /** プリセット値とするサブネットID */
  presets: string[];
  /** プリセット値が空の場合に、サーバーから返された選択肢のデフォルトフラグよりもユーザー入力値(未入力)を尊重するかどうか */
  is_respect_user_input: boolean;
};

/**
 * トリガージョブフォームでECS用サブネットを複数選択するためのコンポーネントです。
 * 以下の責務を持ちます。
 *
 * - サブネット複数選択用UIの表示 (react-bootstrap-typeahead を利用)
 * - 実際の選択値をジョブフォーム内に保持するための <INPUT type="hidden"> タグの管理
 */
const TriggerJobFormEcsSubnetsSelector: React.FC<Props> = (props): JSX.Element => {
  /** 選択可能な選択肢 */
  const [options, setOptions] = useState<Option[]>([]);
  /** 現在選択されている選択肢 */
  const [selected, setSelected] = useState<Option[]>([]);
  /** AWSアカウントIDの現在値 */
  const [currentAwsAccountId] = useInputValue(awsAccountIdSelector);
  /** リージョンの現在値 */
  const [currentRegion] = useInputValue(regionSelector);
  /** VPC IDの現在値 */
  const [currentVpcId, setCurrentVpcId] = useInputValue(vpcIdSelector);
  /** TypeaheadのDOM要素への参照 */
  const typeaheadRef = useRef<TypeaheadRef>(null);

  /** サーバーから選択肢を取得して、選択可能な選択肢と現在選択されている選択肢を更新する */
  const fetchData = (): void => {
    // サーバーとの通信に必要な情報が空の場合は何もしない
    if (currentAwsAccountId == "" || currentRegion == "" || currentVpcId == "") {
      return;
    }

    // GETリクエストのクエリパラメーターを組み立てる
    const params = new URLSearchParams({
      aws_account_id: currentAwsAccountId,
      region: currentRegion,
      vpc_id: currentVpcId,
    });
    let query = params.toString();
    if (currentVpcId == props.preset_ecs_awsvpc_vpc) {
      // 現在選択されているVPCが「プリセット値として指定されているサブネット」が属するVPCと同じ場合は、
      // 「プリセット値」を現在値としてクエリに加えます。
      // これによって、選択肢の取得完了後にプリセット値のサブネットが選択状態となります。
      props.presets.forEach((subnetId) => {
        query = query.concat(`&current[]=${subnetId}`);
      });
    }

    fetch(`/ecs/subnets.json?${query}`)
      .then((response) => response.json())
      .then((options: OptionFromServer[]) => {
        // 選択肢を更新する
        setOptions(options);

        // サーバーから取得した選択肢のデフォルトフラグを利用するかどうか
        // 「ユーザーがサブネットを選択せずにフォームを送信した後に再表示されたフォームにおける処理」以外のケースが該当
        const useDefaultFlag = !(props.presets.length == 0 && props.is_respect_user_input);

        if (useDefaultFlag) {
          // 取得した選択肢のうちデフォルトフラグがオンのものを選択状態にする
          const defaultOptions = options.filter((option) => option.is_default);
          setSelected(defaultOptions);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // ジョブフォーム内のECS用VPC IDの選択肢が更新された際に、
  // VPC IDの現在値が更新されるようにイベントハンドラを設定します。
  useEffect(() => {
    const handler = (): void => {
      // ジョブフォーム内のVPC ID入力欄の値を取得して、VPC IDの現在値に反映する
      const element = document.querySelector<HTMLSelectElement>(vpcIdSelector);
      if (element) {
        setCurrentVpcId(element.value);
      }
    };

    // ECS用VPC IDのセレクトボックスの選択肢が更新された際に発生するカスタムイベントに
    // イベントハンドラを設定する
    document.addEventListener("kanrinmaru:ecs_vpcs_loaded", { handleEvent: handler });

    // コンポーネントのアンマウント時にイベントハンドラが削除されるようにする
    return (): void => {
      document.removeEventListener("kanrinmaru:ecs_vpcs_loaded", handler);
    };
  }, []);

  // 選択肢をサーバーから取得します。
  //
  // コンポーネントのロード時および、AWSアカウントID、リージョン、VPC IDのいずれかの
  // 現在値が変化したタイミングで実行されます。
  useEffect(() => {
    setSelected([]);
    fetchData();
  }, [currentAwsAccountId, currentRegion, currentVpcId]);

  // 現在選択されている選択肢が変化したら、差分表示を更新するためのカスタムイベントを発生させます。
  useEffect(() => {
    $(`#${formId}`).trigger("kanrinmaru:diff_update_requested");
  }, [selected]);

  const handleChange = (selectedItems: Option[]): void => {
    setSelected(selectedItems);
    // 複数選択時の利便性のため、選択肢を選んだ後もプルダウンメニューが閉じないようにする
    typeaheadRef?.current?.toggleMenu();
  };

  /** 選択肢が選ばれているかどうか */
  const isOptionSelected = selected.length != 0;

  /**
   * 差分表示の計算に使われる初期値
   *
   * 文字列の配列をJSON表現にしておくことで、job_edit_diff_service.coffee 内の jQuery.data() で
   * 値を取得した際に文字列の配列として復元される。
   */
  const preset_identifier = JSON.stringify(props.presets);

  return (
    <React.StrictMode>
      <Typeahead
        className="ca-group-name-selector"
        disabled={false}
        id="TriggerJobFormEcsSubnetsSelector"
        inputProps={{ name: "typeahead_for_ecs_awsvpc_subnets" }}
        multiple
        onChange={handleChange}
        options={options}
        placeholder={props.placeholder}
        ref={typeaheadRef}
        selected={selected}
      />

      {isOptionSelected &&
        selected.map((option) => {
          return (
            <input
              type="hidden"
              key={option.id}
              name={inputTagName}
              value={option.id}
              data-preset-identifier={preset_identifier}
            />
          );
        })}
      {!isOptionSelected && (
        <input type="hidden" name={inputTagName} value="" data-preset-identifier={preset_identifier} />
      )}
    </React.StrictMode>
  );
};

export default TriggerJobFormEcsSubnetsSelector;
