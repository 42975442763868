import EventEmitter from "events";
import React from "react";
import PropTypes from "prop-types";

import alertMessage from "../AlertMessage.js";
import DeleteGroupDialog from "./DeleteGroupDialog.jsx";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.groups.delete_group_link";

/**
 * グループ一覧画面で表示するグループ削除リンク
 *
 * propで受け取った `disabled` プロパティに応じて処理が以下に分岐します
 * - 削除可能な場合
 *   - リンクをクリックするとグループ削除ダイアログが現れる
 *   - その後の処理は DeleteGroupDialog で行われる
 * - 削除不可能な場合
 *   - リンクをクリックすると画面上部のFlashメッセージ表示箇所にエラーメッセージが表示される
 */
class DeleteGroupLink extends React.Component {
  /**
   * プロパティ定義を返します。
   * @public
   * @return {Object}
   * @property {boolean} disabled グループが削除可能かどうか
   * @property {number} groupId グループのID
   * @property {string} groupName グループ名
   */
  static get propTypes() {
    return {
      disabled: PropTypes.bool.isRequired,
      groupId: PropTypes.number.isRequired,
      groupName: PropTypes.string.isRequired,
    };
  }

  /**
   * コンストラクタ
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.state = { showDialog: false };

    this.emitter = new EventEmitter();
    this.emitter.on("closeDialog", () => this.setState({ showDialog: false }));
    this.emitter.on("openDialog", () => this.setState({ showDialog: true }));
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    const content = this.props.disabled ? this.renderDisabledLink() : this.renderEnableLink();

    return content;
  }

  /**
   * グループ削除不可の時に表示する削除リンク
   * クリックをするとフラッシュメッセージ表示部にアラートメッセージを出す
   *
   * @public
   * @return {ReactElement}
   */
  renderDisabledLink() {
    return (
      <Link
        onClick={() => {
          alertMessage(I18n.t("not_deletable", { scope: I18N_SCOPE }));
        }}
      />
    );
  }

  /**
   * グループ削除リンク
   * クリックするとグループ削除確認ダイアログが表示される
   * @public
   * @return {ReactElement}
   */
  renderEnableLink() {
    return (
      <>
        <Link onClick={() => this.emitter.emit("openDialog")} />
        <DeleteGroupDialog
          emitter={this.emitter}
          groupId={this.props.groupId}
          groupName={this.props.groupName}
          showDeleteGroupDialog={this.state.showDialog}
        />
      </>
    );
  }
}

/**
 * グループ削除リンクのAタグを描画します。
 */
class Link extends React.Component {
  /**
   * プロパティ定義を返します。
   * @public
   * @return {Object}
   * @property {function(event: React.SyntheticEvent): void} onClick クリックされた際に実行するコールバック
   */
  static get propTypes() {
    return {
      onClick: PropTypes.func.isRequired,
    };
  }

  render() {
    return (
      <a href="#" onClick={this.props.onClick}>
        <i className="fas fa-trash-alt" aria-hidden="true"></i>
        {I18n.t("delete", { scope: I18N_SCOPE })}
      </a>
    );
  }
}

export default DeleteGroupLink;
