import React, { useState } from "react";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.aws_account.iam_role";

/**
 * CFnスタックID入力フィールド
 */
export const CfnStackIdInputField: React.FC<{
  disabled: boolean;
  errors: string[];
  name: string;
  onChange: (newValue: string) => void;
  placeholder: string;
  required: boolean;
  value: string;
}> = (props) => {
  const [value, setValue] = useState(props.value || "");

  return (
    <div className="form-group marginB20">
      <label className="ca-group__aws-account-form__label">
        {I18n.t("cfn_stack_id", { scope: I18N_SCOPE })}
        {props.required && <span className="ca-group__aws-account-form__required-mark">*</span>}
      </label>

      <input
        className="form-control input-long input-sm inline-block required"
        disabled={props.disabled}
        id="UpdateAwsAccountForm-cfn-stack-id"
        name={props.name}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
          const value = event.target.value;
          setValue(value);
          props.onChange(value);
        }}
        placeholder={props.placeholder}
        value={value}
      />
      {props.errors.length > 0 && <ErrorArea errors={props.errors} />}
    </div>
  );
};

/**
 * CFnスタックID入力フィールド下のエラーメッセージ表示
 */
export const ErrorArea: React.FC<{
  errors: string[];
}> = (props) => {
  if (props.errors.length < 1) {
    return <div className="error-area"></div>;
  }

  return (
    <div className="error-area">
      <ul style={{ paddingLeft: 0, listStyleType: "none" }}>
        {props.errors.map((value, index) => {
          return (
            <li className="error" key={index}>
              <label
                className="error"
                dangerouslySetInnerHTML={{
                  __html: value,
                }}
              ></label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
