import React, { useEffect } from "react";
import ReactModal from "react-modal";

import { ToggleableJob } from "./types";

export const ToggleJobUnavailableModal: React.FC<{
  job: ToggleableJob;
  dialogMessage: string;
  showDialog: boolean;
  onOkButtonClick: () => void;
}> = (props) => {
  // Initialize
  useEffect(() => {
    ReactModal.setAppElement("body");
  }, []);

  return (
    <ReactModal
      className="ca-job-toggle-modal__content"
      isOpen={props.showDialog}
      onRequestClose={() => props.onOkButtonClick()}
      overlayClassName="ca-job-toggle-modal__overlay"
      role="dialog"
    >
      <div className="ca-job-toggle-modal__content-messages">
        <p dangerouslySetInnerHTML={{ __html: props.dialogMessage }}></p>
        <p>
          <strong>{props.job.name}</strong>
        </p>
      </div>

      <div className="ca-job-toggle-modal__content-buttons">
        <OkButton onClick={() => props.onOkButtonClick()} />
      </div>
    </ReactModal>
  );
};

const OkButton: React.FC<{
  onClick: () => void;
}> = (props) => {
  return (
    <button className="btn btn-success btn-positive" onClick={props.onClick} type="button">
      <span className="fa fa-check marginR5"></span>OK
    </button>
  );
};
