/**
 * グループ編集フォーム（グループ設定 - 基本情報）のグループ利用可否チェック用ロジック
 *
 * ReactではないHTML(ERB)のフォームに含まれるグループ名入力欄(INPUT)を対象に、
 * 入力されたグループ名の利用可否チェックと結果に応じたエラーメッセージの表示制御を行います。
 */

/**
 * グループ名が利用可能かどうかをチェックする関数。
 *
 * name に与えられたグループ名が組織内で利用可能かどうかを、以下のリクエストのステータスコードで判定し
 * その結果を callback に与えられた関数に渡して呼び出します。
 *
 *   POST /organization/available_group_names
 *
 */
const checkAvailability = (name: string, callback: (isAvailable: boolean) => void): void => {
  jQuery
    .ajax({
      data: { name: name },
      dataType: "json",
      method: "POST",
      url: "/organization/available_group_names",
    })
    .done(() => {
      callback(true);
    })
    .fail((jqXHR) => {
      if (jqXHR.readyState != 4) {
        // readyState が4以外の場合は、XHRの通信自体が失敗しているためコールバック関数を呼ばない。
        //
        // この判定を行わないと、利用可否チェック中にフォームが送信された場合などに、グループ名の
        // 利用可否にかかわらず一瞬だけ利用不可であることをあらわすエラーメッセージが表示されてしまう。
        //
        // https://developer.mozilla.org/ja/docs/Web/API/XMLHttpRequest/readyState
        return;
      }
      callback(false);
    });
};

/**
 * グループ利用可否チェック用関数
 *
 * formIdで指定されたid属性値を持つFORM要素を対象として、以下の子要素を利用し、
 *
 * - グループ名の入力欄 (name="group[name]" なINPUT要素)
 * - エラーメッセージ表示エリア (data-js-id="error-area" な要素)
 * - エラーメッセージ (data-js-id="error-list-template" な要素)
 *
 * グループ名の値が変化する毎に（多くても1秒に1回の頻度で）、サーバーサイドにグループ名の利用可否を
 * 問い合わせ、結果に応じてエラーメッセージ表示エリアのクリアまたはエラーメッセージのセットを行います。
 */
export const groupEditFormController = (formId: string): void => {
  // グループ名が利用可能かどうかをチェックする関数を、最短でも1秒に1回しか実行されないようにスロットリングした関数。
  const throttledCheckAvailability = window._.throttle(checkAvailability, 1000);

  const $form = jQuery(`#${formId}`);
  const $input = $form.find("input[name='group[name]']");
  const $errorArea = $form.find("[data-js-id='error-area']");
  const $errorListTemplate = $form.find("[data-js-id='error-list-template']");
  // 初期値(現在のグループ名)をあとで比較するために退避しておく
  const currentName = $input.val();

  $input.on("input", (event: Event) => {
    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }

    const newName = event.target.value;

    if (newName == currentName || newName.trim() == "") {
      // 以下のケースでは、エラーメッセージのクリアのみ行い利用可否チェックは行わない。
      //
      // 1. 入力値が現在のグループ名と同じ場合
      //   「名前を変更しようとしてから元の名前に戻す」操作をした際に、
      //   入力値が「編集対象にしているグループの現在の名前」と比較されてしまい
      //   利用不可と表示されることを避けるため。
      // 2. 入力値が空文字または空白文字列の場合
      //   一度、利用不可のエラーメッセージが表示されたあと、ユーザーが入力値を
      //   空にした際には直ちにエラーメッセージがクリアされたほうが自然なため。
      //
      // なお、ここの処理によって、フォーム送信後にバリデーションエラーが発生した際に
      // ERB上で表示されるバリデーションエラーメッセージがあわせてクリアされてしまうが、
      // 致命的ではないので許容することにする。
      $errorArea.html("");
      return;
    }

    throttledCheckAvailability(newName, (isAvailable) => {
      $errorArea.html(isAvailable ? "" : $errorListTemplate.html());
    });
  });
};
