import React from 'react';

import Constants from './Constants.js';
import RecentLogsDropdownMenu from './RecentLogsDropdownMenu.jsx';

const I18n = window.I18n; // i18n-js

/**
 * トップメニューにあるログ情報のボックスを表示します。
 * 展開メニュークリック時に最新のログ情報を取得し表示します。
 *
 * サーバーとの通信および配下のコンポーネントの呼び出しのみを行い、UIに関する処理は行いません。
 */
class RecentLogsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleShowDropdown = this.handleShowDropdown.bind(this);

    this.loadingText = I18n.t('loading', { scope: Constants.I18N_SCOPE });
    this.emptyText = I18n.t('empty_message', { scope: Constants.I18N_SCOPE });
    this.loadFailedText = I18n.t('load_failed', { scope: Constants.I18N_SCOPE });

    this.state = {
      policyLogs: [], // ポリシーログのJSONオブジェクトの配列
      policyLogsMessage: '', // ポリシーログのかわりに表示するメッセージ
      triggerJobLogs: [], // ジョブログのJSONオブジェクトの配列
      triggerJobLogsMessage: '', // ジョブログのかわりに表示するメッセージ
    };
  }

  render() {
    return(
      <React.StrictMode>
        <RecentLogsDropdownMenu
          onShowDropdown={this.handleShowDropdown}
          policyLogs={this.state.policyLogs}
          policyLogsMessage={this.state.policyLogsMessage}
          triggerJobLogs={this.state.triggerJobLogs}
          triggerJobLogsMessage={this.state.triggerJobLogsMessage}
        />
      </React.StrictMode>
    );
  }

  /**
   * ドロップダウンメニューが表示される際の処理を行うイベントハンドラ。
   * サーバーからログ情報のJSONを取得してステートを更新します。
   */
  handleShowDropdown() {
    $.ajax({
      dataType: 'json',
      url: Constants.RECENT_LOGS_URL,
      beforeSend: () => {
        // 表示すべきログデータをまだ保持していない場合は、代替としてデータを取得中であることを表示する
        // ページ表示後、2度目以降の呼び出し時には前回取得のログデータを表示しつつ通信を行う動作となる
        this.setState({
          policyLogsMessage: this.state.policyLogs.length == 0 ? this.loadingText : '',
          triggerJobLogsMessage: this.state.triggerJobLogs.length == 0 ? this.loadingText : '',
        });
      },
    }).done((data) => {
      const triggerJobLogs = data.trigger_job_logs;
      const policyLogs = data.policy_logs;
      this.setState({
        policyLogsMessage: policyLogs.length ? '' : this.emptyText,
        policyLogs: policyLogs,
        triggerJobLogsMessage: triggerJobLogs.length ? '' : this.emptyText,
        triggerJobLogs: triggerJobLogs,
      });
    }).fail(() => {
      this.setState({
        policyLogsMessage: this.loadFailedText,
        policyLogs: [],
        triggerJobLogsMessage: this.loadFailedText,
        triggerJobLogs: [],
      });
    });
  }
}

export default RecentLogsContainer;
