import React from 'react';
import PropTypes from 'prop-types';

class RecentLogsLinks extends React.Component {
  /**
   * プロパティ。
   *
   * @property {string} name トリガージョブ名またはポリシー名
   * @property {string} statusIconStyle 実行結果を表すアイコンのクラス名
   * @property {string} timeAgo 詳細は下記参照
   * @property {string} url ログのパス
   *
   * timeAgo: XXX時間前 などの相対時間を表示。
   * トリガージョブの場合: ジョブが終了した時間を表示。(終了時間データがないログは「実行中」と表示)
   * ポリシーログの場合: ログが作成された時間を表示。
   */
  static get propTypes() {
    return ({
      name: PropTypes.string.isRequired,
      statusIconStyle: PropTypes.string.isRequired,
      timeAgo: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    });
  }

  render() {
    const classesForStatus = `fa ${this.props.statusIconStyle}`;

    return (
      <React.Fragment>
        <li className="dropdown-menu__list--link">
          <a className="ca-logs-link" href={this.props.url}>
            <div className="ca-logs-link__info">
              <div className="ca-logs-link__title">
                {this.props.name}
              </div>
              <div className="ca-logs-link__time-ago">
                {this.props.timeAgo}
              </div>
            </div>
            <div className="ca-logs-link__status">
              <i className={classesForStatus} aria-hidden="true"></i>
            </div>
          </a>
        </li>
        <li className="divider"></li>
      </React.Fragment>
    );
  }
}

export default RecentLogsLinks;
