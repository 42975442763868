import PropTypes from 'prop-types';
import React from 'react';

import Dialog from './Dialog.jsx';
import DialogOpenButton from './DialogOpenButton.jsx';

/**
 * パスワード設定UIコンポーネント。
 *
 * このコンポーネントはパスワード設定のルック＆フィールについて責務を持ちます。
 */
export default class PasswordSettings extends React.Component {
  /**
   * プロパティの定義を返します。
   *
   * @return {Object}
   * @property {boolean} completed パスワード更新が完了したかどうか
   * @property {string} currentPassword 現在のパスワードの入力欄の値
   * @property {boolean} dialogVisible フォームのモーダルダイアログが表示中かどうか
   * @property {Object} emitter EventEmitterオブジェクト
   * @property {string[]} errorMessages エラーメッセージの配列
   * @property {boolean} isLoading 通信中かどうか
   * @property {string} newPassword 新しいパスワードの入力欄の値
   * @property {string} newPasswordConfirmation 確認用パスワードの入力欄の値
   */
  static get propTypes() {
    return({
      completed: PropTypes.bool.isRequired,
      currentPassword: PropTypes.string.isRequired,
      emitter: PropTypes.object.isRequired,
      dialogVisible: PropTypes.bool.isRequired,
      errorMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
      isLoading: PropTypes.bool.isRequired,
      newPassword: PropTypes.string.isRequired,
      newPasswordConfirmation: PropTypes.string.isRequired,
    });
  }

  /**
   * コンストラクタ。
   *
   * @override
   */
  constructor(props) {
    super(props);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <React.Fragment>
        <DialogOpenButton emitter={this.props.emitter} />
        <Dialog {...this.props} />
      </React.Fragment>
    );
  }
}
