import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import DisableDialogContent from './DisableDialogContent.jsx';
import EnableDialogContent from './EnableDialogContent.jsx';

const I18n = window.I18n; // i18n-js
const I18N_SCOPE = 'javascript.tfa_settings.dialog';

/**
 * 二要素認証有効化・無効化ダイアログ
 * ダイアログの表示制御および、プロパティの値に応じたコンテンツの表示を行います。
 */
export default class Dialog extends React.Component {
  /**
   * propTypes
   * @return {object}
   * @property {object} emitter EventEmitter
   * @property {bool} enabled 二要素認証が有効化されているかどうか
   * @property {bool} error エラーが発生したかどうか
   * @property {string|null} qrCodeHtml QRコード
   * @property {bool} showDialog ダイアログが表示状態かどうか
   */
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
      enabled: PropTypes.bool.isRequired,
      error: PropTypes.bool.isRequired,
      otpSecretKey: PropTypes.string,
      qrCodeHtml: PropTypes.string,
      showDialog: PropTypes.bool.isRequired,
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);

    this.contentLabelText = I18n.t('content_label', { scope: I18N_SCOPE });

    ReactModal.setAppElement("body");
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const contentClassName = this.props.enabled
      ? "tfa-settings-modal__disable-content"
      : "tfa-settings-modal__enable-content";

    return(
      <ReactModal
        className={contentClassName}
        contentLabel={this.contentLabelText}
        isOpen={this.props.showDialog}
        onAfterOpen={this.handleOnAfterOpen.bind(this)}
        onRequestClose={() => { this.props.emitter.emit('closeDialog'); }}
        overlayClassName="tfa-settings-modal__overlay"
        role="dialog"
      >
        {this.renderContent()}
      </ReactModal>
    );
  }

  /**
   * ダイアログのコンテンツを表示します。
   * @access {private}
   * @return {ReactElement}
   */
  renderContent() {
    if (this.props.enabled) {
      return(
        <DisableDialogContent
          emitter={this.props.emitter}
          error={this.props.error}
        />
      );
    }
    return(
      <EnableDialogContent
        emitter={this.props.emitter}
        error={this.props.error}
        otpSecretKey={this.props.otpSecretKey}
        qrCodeHtml={this.props.qrCodeHtml}
      />
    );
  }

  /**
   * ダイアログが開いた後の処理を行います。
   * @access {private}
   */
  handleOnAfterOpen() {
    if (!this.props.enabled) {
      // 有効化ダイアログが開かれる度に新しいQRコードをサーバーから取得する
      this.props.emitter.emit('fetchQrCode');
    }
  }
}
