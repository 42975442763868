import PropTypes from 'prop-types';
import React from 'react';

/**
 * フィルタリング用セレクトボックスUIコンポーネント。
 *
 * SELECTタグのスタイリングおよび値変更時のイベントハンドリングの責務のみを持ちます。
 * 選択肢については、呼び出し側で任意のOPTIONタグを子要素として与える必要があります。
 *
 *   <FilterSelectBox onChange={...}>
 *     <option value="...">...</option>
 *   </FilterSelectBox>
 */
export default class FilterSelectBox extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {ReactElement} children 子コンポーネント
   * @property {string} name SELECTタグのname属性値
   * @property {function(newValue: string)} onChange SELECTタグの値が変化した際に呼び出されるコールバック関数
   * @property {string?} width コンポーネントのスタイルのwidthプロパティに設定する値
   */
  static get propTypes() {
    return({
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
      ]),
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func,
      width: PropTypes.string,
    });
  }

  /**
   * デフォルトのプロパティを返します。
   *
   * @public
   * @return {Object}
   * @property {function(newValue: string)} onChange SELECTタグの値が変化した際に呼び出されるコールバック関数
   * @property {string} width コンポーネントのスタイルのwidthプロパティに設定する値
   */
  static get defaultProps() {
    return({
      onChange: null,
      width: '15em',
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = { value: '' };
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <div style={{display: 'inline-block', width: this.props.width}}>
        <select
          className="form-control"
          name={this.props.name}
          onChange={this.handleChange}
          value={this.state.value}
        >
          {this.props.children}
        </select>
      </div>
    );
  }

  /**
   * セレクトボックスの値が変化した際の処理を行います。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleChange(event) {
    event.preventDefault();

    const newValue = event.target.value;
    this.setState({ value: newValue }, () => {
      if (this.props.onChange) {
        this.props.onChange(newValue);
      }
    });
  }
}
