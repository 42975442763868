import React, { useState } from "react";

import { AccessKeyIdInputField } from "../aws_account/AccessKeyIdInputField.jsx";
import { AwsAccountNameInputField } from "../aws_account/AwsAccountNameInputField.jsx";
import ManualLink from "../ManualLink.jsx";
import { SecretAccessKeyInputField } from "../aws_account/SecretAccessKeyInputField.jsx";
import { SubmitButton } from "../aws_account/SubmitButton.jsx";

import { CreateGroupFormData, ErrorMessages } from "./types";

import { ProviderAccountTypeSelectorForGroup } from "./ProviderAccountTypeSelectorForGroup";
import { ErrorArea2 } from "./ErrorArea2";
import { GroupColorSelector2 } from "./GroupColorSelector2";
import { GroupNameInputField } from "./GroupNameInputField";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.new_group_with_iam_user_form";

/**
 * フォームの各フィールドに対するエラーメッセージを含むオブジェクトの型
 */
type FormErrors = {
  awsAccountAccessKeyId: ErrorMessages;
  awsAccountName: ErrorMessages;
  awsAccounts: ErrorMessages;
  awsAccountSecretAccessKey: ErrorMessages;
  base: ErrorMessages;
  groupColor: ErrorMessages;
  groupName: ErrorMessages;
};

/**
 * エラーメッセージ用ステートの初期状態およびリセット後の状態
 */
const emptyErrors: FormErrors = {
  awsAccountAccessKeyId: [],
  awsAccountName: [],
  awsAccounts: [],
  awsAccountSecretAccessKey: [],
  base: [],
  groupColor: [],
  groupName: [],
};

/**
 * グループ新規作成フォーム (IAMユーザー方式)
 *
 * 以下の責務を持ちます。
 *
 * - グループ新規作成HTMLフォーム(IAMユーザー方式用)の表示
 * - グループ作成リクエストのサーバーサイドへの送信
 *   - グループ作成成功時のリダイレクト
 *   - グループ作成失敗時のエラーメッセージ表示
 *
 */
export const NewGroupWithIamUserForm: React.FC<{
  group: {
    color: string;
    name: string;
  };
}> = (props) => {
  // AWSアカウントのアクセスキーID
  const [awsAccountAccessKeyId, setAwsAccountAccessKeyId] = useState("");
  // AWSアカウント名
  const [awsAccountName, setAwsAccountName] = useState("");
  // AWSアカウントのシークレットアクセスキー
  const [awsAccountSecretAccessKey, setAwsAccountSecretAccessKey] = useState("");
  // 各フィールド毎のエラーメッセージ
  const [errors, setErrors] = useState(Object.assign({}, emptyErrors));
  // グループのカラー
  const [groupColor, setGroupColor] = useState(props.group.color || "#aa0441");
  // グループの名前
  const [groupName, setGroupName] = useState(props.group.name || "");
  // フォームを送信中かどうか
  const [isTransmitting, setIsTransmitting] = useState(false);

  /**
   * フォームをXHRで送信してグループの新規作成を行います。
   *
   * 処理に成功した場合は、サーバーから指示されたURLにリダイレクトします。
   * 処理に失敗した場合は、バリデーションエラーまたはエラーメッセージを設定します。
   */
  const handleSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();

    setErrors(emptyErrors);
    setIsTransmitting(true);

    const data: CreateGroupFormData = {
      // ここで記述している各リクエストパラメーター名は、サーバー側Groupモデルの
      // accepts_nested_attributes_for設定に準じたもの。
      group: {
        name: groupName,
        color: groupColor,
        aws_accounts_attributes: [
          {
            access_key_id: awsAccountAccessKeyId,
            name: awsAccountName,
            secret_access_key: awsAccountSecretAccessKey,
          },
        ],
      },
    };

    jQuery
      .ajax({
        url: "/groups",
        method: "POST",
        dataType: "json",
        data: data,
      })
      .done((data) => {
        window.location.href = data.url;
      })
      .fail((jqXHR, textStatus) => {
        const newErrors = Object.assign({}, emptyErrors);

        if (jqXHR.status == 422 && Object.prototype.hasOwnProperty.call(jqXHR.responseJSON, "errors")) {
          // バリデーションエラーかつレスポンスJSONに errors プロパティが存在する場合
          const rawErrors = jqXHR.responseJSON.errors;
          // レスポンスJSONに含まれるエラーメッセージのキーはGroupモデルの定義に
          // 準じたキーになっているため、個別に読み替えて変数に格納する。
          newErrors.awsAccountAccessKeyId = rawErrors["aws_accounts.access_key_id"] || [];
          newErrors.awsAccountName = rawErrors["aws_accounts.name"] || [];
          newErrors.awsAccounts = rawErrors.aws_accounts || [];
          newErrors.awsAccountSecretAccessKey = rawErrors["aws_accounts.secret_access_key"] || [];
          newErrors.base = rawErrors.base || [];
          newErrors.groupColor = rawErrors.color || [];
          newErrors.groupName = rawErrors.name || [];
        } else {
          // 500エラーなどの場合
          newErrors.base = [I18n.t("error", { message: textStatus, scope: I18N_SCOPE })];
        }
        setErrors(newErrors);
        setIsTransmitting(false);
      });
  };

  /**
   * フォームが送信可能かどうかを返します。
   */
  const isSubmittable = (): boolean => {
    return (
      groupName != "" &&
      groupColor != "" &&
      awsAccountAccessKeyId != "" &&
      awsAccountSecretAccessKey != "" &&
      awsAccountName != ""
    );
  };

  return (
    <React.StrictMode>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="panel clearfix padding30">
          <h2 className="marginB0 font-18px">
            <span className="fa fa-drivers-license-o fa-lg"></span>
            {I18n.t("group_heading", { scope: I18N_SCOPE })}
          </h2>

          <div className="ca-group-form">
            <table className="table vertical-middle ca-group-form__table">
              <tbody>
                <tr>
                  <th className="ca-group-form__table-header">
                    <label htmlFor="NewGroupWithIamUserForm-name" style={{ fontWeight: "bold" }}>
                      {I18n.t("activerecord.attributes.group.name")}
                      <span className="required">*</span>
                    </label>
                  </th>
                  <td>
                    <GroupNameInputField
                      defaultValue={props.group.name}
                      id="NewGroupWithIamUserForm-name"
                      onAvailabilityChecked={(isAvailable): void => {
                        // グループ名の利用可否に応じてグループ名のエラーメッセージを設定する
                        const newErrors = Object.assign({}, errors);
                        if (isAvailable) {
                          newErrors.groupName = [];
                        } else {
                          newErrors.groupName = [I18n.t("group_name_unavailable", { scope: I18N_SCOPE })];
                        }
                        setErrors(newErrors);
                      }}
                      onChange={(value): void => setGroupName(value)}
                    />
                    <ErrorArea2 errors={errors.groupName} />
                  </td>
                </tr>
                <tr>
                  <th className="ca-group-form__table-header">
                    {I18n.t("activerecord.attributes.group.color")}
                    <span className="required">*</span>
                  </th>
                  <td>
                    <GroupColorSelector2
                      onChange={(color): void => {
                        setGroupColor(color);
                      }}
                      selectedColor={groupColor}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="ca-group-form-hr"></div>

          <h2 className="marginB0 font-18px">
            <span className="fa fa-key fa-lg"></span>
            {I18n.t("aws_account_heading", { scope: I18N_SCOPE })}
          </h2>

          <div className="ca-group-form">
            <div className="marginB20 ca-callout ca-callout--warning">
              <div
                dangerouslySetInnerHTML={{ __html: I18n.t("aws_account_description_html", { scope: I18N_SCOPE }) }}
              ></div>
              <div className="marginT10">
                <ManualLink
                  linkText={I18n.t("common.manual.text.aws_account.iam_user")}
                  url={I18n.t("common.manual.url.aws_account.iam_user")}
                />
              </div>
            </div>

            <div className="marginB20">
              <ProviderAccountTypeSelectorForGroup
                defaultValue="iam_user"
                groupColor={groupColor}
                groupName={groupName}
              />
            </div>

            <div className="ca-group__aws-account-form">
              <div className="ca-group__aws-account-form__row">
                <div className="ca-group__aws-account-form__left-col">
                  <div className="ca-group__aws-account-form__heading">{I18n.t("step1", { scope: I18N_SCOPE })}</div>
                </div>
                <div className="ca-group__aws-account-form__right-col">
                  <p className="ca-group__aws-account-form__prompt">
                    {I18n.t("credentials_prompt", { scope: I18N_SCOPE })}
                  </p>
                  <AccessKeyIdInputField
                    accessKeyId={awsAccountAccessKeyId}
                    disabled={false}
                    errors={errors.awsAccountAccessKeyId}
                    onChange={(value): void => {
                      setAwsAccountAccessKeyId(value);
                    }}
                  />
                  <SecretAccessKeyInputField
                    errors={errors.awsAccountSecretAccessKey}
                    disabled={false}
                    onChange={(value): void => {
                      setAwsAccountSecretAccessKey(value);
                    }}
                    secretAccessKey={awsAccountSecretAccessKey}
                  />
                  <AwsAccountNameInputField
                    awsAccountName={awsAccountName}
                    disabled={false}
                    errors={errors.awsAccountName}
                    onChange={(value): void => {
                      setAwsAccountName(value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="ca-btn-block marginT40">
            <div className="ca-btn-block__center">
              <SubmitButton
                disabled={!isSubmittable()}
                label={I18n.t("submit", { scope: I18N_SCOPE })}
                pending={isTransmitting}
                pendingLabel={I18n.t("pending", { scope: I18N_SCOPE })}
              />
              <ErrorArea2 errors={errors.base} />
              <ErrorArea2 errors={errors.awsAccounts} />
            </div>
          </div>
        </div>
      </form>
    </React.StrictMode>
  );
};
