import React from "react";

import { useToggle } from "react-use";

import { AwsAccount } from "./types";
import { EditAwsAccountWithIamRoleDialog } from "./EditAwsAccountWithIamRoleDialog";
import { EditAwsAccountWithIamUserDialog } from "./EditAwsAccountWithIamUserDialog";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.groups.edit_aws_account_button";

/**
 * AWSアカウント一覧画面で表示するAWSアカウント編集ボタン
 *
 * 以下の責務を持ちます。
 *
 * - 編集ボタンの表示
 * - IAMロール方式およびIAMユーザー方式に応じた編集ダイアログの出し分け
 * - 編集ダイアログの表示制御
 */
export const EditAwsAccountButton: React.FC<{
  awsAccount: AwsAccount;
  onUpdated: () => void;
}> = (props): JSX.Element => {
  /**
   * ダイアログの表示状態をあらわすステート
   */
  const [isDialogOpen, toggleDialog] = useToggle(false);

  return (
    <div className="inline-block">
      <EditButton onClick={(): void => toggleDialog(true)} />

      {props.awsAccount.isIamRole && (
        <EditAwsAccountWithIamRoleDialog
          awsAccount={props.awsAccount}
          isOpen={isDialogOpen}
          onCancel={(): void => toggleDialog(false)}
          onUpdated={(): void => {
            props.onUpdated();
            toggleDialog(false);
          }}
        />
      )}

      {!props.awsAccount.isIamRole && (
        <EditAwsAccountWithIamUserDialog
          awsAccount={props.awsAccount}
          isOpen={isDialogOpen}
          onCancel={(): void => toggleDialog(false)}
          onUpdated={(): void => {
            props.onUpdated();
            toggleDialog(false);
          }}
        />
      )}
    </div>
  );
};

/**
 * 編集ボタン
 */
const EditButton: React.FC<{
  onClick: () => void;
}> = (props): JSX.Element => {
  return (
    <button type="button" className="btn" onClick={props.onClick}>
      <span className="fa fa-pencil" />
      <span className="font-12px">{I18n.t("edit", { scope: I18N_SCOPE })}</span>
    </button>
  );
};
