import React from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";

const I18n = window.I18n;

/**
 * 後処理削除確認ダイアログ
 * 除外処理もこのコンポーネントで行います
 */
export default class DeletePostProcessDialog extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @property {object} emitter ダイアログの開閉をするイベントを発火するオブジェクト
   * @property {object} postProcess 後処理オブジェクト
   * @property {object} reloadEmitter 削除後に一覧をリロードするイベントを発火するオブジェクト
   * @property {bool} showDeletePostProcessDialog ダイアログの開閉状態を管理するプロパティ
   */
  static get propTypes() {
    return {
      emitter: PropTypes.object.isRequired,
      postProcess: PropTypes.object.isRequired,
      reloadEmitter: PropTypes.object.isRequired,
      showDeletePostProcessDialog: PropTypes.bool.isRequired,
    };
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);

    this.deleteButton = this.deleteButton.bind(this);

    ReactModal.setAppElement("body");
  }

  /**
   * ダイアログ内に表示するdeleteボタン
   */
  deleteButton() {
    const csrfToken = document.querySelector("meta[name='csrf-token']")?.getAttribute("content");
    return (
      <form className="inline-block" method="post" action={`${this.props.postProcess.url}`}>
        <input type="hidden" name="_method" value="delete" />
        <input type="hidden" name="authenticity_token" value={csrfToken || ""} />
        <button type="submit" className="btn btn-danger btn-delete">
          <span className="fa fa-trash-o marginR5" />
          Delete
        </button>
      </form>
    );
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return (
      <ReactModal
        isOpen={this.props.showDeletePostProcessDialog}
        onRequestClose={() => {
          this.props.emitter.emit("closeDialog");
        }}
        overlayClassName="ca-delete-post-process-modal__overlay"
        className="ca-delete-post-process-modal__content"
        role="dialog"
      >
        <div className="ca-delete-post-process-modal__content-message">
          {this.render_assigned_warning()}
          <p>{I18n.t("delete_confirmation", { scope: "javascript.post_process_table" })}</p>
          <p>
            <strong>{this.props.postProcess.name}</strong>
          </p>
        </div>

        <div className="ca-delete-post-process-modal__content-buttons">
          <button
            type="button"
            className="btn btn-cancel btn-negative"
            onClick={() => {
              this.props.emitter.emit("closeDialog");
            }}
          >
            <span className="fa fa-ban marginR5" />
            Cancel
          </button>
          {this.deleteButton()}
        </div>
      </ReactModal>
    );
  }

  /**
   * 後処理にジョブ・ポリシーが割当られているときの警告メッセージを返します
   */
  render_assigned_warning() {
    const postProcess = this.props.postProcess;
    if (postProcess.jobs_count <= 0 && postProcess.policies_count <= 0) {
      return;
    }

    return (
      <p className="text-danger">
        {I18n.t("delete_confirmation_with_assigns", { scope: "javascript.post_process_table" })}
      </p>
    );
  }
}
