import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";

import { showErrorToast, showSuccessToast } from "../../toast";

const $ = window.jQuery;
const I18n = window.I18n;
const I18N_SCOPE = "javascript.group_user.edit_group_user_modal";

export default class EditUserDialog extends React.Component {
  /**
   * プロパティ定義を返します
   *
   * @public
   * @property {boolean} dateLabelFeatureEnabled 組織に対してカレンダー機能の機能フラグが有効かどうか
   * @property {object} emitter ダイアログの開閉をするイベントを発火するオブジェクト
   * @property {number} groupId 編集対象グループ
   * @property {boolean} inventoryAvailable 組織がインベントリ機能を利用可能かどうか
   * @property {boolean} policySetsAvailable 組織が構成レビュー機能を利用可能かどうか
   * @property {object} reloadEmitter グループ一覧をリロードするイベントを発火するオブジェクト。追加処理後にイベントを発火し、グループ一覧を更新します
   * @property {bool} showEditUserDialog メンバー編集ダイアログの開閉を制御します。trueの時ダイアログが表示されます
     @property {object} user ユーザー情報が入ったオブジェクト
   */
  static get propTypes() {
    return {
      dateLabelFeatureEnabled: PropTypes.bool.isRequired,
      emitter: PropTypes.object.isRequired,
      groupId: PropTypes.number.isRequired,
      inventoryAvailable: PropTypes.bool.isRequired,
      policySetsAvailable: PropTypes.bool.isRequired,
      reloadEmitter: PropTypes.object.isRequired,
      showEditUserDialog: PropTypes.bool.isRequired,
      user: PropTypes.object.isRequired
    };
  }

  /**
   * コンストラクタ
   *
   * ステート
   * jobPermission - ユーザーのジョブ操作権限を保持します
   * policySetPermission - ユーザーのポリシーセット操作権限を保持します
   */
  constructor(props) {
    super(props);

    this.state = {
      dateLabelPermission: this.props.user.calendar_permission,
      jobPermission: this.props.user.job_permission,
      policySetPermission: this.props.user.policy_set_permission,
      inventoryPermission: this.props.user.inventory_permission
    };

    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleUpdateCalendarPermission = this.handleUpdateCalendarPermission.bind(
      this
    );
    this.handleUpdateInventoryPermission = this.handleUpdateInventoryPermission.bind(
      this
    );
    this.handleUpdateJobPermission = this.handleUpdateJobPermission.bind(this);
    this.handleUpdatePolicySetPermission = this.handleUpdatePolicySetPermission.bind(
      this
    );
    this.updateGroupUser = this.updateGroupUser.bind(this);

    ReactModal.setAppElement("body");
  }

  /*
   * render メソッド内で利用するハンドラーメソッド
   */
  handleCloseDialog(event) {
    this.props.emitter.emit("closeDialog");
  }

  /*
   * render メソッド内で利用するハンドラーメソッド
   * カレンダー管理機能権限を操作するselectのonChangeで利用する
   */
  handleUpdateCalendarPermission(event) {
    this.setState({ dateLabelPermission: event.target.value });
  }

  /*
   * render メソッド内で利用するハンドラーメソッド
   * インベントリ権限を操作するselectのonChangeで利用する
   */
  handleUpdateInventoryPermission(event) {
    this.setState({ inventoryPermission: event.target.value });
  }

  /*
   * render メソッド内で利用するハンドラーメソッド
   * ジョブ権限を操作するselectのonChangeで利用する
   */
  handleUpdateJobPermission(event) {
    this.setState({ jobPermission: event.target.value });
  }

  /*
   * render メソッド内で利用するハンドラーメソッド
   * ポリシーセット権限を操作するselectのonChangeで利用する
   */
  handleUpdatePolicySetPermission(event) {
    this.setState({ policySetPermission: event.target.value });
  }

  /**
   * 指定のグループメンバーの権限を更新します
   * 更新後はダイアログのクローズとグループメンバー一覧のリロードを行います
   */
  updateGroupUser() {
    const url = `/groups/${this.props.groupId}/user_assignments/${
      this.props.user.id
    }.json`;

    $.ajax({
      type: "PUT",
      url: url,
      data: {
        calendar_permission: this.state.dateLabelPermission,
        job_permission: this.state.jobPermission,
        policy_set_permission: this.state.policySetPermission,
        inventory_permission: this.state.inventoryPermission
      }
    })
      .done(() => {
        this.props.emitter.emit("closeDialog");
        this.props.reloadEmitter.emit("reloadGroupUsers");
        showSuccessToast(I18n.t("succeeded", { scope: I18N_SCOPE }));
      })
      .fail((jqXHR) => {
        this.props.emitter.emit("closeDialog");
        const error = `status=${jqXHR.status} statusText=${jqXHR.statusText}`;
        showErrorToast(I18n.t("failed", { error: error, scope: I18N_SCOPE }));
      });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    const options = { scope: I18N_SCOPE };

    return (
      <ReactModal
        isOpen={this.props.showEditUserDialog}
        onRequestClose={this.handleCloseDialog}
        overlayClassName="ca-edit-group-user-modal__overlay"
        className="ca-edit-group-user-modal__content text-left"
        role="dialog"
      >
        <div className="marginB30">
          <h2>
            <span className="fa fa-users" />
            &nbsp;
            {I18n.t("title", options)}
          </h2>
        </div>
        <div>
          <div className="marginB10 ca-edit-group-user-modal__content-form">
            <div className="ca-edit-group-user-modal__content-label">
              <span>{I18n.t("trigger_job", options)}</span>
            </div>

            <div className="ca-edit-group-user-modal__content-select">
              <select
                className="form-control inline-block"
                onChange={this.handleUpdateJobPermission}
                defaultValue={this.state.jobPermission}
              >
                <option value="read_write">
                  {I18n.t("job_read_write", options)}
                </option>
                <option value="read_only">
                  {I18n.t("read_only", options)}
                </option>
              </select>
            </div>
          </div>

          {this.props.policySetsAvailable && (
            <div className="marginB10 ca-edit-group-user-modal__content-form">
              <div className="ca-edit-group-user-modal__content-label">
                <span>{I18n.t("policy_set", options)}</span>
              </div>
              <div className="ca-edit-group-user-modal__content-select">
                <select
                  className="form-control inline-block"
                  onChange={this.handleUpdatePolicySetPermission}
                  defaultValue={this.state.policySetPermission}
                >
                  <option value="read_write">{I18n.t("policy_set_read_write", options)}</option>
                  <option value="read_only">{I18n.t("read_only", options)}</option>
                </select>
              </div>
            </div>
          )}

          <div className="marginB10 ca-edit-group-user-modal__content-form">
            <div className="ca-edit-group-user-modal__content-label">
              <span>{I18n.t("inventory.workspaces", options)}</span>
            </div>

            <div className="ca-edit-group-user-modal__content-select">
              <select
                className="form-control inline-block ca-inventory-permission"
                onChange={this.handleUpdateInventoryPermission}
                defaultValue={this.state.inventoryPermission}
                disabled={!this.props.inventoryAvailable}
              >
                <option value="">
                  {I18n.t("inventory.workspaces_permission_null", options)}
                </option>
                {this.props.inventoryAvailable &&
                 <React.Fragment>
                  <option value="read_write">
                    {I18n.t("inventory.workspaces_read_write", options)}
                  </option>
                  <option value="read_only">
                    {I18n.t("inventory.workspaces_read_only", options)}
                  </option>
                 </React.Fragment>
                }
              </select>
              {!this.props.inventoryAvailable &&
               <p className="ca-add-group-user-modal__content__prompt marginT5">
                 {I18n.t("inventory.workspaces_permission_note", options)}
               </p>
              }
            </div>
          </div>

          {this.props.dateLabelFeatureEnabled &&
            <div className="marginB10 ca-edit-group-user-modal__content-form">
              <div className="ca-edit-group-user-modal__content-label">
                <span>{I18n.t("calendar", options)}</span>
              </div>

              <div className="ca-edit-group-user-modal__content-select">
                <select
                  className="form-control inline-block"
                  onChange={this.handleUpdateCalendarPermission}
                  defaultValue={this.state.dateLabelPermission}
                >
                  <option value="read_write">
                    {I18n.t("calendar_read_write", options)}
                  </option>
                  <option value="read_only">
                    {I18n.t("read_only", options)}
                  </option>
                </select>
              </div>
            </div>
          }

          <div className="marginT20 text-center">
            <button
              type="button"
              className="btn btn-primary btn-lg"
              onClick={this.updateGroupUser}
            >
              <span className="fa fa-check-circle" />
              {I18n.t("update", options)}
            </button>
          </div>
        </div>
      </ReactModal>
    );
  }
}
