// Reactコンポーネントのマウントを支援するモジュール

import React from "react";
import { createRoot } from "react-dom/client";

// Reactコンポーネントのマウント対象となる各ノードが持つ属性名(値はReactコンポーネントクラス名)
const CLASS_NAME_ATTR = "data-react-class";

// Reactコンポーネントのマウント対象となる各ノードが持つ属性名(値はReactコンポーネントに与えるプロパティのJSON)
const PROPS_ATTR = "data-react-props";

// Reactコンポーネントのマウント対象となるノードを取得するためのセレクタ
const NODE_SELECTOR = `[${CLASS_NAME_ATTR}]`;

/**
 * 指定されたDOMノードに設定された属性値を元にReactコンポーネントをマウントします。
 *
 * @param {DOMNode} node
 */
function mountReactComponent(node) {
  const className = node.getAttribute(CLASS_NAME_ATTR);
  const constructor = window[className] || eval.call(window, className);
  const propsJson = node.getAttribute(PROPS_ATTR);
  const props = propsJson && JSON.parse(propsJson);

  const root = createRoot(node);
  root.render(React.createElement(constructor, props));
}

/**
 * ページに存在するDOMノードのうち、属性値でReactコンポーネントが指定されているすべての
 * ノードに対してReactコンポーネントをマウントします。
 */
export default function mountReactComponents() {
  const domNodes = document.querySelectorAll(NODE_SELECTOR);
  for (const node of domNodes) {
    mountReactComponent(node);
  }
}
