import PropTypes from 'prop-types';
import React from 'react';

import Constants from './Constants.js';

const I18n = window.I18n; // i18n-js

/**
 * ダイアログオープンボタンコンポーネント。
 *
 * このコンポーネントは、パスワード設定のダイアログを開くボタンのルック＆フィールに
 * ついて責務を持ちます。
 */
export default class DialogOpenButton extends React.Component {
  /**
   * プロパティの定義を返します。
   *
   * @return {Object}
   * @property {Object} emitter EventEmitterオブジェクト
   */
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
    });
  }

  /**
   * コンストラクタ。
   *
   * @override
   */
  constructor(props) {
    super(props);

    this.buttonLabel = I18n.t('open_dialog_button', { scope: Constants.I18N_SCOPE });
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <button
        className={`
          btn
          btn-default
          ca-organization-user-account__button
          ca-organization-user-account__button--${I18n.locale}
        `}
        onClick={() => this.props.emitter.emit(Constants.EVENT_OPEN_DIALOG)}
      >{this.buttonLabel}</button>
    );
  }
}
