import React, { useState } from "react";

import alertMessage from "../AlertMessage.js";

import { ToggleableJob } from "./types";
import { ToggleJobModal } from "./ToggleJobModal";

const I18n = window.I18n; // i18n-js

/**
 * 手動トリガージョブの「今すぐ実行」ボタンおよび確認ダイアログ
 */
export const RunJobButton: React.FC<{
  /** 対象のジョブ**/
  job: ToggleableJob;
  /** 対象のジョブが所属するジョブワークフローが実行中かどうか **/
  isOngoingLogExists?: boolean;
}> = (props) => {
  const scope = "javascript.job_table.run_job_button";
  const labelText = I18n.t("label", { scope: scope });
  const confirmationText = I18n.t("confirmation", { scope: scope });
  const ongoingLogExistsText = I18n.t("ongoing_log_exists", { scope: scope });
  const buttonDisabled = props.isOngoingLogExists ?? false;

  const [dialogOpened, setDialogOpened] = useState(false);

  return (
    <>
      <button
        className={
          buttonDisabled
            ? "btn btn-warning ca-control-button__run-now ca-job_workflows__workflow-button--disabled"
            : "btn btn-warning ca-control-button__run-now"
        }
        onClick={(): void => {
          if (props.isOngoingLogExists) {
            alertMessage(ongoingLogExistsText);
          } else {
            setDialogOpened(true);
          }
        }}
      >
        <i aria-hidden className="far fa-play-circle"></i> {labelText}
      </button>

      <ToggleJobModal
        dialogMessage={confirmationText}
        job={props.job}
        onCancelButtonClick={(): void => setDialogOpened(false)}
        showDialog={dialogOpened}
      />
    </>
  );
};
