import React from "react";
import PropTypes from "prop-types";

// 送信ボタン
//
// pending プロパティが true の場合は送信中の表示になります。
//
export class SubmitButton extends React.Component {
  static get defaultProps() {
    return {
      disabled: false,
      pending: false,
    };
  }

  static get propTypes() {
    return {
      disabled: PropTypes.bool,
      label: PropTypes.string.isRequired,
      pending: PropTypes.bool,
      pendingLabel: PropTypes.string.isRequired,
    };
  }

  render() {
    if (this.props.pending) {
      return this.renderPendingButton();
    }
    return this.renderButton();
  }

  /**
   * 通常表示のボタンを返します。
   */
  renderButton() {
    const buttonClass = this.props.disabled ? "btn-default" : "btn-primary";

    return (
      <button className={`btn ${buttonClass} btn-lg btn-continue`} disabled={this.props.disabled} type="submit">
        <span className="fa fa-check-circle fa-lg"></span>&nbsp;
        {this.props.label}
      </button>
    );
  }

  /**
   * 送信中表示のボタンを返します。
   */
  renderPendingButton() {
    return (
      <button className={`btn btn-success btn-lg btn-continue`} disabled={true} type="submit">
        {this.props.pendingLabel}
      </button>
    );
  }
}
