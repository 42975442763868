import React from 'react';
import PropTypes from 'prop-types';

import DashboardSpinner from './DashboardSpinner.jsx';
import GroupIconTip from './GroupIconTip.jsx';
import withBootstrapTooltip from './withBootstrapTooltip.js';

const $ = window.jQuery;
const _ = window._; // Underscore.js
const I18n = window.I18n; // i18n-js

// ポリシーセット一覧のテーブルに表示される列の数
const TABLE_COLUMNS = 6;

/**
 * ポリシーセット一覧コンポーネント
 *
 * /policy_sets.json から取得したポリシーセットの情報をテーブルで一覧表示します。
 * ポリシーセットに処理完了待ち(current_stateがavailableまたはunavailable以外)のものが
 * 含まれる場合は、それらが無くなるまで10秒毎にJSONの再取得および再表示を行います。
 *
 * プロパティ
 * newPolicySetPath - ポリシーセットを新規作成するためのURLパス
 *
 * ステート
 * policySets - ポリシーセットをあらわすオブジェクトの配列
 *
 * policySetsの構造は policy_sets#index のJSONレスポンスを参照。
 */
export default class PolicySetTableContainer extends React.Component {
  /**
   * propTypes
   * @return {object}
   * @property {?string} newPolicySetPath ポリシーセットを作成するボタンのリンク先
   */
  static get propTypes() {
    return({
      newPolicySetPath: PropTypes.string
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);
    this.initializeState();
  }

  /**
   * @override
   */
  componentDidMount() {
    this.loadPolicySets();
  }

  /**
   * @override
   */
  componentWillUnmount() {
    if (this.state.intervalID != null) {
      // JSONの自動更新が有効なままの場合はアンマウント前にclearIntervalを行う
      clearInterval(this.state.intervalID);
    }
  }

  /**
   * ステートを初期化します。
   */
  initializeState() {
    this.state = {
      errorMessage: null,
      intervalID: null, // JSONの自動更新が有効な間はIntervalIDがセットされる
      policySets: []
    };
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <React.StrictMode>
        <div className="clearfix">
          {this.props.newPolicySetPath &&
            <NewPolicySetButton path={this.props.newPolicySetPath} />
          }
          <DashboardSpinner />
          <PolicySetTable
            policySets={this.state.policySets}
            errorMessage={this.state.errorMessage}
          />
        </div>
      </React.StrictMode>
    );
  }

  /**
   * サーバーからポリシーセットの情報をJSONで取得してステートに設定します。
   *
   * 取得したポリシーに変更反映中のものがある場合は10秒毎に自身を繰り返し呼び出します。
   */
  loadPolicySets() {
    // 自動更新を行う必要がある場合のインターバル(ミリ秒)
    const autoReloadIntervalMsec = 10000;

    $.ajax({
      url: "/policy_sets.json"
    }).done((data) => {
      // レスポンスのJSONフォーマットをチェックする
      const requiredKeys = ['policy_sets'];
      let invalidFormat = false;
      requiredKeys.forEach((key) => {
        if (data[key] == undefined) {
          invalidFormat = true;
        }
      });

      if (invalidFormat) {
        this.setState({
          currentPage: 1,
          policySets: [],
          errorMessage: I18n.t('javascript.job_table.invalid_server_response'),
          intervalID: null
        });
        return;
      }

      // 処理完了待ちのポリシーセットが存在するかどうか
      const ongoingPolicySetExists = !_.chain(data.policy_sets)
        .pluck('current_state')
        .without('available', 'unavailable')
        .isEmpty()
        .value();

      // ステートに新しいポリシーセットを設定した後で自動更新の制御を行う
      this.setState({
        policySets: data.policy_sets
      }, () => {
        if (ongoingPolicySetExists) {
          // 処理完了待ちのポリシーセットが存在している
          if (this.state.intervalID == null) {
            // 自動更新が無効の場合は自動更新を開始する
            const id = setInterval(this.loadPolicySets.bind(this), autoReloadIntervalMsec);
            this.setState({ intervalID: id });
          }
        } else {
          // 処理完了待ちのポリシーセットが存在していない
          if (this.state.intervalID != null) {
            // 自動更新中の場合は、自動更新を終了する
            clearInterval(this.state.intervalID);
            this.setState({ intervalID: null });
          }
        }
      });
    }).fail(() => {
      this.setState({
        policySets: [],
        errorMessage: I18n.t('javascript.policy_set_table.load_failed'),
        intervalID: null
      });
    });
  }
}

/**
 * ポリシーセット作成ボタン
 */
class NewPolicySetButton extends React.Component{
  /**
   * propTypes
   * @return {object}
   * @property {string} path リンク先URLまたはパス
   */
  static get propTypes() {
    return({
      path: PropTypes.string.isRequired
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);
    this.titleText = I18n.t('policy_sets.new.title');
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <a
        className="btn btn-highlight marginB10"
        href={this.props.path}
        type="button"
      >
        <i className="fa fa-plus-square marginR5" aria-hidden="true"></i>
        <span>{this.titleText}</span>
      </a>
    );
  }
}

/**
 * ポリシーセット一覧テーブル
 *
 * 複数のポリシーセットを一覧するテーブルのコンポーネントです。
 */
const PolicySetTable = withBootstrapTooltip(class PolicySetTable extends React.Component {
  /**
   * propTypes
   * @return {object}
   * @property {object[]} policySets ポリシーセットの配列
   * @property {string|null} errorMessage 表示するエラーメッセージ
   * @property {function(element: DOMNode)} tooltipRef
   */
  static get propTypes() {
    return({
      policySets: PropTypes.arrayOf(PropTypes.object),
      errorMessage: PropTypes.any,
      tooltipRef: PropTypes.func,
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);
    const options = { scope: "activerecord.attributes.policy_set" };
    this.nameText = I18n.t("name", options);
    this.templateNameText = I18n.t("javascript.policy_set_table.policy_set_template");
    this.awsAccountNameText = I18n.t("aws_account", options);
    this.currentStateText = I18n.t("current_state", options);
    this.noPolicySetsText = I18n.t("javascript.policy_set_table.no_policy_sets");
    this.regionText = I18n.t("javascript.policy_set_table.region");
  }

  /**
   * @return {ReactElement}
   */
  render() {
    // THEADタグ内のTHを増減する場合は TABLE_COLUMNS 定数も更新すること
    return(
      <table
        className="table vertical-middle ca-table-header-no-bordered qa-policy-set-table"
        id="table-policy-set"
        ref={this.props.tooltipRef}
        style={{ tableLayout: 'fixed' }}
      >
        <thead>
          <tr>
            <th style={{ whiteSpace: 'nowrap', width: 'auto' }}>{this.nameText}</th>
            <th style={{ whiteSpace: 'nowrap', width: 'auto' }}>{this.awsAccountNameText}</th>
            <th style={{ whiteSpace: 'nowrap', width: 'auto' }}>{this.regionText}</th>
            <th style={{ whiteSpace: 'nowrap', width: 'auto' }}>{this.templateNameText}</th>
            <th style={{ whiteSpace: 'nowrap', width: 'auto' }}>{this.currentStateText}</th>
            <th style={{ whiteSpace: 'nowrap', width: '160px' }}></th>
          </tr>
        </thead>
        <tbody>
          {this.renderRows()}
        </tbody>
      </table>
    );
  }

  /**
   * テーブル行を返します。
   * @return {ReactElement|ReactElement[]}
   */
  renderRows() {
    const errorMessage = this.props.errorMessage;
    const isPolicySetsExist = (errorMessage == null && this.props.policySets.length > 0);

    if (!isPolicySetsExist) {
      return(
        <tr key="0">
          <td colSpan={TABLE_COLUMNS}>
            {this.props.errorMessage || this.noPolicySetsText}
          </td>
        </tr>
      );
    }

    return this.props.policySets.map((policySet) => {
      // ポリシーセットの状態が変わった場合にコンポーネントが再利用されると表示内容が更新されないため
      // キーの値にはIDと状態を含める
      const key = `${policySet.id}-${policySet.current_state}`;
      return(
        <PolicySetRow
          policySet={policySet}
          key={key}
        />
      );
    });
  }
});

/**
 * ポリシーセットテーブル行
 *
 * 1件のポリシーセットに対応するテーブル行のコンポーネントです。
 */
class PolicySetRow extends React.Component {
  /**
   * propTypes
   * @return {object}
   * @property {object} policySet ポリシーセットをあらわすオブジェクト
   * @property {string} policySet.aws_account_name
   * @property {string} policySet.current_state
   * @property {string} policySet.group グループ
   * @property {string} policySet.name ポリシーセット名
   * @property {string} policySet.region リージョン識別子 (例: us-east-1)
   * @property {string} policySet.template_name_en
   * @property {string} policySet.template_name_ja
   */
  static get propTypes() {
    return({
      policySet: PropTypes.object.isRequired
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);

    this.state = {
      policySet: this.props.policySet
    };

    const options = { scope: "javascript.policy_set_table" };
    this.deleteText = I18n.t('delete', options);
    this.editText = I18n.t('edit', options);
    this.logsText = I18n.t('logs', options);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const policySet = this.state.policySet;
    const unavailable = policySet.current_state === 'unavailable';
    const regionName = I18n.t(
      policySet.region,
      {
        // 翻訳メッセージが見つからない場合はリージョン識別子をそのまま表示する
        defaultValue: policySet.region,
        scope: "common.aws.regions",
      }
    );
    const buttons = [
      this.renderEditButton(),
      this.renderLogsButton(),
      this.renderDeleteButton(),
    ].filter(b => b !== null);

    return(
      <tr>
        <td>
          <GroupIconTip group={policySet.group} />
          {policySet.url ?
            <a href={policySet.url}>{policySet.name}</a> :
            policySet.name
          }
        </td>
        <td>
          {policySet.aws_account_name}
        </td>
        <td>
          {regionName}
        </td>
        <td>
          {I18n.locale == "ja" ?
            policySet.template_name_ja :
            policySet.template_name_en}
        </td>
        <td>
          {policySet.current_state}
          {this.renderOngoingIndicator()}
        </td>
        <td>
          {buttons.length > 0 &&
            <div className={unavailable ? 'btn-group marginB5' : 'btn-group'}>
              {buttons}
            </div>
          }
          {unavailable && <SupportLink />}
        </td>
      </tr>
    );
  }

  /**
   * 必要に応じて削除ボタンを返します。
   * @return {?ReactElement}
   */
  renderDeleteButton() {
    const url = this.state.policySet.delete_url;
    if (!url) {
      return null;
    }

    return(
      <a
        className="btn btn-default"
        data-container="body"
        data-placement="bottom"
        data-toggle="tooltip"
        data-qa="policy-set-delete-button"
        href={url}
        key="delete"
        title={this.deleteText}
      >
        <span className="fa fa-trash-o"></span>
      </a>
    );
  }

  /**
   * 必要に応じて編集ボタンを返します。
   * @return {?ReactElement}
   */
  renderEditButton() {
    const url = this.state.policySet.edit_url;
    if (!url) {
      return null;
    }

    return(
      <a
        className="btn btn-secondary"
        data-container="body"
        data-placement="bottom"
        data-toggle="tooltip"
        href={url}
        key="edit"
        title={this.editText}
      >
        <span className="fa fa-pencil-square-o"></span>
      </a>
    );
  }

  /**
   * 必要に応じてログボタンを返します。
   * @return {?ReactElement}
   */
  renderLogsButton() {
    const url = this.state.policySet.logs_url;
    if (!url) {
      return null;
    }

    return(
      <a
        className="btn btn-secondary"
        data-container="body"
        data-placement="bottom"
        data-toggle="tooltip"
        href={url}
        key="logs"
        title={this.logsText}
      >
        <span className="fa fa-bar-chart-o"></span>
      </a>
    );
  }

  /**
   * 必要に応じて変更反映中のインジケーターを返します。
   * @return {?ReactElement}
   */
  renderOngoingIndicator() {
    const currentState = this.state.policySet.current_state;
    if (currentState == 'available' || currentState == 'unavailable') {
      return null;
    }

    return(<i className="fa fa-spinner fa-spin marginL5"></i>);
  }
}

/**
 * お問い合わせくださいリンク
 * クリックするとUserVoiceのウィジェットをcontactモードで開きます。
 *
 * A要素に指定している data-uv-* 属性値については以下のUserVoiceのドキュメントを参照してください。
 * https://developer.uservoice.com/docs/widgets/options/
 */
class SupportLink extends React.Component {
  /**
   * @override
   */
  constructor(props) {
    super(props);
    this.linkText = I18n.t('javascript.policy_set_table.contact_to_support');
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <a
        data-uv-trigger
        data-uv-mode="contact"
        data-uv-target="false"
        href="#"
        style={{ display: 'block', textDecoration: 'underline' }}
      >
        {this.linkText}
      </a>
    );
  }
}
