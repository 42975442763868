/**
 * TODO: 機能フラグ v27_job_workflow_improvement が不要になったらこのコンポーネントを削除する
 */

import React from "react";
import Constants from "./Constants.js";
import { TriggerJob } from "./types";

const I18n = window.I18n; // i18n-js

/**
 * 旧先頭ジョブの移行に関するメッセージを表示するコンポーネント
 * job.action_type が "no_action" の場合のみ表示されます
 */
export const OldFirstJobMigrationMessage: React.FC<{ job: TriggerJob }> = (props): JSX.Element | null => {
  if (props.job.action_type == "no_action") {
    return null;
  }

  return (
    <div className="ca-workflow-trigger__item">
      <div className="marginB5">
        <a
          href={I18n.t("common.manual.url.about.job_workflow_old_first_job_migration")}
          target="_blank"
          rel="noreferrer"
          className="ca-workflow-trigger__old-first-job__msg"
        >
          <i className="fa-solid fa-arrow-up-right-from-square marginR5"></i>
          <span>{I18n.t("old_first_job_migration_warning", { scope: Constants.I18N_SCOPE })}</span>
        </a>
      </div>
    </div>
  );
};
