import React from 'react';
import PropTypes from 'prop-types';

const I18n = window.I18n; // i18n-js
const I18N_SCOPE = { scope: 'javascript.group_icon' };


// グループ名表示コンポーネント
//
// プロパティ:
// group  - グループオブジェクト。指定されない場合はグループ共通として表示します
//
class GroupIconName extends React.Component {
  static get propTypes() {
    return({
      group: PropTypes.object,
    });
  }

  render() {
    let class_name, color, name;

    if (this.props.group) {
      class_name = "ca-group-icon__icon fa fa-briefcase";
      color = { color: this.props.group.color };
      name = this.props.group.name;
    } else {
      class_name = "ca-group-icon__icon fa fa-sitemap";
      color = { color: "#919191" };
      name = I18n.t("shared", I18N_SCOPE);
    }

    return(
      <React.StrictMode>
        <span className="ca-group-icon">
          <i
            aria-hidden
            className={class_name}
            style={color}
          ></i>
          {name}
        </span>
      </React.StrictMode>
    );
  }
}

export default GroupIconName;
