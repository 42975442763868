import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon.jsx';
import SupportLink from '../SupportLink.jsx';
import UserAvatar from '../UserAvatar.jsx';

import Constants from './Constants.js';
import DeleteButton from './DeleteButton.jsx';
import DownloadButton from './DownloadButton.jsx';

const I18n = window.I18n; // i18n-js

/**
 * リスト作成リクエストテーブル行
 */
export default class RequestTableRow extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   */
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
      generateRequest: PropTypes.object.isRequired,
      isReadOnly: PropTypes.bool.isRequired,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.handleClickDeleteButton = this.handleClickDeleteButton.bind(this);

    this.hintForFailedText = I18n.t('hint_for_failed', { scope: Constants.I18N_SCOPE });
    this.tooltipCompletedText = I18n.t('tooltip_completed', { scope: Constants.I18N_SCOPE });
    this.tooltipCreatingText = I18n.t('tooltip_creating', { scope: Constants.I18N_SCOPE });
    this.tooltipDeletingText = I18n.t('tooltip_deleting', { scope: Constants.I18N_SCOPE });
    this.tooltipFailedText = I18n.t('tooltip_failed', { scope: Constants.I18N_SCOPE });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    const genreq = this.props.generateRequest;

    return(
      <tr>
        <td className="ca-workspaces-lists-table__cell text-center">
          {genreq.serial_number}
        </td>
        <td className="ca-workspaces-lists-table__cell ca-workspaces-lists-table__start-at">
          <UserAvatar user={genreq.creator} />
          <span className="marginL5">
            {genreq.created_at}
          </span>
        </td>
        <td className="ca-workspaces-lists-table__cell">
          {genreq.aws_account_name}
        </td>
        <td className="ca-workspaces-lists-table__cell">
          {I18n.t(genreq.region, { scope: 'common.aws.regions', defaultValue: genreq.region })}
        </td>
        <td className="ca-workspaces-lists-table__cell text-center">
          {this.renderStateAndResultIcon(genreq)}
        </td>
        <td className="ca-workspaces-lists-table__cell ca-workspaces-lists-table__buttons text-right">
          {genreq.state == 'completed' && genreq.result == 'succeeded' &&
            <React.Fragment>
              <DownloadButton url={genreq.download_url} />
              &nbsp;
              {!this.props.isReadOnly &&
                <DeleteButton
                  generateRequestId={genreq.id}
                  onClick={this.handleClickDeleteButton}
                  serialNumber={genreq.serial_number}
                />
              }
            </React.Fragment>
          }
          {genreq.state == 'completed' && genreq.result == 'failed' &&
            <SupportLink />
          }
        </td>
      </tr>
    );
  }

  /**
   * 削除ボタンがクリックされた際の処理を行います。
   *
   * @private
   * @param {number} generateRequestId リスト作成リクエストID
   */
  handleClickDeleteButton(generateRequestId) {
    this.props.emitter.emit(Constants.EVENT_DELETE_LIST, generateRequestId);
  }

  /**
   * リスト作成リクエストの状態および結果をあらわすアイコンを返します。
   *
   * @private
   * @param {Object} genreq リスト作成リクエスト
   */
  renderStateAndResultIcon(genreq) {
    if (genreq.state == 'completed') {
      if (genreq.result == 'failed') {
        return this.renderFailedResultIcon(genreq);
      }
      return <Icon name="check" tooltip={this.tooltipCompletedText} />;
    }

    if (genreq.state == 'deleting') {
      return <Icon name="clock-o" tooltip={this.tooltipDeletingText} />;
    }

    return <Icon name="clock-o" tooltip={this.tooltipCreatingText} />;
  }

  /**
   * 失敗時の結果をあらわすアイコンを返します。
   *
   * @private
   * @param {Object} genreq リスト作成リクエスト
   */
  renderFailedResultIcon(genreq) {
    let tooltipFailedText = this.tooltipFailedText;

    if (genreq.metadata?.error_code) {
      const error_code = genreq.metadata.error_code;
      const options = {
        scope: Constants.I18N_SCOPE,
        defaultValue: genreq.metadata.error_code,
      };

      tooltipFailedText = `${tooltipFailedText}: ${I18n.t(error_code, options)}`;
    }

    return <Icon name="exclamation-triangle" tooltip={tooltipFailedText} />;
  }
}
