import jQuery from "jquery";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactModal from "react-modal";

import { OnDemandRunnableJob } from "./types";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.run_on_demand";

/**
 * オンデマンド実行のダイアログです
 *
 * このコンポーネント内でサーバーにオンデマンド実行のリクエストを送ります
 */
const RunJobOnDemandDialog: React.FC<{
  job: OnDemandRunnableJob, // 対象のジョブ
  onClose: () => void, // ダイアログを閉じる際に呼び出されるコールバック
  showRunOnDemandDialog: boolean, // ダイアログが表示されているかどうか
}> = (props) => {
  // オンデマンド実行のXHRが失敗した場合のエラーメッセージ
  const [errorMessage, setErrorMessage] = useState("");

  const confirmText = useMemo(() => I18n.t("confirm", { scope: I18N_SCOPE }), [I18n.locale]);
  const cautionText = useMemo(() => I18n.t("caution", { scope: I18N_SCOPE }), [I18n.locale]);
  const idText = useMemo(
    () => I18n.t(props.job.for_workflow ? "job_workflow_id" : "job_id", { scope: I18N_SCOPE }),
    [I18n.locale]
  );

  const truncatedName = props.job.name.length > 50 ? props.job.name.slice(0, 50) + "..." : props.job.name;

  // ダイアログを閉じる操作が行われた際のコールバック
  const handleCloseRequest = useCallback(() => {
    props.onClose();
    setErrorMessage("");
  }, [props.onClose]);

  // OKボタンが押された際のコールバック
  const handleOkClick = useCallback(() => {
    jQuery.ajax({
      url: props.job.run_on_demand_url,
      method: "POST",
      dataType: "json",
      headers: { "X-CSRF-Token": jQuery("meta[name='csrf-token']").attr("content") },
    }).then(
      (data) => {
        props.onClose();
        const message = `<div id='js-alert-message' class='alert alert-success alert-dismissable'>
                         <button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;</button>
                         <span class='success-color alert-success__icon'><i class="fa fa-check-circle" aria-hidden="true"></i></span>
                         ${data.message}
                      </div>`;
        jQuery("#js-flash-container").prepend(message);
      },
      (data) => {
        setErrorMessage(data.responseJSON.message);
      }
    );
  }, [props.job.run_on_demand_url]);

  useEffect(() => {
    ReactModal.setAppElement("body");
  }, []);

  return(
    <ReactModal
      className="ca-run-on-demand-modal__content"
      isOpen={props.showRunOnDemandDialog}
      onRequestClose={handleCloseRequest}
      overlayClassName="ca-run-on-demand-modal__overlay"
      role="dialog"
    >
      <div>
        <p>
          {confirmText}
        </p>
        <p className="ca-run-on-demand-modal__content__job-id">
          <strong>
            {idText}:{props.job.id}
          </strong>
        </p>
        <p>
          <strong>{truncatedName}</strong>
        </p>
        <ul className="ca-run-on-demand-modal__caution">
          {props.job.today_is_date_to_skip && (
            <li className="ca-run-on-demand-modal__caution__msg">
              <TodayIsDateToSkipCaution />
            </li>
          )}
          <li className="ca-run-on-demand-modal__caution__msg">{cautionText}</li>
          {props.job.show_downtime_caution && (
            <li className="ca-run-on-demand-modal__caution__msg">
              <DowntimeCaution />
            </li>
          )}
        </ul>
        {errorMessage &&
          <p className="ca-run-on-demand-modal__content__error">
            <label className="error">
              {errorMessage}
            </label>
          </p>
        }
      </div>
      <div className="ca-run-on-demand-modal__content-buttons">
        <CancelButton onClick={handleCloseRequest} />
        <OkButton onClick={handleOkClick} />
      </div>
    </ReactModal>
  );
};

const CancelButton: React.FC<{
  onClick: () => void,
}> = (props) => {
  return (
    <button
      className="btn btn-cancel btn-negative"
      onClick={props.onClick}
      type="button"
    >
      <span className="fa fa-ban marginR5"></span>Cancel
    </button>
  );
};

const OkButton: React.FC<{
  onClick: () => void,
}> = (props) => {
  return (
    <button
      className="btn btn-danger btn-delete"
      onClick={props.onClick}
      type="button"
    >
      <span className="fa fa-check marginR5"></span>OK
    </button>
  );
};

const TodayIsDateToSkipCaution: React.FC = () => {
  const cautionText = useMemo(() => I18n.t("today_is_date_to_skip_caution", { scope: I18N_SCOPE }), [I18n.locale]);
  return (
    <>
      {cautionText}
      <br />
    </>
  );
};

const DowntimeCaution: React.FC = () => {
  const downtimeCautionText = useMemo(() => I18n.t("downtime_caution", { scope: I18N_SCOPE }), [I18n.locale]);
  return <>{downtimeCautionText}</>;
};

export default RunJobOnDemandDialog;
