/**
 * Reactコンポーネント用定数
 */
export default {
  URL: {
    GROUPS_SOURCE: "/groups.json",
    JOB_DASHBOARD: "/",
    NEW_GROUP_WITH_IAM_ROLE: "/groups/new_with_iam_role",
    NEW_JOB: "/jobs/new",
    NEW_POST_PROCESS: "/post_processes/new",
    PLAN_EDIT: "/plan/edit",
    POLICY_LOGS: "/policy_logs",
    POST_PROCESSES_SOURCE: "/post_processes.json",
    RESOURCE_DASHBOARD: "/resources",
    RESOURCES_SOURCE: "/aws_resources.json",
    TRIGGER_LOGS: "/logs",
  },
  DIRECTION: {
    ASC: "asc",
    DESC: "desc",
  },
  // ダッシュボードテーブルの各カラムとデータベースのカラム名の対応
  COLUMN: {
    LAST_UPDATED: "last_modified_at",
    NAME: "name",
    STATUS: "active",
    TRIGGER: "rule_type",
    REGION: "region",
    RESOURCE_ID: "identifier",
  },
  // リソース種別の識別子(RESOURCES_SOURCEへのリクエストパラメーターとなる)
  RESOURCE_TYPE: {
    EC2_INSTANCE: "ec2_instance",
    EC2_VOLUME: "ec2_volume",
    EC2_SNAPSHOT: "ec2_snapshot",
    EC2_IMAGE: "ec2_image",
    ELB_LOAD_BALANCER: "elb_load_balancer",
    RDS_DB_INSTANCE: "rds_db_instance",
    RDS_DB_SNAPSHOT: "rds_db_snapshot",
    REDSHIFT_CLUSTER: "redshift_cluster",
    ROUTE53_HOSTED_ZONE: "route53_hosted_zone",
  },
  RULE_TYPE: {
    AMAZON_SNS: "amazon_sns",
    CRON: "cron",
    IMMEDIATE_EXECUTION: "immediate_execution",
    SCHEDULE: "schedule",
    SQS_V2: "sqs_v2",
    WEBHOOK: "webhook",
  },
  // クッキーに保存する状態値のキー
  COOKIE: {
    EBS_BACKUP_EXISTS_SETTINGS: "ebs_backup_exists_setting",
    EBS_BACKUP_NOT_EXISTS_SETTINGS: "ebs_backup_not_exists_setting",
    GROUP_SETTINGS: "group_settings",
    GROUP_USER_SETTINGS: "group_user_settings",
    JOB_DASHBOARD_SETTINGS: "job_dashboard_settings",
    POST_PROCESS_POLICY_SETS_SETTINGS: "post_process_policy_sets_settings",
    POST_PROCESS_DASHBOARD_SETTINGS: "post_process_dashboard_settings",
    SELECTED_PAGINATION_PER_PAGE: "selected_pagination_per_page",
    SELECTED_RESOURCE_TYPE: "selected_resource_type",
    USER_GROUPS_SETTINGS: "user_groups_settings",
  },
  DETAILED_SEARCH_COLUMN: {
    ACTION: "action_type_name",
    AWS_ACCOUNT: "aws_account_id",
    GROUP: "group_id",
    STATUS: "active",
    TRIGGER: "rule_type",
    NAME: "name",
  },
  RUN_ON_DEMAND: {
    // オンデマンド実行が可能なトリガータイプ
    RULE_TYPES: [
      "cron",
      "schedule",
    ]
  }
};
