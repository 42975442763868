import PropTypes from 'prop-types';
import React from 'react';

import DetailLink from './DetailLink.jsx';
import ResultIcon from './ResultIcon.jsx';

/**
 * ポリシーログテーブル行コンポーネント。
 *
 * 1件のポリシーログ情報を1行のテーブル行として表示します。
 * 表示されるDOMはTRタグとなります。
 */
export default class PolicyLogTableRow extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {PolicyLog} policyLog ポリシーログ
   */
  static get propTypes() {
    return({
      policyLog: PropTypes.object.isRequired,
    });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    const log = this.props.policyLog;

    return(
      <tr>
        <td className="policy-logs-table__name-column">
          <span className="policy-logs-table__policy-name">
            {log.policy_name}
          </span>
        </td>
        <td className="policy-logs-table__result-column">
          <ResultIcon classes={log.status_icon_style} />
        </td>
        <td className="policy-logs-table__trigger-type-column">
          {log.review_timing_text}
        </td>
        <td className="policy-logs-table__evaluation-time-column">
          <time dateTime={log.evaluation_time}>
            {log.evaluation_time_formatted}
          </time><br />
          <span className="policy-logs-table__time-ago-in-words">
            ({log.evaluation_time_in_relative})
          </span>
        </td>
        <td className="col-control text-right">
          <DetailLink url={log.log_url} />
        </td>
      </tr>
    );
  }
}
