import React from "react";
import PropTypes from "prop-types";

import Constants from "../../../Constants.js";
import SortableTableHeaderCell from "../../../SortableTableHeaderCell.jsx";

import UserGroupsTableRow from "./UserGroupsTableRow.jsx";

const I18n = window.I18n;

export default class UserGroupsTable extends React.Component {
  /**
   * プロパティ定義を返します
   *
   * @public
   * @property {bool} calendarFeatureEnabled カレンダー機能が有効かどうか
   * @property {boolean} policySetsAvailable 組織が構成レビュー機能を利用可能かどうか
   * @property {string} userDepartment ユーザーの所属部署
   * @property {number} userId 対象となるユーザーのID
   * @property {string} userName ユーザー名
   * @property {Array<Object>} groups ユーザーの所属グループ
   */
  static get propTypes() {
    return {
      calendarFeatureEnabled: PropTypes.bool.isRequired,
      errorMessage: PropTypes.any,
      policySetsAvailable: PropTypes.bool.isRequired,
      reloadEmitter: PropTypes.object.isRequired,
      userId: PropTypes.number.isRequired,
      userName: PropTypes.string.isRequired,
      userRemovable: PropTypes.bool.isRequired,
      groups: PropTypes.arrayOf(PropTypes.object).isRequired,
    };
  }

  /**
   * コンポーネントを初期化します
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    // デフォルト値(Cookieに設定が存在しない場合はこれらの値が使われる)
    let sortColumn = "registered_at";
    let sortDirection = Constants.DIRECTION.ASC;

    // Cookieに設定がある場合はその値をロードする
    $.cookie.json = true;
    const settings = $.cookie(Constants.COOKIE.USER_GROUPS_SETTINGS);
    if ($.isPlainObject(settings)) {
      if (settings.sort_column != null) {
        sortColumn = settings.sort_column;
      }
      if (settings.sort_direction != null) {
        sortDirection = settings.sort_direction;
      }
    }
    this.state = {
      sortColumn: sortColumn,
      sortDirection: sortDirection
    };

    this.handleSortLinkClick = this.handleSortLinkClick.bind(this);
  }

  /**
   * 現在の設定をCookieに保存します。
   */
  saveSettings() {
    // 選択されたページをCookieに保存する
    $.cookie(Constants.COOKIE.USER_GROUPS_SETTINGS, {
      sort_column: this.state.sortColumn,
      sort_direction: this.state.sortDirection
    });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return (
      <table className="table vertical-middle ca-user-groups-table ca-table-header-no-bordered">
        <thead>
          <tr>
            <SortableTableHeaderCell
              active={this.state.sortColumn == "name"}
              callback={this.handleSortLinkClick}
              column="name"
              direction={this.state.sortDirection}
              text={I18n.t("javascript.user_groups.group_name")}
              classNameForHeaderCell="
                ca-user-groups-table__header-column
                ca-user-groups-table__header-column--clickable
                text-left
              "
            />
            <SortableTableHeaderCell
              active={this.state.sortColumn == "job_permission"}
              callback={this.handleSortLinkClick}
              column="job_permission"
              direction={this.state.sortDirection}
              text={I18n.t("javascript.user_groups.job_permission")}
              icon={"fa-tachometer"}
              classNameForHeaderCell="
                ca-user-groups-table__header-column
                ca-user-groups-table__header-column--clickable
                ca-user-groups-table__header-cloumn--narrow
              "
            />
            {this.props.policySetsAvailable && (
              <SortableTableHeaderCell
                active={this.state.sortColumn == "policy_set_permission"}
                callback={this.handleSortLinkClick}
                column="policy_set_permission"
                direction={this.state.sortDirection}
                text={I18n.t("javascript.user_groups.policy_set_permission")}
                icon={"fa-shield"}
                classNameForHeaderCell="
                ca-user-groups-table__header-column
                ca-user-groups-table__header-column--clickable
                ca-user-groups-table__header-cloumn--narrow
              "
              />
            )}
            <SortableTableHeaderCell
              active={this.state.sortColumn == "inventory_permission"}
              callback={this.handleSortLinkClick}
              column="inventory_permission"
              direction={this.state.sortDirection}
              text={I18n.t("javascript.user_groups.inventory_permission.workspaces")}
              icon={"fa-server"}
              classNameForHeaderCell="
                ca-user-groups-table__header-column
                ca-user-groups-table__header-column--clickable
                ca-user-groups-table__header-cloumn--narrow
              "
            />
            {this.props.calendarFeatureEnabled && (
              <SortableTableHeaderCell
                active={this.state.sortColumn == "calendar_permission"}
                callback={this.handleSortLinkClick}
                column="calendar_permission"
                direction={this.state.sortDirection}
                text={I18n.t("javascript.user_groups.calendar_permission")}
                icon={"fa-calendar"}
                classNameForHeaderCell="
                    ca-user-groups-table__header-column
                    ca-user-groups-table__header-column--clickable
                    ca-user-groups-table__header-cloumn--narrow
                  "
              />
            )}
            <SortableTableHeaderCell
              active={this.state.sortColumn == "users_count"}
              callback={this.handleSortLinkClick}
              column="users_count"
              direction={this.state.sortDirection}
              text={I18n.t("javascript.user_groups.users_count")}
              classNameForHeaderCell="
                ca-user-groups-table__header-column
                ca-user-groups-table__header-column--clickable
                ca-user-groups-table__header-cloumn--narrow
                text-center
              "
            />
            <SortableTableHeaderCell
              active={this.state.sortColumn == "registered_at"}
              callback={this.handleSortLinkClick}
              column="registered_at"
              direction={this.state.sortDirection}
              text={I18n.t("javascript.user_groups.registered_at")}
              colspan={this.props.userRemovable ? 2 : 1}
              classNameForHeaderCell="
                ca-user-groups-table__header-column
                ca-user-groups-table__header-column--clickable
              "
            />
          </tr>
        </thead>
        <tbody>{this.getUserGroupRows()}</tbody>
      </table>
    );
  }

  /**
   * @public
   * @return {Array<ReactElement>}
   */
  getUserGroupRows() {
    const isUserGroupsExists =
      this.props.errorMessage == null && this.props.groups.length > 0;
    if (isUserGroupsExists) {
      const groups = this.sortGroups(this.props.groups);
      return groups.map(group => {
        return (
          <UserGroupsTableRow
            key={group.id}
            calendarFeatureEnabled={this.props.calendarFeatureEnabled}
            group={group}
            policySetsAvailable={this.props.policySetsAvailable}
            reloadEmitter={this.props.reloadEmitter}
            userId={this.props.userId}
            userName={this.props.userName}
            userRemovable={this.props.userRemovable}
          />
        );
      });
    }

    // カレンダー機能が有効な場合にはカラム数が変わるため
    const colSpanCount = this.props.calendarFeatureEnabled ? "7" : "6";
    const message =
      this.props.errorMessage != null
        ? this.props.errorMessage
        : I18n.t("javascript.user_groups.no_groups");
    return (
      <tr key="0">
        <td colSpan={colSpanCount}>{message}</td>
      </tr>
    );
  }

  /**
   * @public
   * @property {Array<Object>} groups ユーザーのグループ
   * @return {Array<Object>}
   */
  sortGroups(groups) {
    const sortedGroups = groups.sort((a, b) => {
      const valueOfA = a[this.state.sortColumn];
      const valueOfB = b[this.state.sortColumn];
      if (valueOfA < valueOfB) {
        return -1;
      }
      if (valueOfA > valueOfB) {
        return 1;
      }
      return 0;
    });

    if (this.state.sortDirection == Constants.DIRECTION.DESC) {
      return sortedGroups.reverse();
    }
    return sortedGroups;
  }

  /**
   * 特定のカラムでソートするリンクがクリックされた際の処理を実行します。
   * @param {string} column クリックされたカラムの識別子
   */
  handleSortLinkClick(column) {
    let newState = {};

    if (column == this.state.sortColumn) {
      if (this.state.sortDirection == Constants.DIRECTION.ASC) {
        newState.sortDirection = Constants.DIRECTION.DESC;
      } else {
        newState.sortDirection = Constants.DIRECTION.ASC;
      }
    } else {
      newState.sortColumn = column;
      newState.sortDirection = Constants.DIRECTION.ASC;
    }

    this.setState(newState);
  }
}
