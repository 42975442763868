import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';

const I18n = window.I18n; // i18n-js

/**
 * リスト作成リクエストフォーム送信済みUI
 */
export default class SubmittedRequestForm extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   */
  static get propTypes() {
    return({
      errorMessage: PropTypes.string.isRequired,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.headingText = I18n.t('heading_for_request_form', { scope: Constants.I18N_SCOPE });
    this.result1Text = I18n.t('request_form_result1', { scope: Constants.I18N_SCOPE });
    this.result2Text = I18n.t('request_form_result2', { scope: Constants.I18N_SCOPE });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <div className="ca-workspaces-lists-request-form panel">
        <h2 className="ca-workspaces-lists-request-form__heading">
          <i className="fa fa-cog fa-lg marginR5"></i>
          {this.headingText}
        </h2>
        <div className="ca-workspaces-lists-request-form__result">
          {this.props.errorMessage == '' &&
            <p>
              {this.result1Text}<br/>
              {this.result2Text}
            </p>
          }
          {this.props.errorMessage != '' &&
            <p>
              {this.props.errorMessage}
            </p>
          }
        </div>
      </div>
    );
  }
}
