import React from 'react';
import PropTypes from 'prop-types';

const I18n = window.I18n; // i18n-js
const I18N_SCOPE = 'javascript.tfa_settings.disable_dialog_content';

/**
 * 二要素認証を無効化するダイアログに表示するコンテンツ
 * 二要素認証無効化に対するユーザーからの同意取得およびフォーム送信までを行います。
 */
export default class DisableDialogContent extends React.Component {
  /**
   * propTypes
   * @return {object}
   * @property {object} emitter EventEmitter
   * @property {bool} error エラーが発生したかどうか
   */
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
      error: PropTypes.bool.isRequired,
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);

    this.state = {
      confirmed: false, // 二要素認証を無効化する際の同意が得られているかどうか
    };

    const options = { scope: I18N_SCOPE };
    this.cancelText = I18n.t('cancel', options);
    this.errorMessage = I18n.t('error', options);
    this.headingText = I18n.t('heading', options);
    this.noticeHtml = I18n.t('notice_html', options);
    this.termOfNoticeText = I18n.t('term_of_notice', options);
    this.toDisableText = I18n.t('to_disable', options);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <div className="qa-tfa-settings-disable-modal">
        <h3>{this.headingText}</h3>
        <p className="marginT30" dangerouslySetInnerHTML={{__html: this.noticeHtml}} />
        <p>
          <label>
            <input
              checked={this.state.confirmed}
              className="qa-tfa-settings-disable-confirmation-input"
              name="confirmation"
              onChange={this.handleOnConfirmationChange.bind(this)}
              type="checkbox"
            /> {this.termOfNoticeText}
          </label>
        </p>
        <div className="error-area">
          {this.renderErrorMessage()}
        </div>
        <form
          className="form-horizontal"
          onSubmit={this.handleOnSubmit.bind(this)}
          role="form"
        >
          <div className="marginT20">
            <button
              className="btn btn-cancel btn-lg tfa-settings-modal__cancel-button margin10"
              onClick={() => { this.props.emitter.emit('closeDialog'); }}
              type="button"
            >
              {this.cancelText}
            </button>
            <button
              className="btn btn-default btn-lg btn-right tfa-settings-modal__submit-button qa-tfa-settings-disable-submit-button margin10"
              disabled={!this.state.confirmed}
              type="submit"
            >
              {this.toDisableText}
            </button>
          </div>
        </form>
      </div>
    );
  }

  /**
   * エラーメッセージを表示します。
   * @access {private}
   * @return {ReactElement|null}
   */
  renderErrorMessage() {
    if (this.props.error) {
      return(
        <label className="error">{this.errorMessage}</label>
      );
    } else {
      return null;
    }
  }

  /**
   * 確認チェックボックスのchangeイベントを処理します。
   * @access {private}
   * @param {SyntheticEvent} event
   */
  handleOnConfirmationChange() {
    const newValue = !this.state.confirmed;
    this.setState({ confirmed: newValue });
  }

  /**
   * フォームのsubmitイベントを処理します。
   * @access {private}
   * @param {SyntheticEvent} event
   */
  handleOnSubmit(event) {
    event.preventDefault();
    this.props.emitter.emit('disableTFA');
  }
}
