import PropTypes from 'prop-types';
import React from 'react';

import Constants from "./Constants.js";
import GroupIconTip from "../GroupIconTip.jsx";

const I18n = window.I18n; // i18n-js

/**
 * EBSバックアップ監視結果テーブル行コンポーネント
 *
 * 監視結果1件を1行のテーブル業として表示します。
 * 表示されるDOMはTRタグとなります。
 */
export default class EbsbackupCheckResultsTableRow extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {array} chekResult 監視結果一覧の配列
   */
  static get propTypes() {
    return({
      checkResult: PropTypes.object.isRequired,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);
    this.backupCheckSettingsText = I18n.t("backup_check_settings", { scope: `${Constants.I18N_SCOPE}` });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    const result = this.props.checkResult;
    const errorText = I18n.t(result.error, { scope: `${Constants.I18N_SCOPE}.error` });

    let errorCell =
      <a className="ca-backup-check-results-table-row__link" href={result.backup_failures_url}>
        {errorText}
      </a>;

    if (result.error) {
      errorCell =
        <a
          className="ca-backup-check-results-table-row__error"
          href={result.backup_failures_url}
        >
          <span className="fa fa-exclamation-triangle marginR5" />
          {errorText}
        </a>;
    }

    let editCell = null;
    if (result.group_aws_accounts_url) {
      editCell =
        <a
          className="btn"
          data-html="true"
          data-placement="bottom"
          data-toggle="tooltip"
          href={result.group_aws_accounts_url}
          title={this.backupCheckSettingsText}
        >
          <span className="fa fa-pencil" />
        </a>;
    }

    return(
      <tr>
        <td className="ca-backup-check-results-table-row">
          <a className="ca-backup-check-results-table-row__text-link" href={result.backup_not_exists_url}>
            <GroupIconTip group={result.group}/> {result.aws_account.name}
          </a>
        </td>
        <td className="ca-backup-check-results-table-row__backup-not-exist-ebs-count">
          <a className="ca-backup-check-results-table-row__link" href={result.backup_not_exists_url}>
            {result.backup_not_exist_ebs_count != null ? result.backup_not_exist_ebs_count : "---"}
          </a>
        </td>
        <td className="ca-backup-check-results-table-row__backup-exist-ebs-count">
          <a className="ca-backup-check-results-table-row__link" href={result.backup_exists_url}>
            {result.backup_exist_ebs_count != null ? result.backup_exist_ebs_count : "---"}
          </a>
        </td>
        <td className="ca-backup-check-results-table-row__check-status">
          {errorCell}
        </td>
        <td className="col-control text-right">
          {editCell}
        </td>
      </tr>
    );
  }
}
