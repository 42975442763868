import React from "react";

import { ErrorMessages } from "./types";

/**
 * エラーメッセージ表示コンポーネント
 *
 * 既存のErrorAreaコンポーネントは .error-area クラスに設定されているスタイルの影響を
 * 受けるため、利用するページによってはエラーメッセージのULタグの list-style-type が
 * none になりません。
 * この問題を副作用なしに解決するため、style属性値でスタイルを指定したコンポーネントです。
 */
export const ErrorArea2: React.FC<{
  errors: ErrorMessages;
}> = (props) => {
  if (props.errors.length < 1) {
    return <div className="error-area"></div>;
  }

  return (
    <div className="error-area">
      <ul style={{ paddingLeft: 0, listStyleType: "none" }}>
        {props.errors.map((value, index) => {
          return (
            <li className="error" key={index}>
              <label className="error">{value}</label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
