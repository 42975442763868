import React from 'react';
import PropTypes from 'prop-types';

import UserAvatar from '../UserAvatar.jsx';
import withBootstrapTooltip from '../withBootstrapTooltip.js';

/**
 * ユーザーアバター表示コンポーネント
**/
class PostProcessAvatar extends React.Component {
  /**
   * propTypes
   * @return {Object}
   * @property {Object} user - Userオブジェクト(full_name, profile_image_url, initial)
   * @property {function(element: DOMNode)} tooltipRef
  **/
  static get propTypes() {
    return({
      user: PropTypes.object,
      tooltipRef: PropTypes.func,
    });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <div className="ca-post-process-table__avatar" ref={this.props.tooltipRef}>
        <UserAvatar
          user={this.props.user}
        />
      </div>
    );
  }
}

export default withBootstrapTooltip(PostProcessAvatar);
