import React, { useState } from "react";

import { Group } from "./groups/google_cloud_accounts/types";

import GroupSelector from "./GroupSelector.jsx";

import { CredentialsJsonInputField } from "./google_cloud_accounts/CredentialsJsonInputField";
import { ErrorArea } from "./google_cloud_accounts/ErrorArea";
import { NameInputField } from "./google_cloud_accounts/NameInputField";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.new_google_cloud_account_form";

/** NewGoogleCloudAccountFormコンポーネントのプロパティ */
type Props = {
  /** 選択肢として表示するグループの配列 */
  groups: Group[];
};

/**
 * Google Cloudアカウントの追加時にサーバーサイドに送信するデータの構造
 *
 * groups/google_cloud_accounts#create の仕様に準拠。
 */
type CreateAccountData = {
  /** リクエスト成功時にflashメッセージを設定しない */
  suppress_flash: boolean;
  /** Google Cloudアカウント */
  google_cloud_account: {
    /** Google Cloudアカウントの名前 */
    name: string;
    /** Googleサービスアカウント */
    google_service_account_attributes: {
      /** アクセスキー(JSON文字列) */
      credentials_json: string;
    };
  };
};

/** Google Cloudアカウント追加フォームのエラーメッセージ */
type FormErrors = {
  /** アクセスキーのエラーメッセージ */
  credentialsJson: string[];
  /** アカウント名のエラーメッセージ */
  name: string[];
  /** フォーム全体に関するエラーメッセージ */
  base: string[];
  /** グループのエラーメッセージ */
  groupId: string[];
};

/**
 * Google Cloudアカウント作成フォーム
 *
 * 既存グループに対して、サービスアカウント方式でGoogle Cloudアカウントを追加するためのフォーム。
 */
const NewGoogleCloudAccountForm: React.FC<Props> = (props): JSX.Element => {
  /** エラーメッセージの初期状態 */
  const defaultErrors: FormErrors = {
    /** アクセスキーのエラーメッセージ */
    credentialsJson: [],
    /** アカウント名のエラーメッセージ */
    name: [],
    /** フォーム全体のエラーメッセージ */
    base: [],
    /** グループのエラーメッセージ */
    groupId: [],
  };

  /** グループIDの現在値 */
  const [groupId, setGroupId] = useState("");
  /** アクセスキー入力欄の現在値 */
  const [credentialsJson, setCredentialsJson] = useState("");
  /** エラーメッセージの現在値 */
  const [errors, setErrors] = useState(defaultErrors);
  /** フォームの送信状態 */
  const [isTransmitting, setIsTransmitting] = useState(false);
  /** アカウント名入力欄の現在値 */
  const [name, setName] = useState("");

  /**
   * フォームの送信を行う
   *
   * Google Cloudアカウントの追加をサーバーサイドに反映し、レスポンスに応じた処理を行います。
   */
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    /** フォームの送信先URL */
    const url = `/groups/${groupId}/google_cloud_accounts`;

    /** サーバーサイドに送信するデータ */
    const params: CreateAccountData = {
      // Railsに渡すパラメーター名はCamelCaseにできないのでlintを無効化する
      google_cloud_account: {
        name: name,
        google_service_account_attributes: {
          credentials_json: credentialsJson,
        },
      },
    };

    jQuery
      .ajax({
        beforeSend: () => {
          setErrors(defaultErrors);
          setIsTransmitting(true);
        },
        url: url,
        method: "POST",
        dataType: "json",
        data: params,
      })
      .done((data) => {
        window.location = data.url;
      })
      .fail((data) => {
        let errorResponse = data.responseJSON?.errors;
        if (!errorResponse) {
          errorResponse = { base: [I18n.t("error", { message: data.statusText, scope: I18N_SCOPE })] };
        }
        setErrors({
          ...errors,
          credentialsJson: errorResponse["google_service_account.credentials"] || [],
          name: errorResponse.name || [],
          base: errorResponse.base || [],
          groupId: errorResponse.group || [],
        });
      })
      .always(() => {
        setIsTransmitting(false);
      });
  };

  /**
   * フォームが送信可能な状態かどうかを返す
   */
  const isSubmittable = (): boolean => {
    return groupId != "" && credentialsJson !== "" && name !== "";
  };

  /**
   * GroupSelectorから渡された「選択されたグループ」をstateに格納します。
   *
   * GroupSelectorからは下記のような配列が渡されます。
   * [{ id: 1, name: "グループ名" }]
   */
  const handleChangeGroupSelector = (groups: { id: string; name: string }[]): void => {
    if (groups[0]) {
      setGroupId(groups[0].id);
    } else {
      setGroupId("");
    }
  };

  /**
   * グループが選択済みかどうかを返します。
   */
  const isGroupSelected = (): boolean => {
    return groupId != "";
  };

  return (
    <React.StrictMode>
      <form onSubmit={handleSubmit}>
        <div className="ca-group__aws-account-form">
          <div className="ca-group__aws-account-form__row">
            <div className="ca-group__aws-account-form__left-col">
              <div className="ca-group__aws-account-form__heading">{I18n.t("step1", { scope: I18N_SCOPE })}</div>
            </div>
            <div>
              <label className="ca-group__aws-account-form__label" htmlFor="GroupSelector">
                {I18n.t("group", { scope: I18N_SCOPE })}
                <span className="ca-group__aws-account-form__required-mark">*</span>
              </label>
              <div className="ca-group__aws-account-form__select">
                <GroupSelector onChange={handleChangeGroupSelector} options={props.groups} />
              </div>
              <ErrorArea errors={errors.groupId} />
              <p className="ca-group__aws-account-form__note">{I18n.t("group_note", { scope: I18N_SCOPE })}</p>
            </div>
          </div>

          <div className="ca-group__aws-account-form__row">
            <div className="ca-group__aws-account-form__left-col">
              <div className="ca-group__aws-account-form__heading">{I18n.t("step2", { scope: I18N_SCOPE })}</div>
            </div>
            <div>
              <div style={{ width: "450px" }}>
                <p className="ca-group__aws-account-form__prompt">{I18n.t("prompt", { scope: I18N_SCOPE })}</p>
                <CredentialsJsonInputField
                  disabled={!isGroupSelected()}
                  errors={errors.credentialsJson}
                  name=""
                  onChange={setCredentialsJson}
                  placeholder=""
                  required={true}
                  useError2={true}
                  value=""
                />
                <NameInputField
                  disabled={!isGroupSelected()}
                  errors={errors.name}
                  onChange={setName}
                  useError2={true}
                  value={name}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ca-btn-block marginT40">
          <div className="ca-btn-block__center">
            <SubmitButton
              disabled={!isSubmittable()}
              label={I18n.t("submit", { scope: I18N_SCOPE })}
              pending={isTransmitting}
              pendingLabel={I18n.t("transmitting", { scope: I18N_SCOPE })}
            />
            <ErrorArea errors={errors.base} />
            <ErrorArea errors={errors.groupId} />
          </div>
        </div>
      </form>
    </React.StrictMode>
  );
};

// 以下のコンポーネントは app/javascript/src/google_cloud_accounts/SubmitButton.tsx の
// コンポーネントにアイコン表示を加えるために、定義を複製してHTML部分を改変したもの。

/**
 * 送信ボタン
 *
 * 状態に応じて送信ボタンまたは送信中ボタン(操作不可)をBUTTON要素で表示します。
 */
const SubmitButton: React.FC<{
  /** 無効かどうか */
  disabled: boolean;
  /** ボタンのラベル */
  label: string;
  /** 送信中状態にするかどうか */
  pending: boolean;
  /** 送信中状態の場合のボタンのラベル */
  pendingLabel: string;
}> = ({ disabled = false, pending = false, label, pendingLabel }) => {
  if (pending) {
    return (
      <button className={`btn btn-success btn-lg btn-continue`} disabled={true} type="submit">
        {pendingLabel}
      </button>
    );
  }

  return (
    <button
      className={`btn ${disabled ? "btn-default" : "btn-primary"} btn-lg btn-continue`}
      disabled={disabled}
      type="submit"
    >
      <span className="fa fa-check-circle fa-lg"></span>&nbsp;
      {label}
    </button>
  );
};

export { NewGoogleCloudAccountForm };
