import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import ManualLink from './ManualLink.jsx';

const I18n = window.I18n; // i18n-js

/**
 * アクティビティ利用状況表示コンポーネント
 */
export default class UsageBoxContainer extends React.Component {
  /**
   * propTypes
   * @return {object}
   * @property {number} activityUsageCount 当月のアクティビティ実行回数の現在値
   * @property {number} activityUsageLimit 当月のアクティビティ実行回数の上限値
   * @property {bool} allowActivityLimitExceedance アクティビティの超過が許可されているかどうか
   * @property {number} usageAmount 超過したアクティビティ実行回数の金額
   */
  static get propTypes() {
    return({
      activityUsageCount: PropTypes.number.isRequired,
      activityUsageLimit: PropTypes.number.isRequired,
      allowActivityLimitExceedance: PropTypes.bool.isRequired,
      usageAmount: PropTypes.number.isRequired
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);

    const options = { scope: 'javascript.usage_box' };
    this.titleText = I18n.t('title', options);
    this.activityUsageTitleText = I18n.t('activity_usage', options);
    this.manualTitle = I18n.t('activity', { scope: 'common.manual.text.about' });
    this.manualURL = I18n.t('activity', { scope: 'common.manual.url.about' });
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const amount = this.props.allowActivityLimitExceedance ? this.props.usageAmount : 0;

    return(
      <div className="activity-panel col-md-12 col-xs-6">
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">
              <span className="fa fa-tasks"></span> {this.titleText}
            </h3>
          </div>
          <div className="panel-body">
            <div className="marginB10">
              <ManualLink
                linkText={this.manualTitle}
                url={this.manualURL}
              />
            </div>
            <ActivityUsage
              title={this.activityUsageTitleText}
              count={this.props.activityUsageCount}
              limit={this.props.activityUsageLimit}
              amount={amount}
            />
          </div>
          <div className="margin10">
            <UpgradeButton />
          </div>
        </div>
      </div>
    );
  }
}

/**
 * アクティビティ実行回数表示
 * バーチャート、超過アクティビティ実行回数、金額を表示します。
 */
class ActivityUsage extends React.Component {
  /**
   * propTypes
   * @return {object}
   * @property {number} amount 超過金額
   * @property {number} count アクティビティ実行回数の現在値
   * @property {number} limit アクティビティ実行回数の上限
   * @property {number} title 超過金額
   */
  static get propTypes() {
    return({
      amount: PropTypes.number.isRequired,
      count: PropTypes.number.isRequired,
      limit: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);
    this.yenText = I18n.t('javascript.usage_box.yen');
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const count = this.props.count;
    const limit = this.props.limit;
    const title = this.props.title;
    const progressBarClasses = this.isOverrun() ? 'progress-bar limit' : 'progress-bar';
    const percentage = this.isOverrun() ? 100 : Math.round(count / limit * 100);
    const percentageText = `${percentage}%`;

    // アクティビティ実行回数の現在値が上限を超過している場合は「5000 / 5000」のように
    // 上限値を現在値として表示する
    const current = this.isOverrun() ? limit : count;

    return(
      <div className="clearfix">
        <dl className="clearfix marginB5">
          <dt className="float-left">
            {title}
          </dt>
          <dd className="float-right text-right">
            <b>{current} / {limit}</b>
          </dd>
        </dl>
        <div className="progress marginB5">
          <div
            className={progressBarClasses}
            role="progressbar"
            style={{width: percentageText}}
          >
            <span className="sr-only">{percentageText} {title.toLowerCase()} used</span>
          </div>
        </div>
        {this.isOverrun() ? this.renderOverrunAmount() : null}
      </div>
    );
  }

  /**
   * @return {ReactElement}
   */
  renderOverrunAmount() {
    const overrunCount = this.props.count - this.props.limit;
    const overrunText = I18n.t('javascript.usage_box.overrun', { count: overrunCount });

    return(
      <div className="float-right">
        <div className="text-right">
          <b>+ {this.props.amount} </b> {this.yenText}<br/>
          ({overrunText})
        </div>
      </div>
    );
  }

  /**
   * アクティビティ実行回数の現在値が上限値に達しているかどうかを返します。
   * @return {bool}
   */
  isOverrun() {
    return(this.props.count >= this.props.limit);
  }
}

/**
 * プランアップグレードボタン
 */
class UpgradeButton extends React.Component {
  /**
   * @override
   */
  constructor(props) {
    super(props);
    this.buttonText = I18n.t('javascript.usage_box.upgrade');
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <a href={Constants.URL.PLAN_EDIT}>
        <button
          className="btn btn-primary btn-block"
          type="button"
        >
          {this.buttonText}
        </button>
      </a>
    );
  }
}
