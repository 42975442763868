import React, { SyntheticEvent, useState } from "react";

type option = {
  name: string;
  value: number;
};

type groupOption = option;

type awsAccountOption = option & {
  group_id: number;
};

type column = "aws_account_id" | "group_id";

type selectedValueChange = {
  column: column;
  value: string;
};

/**
 * ジョブ一覧のジョブ詳細検索のメニューに表示するグループとAWSアカウントのセレクトボックス
 * ２つのセレクトボックスは以下のように連携して動作します
 * - グループが選択されていない場合、AWSアカウントは選択できない
 * - グループが選択済みの場合、グループに属するAWSアカウントのみが選択可能になる
 * - AWSアカウントが選択された状態でグループが変更された場合、AWSアカウントの選択がリセットされる
 */
export const GroupAndAwsAccountSelector: React.FC<{
  groupValue: string;
  awsAccountValue: string;
  groupOptions: groupOption[];
  awsAccountOptions: awsAccountOption[];
}> = (props) => {
  const [selectedValues, setSelectedValues] = useState({
    awsAccountId: props.awsAccountValue ?? "",
    groupId: props.groupValue ?? "",
  });

  const awsAccountOptions = props.awsAccountOptions.filter((awsAccount) => {
    return String(awsAccount.group_id) === selectedValues.groupId;
  });
  const I18n = window.I18n; // i18n-js
  const i18nScope = { scope: "javascript.job_table.detailed_search_input" };
  const allAwsAccountText = I18n.t("all_aws_account", i18nScope);
  const allGroupText = I18n.t("all_group", i18nScope);

  /**
   * セレクトボックスの選択値が変更された場合に子コンポーネントから呼び出されるコールバック関数
   */
  const handleOnChange = ({ column, value }: selectedValueChange): void => {
    if (column === "group_id") {
      if (value === "" || value !== selectedValues.groupId) {
        // グループが指定されない場合や他のグループが指定された場合はAWSアカウントの選択をリセットする
        return setSelectedValues({ groupId: value, awsAccountId: "" });
      }
      return setSelectedValues({ ...selectedValues, groupId: value });
    }

    return setSelectedValues({ ...selectedValues, awsAccountId: value });
  };

  return (
    <div className="display-flex">
      {/* グループのセレクトボックス */}
      <SearchSelector
        callback={handleOnChange}
        blankText={allGroupText}
        options={props.groupOptions}
        column={"group_id"}
        selectedValue={selectedValues.groupId}
      />
      <span
        className="ca-job-search-input__detailed-search-container__group-icon fa fa-caret-right"
        aria-hidden="true"
      ></span>
      {/* AWSアカウントのセレクトボックス */}
      <SearchSelector
        callback={handleOnChange}
        blankText={allAwsAccountText}
        disabled={awsAccountOptions.length === 0}
        options={awsAccountOptions}
        column={"aws_account_id"}
        selectedValue={selectedValues.groupId == "" ? "" : selectedValues.awsAccountId}
      />
    </div>
  );
};

const SearchSelector: React.FC<{
  callback: (selectedValueChange: selectedValueChange) => void;
  blankText: string;
  disabled?: boolean;
  options: option[];
  column: column;
  selectedValue: string;
}> = (props) => {
  /*
   * セレクトボックスのコールバック関数
   * 変更された要素の名前と値を整形して、 Props のコールバック関数を呼び出します
   */
  const handleOnChange = (event: SyntheticEvent): void => {
    event.preventDefault();
    const target = event.target as HTMLInputElement;
    const targetValue = target.value;
    const selectedValueChange: selectedValueChange = {
      value: targetValue as string,
      column: props.column,
    };
    props.callback(selectedValueChange);
  };

  return (
    <>
      <select
        className="ca-job-search-input__detailed-search-container__selector form-control"
        name={props.column} // rails のフォームヘルパーで作成したボタンで submit するために必要
        disabled={props.disabled ?? false}
        onChange={handleOnChange}
        value={props.selectedValue}
      >
        <option key={0} value="">
          {props.blankText}
        </option>
        {props.options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          );
        })}
      </select>
    </>
  );
};
