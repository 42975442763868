import React from "react";
import PropTypes from "prop-types";

import TriggerJobTableRow from "./TriggerJobTableRow.jsx";


const I18n = window.I18n;
const I18N_SCOPE = "activerecord.attributes.trigger_job";

/**
 * 割当ジョブテーブルコンポーネント
 *
 * このコンポーネントの描画結果はTABLE要素となります。
 */
export default class TriggerJobTable extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @property {string} errorMessage エラーメッセージまたは null
   * @property {Object[]} triggerJobs ジョブの配列
   */
  static get propTypes() {
    return {
      errorMessage: PropTypes.string,
      triggerJobs: PropTypes.array.isRequired,
    };
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return (
      <table className="table vertical-middle ca-post-process-jobs-table ca-table-header-no-bordered">
        <thead>
          <tr>
            <th className="ca-post-process-table-head ca-post-process-jobs__provider"></th>
            <th className="ca-post-process-table-head ca-post-process-jobs__name">
              {I18n.t("name", {scope: I18N_SCOPE})}
            </th>
            <th className="ca-post-process-table-head ca-post-process-jobs__type">
              {I18n.t("type", {scope: I18N_SCOPE})}
            </th>
            <th className="ca-post-process-table-head ca-post-process-jobs__trigger-action">
              <span className="trigger-name">{I18n.t("rule_type", {scope: I18N_SCOPE})}</span>
              <span className="fa fa-arrow-circle-right fa-sm" />
              <span className="action-name">{I18n.t("action_type", {scope: I18N_SCOPE})}</span>
            </th>
            <th className="ca-post-process-table-head ca-post-process-jobs__active">
              {I18n.t("active", {scope: I18N_SCOPE})}
            </th>
            <th className="ca-post-process-table-head ca-post-process-jobs__enable-on">
              {I18n.t("enabled_on", {scope: "activerecord.attributes.post_process_assignment"})}
            </th>
          </tr>
        </thead>
        <tbody>{this.getTriggerJobRows()}</tbody>
      </table>
    );
  }

  /**
   * テーブルに表示する割当ジョブの行コンポーネントを配列で返します。
   * @return {ReactElement}
   */
  getTriggerJobRows() {
    const isTriggerJobsExist =
      this.props.errorMessage == null && this.props.triggerJobs.length > 0;

    if (isTriggerJobsExist) {
      return this.props.triggerJobs.map(triggerJob => {
        return <TriggerJobTableRow
                  key={triggerJob.id}
                  triggerJob={triggerJob} />;
      });
    }

    const message = this.props.errorMessage || I18n.t("no_jobs", {scope: "javascript.post_process_trigger_jobs"});
    return (
      <tr><td colSpan="4">{message}</td></tr>
    );
  }
}
