import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";

import ManualLink from "../../ManualLink";
import { showSuccessToast } from "../../toast";

import { CredentialsJsonInputField } from "../../google_cloud_accounts/CredentialsJsonInputField";
import { ErrorArea } from "../../google_cloud_accounts/ErrorArea";
import { NameInputField } from "../../google_cloud_accounts/NameInputField";
import { SubmitButton } from "../../google_cloud_accounts/SubmitButton";

import { Group } from "./types";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.group_google_cloud_account.new_dialog";

/** NewDialogコンポーネントのプロパティ */
type Props = {
  /** Google Cloudアカウントの作成対象となるグループ */
  group: Group;
  /** ダイアログが開いているかどうか */
  isOpen: boolean;
  /** ダイアログが閉じる直前に実行させる処理 */
  onCancel: () => void;
  /** アカウントの作成が完了した直後に実行させる処理 */
  onCreated: () => void;
};

/**
 * Google Cloudアカウントの追加時にサーバーサイドに送信するデータの構造
 *
 * groups/google_cloud_accounts#create の仕様に準拠。
 */
type CreateAccountData = {
  /** リクエスト成功時にflashメッセージを設定しない */
  suppress_flash: boolean;
  /** Google Cloudアカウント */
  google_cloud_account: {
    /** Google Cloudアカウントの名前 */
    name: string;
    /** Googleサービスアカウント */
    google_service_account_attributes: {
      /** アクセスキー(JSON文字列) */
      credentials_json: string;
    };
  };
};

/** Google Cloudアカウント追加フォームのエラーメッセージ */
type FormErrors = {
  /** アクセスキーのエラーメッセージ */
  credentialsJson: string[];
  /** アカウント名のエラーメッセージ */
  name: string[];
  /** フォーム全体に関するエラーメッセージ */
  base: string[];
  /** グループのエラーメッセージ */
  groupId: string[];
};

/**
 * Google Cloudアカウント追加ダイアログ
 *
 * Google Cloudアカウント一覧の追加ボタン押下時に表示される追加ダイアログ。
 */
const NewDialog: React.FC<Props> = (props): JSX.Element => {
  // ReactModalの初期化
  useEffect((): void => {
    ReactModal.setAppElement("body");
  }, []);

  /** エラーメッセージの初期状態 */
  const defaultErrors: FormErrors = {
    /** アクセスキーのエラーメッセージ */
    credentialsJson: [],
    /** アカウント名のエラーメッセージ */
    name: [],
    /** フォーム全体のエラーメッセージ */
    base: [],
    /** グループのエラーメッセージ */
    groupId: [],
  };

  /** アクセスキー入力欄の現在値 */
  const [credentialsJson, setCredentialsJson] = useState("");
  /** エラーメッセージの現在値 */
  const [errors, setErrors] = useState(defaultErrors);
  /** フォームの送信状態 */
  const [isTransmitting, setIsTransmitting] = useState(false);
  /** アカウント名入力欄の現在値 */
  const [name, setName] = useState("");

  /**
   * フォームの状態をリセットする
   */
  const resetForm = (): void => {
    setCredentialsJson("");
    setName("");
    setErrors(defaultErrors);
    setIsTransmitting(false);
  };

  /**
   * ダイアログを閉じる直前に必要な処理を行う
   */
  const handleRequestClose = (): void => {
    props.onCancel();
    resetForm();
  };

  /**
   * フォームの送信を行う
   *
   * Google Cloudアカウントの追加をサーバーサイドに反映し、レスポンスに応じた処理を行います。
   */
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    /** フォームの送信先URL */
    const url = `/groups/${props.group.id}/google_cloud_accounts`;

    /** サーバーサイドに送信するデータ */
    const params: CreateAccountData = {
      // Railsに渡すパラメーター名はCamelCaseにできないのでlintを無効化する
      suppress_flash: true,
      google_cloud_account: {
        name: name,
        google_service_account_attributes: {
          credentials_json: credentialsJson,
        },
      },
    };

    jQuery
      .ajax({
        beforeSend: () => {
          setErrors(defaultErrors);
          setIsTransmitting(true);
        },
        url: url,
        method: "POST",
        dataType: "json",
        data: params,
      })
      .done(() => {
        props.onCreated();
        resetForm();
        showSuccessToast(I18n.t("succeeded", { scope: I18N_SCOPE }));
      })
      .fail((data) => {
        let errorResponse = data.responseJSON?.errors;
        if (!errorResponse) {
          errorResponse = { base: [I18n.t("error", { message: data.statusText, scope: I18N_SCOPE })] };
        }
        setErrors({
          ...errors,
          credentialsJson: errorResponse["google_service_account.credentials"] || [],
          name: errorResponse.name || [],
          base: errorResponse.base || [],
          groupId: errorResponse.group || [],
        });
      })
      .always(() => {
        setIsTransmitting(false);
      });
  };

  /**
   * フォームが送信可能な状態かどうかを返す
   */
  const isSubmittable = (): boolean => {
    return credentialsJson !== "" && name !== "";
  };

  return (
    <ReactModal
      className="ca-group-aws-account-modal__content"
      isOpen={props.isOpen}
      onRequestClose={handleRequestClose}
      overlayClassName="ca-group-aws-account-modal__overlay"
      role="dialog"
    >
      <div className="text-left">
        <Heading />
      </div>
      <div className="text-left marginB10">
        <GoogleCloudAccountManualLink />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="ca-group-aws-accounts-new-iam-role-form__fields">
          <div className="ca-group-aws-accounts-new-iam-role-form__fields-row">
            <div className="ca-group-aws-accounts-new-iam-role-form__fields-left-col">
              <div className="ca-group-aws-accounts-new-iam-role-form__field-heading">
                {I18n.t("step1", { scope: I18N_SCOPE })}
              </div>
            </div>
            <div className="ca-group-aws-accounts-new-iam-role-form__fields-right-col">
              <CredentialsJsonInputField
                disabled={false}
                errors={errors.credentialsJson}
                name=""
                onChange={setCredentialsJson}
                placeholder=""
                required={true}
                value=""
              />
              <NameInputField disabled={false} errors={errors.name} onChange={setName} value={name} />
            </div>
          </div>
        </div>
        <div className="ca-btn-block__center marginT15">
          <SubmitButton
            disabled={!isSubmittable()}
            label={I18n.t("submit", { scope: I18N_SCOPE })}
            pending={isTransmitting}
            pendingLabel={I18n.t("transmitting", { scope: I18N_SCOPE })}
          />
          <ErrorArea errors={errors.base} />
          <ErrorArea errors={errors.groupId} />
        </div>
      </form>
    </ReactModal>
  );
};

/**
 * フォームの見出し
 */
const Heading: React.FC = (): JSX.Element => {
  return (
    <h2>
      <span className="fa fa-key" />
      &nbsp;
      {I18n.t("heading", { scope: I18N_SCOPE })}
    </h2>
  );
};

/**
 * マニュアルへのリンク
 */
const GoogleCloudAccountManualLink: React.FC = (): JSX.Element => {
  return (
    <ManualLink
      linkText={I18n.t("manual.text", { scope: I18N_SCOPE })}
      url={I18n.t("manual.url", { scope: I18N_SCOPE })}
    />
  );
};

export { NewDialog };
