import React from "react";

import { CronJob, TriggerJob } from "./types";
import { OldFirstJobMigrationMessage } from "./OldFirstJobMigrationMessage";

const I18n = window.I18n; // i18n-js

/**
 * タイマートリガーの有効期限を表示します。
 * renderCronJobInfoから実行されます。
 * @private
 */
const renderCronJobEffectiveSpan = (job: CronJob): JSX.Element | null => {
  const hasEffectiveSpan = job.effective_date != undefined || job.expiration_date != undefined;

  if (!hasEffectiveSpan) {
    return null;
  }

  const effectiveDate = job.effective_date ?? I18n.t("trigger_jobs.show.not_specified");
  const expirationDate = job.expiration_date ?? I18n.t("trigger_jobs.show.not_specified");
  return (
    <div className="ca-workflow-trigger__item">
      <div className="ca-workflow-trigger__label">
        {I18n.t("activerecord.attributes.trigger_job.effective_date_period")}
      </div>
      <div className="ca-workflow-trigger__data">{[effectiveDate, expirationDate].join(" 〜 ")}</div>
    </div>
  );
};

/**
 * タイマートリガーの開始遅延時の実行キャンセル設定情報を表示します。
 *
 * @param {CronJob} job - ジョブ
 * @returns {JSX.Element | null} - 開始遅延時の実行キャンセル設定情報を表示する JSX 要素、または null
 * @private
 */
const renderStartTimeoutText = (job: CronJob): JSX.Element | null => {
  if (job.start_timeout_minutes_text) {
    return (
      <>
        <br />
        {`(${job.start_timeout_minutes_text})`}
      </>
    );
  }

  return null;
};

/**
 * タイマートリガーの詳細を表示します。
 * @public
 */
export const CronTriggerDetails = (job: CronJob): JSX.Element => {
  return (
    <div className="ca-workflow-trigger">
      <div className="ca-workflow-trigger__icon-area">
        <div className="ca-workflow-trigger__icon">
          <i className="fa-duotone fa-solid fa-bullseye-pointer"></i>
        </div>
        {I18n.t("common.rule_types_abb.cron")}
        <br />
        {I18n.t("activerecord.attributes.trigger_job.rule_type")}
      </div>

      <div className="ca-workflow-trigger__info-area">
        {/* TODO: 機能フラグ v27_job_workflow_improvement が不要になったら OldFirstJobMigrationMessage を削除する */}
        <OldFirstJobMigrationMessage job={job as TriggerJob} />
        <div className="ca-workflow-trigger__item">
          <div className="ca-workflow-trigger__label">{I18n.t("activerecord.attributes.trigger_job.time_zone")}</div>
          <div className="ca-workflow-trigger__data">{job.show_time_zone_and_offset}</div>
        </div>

        <div className="ca-workflow-trigger__item">
          <div className="ca-workflow-trigger__label">
            {I18n.t(job.schedule_type, {
              scope: "activerecord.attributes.trigger_job",
            })}
          </div>
          <div className="ca-workflow-trigger__data">
            {job.show_cron_rule_value}
            {renderStartTimeoutText(job)}
          </div>
        </div>

        {job.national_holiday_schedule && (
          <div className="ca-workflow-trigger__item">
            <div className="ca-workflow-trigger__label">
              {I18n.t("trigger_jobs.show_cron_rule_value.exception_condition")}
            </div>
            <div className="ca-workflow-trigger__data">
              {I18n.t(`trigger_jobs.show_cron_rule_value.national_holiday_schedule_${job.national_holiday_schedule}`)}
            </div>
          </div>
        )}

        {job.show_dates_to_skip_count && (
          <div className="ca-workflow-trigger__item">
            <div className="ca-workflow-trigger__label">
              {I18n.t("trigger_jobs.show_cron_rule_value.dates_to_skip")}
            </div>
            <div className="ca-workflow-trigger__data">{job.show_dates_to_skip_count}</div>
          </div>
        )}

        {renderCronJobEffectiveSpan(job)}
      </div>
    </div>
  );
};
