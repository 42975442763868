import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import ErrorMessages from '../ErrorMessages.jsx';

const _ = window._; // Underscore.js
const I18n = window.I18n; // i18n-js

const I18N_SCOPE = `${Constants.I18N_SCOPE}.aws_account_id_field`;

// AWSアカウントID選択フィールドコンポーネント
//
// 対象が新規レコードの場合はAWSアカウントのセレクトボックスを表示し、既存レコードの場合は
// 現在のAWSアカウント名の表示のみを行います。
//
class AwsAccountIdField extends React.Component {
  static get propTypes() {
    return({
      // セレクトボックスの選択肢となるAWSアカウント
      awsAccounts: PropTypes.arrayOf(PropTypes.array).isRequired,
      emitter: PropTypes.object.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
      // セレクトボックスの初期値(既存レコードの場合はレコードに設定されているAWSアカウントID)
      initialValue: PropTypes.string.isRequired,
      // 対象が新規レコードかどうか
      newRecord: PropTypes.bool.isRequired
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.initialValue
    };
    this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // awsAccountsプロパティまたはerrorsプロパティ、valueステートが変化した場合のみ再描画を行う
    return (
      nextProps.awsAccounts.join() !== this.props.awsAccounts.join()
        || nextProps.errors.join() !== this.props.errors.join()
        || nextState.value !== this.state.value
    );
  }

  /**
   * エラーがあるかどうかを返します。
   *
   * @return {boolean}
   */
  hasError() {
    return this.props.errors.length > 0;
  }

  render() {
    const errorClasses = this.hasError() ? 'has-error' : '';
    const label = I18n.t('activerecord.attributes.policy_set.aws_account');

    return(
      <div className={`form-group ${errorClasses}`}>
        <div className="row">
          <div className="col-xs-6">
            <label htmlFor="policy_set-aws_account_id">
              {label}
            </label>
            {this.props.newRecord ? this.renderContentForNewRecord() : this.renderContentForExistingRecord()}
          </div>
        </div>
      </div>
    );
  }

  /**
   * 既存レコード用のコンテンツを表示します。
   */
  renderContentForExistingRecord() {
    const awsAccount = _.find(this.props.awsAccounts, item => item[1] === Number(this.props.initialValue));
    const awsAccountName = awsAccount[0];

    return(<p>{awsAccountName}</p>);
  }

  /**
   * 新規レコード用のコンテンツを表示します。
   */
  renderContentForNewRecord() {
    return(
      <div>
        <select id="policy_set-aws_account_id"
          className="form-control"
          onChange={this.handleChange}
          required={true}
          value={this.state.value}>
          {this.renderOptions()}
        </select>
        <ErrorMessages messages={this.props.errors} />
      </div>
    );
  }

  /**
   * セレクトボックスの選択肢を表示します。
   */
  renderOptions() {
    const blankOption = [I18n.t('blank_option', { scope: I18N_SCOPE }), ''];
    const options = [blankOption].concat(this.props.awsAccounts);

    return(options.map((item) => {
      const label = item[0];
      const value = item[1];
      return(
        <option key={value} value={value}>{label}</option>
      );
    }));
  }

  /**
   * セレクトボックスの値が変更された場合のイベントハンドラ。
   */
  handleChange(event) {
    const value = event.target.value;

    this.setState({
      value: value
    }, () => {
      this.props.emitter.emit(Constants.EVENT_CHANGE_AWS_ACCOUNT_ID, value);
    });
  }
}

export default AwsAccountIdField;
