import React from 'react';
import PropTypes from 'prop-types';

import PostProcessAssignment from './PostProcessAssignment.jsx';
import PostProcessSelector from './PostProcessSelector.jsx';
import PostProcessSettings from './PostProcessSettings.jsx';
import RefreshPostProcessButton from './RefreshPostProcessButton.jsx';

const _ = window._; // Underscore.js

/**
 * 後処理割り当てパネル
 *
 * 後処理割り当てパネルを表示するコンポーネントで、以下の責務を持ちます。
 *
 * - 割り当て済み後処理を表示するコンポーネントの呼び出し
 * - 後処理一覧更新ボタンを表示するコンポーネントの呼び出し
 * - 後処理を作成するコンポーネントの呼び出し
 * - 後処理一覧セレクトボックスを表示するコンポーネントの呼び出し
 */
export default class PostProcessAssignmentsPanel extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {Object[]} assignments 割り当て済み後処理の配列
   * @property {string} defaultLanguage - 言語設定欄がある場合のデフォルトの言語(例 "ja")
   * @property {string} defaultTimeZone - タイムゾーン設定がある場合のデフォルトのタイムゾーン(例 "Tokyo")
   * @property {Object} emitter EventEmitterオブジェクト
   * @property {Array<Number>} groupIds グループIDの配列
   * @property {array[]} groups グループの配列
   * @property {boolean} isSlackIntegrated - Slackが外部サービスとして連携済みかどうか
   * @property {boolean} isUserCanManageIntegrations - ユーザーが外部サービス連携を管理できるかどうか
   * @property {Object[]} postProcesses 後処理の配列
   * @property {string} postProcessesUrls 後処理の更新時にXHRでアクセスするURL
   * @property {string} postProcessType 成功時の後処理か失敗時の後処理かをあらわす文字列
   *                                    "completed" もしくは "failed"
   * @property {sring} loadingImagePath ローディング中に表示する画像のURLパス
   * @property {array[]} regions リージョンの配列
   * @property {bool} refreshButtonDisabled 後処理一覧更新ボタンの状態
   *                  true で更新ボタンが disabled 状態となる
   * @property {array[]} services サービスの選択肢
   * @property {string} sqsQueuesPath SQSキュー名を取得するためのパス
   * @property {bool} showDialog 後処理作成ダイアログの表示状態
   * @property {string} title パネルのヘッダー部に表示する文字列
   * @property {string} titleIcon パネルのヘッダー部に表示するアイコンのclass属性値
   * @property {Array<Array>} timeZones - タイムゾーンの選択肢
   */
  static get propTypes() {
    return({
      assignments: PropTypes.arrayOf(PropTypes.object),
      defaultLanguage: PropTypes.string.isRequired,
      defaultTimeZone: PropTypes.string.isRequired,
      emitter: PropTypes.object.isRequired,
      groupIds: PropTypes.arrayOf(PropTypes.number),
      groups: PropTypes.arrayOf(PropTypes.array),
      isSlackIntegrated: PropTypes.bool.isRequired,
      isUserCanManageIntegrations: PropTypes.bool.isRequired,
      loadingImagePath: PropTypes.string.isRequired,
      postProcesses: PropTypes.arrayOf(PropTypes.object),
      postProcessesUrl: PropTypes.string.isRequired,
      postProcessType: PropTypes.string.isRequired,
      regions: PropTypes.arrayOf(PropTypes.array).isRequired,
      refreshButtonDisabled: PropTypes.bool,
      services: PropTypes.arrayOf(PropTypes.array).isRequired,
      showDialog: PropTypes.bool,
      sqsQueuesPath: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      titleIcon: PropTypes.string.isRequired,
      timeZones: PropTypes.arrayOf(PropTypes.array).isRequired,
    });
  }

  /**
   * デフォルトのプロパティを返します。
   *
   * @public
   * @return {Object}
   */
  static get defaultProps() {
    return({
      assignments: [],
      groups: [],
      postProcesses: [],
      refreshButtonDisabled: false,
      showDialog: false
    });
  }

  /**
   * 引数で指定した後処理が所属しているグループのオブジェクトを返します。
   *
   * @private
   * @param {Object|null} postProcess 後処理オブジェクト
   */
  getGroup(postProcess) {
    const group = this.props.groups.find((group) => {
      return(group[1] == postProcess.group_id);
    });

    return group ? { name: group[0], color: group[2] } : null;
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <div id={`qa-post-process-assignment-panel-for-${this.props.postProcessType}`} className="col-md-6">
        <div className="ca-post-process-assignment-panel">
          <div className={`ca-post-process-assignment-panel__panel-heading--${this.props.postProcessType}`}>
            <div className={`ca-post-process-assignment-panel__post-process-icon--${this.props.postProcessType}`}>
              <span className={`fa ${this.props.titleIcon}`}></span> {this.props.title}
            </div>
            <div className="ca-post-process-assignment-panel__post-process-refresh">
              <RefreshPostProcessButton
                emitter={this.props.emitter}
                postProcessType={this.props.postProcessType}
                disabled={this.props.refreshButtonDisabled}
              />
            </div>
            <div className="ca-post-process-assignment-panel__post-process-add">
              <PostProcessSettings
                defaultLanguage={this.props.defaultLanguage}
                defaultTimeZone={this.props.defaultTimeZone}
                emitter={this.props.emitter}
                groupIds={this.props.groupIds}
                groups={this.props.groups}
                isSlackIntegrated={this.props.isSlackIntegrated}
                isUserCanManageIntegrations={this.props.isUserCanManageIntegrations}
                loadingImagePath={this.props.loadingImagePath}
                postProcessType={this.props.postProcessType}
                postProcessesUrl={this.props.postProcessesUrl}
                regions={this.props.regions}
                services={this.props.services}
                showDialog={this.props.showDialog}
                sqsQueuesPath={this.props.sqsQueuesPath}
                timeZones={this.props.timeZones}
              />
            </div>
          </div>
          <div className="ca-post-process-assignment-panel__panel-body">
            {this.renderAssignments()}
          </div>
          <div className="ca-post-process-assignment-panel__panel-footer">
            <PostProcessSelector
              emitter={this.props.emitter}
              postProcessType={this.props.postProcessType}
              postProcesses={this.props.postProcesses}
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   * 割り当て済み後処理をあらわすコンポーネントの配列を返します。
   *
   * @private
   * @return {ReactElement}
   */
  renderAssignments() {
    return(
      _.map(this.props.assignments, (assignment) => {
        return (
          <PostProcessAssignment
            emitter={this.props.emitter}
            group={this.getGroup(assignment)}
            key={assignment.id}
            postProcess={assignment}
            postProcessType={this.props.postProcessType}
          />
        );
      })
    );
  }
}
