import React from 'react';
import PropTypes from 'prop-types';

import withBootstrapTooltip from '../withBootstrapTooltip.js';
import Pagination from '../Pagination.jsx';
import SortableTableHeaderCell from '../SortableTableHeaderCell.jsx';

import Constants from './Constants.js';
import UserTableRow from './UserTableRow.jsx';

const I18n = window.I18n; // i18n-js
const Underscore = window._; // Underscore.js

/**
 * ユーザーテーブル
 *
 * ユーザー一覧テーブルを表示するコンポーネントで、以下の責務を持ちます。
 *
 * - ユーザー一覧テーブルの表示
 * - 任意のカラムでの降順昇順のソート
 * - ページング操作が行われた場合にEventEmitterにイベント(EVENT_CHANGE_CURRENT_PAGE)を通知する
 */
class UserTable extends React.Component { // export は定義が完了した後で行っている
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {number} currentPage 現在表示中のページ番号(最小は1)
   * @property {string} currentUserType サインイン中のユーザーのユーザー種類
   * @property {string} editUserPath ユーザー編集ボタンのリンク先URLパス(ID部分は":id"とする)
   * @property {Object} emitter EventEmitterオブジェクト
   * @property {string|null} errorMessage テーブル内に表示するエラーメッセージ
   * @property {boolean} isPending データを取得中かどうか
   * @property {string} searchInput 絞り込み用文字列
   * @property {string} sortColumn ソートに使うにカラム名
   * @property {string} sortDirection ソート方向
   * @property {function(element: DOMNode)} tooltipRef
   * @property {Object[]} users ユーザーの配列(Objectのプロパティはusers/index.jsonのレスポンスを参照)
   * @property {number} usersPerPage 1ページに表示するユーザーの件数
   */
  static get propTypes() {
    return({
      currentPage: PropTypes.number.isRequired,
      currentUserType: PropTypes.string.isRequired,
      editUserPath: PropTypes.string.isRequired,
      emitter: PropTypes.object.isRequired,
      errorMessage: PropTypes.string,
      isPending: PropTypes.bool.isRequired,
      searchInput: PropTypes.string.isRequired,
      sortColumn: PropTypes.oneOf(Underscore.values(Constants.COLUMN)).isRequired,
      sortDirection: PropTypes.oneOf(Underscore.values(Constants.DIRECTION)).isRequired,
      tooltipRef: PropTypes.func,
      users: PropTypes.arrayOf(PropTypes.object).isRequired,
      usersPerPage: PropTypes.number.isRequired,
    });
  }

  /**
   * デフォルトのプロパティを返します。
   *
   * @public
   * @return {Object}
   * @property {null} errorMessage 表示するエラーメッセージ
   */
  static get defaultProps() {
    return({
      errorMessage: null,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.handleClickPaginationPage = this.handleClickPaginationPage.bind(this);
    this.handleClickSortColumn = this.handleClickSortColumn.bind(this);

    this.i18n_text = {
      created_at: I18n.t('created_at', { scope: Constants.I18N_SCOPE }),
      department: I18n.t('department', { scope: Constants.I18N_SCOPE }),
      email: I18n.t('email', { scope: Constants.I18N_SCOPE }),
      full_name: I18n.t('full_name', { scope: Constants.I18N_SCOPE }),
      loading: I18n.t('loading', { scope: Constants.I18N_SCOPE }),
      no_user: I18n.t('no_user', { scope: Constants.I18N_SCOPE }),
      user_type: I18n.t('user_type', { scope: Constants.I18N_SCOPE }),
    };
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    let tableBody = null;

    // 状態に応じてテーブルのコンテンツを生成する
    if (this.props.isPending) {
      tableBody = this.renderIndicator();
    } else if (this.props.errorMessage != null) {
      tableBody = this.renderErrorMessage();
    } else {
      tableBody = this.renderRows();
    }

    return(
      <React.Fragment>
        <table
          className="ca-user-table ca-user-table--above-spacing ca-table-header-no-bordered"
          ref={this.props.tooltipRef}
        >
          <thead>
            <tr className="ca-user-table__header-row">
              <SortableTableHeaderCell
                active={this.props.sortColumn == Constants.COLUMN.FULL_NAME}
                callback={this.handleClickSortColumn}
                classNameForHeaderCell="
                  ca-user-table__header-column
                  ca-user-table__header-column--clickable
                "
                column={Constants.COLUMN.FULL_NAME}
                direction={this.props.sortDirection}
                text={this.i18n_text.full_name}
              />
              <SortableTableHeaderCell
                active={this.props.sortColumn == Constants.COLUMN.USER_TYPE}
                callback={this.handleClickSortColumn}
                classNameForHeaderCell="
                  ca-user-table__header-column
                  ca-user-table__header-column--clickable
                  ca-user-table__header-column--narrowest
                  text-center
                "
                column={Constants.COLUMN.USER_TYPE}
                direction={this.props.sortDirection}
                text={this.i18n_text.user_type}
              />
              <SortableTableHeaderCell
                active={this.props.sortColumn == Constants.COLUMN.DEPARTMENT}
                callback={this.handleClickSortColumn}
                classNameForHeaderCell="
                  ca-user-table__header-column
                  ca-user-table__header-column--clickable
                "
                column={Constants.COLUMN.DEPARTMENT}
                direction={this.props.sortDirection}
                text={this.i18n_text.department}
              />
              <SortableTableHeaderCell
                active={this.props.sortColumn == Constants.COLUMN.EMAIL}
                callback={this.handleClickSortColumn}
                classNameForHeaderCell="
                  ca-user-table__header-column
                  ca-user-table__header-column--clickable
                "
                column={Constants.COLUMN.EMAIL}
                direction={this.props.sortDirection}
                text={this.i18n_text.email}
              />
              <SortableTableHeaderCell
                active={this.props.sortColumn == Constants.COLUMN.CREATED_AT_TIME}
                callback={this.handleClickSortColumn}
                classNameForHeaderCell="
                  ca-user-table__header-column
                  ca-user-table__header-column--clickable
                  ca-user-table__header-column--narrow
                "
                column={Constants.COLUMN.CREATED_AT_TIME}
                direction={this.props.sortDirection}
                text={this.i18n_text.created_at}
              />
              <th className="
                ca-user-table__header-column
                ca-user-table__header-column--narrowest
              "></th>
            </tr>
          </thead>
          <tbody>
            {tableBody}
          </tbody>
        </table>
        <Pagination
          callback={this.handleClickPaginationPage}
          currentPage={this.props.currentPage}
          perPage={this.props.usersPerPage}
          totalItems={this.props.users.length}
        />
      </React.Fragment>
    );
  }

  /**
   * テーブル内に表示するエラーメッセージを返します。
   *
   * @private
   * @return {ReactElement}
   */
  renderErrorMessage() {
    return(
      <tr>
        <td className="ca-user-table__body-column" colSpan="6">
          {this.props.errorMessage}
        </td>
      </tr>
    );
  }

  /**
   * テーブル内に表示するデータ取得中メッセージを返します。
   *
   * @private
   * @return {ReactElement}
   */
  renderIndicator() {
    return(
      <tr>
        <td className="ca-user-table__body-column" colSpan="6">
          {this.i18n_text.loading}
        </td>
      </tr>
    );
  }

  /**
   * テーブル内に表示するユーザー一覧を返します。
   * 戻り値に含まれるユーザーは、現在のページ番号、絞り込み用キーワード、ソート方向を考慮した
   * うえで、現在表示すべきもののみとなります。
   *
   * @private
   * @return {ReactElement[]}
   */
  renderRows() {
    const users = this.getUsersForCurrentPage();

    if (users.length == 0) {
      return(
        <tr>
          <td className="ca-user-table__body-column" colSpan="6">
            {this.i18n_text.no_user}
          </td>
        </tr>
      );
    }

    return(users.map((user) => {
      return(
        <UserTableRow
          editUserPath={
            // 対象がオーナーの場合は、サインイン中のユーザーもオーナーでない限り編集用URLを渡さない
            (user.user_type === 'owner' && this.props.currentUserType !== 'owner')
              ? null
              : this.props.editUserPath
          }
          key={user.id}
          user={user}
        />
      );
    }));
  }

  /**
   * 現在のページに表示するユーザーのみを返します。
   * 戻り値に含まれるユーザーは、現在のページ番号、ソート方向を考慮したうえで、現在ページに
   * 表示すべきもののみとなります。
   *
   * @private
   * @return {Object[]}
   */
  getUsersForCurrentPage() {
    const start = this.props.usersPerPage * (this.props.currentPage - 1);
    const end = this.props.usersPerPage * this.props.currentPage;
    return this.sortUsers(this.props.users).slice(start, end);
  }

  /**
   * ページングナビゲーションのページ番号がクリックされた際の処理を行います。
   *
   * @private
   * @param {number} page ページ番号
   */
  handleClickPaginationPage(page) {
    this.props.emitter.emit(Constants.EVENT_CHANGE_CURRENT_PAGE, page);
  }

  /**
   * ソート用カラムがクリックされた際の処理を行います。
   *
   * @private
   * @param {string} column クリックされたカラム名
   */
  handleClickSortColumn(column) {
    const columnWillChange = column != this.props.sortColumn;
    let newDirection;

    if (columnWillChange) {
      // ソート用カラムが変更になる場合は、ソート方向を昇順にする
      newDirection = Constants.DIRECTION.ASC;
    } else {
      // クリックされたカラムが現在のソート用カラムと同じ場合は、ソート方向を反転させる
      newDirection = this.props.sortDirection == Constants.DIRECTION.ASC ?
        Constants.DIRECTION.DESC :
        Constants.DIRECTION.ASC;
    }

    this.props.emitter.emit(Constants.EVENT_CHANGE_SORT_COLUMN, column, newDirection);
  }

  /**
   * ユーザーを現在のソート用カラムおよびソート方向でソートします。
   * @private
   * @param {Object[]} users ユーザーの配列
   * @return {Object[]} ソート済みのユーザーの配列
   */
  sortUsers(users) {
    // ソートを行う
    const sortedUsers = users.sort((a, b) => {
      // 現在のソート用カラムを利用してユーザーの配列を昇順にソートする
      const valueOfA = a[this.props.sortColumn];
      const valueOfB = b[this.props.sortColumn];
      if (valueOfA < valueOfB) {
        return -1;
      }
      if (valueOfA > valueOfB) {
        return 1;
      }
      return 0;
    });

    if (this.props.sortDirection == Constants.DIRECTION.DESC) {
      return sortedUsers.reverse();
    }
    return sortedUsers;
  }
}

// UserTableコンポーネント内でTooltipを使えるようにしてから外部にエクスポートする
export default withBootstrapTooltip(UserTable);
