import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import ErrorMessages from '../ErrorMessages.jsx';

const I18n = window.I18n; // i18n-js
const I18N_SCOPE = `${Constants.I18N_SCOPE}.post_process_name_field`;

// 後処理名入力フィールド
//
// 以下の責務を持ちます。
//
// - 後処理名入力欄の表示
// - エラーメッセージの表示
// - 変更された値の EventEmitter への通知
//   Constants.EVENT_CHANGE_POST_PROCESS_NAME をイベント名として変更後の値を通知します
//
class PostProcessNameField extends React.Component {
  static get defaultProps() {
    return({
      classesForColumn: 'col-xs-6',
    });
  }

  static get propTypes() {
    return({
      classesForColumn: PropTypes.string,
      emitter: PropTypes.object.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
      value: PropTypes.string.isRequired
    });
  }

  /**
   * エラーがあるかどうかを返します。
   *
   * @return {boolean}
   */
  hasError() {
    return this.props.errors.length > 0;
  }

  render() {
    const errorClass = this.hasError() ? 'error' : '';
    const label = I18n.t('activerecord.attributes.post_process.name');
    const help = I18n.t('help', { scope: I18N_SCOPE });

    return(
      <div className="form-group">
        <div className="post-process-setting">
          <div className="post-process-setting__label">
            <div className="post-process-setting__label__text">
              <label htmlFor="PostProcess-name">
                {label}
              </label>
              <span className="required">*</span>
            </div>
          </div>
          <div className="post-process-setting__form">
            <input
              id="PostProcess-name"
              className={`form-control ${errorClass}`}
              onChange={event => this.props.emitter.emit(Constants.EVENT_CHANGE_FORM_VALUE, 'postProcessName', event.target.value)}
              type="text"
              value={this.props.value}
            />
            <p className="post-process-setting__prompt">
              {help}
            </p>
            <ErrorMessages messages={this.props.errors} />
          </div>
        </div>
      </div>
    );
  }
}

export default PostProcessNameField;
