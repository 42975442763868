import React from 'react';
import PropTypes from 'prop-types';

/**
 * リージョンを切り替えるセレクトボックスコンポーネント
 *
 * プロパティ
 * blankText - 未選択の状態の選択肢として表示する文字列。
 * callback  - リージョンが変更された際に呼び出す関数。変更後の値が引数として渡される。
 * current   - リージョンの現在値(us-east-1のような識別子)。未選択の場合は空文字列。
 * disabled  - disabled状態を表すboolean情報。
 * htmlId    - SELECT要素に設定するid属性値。LABELタグと連携させる際に利用。
 * options   - 選択可能なリージョンの配列。
 *             [{ name: '米国東部 (バージニア北部)', value: 'us-east-1' }, ...]
 * selectClassName - SELECT要素のclass属性値として設定する文字列。省略した場合はデフォルトの値が使われる。
 *
 * Examples
 *
 *   <RegionSelector
 *     blankText="選択してください"
 *     callback={this.handleRegionChange}
 *     current={region}
 *     options={regions}
 *   />
 */
class RegionSelector extends React.Component {
  /**
   * propTypes
   * @property {}
   */
  static get propTypes() {
    return({
      blankText: PropTypes.string,
      callback: PropTypes.func.isRequired,
      current: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      htmlId: PropTypes.string,
      options: PropTypes.array.isRequired,
      selectClassName: PropTypes.string,
    });
  }

  static get defaultProps() {
    return({
      blankText: null,
      disabled: false,
      htmlId: null,
      selectClassName: '',
    });
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.blankText = props.blankText || '';
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return (
      <select
        className={this.props.selectClassName}
        id={this.props.htmlId}
        onChange={this.handleChange}
        value={this.props.current}
        disabled={this.props.disabled}
      >
        <option value="">{this.blankText}</option>
        {this.props.options.map((region) => {
          return (
            <option
              key={region.value}
              value={region.value}
            >
              {region.name}
            </option>
          );
        })}
      </select>
    );
  }

  /**
   * セレクトボックスの値が変更された際に呼び出されるイベントハンドラ。
   * @private
   * @param {SyntheticEvent} event
   */
  handleChange(event) {
    event.preventDefault();
    this.props.callback(event.target.value);
  }
}

export default RegionSelector;
