import React, { useState } from "react";

/**
 * カラーコード (実体は文字列だが、コードの可読性のため型名を付ける)
 */
type Color = string;

const $ = jQuery;

/**
 * 選択肢として表示するカラーコード6列4行分の配列
 */
const groupColors: Color[] = [
  "#aa0441",
  "#aa671d",
  "#4c7a34",
  "#005bb1",
  "#740493",
  "#232323",
  "#e93578",
  "#ff6624",
  "#72bb53",
  "#00b1f8",
  "#a658ef",
  "#606060",
  "#ff96bb",
  "#ffa834",
  "#aedd94",
  "#7adbf6",
  "#b892fc",
  "#c0c0c0",
  "#ffd7f7",
  "#ffe3ae",
  "#e2ee79",
  "#c9f1fd",
  "#dacafb",
  "#ebebeb",
];
const firstGroupColors = groupColors.slice(0, 6);
const secondGroupColors = groupColors.slice(6, 12);
const thirdGroupColors = groupColors.slice(12, 18);
const fourthGroupColors = groupColors.slice(18);

/**
 * グループカラー選択UI
 */
// TODO: グループが新UIになったら旧コンポーネントを削除してリネームする
export const GroupColorSelector2: React.FC<{
  onChange: (value: string) => void;
  selectedColor: string;
}> = (props) => {
  // 現在選択されているカラーの値
  const [value, setValue] = useState(props.selectedColor);

  /**
   * 色を選択のためにクリックした時のハンドラ
   * 選択された色の切り替えと、カラーパレットを閉じる処理を行う
   *
   * @property {string} color カラーコード
   */
  const handleOnClick = (color: string): void => {
    setValue(color);
    props.onChange(color);
    $("#ca-group-color-selector").collapse("hide");
  };

  /**
   * カラーコードをもとに ColorElement を生成して返します。
   */
  const buildColorElement = (color: Color): JSX.Element => {
    return <ColorElement checked={color == value} color={color} key={color} onClick={handleOnClick} />;
  };

  /**
   * カラーコードの配列をもとに ColorElement の配列を生成して返します。
   */
  const buildColorElements = (colors: Color[]): JSX.Element => {
    return <>{colors.map(buildColorElement)}</>;
  };

  return (
    <div className="ca-group-color-selector">
      <button
        type="button"
        className="form-control inline-block ca-group-color-selector__btn"
        data-toggle="collapse"
        data-target="#ca-group-color-selector"
        aria-controls="ca-group-color-selector"
      >
        <span aria-hidden="true" className="fa fa-briefcase" style={{ color: value }}></span>
      </button>

      <div className="collapse ca-group-color-selector__picker" id="ca-group-color-selector" style={{ zIndex: 1 }}>
        <div className="well ca-group-color-selector__picker__inner">
          <div>{buildColorElements(firstGroupColors)}</div>
          <div>{buildColorElements(secondGroupColors)}</div>
          <div>{buildColorElements(thirdGroupColors)}</div>
          <div>{buildColorElements(fourthGroupColors)}</div>
        </div>
      </div>
    </div>
  );
};

/**
 * カラーピッカー内の1つの色をあらわすセル
 */
const ColorElement: React.FC<{
  checked: boolean;
  color: string;
  onClick: (color) => void;
}> = (props) => {
  return (
    <i
      aria-hidden="true"
      className={props.checked ? "fa fa-check-circle" : "fa fa-circle"}
      key={props.color}
      onClick={(e): void => {
        e.preventDefault();
        props.onClick(props.color);
      }}
      style={{ color: props.color }}
    />
  );
};
