import React from 'react';
import PropTypes from 'prop-types';

const jQuery = window.jQuery;

/**
 * 汎用ローディング表示コンポーネント
 * jQuery と spin.js を利用しています。
 * http://spin.js.org/
 *
 * 以下のように親コンポーネント内でSpinnerコンポーネントを描画するかどうか切り替えている
 * 場合は、loading プロパティを指定する必要はありません。
 *
 *   if (this.state.isLoading) {
 *     return <Spinner />;
 *   }
 *
 * 親コンポーネント内で常にSpinnerコンポーネントを描画する場合は、loading プロパティに与える
 * 値を変更することで、表示状態の切り替えを行います。
 *
 *   <Spinner loading={this.state.isLoading} />
 *
 * このコンポーネントの描画結果は常にSPAN要素となります。
 */
export default class Spinner extends React.Component {
  /**
   * propTypes
   * @return {object}
   * @property {boolean} loading スピナーを表示するかどうか
   * @property {object} options spin.jsに渡すオプション
   */
  static get propTypes() {
    return({
      loading: PropTypes.bool,
      options: PropTypes.object,
    });
  }

  /**
   * @return {object}
   */
  static get defaultProps() {
    return({
      loading: true,
      options: {
        lines: 9,
        length: 3,
        width: 3,
        radius: 5,
        top: '15px',
        left: '220px',
      },
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);
    this.element = null;
  }

  /**
   * @override
   */
  componentDidMount() {
    // props, stateを操作しないので無限ループにならずに更新できる
    if (this.props.loading) {
      jQuery(this.element).spin(this.props.options);
    } else {
      jQuery(this.element).spin(false);
    }
  }

  /**
   * @override
   */
  componentDidUpdate() {
    if (this.props.loading) {
      jQuery(this.element).spin(this.props.options);
    } else {
      jQuery(this.element).spin(false);
    }
  }

  /**
   * @return {ReactElement|null}
   */
  render() {
    return <span ref={(span) => { this.element = span; }}></span>;
  }
}
