import React from "react";
import Modal from "react-modal";
import jQuery from "jquery";

type Props = {
  calendarId: string;
};

export const DeleteCalendarButton = (props: Props): JSX.Element => {
  const I18n = window.I18n;

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const closeModal = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>): void => {
    e.preventDefault();
    setIsOpen(false);
  };

  const hanleDeleteButtonClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    const url = `/calendars/${props.calendarId}`;
    jQuery
      .ajax({
        url: url,
        method: "DELETE",
        dataType: "json",
        data: { authenticity_token: jQuery('[name="csrf-token"]').attr("content") },
      })
      .then(
        (data) => {
          window.location.assign(data.url);
        },
        (data) => {
          const msg: string = data.responseJSON?.message ?? "";
          setErrorMessage(msg);
        }
      );
  };

  const openModal = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setIsOpen(true);
  };

  return (
    <div className="ca-btn-block__secondary">
      <a className="ca-btn-block__link text-danger" onClick={openModal} href="javascript:void(0)">
        <span className="fa fa-trash marginR5" aria-hidden="true"></span>
        {I18n.t("javascript.calendars.destroy.title")}
      </a>

      <Modal
        appElement={document.getElementById("ca-calendars-setting-container") ?? undefined}
        className="ca-calendars-delete-modal__content"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        overlayClassName="ca-calendars-delete-modal__overlay"
        role="dialog"
      >
        <div className="ca-calendars-delete-modal__content-message">
          <p>{I18n.t("javascript.calendars.destroy.confirm")}</p>
          {errorMessage !== "" && (
            <p>
              <label className="error">{errorMessage}</label>
            </p>
          )}
        </div>
        <div className="ca-calendars-delete-modal__content-buttons">
          <button className="btn btn-cancel btn-negative" onClick={closeModal} type="button">
            <i className="fa fa-ban marginR5" aria-hidden="true"></i>
            Cancel
          </button>
          <button className="btn btn-danger btn-delete" onClick={hanleDeleteButtonClick} type="button">
            <i className="fa fa-trash-o marginR5" aria-hidden="true"></i>
            Delete
          </button>
        </div>
      </Modal>
    </div>
  );
};
