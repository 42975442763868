import React from "react";
import { SQSJob, TriggerJob } from "./types";
import { OldFirstJobMigrationMessage } from "./OldFirstJobMigrationMessage";

const I18n = window.I18n; // i18n-js

/**
 * SQSトリガーの詳細を表示します。
 * @public
 */
export const SQSTriggerDetails = (job: SQSJob): JSX.Element => {
  return (
    <div className="ca-workflow-trigger">
      <div className="ca-workflow-trigger__icon-area">
        <div className="ca-workflow-trigger__icon">
          <i className="fa-duotone fa-solid fa-bullseye-pointer"></i>
        </div>
        SQS
        <br />
        {I18n.t("activerecord.attributes.trigger_job.rule_type")}
      </div>

      <div className="ca-workflow-trigger__info-area">
        {/* TODO: 機能フラグ v27_job_workflow_improvement が不要になったら OldFirstJobMigrationMessage を削除する */}
        <OldFirstJobMigrationMessage job={job as TriggerJob} />
        <div className="ca-workflow-trigger__item">
          <div className="ca-workflow-trigger__label">{I18n.t("activerecord.attributes.trigger_job.queue")}</div>
          <div className="ca-workflow-trigger__data">{job.queue}</div>
        </div>

        <div className="ca-workflow-trigger__item">
          <div className="ca-workflow-trigger__label">{I18n.t("activerecord.attributes.trigger_job.sqs_region")}</div>
          <div className="ca-workflow-trigger__data">{job.sqs_region}</div>
        </div>

        <div className="ca-workflow-trigger__item">
          <div className="ca-workflow-trigger__label">{I18n.t("activerecord.attributes.trigger_job.aws_account")}</div>
          <div className="ca-workflow-trigger__data">{job.sqs_aws_account_name}</div>
        </div>
      </div>
    </div>
  );
};
