import React from "react";
import PropTypes from "prop-types";

import Icon from "../Icon.jsx";

import { ErrorArea } from "./ErrorArea.jsx";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.aws_account.iam_role";

// IAMロールセットアップ用CFnスタック作成ページを表示するボタンのコンポーネント
export class IamRoleSetupButton extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {boolean} disabled 入力欄が有効か否か
   * @property {Object} errors 各入力欄の下に表示するエラーメッセージの配列をプロパティ値として持つオブジェクト
   * @property {Object} onClick ロール作成ボタンをクリックしたときに呼び出される関数
   */
  static get propTypes() {
    return {
      disabled: PropTypes.bool.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
      onClick: PropTypes.func.isRequired,
    };
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return (
      <div className="ca-group__aws-account-form__right-col">
        <button className="btn btn-default" disabled={this.props.disabled} onClick={this.props.onClick} type="button">
          <Icon name="external-link" />
          <span className="marginL5">{I18n.t("create_iam_role", { scope: I18N_SCOPE })}</span>
        </button>
        <ErrorArea errors={this.props.errors} />
        <p className="ca-group__aws-account-form__note">{I18n.t("create_iam_role_note", { scope: I18N_SCOPE })}</p>
      </div>
    );
  }
}
