import EventEmitter from "events";
import React from "react";
import PropTypes from "prop-types";

import RemoveUserDialog from "./RemoveUserDialog.jsx";
import withBootstrapTooltip from "../../withBootstrapTooltip.js";

const I18n = window.I18n;

/**
 * グループからユーザーを取り除くダイアログを表示するボタン
 * このコンポーネントでは実際の処理は行わずダイアログの制御とボタンの表示を行う
 *
 * @return {ReactElement}
 */
class RemoveUserButton extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @property {number} groupId グループのID
   * @property {object} reloadEmitter グループ一覧をリロードするイベントを発火するオブジェクト。
   * @property {?function(element: DOMNode)} tooltipRef
   * @property {number} userId 除外対象となるユーザーのID
   * @property {number} userName 除外対象となるユーザーの名前
   */
  static get propTypes() {
    return {
      groupId: PropTypes.number.isRequired,
      reloadEmitter: PropTypes.object.isRequired,
      tooltipRef: PropTypes.func,
      userId: PropTypes.number.isRequired,
      userName: PropTypes.string.isRequired
    };
  }

  /**
   * コンストラクタ
   *
   * ステート
   * showRemoveUserDialog - ユーザー除外ダイアログを表示するかどうかの制御に利用します
   */
  constructor(props) {
    super(props);

    this.state = {
      showRemoveUserDialog: false
    };

    this.emitter = new EventEmitter();
    this.emitter.on("closeDialog", this.handleCloseDialog.bind(this));
    this.emitter.on("openDialog", this.handleOpenDialog.bind(this));
  }

  /**
   * ユーザー除外ダイアログを閉じるハンドラ
   */
  handleCloseDialog() {
    this.setState({ showRemoveUserDialog: false });
  }

  /**
   * ユーザー除外ダイアログを開くハンドラ
   */
  handleOpenDialog() {
    this.setState({ showRemoveUserDialog: true });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return (
      <div ref={this.props.tooltipRef}>
        <button
          className="btn btn-default"
          type="button"
          data-toggle="tooltip"
          data-placement="bottom"
          title={I18n.t("remove", { scope: "javascript.group_user" })}
          onClick={() => {
            this.emitter.emit("openDialog");
          }}
        >
          <span className="fa fa-trash-o" />
        </button>
        <RemoveUserDialog
          emitter={this.emitter}
          groupId={this.props.groupId}
          reloadEmitter={this.props.reloadEmitter}
          userId={this.props.userId}
          userName={this.props.userName}
          showRemoveUserDialog={this.state.showRemoveUserDialog}
        />
      </div>
    );
  }
}

export default withBootstrapTooltip(RemoveUserButton);
