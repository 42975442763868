import React from 'react';
import PropTypes from 'prop-types';

import Constants from '../policy_set_form/Constants.js';
import PolicyParameterStringField from '../policy_set_form/PolicyParameterStringField.jsx';
import PolicyParameterStringsField from '../policy_set_form/PolicyParameterStringsField.jsx';
import PolicyTriggerType from '../PolicyTriggerType.jsx';
import ReadOnlyPostProcessAssignmentsPanel from './ReadOnlyPostProcessAssignmentsPanel.jsx';

const _ = window._; // Underscore.js
const I18n = window.I18n; // i18n-js

// ポリシー詳細表示コンポーネント
class Policy extends React.Component {
  /**
   * プロパティ定義を返します
   *
   * @public
   * @property {number[]} failedPostProcessIds 失敗時の後処理のID
   * @property {Group} group ポリシーセットが属するグループ
   * @property {Policy} policy ポリシー
   * @property {PostProcess} postProcess ポリシーに設定されている後処理
   * @property {number[]} succeededPostProcessIds 成功時の後処理のID
   * @property {string|string[]} triggerType ポリシーのトリガータイプ(Periodic/configuration change)
   */
  static get propTypes() {
    return({
      failedPostProcessIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      group: PropTypes.object.isRequired,
      policy: PropTypes.object,
      postProcesses: PropTypes.arrayOf(PropTypes.object).isRequired,
      succeededPostProcessIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      triggerType: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <div className="policy-fieldset">
        {this.renderPolicyState()}
        <fieldset className="policy-fieldset__fieldset">
          <legend>
            <PolicyTriggerType
                triggerType={this.props.triggerType}
            />
          </legend>
          <div className="policy-fieldset__div">
            <span className="policy-fieldset__span">
              {this.props.policy.name}
            </span>
          </div>
          {this.renderContents()}
        </fieldset>
      </div>
    );
  }

  /**
   * @public
   * @return {ReactElement}
   */
  renderContents() {
    if (!this.props.policy.enabled) {
      return null;
    }

    return(
      <div>
        <p>{this.props.policy.description}</p>
        {this.renderParameterFields()}
        {this.renderPostProcessAssignmentsPanel()}
      </div>
    );
  }

  /**
   * @public
   * @return {ReactElement}
   */
  renderParameterFields() {
    return(
      this.props.policy.templateParameters.fields.map((field) => {
        // 暗黙的パラメーターは表示しない
        if (_.contains(Constants.IMPLICIT_PARAMETER_NAMES, field.name)) {
          return null;
        }

        const label = I18n.locale === "ja" ? field.label.ja : field.label.en;

        if (field.type === Constants.PARAMETER_FIELD_TYPE_STRING) {
          return(
            <PolicyParameterStringField
              emitter={null}
              initialValue={this.props.policy.parameters[field.name]}
              key={field.name}
              label={label}
              name={field.name}
              policyTemplateId={this.props.policy.templateId}
              readOnly={true}
              required={false}
            />
          );
        }

        if (field.type === Constants.PARAMETER_FIELD_TYPE_STRINGS) {
          return(
            <PolicyParameterStringsField
              emitter={null}
              initialValues={this.props.policy.parameters[field.name]}
              key={field.name}
              label={label}
              name={field.name}
              policyTemplateId={this.props.policy.templateId}
              readOnly={true}
              required={false}
            />
          );
        }
      })
    );
  }

  /**
   * @public
   * @return {ReactElement}
   */
  renderPostProcessAssignmentsPanel() {
    return(
      <div className="
        policy-fieldset__post-process-assignments-panel
        policy-fieldset-fields-appear-active
      ">
        <div className="ca-two-panel-layout">
          <div className="ca-two-panel-layout__primary">
            <ReadOnlyPostProcessAssignmentsPanel
              assignments={this.props.succeededPostProcessIds}
              group={this.props.group}
              panelClass="ca-post-process-assignments-panel--success"
              postProcesses={this.props.postProcesses}
              title={I18n.t('common.policy_set.on_compliant')}
              titleIcon="fa-check-circle"
            />
          </div>
          <div className="ca-two-panel-layout__secondary">
            <ReadOnlyPostProcessAssignmentsPanel
              assignments={this.props.failedPostProcessIds}
              group={this.props.group}
              panelClass="ca-post-process-assignments-panel--failed"
              postProcesses={this.props.postProcesses}
              title={I18n.t('common.policy_set.on_not_compliant')}
              titleIcon="fa-times"
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   * @public
   * @return {ReactElement}
   */
  renderPolicyState() {
    const stateClass = this.props.policy.enabled ? 'policy__state--on' : 'policy__state--off';

    return(
      <div className={`pull-right policy__state ${stateClass}`} />
    );
  }
}

export default Policy;
