import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import ErrorMessages from '../ErrorMessages.jsx';

const I18n = window.I18n; // i18n-js
const I18N_SCOPE = `${Constants.I18N_SCOPE}.sqs_region_field`;

// SQS用リージョン選択フィールド
class SqsRegionField extends React.Component {
  static get propTypes() {
    return({
      regions: PropTypes.arrayOf(PropTypes.array).isRequired,
      emitter: PropTypes.object.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
      value: PropTypes.string.isRequired
    });
  }

  /**
   * エラーがあるかどうかを返します。
   *
   * @return {boolean}
   */
  hasError() {
    return this.props.errors.length > 0;
  }

  render() {
    const errorClass = this.hasError() ? 'error' : '';
    const label = I18n.t('activerecord.attributes.post_process.sqs_region');

    return(
      <div className="form-group">
        <div className="post-process-setting">
          <div className="post-process-setting__label">
            <div className="post-process-setting__label__text">
              <label htmlFor="PostProcess-sqsRegion">
                {label}
              </label>
              <span className="required">*</span>
            </div>
          </div>
          <div className="post-process-setting__form">
            <select id="PostProcess-sqsRegion"
              className={`form-control ${errorClass}`}
              onChange={event => this.props.emitter.emit(Constants.EVENT_CHANGE_SQS_REGION, event.target.value)}
              value={this.props.value}>
              {this.renderOptions()}
            </select>
            <ErrorMessages messages={this.props.errors} />
          </div>
        </div>
      </div>
    );
  }

  renderOptions() {
    const blankOption = [I18n.t('blank_option', { scope: I18N_SCOPE }), ''];
    const options = [blankOption].concat(this.props.regions);

    return(options.map((item) => {
      const label = item[0];
      const value = item[1];
      return(
        <option key={value} value={value}>{label}</option>
      );
    }));
  }
}

export default SqsRegionField;
