import React from "react";
import PropTypes from "prop-types";

import Constants from "./Constants.js";
import AwsAccountSelector from "./AwsAccountSelector.jsx";
import Pagination from "./Pagination.jsx";
import SortableTableHeaderCell from "./SortableTableHeaderCell.jsx";
import GroupSelector from "./GroupSelector.jsx";
import GroupIconTip from "./GroupIconTip.jsx";

const $ = window.jQuery;
const _ = window._; // Underscore.js
const I18n = window.I18n; // i18n-js

/**
 * リソーステーブルを含むコンテナコンポーネント
 *
 * ステート
 * awsAccountId  - 現在のAWSアカウントID
 *                 初期値は null
 * awsAccounts   - AWSアカウントの選択肢の配列
 *                 [{ name: '...', id: 1 }, ...]
 * currentPage   - 現在のページ番号
 *                 初期値は 1
 * connections   - 進行中のAJAX通信の数
 *                 初期値は 0
 * message       - テーブルにデータが無い場合に表示するメッセージ
 * perPage       - 1ページあたりの表示件数
 *                 初期値は 10
 * resources     - 現在のAWSアカウントおよびリソース種別に該当するすべてのリソースを
 *                 あらわすオブジェクトの配列
 *                 source プロパティのURLから返されるJSONの resources キーの値が設定される
 *                 初期値は空の配列
 * resourceType  - 選択されているリソース種別
 * sortColumn    - ソートに使うカラム識別子
 *                 Constants.COLUMN.* のいずれかを設定する
 *                 初期値は Constants.COLUMN.NAME
 * sortDirection - ソートの方向
 *                 Constants.DIRECTION.ASC または Constants.DIRECTION.DESC を設定する
 *                 初期値は Constants.DIRECTION.ASC
 *
 * AWSアカウントまたはリソース種別が変更された際にサーバーと通信を行い、その
 * AWSアカウントの全リージョンからリソース情報を取得して resources ステートに
 * 格納します。
 *
 * 表示件数が変更された場合およびページング操作が行われた場合には、サーバーとの
 * 通信は行わずに、resources ステートから現在ページに表示される範囲のみを取り出して
 * 利用します。
 *
 * Examples
 *
 *   ResourceTableContainerComponent = React.createFactory(ResourceTableContainer)
 *
 *   React.render(
 *     ResourceTableContainerComponent()
 *     document.getElementById('js-resource-table-container')
 *   );
 *
 *   React.render(
 *     ResourceTableContainerComponent(source: '/resources.json')
 *     document.getElementById('js-resource-table-container')
 *   );
 *
 */
export default class ResourceTableContainer extends React.Component {
  /**
   * propTypes
   * @return {object}
   * @property {string} loadingImagePath ローディング中に表示する画像のURLパス
   * @property {array} groups Groupオブジェクトの入った配列
   * @property {string} source JSONを取得する際にアクセスするURLパス
   */
  static get propTypes() {
    return {
      loadingImagePath: PropTypes.string.isRequired,
      groups: PropTypes.array.isRequired,
      source: PropTypes.string,
    };
  }

  /**
   * デフォルトのプロパティ値を返します。
   * React内部から呼び出されます。
   *
   * @return {object}
   */
  static get defaultProps() {
    return {
      source: Constants.URL.RESOURCES_SOURCE,
    };
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);

    this.state = this.initializeState();

    this.handleAwsAccountIdChange = this.handleAwsAccountIdChange.bind(this);
    this.handleGroupIdChange = this.handleGroupIdChange.bind(this);
    this.handlePaginationPageClick = this.handlePaginationPageClick.bind(this);
    this.handleResourceTypeChange = this.handleResourceTypeChange.bind(this);
    this.handleSortLinkClick = this.handleSortLinkClick.bind(this);
  }

  /**
   * コンポーネントの初期ステートを返します。
   */
  initializeState() {
    // リソース種別の現在値をCookieから取得する
    const resourceType = $.cookie(Constants.COOKIE.SELECTED_RESOURCE_TYPE) || Constants.RESOURCE_TYPE.EC2_INSTANCE;

    return {
      awsAccountId: null,
      awsAccounts: [],
      connections: 0,
      currentPage: 1,
      groupId: null,
      message: I18n.t("javascript.resource_table.no_resources"),
      perPage: 30,
      resources: [],
      resourceType: resourceType,
      sortColumn: Constants.COLUMN.NAME,
      sortDirection: Constants.DIRECTION.ASC,
    };
  }

  /**
   * @return {ReactElement}
   */
  render() {
    // AWSアカウントが未選択かどうかのフラグ
    const awsAccountNotSelected = this.state.awsAccountId == null;
    const groupNotSelected = this.state.groupId == null;
    const resources = this.getResourcesForCurrentPage();
    const loading = this.state.connections > 0;

    return (
      <React.StrictMode>
        <div className="clearfix">
          <div className="table-actions ca-resources-selector">
            <GroupSelector
              disabled={false}
              onChange={this.handleGroupIdChange}
              options={this.props.groups}
              selectedIds={[this.state.groupId]}
            />
            <AwsAccountSelector
              options={this.state.awsAccounts}
              disabled={groupNotSelected}
              current={this.state.awsAccountId || ""}
              callback={this.handleAwsAccountIdChange}
            />
            <ResourceTypeSelector
              resourceType={this.state.resourceType}
              callback={this.handleResourceTypeChange}
              disabled={awsAccountNotSelected}
            />
          </div>
          <ResourceTable
            awsAccountId={this.state.awsAccountId}
            resources={resources}
            resourceType={this.state.resourceType}
            loading={loading}
            loadingImagePath={this.props.loadingImagePath}
            message={this.state.message}
            sortColumn={this.state.sortColumn}
            sortDirection={this.state.sortDirection}
            callback={this.handleSortLinkClick}
          />
          <Pagination
            totalItems={this.state.resources.length}
            perPage={this.state.perPage}
            currentPage={this.state.currentPage}
            callback={this.handlePaginationPageClick}
          />
        </div>
      </React.StrictMode>
    );
  }

  /**
   * 現在のソート方向を逆転したものを返します。
   * コンポーネントの状態は変更しません。
   * @return {string}
   */
  getInvertedSortDirection() {
    if (this.state.sortDirection == Constants.DIRECTION.ASC) {
      return Constants.DIRECTION.DESC;
    }
    return Constants.DIRECTION.ASC;
  }

  /**
   * 現在のページに表示するリソースを返します。
   *
   * ステートの currentPage と perPage の値をもとに、現在のページに表示する
   * リソースの範囲を算出し、resources ステートの部分配列を返します。
   *
   * @return {array}
   */
  getResourcesForCurrentPage() {
    const perPage = this.state.perPage;

    // 現在のページに表示する要素の開始インデックス（1ページ目の場合は0）
    const begin = (this.state.currentPage - 1) * perPage;

    // 現在のページに表示する要素の終了インデックス（このインデックスで返される要素は含まれない）
    const end = begin + perPage;

    return this.state.resources.slice(begin, end);
  }

  /**
   * 現在のAWSアカウントおよびリソース種別のリソース情報をJSONとして
   * 取得するためのURLを返します。
   * @return {string}
   */
  getResourcesPageURL() {
    return (
      this.props.source + `?aws_account_id=${this.state.awsAccountId}` + `&resource_type=${this.state.resourceType}`
    );
  }

  /**
   * グループIDが変更された際の処理を実行します。
   * resources ステートを更新するためにサーバーとの通信を行います。
   * @param {number} value 変更後のAWSアカウントID。未選択の場合は空文字列
   */
  handleGroupIdChange(value) {
    // グループ選択情報は配列で返る
    const groupId = value.length ? value[0].value : null;

    this.setState({
      awsAccountId: null,
      awsAccounts: [],
      groupId: groupId,
    });

    this.loadAwsAccounts(null, groupId);
  }

  /**
   * AWSアカウントが変更された際の処理を実行します。
   * resources ステートを更新するためにサーバーとの通信を行います。
   * @param {number} value 変更後のAWSアカウントID。未選択の場合は空文字列
   */
  handleAwsAccountIdChange(value) {
    this.updateStateAndReloadResources({
      awsAccountId: value == "" ? null : Number(value),
      currentPage: 1,
    });
  }

  /**
   * ページングナビゲーションのページ番号がクリックされた際の処理を実行します。
   * サーバーとの通信は行いません。
   * @param {number} page ページ番号
   */
  handlePaginationPageClick(page) {
    this.setState({ currentPage: page });
  }

  /**
   * リソース種別が変更された際の処理を実行します。
   * resources ステートを更新するためにサーバーとの通信を行います。
   * @param {string} value 変更後のリソース種別。
   */
  handleResourceTypeChange(value) {
    // 選択された値をCookieに保存する
    $.cookie(Constants.COOKIE.SELECTED_RESOURCE_TYPE, value);

    this.updateStateAndReloadResources({ resourceType: value });
  }

  /**
   * 特定のカラムでソートするリンクがクリックされた際の処理を実行します。
   * サーバーとの通信は行いません。
   * @param {string} column クリックされたカラムの識別子
   */
  handleSortLinkClick(column) {
    const shouldInvertCurrentDirection = column == this.state.sortColumn;
    const sortDirection = shouldInvertCurrentDirection ? this.getInvertedSortDirection() : Constants.DIRECTION.ASC;
    const resources = this.sortResources(this.state.resources, column, sortDirection);

    this.setState({
      currentPage: 1,
      resources: resources,
      sortColumn: column,
      sortDirection: sortDirection,
    });
  }

  /**
   * 選択可能なAWSアカウントをサーバーから取得します。
   * @param {func} callback 正常終了後に呼び出す関数。
   */
  loadAwsAccounts(callback, groupId = null) {
    // グループIDがnullの場合は何もしない
    if (groupId == null) {
      return;
    }

    const that = this;
    const ajaxProcess = () => {
      $.ajax({
        context: that, // コールバック関数内のthisがthatを指すようにする
        data: { group_id: this.state.groupId },
        dataType: "json",
        url: "/aws_accounts",
      }).done((data) => {
        // レスポンスのJSONフォーマットをチェックする
        const requiredKeys = ["aws_accounts"];
        let invalidFormat = false;
        requiredKeys.forEach((key) => {
          if (data[key] == undefined) {
            invalidFormat = true;
          }
        });

        if (invalidFormat) {
          this.setState({ connections: this.state.connections - 1 });
          throw "Couldn't fetch aws accounts information: Response is invalid format.";
        }

        // AWSアカウントが未登録の場合はエラーメッセージを設定して終了する
        if (data.aws_accounts.length == 0) {
          this.setState({
            awsAccounts: [],
            connections: this.state.connections - 1,
            message: I18n.t("javascript.resource_table.no_aws_accounts"),
          });
          return;
        }

        // AWSアカウントが存在する場合はステートの更新後にコールバックを実行する
        this.setState(
          {
            awsAccounts: data.aws_accounts,
            connections: this.state.connections - 1,
          },
          callback
        );
      });
    };

    // 進行中のAjax通信数をインクリメントしてから、通信を開始する
    this.setState(
      {
        connections: this.state.connections + 1,
      },
      ajaxProcess
    );
  }

  /**
   * 現在のAWSアカウントおよびリソース種別に該当するすべてのリソースを
   * サーバーから取得します。
   */
  loadResources() {
    if (this.state.awsAccountId == null) {
      // AWSアカウントが未選択の場合はリソースの取得を行わない
      this.setState({
        currentPage: 1,
        resources: [],
        message: I18n.t("javascript.resource_table.select_aws_account"),
      });
      return;
    }

    const that = this;
    const ajaxProcess = () => {
      $.ajax({
        context: that, // コールバック関数内のthisがthatを指すようにする
        url: that.getResourcesPageURL(),
      })
        .done((data) => {
          // レスポンスのJSONフォーマットをチェックする
          const requiredKeys = ["resources"];
          let invalidFormat = false;
          requiredKeys.forEach((key) => {
            if (data[key] == undefined) {
              invalidFormat = true;
            }
          });
          if (invalidFormat) {
            this.setState({
              connections: this.state.connections - 1,
              currentPage: 1,
              resources: [],
              message: I18n.t("javascript.resource_table.invalid_server_response"),
            });
            return;
          }

          this.setState({
            connections: this.state.connections - 1,
            currentPage: 1,
            message: null,
            resources: this.sortResources(data.resources, this.state.sortColumn, this.state.sortDirection),
          });
        })
        .fail(() => {
          this.setState({
            connections: this.state.connections - 1,
            currentPage: 1,
            resources: [],
            message: I18n.t("javascript.resource_table.load_failed"),
          });
        });
    };

    // 進行中のAjax通信数をインクリメントしてから、通信を開始する
    this.setState(
      {
        connections: this.state.connections + 1,
      },
      ajaxProcess
    );
  }

  /**
   * 渡されたリソースの配列をソートした配列を新たに生成して返します。
   * この関数はコンポーネントのステートにアクセスしません。
   *
   * Examples
   *
   *   var sortedResources = this.sortResources(resources, Constants.COLUMN.NAME, Constants.DIRECTION.DESC);
   *
   * @param {array} resources リソースの配列
   * @param {string} column ソートに使うカラムの識別子
   * @param {string} direction ソート方向
   * @return {array}
   */
  sortResources(resources, column, direction) {
    if (resources.length == 0) {
      return resources;
    }

    // 逆順ソートが可能かどうか判断するため、各行のソート対象カラムの値からユニークなものを抽出する
    const values = _.chain(resources)
      .map((resource) => resource[column])
      .uniq()
      .value();
    const descendible = values.length > 1;

    let sortedResources = _.sortBy(resources, (resource) => resource[column]);

    if (descendible && direction == Constants.DIRECTION.DESC) {
      sortedResources = sortedResources.reverse();
    }

    return sortedResources;
  }

  /**
   * ステートを更新し、新しいステートにもとづいてサーバーからリソースを取得します。
   * @param {obect} newState 現在のステートにマージするオブジェクト
   */
  updateStateAndReloadResources(newState) {
    this.setState(newState, () => {
      this.loadResources();
    });
  }
}

/**
 * リソース種別を切り替えるセレクトボックスコンポーネント
 *
 * プロパティ
 * callback     - リソース種別が変更された際に呼び出す関数。変更後の値が引数として渡される。
 * disabled     - セレクトボックスが無効かどうかのフラグ。
 * resourceType - 選択されているリソース種別。
 *
 * Examples
 *
 *   <ResourceTypeSelector resourceType={resourceType}
 *                         callback={this.handleResourceTypeChange} />
 *
 * このコンポーネントの描画結果はSPAN要素となります。
 */
class ResourceTypeSelector extends React.Component {
  /**
   * propTypes
   * @property {}
   */
  static get propTypes() {
    return {
      callback: PropTypes.func.isRequired,
      disabled: PropTypes.bool.isRequired,
      resourceType: PropTypes.string.isRequired,
    };
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return (
      <span>
        <select
          onChange={this.handleChange}
          value={this.props.resourceType}
          className="form-control inline-block vertical-middle width-auto marginL10"
          disabled={this.props.disabled}
        >
          <option value={Constants.RESOURCE_TYPE.EC2_INSTANCE}>EC2 Instance</option>
          <option value={Constants.RESOURCE_TYPE.EC2_VOLUME}>EBS Volume</option>
          <option value={Constants.RESOURCE_TYPE.EC2_SNAPSHOT}>EBS Snapshot</option>
          <option value={Constants.RESOURCE_TYPE.EC2_IMAGE}>AMI</option>
          <option value={Constants.RESOURCE_TYPE.ELB_LOAD_BALANCER}>ELB Load Balancer</option>
          <option value={Constants.RESOURCE_TYPE.RDS_DB_INSTANCE}>RDS Instance</option>
          <option value={Constants.RESOURCE_TYPE.RDS_DB_SNAPSHOT}>RDS Snapshot</option>
          <option value={Constants.RESOURCE_TYPE.ROUTE53_HOSTED_ZONE}>Route 53 Hosted Zone</option>
          <option value={Constants.RESOURCE_TYPE.REDSHIFT_CLUSTER}>Redshift Cluster</option>
        </select>
      </span>
    );
  }

  /**
   * セレクトボックスの値が変更された際に呼び出されるイベントハンドラ。
   * @param {SyntheticEvent} event
   */
  handleChange(event) {
    event.preventDefault();
    this.props.callback(event.target.value);
  }
}

/**
 * リソーステーブルコンポーネント
 *
 * このコンポーネントの描画結果はTABLE要素となります。
 */
class ResourceTable extends React.Component {
  /**
   * propTypes
   * @return {object}
   * @property {number} awsAccountId リソースが属するAWSアカウントのID
   * @property {bool} loading AJAX通信中かどうか
   * @property {string} loadingImagePath ローディング中に表示する画像のURLパス
   * @property {?string} message メッセージ
   * @property {object[]} resources リソースの配列
   * @property {string} resourceType AWSリソースのリソースタイプ
   * @property {string} sortColumn ソートに使うカラム識別子
   * @property {string} sortDirection ソートの方向
   * @property {function(attributeName: string)} callback ソート用リンクがクリックされた際に呼び出す関数
   */
  static get propTypes() {
    return {
      awsAccountId: PropTypes.number,
      loading: PropTypes.bool.isRequired,
      loadingImagePath: PropTypes.string.isRequired,
      message: PropTypes.any,
      resources: PropTypes.array.isRequired,
      resourceType: PropTypes.string.isRequired,
      sortColumn: PropTypes.string.isRequired,
      sortDirection: PropTypes.string.isRequired,
      callback: PropTypes.func.isRequired,
    };
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);
    const options = { scope: "javascript.resource_table" };
    this.resourceIdText = I18n.t("resource_id", options);
    this.nameText = I18n.t("name", options);
    this.regionText = I18n.t("region", options);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const sortByResourceId = this.props.sortColumn == Constants.COLUMN.RESOURCE_ID;
    const sortByName = this.props.sortColumn == Constants.COLUMN.NAME;
    const sortByRegion = this.props.sortColumn == Constants.COLUMN.REGION;

    return (
      <table id="table-job" className="table vertical-middle ca-table-header-no-bordered">
        <thead>
          <tr>
            <SortableTableHeaderCell
              column={Constants.COLUMN.RESOURCE_ID}
              active={sortByResourceId}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.resourceIdText}
            />
            <SortableTableHeaderCell
              column={Constants.COLUMN.NAME}
              active={sortByName}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.nameText}
            />
            <SortableTableHeaderCell
              column={Constants.COLUMN.REGION}
              active={sortByRegion}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.regionText}
            />
            <th className="col-control"></th>
          </tr>
        </thead>
        <tbody>{this.getResourceRows()}</tbody>
      </table>
    );
  }

  /**
   * テーブルに表示する各リソースのリソーステーブル行コンポーネントを配列またはコンポーネントで返します。
   * @return {array,ReactElement}
   */
  getResourceRows() {
    const isResourcesExist = this.props.message == null && this.props.resources.length > 0;

    // Ajax通信中の場合はローディングインジケーターのテーブル行のみを返す
    if (this.props.loading) {
      return (
        <tr key="0">
          <td colSpan="4">
            {I18n.t("javascript.resource_table.loading")}
            <img src={this.props.loadingImagePath} />
          </td>
        </tr>
      );
    }

    // リソースが空もしくはAWSアカウントが未選択の場合はエラーメッセージのテーブル行のみを返す
    if (!isResourcesExist || this.props.awsAccountId == null) {
      const msg = this.props.message || I18n.t("javascript.resource_table.no_resources");
      return (
        <tr key="0">
          <td colSpan="4">{msg}</td>
        </tr>
      );
    }

    // リソース毎に ResourceRow と ResourceJobsRow コンポーネントのテーブル行を返す
    let rows = [];
    this.props.resources.forEach((resource) => {
      const parentKey = `parent-${resource.identifier}`;
      const childKey = `child-${resource.identifier}`;
      const jobAvailability = resource.jobs.length > 0;
      rows.push(
        <ResourceRow
          key={parentKey}
          awsAccountId={this.props.awsAccountId}
          jobAvailable={jobAvailability}
          resource={resource}
          resourceType={this.props.resourceType}
        />
      );
      rows.push(<ResourceJobsRow key={childKey} resource={resource} />);
    });
    return rows;
  }
}

/**
 * リソーステーブルリソース行コンポーネント
 */
class ResourceRow extends React.Component {
  /**
   * propTypes
   * @return {object}
   * @property {string} awsAccountId リソースが属するAWSアカウントのID
   * @property {boolean} jobAvailable 関連するジョブがあるかどうかのフラグ
   * @property {object} resource 1つのAWSリソースのデータを持つオブジェクト
   * @property {string} resourceType AWSリソースの種類
   */
  static get propTypes() {
    return {
      awsAccountId: PropTypes.number.isRequired,
      jobAvailable: PropTypes.bool.isRequired,
      resource: PropTypes.object.isRequired,
      resourceType: PropTypes.string.isRequired,
    };
  }

  constructor(props) {
    super(props);
    this.handleToggleButtonClick = this.handleToggleButtonClick.bind(this);
    this.tableRowElement = null;
    this.toggleButtonElement = null;
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const resource = this.props.resource;

    if (this.props.jobAvailable) {
      // 関連するジョブが存在する場合のHTML
      const relatedJobsText = I18n.t("javascript.resource_table.related_jobs");
      return (
        <tr
          ref={(row) => {
            this.tableRowElement = row;
          }}
          className="row-parent"
        >
          <td>{resource.identifier}</td>
          <td>{resource.name}</td>
          <td>{resource.region_text}</td>
          <td className="text-right">
            <button
              className="related-btn btn btn-default marginR5"
              onClick={this.handleToggleButtonClick}
              type="button"
            >
              <span
                ref={(button) => {
                  this.toggleButtonElement = button;
                }}
                className="fa fa-chevron-down marginR5"
              ></span>
              <small>{relatedJobsText}</small>
            </button>
          </td>
        </tr>
      );
    }

    // 関連するジョブが存在しない場合はテーブルの各セルに対するonClickハンドラの設定を
    // 行わず、HTMLに「関連するジョブ」ボタンを含めない
    return (
      <tr ref="row" className="row-parent">
        <td>{resource.identifier}</td>
        <td>{resource.name}</td>
        <td>{resource.region_text}</td>
        <td></td>
      </tr>
    );
  }

  /**
   * 関連するジョブボタンがクリックされた際に呼び出されるイベントハンドラ。
   * @param {SyntheticEvent} event
   */
  handleToggleButtonClick(event) {
    event.preventDefault();

    const rowNode = this.tableRowElement;
    const toggleButton = this.toggleButtonElement;

    if (!rowNode || !toggleButton) {
      return;
    }

    $(rowNode).toggleClass("show");
    $(toggleButton).toggleClass("fa-chevron-down").toggleClass("fa-chevron-up");
    $(rowNode).closest("tr").next().toggleClass("show");
  }
}

/**
 * リソーステーブルジョブ行コンポーネント
 *
 * プロパティ
 * resource - 1つのリソースのデータを持つオブジェクト。
 *            サーバーから取得したリソースのJSONデータの resources キーに対応する
 *            配列の1要素が設定される。
 */
class ResourceJobsRow extends React.Component {
  /**
   * propTypes
   * @property {}
   */
  static get propTypes() {
    return {
      resource: PropTypes.object.isRequired,
    };
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return (
      <tr className="row-child no-borders font-13px">
        <td colSpan="4" className="padding0">
          <div className="row-child-in">
            <table className="table vertical-middle table-child">
              <tbody>{this.getJobRows()}</tbody>
            </table>
          </div>
        </td>
      </tr>
    );
  }

  /**
   * テーブルに表示する各ジョブのテーブル行コンポーネントを配列で返します。
   * @return {array}
   */
  getJobRows() {
    const jobs = this.props.resource.jobs;

    if (jobs.length == 0) {
      const msg = I18n.t("javascript.resource_table.no_jobs");
      return (
        <tr key="0">
          <td colSpan="4">{msg}</td>
        </tr>
      );
    }

    return jobs.map((job) => {
      return <ResourceJobRow key={job.id} job={job} />;
    });
  }
}

/**
 * リソーステーブルジョブ行コンポーネント
 *
 * プロパティ
 * job - 1つのジョブのデータを持つオブジェクト。
 *       サーバーから取得したジョブのJSONデータの jobs キーに対応する配列の1要素が設定される。
 */
class ResourceJobRow extends React.Component {
  /**
   * propTypes
   * @property {}
   */
  static get propTypes() {
    return {
      job: PropTypes.object.isRequired,
    };
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);
    this.handleDetailClick = this.handleDetailClick.bind(this);
    this.detailText = I18n.t("javascript.resource_table.detail");
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const job = this.props.job;
    const name = job.name;
    const ruleType = job.rule_type;
    const actionType = job.action_type;
    const url = job.url;
    return (
      <tr>
        <td className="col-jobname">
          <div className="ca-aws-resouce-jobname">
            <GroupIconTip group={job.group} />
            <a href={url}>{name}</a>
          </div>
        </td>
        <td className="col-trigger">
          <div className="ca-trigger-action">
            <span className="trigger-name">
              <span>{I18n.t(ruleType, { scope: "common.rule_types_abb" })}</span>
            </span>
            <span className="fa fa-arrow-circle-right fa-sm"></span>
            <span className="action-name">
              <span>{actionType}</span>
            </span>
          </div>
        </td>
        <td className="text-right col-detail">
          <button className="detail-btn btn" onClick={this.handleDetailClick} type="button">
            <span className="fa fa-list-alt marginR5"></span>
            <small>{this.detailText}</small>
          </button>
        </td>
      </tr>
    );
  }

  /**
   * 詳細ボタンがクリックされた際に呼び出されるイベントハンドラ。
   * @param {SyntheticEvent} event
   */
  handleDetailClick(event) {
    event.preventDefault();
    window.location = this.props.job.url;
  }
}
