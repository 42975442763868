import React from 'react';
import PropTypes from 'prop-types';
import {Typeahead} from 'react-bootstrap-typeahead';

const I18n = window.I18n; // i18n-js
const I18N_SCOPE = 'javascript.group_selector';
const Underscore = window._;

/**
 * グループ選択用セレクトボックスコンポーネント
 *
 * Example:
 *
 *   <GroupSelector
 *     onChange={(groups) => { window.console.log(JSON.stringify(groups)); }}
 *     options={
 *       [
 *         { id: 1, name: "グループ1" },
 *         { id: 2, name: "グループ2" }
 *       ]
 *     },
 *     selectedIds={[2]}
 *   />
 *
 * onChangeプロパティに渡されたコールバック関数には以下のようなObjectの配列が渡されます。
 *
 *   {
 *     id: 1,
 *     name: "グループ1"
 *   }
 *
 * onChangeプロパティが省略された場合は動作確認用の情報がブラウザコンソールに出力されます。
 */
class GroupSelector extends React.Component {
  /**
   * propTypes
   * @return {Object}
   * @property {bool} disabled - セレクトボックスを無効化するかどうか
   * @property {bool} multiple - グループを複数選択出来るかどうか
   * @property {function(groups: object[])} onChange - 値が変化した際に呼び出されるコールバック関数
   * @property {Object[]} options - 選択肢となるグループの配列
   * @property {number[]} selectedIds - 選択状態とするグループのIDの配列
   * @property {string} placeholder - placeholderに表示する文字列
   */
  static get propTypes() {
    return({
      disabled: PropTypes.bool,
      multiple: PropTypes.bool,
      onChange: PropTypes.func,
      options: PropTypes.arrayOf(PropTypes.object).isRequired,
      selectedIds: PropTypes.arrayOf(PropTypes.number),
      placeholder: PropTypes.string,
    });
  }

  /**
   * @return {Object}
   */
  static get defaultProps() {
    return({
      disabled: false,
      multiple: false,
      onChange: null,
      selectedIds: [],
      placeholder: I18n.t("placeholder", { scope: I18N_SCOPE })
    });
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
    this.i18n = {
      emptyLabel: I18n.t("empty_label", { scope: I18N_SCOPE }),
      paginationText: I18n.t("pagination_text", { scope: I18N_SCOPE }),
    };

    // 与えられた選択肢の中から選択済みとするもののみを抽出する。
    // これは、TypeaheadコンポーネントのdefaultSelectedプロパティには値だけでなく
    // 選択肢をあらわすObjectを渡す必要があるため。
    this.defaultSelectedOptions = Underscore.filter(props.options, (option) => {
      return props.selectedIds.indexOf(option.id) != -1;
    });
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <Typeahead
        clearButton
        className="ca-group-name-selector"
        disabled={this.props.disabled}
        emptyLabel={this.i18n.emptyLabel}
        id="GroupSelector"
        labelKey="name"
        minLength={0}
        inputProps={{className: "required"}}
        multiple={this.props.multiple}
        onChange={this.handleOnChange}
        options={this.props.options}
        paginationText={this.i18n.paginationText}
        placeholder={this.props.placeholder}
        defaultSelected={this.defaultSelectedOptions}
      />
    );
  }

  /**
   * セレクトボックスの値が変更された際に呼び出されるイベントハンドラ。
   * groups の各要素は id と name プロパティを持ちます。
   *
   * プロパティ onChange が指定されている場合はその関数を呼び出し、指定されていない場合は
   * デバッグ用の出力を行います。
   *
   * @param {Object[]} groups - 現在選択されているグループの配列
   */
  handleOnChange(groups) {
    if (this.props.onChange) {
      this.props.onChange(groups);
    } else {
      const debug = groups.map((group) => `${group.name}(ID:${group.id})`);
      window.console.log(`選択されたグループ: ${debug}`);
    }
  }
}

export default GroupSelector;
