import EventEmitter from 'events';
import React from 'react';
import PropTypes from 'prop-types';

import PolicySetFormConstants from './Constants.js';
import Constants from '../post_process_assignments_panel/Constants.js';
import PostProcessAssignmentsPanel from '../post_process_assignments_panel/PostProcessAssignmentsPanel.jsx';

const I18n = window.I18n; // i18n-js
const _ = window._; // Underscore.js

/**
 * ポリシーセット用後処理追加コンテナ
 *
 * このクラスは、ジョブ用の後処理追加コンテナ
 * (post_process_assignments_panel/PostProcessAssignmentsPanelContainer) を複製し、
 * ジョブ作成フォームに固有の処理を変更したものです。
 * 将来的には、依存する部分を取り除いた共通のクラスとして実装するようにします。
 *
 * 後処理割り当て要素の起点となるコンポーネントで、以下の責務を持ちます。
 *
 * - サーバーサイドから後処理一覧のJSONをXHRで取得する
 * - 後処理一覧のセレクトボックスの値が変更された際の処理
 * - 後処理追加ボタンがクリックされた際の処理
 * - 後処理更新ボタンがクリックされた際の処理
 */
export default class PostProcessAssignmentsPanelContainer extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {Object[]} completedAssignments 成功時の後処理割り当ての配列
   * @property {string} defaultLanguage - 言語設定欄がある場合のデフォルトの言語(例 "ja")
   * @property {string} defaultTimeZone - タイムゾーン設定がある場合のデフォルトのタイムゾーン(例 "Tokyo")
   * @property {Object} emitter 親コンポーネントのEventEmitter
   * @property {Object[]} failedAssignments 失敗時の後処理割り当ての配列
   * @property {array[]} groups グループの配列
   * @property {boolean} isSlackIntegrated - Slackが外部サービスとして連携済みかどうか
   * @property {boolean} isUserCanManageIntegrations - ユーザーが外部サービス連携を管理できるかどうか
   * @property {sring} loadingImagePath ローディング中に表示する画像のURLパス
   * @property {number} policyTemplateId 後処理を割り当てるポリシーのポリシーテンプレートID
   * @property {Object[]} postProcesses 後処理の配列
   * @property {string} postProcessesUrls 後処理の更新時にXHRでアクセスするURL
   * @property {array[]} regions リージョンの配列
   * @property {number} selectedGroupId 選択されている状態とするグループID
   * @property {array[]} services サービスの配列
   * @property {string} sqsQueuesPath SQSキュー名を取得するためのパス
   * @property {Array<Array>} timeZones - タイムゾーンの選択肢
   */
  static get propTypes() {
    return({
      completedAssignments: PropTypes.arrayOf(PropTypes.object).isRequired,
      defaultLanguage: PropTypes.string.isRequired,
      defaultTimeZone: PropTypes.string.isRequired,
      emitter: PropTypes.object.isRequired,
      failedAssignments: PropTypes.arrayOf(PropTypes.object).isRequired,
      groups: PropTypes.arrayOf(PropTypes.array),
      isSlackIntegrated: PropTypes.bool.isRequired,
      isUserCanManageIntegrations: PropTypes.bool.isRequired,
      loadingImagePath: PropTypes.string.isRequired,
      policyTemplateId: PropTypes.number.isRequired,
      postProcesses: PropTypes.arrayOf(PropTypes.object),
      postProcessesUrl: PropTypes.string.isRequired,
      regions: PropTypes.arrayOf(PropTypes.array).isRequired,
      selectedGroupId: PropTypes.number,
      services: PropTypes.arrayOf(PropTypes.array).isRequired,
      sqsQueuesPath: PropTypes.string.isRequired,
      timeZones: PropTypes.arrayOf(PropTypes.array).isRequired,
    });
  }

  /**
   * デフォルトのプロパティを返します。
   *
   * @public
   * @return {Object}
   */
  static get defaultProps() {
    return({
      completedAssignments: [],
      failedAssignments: [],
      groups: [],
      postProcesses: [],
      selectedGroupId: null
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.state = {
      completedAssignments: this.props.completedAssignments,
      failedAssignments: this.props.failedAssignments,
      refreshButtonDisabled: false,
      postProcesses: this.props.postProcesses,
      selectedGroupId: this.props.selectedGroupId,
      showDialog: false,
    };

    this.localEmitter = this.initLocalEventEmitter();

    this.i18n_text = {
      completed: I18n.t('completed', { scope: Constants.I18N_SCOPE }),
      failed: I18n.t('failed', { scope: Constants.I18N_SCOPE })
    };
  }

  /**
   * コンポーネントがマウントされた際の処理を行います。
   * @public
   */
  componentDidMount() {
    this.fetchPostProcesses();
  }

  /**
   * 共通後処理と選択状態になっているグループに属する後処理のみに絞り込みます。
   *
   * @private
   * @return {Object[]}
   */
  getFilteredPostProcesses() {
    const postProcesses = this.state.postProcesses.filter((postProcess) => {
        return(postProcess.shared_by_group || this.state.selectedGroupId == postProcess.group_id);
    });

    return(postProcesses);
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    const filteredPostProcesses = this.getFilteredPostProcesses();
    const commonProps = {
      defaultLanguage: this.props.defaultLanguage,
      defaultTimeZone: this.props.defaultTimeZone,
      groups: this.props.groups,
      isSlackIntegrated: this.props.isSlackIntegrated,
      isUserCanManageIntegrations: this.props.isUserCanManageIntegrations,
      loadingImagePath: this.props.loadingImagePath,
      postProcessesUrl: this.props.postProcessesUrl,
      regions: this.props.regions,
      services: this.props.services,
      sqsQueuesPath: this.props.sqsQueuesPath,
      timeZones: this.props.timeZones,
    };

    return(
      <React.StrictMode>
        <React.Fragment>
          <PostProcessAssignmentsPanel
            assignments={this.state.completedAssignments}
            emitter={this.localEmitter}
            postProcessType={Constants.POST_PROCESS_TYPE.COMPLETED}
            postProcesses={filteredPostProcesses}
            refreshButtonDisabled={this.state.refreshButtonDisabled}
            showDialog={this.state.showDialog}
            title={this.i18n_text.completed}
            titleIcon={Constants.POST_PROCESS_ICON.COMPLETED}
            {...commonProps}
          />
          <PostProcessAssignmentsPanel
            assignments={this.state.failedAssignments}
            emitter={this.localEmitter}
            postProcessType={Constants.POST_PROCESS_TYPE.FAILED}
            postProcesses={filteredPostProcesses}
            refreshButtonDisabled={this.state.refreshButtonDisabled}
            showDialog={this.state.showDialog}
            title={this.i18n_text.failed}
            titleIcon={Constants.POST_PROCESS_ICON.FAILED}
            {...commonProps}
          />
        </React.Fragment>
      </React.StrictMode>
    );
  }

  /**
   * セレクトボックスで選択された後処理を割り当て済み後処理に追加して、コンポーネントの状態を更新します。
   *
   * @private
   * @param postProcessType {string} 成功時の後処理か失敗時の後処理かをあらわす文字列
   *                                 "completed" もしくは "failed"
   * @param postProcessId {number} 選択された後処理ID
   */
  handleChangeSelector(postProcessType, postProcessId) {
    const postProcess = this.state.postProcesses.find((postProcess) => {
      return(postProcess.id === postProcessId);
    });
    let completedAssignments = this.state.completedAssignments;
    let failedAssignments = this.state.failedAssignments;

    if (postProcessType == Constants.POST_PROCESS_TYPE.COMPLETED) {
      completedAssignments = _.uniq(completedAssignments.concat(postProcess), (assignment) => {
        return(assignment.id);
      });
    } else if (postProcessType == Constants.POST_PROCESS_TYPE.FAILED) {
      failedAssignments = _.uniq(failedAssignments.concat(postProcess), (assignment) => {
        return(assignment.id);
      });
    }

    this.setState({
      completedAssignments: completedAssignments,
      failedAssignments: failedAssignments
    }, () => {
      // 後処理の割り当てイベントをポリシーセット作成フォームへ送る
      if (postProcessType == Constants.POST_PROCESS_TYPE.COMPLETED) {
        this.props.emitter.emit(
          PolicySetFormConstants.EVENT_ADD_SUCCEEDED_POST_PROCESS_ASSIGNMENT,
          this.props.policyTemplateId,
          completedAssignments
        );
      } else if (postProcessType == Constants.POST_PROCESS_TYPE.FAILED) {
        this.props.emitter.emit(
          PolicySetFormConstants.EVENT_ADD_FAILED_POST_PROCESS_ASSIGNMENT,
          this.props.policyTemplateId,
          failedAssignments
        );
      }
    });
  }

  /**
   * 後処理作成ダイアログを非表示状態にします。
   *
   * @private
   */
  handleCloseDialog() {
    this.setState({ showDialog: false }, this.fetchPostProcesses);
  }

  /**
   * 後処理作成ダイアログを表示状態にします。
   *
   * @private
   */
  handleOpenDialog() {
    this.setState({ showDialog: true });
  }

  /**
   * 指定された後処理を割り当て済み後処理から取り除いて、コンポーネントの状態を更新します。
   *
   * @private
   * @param postProcessType {string} 成功時の後処理か失敗時の後処理かをあらわす文字列
   *                                 "completed" もしくは "failed"
   * @param postProcessId {number} 選択された後処理ID
   */
  handleDeleteButton(postProcessType, postProcessId) {
    let completedAssignments = this.state.completedAssignments;
    let failedAssignments = this.state.failedAssignments;

    if (postProcessType == Constants.POST_PROCESS_TYPE.COMPLETED) {
      completedAssignments = _.reject(completedAssignments, (assignment) => {
        return(assignment.id === postProcessId);
      });
    } else if (postProcessType == Constants.POST_PROCESS_TYPE.FAILED) {
      failedAssignments = _.reject(failedAssignments, (assignment) => {
        return(assignment.id === postProcessId);
      });
    }

    this.setState({
      completedAssignments: completedAssignments,
      failedAssignments: failedAssignments
    }, () => {
      // 後処理の割り当て解除イベントをポリシーセット作成フォームへ送る
      if (postProcessType == Constants.POST_PROCESS_TYPE.COMPLETED) {
        this.props.emitter.emit(
          PolicySetFormConstants.EVENT_DELETE_SUCCEEDED_POST_PROCESS_ASSIGNMENT,
          this.props.policyTemplateId,
          completedAssignments
        );
      } else if (postProcessType == Constants.POST_PROCESS_TYPE.FAILED) {
        this.props.emitter.emit(
          PolicySetFormConstants.EVENT_DELETE_FAILED_POST_PROCESS_ASSIGNMENT,
          this.props.policyTemplateId,
          failedAssignments
        );
      }
    });
  }

  /**
   * サーバーから後処理一覧のJSONを取得して、コンポーネントの状態を更新します。
   *
   * @private
   */
  fetchPostProcesses() {
    window.jQuery.ajax({
      url: this.props.postProcessesUrl,
      method: 'GET',
      dataType: 'json',
      beforeSend: () => {
        this.setState({
          refreshButtonDisabled: true
        });
      }
    }).done((data) => {
      this.setState({
        postProcesses: data.post_processes,
        refreshButtonDisabled: false
      });
    }).fail(() => {
      alert("Couldn't fetch Post Processes.");
      this.setState({
        postProcesses: [],
        refreshButtonDisabled: false
      });
    });
  }

  /**
   * ローカルなEventEmitterを初期化して返します。
   *
   * 各コンポーネント内で発生するイベントとイベントハンドラ関数の割り当てを行います。
   *
   * @private
   * @return {Object}
   */
  initLocalEventEmitter() {
    const emitter = new EventEmitter;
    emitter.on(Constants.EVENT_CHANGE_POST_PROCESS_SELECTOR, this.handleChangeSelector.bind(this));
    emitter.on(Constants.EVENT_CLICK_DELETE_BUTTON, this.handleDeleteButton.bind(this));
    emitter.on(Constants.EVENT_CLICK_REFRESH_BUTTON, this.fetchPostProcesses.bind(this));
    emitter.on(Constants.EVENT_OPEN_DIALOG, this.handleOpenDialog.bind(this));
    emitter.on(Constants.EVENT_CLOSE_DIALOG, this.handleCloseDialog.bind(this));
    return emitter;
  }
}
