import EventEmitter from 'events';
import React from "react";
import PropTypes from "prop-types";

import DeleteGroupDialog from "./DeleteGroupDialog.jsx";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.group";

/**
 * グループの編集画面で利用で表示するグループ削除のリンクです
 *
 * propで受け取った `disabled` プロパティに応じて処理が以下に分岐します
 * - 削除可能な場合
 *   - リンクをクリックするとグループ削除ダイアログが現れる
 *   - その後の処理は DeleteGroupDialog で行われる
 * - 削除不可能な場合
 *   - リンクをクリックすると画面上部のFlashメッセージ表示箇所にエラーメッセージが表示される
 */
export default class DeleteGroup extends React.Component {
  /**
   * プロパティ定義を返します。
   * @public
   * @return {Object}
   * @property {boolean} disabled グループが削除可能かどうか
   * @property {number} groupId グループのID
   * @property {string} groupName グループ名
   */
  static get propTypes() {
    return({
      disabled: PropTypes.bool.isRequired,
      groupId: PropTypes.number.isRequired,
      groupName: PropTypes.string.isRequired
    });
  }

  /**
   * コンストラクタ
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.state = {
      showDeleteGroupDialog: false
    };

    this.handleCloseDeleteGroupDialog = this.handleCloseDeleteGroupDialog.bind(this);
    this.handleOpenDeleteGroupDialog = this.handleOpenDeleteGroupDialog.bind(this);
    this.handleAlertMessage = this.handleAlertMessage.bind(this);

    this.emitter = new EventEmitter();
    this.emitter.on("closeDialog", this.handleCloseDeleteGroupDialog);
    this.emitter.on("openDialog", this.handleOpenDeleteGroupDialog);
  }

  /**
   * グループが削除出来ない時に出すアラートを表示する
   *
   * @public
   */
  handleAlertMessage() {
    const message = I18n.t("javascript.group.failed_delete_group_alert");
    // TODO: DOM操作を行わずに済むようリファクタリングする
    const alert = `<div id="js-alert-message" class="alert alert-danger alert-dismissable">
              <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
              <span class="alert-danger__icon"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
              ${message}
              </div>`;
    $('#js-flash-container').prepend(alert);
  }

  /**
   * グループ削除ダイアログを閉じるハンドラ
   * stateの値を更新する
   *
   * @private
   */
  handleCloseDeleteGroupDialog() {
    this.setState({ showDeleteGroupDialog: false });
  }

  /**
   * グループ削除ダイアログを開くハンドラ
   * stateの値を更新する
   *
   * @private
   */
  handleOpenDeleteGroupDialog() {
    this.setState({ showDeleteGroupDialog: true });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    const content = this.props.disabled ? this.disabledDeleteGroup() : this.enabledDeleteGroup();

    return(
      <React.StrictMode>
        {content}
      </React.StrictMode>
    );
  }

  /**
   * グループ削除不可の時に表示する削除リンク
   * クリックをするとフラッシュメッセージ表示部にアラートメッセージを出す
   *
   * @public
   * @return {ReactElement}
   */
  disabledDeleteGroup() {
    return(
      <div>
        <div onClick={this.handleAlertMessage} className="ca-delete-group-link text-danger">
          <span className="fa fa-trash-o">&nbsp;</span>{I18n.t("delete", { scope: I18N_SCOPE })}
        </div>
      </div>
    );
  }

  /**
   * グループ削除リンク
   * クリックするとグループ削除確認ダイアログが表示される
   * @public
   * @return {ReactElement}
   */
  enabledDeleteGroup() {
    return(
      <div>
        <div onClick={() => {this.emitter.emit("openDialog"); }} className="ca-delete-group-link text-danger">
          <span className="fa fa-trash-o">&nbsp;</span>{I18n.t("delete", { scope: I18N_SCOPE })}
        </div>
        <DeleteGroupDialog emitter={this.emitter}
                           groupId={this.props.groupId}
                           groupName={this.props.groupName}
                           showDeleteGroupDialog={this.state.showDeleteGroupDialog}/>
      </div>
    );
  }
}
