import React from "react";
import PropTypes from "prop-types";

/**
 * バックアップ結果ボリュームテーブルの一行を表すコンポーネント
 *
 * バックアップ結果ボリュームの情報を描画します
 *
 */
export default class EbsBackupVolumeRow extends React.Component {
  /**
   * プロパティ定義を返します
   *
   * @public
   * @property {object} result バックアップ結果ボリュームの情報が入ったオブジェクト
   */
  static get propTypes() {
    return {
      result: PropTypes.object.isRequired,
    };
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const result = this.props.result;
    const region = this.props.result.region;

    const regionName = region.split("(")[0];
    const placeName = "(" + region.split("(")[1];
    const snapshot_id = result.snapshot_id && `(${result.snapshot_id})`;

    return (
      <tr>
        <td>
          {regionName}<br />
          {placeName}
        </td>
        <td>
          {result.volume_name}<br />
          <span className="ca-backup-check-volumes-table-row__volume-id">
            {`(${result.volume_id})`}
          </span>
        </td>
        <td>
          {result.volume_status}
        </td>
        <td>
          {result.snapshot_name}<br />
          <span className="ca-backup-check-volumes-table-row__snapshot-id">
            {snapshot_id}
          </span>
        </td>
        <td>
          {result.snapshot_start_time}
        </td>
      </tr>
    );
  }
}
