import React from 'react';
import PropTypes from 'prop-types';
import camelize from 'underscore.string/camelize';

import Constants from './Constants.js';
import ErrorMessages from '../ErrorMessages.jsx';

const I18n = window.I18n; // i18n-js

// Webhook HTTPヘッダー値入力フィールド
//
// attribute プロパティに、入力された値に対応するPostProcessモデルの属性名を指定します。
//
//   <WebhookHeaderField attribute="webhook_authorization_header" ... />
//
// この場合、入力値が変化した際に emitter プロパティに指定されたEmitterに
// Cnnstants.EVENT_CHANGE_FORM_VALUEイベントが送信され、その引数には
//
//   - attribute をキャメルケースに変換した文字列
//   - 変更後の値
//
// が渡されます。
//
class WebhookHeaderField extends React.Component {
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
      attribute: PropTypes.string.isRequired, // 対象となるPostProcessモデルの属性名
      value: PropTypes.string.isRequired
    });
  }

  /**
   * エラーがあるかどうかを返します。
   *
   * @return {boolean}
   */
  hasError() {
    return this.props.errors.length > 0;
  }

  render() {
    const errorClass = this.hasError() ? 'error' : '';
    const label = I18n.t(this.props.attribute, { scope: 'activerecord.attributes.post_process' });
    const inputId = `PostProcess-${this.props.attribute}`;
    const camelizedAttribute = camelize(this.props.attribute);
    const prompt = I18n.t('webhook_header_prompt', { scope: Constants.I18N_SCOPE });
    const optional = I18n.t('optional', { scope: 'common.form' });

    return(
      <div className="form-group">
        <div className="post-process-setting">
          <div className="post-process-setting__label">
            <div className="post-process-setting__label__text">
              <label htmlFor={inputId}>
                {label}
                <span>({optional})</span>
              </label>
            </div>
          </div>
          <div className="post-process-setting__form">
            <input
              id={inputId}
              className={`form-control ${errorClass}`}
              onChange={event => this.props.emitter.emit(Constants.EVENT_CHANGE_FORM_VALUE, camelizedAttribute, event.target.value)}
              type="url"
              value={this.props.value}
            />
            <div className="post-process-setting__prompt">{prompt}</div>
            <div className="row">
              <ErrorMessages messages={this.props.errors} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WebhookHeaderField;
