import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';

const I18n = window.I18n; // i18n-js

const I18N_SCOPE = `${Constants.I18N_SCOPE}.pricing_section`;

// ポリシーセット請求金額セクションコンポーネント
// 請求金額の算出と表示を行います。
class PricingSection extends React.PureComponent {
  static get propTypes() {
    return({
      policyCount: PropTypes.number.isRequired,
      overseas: PropTypes.bool.isRequired,
      unitPriceJPY: PropTypes.number.isRequired,
      unitPriceUSD: PropTypes.number.isRequired,
    });
  }

  constructor(props) {
    super(props);
  }

  render() {
    return(
      <div className="policy-pricing-section">
        <div className="policy-pricing-section__message">{this.renderMessage()}</div>
      </div>
    );
  }

  renderMessage() {
    const count = this.props.policyCount;
    const unitPrice = this.decideUnitPrice();
    const amount = this.calculateAmount(count, unitPrice);
    const currencyUnit = this.decideCurrencyUnit();
    let message = null;
    if (count > 0) {
      message = I18n.t('message', { scope: I18N_SCOPE, count: count, amount: amount, currency_unit: currencyUnit });
    } else {
      message = I18n.t('empty_message', { scope: I18N_SCOPE, });
    }

    return (
      <div className="policy-pricing-section__message">{message}</div>
    );
  }

  /**
   * ポリシーの請求金額を算出して返します。
   *
   * @param {number} count - 有効なポリシーの数
   * @param {number} unitPrice - ポリシーの単価
   * @return {number} 金額
   */
  calculateAmount(count, unitPrice) {
    return Math.round(count * unitPrice);
  }

  /**
   * ポリシーの金額の単位を決定します。
   *
   * @return {currencyUnit}
   */
  decideCurrencyUnit(){
    if (this.props.overseas){
      return I18n.t('common.currency_unit.usd')
    }else{
      return I18n.t('common.currency_unit.jpy')
    }
  }

  /**
   * ポリシーの単価の単位を決定します。
   *
   * @return {unitPrice} 単価
   */
  decideUnitPrice(){
    if (this.props.overseas){
      return this.props.unitPriceUSD
    }else{
      return this.props.unitPriceJPY
    }
  }
}

export default PricingSection;
