import camelize from "camelize";
import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useToggle } from "react-use";

import { showSuccessToast } from "../../toast";
import ErrorArea from "../ErrorArea";
import ManualLink from "../../ManualLink";

import { AwsAccountWithIamUser } from "./types";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.groups.aws_accounts.edit_aws_account_with_iam_user_dialog";

/**
 * AWSアカウント(IAMユーザー方式)の編集用の型
 *
 * 編集用にサーバーサイドから取得したAWSアカウントのオブジェクトは
 * シークレットアクセスキーを含んでいません。その型をそのまま
 * AWSアカウントの編集時に利用すると型による保護が受けられないため、
 * シークレットアクセスキーを保持するように拡張した型を利用します。
 */
type AwsAccountWithIamUserForEdit = AwsAccountWithIamUser & {
  /**
   * シークレットアクセスキー
   */
  secretAccessKey: string;
};

/**
 * AWSアカウント(IAMユーザー方式)の更新時に送信するデータ
 * groups/aws_accounts#update の仕様と合わせる。
 */
type UpdateAwsAccountData = {
  account_type: "iam_user";
  aws_account: {
    access_key_id: string;
    name: string;
    secret_access_key?: string;
  };
};

/**
 * useEditAwsAccountLogicフックの戻り値の型
 */
type UseEditAwsAccountLogicResult = {
  /**
   * 編集中のAWSアカウント
   */
  awsAccount: AwsAccountWithIamUserForEdit;
  /**
   * キャンセル操作時に呼び出す関数
   */
  handleCancel: () => void;
  /**
   * フォーム送信時に呼び出す関数
   */
  handleSubmit: (event: React.SyntheticEvent) => void;
  /**
   * 通信中かどうかのフラグ
   */
  isTransmitting: boolean;
  /**
   * サーバーから返されたエラーメッセージ
   */
  serverError: string;
  /**
   * 編集中のAWSアカウントを更新するための関数
   */
  setAwsAccount: (awsAccount: AwsAccountWithIamUserForEdit) => void;
  /**
   * バリデーションエラーメッセージ
   */
  validationErrors: ValidationErrors;
};

/**
 * フォームのバリデーションエラーメッセージを保持するオブジェクトの型
 */
type ValidationErrors = {
  /**
   * アクセスキーIDのバリデーションエラーメッセージ
   */
  accessKeyId: string[];
  /**
   * アカウント名のバリデーションエラーメッセージ
   */
  name: string[];
  /**
   * シークレットアクセスキーのバリデーションエラーメッセージ
   */
  secretAccessKey: string[];
};

/**
 * フォームのバリデーションエラーメッセージの初期状態
 */
const emptyValidationErrors: ValidationErrors = {
  accessKeyId: [],
  name: [],
  secretAccessKey: [],
};

/**
 * AWSアカウント(IAMユーザー方式)編集用ビジネスロジックのHook
 *
 * @param target 編集対象のAWSアカウント
 * @param cancelCallback キャンセル操作時に呼び出すコールバック
 * @param updatedCallback 更新完了時に呼び出すコールバック
 */
const useEditAwsAccountLogic = (
  target: AwsAccountWithIamUser,
  cancelCallback: () => void,
  updatedCallback: () => void
): UseEditAwsAccountLogicResult => {
  // 編集中のAWSアカウント
  const [awsAccount, setAwsAccount] = useState<AwsAccountWithIamUserForEdit>({
    ...target,
    secretAccessKey: "",
  });
  // 通信中かどうかのフラグ
  const [isTransmitting, toggleTransmitting] = useToggle(false);
  // サーバーから返されたエラーメッセージ
  const [serverError, setServerError] = useState("");
  // バリデーションエラーメッセージ
  const [validationErrors, setValidationErrors] = useState(emptyValidationErrors);

  /**
   * 編集をキャンセルする関数
   *
   * ダイアログを閉じる操作が行われた場合に実行されます。
   */
  const handleCancel = (): void => {
    cancelCallback();
    // ダイアログを閉じた後も入力内容やエラーメッセージは保持されるため、再度開いた時にそれらが
    // リセットされているようにする。
    setAwsAccount({ ...target, secretAccessKey: "" });
    setValidationErrors(emptyValidationErrors);
    setServerError("");
  };

  /**
   * サーバーに送信するデータを生成する関数
   */
  const buildData = (): UpdateAwsAccountData => {
    const data: UpdateAwsAccountData = {
      account_type: "iam_user",
      aws_account: {
        access_key_id: awsAccount.accessKeyId,
        name: awsAccount.name,
      },
    };
    if (awsAccount.secretAccessKey !== "") {
      // シークレットアクセスキーは「値が空文字列」かどうかではなく「リクエストパラメーターに
      // 含まれていないかどうか」で「変更なし」として処理されるかどうかが決まる。
      // そのため、値が入力されている場合にのみリクエストパラメーターに含めるようにする。
      data.aws_account.secret_access_key = awsAccount.secretAccessKey;
    }

    return data;
  };

  /**
   * フォームの送信処理を行う関数
   *
   * 編集中のAWSアカウントをサーバーサイドに反映し、レスポンスに応じた処理を行います。
   * リクエストは、「編集中のAWSアカウントの updateUrl プロパティ」に設定されているURLに対して
   * PATCHメソッドで行われます。
   */
  const handleSubmit = (event): void => {
    event.preventDefault();

    jQuery
      .ajax({
        beforeSend: () => {
          setValidationErrors(emptyValidationErrors);
          setServerError("");
          toggleTransmitting(true);
        },
        data: buildData(),
        dataType: "json",
        method: "PATCH",
        url: target.updateUrl,
      })
      .done(() => {
        updatedCallback();
        showSuccessToast(I18n.t("succeeded", { name: awsAccount.name, scope: I18N_SCOPE }));
      })
      .fail((jqXHR, textStatus) => {
        if (jqXHR.status == 422) {
          const errors = camelize(jqXHR.responseJSON?.errors ?? {});
          setValidationErrors({ ...emptyValidationErrors, ...errors });
        } else {
          setServerError(I18n.t("error", { message: textStatus, scope: I18N_SCOPE }));
        }
      })
      .always(() => toggleTransmitting(false));
  };

  return { awsAccount, setAwsAccount, isTransmitting, serverError, validationErrors, handleCancel, handleSubmit };
};

/**
 * グループAWSアカウント(IAMユーザー方式)編集ダイアログ
 *
 * 以下の責務を持ちます。
 *
 * - 編集ダイアログ内のコンテンツの表示
 * - キャンセル操作時のコールバック呼び出し(ダイアログ表示の制御はしない)
 * - 更新操作時のリクエスト送信
 * - 更新成功時のコールバック呼び出し(ダイアログ表示の制御はしない)
 * - 更新成功時のToast UIの表示
 * - 更新失敗時のエラーメッセージの表示
 */
export const EditAwsAccountWithIamUserDialog: React.FC<{
  awsAccount: AwsAccountWithIamUser;
  isOpen: boolean;
  onCancel: () => void;
  onUpdated: () => void;
}> = (props): JSX.Element => {
  const {
    awsAccount,
    setAwsAccount,
    serverError,
    validationErrors,
    isTransmitting,
    handleCancel,
    handleSubmit,
  } = useEditAwsAccountLogic(props.awsAccount, props.onCancel, props.onUpdated);

  useEffect(() => {
    ReactModal.setAppElement("body");
  }, []);

  return (
    <ReactModal
      className="ca-group-aws-account-modal__content"
      isOpen={props.isOpen}
      onRequestClose={handleCancel}
      overlayClassName="ca-group-aws-account-modal__overlay"
      role="dialog"
    >
      <div className="text-left">
        <Heading />
      </div>
      <div className="marginB10 text-left">
        <AwsAccountManualLink />
      </div>
      <form onSubmit={handleSubmit}>
        <table className="table vertical-middle no-borders ca-group-aws-account-modal__input-group text-left">
          <tbody>
            <tr>
              <th className="ca-group-aws-account-table__header">
                <Label name="name" />
                <RequiredMark />
              </th>
              <td>
                <TextInputField
                  name="name"
                  onChange={(value): void => setAwsAccount({ ...awsAccount, name: value })}
                  placeholder="Name"
                  value={awsAccount.name}
                />
                {validationErrors.name.length > 0 && <ErrorArea errors={validationErrors.name} />}
              </td>
            </tr>
            <tr>
              <th className="ca-group-aws-account-table__header">
                <Label name="access_key_id" />
                <RequiredMark />
              </th>
              <td>
                <TextInputField
                  name="access_key_id"
                  onChange={(value): void => setAwsAccount({ ...awsAccount, accessKeyId: value })}
                  placeholder="XXXXXXXXXXXXXXXXXXXX"
                  value={awsAccount.accessKeyId}
                />
                {validationErrors.accessKeyId.length > 0 && <ErrorArea errors={validationErrors.accessKeyId} />}
              </td>
            </tr>
            <tr>
              <th className="ca-group-aws-account-table__header">
                <Label name="secret_access_key" />
                <RequiredMark />
              </th>
              <td>
                <PasswordInputField
                  name="secret_access_key"
                  onChange={(value): void => setAwsAccount({ ...awsAccount, secretAccessKey: value })}
                  placeholder="****************************************"
                />
                {validationErrors.secretAccessKey.length > 0 && <ErrorArea errors={validationErrors.secretAccessKey} />}
              </td>
            </tr>
          </tbody>
        </table>
        {serverError != "" && <ServerError error={serverError} />}
        <SubmitButton
          disabled={awsAccount.accessKeyId == "" || awsAccount.name == ""}
          isTransmitting={isTransmitting}
        />
      </form>
    </ReactModal>
  );
};

/**
 * IAMユーザー方式でAWSアカウントを登録するマニュアルへのリンク
 */
const AwsAccountManualLink: React.FC = (): JSX.Element => {
  return (
    <ManualLink
      linkText={I18n.t("common.manual.text.account.iam_policy")}
      url={I18n.t("common.manual.url.account.iam_policy")}
    />
  );
};

/**
 * ダイアログの見出し
 */
const Heading: React.FC = (): JSX.Element => {
  return (
    <h2>
      <span className="fa fa-key" />
      &nbsp;
      {I18n.t("heading", { scope: I18N_SCOPE })}
    </h2>
  );
};

/**
 * 入力欄
 */
const InputField: React.FC<{
  name: string;
  onChange: (newValue: string) => void;
  placeholder: string;
  type: "text" | "password";
  value?: string;
}> = (props): JSX.Element => {
  const [value, setValue] = useState(props.value || "");

  return (
    <input
      className="form-control input-long input-sm inline-block required"
      id={`aws_account-${props.name}`}
      name={props.name}
      onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;
        setValue(value);
        props.onChange(value);
      }}
      placeholder={props.placeholder}
      type={props.type}
      value={value}
    />
  );
};

/**
 * 入力欄ラベル
 */
const Label: React.FC<{ name: string }> = (props): JSX.Element => {
  return <label htmlFor={`aws_account-${props.name}`}>{I18n.t(props.name, { scope: I18N_SCOPE })}</label>;
};

/**
 * パスワード入力欄
 */
const PasswordInputField: React.FC<{
  name: string;
  onChange: (newValue: string) => void;
  placeholder: string;
}> = (props): JSX.Element => {
  return <InputField type="password" {...props} />;
};

/**
 * 必須マーク
 */
const RequiredMark: React.FC = (): JSX.Element => {
  return <span className="required">*</span>;
};

/**
 * サーバーエラーメッセージ
 *
 * バリデーションエラー以外のエラーメッセージを表示します。
 */
const ServerError: React.FC<{ error: string }> = (props): JSX.Element => {
  return (
    <p>
      <label className="error">{props.error}</label>
    </p>
  );
};

/**
 * 送信ボタン
 */
const SubmitButton: React.FC<{
  disabled: boolean;
  isTransmitting: boolean;
}> = (props): JSX.Element => {
  const key = props.isTransmitting ? "transmitting" : "submit";
  const label = I18n.t(key, { scope: I18N_SCOPE });

  return (
    <input
      className="btn btn-success ca-group-aws-account-modal__submit-button"
      disabled={props.disabled || props.isTransmitting}
      type="submit"
      value={label}
    />
  );
};

/**
 * テキスト入力欄
 */
const TextInputField: React.FC<{
  name: string;
  onChange: (newValue: string) => void;
  placeholder: string;
  value: string;
}> = (props): JSX.Element => {
  return <InputField type="text" {...props} />;
};
