import React from 'react';
import PropTypes from 'prop-types';

const $ = window.jQuery;
const I18n = window.I18n; // i18n-js
const Modal = window.Modal;

/**
 * アクティビティ上限超過制限の切替スイッチ
 */
class ActivityLimitExceedanceToggleSwitch extends React.Component {
  /**
   * propTypes
   * @return {object}
   * @property {bool} enable アクティビティ実行回数の超過を許可しているかどうか
   * @property {bool} subscribeUnlimitedPlan Unlimitedプランを購読しているかどうか
   * @property {string} url 切替処理を行うURL
   */
  static get propTypes() {
    return({
      enable: PropTypes.bool.isRequired,
      subscribeUnlimitedPlan: PropTypes.bool.isRequired,
      url: PropTypes.string.isRequired
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);
    this.state = { enable: this.props.enable };
    this.handleStatusSwitchClick = this.handleStatusSwitchClick.bind(this);
    this.modal = this.createModal();

    const options = { scope: 'javascript.plan.toggle_modal' };

    if (this.props.subscribeUnlimitedPlan) {
      this.disableMessageText = I18n.t('unlimited_plan_disable_message', options);
      this.enableMessageText = I18n.t('unlimited_plan_enable_message', options);
    } else {
      this.disableMessageText = I18n.t('disable_message', options);
      this.enableMessageText = I18n.t('enable_message', options);
    }
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const enabled = this.state.enable;
    const toggleSwitchClasses = enabled ? 'toggle-switch on' : 'toggle-switch off';
    this.modal.setContent(`<p>${enabled ? this.disableMessageText : this.enableMessageText}</p>`);

    return(
      <React.StrictMode>
        <div
          className={toggleSwitchClasses}
          onClick={this.handleStatusSwitchClick}
        >
          <span className="toggle-button"></span>
        </div>
      </React.StrictMode>
    );
  }

  /**
   * @param {SyntheticEvent} event
   */
  handleStatusSwitchClick(event) {
    event.preventDefault();
    this.modal.show();
  }

  /**
   * @return {Modal}
   */
  createModal() {
    const modal = new Modal({
      id: 'qa-toggle-modal', // feature specからモーダルダイアログを参照するためのID
      positiveBtn: true,
      negativeBtn: true,
      width: 400
    });
    modal.setAction(() => {
      $.ajax({
        dataType: 'json',
        method: 'POST',
        url: this.props.url
      }).done((data) => {
        this.setState({
          enable: data.user.allow_activity_limit_exceedance
        });
      }).fail((jqXHR) => {
        const data = jqXHR.responseJSON;
        this.setState({
          enable: data.user.allow_activity_limit_exceedance
        }, () => {
          // TODO: DOM操作を行わずに済むようリファクタリングする
          const alert = '<div id="js-alert-message" class="alert alert-danger alert-dismissable">' +
            '<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>' +
            '<strong>Alert</strong>' +
            ' ' +
            data.message +
            '</div>';
          $('#container').prepend(alert);
        });
      }).always(() => {
        this.modal.hide();
      });
    });
    return modal;
  }
}

export default ActivityLimitExceedanceToggleSwitch;
