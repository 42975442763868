import React from "react";
import { ScheduleJob, TriggerJob } from "./types";
import { OldFirstJobMigrationMessage } from "./OldFirstJobMigrationMessage";

const I18n = window.I18n; // i18n-js

/**
 * 直近のスケジュールを表示します。
 * 直近のスケジュールが存在しない場合には予定がない旨を表示します。
 * @private
 */
const recentSchedulesElement = (job: ScheduleJob): JSX.Element => {
  if (job.recent_schedules.length === 0 || job.expired_schedule_job) {
    return (
      <div className="ca-workflow-trigger__expired">{I18n.t("javascript.job_workflow_form.no_execution_schedule")}</div>
    );
  }

  return (
    <>
      {job.recent_schedules.map((schedule, index) => {
        return <li key={index}>{schedule}</li>;
      })}
    </>
  );
};

/**
 * HTTPトリガーの詳細を表示します。
 * @public
 */
export const ScheduleTriggerDetails = (job: ScheduleJob): JSX.Element => {
  return (
    <div className="ca-workflow-trigger">
      <div className="ca-workflow-trigger__icon-area">
        <div className="ca-workflow-trigger__icon">
          <i className="fa-duotone fa-solid fa-bullseye-pointer"></i>
        </div>
        {I18n.t("common.rule_types_abb.schedule")}
        <br />
        {I18n.t("activerecord.attributes.trigger_job.rule_type")}
      </div>
      <div className="ca-workflow-trigger__info-area">
        {/* TODO: 機能フラグ v27_job_workflow_improvement が不要になったら OldFirstJobMigrationMessage を削除する */}
        <OldFirstJobMigrationMessage job={job as TriggerJob} />
        <div className="ca-workflow-trigger__item">
          <div className="ca-workflow-trigger__label">{I18n.t("activerecord.attributes.trigger_job.time_zone")}</div>
          <div className="ca-workflow-trigger__data">{job.show_time_zone_and_offset}</div>
        </div>

        <div className="ca-workflow-trigger__item">
          <div className="ca-workflow-trigger__label">
            {I18n.t("trigger_jobs.show_schedule_rule_value.timetable")}
            <br />
            {I18n.t("javascript.job_workflow_form.recent_schedules")}
          </div>
          <div className="ca-workflow-trigger__data">{recentSchedulesElement(job)}</div>
        </div>
      </div>
    </div>
  );
};
