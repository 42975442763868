import React from 'react';
import PropTypes from 'prop-types';
import EventEmitter from 'events';

import GroupIconTip from '../GroupIconTip.jsx';

const I18n = window.I18n; // i18n-js

/**
 * リードオンリーな後処理割り当てパネルコンポーネント
 * 成功時または失敗時どちらかの後処理割り当てをリスト表示するパネルです。
 */
class ReadOnlyPostProcessAssignmentsPanel extends React.Component {
  /**
   * プロパティ定義を返します
   *
   * @public
   * @property {number[]} assignments 割り当てられている後処理のID
   * @property {Group} group ポリシーセットが属するグループ
   * @property {?string} panelClass 後処理割り当てパネルに設定するclass属性値
   * @property {Array<PostProcess>} postProcesses ポリシーに設定されている後処理
   * @property {string} title 見出しの文字列
   * @property {string} titleIcon 見出しのアイコン(FontAwesomeのclass名)
   */
  static get propTypes() {
    return({
      assignments: PropTypes.arrayOf(PropTypes.number).isRequired,
      group: PropTypes.object.isRequired,
      panelClass: PropTypes.string,
      postProcesses: PropTypes.arrayOf(PropTypes.object).isRequired,
      title: PropTypes.string.isRequired,
      titleIcon: PropTypes.string.isRequired
    });
  }

  /**
   * プロパティ定義のデフォルト値を返します
   *
   * @public
   * @property {string} panelClass 後処理割り当てパネルに設定するclass属性値
   */
  static get defaultProps() {
    return({
      panelClass: ''
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);

    // 利用しないが PostProcessAssignment に渡すために必要
    this.emitter = new EventEmitter;
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    const iconClasses = `fa ${this.props.titleIcon}`;

    return(
      <div className={`
        ca-post-process-assignments-panel
        ca-post-process-assignments-panel--full-height
        ${this.props.panelClass}
      `}>
        <div className="ca-post-process-assignments-panel__heading">
          <span className={iconClasses}></span> {this.props.title}
        </div>
        <div className="ca-post-process-assignments-panel__content">
          {this.renderAssignments()}
        </div>
      </div>
    );
  }

  /**
   * 後処理割り当てのリストを返します。
   * 後処理が1件も割り当てられていない場合はリストではなくメッセージを返します。
   *
   * @public
   * @return {ReactElement}
   */
  renderAssignments() {
    if (this.props.assignments.length < 1) {
      return(
        <span>
          {I18n.t('javascript.post_process_assignments_panel.no_assignments')}
        </span>
      );
    }

    return(
      <ul className="ca-post-process-assignments-panel__list">
        {this.props.assignments.map(assignment => this.renderAssignment(assignment))}
      </ul>
    );
  }

  /**
   * 1件の後処理割り当てをあらわすリストの要素を返します。
   *
   * @param {number} assignment 割り当てられている後処理のID
   */
  renderAssignment(assignment) {
    const postProcess = this.props.postProcesses.find(item => item.id == assignment);

    return(
      <li className="ca-post-process-assignments-panel__list-item" key={assignment}>
        <GroupIconTip group={postProcess.group} />
        <a href={`/post_processes/${postProcess.id}`}>{postProcess.name}</a>
      </li>
    );
  }
}

export default ReadOnlyPostProcessAssignmentsPanel;
