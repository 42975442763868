import React, { useMemo } from "react";

import { AccountType } from "./types";
import { ProviderAccountTypeSelector } from "./ProviderAccountTypeSelector";

type Props = {
  // デフォルトの選択値
  defaultValue: AccountType;
  // グループカラー
  groupColor: string;
  // グループ名
  groupName: string;
};

/**
 * グループ追加フォーム専用のプロバイダアカウント登録方式の選択UI
 *
 * 以下の責務を持ちます。
 *
 * - プロバイダアカウント登録方式の選択UIの表示
 * - 方式変更時にグループ名とグループカラーを引き継いでページを遷移させる
 */
export const ProviderAccountTypeSelectorForGroup: React.FC<Props> = (props): JSX.Element => {
  const groupName = useMemo(() => encodeURIComponent(props.groupName), [props.groupName]);
  const groupColor = useMemo(() => encodeURIComponent(props.groupColor), [props.groupColor]);

  return (
    <ProviderAccountTypeSelector
      defaultValue={props.defaultValue}
      onGoogleServiceAccountSelected={(): void => {
        window.location.href = `/groups/new_with_google_service_account?name=${groupName}&color=${groupColor}`;
      }}
      onIamRoleSelected={(): void => {
        window.location.href = `/groups/new_with_iam_role?name=${groupName}&color=${groupColor}`;
      }}
      onIamUserSelected={(): void => {
        window.location.href = `/groups/new_with_iam_user?name=${groupName}&color=${groupColor}`;
      }}
    />
  );
};
