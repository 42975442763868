import PropTypes from 'prop-types';
import React from 'react';

import Constants from './Constants.js';

const I18n = window.I18n; // i18n-js

/**
 * 空のテーブル行コンポーネント。
 *
 * テーブルに表示するデータが存在しない場合のメッセージを、テーブル内の1行として表示します。
 * 表示されるDOMはTRタグとなります。
 * 子要素が存在しない場合はデフォルトのメッセージ(該当するデータはありません。)を表示します。
 *
 *   <EmptyRow columns={1} />
 *
 *   <EmptyRow columns={1}>
 *     <img src="empty_state.png" />
 *   </EmptyRow>
 */
export default class EmptyRow extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {ReactElement} children 子コンポーネント
   * @property {number} columns 親となるテーブルの列の数
   */
  static get propTypes() {
    return({
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
      ]),
      columns: PropTypes.number.isRequired,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    const options = { scope : `${Constants.I18N_SCOPE}.empty_row` };
    this.messageText = I18n.t('message', options);
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <tr>
        <td colSpan={this.props.columns}>
          {this.props.children || this.messageText}
        </td>
      </tr>
    );
  }
}
