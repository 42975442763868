import React from 'react';
import PropTypes from 'prop-types';

const I18n = window.I18n; // i18n-js

const I18N_SCOPE = 'javascript.policy_set_form';

/**
 * ポリシーのトリガータイプ表示コンポーネント
 *
 * triggerType - ポリシーのトリガータイプ
 *
 * Examples
 *
 *   <PolicyTriggerType
 *     triggerType={triggerType}
 *   />
 */
class PolicyTriggerType extends React.Component {
  static get propTypes() {
    return({
      triggerType: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    });
  }

  constructor(props) {
      super(props);
  }

  render() {
    return(
      <span className="label trigger-type">
        <span className={`fa ${this.getTriggerTypeIcon(this.props.triggerType)} marginR5`}></span>
        {this.getTriggerTypeText(this.props.triggerType)}
      </span>
    );
  }

  /**
   * トリガータイプカラムに表示する文字列を返します。
   *
   * @param {string}
   */
  getTriggerTypeText(type) {
    let i18nPath = "";
    let resourceText = "";

    if (Array.isArray(type)) {
      const target_resource_text = I18n.t("target_resource", { scope: I18N_SCOPE});
      i18nPath = 'label_for_configuration_changed';
      resourceText = ` ( ${target_resource_text}: ${type} )`.replace(/,/, " / ")
                                                            .replace(/A(WS|ws)::/g, "");
    } else {
      i18nPath = 'label_for_periodic';
    }

    return(
      I18n.t(i18nPath, { scope: I18N_SCOPE }) + resourceText
    );
  }

  /**
   * トリガータイプを表すCSSのクラスを返します。
   *
   * @param {string}
   */
  getTriggerTypeIcon(type) {
    if (Array.isArray(type)) {
      return "fa-cube";
    } else {
      return "fa-clock-o";
    }
  }
}

export default PolicyTriggerType;
