// NOTE: 今後はwithBootstrapTooltip関数よりも BootstrapTooltip コンポーネントを利用するようにしてください。
import React from 'react';

const jquery = window.jQuery;

/**
 * 任意のReactコンポーネントをBootstrapのTooltipが機能する形にラップして返します。
 * この関数を経由したReactコンポーネント内では data-toggle="tooltip" 属性を持つ要素の
 * ツールチップが表示されるようになります。
 *
 * 利用する際は、対象のReactコンポーネント側で以下の3ステップの記述を行います。
 *
 * 1. withBootstrapTooltip.js をimportする(インポートした結果は関数となる)
 * 2. 対象のコンポーネントのプロパティに tooltipRef (func) を追加する
 * 3. 対象のコンポーネントのrender関数内で、ルートとなる要素に
 *    `ref={this.props.tooltipRef}` というプロパティを指定する
 * 4. 対象のコンポーネントをwithBootstrapTooltip関数でラップした戻り値を
 *    新たなReactコンポーネントとして利用する
 *
 * サンプルコードは以下の通りです。
 *
 *   // withBootstrapTooltip関数を取得する
 *   import withBootstrapTooltip from 'withBootstrapTooltip.js';
 *
 *   // 対象のコンポーネント
 *   class Message extends React.Component {
 *     static get propTypes() {
 *       return({
 *         tooltipRef: PropTypes.func,
 *       });
 *     }
 *
 *     render() {
 *       // ルートとなるdiv要素にrefプロパティを設定する
 *       return(
 *         <div ref={this.props.tooltipRef}>
 *           <span data-toggle="tooltip" title="ヒント">テスト</span>
 *         </div>
 *       );
 *     }
 *   }
 *   // コンポーネントをラップしたものをエクスポートする
 *   export default withBootstrapTooltip(Message);
 *
 *   // ツールチップを適用する要素のセレクタを変更する場合
 *   export default withBootstrapTooltip(Message, '#js-tooltip');
 *
 * @public
 * @param {React.Component} WrappedComponent ラップ対象のReactコンポーネント
 * @param {string|null} selector ツールチップを適用する要素のセレクタ(デフォルトは `data-toggle="tooltip"`)
 * @return {React.Component}
 */
export default function withBootstrapTooltip(WrappedComponent, selector = null) {
  // この関数は呼び出される度に、対象のコンポーネントをラップした新しいReactコンポーネント
  // を定義し、表示名を設定したうえで関数の戻り値として返します。

  /**
   * 内包するDOMノードでBootstrap Tooltipを有効にする処理を持ったReactコンポーネント定義
   */
  class WithBootstrapTooltip extends React.Component {
    /**
     * コンポーネントを初期化します。
     *
     * @public
     * @param {Object} props プロパティ
     */
    constructor(props) {
      super(props);
      this.domNode = null;
      this.selector = selector || '[data-toggle="tooltip"]';
    }

    /**
     * コンポーネントがマウントされた際の処理を行います。
     *
     * @public
     */
    componentDidMount() {
      this.enableTooltip();
    }

    /**
     * コンポーネントが新しいプロパティまたはステートによって更新された後の処理を行います。
     *
     * @public
     */
    componentDidUpdate() {
      this.enableTooltip();
    }

    /**
     * @public
     * @return {ReactElement}
     */
    render() {
      return(
        <WrappedComponent
          tooltipRef={element => this.domNode = element}
          {...this.props}
        />
      );
    }

    /**
     * ラップしたコンポーネントに含まれるDOMノードに対して、BootstrapのTooltipを
     * 有効化します。
     *
     * @private
     */
    enableTooltip() {
      jquery(this.domNode).find(this.selector).tooltip();
    }
  }

  // WithBootstrapTooltip コンポーネントの表示名を、内包するコンポーネント名を含む形に
  // 変更する
  WithBootstrapTooltip.displayName = `WithBootstrapTooltip(${getDisplayName(WrappedComponent)})`;

  return WithBootstrapTooltip;
}

/**
 * Reactコンポーネントの表示名を返します。
 *
 * @private
 * @param {React.Component} WrappedComponent
 * @return {string}
 */
function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
