// ジョブワークフローフォーム関連コンポーネントで利用する定数

export default {
  // EventEmitterで扱うイベントの識別子
  EVENT_CHANGE_ACTIVE: 'changeActive',
  EVENT_CHANGE_GROUP: 'changeGroup',
  EVENT_CHANGE_NAME: 'changeName',
  EVENT_CHANGE_TRIGGER_JOB: 'changeTriggerJob',
  EVENT_SUBMIT_FORM: 'submitForm',

  // I18nメッセージリソースのスコープ
  I18N_SCOPE: 'javascript.job_workflow_form',
};
