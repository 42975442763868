import { useState, useEffect } from "react";

/**
 * DOMイベント用のイベントハンドラが受け取るイベントから、currentTargetを取り出せることを
 * 型として表現するためのインターフェース。
 */
interface HTMLElementEvent<T extends EventTarget> extends Event {
  currentTarget: T;
}

/** セレクトボックスの選択肢 */
export type Option = {
  /** 選択肢の値 */
  id: string;
  /** 選択肢のラベル */
  label: string;
};

/** サーバーからのレスポンスに含まれるセレクトボックスの選択肢の情報 */
export type OptionFromServer = {
  /** 選択肢の値 */
  id: string;
  /** 選択肢のラベル */
  label: string;
  /** 選択肢のロード直後に選択状態にするかどうか */
  is_default: boolean;
};

/**
 * React外にあるフォーム入力欄のDOM要素の現在値を読み書きするためのhook。
 *
 * このhookを利用すると、Reactの管理外の入力欄で値が変化した場合に、新しい値を
 * リアルタイムに得ることができます。
 */
export const useInputValue = (selector: string): [string, (value: string) => void] => {
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    const element = document.querySelector<HTMLSelectElement>(selector);

    if (element == null) {
      throw new Error(`Couldn't find an element with selector '${selector}'.`);
    }
    setValue(element.value);

    const handler = (e: HTMLElementEvent<HTMLSelectElement>): void => {
      setValue(e.currentTarget.value);
    };
    element.addEventListener("change", { handleEvent: handler });

    return (): void => {
      element.removeEventListener("change", handler);
    };
  }, []);

  return [value, setValue];
};
