import camelize from "camelize";
import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useToggle } from "react-use";

import { showSuccessToast } from "../../toast";
import ErrorArea from "../ErrorArea";

import { AwsAccountWithIamRole } from "./types";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.groups.aws_accounts.edit_aws_account_with_iam_role_dialog";

/**
 * AWSアカウント(IAMロール方式)の更新時に送信するデータ
 * groups/aws_accounts#update の仕様と合わせる。
 */
type UpdateAwsAccountData = {
  account_type: "iam_role";
  aws_account: {
    name: string;
  };
};
/**
 * useEditAwsAccountLogicフックの戻り値の型
 */
type UseEditAwsAccountLogicResult = {
  /**
   * 編集中のAWSアカウント
   */
  awsAccount: AwsAccountWithIamRole;
  /**
   * キャンセル操作時に呼び出す関数
   */
  handleCancel: () => void;
  /**
   * フォーム送信時に呼び出す関数
   */
  handleSubmit: (event: React.SyntheticEvent) => void;
  /**
   * 通信中かどうかのフラグ
   */
  isTransmitting: boolean;
  /**
   * サーバーから返されたエラーメッセージ
   */
  serverError: string;
  /**
   * 編集中のAWSアカウントを更新するための関数
   */
  setAwsAccount: (awsAccount: AwsAccountWithIamRole) => void;
  /**
   * バリデーションエラーメッセージ
   */
  validationErrors: ValidationErrors;
};

/**
 * フォームのバリデーションエラーメッセージを保持するオブジェクトの型
 */
type ValidationErrors = {
  /**
   * IAMロールのバリデーションエラーメッセージ
   * IAMロールが権限エラーだった場合のエラーメッセージの表示に利用する。
   */
  iamRole: string[];
  /**
   * アカウント名のバリデーションエラーメッセージ
   */
  name: string[];
};

/**
 * フォームのバリデーションエラーメッセージの初期状態
 */
const emptyValidationErrors: ValidationErrors = {
  iamRole: [],
  name: [],
};

/**
 * AWSアカウント(IAMロール方式)編集用ビジネスロジックのHook
 *
 * @param target 編集対象のAWSアカウント
 * @param cancelCallback キャンセル操作時に呼び出すコールバック
 * @param updatedCallback 更新完了時に呼び出すコールバック
 */
const useEditAwsAccountLogic = (
  target: AwsAccountWithIamRole,
  cancelCallback: () => void,
  updatedCallback: () => void
): UseEditAwsAccountLogicResult => {
  // 編集中のAWSアカウント
  const [awsAccount, setAwsAccount] = useState<AwsAccountWithIamRole>({ ...target });
  // 通信中かどうかのフラグ
  const [isTransmitting, toggleTransmitting] = useToggle(false);
  // サーバーから返されたエラーメッセージ
  const [serverError, setServerError] = useState("");
  // バリデーションエラーメッセージ
  const [validationErrors, setValidationErrors] = useState(emptyValidationErrors);

  /**
   * 編集をキャンセルする関数
   *
   * ダイアログを閉じる操作が行われた場合に実行されます。
   */
  const handleCancel = (): void => {
    cancelCallback();
    // ダイアログを閉じた後も入力内容やエラーメッセージは保持されるため、再度開いた時にそれらが
    // リセットされているようにする。
    setAwsAccount({ ...target });
    setValidationErrors(emptyValidationErrors);
    setServerError("");
  };

  /**
   * サーバーに送信するデータを生成する関数
   */
  const buildData = (): UpdateAwsAccountData => {
    return {
      account_type: "iam_role",
      aws_account: {
        name: awsAccount.name,
      },
    };
  };

  /**
   * フォームの送信処理を行う関数
   *
   * 編集中のAWSアカウントをサーバーサイドに反映し、レスポンスに応じた処理を行います。
   * リクエストは、「編集中のAWSアカウントの updateUrl プロパティ」に設定されているURLに対して
   * PATCHメソッドで行われます。
   */
  const handleSubmit = (event): void => {
    event.preventDefault();

    jQuery
      .ajax({
        beforeSend: () => {
          setValidationErrors(emptyValidationErrors);
          setServerError("");
          toggleTransmitting(true);
        },
        data: buildData(),
        dataType: "json",
        method: "PATCH",
        url: target.updateUrl,
      })
      .done(() => {
        updatedCallback();
        showSuccessToast(I18n.t("succeeded", { name: awsAccount.name, scope: I18N_SCOPE }));
      })
      .fail((jqXHR, textStatus) => {
        if (jqXHR.status == 422) {
          const errors = camelize(jqXHR.responseJSON?.errors ?? {});
          setValidationErrors({ ...emptyValidationErrors, ...errors });
        } else {
          setServerError(I18n.t("error", { message: textStatus, scope: I18N_SCOPE }));
        }
      })
      .always(() => toggleTransmitting(false));
  };

  return { awsAccount, setAwsAccount, isTransmitting, serverError, validationErrors, handleCancel, handleSubmit };
};

/**
 * AWSアカウント(IAMロール方式)編集ダイアログ
 *
 * 以下の責務を持ちます。
 *
 * - 編集ダイアログ内のコンテンツの表示
 * - キャンセル操作時のコールバック呼び出し(ダイアログ表示の制御はしない)
 * - 更新操作時のリクエスト送信
 * - 更新成功時のコールバック呼び出し(ダイアログ表示の制御はしない)
 * - 更新成功時のToast UIの表示
 * - 更新失敗時のエラーメッセージの表示
 */
export const EditAwsAccountWithIamRoleDialog: React.FC<{
  awsAccount: AwsAccountWithIamRole;
  isOpen: boolean;
  onCancel: () => void;
  onUpdated: () => void;
}> = (props): JSX.Element => {
  const {
    awsAccount,
    setAwsAccount,
    serverError,
    validationErrors,
    isTransmitting,
    handleCancel,
    handleSubmit,
  } = useEditAwsAccountLogic(props.awsAccount, props.onCancel, props.onUpdated);

  useEffect(() => {
    ReactModal.setAppElement("body");
  }, []);

  return (
    <ReactModal
      className="ca-group-aws-account-modal__content"
      isOpen={props.isOpen}
      onRequestClose={handleCancel}
      overlayClassName="ca-group-aws-account-modal__overlay"
      role="dialog"
    >
      <div className="text-left">
        <Heading />
      </div>

      <form className="ca-group-aws-accounts-edit-iam-role-form" onSubmit={handleSubmit}>
        <table className="table vertical-middle no-borders ca-group-aws-account-modal__input-group text-left">
          <tbody>
            <tr>
              <td>
                <div className="ca-group-aws-accounts-edit-iam-role-form__note-area">
                  <p className="ca-group-aws-accounts-edit-iam-role-form__note-area__cfn-stack-name-note">
                    {I18n.t("cfn_stack_name_note", { scope: I18N_SCOPE })}
                  </p>
                </div>
                <div className="ca-group-aws-accounts-edit-iam-role-form__info-area">
                  <table className="ca-group-aws-accounts-edit-iam-role-form__info-area__cfn-stack-info">
                    <tbody>
                      <tr>
                        <th>{I18n.t("arn", { scope: I18N_SCOPE })}</th>
                        <td>{props.awsAccount.iamRoleArn}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="ca-group-aws-accounts-edit-iam-role-form__info-area__border"></div>
                  <table className="ca-group-aws-accounts-edit-iam-role-form__info-area__cfn-stack-info">
                    <tbody>
                      <tr>
                        <th>{I18n.t("region", { scope: I18N_SCOPE })}</th>
                        <td>
                          {props.awsAccount.cfnStackRegion.length > 0 ? (
                            <p>{props.awsAccount.cfnStackRegion}</p>
                          ) : (
                            <p>---</p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>{I18n.t("cfn_stack_name", { scope: I18N_SCOPE })}</th>
                        <td>{props.awsAccount.cfnStackName}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="form-group">
                  <Label name="name" />
                  <RequiredMark />
                  <TextInputField
                    name="name"
                    onChange={(value): void => setAwsAccount({ ...awsAccount, name: value })}
                    placeholder={I18n.t("name_placeholder", { scope: I18N_SCOPE })}
                    value={awsAccount.name}
                  />
                  {validationErrors.name.length > 0 && <ErrorArea errors={validationErrors.name} />}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {serverError != "" && <ServerError error={serverError} />}
        {validationErrors.iamRole.length > 0 && <ErrorArea errors={validationErrors.iamRole} />}
        <SubmitButton disabled={awsAccount.name == ""} isTransmitting={isTransmitting} />
      </form>
    </ReactModal>
  );
};

const Heading: React.FC = (): JSX.Element => {
  return (
    <h2>
      <span className="fa fa-key" />
      &nbsp;
      {I18n.t("heading", { scope: I18N_SCOPE })}
    </h2>
  );
};

/**
 * 入力欄
 */
const InputField: React.FC<{
  name: string;
  onChange: (newValue: string) => void;
  placeholder: string;
  type: "text" | "password";
  value?: string;
}> = (props): JSX.Element => {
  const [value, setValue] = useState(props.value || "");

  return (
    <input
      className="form-control input-long input-sm inline-block required"
      id={`aws_account-${props.name}`}
      name={props.name}
      onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;
        setValue(value);
        props.onChange(value);
      }}
      placeholder={props.placeholder}
      type={props.type}
      value={value}
    />
  );
};

const Label: React.FC<{ name: string }> = (props): JSX.Element => {
  return (
    <label className="ca-group-aws-accounts-edit-iam-role-form__field-label" htmlFor={`aws_account-${props.name}`}>
      {I18n.t(props.name, { scope: I18N_SCOPE })}
    </label>
  );
};

const RequiredMark: React.FC = (): JSX.Element => {
  return <span style={{ color: "#e23c3c", marginLeft: "2px", fontSize: "16px" }}>*</span>;
};

/**
 * サーバーエラーメッセージ
 *
 * バリデーションエラー以外のエラーメッセージを表示します。
 */
const ServerError: React.FC<{ error: string }> = (props): JSX.Element => {
  return (
    <p>
      <label className="error">{props.error}</label>
    </p>
  );
};

/**
 * 送信ボタン
 */
const SubmitButton: React.FC<{
  disabled: boolean;
  isTransmitting: boolean;
}> = (props): JSX.Element => {
  const key = props.isTransmitting ? "transmitting" : "submit";
  const label = I18n.t(key, { scope: I18N_SCOPE });

  return (
    <input
      className="btn btn-success ca-group-aws-account-modal__submit-button"
      disabled={props.disabled || props.isTransmitting}
      type="submit"
      value={label}
    />
  );
};

/**
 * テキスト入力欄
 */
const TextInputField: React.FC<{
  name: string;
  onChange: (newValue: string) => void;
  placeholder: string;
  value: string;
}> = (props): JSX.Element => {
  return <InputField type="text" {...props} />;
};
