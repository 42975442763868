import React from "react";
import PropTypes from "prop-types";

import RemoveUserButton from "./RemoveUserButton.jsx";
import PermissionIcon from "../../../PermissionIcon.jsx";

const I18n = window.I18n;

/**
 * ユーザーのグループを表示するテーブルの一行を表すコンポーネント
 */
export default class UserGroupsTableRow extends React.Component {
  /**
   * プロパティ定義を返します
   *
   * @property {bool} calendarFeatureEnabled カレンダー機能が有効かどうか
   * @property {object} group グループオブジェクト
   * @property {boolean} policySetsAvailable 組織が構成レビュー機能を利用可能かどうか
   * @property {object} reloadEmitter グループ一覧をリロードするイベントを発火するオブジェクト。
   * @property {number} userId ユーザーのID
   * @property {number} userName ユーザーの名前
   * @property {bool} userRemovable ユーザーが除外可能かどうかを返す。画面により除外可能かどうかが変わる。
   */
  static get propTypes() {
    return {
      calendarFeatureEnabled: PropTypes.bool.isRequired,
      group: PropTypes.object.isRequired,
      policySetsAvailable: PropTypes.bool.isRequired,
      reloadEmitter: PropTypes.object.isRequired,
      userId: PropTypes.number.isRequired,
      userName: PropTypes.string.isRequired,
      userRemovable: PropTypes.bool.isRequired,
    };
  }

  /**
   * @return {ReactElement}
   */
  render() {
    // tooltip表示用メッセージ
    const group = this.props.group;
    const scope = { scope: "activerecord.attributes.user_assignment.permission" };

    const jobPermission = I18n.t(group.job_permission, scope);
    const policySetPermission = I18n.t(group.policy_set_permission, scope);
    const InventoryPermission = I18n.t(
      'inventory.workspaces_' + (group.inventory_permission || 'permission_null'),
      { scope: "javascript.group_user" }
    );
    const CalendarPermission = I18n.t(group.calendar_permission, scope);

    let removeButtonColumn = null;
    if(this.props.userRemovable) {
      removeButtonColumn = <td className="text-right ca-user-groups-table__remove-button">
        <RemoveUserButton
          group={this.props.group}
          reloadEmitter={this.props.reloadEmitter}
          userId={this.props.userId}
          userName={this.props.userName}
        />
      </td>;
    }

    // 削除ボタンのあるなしで崩れないようにグループ名のところでpaddingを指定している
    // .table > tbody > tr > td の形で元々paddingが指定されているのでこの形が手っ取り早い
    return (
      <tr>
        <td style={{padding: "12px 6px"}}>
          <span className="fa fa-briefcase font-18px ca-user-groups-table__group-icon" style={{color: this.props.group.color}}></span>
          {this.props.group.name}
        </td>
        <td>
          <PermissionIcon permission={group.job_permission} permissionName={jobPermission} />
        </td>
        {this.props.policySetsAvailable && (
          <td>
            <PermissionIcon permission={group.policy_set_permission} permissionName={policySetPermission} />
          </td>
        )}
        <td>
          <PermissionIcon permission={group.inventory_permission} permissionName={InventoryPermission} />
        </td>
        { this.props.calendarFeatureEnabled && (
          <td>
            <PermissionIcon permission={group.calendar_permission} permissionName={CalendarPermission} />
          </td>
        ) }
        <td className="text-center">{this.props.group.users_count}</td>
        <td className="ca-user-groups-table__time"><time>{this.props.group.registered_at}</time></td>
        {removeButtonColumn}
      </tr>
    );
  }
}
