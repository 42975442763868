import React from "react";
import PropTypes from "prop-types";

export class ErrorArea extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {ReacteElement[]} errors エラーメッセージの配列
   */
  static get propTypes() {
    return {
      errors: PropTypes.array.isRequired,
    };
  }

  /**
   * デフォルトのプロパティを返します。
   *
   * @public
   * @return {Object}
   * @property {array} errors 表示するエラーメッセージ
   */
  static get defaulProps() {
    return {
      errors: [],
    };
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const errors = [];
    this.props.errors.forEach((error, index) => {
      errors.push(
        <li className="error" key={index}>
          <label className="error">{error}</label>
        </li>
      );
    });

    if (errors.length > 0) {
      return (
        <div className="error-area">
          <ul>{errors}</ul>
        </div>
      );
    }
    return <div className="error-area"></div>;
  }
}
