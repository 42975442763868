import React from "react";
import PropTypes from "prop-types";

import DashboardSpinner from "../DashboardSpinner.jsx";
import Pagination from "../Pagination.jsx";

import TriggerJobTable from "./TriggerJobTable.jsx";

const $ = window.jQuery;
const I18n = window.I18n;

/**
 * 割当ジョブテーブルを含むコンテナコンポーネント
 *
 * このコンポーネントはマウント時のみ、サーバーから後処理割当ジョブ一覧を取得します。
 * それ以外の操作（ページングなど）が行われた場合は既に取得済みのJSONをもとに表示するジョブを決定します。
 *
 * ステート
 * currentPage   - 現在のページ番号
 *                 初期値は 1
 * errorMessage  - エラーが発生した場合のエラーメッセージ
 *                 初期値は null
 * triggerJobs   - 後処理に割り当てられているジョブの配列
 *                 source プロパティのURLから返されるJSONの triggerJobs キーの値が設定される
 *                 初期値は空の配列
 * perPage       - 1ページあたりの表示件数
 *                 初期値は 30
 * totalItems    - 割当ジョブの数
 *                 source プロパティのURLから返されるJSONの total キーの値が設定される
 *                 初期値は 0
 */
export default class TriggerJobTableContainer extends React.Component {
  /**
   * プロパティ定義を返します
   *
   * @public
   * @property {string} source ジョブの取得に利用するURL
   */
  static get propTypes() {
    return {
      source: PropTypes.string.isRequired
    };
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);
    this.state = this.initialState();

    this.handlePaginationPageClick = this.handlePaginationPageClick.bind(this);

    // ローディング中(Ajax通信中)に表示するDashboardSpinnerに渡すpropsを定義する
    this.spinOptions = {
      lines: 9,
      length: 3,
      width: 3,
      radius: 5,
      top: "15px",
      left: "220px"
    };
  }

  /**
   * 初期ステートを作成して返します
   */
  initialState() {
    let currentPage = 1;

    $.cookie.json = true;
    const settings = $.cookie("post_process_trigger_jobs_settings");
    if ($.isPlainObject(settings)) {
      if (settings.current_page != null) {
        currentPage = Number(settings.current_page);
      }
    }

    return {
      currentPage: currentPage,
      errorMessage: null,
      triggerJobs: [],
      perPage: 30,
      totalItems: 0
    };
  }

  /**
   * コンポーネントがマウントされた際の処理を行います。
   */
  componentDidMount() {
    this.loadAlltriggerJobs();
  }

  /**
   * @return {ReactElement}
   */
  render() {
    // 現在のページに表示するジョブをstateから取得する
    const currentPagetriggerJobs = this.getTriggerJobsForCurrentPage();

    // ジョブの件数
    const triggerJobCount = this.state.triggerJobs.length;

    return (
      <React.StrictMode>
        <div className="ca-post-process-jobs">
          <span className="ca-post-process-jobs__count">
            {I18n.t("javascript.post_process_trigger_jobs.count", {
              total: triggerJobCount
            })}
          </span>
          <DashboardSpinner spinOptions={this.spinOptions} />
          <TriggerJobTable
            triggerJobs={currentPagetriggerJobs}
            errorMessage={this.state.errorMessage}
          />
          <Pagination
            totalItems={this.state.totalItems}
            perPage={this.state.perPage}
            currentPage={this.state.currentPage}
            callback={this.handlePaginationPageClick}
          />
        </div>
      </React.StrictMode>
    );
  }

  /**
   * ページングナビゲーションのページ番号がクリックされた際の処理を実行します。
   * @param {number} page ページ番号
   */
  handlePaginationPageClick(page) {
    this.setState({ currentPage: page }, () => this.saveSettings());
  }

  /**
   * 割当ジョブ一覧をサーバーから取得します。
   */
  loadAlltriggerJobs() {
    $.ajax({
      type: "GET",
      url: this.props.source
    })
      .done(data => {
        // レスポンスのJSONフォーマットをチェックする
        const requiredKeys = ["total", "trigger_jobs"];
        let invalidFormat = false;
        requiredKeys.forEach(key => {
          if (data[key] == undefined) {
            invalidFormat = true;
          }
        });

        if (invalidFormat) {
          this.setState({
            currentPage: 1,
            triggerJobs: [],
            errorMessage: I18n.t(
              "javascript.post_process_trigger_jobs.invalid_server_response"
            ),
            totalItems: 0
          });
          return;
        }

        let newState = {
          errorMessage: null,
          triggerJobs: data.trigger_jobs,
          totalItems: data.total
        };

        // 現在ページが1以外の場合は、現在ページが超過しないかチェックする
        if (this.state.currentPage > 1) {
          // 取得したジョブの数が現在ページに表示する件数よりも少ない場合は
          // 現在ページを1にする(Cookiesに設定が保存されていた場合に対応するため)
          const idxOfCurrenttriggerJobs =
            (this.state.currentPage - 1) * this.state.perPage + 1;
          if (data.total < idxOfCurrenttriggerJobs) {
            newState.currentPage = 1;
          }
        }

        this.setState(newState);
      })
      .fail(() => {
        this.setState({
          currentPage: 1,
          triggerJobs: [],
          errorMessage: I18n.t("javascript.post_process_trigger_jobs.load_error"),
          totalItems: 0
        });
      });
  }

  /**
   * 現在のページに表示する範囲のジョブだけを返します。
   * @return {array}
   */
  getTriggerJobsForCurrentPage() {
    // ジョブを現在のページに表示する範囲だけに絞り込む
    const start = this.state.perPage * (this.state.currentPage - 1);
    const end = this.state.perPage * this.state.currentPage;

    return this.state.triggerJobs.slice(start, end);
  }

  /**
   * 現在の設定をCookieに保存します。
   */
  saveSettings() {
    // 選択されたページをCookieに保存する
    $.cookie('post_process_trigger_jobs_settings', {
      current_page: this.state.currentPage,
    });
  }
}
