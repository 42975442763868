import React from 'react';
import PropTypes from 'prop-types';

import AwsAccountIdField from './AwsAccountIdField.jsx';
import Constants from './Constants.js';
import ErrorMessages from '../ErrorMessages.jsx';
import Policies from './Policies.jsx';
import PolicySetNameField from './PolicySetNameField.jsx';
import PricingSection from './PricingSection.jsx';
import RegionField from './RegionField.jsx';
import SubmitButton from './SubmitButton.jsx';

const _ = window._; // Underscore.js
const I18n = window.I18n; // i18n-js

// ポリシーセットフォーム
// ポリシーセットフォームの表示に関する責務を持ちます。
class PolicySetForm extends React.Component {
  static get propTypes() {
    return({
      awsAccountId: PropTypes.string.isRequired, // 空文字列もあり得るのでnumberではない
      awsAccountIdErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
      awsAccounts: PropTypes.arrayOf(PropTypes.array).isRequired,
      baseErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
      completedAssignments: PropTypes.object.isRequired,
      defaultLanguage: PropTypes.string.isRequired,
      defaultTimeZone: PropTypes.string.isRequired,
      emitter: PropTypes.object.isRequired,
      failedAssignments: PropTypes.object.isRequired,
      failedPostProcessIds: PropTypes.object.isRequired, // TODO: Deprecate
      group: PropTypes.object.isRequired,
      groups: PropTypes.arrayOf(PropTypes.array).isRequired,
      isSlackIntegrated: PropTypes.bool.isRequired,
      isUserCanManageIntegrations: PropTypes.bool.isRequired,
      loadingImagePath: PropTypes.string.isRequired,
      newRecord: PropTypes.bool.isRequired,
      overseas: PropTypes.bool.isRequired,
      parameterValidities: PropTypes.object.isRequired,
      pending: PropTypes.bool.isRequired,
      policyEnables: PropTypes.object.isRequired,
      policyParameters: PropTypes.object.isRequired,
      policySetName: PropTypes.string.isRequired,
      policySetNameErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
      policyTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
      postProcesses: PropTypes.arrayOf(PropTypes.object).isRequired,
      postProcessesUrl: PropTypes.string.isRequired,
      region: PropTypes.string.isRequired,
      regionErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
      regions: PropTypes.arrayOf(PropTypes.array).isRequired,
      regionSet: PropTypes.arrayOf(PropTypes.array).isRequired,
      services: PropTypes.arrayOf(PropTypes.array).isRequired,
      succeededPostProcessIds: PropTypes.object.isRequired, // TODO: Deprecate
      sqsQueuesPath: PropTypes.string.isRequired,
      timeZones: PropTypes.arrayOf(PropTypes.array).isRequired,
      triggerTypes: PropTypes.object.isRequired,
      url: PropTypes.string.isRequired,
    });
  }

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const enabledPolicyCount = this.enabledPolicyCount();
    const submittable = enabledPolicyCount > 0 && !this.hasValidationError();

    return(
      <React.StrictMode>
        <form onSubmit={this.handleSubmit}>
          <Policies
            emitter={this.props.emitter}
            completedAssignments={this.props.completedAssignments}
            defaultLanguage={this.props.defaultLanguage}
            defaultTimeZone={this.props.defaultTimeZone}
            failedAssignments={this.props.failedAssignments}
            failedPostProcessIds={this.props.failedPostProcessIds}
            group={this.props.group}
            groups={this.props.groups}
            isSlackIntegrated={this.props.isSlackIntegrated}
            isUserCanManageIntegrations={this.props.isUserCanManageIntegrations}
            loadingImagePath={this.props.loadingImagePath}
            policyEnables={this.props.policyEnables}
            policyParameters={this.props.policyParameters}
            policyTemplates={this.props.policyTemplates}
            postProcesses={this.props.postProcesses}
            succeededPostProcessIds={this.props.succeededPostProcessIds}
            triggerTypes={this.props.triggerTypes}
            postProcessesUrl={this.props.postProcessesUrl}
            regionSet={this.props.regionSet}
            services={this.props.services}
            sqsQueuesPath={this.props.sqsQueuesPath}
            timeZones={this.props.timeZones}
            url={this.props.url}
          />
          <SectionDivider />
          <AwsAccountIdField
            awsAccounts={this.props.awsAccounts}
            emitter={this.props.emitter}
            errors={this.props.awsAccountIdErrors}
            initialValue={this.props.awsAccountId}
            newRecord={this.props.newRecord}
          />
          <RegionField
            emitter={this.props.emitter}
            errors={this.props.regionErrors}
            regions={this.props.regions}
            initialValue={this.props.region}
            newRecord={this.props.newRecord}
          />
          <PolicySetNameField
            emitter={this.props.emitter}
            errors={this.props.policySetNameErrors}
            initialValue={this.props.policySetName}
          />
          <PricingSection
            overseas={this.props.overseas}
            policyCount={enabledPolicyCount}
            unitPriceJPY={Constants.POLICY_UNIT_PRICE_JPY}
            unitPriceUSD={Constants.POLICY_UNIT_PRICE_USD}
          />
          <SectionDivider />
          <div className="policy-set-form__base-errors">
            <ErrorMessages
              isHtml={true}
              messages={this.props.baseErrors}
            />
          </div>
          <div className="text-center">
            <SubmitButton
              disabled={!submittable}
              emitter={this.props.emitter}
              emitterEvent={Constants.EVENT_SUBMIT_FORM}
              label={
                I18n.t(
                  this.props.newRecord ? 'submit_button.label_for_create' : 'submit_button.label_for_update',
                  { scope: Constants.I18N_SCOPE }
                )
              }
              pending={this.props.pending}
              pendingLabel={I18n.t('submit_button.label_for_pending', { scope: Constants.I18N_SCOPE })}
            />
          </div>
        </form>
      </React.StrictMode>
    );
  }

  /**
   * 有効になっているポリシーの数を返します。
   *
   * @return {number}
   */
  enabledPolicyCount() {
    return _.select(
      Object.keys(this.props.policyEnables),
      (key) => this.props.policyEnables[key] === true
    ).length;
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.emitter.emit(Constants.EVENT_SUBMIT_FORM);
  }

  /**
   * フォームの入力内容にクライアントサイドのバリデーションエラーがあるかどうかを返します。
   *
   * @return {boolean}
   */
  hasValidationError() {
    // parameterValiditiesプロパティに含まれるfalseは「エラーあり」を表す
    return _.contains(_.values(this.props.parameterValidities), false);
  }
}

/**
 * フォームのセクションを区切る破線。
 */
class SectionDivider extends React.Component {
  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <hr
        style={{
          border: 'none',
          borderTop: '1px dashed #ddd',
          margin: '30px -30px',
        }}
      />
    );
  }
}

export default PolicySetForm;
