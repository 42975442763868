import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import Modal from './Modal.jsx';

const I18n = window.I18n; // i18n-js

/**
 * APIキー作成モーダルダイアログ。
 */
export default class CreateModal extends React.Component {
  /**
   * プロパティの定義を返します。
   *
   * @return {Object}
   * @property {Object} emitter EventEmitterのインスタンス
   */
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
    });
  }

  /**
   * コンストラクタ。
   *
   * @override
   */
  constructor(props) {
    super(props);

    this.cancelText = I18n.t('create_modal.cancel', { scope: Constants.I18N_SCOPE });
    this.proceedText = I18n.t('create_modal.proceed', { scope: Constants.I18N_SCOPE });
    this.contentText = I18n.t('create_modal.content', { scope: Constants.I18N_SCOPE });
  }

  /**
   * @override
   * @return {ReactElement}
   */
  render() {
    return(
      <Modal
        content={
          <div className="modal-content api-key-modal__content">
            {this.contentText}
          </div>
        }
        id="create-api-key-modal"
        isOpen={true}
        needsPositiveButton={true}
        negativeButtonClass="btn btn-cancel btn-sm"
        negativeButtonValue={this.cancelText}
        onClickPositiveButton={() => this.props.emitter.emit(Constants.EVENT_CREATE_API_KEY)}
        onClose={() => this.props.emitter.emit(Constants.EVENT_CLOSE_MODAL)}
        positiveButtonClass="btn btn-primary btn-sm btn-right"
        positiveButtonValue={this.proceedText}
      />
    );
  }
}
