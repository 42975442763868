import React from "react";
import PropTypes from "prop-types";

import SortableTableHeaderCell from "../../SortableTableHeaderCell.jsx";
import withBootstrapTooltip from "../../withBootstrapTooltip.js";

import GroupUserRow from "./GroupUserRow.jsx";

const I18n = window.I18n;

/**
 * グループメンバー一覧を表示するテーブルを描画します
 *
 * このコンポーネントの描画結果はTABLE要素となります
 */
class GroupUserTable extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {object}
   * @property {boolean} dateLabelFeatureEnabled 組織に対してカレンダー機能の機能フラグが有効かどうか
   * @property {any} errorMessage エラーメッセージまたはnull TODO: 型を文字列にする
   * @property {boolean} inventoryAvailable 組織がインベントリ機能を利用可能かどうか
   * @property {boolean} policySetsAvailable 組織が構成レビュー機能を利用可能かどうか
   * @property {object} reloadEmitter グループ一覧をリロードするイベントを発火するオブジェクト。
   * @property {string} sortColumn ソート対象となるカラム
   * @property {string} sortDirection ソートの方向
   * @property {?function(element: DOMNode)} tooltipRef
   * @property {object[]} users 表示するグループユーザーの配列
   * @property {function} callback ソートリンククリック時のcallback
   */
  static get propTypes() {
    return {
      dateLabelFeatureEnabled: PropTypes.bool.isRequired,
      errorMessage: PropTypes.any,
      groupId: PropTypes.number.isRequired,
      inventoryAvailable: PropTypes.bool.isRequired,
      policySetsAvailable: PropTypes.bool.isRequired,
      reloadEmitter: PropTypes.object.isRequired,
      sortColumn: PropTypes.string.isRequired,
      sortDirection: PropTypes.string.isRequired,
      tooltipRef: PropTypes.func,
      users: PropTypes.arrayOf(PropTypes.object).isRequired,
      callback: PropTypes.func.isRequired
    };
  }

  /**
   * コンポーネントを初期化します
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.nameText = I18n.t("javascript.group_user.name");
    this.userTypeText = I18n.t("javascript.group_user.user_type");
    this.dateLabelPermissionText = I18n.t("javascript.group_user.calendar");
    this.departmentText = I18n.t("department", {
      scope: "activerecord.attributes.user"
    });
    this.jobPermissionText = I18n.t("javascript.group_user.trigger_job");
    this.policySetPermissionText = I18n.t("javascript.group_user.policy_set");
    this.inventoryPermissionText = I18n.t("javascript.group_user.inventory.workspaces");
    this.registeredAtText = I18n.t("javascript.group_user.registered_at");
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    const sortByName = this.props.sortColumn == "users.name";
    const sortByUserType = this.props.sortColumn == "users.user_type";
    const sortByCalendarPermission =
      this.props.sortColumn == "user_assignments.calendar_permission";
    const sortByDepartment = this.props.sortColumn == "users.department";
    const sortByJobPermission =
      this.props.sortColumn == "user_assignments.job_permission";
    const sortByPolicySetPermission =
      this.props.sortColumn == "user_assignments.policy_set_permission";
    const sortByInventoryPermission =
      this.props.sortColumn == "user_assignments.inventory_permission";
    const sortByRegisteredAt =
      this.props.sortColumn == "registered_at";

    return (
      <table
        className="table vertical-middle ca-group-users ca-table-header-no-bordered"
        ref={this.props.tooltipRef}
      >
        <thead>
          <tr>
            <SortableTableHeaderCell
              column="users.name"
              active={sortByName}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.nameText}
              classNameForHeaderCell="
                ca-group-users-header--clickable
                ca-group-users-header__name
              "
            />
            <SortableTableHeaderCell
              column="users.user_type"
              active={sortByUserType}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.userTypeText}
              classNameForHeaderCell="
                ca-group-users-header--clickable
                ca-group-users-header__user-type
                text-center
              "
            />
            <SortableTableHeaderCell
              column="users.department"
              active={sortByDepartment}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.departmentText}
              classNameForHeaderCell="
                ca-group-users-header--clickable
                ca-group-users-header__department
              "
            />
            <SortableTableHeaderCell
              column="user_assignments.job_permission"
              active={sortByJobPermission}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.jobPermissionText}
              icon={"fa-tachometer"}
              classNameForHeaderCell="
                ca-group-users-header--clickable
                ca-group-users-header__permission
              "
            />
            {this.props.policySetsAvailable && (
              <SortableTableHeaderCell
                column="user_assignments.policy_set_permission"
                active={sortByPolicySetPermission}
                callback={this.props.callback}
                direction={this.props.sortDirection}
                text={this.policySetPermissionText}
                icon={"fa-shield"}
                classNameForHeaderCell="
                ca-group-users-header--clickable
                ca-group-users-header__permission
              "
              />
            )}
            <SortableTableHeaderCell
              column="user_assignments.inventory_permission"
              active={sortByInventoryPermission}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.inventoryPermissionText}
              icon={"fa-server"}
              classNameForHeaderCell="
                ca-group-users-header--clickable
                ca-group-users-header__permission
              "
            />
            {this.props.dateLabelFeatureEnabled &&
              <SortableTableHeaderCell
                column="user_assignments.calendar_permission"
                active={sortByCalendarPermission}
                callback={this.props.callback}
                direction={this.props.sortDirection}
                text={this.dateLabelPermissionText}
                icon={"fa-calendar"}
                classNameForHeaderCell="
                  ca-group-users-header--clickable
                  ca-group-users-header__permission
                "
              />
            }
            <SortableTableHeaderCell
              column="registered_at"
              active={sortByRegisteredAt}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.registeredAtText}
              colspan={3}
              classNameForHeaderCell="
                ca-group-users-header--clickable
                ca-group-users-header__registered-at
              "
            />
          </tr>
        </thead>
        <tbody>{this.getGroupUserRows()}</tbody>
      </table>
    );
  }

  /*
   * テーブルに表示するグループのグループユーザー行コンポーネントを配列で返します
   * ユーザーがいない時は列を連結してエラーメッセージを表示します
   *
   * @return {array}
   */
  getGroupUserRows() {
    const isGroupUsersExist =
      this.props.errorMessage == null && this.props.users.length > 0;
    if (isGroupUsersExist) {
      return this.props.users.map(user => {
        return (
          <GroupUserRow
            dateLabelFeatureEnabled={this.props.dateLabelFeatureEnabled}
            key={user.id}
            groupId={this.props.groupId}
            inventoryAvailable={this.props.inventoryAvailable}
            policySetsAvailable={this.props.policySetsAvailable}
            reloadEmitter={this.props.reloadEmitter}
            user={user}
          />
        );
      });
    }

    const message =
      this.props.errorMessage != null
        ? this.props.errorMessage
        : I18n.t("javascript.group_user.no_users");
    return (
      <tr key="0">
        <td colSpan="7">{message}</td>
      </tr>
    );
  }
}

// UserTableコンポーネント内でTooltipを使えるようにしてから外部にエクスポートする
export default withBootstrapTooltip(GroupUserTable);
