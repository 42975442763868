/**
 * Reactコンポーネント用定数
 */
export default {
  GROUP_INPUT_TAG_ID: 'trigger_job_group_id',
  GROUP_INPUT_TAG_NAME: 'trigger_job[group_id]',

  URL: {
    AWS_ACCOUNTS: '/aws_accounts',
    POST_PROCESSES: '/post_processes',
  },

  // I18nメッセージリソースのスコープ
  I18N_SCOPE: 'javascript.trigger_job_form',
};
