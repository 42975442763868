import React from 'react';
import PropTypes from 'prop-types';

const _ = window._; // Underscore.js
const I18n = window.I18n; // i18n-js

/**
 * AWSアカウントを切り替えるセレクトボックスコンポーネント
 *
 * プロパティ
 * blankText - 未選択の状態の選択肢として表示する文字列。
 * callback  - AWSアカウントが変更された際に呼び出す関数。変更後の値が引数として渡される。
 * current   - AWSアカウントIDの現在値。未選択の場合は空文字列。
 * disabled  - disabled状態を表すboolean情報。
 * htmlId    - SELECT要素に設定するid属性値。LABELタグと連携させる際に利用。
 * options   - 選択可能なAWSアカウントの配列。
 *             [{ name: '...', id: 1 }, ...]
 * selectClassName - SELECT要素のclass属性値として設定する文字列。省略した場合はデフォルトの値が使われる。
 * wrapperClassName - コンテナ要素のclass属性値として設定する文字列。省略した場合はデフォルトの値が使われる。
 *
 * Examples
 *
 *   <AwsAccountSelector
 *     blankText="選択してください"
 *     callback={this.handleAwsAccountIdChange}
 *     current={awsAccountId}
 *     options={awsAccounts}
 *   />
 *
 * このコンポーネントの描画結果はSPAN要素となります。
 */
class AwsAccountSelector extends React.Component {
  /**
   * propTypes
   * @property {}
   */
  static get propTypes() {
    return({
      blankText: PropTypes.string,
      callback: PropTypes.func.isRequired,
      current: PropTypes.any.isRequired,
      disabled: PropTypes.bool,
      htmlId: PropTypes.string,
      options: PropTypes.array.isRequired,
      selectClassName: PropTypes.string,
      wrapperClassName: PropTypes.string,
    });
  }

  static get defaultProps() {
    return({
      blankText: null,
      disabled: false,
      htmlId: null,
      selectClassName: "form-control inline-block vertical-middle max-width-quarter",
      wrapperClassName: "aws-account-selector",
    });
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.labelText = I18n.t('aws_account', { scope: 'javascript.resource_table' });
    this.blankText = props.blankText || this.labelText;
  }

  /**
   * セレクトボックスの値が変更された際に呼び出されるイベントハンドラ。
   * @param {SyntheticEvent} event
   */
  handleChange(event) {
    event.preventDefault();
    this.props.callback(event.target.value);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    if (this.props.options.length == 0) {
      return this.renderBlankSelect();
    }
    return this.renderSelect();
  }

  /**
   * セレクトボックスの最初の選択肢として表示するブランクのOPTION要素を返します。
   *
   * @return {ReactElement}
   */
  renderBlankOption() {
    return(<option value="">{this.blankText}</option>);
  }

  /**
   * 選択可能なAWSアカウントが無い場合のセレクトボックスを返します。
   *
   * @return {ReactElement}
   */
  renderBlankSelect() {
    return(
      <span>
        <select
          aria-label={this.labelText}
          className={this.props.selectClassName}
          disabled={this.props.disabled}
          id={this.props.htmlId}
        >
          {this.renderBlankOption()}
        </select>
      </span>
    );
  }

  /**
   * AWSアカウントのセレクトボックスを返します。
   *
   * @return {ReactElement}
   */
  renderSelect() {
    return(
      <span className={this.props.wrapperClassName}>
        <select
          aria-label={this.labelText}
          className={this.props.selectClassName}
          id={this.props.htmlId}
          onChange={this.handleChange}
          value={this.props.current}
          disabled={this.props.disabled}
        >
          {this.renderBlankOption()}
          {this.renderOptions()}
        </select>
      </span>
    );
  }

  /**
   * AWSアカウントを選択するためのOPTION要素の配列を返します。
   *
   * @return {array} ReactElementの配列
   */
  renderOptions() {
    return _.map(this.props.options, (awsAccount) => {
      return(
        <option
          key={awsAccount.id}
          value={awsAccount.id}
        >
          {awsAccount.name}
        </option>
      );
    });
  }
}

export default AwsAccountSelector;
