import EventEmitter from "events";
import React from "react";
import PropTypes from "prop-types";
import Select2 from "react-select2-wrapper";

import Constants from "./Constants.js";
import { SelectFilterNote } from "../SelectFilterNote";
import { WorkflowTriggerJobDetail } from "./WorkflowTriggerJobDetail";
import { ProviderIcon } from "../ProviderIcon";
import StepCircle from "./StepCircle.jsx";
import StepsHint from "./StepsHint.jsx";
import { TriggerCircle } from "./TriggerCircle";

const I18n = window.I18n; // i18n-js

/**
 * ジョブワークフロー作成フォームコンテナ
 */
export default class JobWorkflowNewFormContainer extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {object[]} groups グループの配列
   * @property {string} jobWorkflowPath ジョブワークフロー作成リクエストの送信先パス
   * @property {string} triggerJobsPath ジョブ一覧取得リクエストの送信先パス
   */
  static get propTypes() {
    return {
      groups: PropTypes.arrayOf(PropTypes.object).isRequired,
      jobWorkflowPath: PropTypes.string.isRequired,
      triggerJobsPath: PropTypes.string.isRequired,
    };
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.state = {
      workflowTriggerJobs: {}, // ワークフロートリガージョブ候補となるジョブ(ジョブIDがキー)
      followingJobs: {}, // 後続ジョブ候補となるジョブ情報の配列(ジョブIDがキー)
      groupId: "", // グループIDの現在値
      isExpiredWorkflowTriggerJob: false, // ワークフロートリガージョブがExpireしているスケジュールトリガーかどうか
      isLoadingJobs: false, // グループに属するジョブ情報の取得中かどうか
      isSubmitting: false, // フォームを送信中かどうか
      isSubmitted: false, // フォーム送信を完了したかどうか
      name: "", // ジョブワークフロー名
      submitErrorMessage: "", // フォーム送信時のエラーメッセージ
      workflowTriggerJobId: "", // ワークフロートリガージョブで選択されたジョブID
      followingJobId1: "", // ステップ1で選択された後続ジョブID
      followingJobId2: "", // ステップ2で選択された後続ジョブID
      followingJobId3: "", // ステップ3で選択された後続ジョブID
      followingJobId4: "", // ステップ4で選択された後続ジョブID
      followingJobId5: "", // ステップ5で選択された後続ジョブID
      followingJobId6: "", // ステップ6で選択された後続ジョブID
      followingJobId7: "", // ステップ7で選択された後続ジョブID
      followingJobId8: "", // ステップ8で選択された後続ジョブID
      followingJobId9: "", // ステップ9で選択された後続ジョブID
      followingJobId10: "", // ステップ10で選択された後続ジョブID
      followingJobId11: "", // ステップ11で選択された後続ジョブID
      followingJobId12: "", // ステップ12で選択された後続ジョブID
      followingJobId13: "", // ステップ13で選択された後続ジョブID
      followingJobId14: "", // ステップ14で選択された後続ジョブID
      followingJobId15: "", // ステップ15で選択された後続ジョブID
      followingJobId16: "", // ステップ16で選択された後続ジョブID
      followingJobId17: "", // ステップ17で選択された後続ジョブID
      followingJobId18: "", // ステップ18で選択された後続ジョブID
      followingJobId19: "", // ステップ19で選択された後続ジョブID
      followingJobId20: "", // ステップ20で選択された後続ジョブID
    };

    this.handleAlertMessage = this.handleAlertMessage.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeGroup = this.handleChangeGroup.bind(this);
    this.handleChangeTriggerJob = this.handleChangeTriggerJob.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);

    this.initEventEmitter();
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    let formClasses = "ca-job-workflow__form ca-job-workflow__form--new";

    if (this.state.isExpiredWorkflowTriggerJob) {
      formClasses += " ca-job-workflow__form--expired-schedule";
    }

    return (
      <React.Fragment>
        <form className={formClasses} onSubmit={this.handleSubmitForm}>
          <section className="ca-job-workflow__form-fieldset-wrapper">
            <fieldset className="ca-job-workflow__form-fieldset">
              <h2 className="ca-job-workflow__form-fieldset-heading">
                <i aria-hidden className="fa fa-pencil-square-o marginR5"></i>
                {I18n.t("basic_settings", { scope: Constants.I18N_SCOPE })}
              </h2>

              <div className="ca-job-workflow__form-fields">
                <div
                  className="
                  form-group
                  ca-job-workflow__form-field
                "
                >
                  <label
                    htmlFor="ca-job-workflow-name-field"
                    className="
                      ca-job-workflow__form-label
                      ca-job-workflow__form-label--required
                    "
                  >
                    {I18n.t("name", { scope: Constants.I18N_SCOPE })}
                  </label>
                  <div className="ca-job-workflow__form-control">
                    <input
                      className="form-control"
                      id="ca-job-workflow-name-field"
                      onChange={this.handleChangeName}
                      type="text"
                      value={this.state.name}
                    />
                  </div>
                </div>

                <div
                  className="
                  form-group
                  ca-job-workflow__form-field
                "
                >
                  <label
                    htmlFor="ca-job-workflow-group-id-field"
                    className="
                      ca-job-workflow__form-label
                      ca-job-workflow__form-label--required
                    "
                  >
                    {I18n.t("group", { scope: Constants.I18N_SCOPE })}
                  </label>
                  <div className="ca-job-workflow__form-control">
                    {SelectFilterNote()}
                    <Select2
                      className="form-control"
                      style={{ width: "100%" }}
                      id="ca-job-workflow-group-id-field"
                      onSelect={this.handleChangeGroup}
                      value={this.state.groupId}
                      data={this.props.groups.map((group) => {
                        return { id: group.id, text: group.name };
                      })}
                      options={{
                        dropdownAdapter: window.jQuery.fn.select2.amd.require("ForceDropDownAdapter"),
                        forceDropdownPosition: "below",
                        language: window.jQuery.fn.select2.amd.require(`select2/i18n/${window.I18n.locale}`),
                        placeholder: I18n.t("blank_option", { scope: Constants.I18N_SCOPE }),
                        theme: "kanrinmaru-v2",
                      }}
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          </section>

          <section className="ca-job-workflow__form-fieldset-wrapper">
            <fieldset className="ca-job-workflow__form-fieldset">
              <h2 className="ca-job-workflow__form-fieldset-heading">
                <i aria-hidden className="fa fa-dashboard marginR5"></i>
                {I18n.t("job_settings", { scope: Constants.I18N_SCOPE })}
              </h2>
              <div className="ca-job-workflow__form-notice">
                <StepsHint />
              </div>
              {this.renderJobWorkflowTriggerJob()}
              <div className="ca-job-workflow__form-fields">
                {this.renderWorkflowTriggerStep()}
                {this.renderFollowingStep(1)}
                {this.renderFollowingStep(2)}
                {this.renderFollowingStep(3)}
                {this.renderFollowingStep(4)}
                {this.renderFollowingStep(5)}
                {this.renderFollowingStep(6)}
                {this.renderFollowingStep(7)}
                {this.renderFollowingStep(8)}
                {this.renderFollowingStep(9)}
                {this.renderFollowingStep(10)}
                {this.renderFollowingStep(11)}
                {this.renderFollowingStep(12)}
                {this.renderFollowingStep(13)}
                {this.renderFollowingStep(14)}
                {this.renderFollowingStep(15)}
                {this.renderFollowingStep(16)}
                {this.renderFollowingStep(17)}
                {this.renderFollowingStep(18)}
                {this.renderFollowingStep(19)}
                {this.renderFollowingStep(20)}
              </div>
            </fieldset>
          </section>

          <section className="ca-job-workflow__form-fieldset-wrapper">
            <fieldset className="ca-job-workflow__form-fieldset text-center">
              {this.state.submitErrorMessage && (
                <p className="ca-job-workflow__form-error-message">{this.state.submitErrorMessage}</p>
              )}

              <button className="btn btn-primary btn-lg" disabled={!this.isFormFilled()} type="submit">
                <i className="fa fa-check-circle fa-lg"></i>{" "}
                {I18n.t(this.state.isSubmitting ? "submitting" : "submit", { scope: Constants.I18N_SCOPE })}
              </button>
            </fieldset>
          </section>
        </form>
      </React.Fragment>
    );
  }

  renderJobWorkflowTriggerJob() {
    const job =
      this.state.workflowTriggerJobId !== ""
        ? this.state.workflowTriggerJobs[this.state.workflowTriggerJobId]
        : undefined;
    return <WorkflowTriggerJobDetail job={job} />;
  }

  renderWorkflowTriggerStep() {
    return (
      <div className="ca-job-workflow__form-trigger">
        <label className="ca-job-workflow__form-trigger__label">
          {I18n.t("specify_workflow_trigger_job_or_first_job", { scope: Constants.I18N_SCOPE })}
        </label>
        <div className="ca-job-workflow__form-field" data-qa="step-trigger">
          <div className="ca-job-workflow__form-step-row">
            <React.Fragment>
              <TriggerCircle job={this.state.workflowTriggerJobs[this.state.workflowTriggerJobId]} />
            </React.Fragment>
            <select
              className="form-control ca-job-workflow__form-control ca-job-workflow__form-step-select"
              onChange={(e) => this.handleChangeTriggerJob(0, e.target.value)}
              value={this.state.workflowTriggerJobId}
            >
              <option value="">{I18n.t("blank_option", { scope: Constants.I18N_SCOPE })}</option>
              {Object.keys(this.state.workflowTriggerJobs).map((id) => {
                const job = this.state.workflowTriggerJobs[id];
                return (
                  <option key={job.id} value={job.id}>
                    {job.name}
                  </option>
                );
              })}
            </select>

            <div>
              <a
                href="#"
                onClick={(e) => {
                  this.handleChangeTriggerJob(0, "");
                  e.preventDefault();
                }}
              >
                <i className="fa fa-trash-o ca-job-workflow__form-step-trash-icon"></i>
              </a>
            </div>

            {this.state.workflowTriggerJobId && (
              <React.Fragment>
                <div className="ca-job-workflow__form-step-action-type">
                  {this.state.workflowTriggerJobs[this.state.workflowTriggerJobId].action_type !== "no_action" && (
                    <div className="ca-job-workflow__old-first-job">
                      <span className="alert-danger__icon">
                        <i className="fa fa-exclamation-triangle marginR5" aria-hidden="true"></i>
                      </span>
                      {I18n.t("old_first_job", { scope: Constants.I18N_SCOPE })}
                    </div>
                  )}
                  <ProviderIcon
                    identifier={this.state.workflowTriggerJobs[this.state.workflowTriggerJobId].provider_identifier}
                  />
                  {this.state.workflowTriggerJobs[this.state.workflowTriggerJobId].action_type_i18n}
                </div>

                <div className="ca-job-workflow__form-step-link">
                  <a
                    className="marginL10"
                    href={this.state.workflowTriggerJobs[this.state.workflowTriggerJobId].show_url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i aria-hidden className="fa fa-external-link"></i>{" "}
                    {I18n.t("link_to_job", { scope: Constants.I18N_SCOPE })}
                  </a>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderFollowingStep(step) {
    const currentValue = this.state[`followingJobId${step}`];
    let rowClasses = "ca-job-workflow__form-step-row";
    if (step == 20) {
      rowClasses = `${rowClasses} ca-job-workflow__form-step-row--last`;
    }

    return (
      <div className="ca-job-workflow__form-field" data-qa={`step-${step}`}>
        <div className={rowClasses}>
          <StepCircle active={currentValue != ""} step={step} />
          <select
            className="form-control ca-job-workflow__form-control ca-job-workflow__form-step-select"
            value={currentValue}
            onChange={(e) => this.handleChangeTriggerJob(step, e.target.value)}
          >
            <option value="">{I18n.t("blank_option", { scope: Constants.I18N_SCOPE })}</option>
            {currentValue && (
              <option key={currentValue} value={currentValue}>
                {this.state.followingJobs[currentValue].name}
              </option>
            )}
            {this.availableFollowingJobIds().map((id) => {
              const job = this.state.followingJobs[id];
              return (
                <option key={job.id} value={job.id}>
                  {job.name}
                </option>
              );
            })}
          </select>

          <div>
            <a
              href="#"
              onClick={(e) => {
                this.handleChangeTriggerJob(step, "");
                e.preventDefault();
              }}
            >
              <i className="fa fa-trash-o ca-job-workflow__form-step-trash-icon"></i>
            </a>
          </div>

          {currentValue && (
            <React.Fragment>
              <div className="ca-job-workflow__form-step-action-type">
                <ProviderIcon identifier={this.state.followingJobs[currentValue].provider_identifier} />
                {this.state.followingJobs[currentValue].action_type_i18n}
              </div>
              <div className="ca-job-workflow__form-step-link">
                <a
                  className="marginL10"
                  href={this.state.followingJobs[currentValue].show_url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i aria-hidden className="fa fa-external-link"></i>{" "}
                  {I18n.t("link_to_job", { scope: Constants.I18N_SCOPE })}
                </a>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  availableFollowingJobIds() {
    const ids = Object.keys(this.state.followingJobs);
    return ids.filter((id) => {
      return (
        id != this.state.followingJobId1 &&
        id != this.state.followingJobId2 &&
        id != this.state.followingJobId3 &&
        id != this.state.followingJobId4 &&
        id != this.state.followingJobId5 &&
        id != this.state.followingJobId6 &&
        id != this.state.followingJobId7 &&
        id != this.state.followingJobId8 &&
        id != this.state.followingJobId9 &&
        id != this.state.followingJobId10 &&
        id != this.state.followingJobId11 &&
        id != this.state.followingJobId12 &&
        id != this.state.followingJobId13 &&
        id != this.state.followingJobId14 &&
        id != this.state.followingJobId15 &&
        id != this.state.followingJobId16 &&
        id != this.state.followingJobId17 &&
        id != this.state.followingJobId18 &&
        id != this.state.followingJobId19 &&
        id != this.state.followingJobId20
      );
    });
  }

  /**
   * 現在選択中のグループに属するワークフロー専用ジョブの情報を取得します。
   *
   * @private
   */
  fetchTriggerJobs() {
    window.jQuery
      .ajax({
        data: {
          group_id: this.state.groupId,
        },
        dataType: "json",
        url: this.props.triggerJobsPath,
        beforeSend: () => {
          this.setState({ isLoadingJobs: true });
        },
      })
      .done((data) => {
        this.setState({
          workflowTriggerJobs: data.first_jobs,
          followingJobs: data.following_jobs,
          isLoadingJobs: false,
        });
      })
      .fail(() => {
        this.setState({
          errorMessage: I18n.t("fetch_failed", { scope: Constants.I18N_SCOPE }),
          workflowTriggerJobs: {},
          followingJobs: {},
          isLoadingJobs: false,
        });
      });
  }

  /**
   * ジョブワークフローを作成出来ない時に出すアラートを表示する
   *
   * @public
   */
  handleAlertMessage(message) {
    // TODO: DOM操作を行わずに済むようリファクタリングする
    const alert = `<div id="js-alert-message" class="alert alert-danger alert-dismissable">
              <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
              <span class="alert-danger__icon"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
              ${message}
              </div>`;
    $("#js-flash-container").prepend(alert);
  }

  /**
   * 現在選択中のグループを変更します。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleChangeGroup(event) {
    const groupId = event.target.value;
    const newValue = {
      groupId: groupId,
      // グループに由来する以下のステートを初期化する
      workflowTriggerJobs: {},
      followingJobs: {},
      followingJobId1: "",
      followingJobId2: "",
      followingJobId3: "",
      followingJobId4: "",
      followingJobId5: "",
      followingJobId6: "",
      followingJobId7: "",
      followingJobId8: "",
      followingJobId9: "",
      followingJobId10: "",
      followingJobId11: "",
      followingJobId12: "",
      followingJobId13: "",
      followingJobId14: "",
      followingJobId15: "",
      followingJobId16: "",
      followingJobId17: "",
      followingJobId18: "",
      followingJobId19: "",
      followingJobId20: "",
    };

    if (groupId != "") {
      this.setState(newValue, this.fetchTriggerJobs);
    } else {
      // グループが未選択になった場合はジョブ情報の非同期取得は行わない
      this.setState(newValue);
    }
  }

  /**
   * ジョブワークフロー名の現在値を変更します。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }

  /**
   * 指定されたステップのジョブを変更します。
   *
   * 指定されたのがワークフロートリガージョブかつ、予定日時に未来の日時が無い
   * スケジュールトリガーだった場合は編集フォームに警告を表示します。
   *
   * @private
   * @param {string} step 0〜20 (0: ワークフロートリガージョブ, 1〜20: 後続ジョブ)
   * @param {string} triggerJobId 変更後のジョブID
   */
  handleChangeTriggerJob(step, triggerJobId) {
    let key = "";

    if (step == 0) {
      key = "workflowTriggerJobId";
    } else {
      key = `followingJobId${step}`;
    }

    this.setState({ [key]: triggerJobId });

    if (step != 0) {
      // ワークフロートリガージョブ以外の場合
      return;
    }

    const job = this.state.workflowTriggerJobs[triggerJobId];
    let isExpiredMessage = false;

    if (job && job.rule_type == "schedule") {
      isExpiredMessage = job.expired_schedule_job;
    }

    this.setState({
      isExpiredWorkflowTriggerJob: isExpiredMessage,
    });
  }

  /**
   * フォームを送信します。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleSubmitForm(event) {
    event.preventDefault();
    window.jQuery
      .ajax({
        method: "POST",
        data: {
          name: this.state.name,
          group_id: this.state.groupId,
          first_job_id: this.state.workflowTriggerJobId,
          following_job_ids: [
            this.state.followingJobId1,
            this.state.followingJobId2,
            this.state.followingJobId3,
            this.state.followingJobId4,
            this.state.followingJobId5,
            this.state.followingJobId6,
            this.state.followingJobId7,
            this.state.followingJobId8,
            this.state.followingJobId9,
            this.state.followingJobId10,
            this.state.followingJobId11,
            this.state.followingJobId12,
            this.state.followingJobId13,
            this.state.followingJobId14,
            this.state.followingJobId15,
            this.state.followingJobId16,
            this.state.followingJobId17,
            this.state.followingJobId18,
            this.state.followingJobId19,
            this.state.followingJobId20,
          ],
        },
        dataType: "json",
        url: this.props.jobWorkflowPath,
        beforeSend: () => {
          this.setState({
            isSubmitting: true,
            isSubmitted: false,
          });
        },
      })
      .done((data) => {
        this.setState(
          {
            // isSubmitting: false,
            isSubmitted: true,
          },
          () => {
            window.location.href = data.url;
          }
        );
      })
      .fail((data) => {
        const error = I18n.t("submit_failed", { scope: Constants.I18N_SCOPE });
        const json = data.responseJSON;
        const message = `${error} ${json.error}`;
        this.handleAlertMessage(message);
        this.setState({ isSubmitting: false, submitErrorMessage: message });
      });
  }

  isFormFilled() {
    return (
      this.state.name != "" &&
      this.state.groupId != "" &&
      this.state.workflowTriggerJobId != "" &&
      this.state.followingJobId1 != ""
    );
  }

  /**
   * EventEmitterを初期化します。
   *
   * 各コンポーネント内で発生するイベントとイベントハンドラ関数の割り当てを行います。
   * 生成されたEventEmitterオブジェクトは this.emitter にアサインされます。
   *
   * @private
   */
  initEventEmitter() {
    this.emitter = new EventEmitter();
    this.emitter.on(Constants.EVENT_ALERT_MESSAGE, this.handleAlertMessage);
    this.emitter.on(Constants.EVENT_CHANGE_GROUP, this.handleChangeGroup);
    this.emitter.on(Constants.EVENT_CHANGE_NAME, this.handleChangeName);
    this.emitter.on(Constants.EVENT_CHANGE_TRIGGER_JOB, this.handleChangeTriggerJob);
    this.emitter.on(Constants.EVENT_SUBMIT_FORM, this.handleSubmitForm);
  }
}
