import React from "react";
import PropTypes from "prop-types";

import SortableTableHeaderCell from "../../SortableTableHeaderCell.jsx";
import withBootstrapTooltip from "../../withBootstrapTooltip.js";

import EbsBackupVolumeRow from "./EbsBackupVolumeRow.jsx";

const I18n = window.I18n;

/**
 * バックアップ結果ボリューム一覧を表示するテーブルを描画します
 *
 * このコンポーネントの描画結果はTABLE要素となります
 */
class EbsBackupVolumeTable extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {object}
   * @property {function} callback ソートリンククリック時のcallback
   * @property {object[]} ebsBackupCheckResults 表示するバックアップ推奨ボリュームの配列
   * @property {boolean} isAwsAccountUnitError AWSアカウント単位のエラーが発生している場合
   * @property {?string} emptyResultMessage ボリュームがない場合に表示するメッセージ
   * @property {?string} errorMessage エラーメッセージ
   * @property {string} sortColumn ソート対象となるカラム
   * @property {string} sortDirection ソートの方向
   * @property {?function(element: DOMNode)} tooltipRef
   */
  static get propTypes() {
    return {
      callback: PropTypes.func.isRequired,
      ebsBackupCheckResults: PropTypes.arrayOf(PropTypes.object).isRequired,
      emptyResultMessage: PropTypes.string,
      errorMessage: PropTypes.any,
      isAwsAccountUnitError: PropTypes.bool.isRequired,
      sortColumn: PropTypes.string.isRequired,
      sortDirection: PropTypes.string.isRequired,
      tooltipRef: PropTypes.func,
    };
  }

  /**
   * コンポーネントを初期化します
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.regionText = I18n.t("javascript.ebs_backup_not_exists_results.region");
    this.snapshotNameText = I18n.t("javascript.ebs_backup_not_exists_results.snapshot_name");
    this.snapshotIdText = I18n.t("javascript.ebs_backup_not_exists_results.snapshot_id");
    this.snapshotStartTimeText = I18n.t("javascript.ebs_backup_not_exists_results.snapshot_start_time");
    this.volumeNameText = I18n.t("javascript.ebs_backup_not_exists_results.volume_name");
    this.volumeIdText = I18n.t("javascript.ebs_backup_not_exists_results.volume_id");
    this.volumeStatusText = I18n.t("javascript.ebs_backup_not_exists_results.volume_status");
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    const sortByRegion = this.props.sortColumn === "region";
    const sortBySnapshotName = this.props.sortColumn === "snapshot_name";
    const sortBySnapshotStartTime =
      this.props.sortColumn === "snapshot_start_time";
    const sortByVolumeName = this.props.sortColumn === "volume_name";
    const sortByVolumeStatus = this.props.sortColumn === "volume_status";

    return (
      <table
        className="table vertical-middle ca-backup-check-volumes-table ca-table-header-no-bordered"
        ref={this.props.tooltipRef}
      >
        <thead>
          <tr>
            <SortableTableHeaderCell
              column="region"
              active={sortByRegion}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.regionText}
              classNameForHeaderCell="
                ca-backup-check-volumes-table-header--clickable
                ca-backup-check-volumes-table-header__region
              "
            />
            <SortableTableHeaderCell
              column="volume_name"
              active={sortByVolumeName}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.volumeNameText}
              caption={`(${this.volumeIdText})`}
              classNameForHeaderCell="
                ca-backup-check-volumes-table-header--clickable
                ca-backup-check-volumes-table-header__volume-name
              "
            />
            <SortableTableHeaderCell
              column="volume_status"
              active={sortByVolumeStatus}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.volumeStatusText}
              classNameForHeaderCell="
                ca-backup-check-volumes-table-header--clickable
                ca-backup-check-volumes-table-header__volume-status
              "
            />
            <SortableTableHeaderCell
              column="snapshot_name"
              active={sortBySnapshotName}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.snapshotNameText}
              caption={`(${this.snapshotIdText})`}
              classNameForHeaderCell="
                ca-backup-check-volumes-table-header--clickable
                ca-backup-check-volumes-table-header__snapshot-name
                text-center
              "
            />
            <SortableTableHeaderCell
              column="snapshot_start_time"
              active={sortBySnapshotStartTime}
              callback={this.props.callback}
              direction={this.props.sortDirection}
              text={this.snapshotStartTimeText}
              classNameForHeaderCell="
                ca-backup-check-volumes-table-header--clickable
                ca-backup-check-volumes-table-header__snapshot-start-time
              "
            />
          </tr>
        </thead>
        <tbody>{this.getEbsBackupCheckResultRows()}</tbody>
      </table>
    );
  }

  /*
   * テーブルに表示するバックアップボリュームの行コンポーネントを配列で返します
   * バックアップ済み/推奨ボリュームがない時は列を連結してエラーメッセージを表示します
   *
   * @return {ReactElement}
   */
  getEbsBackupCheckResultRows() {
    const isEbsBackupCheckResultsExist =
      this.props.errorMessage == null &&
      this.props.ebsBackupCheckResults.length > 0;

    if (this.props.isAwsAccountUnitError) {
      const message =
        this.props.errorMessage != null
          ? this.props.errorMessage
          : I18n.t(
              "javascript.ebs_backup_not_exists_results.aws_account_unit_error"
            );
      return (
        <tr key="0">
          <td colSpan="7">{message}</td>
        </tr>
      );
    } else if (isEbsBackupCheckResultsExist) {
      return this.props.ebsBackupCheckResults.map(result => {
        return <EbsBackupVolumeRow key={result.id} result={result} />;
      });
    } else {
      const message =
        this.props.emptyResultMessage ||
        this.props.errorMessage ||
        I18n.t("javascript.ebs_backup_not_exists_results.no_results");
      return (
        <tr key="0">
          <td colSpan="7">{message}</td>
        </tr>
      );
    }
  }
}

// EbsBackupVolumeTable コンポーネント内で Tooltip を使えるようにしてから外部にエクスポートする
export default withBootstrapTooltip(EbsBackupVolumeTable);
