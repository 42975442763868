import PropTypes from 'prop-types';
import React from 'react';

import Constants from './Constants.js';

const I18n = window.I18n; // i18n-js

/**
 * 結果表示用ダイアログコンテンツ。
 *
 * パスワードの変更完了時にダイアログ内に表示されるコンテンツをあらわしたコンポーネントです。
 */
export default class ResultDialogContent extends React.Component {
  /**
   * プロパティの定義を返します。
   *
   * @return {Object}
   * @property {Object} emitter EventEmitterオブジェクト
   */
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);

    this.contentText = I18n.t('password_updated', { scope: Constants.I18N_SCOPE });
    this.closeButtonText = I18n.t('close_button', { scope: Constants.I18N_SCOPE });
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <div>
        <div className="ca-password-settings-dialog__result-text">
          {this.contentText}
        </div>
        <button
          className="btn btn-cancel btn-lg"
          onClick={() => { this.props.emitter.emit(Constants.EVENT_CLOSE_DIALOG); }}
          type="button">
          {this.closeButtonText}
        </button>
      </div>
    );
  }
}
