import React, { useEffect, useRef } from "react";

/**
 * タグに設定されたBootstrapのツールチップを有効にするためのカスタムフック。
 *
 * data-toggle="tooltip" 属性を持ったコンポーネントのref属性値に
 * このフックの戻り値を設定すると、ツールチップが表示されるようになります。
 *
 * フックの型パラメーターとして、対象のタグの型を指定します(HTMLLinkElement や HTMLButtonElementなど)。
 *
 * @example
 *   const tooltipRef = useBootstrapTooltip<HTMLButtonElement>();
 *
 *   <button
 *     ref={tooltipRef}
 *     data-toggle="tooltip"
 *     data-placement="bottom"
 *     title="削除"
 *   >
 *     Delete
 *   </button>
 */
export const useBootstrapTooltip = <T,>(): React.RefObject<T> => {
  const tooltipRef = useRef<T>(null);

  // コンポーネントの描画時に、子コンポーネントでdata-toggle属性を持つもののツールチップを有効化する
  useEffect(() => {
    if (tooltipRef.current) {
      // @ts-expect-error Bootstrapのtooltip()呼び出しで型エラーが発生するため。
      // 同様の箇所が増えてきたら DefinitelyTyped/types/bootstrap/ の導入を検討する。
      jQuery(tooltipRef.current).tooltip();
    }
  }, []);

  return tooltipRef;
};
