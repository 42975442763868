import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import PostProcessFormContainer from '../post_process_form/PostProcessFormContainer.jsx';

const I18n = window.I18n; // i18n-js
const I18N_SCOPE = 'javascript.post_process_assignments_panel';

/**
 * トリガージョブ作成フォームにおける後処理作成時のダイアログ
 * ダイアログの表示制御および、プロパティの値に応じたコンテンツの表示を行います。
 */
export default class Dialog extends React.Component {
  /**
   * propTypes
   * @return {object}
   * @property {object} addPostProcessContainerEmitter EventEmitter
   * @property {string} defaultLanguage - 言語設定欄がある場合のデフォルトの言語(例 "ja")
   * @property {string} defaultTimeZone - タイムゾーン設定がある場合のデフォルトのタイムゾーン(例 "Tokyo")
   * @property {Array<Number>} groupIds グループID一覧
   * @property {array[]} groups グループ一覧
   * @property {boolean} isSlackIntegrated - Slackが外部サービスとして連携済みかどうか
   * @property {boolean} isUserCanManageIntegrations - ユーザーが外部サービス連携を管理できるかどうか
   * @property {string} loadingImagePath ローディング中に表示する画像のURLパス
   * @property {string} postProcessesUrl 後処理の更新時にXHRでアクセスするURL
   * @property {array} regions リージョンの選択肢
   * @property {array} services サービスの選択肢
   * @property {bool} showDialog ダイアログが表示状態かどうか
   * @property {string} sqsQueuesPath SQSキュー名を取得するためのパス
   * @property {Array<Array>} timeZones - タイムゾーンの選択肢
   */
  static get propTypes() {
    return({
      defaultLanguage: PropTypes.string.isRequired,
      defaultTimeZone: PropTypes.string.isRequired,
      emitter: PropTypes.object.isRequired,
      groupIds: PropTypes.arrayOf(PropTypes.number),
      groups: PropTypes.arrayOf(PropTypes.array),
      isSlackIntegrated: PropTypes.bool.isRequired,
      isUserCanManageIntegrations: PropTypes.bool.isRequired,
      loadingImagePath: PropTypes.string.isRequired,
      postProcessesUrl: PropTypes.string.isRequired,
      regions: PropTypes.arrayOf(PropTypes.array).isRequired,
      services: PropTypes.arrayOf(PropTypes.array).isRequired,
      showDialog: PropTypes.bool.isRequired,
      sqsQueuesPath: PropTypes.string.isRequired,
      timeZones: PropTypes.arrayOf(PropTypes.array).isRequired,
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);

    this.contentLabelText = I18n.t('content_label', { scope: I18N_SCOPE });

    ReactModal.setAppElement("body");
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const contentClassName = "post-process-settings-modal__enable-content";

    return(
      <ReactModal
        className={contentClassName}
        contentLabel={this.contentLabelText}
        isOpen={this.props.showDialog}
        onRequestClose={() => { this.props.emitter.emit('closeDialog'); }}
        overlayClassName="post-process-assignments-panel-modal__overlay"
        role="dialog"
      >
        {this.renderContent()}
      </ReactModal>
    );
  }

  /**
   * ダイアログのコンテンツを表示します。
   * @access {private}
   * @return {ReactElement}
   */
  renderContent() {
    const { emitter, postProcessesUrl, showDialog, ...commonProps } = this.props;

    return(
      <PostProcessFormContainer
        dialogEventEmitter={emitter}
        isDialog={true}
        url={postProcessesUrl}
        {...commonProps}
      />
    );
  }
}
