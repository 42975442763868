import React from 'react';
import PropTypes from 'prop-types';

import Spinner from '../Spinner.jsx';

// 入力された確認コードが正しい書式かどうかを検証する正規表現
const AUTH_CODE_REGEXP = /^\d{6}$/;

const I18n = window.I18n; // i18n-js
const I18N_SCOPE = 'javascript.tfa_settings.enable_dialog_content';

// ローディング中の表示に使うスピナーの表示オプション
const SPINNER_OPTIONS = {
  lines: 9,
  length: 3,
  width: 3,
  redius: 5,
  top: '100px',
  left: '290px',
};

/**
 * 二要素認証を有効化するダイアログに表示するコンテンツ
 * QRコードの表示および二要素認証有効化のフォーム送信までを行います。
 */
export default class EnableDialogContent extends React.Component {
  /**
   * propTypes
   * @return {object}
   * @property {object} emitter EventEmitter
   * @property {bool} error エラーが発生したかどうか
   * @property {string|null} qrCodeHtml QRコード
   */
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
      error: PropTypes.bool.isRequired,
      otpSecretKey: PropTypes.string,
      qrCodeHtml: PropTypes.string,
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);

    this.state = {
      authCode: '' // 認証コード入力欄に入力された値
    };

    this.authCodeInput = null;

    const options = { scope: I18N_SCOPE };
    this.cancelText = I18n.t('cancel', options);
    this.enableText = I18n.t('to_enable', options);
    this.errorMessage = I18n.t('error', options);
    this.notice1_1 = I18n.t('notice1_1', options);
    this.notice1_2 = I18n.t('notice1_2', options);
    this.notice1_3 = I18n.t('notice1_3', options);
    this.notice1_4 = I18n.t('notice1_4', options);
    this.notice2 = I18n.t('notice2', options);
    this.step1 = I18n.t('step1', options);
    this.step2 = I18n.t('step2', options);
    this.url = I18n.t('url', options);
  }

  /**
   * @override
   */
  componentDidMount() {
    if (this.authCodeInput) {
      // Chrome/Firefoxでは focus() を呼ぶだけだと入力欄にフォーカスが移らないため、
      // ウエイトを入れている。
      // https://gist.github.com/iguu/5575105
      setTimeout(() => {
        if (this.authCodeInput) {
          this.authCodeInput.focus();
        }
      }, 0);
    }
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <div className="qa-tfa-settings-enable-modal">
        <section className="tfa-settings-modal__section">
          <h4 className="tfa-settings-modal__step">{this.step1}</h4>
          <p className="tfa-settings-modal__enable-notice">
            {this.notice1_1}<br/>
            <a className="tfa-settings-modal__link-icon" href={this.url} rel="noreferrer" target="_blank"><i className="fal fa-external-link"></i>{this.notice1_2}</a>
          </p>

          <div className="tfa-settings-modal__qr-area">
            <div className="qr-box">
              {this.renderQrCode()}
            </div>
            <div className="tfa-settings-modal__qr-message">
              <p className="font-red">{this.notice1_4}</p>
              {this.renderOtpSecretKey()}
            </div>
          </div>

        </section>

        <section className="tfa-settings-modal__section">
          <h4 className="tfa-settings-modal__step">{this.step2}</h4>

          <p className="tfa-settings-modal__enable-notice">
            {this.notice2}<br/>
          </p>

          <form
            className="form-horizontal"
            onSubmit={this.handleOnSubmit.bind(this)}
            role="form"
          >
            <div className="input-group tfa-settings-modal__input-group">
              <input
                autoComplete="off"
                className="form-control input-middle text-center"
                maxLength="6"
                minLength="6"
                name="auth_code"
                onChange={(event) => { this.setState({ authCode: event.target.value }); }}
                ref={(input) => { this.authCodeInput = input; }}
                required={true}
                type="text"
                value={this.state.authCode}
              />
            </div>

            <div className="error-area">
              {this.renderErrorMessage()}
            </div>

            <div className="tfa-settings-modal__button-area">
              <button
                className="btn btn-cancel btn-lg tfa-settings-modal__cancel-button"
                onClick={() => { this.props.emitter.emit('closeDialog'); }}
                type="button"
              >
                {this.cancelText}
              </button>

              <button
                className="btn btn-default btn-lg btn-right tfa-settings-modal__submit-button qa-tfa-settings-enable-submit-button"
                disabled={!this.isFormSubmittable()}
                type="submit"
              >
                {this.enableText}
              </button>
            </div>
          </form>
        </section>

      </div>
    );
  }

  /**
   * エラーメッセージを表示します。
   *
   * @access {private}
   * @return {ReactElement|null}
   */
  renderErrorMessage() {
    if (this.props.error) {
      return(
        <label className="error">{this.errorMessage}</label>
      );
    } else {
      return null;
    }
  }

  /**
   * QRコードが読み込めない人用のシークレットキーを表示します。
   *
   * @access {private}
   * @return {ReactElement|null}
   */
  renderOtpSecretKey() {
    if (this.props.otpSecretKey) {
      const secretKey = this.props.otpSecretKey.match(/.{4}/g);
      const keys = [];

      secretKey.map((key, id) => {
        keys.push(
          <span className="tfa-settings-modal__secret-key__keys" key={id}>{key.toLowerCase()}</span>
        );
      });

      return(
        <div>
          <p><i className="fas fa-exclamation-triangle"></i> {this.notice1_3}</p>
          <code className="tfa-settings-modal__secret-key">{keys}</code>
        </div>
      )
    } else {
      return null;
    }
  }

  /**
   * QRコードを表示します。
   *
   * @access {private}
   * @return {ReactElement}
   */
  renderQrCode() {
    return this.props.qrCodeHtml
      ? <div dangerouslySetInnerHTML={{__html: this.props.qrCodeHtml}}></div>
      : <Spinner options={SPINNER_OPTIONS} />;
  }

  /**
   * フォームのsubmitイベントを処理します。
   *
   * @access {private}
   * @param {SyntheticEvent} event
   */
  handleOnSubmit(event) {
    event.preventDefault();
    this.props.emitter.emit('enableTFA', this.state.authCode);
  }

  /**
   * 確認コードが正しく入力されているかどうかを返します。
   *
   * @access {private}
   * @return {boolean}
   */
  isAuthCodeFilled() {
    return AUTH_CODE_REGEXP.test(this.state.authCode);
  }

  /**
   * QRコードがサーバーから取得されているかどうかを返します。
   *
   * @access {private}
   * @return {boolean}
   */
  isQrCodeFetched() {
    return this.props.qrCodeHtml != null;
  }

  /**
   * フォームが送信可能な状態化どうかを返します。
   *
   * @access {private}
   * @return {boolean}
   */
  isFormSubmittable() {
    return this.isQrCodeFetched() && this.isAuthCodeFilled();
  }
}
