import React from 'react';
import PropTypes from 'prop-types';

/**
 * モーダルダイアログ。
 */
export default class Modal extends React.Component {
  /**
   * プロパティの定義を返します。
   *
   * @return {Object}
   * @property {anything} content 確認内容や本文等が含まれたDOM要素
   *                              ex) <div>"are you ok?"</div>
   * @property {string} id モーダルウィンドウのユニークなID
   * @property {boolean} isOpen モーダルウィンドウをオープンするときに更新するbooleanのstate
   * @property {string} needsPositiveButton `作成する`、`削除する`等のアクションを行うボタンが必要か否か
   * @property {string} negativeButtonClass ネガティブボタンに適用するCSSスタイル
   *                                        初期値は`btn btn-sm`
   * @property {string} negativeButtonValue `キャンセル`、`戻る`等のアクションを取りやめるボタンの名前
   * @property {function} onClickPositiveButton ポジティブボタンをクリックしたときに実行する関数
   * @property {function} onClose モーダルウィンドウをクローズするときに実行する関数
   * @property {string} positiveButtonClass ポシティブボタンに適用するCSSスタイル
   *                                        初期値は`btn btn-sm btn-right`
   * @property {string} positiveButtonValue ポジティブボタンの名前
   */
  static get propTypes() {
    return({
      content: PropTypes.any,
      id: PropTypes.string.isRequired,
      isOpen: PropTypes.bool.isRequired,
      needsPositiveButton: PropTypes.bool.isRequired,
      negativeButtonClass: PropTypes.string,
      negativeButtonValue: PropTypes.string,
      onClickPositiveButton: PropTypes.func,
      onClose: PropTypes.func.isRequired,
      positiveButtonClass: PropTypes.string,
      positiveButtonValue: PropTypes.string,
    });
  }

  /**
   * デフォルトのプロパティ値を返します。
   * React内部から呼び出されます。
   *
   * @return {Object}
   */
  static get defaultProps() {
    return({
      negativeButtonClass: "btn btn-sm",
      positiveButtonClass: "btn btn-sm btn-right",
    });
  }

  /**
   * @override
   * @return {?ReactElement}
   */
  render() {
    if (!this.props.isOpen) {
      return null;
    }

    return (
      <div id={this.props.id} className="api-key-modal">
        <div className="api-key-modal__overlay" onClick={this.props.onClose} />
        <div className="api-key-modal__inner">
          {this.props.content}
          <div className="modal-btns">
            <input
              className={this.props.negativeButtonClass}
              onClick={this.props.onClose}
              type="button"
              value={this.props.negativeButtonValue}
            />
            {this.props.needsPositiveButton && this.renderPositiveButton()}
          </div>
        </div>
      </div>
    );
  }

  /**
   * @return {ReactElement}
   */
  renderPositiveButton() {
    return(
      <input
        className={this.props.positiveButtonClass}
        onClick={this.props.onClickPositiveButton}
        type="button"
        value={this.props.positiveButtonValue}
      />
    );
  }
}
