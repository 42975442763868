import PropTypes from 'prop-types';
import React from 'react';

import Constants from './Constants.js';
import EmptyRow from '../record_table/EmptyRow.jsx';
import PendingRow from '../record_table/PendingRow.jsx';
import PolicyLogTableRow from './PolicyLogTableRow.jsx';

const I18n = window.I18n; // i18n-js

/**
 * ポリシーログテーブルコンポーネント。
 *
 * 1つのページに表示するポリシーログ情報を1つのテーブルとして表示します。
 * 表示されるDOMはTABLEタグとなります。
 */
export default class PolicyLogTable extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {boolean} pending 通信中かどうか
   * @property {PolicyLog[]} policyLogs 表示するポリシーログの配列
   */
  static get propTypes() {
    return({
      pending: PropTypes.bool.isRequired,
      policyLogs: PropTypes.arrayOf(PropTypes.object).isRequired,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    const options = { scope : `${Constants.I18N_SCOPE}.table` };
    this.policyNameText = I18n.t('policy_name', options);
    this.resultText = I18n.t('result', options);
    this.reviewTimingText = I18n.t('review_timing', options);
    this.evaluationTimeText = I18n.t('evaluation_time', options);
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <table className="
        table
        vertical-middle
        ca-table-header-no-bordered
        policy-logs-table
      ">
        <thead>
          <tr>
            <th className="policy-logs-table__name-column">
              {this.policyNameText}
            </th>
            <th className="policy-logs-table__result-column">
              {this.resultText}
            </th>
            <th className="policy-logs-table__trigger-type-column">
              {this.reviewTimingText}
            </th>
            <th className="policy-logs-table__evaluation-time-column">
              {this.evaluationTimeText}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.renderRows()}
        </tbody>
      </table>
    );
  }

  /**
   * データに応じてテーブルの行を返します。
   *
   * @private
   * @return {ReactElement}
   */
  renderRows() {
    if (this.props.pending) {
      return <PendingRow columns={5} />;
    }

    if (this.props.policyLogs.length == 0) {
      return <EmptyRow columns={5} />;
    }

    return this.props.policyLogs.map(log => <PolicyLogTableRow key={log.id} policyLog={log} />);
  }
}
