import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import GroupIconName from '../GroupIconName.jsx';
import ErrorMessages from '../ErrorMessages.jsx';
import CurrentUserInfo from '../CurrentUserInfo';

const I18n = window.I18n; // i18n-js
const I18N_SCOPE = `${Constants.I18N_SCOPE}.group_selection_field`;

/**
 * グループ選択フィールド
 * @todo GroupSelectorコンポーネントを使うようにする
**/
class GroupSelectionField extends React.Component {
  /**
   * propTypes
   * @return {Object}
   * @property {Object} emitter - EventEmitterオブジェクト
   * @property {Object} group - グループオブジェクト
   * @property {Array<Number>} groupIds - グループID一覧。値がundefinedの場合は空の配列を代入します。
   * @property {Array<Array>} groups - グループ一覧。値が設定されていない場合は編集画面として振る舞います。
   * @property {string} value 現在選択されているグループのID
  **/
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
      group: PropTypes.object,
      groupIds: PropTypes.arrayOf(PropTypes.number),
      groups: PropTypes.arrayOf(PropTypes.array),
      value: PropTypes.string.isRequired,
    });
  }

  constructor(props) {
    super(props);
    this.userInfo = new CurrentUserInfo();
  }

  /**
   * エラーがあるかどうかを返します。
   *
   * @return {boolean}
   */
  hasError() {
    return this.props.errors.length > 0;
  }

  render() {
    const label = I18n.t('activerecord.attributes.post_process.group');

    return(
      <div className="form-group">
        <div className="post-process-setting">
          <div className="post-process-setting__label">
            <div className="post-process-setting__label__text">
              <label htmlFor="PostProcess-group">
                {label}
              </label>
              {this.renderRequiredMark()}
            </div>
          </div>
          {this.renderGroupField()}
        </div>
      </div>
    );
  }

  /**
   * 入力必須を表す `*` マークを描画します。
   * groups がコンポーネントに渡されていない場合は、編集画面とみなしてマークを描画しません。
  **/
  renderRequiredMark() {
    if (this.props.groups) { return(<span className="required">*</span>); }
  }

  /**
   * グループを選択するためのセレクトボックスを描画します。
  **/
  renderOptions() {
    const blankOption = [I18n.t('blank_option', { scope: I18N_SCOPE }), 'none'];
    const shared_by_group = [I18n.t('shared_by_group', { scope: I18N_SCOPE }), ''];

    let groupIds;
    if (this.props.groupIds == undefined) {
      groupIds = [];
    } else {
      groupIds = this.props.groupIds;
    }

    let groups;
    if (groupIds.length > 0) {
      groups = this.props.groups.filter((group) => {
        const groupId = group[1];
        return groupIds.includes(groupId);
      });
    } else {
      groups = this.props.groups;
    }

    let options;
    if (this.userInfo.getUserType() === "member") {
      options = [blankOption].concat(groups);
    } else {
      options = [blankOption, shared_by_group].concat(groups);
    }

    return(options.map((item) => {
      const label = item[0];
      const value = item[1];
      return(
        <option key={value} value={value}>{label}</option>
      );
    }));
  }

  /**
   * グループ一覧またはグループ名をアイコン付きで描画します。
   * 作成画面の場合はグループ一覧、編集画面の場合はグループ名を描画します。
   * コンポーネントに groups が渡されている場合は作成画面とみなします。
  **/
  renderGroupField() {
    const errorClass = this.hasError() ? 'error' : '';
    const help = I18n.t('help', { scope: I18N_SCOPE });
    const help2 = I18n.t('help2', { scope: I18N_SCOPE });

    if (this.props.groups) {
      return(
        <div className="post-process-setting__form">
          <select id="PostProcess-group"
            className={`form-control ${errorClass}`}
            onChange={event => this.props.emitter.emit(Constants.EVENT_CHANGE_GROUP, event.target.value)}
            value={this.props.value}>
            {this.renderOptions()}
          </select>
          <p className="post-process-setting__prompt">
            {help}<br />{help2}
          </p>
          <ErrorMessages messages={this.props.errors} />
        </div>
      );
    } else {
      return(
        <GroupIconName group={this.props.group} />
      );
    }
  }
}

export default GroupSelectionField;
