import PropTypes from 'prop-types';
import React from 'react';

/**
 * 1組のパスワードフィールドとラベル。
 */
export default class PasswordField extends React.Component {
  /**
   * プロパティの定義を返します。
   *
   * @return {Object}
   * @property {?boolean} autoFocus 自動的にINPUT要素にフォーカスを移すかどうか
   * @property {Object} emitter EventEmitterオブジェクト
   * @property {string} inputId INPUT要素に設定するID属性値
   * @property {string} label ラベルとして表示する文字列
   * @property {?number} minLength INPUT要素のminLength属性値
   * @property {?function(newValue: string)} onChange 値が変化した際に呼び出す関数
   * @property {boolean} required 入力欄が必須かどうか
   * @property {string} value 現在値
   */
  static get propTypes() {
    return({
      autoFocus: PropTypes.bool,
      emitter: PropTypes.object.isRequired,
      inputId: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      minLength: PropTypes.number,
      onChange: PropTypes.func,
      required: PropTypes.bool.isRequired,
      value: PropTypes.string.isRequired,
    });
  }

  /**
   * デフォルトのプロパティを返します。
   *
   * @public
   * @return {Object}
   * @property {boolean} autoFocus 自動的にINPUT要素にフォーカスを移すかどうか
   */
  static get defaultProps() {
    return({
      autoFocus: false,
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);

    // INPUT要素(コンポーネントのマウント時に入力欄にフォーカスを移すために使用)
    this.inputElement = null;

    this.classesForLabel = 'ca-password-settings-dialog__label';
    if (props.required) {
      this.classesForLabel = `${this.classesForLabel} ca-password-settings-dialog__label--required`;
    }
  }

  /**
   * @override
   */
  componentDidMount() {
    if (this.props.autoFocus && this.inputElement) {
      // Chrome/Firefoxでは focus() を呼ぶだけだと入力欄にフォーカスが移らないため、
      // ウエイトを入れている。
      // https://gist.github.com/iguu/5575105
      setTimeout(() => {
        if (this.inputElement) {
          this.inputElement.focus();
        }
      }, 0);
    }
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <div className="ca-password-settings-dialog__field">
        <label
          className={this.classesForLabel}
          htmlFor={this.props.inputId}
        >
          {this.props.label}
        </label>
        <input
          className="form-control ca-password-settings-dialog__input"
          id={this.props.inputId}
          minLength={this.props.minLength}
          onChange={(event) => this.props.onChange(event.target.value)}
          ref={(input) => {
            if (this.props.autoFocus) {
              this.inputElement = input;
            }
          }}
          required={this.props.required}
          type="password"
          value={this.props.value}
        />
      </div>
    );
  }
}
