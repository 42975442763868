import React from 'react';
import PropTypes from 'prop-types';

/**
 * ボタン
 */
export default class Button extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {node} children ボタンの表示内容
   * @property {?string} className class属性値
   * @property {?boolean} disabled 無効かどうか
   * @property {function(event: SyntheticEvent, ?param)} onClick onClickイベントで呼び出す関数
   * @property {?number|?string} param onClickイベントで呼び出す関数に渡される値
   */
  static get propTypes() {
    return({
      children: PropTypes.node.isRequired,
      className: PropTypes.string,
      disabled: PropTypes.bool,
      onClick: PropTypes.func.isRequired,
      param: PropTypes.any,
    });
  }

  /**
   * デフォルトのプロパティを返します。
   *
   * @public
   * @return {Object}
   * @property {?string} className class属性値
   * @property {?boolean} disabled 無効かどうか
   * @property {?number|?string} param onClickイベントで呼び出す関数に渡される値
   */
  static get defaultProps() {
    return({
      className: 'btn',
      disabled: false,
      param: null,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <button
        className={this.props.className}
        disabled={this.props.disabled}
        onClick={this.handleClick}
      >
        {this.props.children}
      </button>
    );
  }

  /**
   * ボタンがクリックされた際の処理を行います。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleClick(event) {
    if (this.props.onClick) {
      event.preventDefault();
      this.props.onClick(event, this.props.param);
    }
  }
}
