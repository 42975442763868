import React from 'react';
import EventEmitter from 'events';
import PropTypes from 'prop-types';

import DeletePostProcessDialog from "./DeletePostProcessDialog.jsx";
import withBootstrapTooltip from '../withBootstrapTooltip.js';

const I18n = window.I18n; // i18n-js

/**
 * 編集ボタンコンポーネント
 *
 * 1行ごとに表示される編集・削除ボタンを描画します。
 */
class PostProcessButtons extends React.Component {
  /**
   * propTypes
   * @return {Object}
   * @property {Object} PostProcess - 後処理オブジェクト
   * @property {function(element: DOMNode)} tooltipRef
  **/
  static get propTypes() {
    return({
      postProcess: PropTypes.object.isRequired,
      reloadEmitter: PropTypes.object.isRequired,
      tooltipRef: PropTypes.func,
    });
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);

    this.state = {
      showDeletePostProcessDialog: false,
    };

    this.emitter = new EventEmitter();
    this.emitter.on('openDialog', this.handleOpenDialog.bind(this));
    this.emitter.on('closeDialog', this.handleCloseDialog.bind(this));
  }

  /**
   * 削除確認に使うモーダル表示の制御を行います
   */
  handleCloseDialog() {
    this.setState({ showDeletePostProcessDialog: false });
  }

  /**
   * 削除確認に使うモーダル表示の制御を行います
   */
  handleOpenDialog() {
    this.setState({ showDeletePostProcessDialog: true });
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const options = { scope: 'javascript.post_process_table' };

    if(this.props.postProcess.writable) {
      const editButtonTitle = I18n.t('edit', options);
      const deleteButtonTitle = I18n.t('delete', options);

      return(
        <div className="ca-post-process-table__row-buttons text-right" ref={this.props.tooltipRef}>
          <a
            href={this.props.postProcess.edit_url}
            className="btn marginR5"
            title={editButtonTitle}
            data-placement="bottom"
            data-toggle="tooltip"
          >
            <i className="fa fa-pencil"></i>
          </a>
          <button
            className="btn btn-default"
            type="button"
            data-placement="bottom"
            data-toggle="tooltip"
            title={deleteButtonTitle}
            onClick={() => {
              this.emitter.emit("openDialog");
            }}
          >
            <span className="fa fa-trash-o" />
          </button>
          <DeletePostProcessDialog
            emitter={this.emitter}
            postProcess={this.props.postProcess}
            reloadEmitter={this.props.reloadEmitter}
            showDeletePostProcessDialog={this.state.showDeletePostProcessDialog}
          />
        </div>
      );
    } else {
      let deleteButtonTitle = "";
      let editButtonTitle = "";

      if(this.props.postProcess.shared_by_group) {
        deleteButtonTitle = I18n.t('delete_insufficient_admin_permission', options);
        editButtonTitle = I18n.t('edit_insufficient_admin_permission', options);
      } else {
        deleteButtonTitle = I18n.t('delete_insufficient_permission', options);
        editButtonTitle = I18n.t('edit_insufficient_permission', options);
      }

      return(
        <div className="ca-post-process-table__row-buttons text-right" ref={this.props.tooltipRef}>
          <a
            className="btn ca-post-process-button__disabled marginR5"
            title={editButtonTitle}
            data-placement="bottom"
            data-toggle="tooltip"
          >
            <i className="fa fa-pencil"></i>
          </a>
          <a
            title={deleteButtonTitle}
            className="btn ca-post-process-button__disabled"
            data-placement="bottom"
            data-toggle="tooltip"
          >
            <i className="fa fa-trash-o"></i>
          </a>
        </div>
      );
    }
  }
}

export default withBootstrapTooltip(PostProcessButtons);
