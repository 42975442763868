import React from "react";
import PropTypes from "prop-types";

import { ErrorArea } from "./ErrorArea.jsx";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.aws_account.common";

// IAMロール方式でAWSアカウントを登録する際に使用するARN入力フォームコンポーネント
export class EbsBackupCheckInputField extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {bool} ebsBackupCheckEnabled EBSバックアップチェックを有効にするか
   * @property {boolean} disabled 入力欄が有効か否か
   * @property {function(ebsBackupCheckEnabled: bool)} onChange - 入力値が変化した際に呼び出されるコールバック関数
   * @property {Object} errors 各入力欄の下に表示するエラーメッセージの配列をプロパティ値として持つオブジェクト
   */
  static get propTypes() {
    return {
      ebsBackupCheckEnabled: PropTypes.bool.isRequired,
      disabled: PropTypes.bool.isRequired,
      onChange: PropTypes.func.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    };
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return (
      <div className="form-group">
        <label className="ca-group__aws-account-form__label" htmlFor="IamRoleAwsAccountForm-ebs-backup-check">
          {I18n.t("ebs_backup_check", { scope: I18N_SCOPE })}
          <span className="ca-group__aws-account-form__required-mark">*</span>
          <a
            href={I18n.t("common.manual.url.about.ebs_backup_check")}
            target="_blank"
            rel="noreferrer"
            className="marginL5"
          >
            <li className="fa fa-question-circle"></li>
          </a>
        </label>
        <select
          className="form-control ca-group__aws-account-form__select"
          disabled={this.props.disabled}
          id="IamRoleAwsAccountForm-ebs-backup-check"
          value={this.props.ebsBackupCheckEnabled}
          onChange={(event) => this.props.onChange(event.target.value)}
        >
          <option value={true}>✓ {I18n.t("enable_ebs_backup_check", { scope: I18N_SCOPE })}</option>
          <option value={false}>✗ {I18n.t("disable_ebs_backup_check", { scope: I18N_SCOPE })}</option>
        </select>
        <ErrorArea errors={this.props.errors} />
      </div>
    );
  }
}
