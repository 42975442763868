import PropTypes from 'prop-types';
import React from 'react';

import Constants from './Constants.js';

const I18n = window.I18n; // i18n-js

/**
 * フィルタリング条件に該当した件数表示。
 * 表示されるDOMは "(全n件)" のようなテキストノードとなります。
 */
export default class TotalCountText extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {number} value 表示する件数
   */
  static get propTypes() {
    return({
      value: PropTypes.number.isRequired,
    });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    const options = {
      scope : `${Constants.I18N_SCOPE}.total_count_text`,
      total: this.props.value,
    };
    const text = I18n.t('text', options);
    return `( ${text} )`;
  }
}
