import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon.jsx';

import Button from './Button.jsx';
import Constants from './Constants.js';

const I18n = window.I18n; // i18n-js

/**
 * ダウンロードボタン
 */
export default class DownloadButton extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {boolean} disabled 無効かどうか
   * @property {?string} url リンク先URL
   */
  static get propTypes() {
    return({
      disabled: PropTypes.bool,
      url: PropTypes.string,
    });
  }

  /**
   * デフォルトのプロパティを返します。
   *
   * @public
   * @return {Object}
   * @property {boolean} disabled 無効かどうか
   * @property {?string} url リンク先URL
   */
  static get defaultProps() {
    return({
      disabled: false,
      url: null,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.handleClickButton = this.handleClickButton.bind(this);
    this.labelText = I18n.t('label_for_download_button', { scope: Constants.I18N_SCOPE });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <Button
        disabled={this.props.disabled}
        onClick={this.handleClickButton}
      >
        <Icon name="cloud-download" />
        <span className="marginL5 font-13px">
          {this.labelText}
        </span>
      </Button>
    );
  }

  /**
   * ボタンがクリックされた際の処理を行います。
   *
   * @private
   */
  handleClickButton() {
    window.location = this.props.url;
  }
}
