import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import GroupIconTip from '../GroupIconTip.jsx';

/**
 * 後処理割り当てコンポーネント
 *
 * 1件の後処理割り当てをあらわします。削除ボタンを内包します。
 */
export default class PostProcessAssignment extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {Object} emitter EventEmitterオブジェクト
   * @property {Object} group グループオブジェクト
   * @property {Object} postProcess 後処理オブジェクト
   * @property {bool} readOnly コンポーネントを表示専用にするかどうか
   */
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
      group: PropTypes.object,
      postProcess: PropTypes.object.isRequired,
      postProcessType: PropTypes.string.isRequired,
      readOnly: PropTypes.bool
    });
  }

  /**
   * デフォルトのプロパティを返します。
   *
   * @public
   * @return {Object}
   */
  static get defaultProps() {
    return({
      readOnly: false
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <React.Fragment>
        <div className="ca-post-process-assignment-panel__assignment">
          <GroupIconTip group={this.props.group} />
          <div>{this.props.postProcess.name}</div>
        </div>
        {this.renderCloseButton()}
      </React.Fragment>
    );
  }

  /**
   * 後処理割り当てを解除するボタンを返します。
   *
   * @private
   */
  renderCloseButton() {
    if (this.props.readOnly) {
      return null;
    }

    return(
      <button
        type="button"
        className="ca-post-process-assignment-panel__close-button"
        onClick={this.handleClick}
      >&times;</button>
    );
  }

  /**
   * 後処理割り当てを解除するボタンがクリックされた際の処理を行います。
   *
   * @private
   */
  handleClick() {
    this.props.emitter.emit(
      Constants.EVENT_CLICK_DELETE_BUTTON,
      this.props.postProcessType,
      this.props.postProcess.id
    );
  }
}
