import React, { useState } from "react";

import { StackInfoInputField } from "../aws_account/StackInfoInputField.jsx";
import { AwsAccountNameInputField } from "../aws_account/AwsAccountNameInputField.jsx";
import ManualLink from "../ManualLink.jsx";
import { SubmitButton } from "../aws_account/SubmitButton.jsx";
import { IamRoleSetupButton } from "../aws_account/IamRoleSetupButton.jsx";

import { CreateGroupFormData, ErrorMessages } from "./types";

import { ProviderAccountTypeSelectorForGroup } from "./ProviderAccountTypeSelectorForGroup";
import { ErrorArea2 } from "./ErrorArea2";
import { GroupColorSelector2 } from "./GroupColorSelector2";
import { GroupNameInputField } from "./GroupNameInputField";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.new_group_with_iam_role_form";

/**
 * フォームの各フィールドに対するエラーメッセージを含むオブジェクトの型
 */
type FormErrors = {
  awsAccountName: ErrorMessages;
  awsAccounts: ErrorMessages;
  base: ErrorMessages;
  createIamRoleButton: ErrorMessages;
  groupColor: ErrorMessages;
  groupName: ErrorMessages;
  iamRoleExternalId: ErrorMessages;
  stackInfo: ErrorMessages;
};

/**
 * エラーメッセージ用ステートの初期状態およびリセット後の状態
 */
const emptyErrors: FormErrors = {
  awsAccountName: [],
  awsAccounts: [],
  base: [],
  createIamRoleButton: [],
  groupColor: [],
  groupName: [],
  iamRoleExternalId: [],
  stackInfo: [],
};

/**
 * グループ新規作成フォーム (IAMロール方式)
 *
 * 以下の責務を持ちます。
 *
 * - グループ新規作成HTMLフォーム(IAMロール方式用)の表示
 * - AWS側のIAMロールセットアップ用CFnのページへのリダイレクト
 * - グループ作成リクエストのサーバーサイドへの送信
 *   - グループ作成成功時のリダイレクト
 *   - グループ作成失敗時のエラーメッセージ表示
 *
 */
export const NewGroupWithIamRoleForm: React.FC<{
  group: {
    color: string;
    name: string;
  };
}> = (props) => {
  // AWSアカウント名
  const [awsAccountName, setAwsAccountName] = useState("");
  // CFNテンプレートキャッシュID
  const [cfnTemplateCacheId, setCfnTemplateCacheId] = useState<number | null>(null);
  // 各フィールド毎のエラーメッセージ
  const [errors, setErrors] = useState(Object.assign({}, emptyErrors));
  // グループのカラー
  const [groupColor, setGroupColor] = useState(props.group.color || "#aa0441");
  // グループの名前
  const [groupName, setGroupName] = useState(props.group.name || "");
  // IAMロールの外部ID
  const [iamRoleExternalId, setIamRoleExternalId] = useState<string | null>(null);
  // AWSのCFnスタック作成ページを開く処理中かどうか
  const [isPendingAwsRedirection, setIsPendingAwsRedirection] = useState(false);
  // フォームを送信中かどうか
  const [isTransmitting, setIsTransmitting] = useState(false);
  // CloudAutomator Stack Info
  const [stackInfo, setStackInfo] = useState("");

  /**
   * AWSのIAMロールセットアップ用CFnスタック作成ページを開きます。
   *
   * 処理に成功した場合は以下の値をステートに設定して、AWSのページを新しいウィンドウで
   * 開きます。
   *
   * - CFNテンプレートキャッシュID
   * - IAMロールの外部ID
   *
   * 処理に失敗した場合はIAMロール作成用ボタンのエラーメッセージを設定します。
   */
  const handleClickCreateIamRoleButton = (event: React.SyntheticEvent): void => {
    event.preventDefault();

    setErrors(Object.assign({}, emptyErrors));
    setIsPendingAwsRedirection(true);

    jQuery
      .ajax({
        url: "/iam_role_setup",
        method: "POST",
        dataType: "json",
        data: {},
      })
      .done((data) => {
        setCfnTemplateCacheId(data.cfn_template_cache_id);
        setIamRoleExternalId(data.external_id);
        setIsPendingAwsRedirection(false);
        if (window.open(data.iam_role_setup_url, "_blank") == null) {
          window.console.log("IAM role setup URL window blocked.");
        }
      })
      .fail(() => {
        const newErrors: FormErrors = { ...errors };
        newErrors.createIamRoleButton = [I18n.t("internal_server_error", { scope: I18N_SCOPE })];
        setErrors(newErrors);
        setIsPendingAwsRedirection(false);
      });
  };

  /**
   * CloudAutomatorStackInfo が変更された場合に呼び出されるコールバック関数
   */
  const handleChangeStackInfoInput = (stackInfo: string): void => {
    setStackInfo(stackInfo);
  };

  /**
   * フォームをXHRで送信してグループの新規作成を行います。
   *
   * 処理に成功した場合は、サーバーから指示されたURLにリダイレクトします。
   * 処理に失敗した場合は、バリデーションエラーまたはエラーメッセージを設定します。
   */
  const handleSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();

    if (cfnTemplateCacheId == null || iamRoleExternalId == null) {
      // この後のdataに含まれる値のうち、nullである可能性があるものをチェックして送信処理をキャンセルする。
      // ここでnullチェックを行わないと、data変数の生成時に型エラーが発生する。
      console.log(`cfnTemplateCacheId=${cfnTemplateCacheId} iamRoleExternalId=${iamRoleExternalId}`);
      alert(I18n.t("submit_cancelled", { scope: I18N_SCOPE }));
      return;
    }

    setErrors(emptyErrors);
    setIsTransmitting(true);

    const data: CreateGroupFormData = {
      // ここで記述している各リクエストパラメーター名は、サーバー側Groupモデルの
      // accepts_nested_attributes_for設定に準じたもの。
      group: {
        name: groupName,
        color: groupColor,
        aws_accounts_attributes: [
          {
            cfn_template_cache_id: cfnTemplateCacheId,
            name: awsAccountName,
            iam_role_attributes: {
              external_id: iamRoleExternalId,
            },
          },
        ],
      },
      stack_info: stackInfo,
    };

    jQuery
      .ajax({
        url: "/groups",
        method: "POST",
        dataType: "json",
        data: data,
      })
      .done((data) => {
        window.location.href = data.url;
      })
      .fail((jqXHR, textStatus) => {
        const newErrors = Object.assign({}, emptyErrors);

        if (jqXHR.status == 422 && Object.prototype.hasOwnProperty.call(jqXHR.responseJSON, "errors")) {
          // バリデーションエラーかつレスポンスJSONに errors プロパティが存在する場合
          const rawErrors = jqXHR.responseJSON.errors;
          // レスポンスJSONに含まれるエラーメッセージのキーはGroupモデルの定義に
          // 準じたキーになっているため、個別に読み替えて変数に格納する。
          newErrors.awsAccountName = rawErrors["aws_accounts.name"] || [];
          newErrors.base = rawErrors.base || [];
          newErrors.groupColor = rawErrors.color || [];
          newErrors.groupName = rawErrors.name || [];
          newErrors.iamRoleExternalId = rawErrors["aws_accounts.iam_role.external_id"] || [];
          newErrors.stackInfo = rawErrors.aws_accounts || [];
        } else {
          // 500エラーなどの場合
          newErrors.base = [I18n.t("error", { message: textStatus, scope: I18N_SCOPE })];
        }
        setErrors(newErrors);
        setIsTransmitting(false);
      });
  };

  /**
   * IAMロールの外部IDが設定済みかどうかを返します。
   * 「IAMロールを作成ボタン」がクリックされている場合に、外部IDも設定済みとなります。
   */
  const isNoIamRoleExternalId = (): boolean => {
    return iamRoleExternalId == null;
  };

  /**
   * フォームが送信可能かどうかを返します。
   */
  const isSubmittable = (): boolean => {
    return (
      groupName != "" &&
      groupColor != "" &&
      cfnTemplateCacheId != null &&
      iamRoleExternalId != null &&
      stackInfo != "" &&
      awsAccountName != ""
    );
  };

  return (
    <React.StrictMode>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="panel clearfix padding30">
          <h2 className="marginB0 font-18px">
            <span className="fa fa-drivers-license-o fa-lg"></span>
            {I18n.t("group_heading", { scope: I18N_SCOPE })}
          </h2>

          <div className="ca-group-form">
            <table className="table vertical-middle ca-group-form__table">
              <tbody>
                <tr>
                  <th className="ca-group-form__table-header">
                    <label htmlFor="NewGroupWithIamRoleForm-name" style={{ fontWeight: "bold" }}>
                      {I18n.t("activerecord.attributes.group.name")}
                      <span className="required">*</span>
                    </label>
                  </th>
                  <td>
                    <GroupNameInputField
                      defaultValue={props.group.name}
                      id="NewGroupWithIamRoleForm-name"
                      onAvailabilityChecked={(isAvailable): void => {
                        // グループ名の利用可否に応じてグループ名のエラーメッセージを設定する
                        const newErrors = Object.assign({}, errors);
                        if (isAvailable) {
                          newErrors.groupName = [];
                        } else {
                          newErrors.groupName = [I18n.t("group_name_unavailable", { scope: I18N_SCOPE })];
                        }
                        setErrors(newErrors);
                      }}
                      onChange={(value): void => setGroupName(value)}
                    />
                    <ErrorArea2 errors={errors.groupName} />
                  </td>
                </tr>
                <tr>
                  <th className="ca-group-form__table-header">
                    {I18n.t("activerecord.attributes.group.color")}
                    <span className="required">*</span>
                  </th>
                  <td>
                    <GroupColorSelector2
                      onChange={(color): void => {
                        setGroupColor(color);
                      }}
                      selectedColor={groupColor}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="ca-group-form-hr"></div>

          <h2 className="marginB0 font-18px">
            <span className="fa fa-key fa-lg"></span>
            {I18n.t("aws_account_heading", { scope: I18N_SCOPE })}
          </h2>

          <div className="ca-group-form">
            <div className="marginB20 ca-callout ca-callout--warning">
              <div
                dangerouslySetInnerHTML={{ __html: I18n.t("aws_account_description_html", { scope: I18N_SCOPE }) }}
              ></div>
              <div className="marginT10">
                <ManualLink
                  linkText={I18n.t("common.manual.text.aws_account.iam_role")}
                  url={I18n.t("common.manual.url.aws_account.iam_role")}
                />
              </div>
            </div>

            <div className="marginB20">
              <ProviderAccountTypeSelectorForGroup
                defaultValue="iam_role"
                groupColor={groupColor}
                groupName={groupName}
              />
            </div>

            <div className="ca-group__aws-account-form">
              <div className="ca-group__aws-account-form__row">
                <div className="ca-group__aws-account-form__left-col">
                  <div className="ca-group__aws-account-form__heading">{I18n.t("step1", { scope: I18N_SCOPE })}</div>
                </div>
                <div className="ca-group__aws-account-form__right-col">
                  <p className="ca-group__aws-account-form__prompt">{I18n.t("step1_note", { scope: I18N_SCOPE })}</p>
                </div>
              </div>

              <div className="ca-group__aws-account-form__row">
                <div className="ca-group__aws-account-form__left-col">
                  <div className="ca-group__aws-account-form__heading">{I18n.t("step2", { scope: I18N_SCOPE })}</div>
                </div>
                <div className="ca-group__aws-account-form__right-col">
                  <IamRoleSetupButton
                    disabled={isPendingAwsRedirection}
                    errors={errors.createIamRoleButton}
                    onClick={handleClickCreateIamRoleButton}
                  />
                </div>
              </div>

              <div className="ca-group__aws-account-form__row">
                <div className="ca-group__aws-account-form__left-col">
                  <div className="ca-group__aws-account-form__heading">{I18n.t("step3", { scope: I18N_SCOPE })}</div>
                </div>
                <div className="ca-group__aws-account-form__right-col">
                  <p className="ca-group__aws-account-form__prompt">{I18n.t("step3_note", { scope: I18N_SCOPE })}</p>
                  <StackInfoInputField
                    disabled={isNoIamRoleExternalId()}
                    onChange={handleChangeStackInfoInput}
                    errors={errors.stackInfo}
                  />
                  <AwsAccountNameInputField
                    awsAccountName={awsAccountName}
                    disabled={isNoIamRoleExternalId()}
                    errors={errors.awsAccountName}
                    onChange={(name): void => {
                      setAwsAccountName(name);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="ca-btn-block marginT40">
            <div className="ca-btn-block__center">
              <SubmitButton
                disabled={!isSubmittable()}
                label={I18n.t("submit", { scope: I18N_SCOPE })}
                pending={isTransmitting}
                pendingLabel={I18n.t("pending", { scope: I18N_SCOPE })}
              />
              <ErrorArea2 errors={errors.base} />
            </div>
          </div>
        </div>
      </form>
    </React.StrictMode>
  );
};
