import EventEmitter from "events";
import PropTypes from "prop-types";
import React from "react";

import AddGroupUserDialog from "./AddGroupUserDialog.jsx";

const I18n = window.I18n;

/**
 * グループにメンバーの追加を行うダイアログを開くボタン
 * 実際の追加処理はダイアログであるAddGroupUserDialogコンポーネントで行います。
 *
 * @return {ReactElement}
 */
export default class AddGroupUserButton extends React.Component {
  /**
   * プロパティ定義を返します
   *
   * @public
   * @property {boolean} dateLabelFeatureEnabled 組織に対してカレンダー管理機能の機能フラグが有効かどうか
   * @property {number} groupId ユーザーを追加するグループのID。
   * @property {object[]} groupUsers グループに所属しているユーザーの配列。AddGroupUserDialogで利用します。
   * @property {boolean} inventoryAvailable 組織がインベントリ機能を利用可能かどうか
   * @property {boolean} policySetsAvailable 組織が構成レビュー機能を利用可能かどうか
   * @property {object} reloadEmitter グループ一覧をリロードするためのイベントを発火するためのオブジェクト。AddGroupUserDialogで利用します。
   */
  static get propTypes() {
    return {
      dateLabelFeatureEnabled: PropTypes.bool.isRequired,
      groupId: PropTypes.number.isRequired,
      groupUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
      inventoryAvailable: PropTypes.bool.isRequired,
      policySetsAvailable: PropTypes.bool.isRequired,
      reloadEmitter: PropTypes.object.isRequired,
    };
  }

  /**
   * コンストラクタ
   */
  constructor(props) {
    super(props);

    this.state = {
      showAddGroupUserDialog: false
    };

    this.emitter = new EventEmitter();
    this.emitter.on("closeDialog", () => {
      this.setState({ showAddGroupUserDialog: false });
    });
    this.emitter.on("openDialog", () => {
      this.setState({ showAddGroupUserDialog: true });
    });
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const addButtonTitle = I18n.t("javascript.group_user.add_group_user");

    return (
      <React.Fragment>
        <button
          className="btn btn-highlight float-right"
          onClick={() => {
            this.emitter.emit("openDialog");
          }}
        >
          <span className="fa fa-plus-square fa-super" />
          <span className="font-12px">{addButtonTitle}</span>
        </button>
        <AddGroupUserDialog
          dateLabelFeatureEnabled={this.props.dateLabelFeatureEnabled}
          emitter={this.emitter}
          groupId={this.props.groupId}
          groupUsers={this.props.groupUsers}
          inventoryAvailable={this.props.inventoryAvailable}
          policySetsAvailable={this.props.policySetsAvailable}
          reloadEmitter={this.props.reloadEmitter}
          showAddGroupUserDialog={this.state.showAddGroupUserDialog}
        />
      </React.Fragment>
    );
  }
}
