import React from "react";
import PropTypes from "prop-types";

import EditUserButton from "./EditUserButton.jsx";
import RemoveUserButton from "./RemoveUserButton.jsx";
import UserAvatar from "../../UserAvatar.jsx";
import UserTypeIcon from "../../UserTypeIcon.jsx";
import PermissionIcon from "../../PermissionIcon.jsx";

const I18n = window.I18n;

/**
 * グループメンバーテーブルの一行を表すコンポーネント
 *
 * グループに所属するそれぞれのユーザーの情報を描画します
 *
 */
export default class GroupUserRow extends React.Component {
  /**
   * プロパティ定義を返します
   *
   * @public
   * @property {boolean} dateLabelFeatureEnabled 組織に対してカレンダー機能の機能フラグが有効かどうか
   * @property {number} groupId メンバーを追加するグループのID
   * @property {boolean} inventoryAvailable 組織がインベントリ機能を利用可能かどうか
   * @property {boolean} policySetsAvailable 組織が構成レビュー機能を利用可能かどうか
   * @property {object} reloadEmitter グループ一覧をリロードするイベントを発火するオブジェクト。
   * @property {object} user ユーザー情報が入ったオブジェクト
   */
  static get propTypes() {
    return {
      dateLabelFeatureEnabled: PropTypes.bool.isRequired,
      groupId: PropTypes.number.isRequired,
      inventoryAvailable: PropTypes.bool.isRequired,
      policySetsAvailable: PropTypes.bool.isRequired,
      reloadEmitter: PropTypes.object.isRequired,
      user: PropTypes.object.isRequired
    };
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const user = this.props.user;

    // tooltip表示用メッセージ
    const scope = { scope: "activerecord.attributes.user_assignment.permission" };
    const dateLabelPermission = I18n.t(user.calendar_permission, scope);
    const jobPermission = I18n.t(user.job_permission, scope);
    const policySetPermission = I18n.t(user.policy_set_permission, scope);
    const InventoryPermission = I18n.t(
      'inventory.workspaces_' + (user.inventory_permission || 'permission_null'),
      { scope: "javascript.group_user" }
    );

    return (
      <tr>
        <td className="ca-group-users-data__name">
          <div className="ca-user-profile">
            <div className="ca-user-profile__image">
              <UserAvatar user={user} withTooltip={false} />
            </div>
            <div className="ca-user-profile__name">{user.full_name}</div>
          </div>
        </td>
        <td className="ca-group-users-data__user-type text-center">
          <UserTypeIcon userType={user.user_type} />
        </td>
        <td className="ca-group-users-data__department">{user.department}</td>
        <td className="ca-group-users-data__permission">
          <PermissionIcon permission={user.job_permission} permissionName={jobPermission} />
        </td>
        {this.props.policySetsAvailable && (
          <td className="ca-group-users-data__permission">
            <PermissionIcon permission={user.policy_set_permission} permissionName={policySetPermission} />
          </td>
        )}
        <td className="ca-group-users-data__permission">
          <PermissionIcon permission={user.inventory_permission} permissionName={InventoryPermission} />
        </td>
        {this.props.dateLabelFeatureEnabled &&
          <td className="ca-group-users-data__permission">
            <PermissionIcon permission={user.calendar_permission} permissionName={dateLabelPermission} />
          </td>
        }
        <td className="ca-group-users-data__registered-at">{user.registered_at}</td>
        <td className="ca-group-users-data__buttons text-right">
          <div className="inline-block">
            <EditUserButton
              dateLabelFeatureEnabled={this.props.dateLabelFeatureEnabled}
              groupId={this.props.groupId}
              inventoryAvailable={this.props.inventoryAvailable}
              policySetsAvailable={this.props.policySetsAvailable}
              reloadEmitter={this.props.reloadEmitter}
              user={user}
            />
          </div>
          <div className="inline-block">
            <RemoveUserButton
              groupId={this.props.groupId}
              reloadEmitter={this.props.reloadEmitter}
              userId={user.id}
              userName={user.full_name}
            />
          </div>
        </td>
      </tr>
    );
  }
}
