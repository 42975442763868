import React, { useState } from "react";
import dayjs from "dayjs";

import { ToggleableJob } from "./types";
import { ToggleJobModal } from "./ToggleJobModal";
import { ToggleJobUnavailableModal } from "./ToggleJobUnavailableModal";
import { ToggleJobActiveButton } from "./ToggleJobActiveButton";

export const ToggleJobActiveButtonContainer: React.FC<{
  job: ToggleableJob;
}> = (props) => {
  const I18n = window.I18n; // i18n-js

  const [dialogOpened, setDialogOpened] = useState(false);

  /**
   * 予定日時リストに未来の日時が含まれていないスケジュールトリガージョブかどうかを返します。
   *
   */
  const isExpiredScheduleTriggerJob = (): boolean => {
    return props.job.rule_type === "schedule" && !props.job.active && !props.job.future_times_in_timetable;
  };

  /**
   * 開始時刻を過ぎている一回のみ実行の cron ジョブかどうかを返します。
   */
  const isOneTimeCronJobOverStartTime = (): boolean => {
    return !props.job.active && props.job.expired_one_time_cron_job;
  };

  /**
   * 有効期間終了日を既に過ぎているかどうかを返します。
   */
  const isCurrentDateOverExpirationDate = (): boolean => {
    if (!props.job.active && props.job.expiration_date) {
      // TODO: job.expiration_date をジョブのタイムゾーンにおける日付(の最初の時点)として解釈する必要があるか調査する
      return dayjs().startOf("day").isAfter(props.job.expiration_date);
    }
    return false;
  };

  /**
   * 次のジョブ実行が有効期間終了日を超えるかどうかを返します。
   *
   */
  const isNextOccurrenceOverExpirationDate = (): boolean => {
    if (!props.job.active && props.job.expiration_date && props.job.next_occurrence) {
      // TODO: job.expiration_date をジョブのタイムゾーンにおける日付(の最初の時点)として解釈する必要があるか調査する
      return dayjs(props.job.next_occurrence).startOf("day").isAfter(props.job.expiration_date);
    }
    return false;
  };

  /**
   * ON/OFFボタン押下時のダイアログをジョブの状態に応じて切り替える
   */
  const toggleModal: JSX.Element = (() => {
    // スケジュールトリガーで未来の実行予定がない場合
    if (isExpiredScheduleTriggerJob()) {
      return (
        <ToggleJobUnavailableModal
          job={props.job}
          dialogMessage={I18n.t("javascript.job_table.no_future_times_in_schedule_timetable")}
          showDialog={dialogOpened}
          onOkButtonClick={() => setDialogOpened(false)}
        />
      );
    }

    // - 1回のみのcronトリガーで実行日時を超えている場合
    // - 有効期限切れ
    // - 次回実行日時が有効期限切れ
    if (isOneTimeCronJobOverStartTime() || isCurrentDateOverExpirationDate() || isNextOccurrenceOverExpirationDate()) {
      let dialogMessage = "";
      if (isOneTimeCronJobOverStartTime()) {
        dialogMessage = I18n.t("javascript.job_table.prevent_notification");
      } else if (isCurrentDateOverExpirationDate()) {
        dialogMessage = I18n.t("javascript.job_table.current_date_over_expiration_date");
      } else if (isNextOccurrenceOverExpirationDate()) {
        dialogMessage = I18n.t("javascript.job_table.next_occurrence_over_expiration_date");
      }
      return (
        <ToggleJobUnavailableModal
          job={props.job}
          dialogMessage={dialogMessage}
          showDialog={dialogOpened}
          onOkButtonClick={() => setDialogOpened(false)}
        />
      );
    }

    // 正常系
    return (
      <ToggleJobModal
        job={props.job}
        dialogMessage={I18n.t("javascript.job_table.toggle_confirmation")}
        showDialog={dialogOpened}
        onCancelButtonClick={() => setDialogOpened(false)}
      />
    );
  })();

  return (
    <>
      <ToggleJobActiveButton jobActive={props.job.active} onClick={() => setDialogOpened(true)} />
      {toggleModal}
    </>
  );
};
