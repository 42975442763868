import React from "react";
import PropTypes from "prop-types";

import Constants from "../../Constants.js";
import Pagination from "../../Pagination.jsx";

import EbsBackupVolumeTable from "./EbsBackupVolumeTable.jsx";

const $ = window.jQuery;
const I18n = window.I18n;

/**
 * バックアップ推奨ボリューム一覧を表示するテーブルを含むコンポーネントです
 */
export default class EbsBackupNotExistsTableContainer extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @property {string} awsAccountName AWSアカウント名
   * @property {boolean} isAwsAccountUnitError AWSアカウント単位のエラーが発生している場合
   * @property {string} lastCheckDate バックアップ推奨ボリュームの最終チェック日時
   * @property {string} source バックアップ推奨ボリューム一覧を取得するURL
   */
  static get propTypes() {
    return {
      awsAccountName: PropTypes.string.isRequired,
      isAwsAccountUnitError: PropTypes.bool.isRequired,
      lastCheckDate: PropTypes.string.isRequired,
      source: PropTypes.string,
    };
  }

  /**
   * コンポーネントを初期化します
   *
   * @public
   * @property {Object} props プロパティ
   */
  constructor(props) {
    super(props);
    this.state = this.initialState();

    this.lastCheckDateText = I18n.t("javascript.ebs_backup_not_exists_results.last_check_date");

    this.handlePaginationPageClick = this.handlePaginationPageClick.bind(this);
    this.handleSortLinkClick = this.handleSortLinkClick.bind(this);
  }

  /**
   * コンポーネントがマウントされた際の処理を行います。
   *
   * @public
   */
  componentDidMount() {
    this.loadEbsBackupCheckResults();
  }

  /**
   * サーバーから取得したバックアップ推奨ボリュームを
   * 現在のページに表示する範囲の後処理だけを返します。
   *
   * @return {array}
   */
  getEbsBackupCheckResultsForCurrentPage() {
    let results = this.state.ebsBackupCheckResults;

    // バックアップ推奨ボリュームを現在のページに表示する範囲だけに絞り込む
    const start = this.state.perPage * (this.state.currentPage - 1);
    const end = this.state.perPage * this.state.currentPage;

    return results.slice(start, end);
  }

  /**
   * 現在のソート方向を逆転したものを返します。
   * コンポーネントの状態は変更しません。
   *
   * @return {string}
   */
  getInvertedSortDirection() {
    if (this.state.sortDirection == Constants.DIRECTION.ASC) {
      return Constants.DIRECTION.DESC;
    }
    return Constants.DIRECTION.ASC;
  }

  /**
   * ページングナビゲーションのページ番号がクリックされた際の処理を実行します。
   *
   * @param {number} page ページ番号
   */
  handlePaginationPageClick(page) {
    this.setState({ currentPage: page }, () => {
      this.saveSettings();
      document.getElementById('main-panel').scrollIntoView();
    });
  }

  /**
   * 特定のカラムでソートするリンクがクリックされた際の処理を実行します
   *
   * @property {string} column クリックされたカラムの識別子
   */
  handleSortLinkClick(column) {
    let newState = {
      currentPage: 1
    };

    if (column == this.state.sortColumn) {
      newState.sortDirection = this.getInvertedSortDirection();
    } else {
      newState.sortColumn = column;
      newState.sortDirection = Constants.DIRECTION.ASC;
    }

    this.setState(newState, () => {
      this.loadEbsBackupCheckResults();
      this.saveSettings();
    });
  }

  /**
   * 初期ステートを作成して返します
   */
  initialState() {
    // デフォルト値(Cookieに設定が存在しない場合はこれらの値が使われる)
    let currentPage = 1;
    let perPage = 30;
    let sortColumn = "snapshot_start_time";
    let sortDirection = Constants.DIRECTION.ASC;

    // Cookieに設定がある場合はその値をロードする
    $.cookie.json = true;
    const settings = $.cookie(Constants.COOKIE.EBS_BACKUP_NOT_EXISTS_SETTINGS);
    if ($.isPlainObject(settings)) {
      if (settings.current_page != null) {
        currentPage = Number(settings.current_page);
      }
      if (settings.sort_column != null) {
        sortColumn = settings.sort_column;
      }
      if (settings.sort_direction != null) {
        sortDirection = settings.sort_direction;
      }
    }

    return {
      currentPage: currentPage,
      ebsBackupCheckResults: [],
      errorMessage: null,
      perPage: perPage,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      totalItems: 0
    };
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    // 現在のページに表示するバックアップ推奨ボリュームだけを取得する
    const currentPageEbsBackupCheckResults = this.getEbsBackupCheckResultsForCurrentPage();

    let pagination = null;
    if (currentPageEbsBackupCheckResults.length > 0) {
      pagination = (
        <Pagination
          totalItems={this.state.totalItems}
          perPage={this.state.perPage}
          currentPage={this.state.currentPage}
          callback={this.handlePaginationPageClick}
        />
      );
    }

    return (
      <React.StrictMode>
        <React.Fragment>
          <div className="table-actions">
            <div className="ca-backup-check-volumes-last-check">
              <span className="ca-backup-check-volumes-last-check__text">
                {this.lastCheckDateText}
              </span>
              <span className="ca-backup-check-volumes-last-check__datetime marginL20">
                {this.props.lastCheckDate}
              </span>
            </div>
          </div>
          <div className="ca-backup-check-volumes-last-check__not-exists__hint">
            <i className="fa fa-info-circle marginR5"></i>
            {I18n.t("javascript.ebs_backup_not_exists_results.volume_list_hint")}
          </div>
          <EbsBackupVolumeTable
            callback={this.handleSortLinkClick}
            ebsBackupCheckResults={currentPageEbsBackupCheckResults}
            errorMessage={this.state.errorMessage}
            isAwsAccountUnitError={this.props.isAwsAccountUnitError}
            sortColumn={this.state.sortColumn}
            sortDirection={this.state.sortDirection}
          />
          {pagination}
        </React.Fragment>
      </React.StrictMode>
    );
  }

  /**
   * サーバーからバックアップ推奨ボリューム一覧のJSONを取得して、
   * コンポーネントの状態を更新します
   *
   * @private
   */
  loadEbsBackupCheckResults() {
    // バックアップ推奨ボリューム一覧のJSONを取得する際のソート条件を 'name asc' のような文字列として生成する
    const orderParam = `${this.state.sortColumn} ${this.state.sortDirection}`;

    // バックアップ推奨ボリューム一覧のJSONを取得するためのURL
    const jsonUrl = `${this.props.source}?order=${orderParam}`;

    $.ajax({
      dataType: "json",
      type: "GET",
      url: jsonUrl
    })
      .done(data => {
        let newState = {
          errorMessage: null,
          ebsBackupCheckResults: data.ebs_backup_not_exists_results,
          totalItems: data.ebs_backup_not_exists_results.length
        };

        // 現在ページが1以外の場合は、現在ページが超過しないかチェックする
        if (this.state.currentPage > 1) {
          // 取得したジョブの数が現在ページに表示する件数よりも少ない場合は
          // 現在ページを1にする(Cookiesに設定が保存されていた場合に対応するため)
          const idxOfCurrentResults =
            (this.state.currentPage - 1) * this.state.perPage + 1;
          if (data.total < idxOfCurrentResults) {
            newState.currentPage = 1;
          }
        }

        this.setState(newState);
      })
      .fail(() => {
        this.setState({
          errorMessage: Constants.DATA_LOAD_ERROR_MESSAGE,
          ebsBackupCheckResults: []
        });
      });
  }

  /**
   * 現在の設定をCookieに保存します。
   */
  saveSettings() {
    // 選択されたページをCookieに保存する
    $.cookie(Constants.COOKIE.EBS_BACKUP_NOT_EXISTS_SETTINGS, {
      current_page: this.state.currentPage,
      per_page: this.state.perPage,
      search_input: this.state.searchInput,
      sort_column: this.state.sortColumn,
      sort_direction: this.state.sortDirection
    });
  }
}
