import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import UserAvatar from '../UserAvatar.jsx';
import UserTypeIcon from '../UserTypeIcon.jsx';

const I18n = window.I18n; // i18n-js

/**
 * ユーザーテーブル行
 *
 * ユーザー一覧テーブルに含まれる行を表示するコンポーネントで、以下の責務を持ちます。
 *
 * - ユーザーテーブル行の表示
 */
export default class UserTableRow extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {?string} editUserPath ユーザー編集ボタンのリンク先URLパス(ID部分は":id"とする)
   * @property {Object} user 表示するユーザー
   */
  static get propTypes() {
    return({
      editUserPath: PropTypes.string,
      user: PropTypes.object.isRequired,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.i18n_text = {
      edit: I18n.t('edit', { scope: Constants.I18N_SCOPE }),
    };
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    const user = this.props.user;
    // editUserPathがnullの場合は編集ページへのリンクが行われなくなる
    const editable = this.props.editUserPath !== null;
    const editUserPath = editable ? this.props.editUserPath.replace(':id', user.id) : null;

    return(
      <tr>
        <td className="ca-user-table__body-column">
          <div className="ca-user-profile">
            <div className="ca-user-profile__image">
              {editable
                ? <a href={editUserPath} style={{textDecoration: 'none'}}>
                    <UserAvatar user={user} withTooltip={false} />
                  </a>
                : <UserAvatar user={user} withTooltip={false} />
              }
            </div>
            <div className="ca-user-profile__name">
              {editable
                ? <a href={editUserPath}>
                    <span title={user.full_name}>{user.full_name}</span>
                  </a>
                : <span title={user.full_name}>{user.full_name}</span>
              }
            </div>
          </div>
        </td>
        <td className="ca-user-table__body-column text-center">
          <UserTypeIcon userType={user.user_type} />
        </td>
        <td className="ca-user-table__body-column">
          <span title={user.department}>
            {user.department}
          </span>
        </td>
        <td className="ca-user-table__body-column">
          <span title={user.email}>
            {user.email}
          </span>
        </td>
        <td className="ca-user-table__body-column">
          <time dateTime={user.created_at_iso8601}>
            {user.created_at}
          </time>
        </td>
        <td className="ca-user-table__body-column text-right">
          {editable &&
            <a
              className="btn"
              data-placement="bottom"
              data-toggle="tooltip"
              href={editUserPath}
              title={this.i18n_text.edit}
            >
              <i className="fa fa-pencil"></i>
              <span className="sr-only">{this.i18n_text.edit}</span>
            </a>
          }
        </td>
      </tr>
    );
  }
}
