import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import ErrorMessages from '../ErrorMessages.jsx';

const I18n = window.I18n; // i18n-js
const I18N_SCOPE = `${Constants.I18N_SCOPE}.policy_set_name_field`;

// ポリシーセット名入力フィールド
//
// 以下の責務を持ちます。
//
// - ポリシーセット名入力欄の表示
// - エラーメッセージの表示
// - 変更された値の EventEmitter への通知
//   Constants.EVENT_CHANGE_POLICY_SET_NAME をイベント名として変更後の値を通知します
//
class PolicySetNameField extends React.Component {
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
      initialValue: PropTypes.string.isRequired
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.initialValue
    };
    this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.errors.join() !== this.props.errors.join()
        || nextState.value !== this.state.value
    );
  }

  /**
   * エラーがあるかどうかを返します。
   *
   * @return {boolean}
   */
  hasError() {
    return this.props.errors.length > 0;
  }

  render() {
    const errorClasses = this.hasError() ? 'has-error' : '';
    const label = I18n.t('activerecord.attributes.policy_set.name');
    const help = I18n.t('help', { scope: I18N_SCOPE });

    return(
      <div className={`form-group ${errorClasses}`}>
        <div className="row">
          <div className="col-xs-6">
            <label className="control-label" htmlFor="policy_set-name">
              {label}
            </label>
            <input
              id="policy_set-name"
              className="form-control"
              onChange={this.handleChange}
              required={true}
              type="text"
              value={this.state.value}
            />
            <p className="help-block">
              {help}
            </p>
            <ErrorMessages messages={this.props.errors} />
          </div>
        </div>
      </div>
    );
  }

  handleChange(event) {
    const value = event.target.value;
    this.setState({ value: value }, () => {
      this.props.emitter.emit(Constants.EVENT_CHANGE_POLICY_SET_NAME, value);
    });
  }
}

export default PolicySetNameField;
