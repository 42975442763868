import React from "react";

import SortableTableHeaderCell from "../../SortableTableHeaderCell";

import { GoogleCloudAccount } from "./types";
import { DeleteButton } from "./DeleteButton";
import { EditButton } from "./EditButton";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.group_google_cloud_account.table";

type Props = {
  accounts: GoogleCloudAccount[];
  errorMessage: string;
  onSortClick: (column: string) => void;
  reload: () => void;
  sortColumn: string;
  sortDirection: string;
};

/**
 * グループ設定 Google Cloudアカウント一覧テーブル
 */
const Table: React.FC<Props> = (props) => {
  const renderButtons = (account: GoogleCloudAccount): React.ReactElement => {
    return (
      <React.Fragment>
        <EditButton account={account} onUpdated={props.reload} />
        <DeleteButton account={account} onDeleted={props.reload} />
      </React.Fragment>
    );
  };

  const renderAccount = (account: GoogleCloudAccount): React.ReactElement => {
    return (
      <tr key={account.id}>
        <td style={{ whiteSpace: "nowrap" }}>{account.id}</td>
        <td>
          {account.name}
          <br />
          <span className="ca-aws-account__aws-accounts-table__account-id">({account.identity})</span>
        </td>
        <td>{account.email}</td>
        <td className="text-center">{account.jobCount}</td>
        <td>
          <div className="ca-aws-account__aws-accounts-table__buttons">{renderButtons(account)}</div>
        </td>
      </tr>
    );
  };

  const renderAccounts = (accounts: GoogleCloudAccount[]): React.ReactElement[] => {
    if (props.errorMessage !== "") {
      return [
        <tr key="0">
          <td colSpan={5}>Error: {props.errorMessage}</td>
        </tr>,
      ];
    }

    if (accounts.length === 0) {
      return [
        <tr key="0">
          <td colSpan={5}>{I18n.t("no_google_cloud_accounts", { scope: I18N_SCOPE })}</td>
        </tr>,
      ];
    }

    return accounts.map(renderAccount);
  };

  return (
    <React.StrictMode>
      <table className="table vertical-middle ca-table-header-no-bordered">
        <thead>
          <tr>
            <th className="ca-aws-account__aws-accounts-table__button-cell-header ca-aws-account__aws-accounts-table__th-narrow">
              {I18n.t("id", { scope: I18N_SCOPE })}
            </th>
            <SortableTableHeaderCell
              column="google_cloud_accounts.name"
              active={props.sortColumn == "google_cloud_accounts.name"}
              callback={props.onSortClick}
              direction={props.sortDirection}
              text={I18n.t("account_name", { scope: I18N_SCOPE })}
              caption={I18n.t("account_identifier", { scope: I18N_SCOPE })}
              classNameForHeaderCell="ca-group-table-header--clickable"
            />
            <th className="ca-aws-account__aws-accounts-table__button-cell-header">
              {I18n.t("email", { scope: I18N_SCOPE })}
            </th>
            <SortableTableHeaderCell
              column="trigger_jobs_count"
              active={props.sortColumn == "trigger_jobs_count"}
              callback={props.onSortClick}
              direction={props.sortDirection}
              text={I18n.t("job_count", { scope: I18N_SCOPE })}
              textAlignCenter={true}
              classNameForHeaderCell="ca-group-table-header--clickable ca-aws-account__aws-accounts-table__th-narrow"
            />
            <th className="ca-aws-account__aws-accounts-table__button-cell-header ca-aws-account__aws-accounts-table__th-narrow"></th>
          </tr>
        </thead>
        <tbody>{renderAccounts(props.accounts)}</tbody>
      </table>
    </React.StrictMode>
  );
};

export { Table };
