import React from "react";
import PropTypes from "prop-types";

import EventEmitter from "events";

import Constants from "./Constants.js";
import PostProcessForm from "./PostProcessForm.jsx";

const I18n = window.I18n; // i18n-js
const jQuery = window.jQuery;

/**
 * 後処理フォームコンテナ
 * 後処理フォームに関するロジックについての責務を持ちます。
 * フォームのルック＆フィールに関する責務は子コンポーネントに委譲します。
 **/
class PostProcessFormContainer extends React.Component {
  /**
   * デフォルトのプロパティ値を返します。
   * React内部から呼び出されます。
   *
   * @return {object}
   */
  static get defaultProps() {
    return {
      dialogEventEmitter: null,
      group: null,
      groups: null,
      handleUpdatePostProcesses: null,
      isDialog: false,
      postProcess: null,
    };
  }

  /**
   * プロパティのバリデーション定義を返します。
   * React内部から呼び出されます。
   *
   * @return {object}
   * @property {string} defaultLanguage - 言語設定欄がある場合のデフォルトの言語(例 "ja")
   * @property {string} defaultTimeZone - タイムゾーン設定がある場合のデフォルトのタイムゾーン(例 "Tokyo")
   * @property {Object} dialogEventEmitter - ダイアログモードの際、ダイアログの状態を制御するEventEmitterオブジェクト
   * @property {Object} group - グループオブジェクト
   * @property {Array<Number>} groupIds - グループID一覧
   * @property {Array<Array>} groups - グループ一覧
   * @property {function} handleUpdatePostProcesses - ダイアログモードの際、後処理の更新後に呼び出されるコールバック関数
   * @property {boolean} isDialog - ダイアログモードとして表示するかどうか
   * @property {boolean} isSlackIntegrated - Slackが外部サービスとして連携済みかどうか
   * @property {boolean} isUserCanManageIntegrations - ユーザーが外部サービス連携を管理できるかどうか
   * @property {string} loadingImagePath - 通信中のインジケーター画像のパス
   * @property {Object} postProcess - 編集する後処理。省略すると新規作成となる
   * @property {Array<Array>} regions - リージョンの選択肢
   * @property {Array<Array>} services - サービスの選択肢
   * @property {string} sqsQueuesPath - SQSキュー名を取得するためのパス
   * @property {Array<Array>} timeZones - タイムゾーンの選択肢
   * @property {string} url - XHRの送信先URL
   */
  static get propTypes() {
    return {
      defaultLanguage: PropTypes.string.isRequired,
      defaultTimeZone: PropTypes.string.isRequired,
      dialogEventEmitter: PropTypes.object,
      group: PropTypes.object,
      groupIds: PropTypes.arrayOf(PropTypes.number),
      groups: PropTypes.arrayOf(PropTypes.array),
      handleUpdatePostProcesses: PropTypes.func,
      isDialog: PropTypes.bool,
      isSlackIntegrated: PropTypes.bool.isRequired,
      isUserCanManageIntegrations: PropTypes.bool.isRequired,
      loadingImagePath: PropTypes.string.isRequired,
      postProcess: PropTypes.object,
      regions: PropTypes.arrayOf(PropTypes.array).isRequired,
      services: PropTypes.arrayOf(PropTypes.array).isRequired,
      sqsQueuesPath: PropTypes.string.isRequired,
      timeZones: PropTypes.arrayOf(PropTypes.array).isRequired,
      url: PropTypes.string.isRequired,
    };
  }

  /**
   * コンストラクタ。
   * React内部から呼び出されます。
   */
  constructor(props) {
    super(props);

    if (props.isDialog) {
      this.heading = I18n.t("service_selection_field.post_process_form_title", { scope: Constants.I18N_SCOPE });
    }

    this.bindEventHandlers();
    this.initEventEmitter();
    this.state = this.initialState(props);
  }

  /**
   * コンポーネントのマウント後、初回の描画が完了した後にReact内部から呼び出されます。
   */
  componentDidMount() {
    if (this.props.postProcess) {
      // 編集する後処理が指定されている場合はAWSアカウントの一覧をサーバーから取得する
      this.getAwsAccounts(true);
    }
  }

  /**
   * イベントハンドラ用関数を初期化します。
   *
   * 各イベントハンドラ関数の内部で、コンポーネント自身を this として参照できるようにします。
   */
  bindEventHandlers() {
    this.handleChangeFormValue = this.handleChangeFormValue.bind(this);
    this.handleChangeGroup = this.handleChangeGroup.bind(this);
    this.handleChangeService = this.handleChangeService.bind(this);
    this.handleChangeSqsAwsAccountId = this.handleChangeSqsAwsAccountId.bind(this);
    this.handleChangeSqsRegion = this.handleChangeSqsRegion.bind(this);
    this.handleSubmitFormEvent = this.handleSubmitFormEvent.bind(this);
  }

  /**
   * EventEmitterを初期化します。
   *
   * 各コンポーネント内で発生するイベントとイベントハンドラ関数の割り当てを行います。
   * 生成されたEventEmitterオブジェクトは this.emitter にアサインされます。
   */
  initEventEmitter() {
    this.emitter = new EventEmitter();
    this.emitter.on(Constants.EVENT_CHANGE_FORM_VALUE, this.handleChangeFormValue);
    this.emitter.on(Constants.EVENT_CHANGE_GROUP, this.handleChangeGroup);
    this.emitter.on(Constants.EVENT_CHANGE_SERVICE, this.handleChangeService);
    this.emitter.on(Constants.EVENT_CHANGE_SQS_AWS_ACCOUNT_ID, this.handleChangeSqsAwsAccountId);
    this.emitter.on(Constants.EVENT_CHANGE_SQS_REGION, this.handleChangeSqsRegion);
    this.emitter.on(Constants.EVENT_SUBMIT_FORM, this.handleSubmitFormEvent);
  }

  /**
   * ステートの初期値を返します。
   *
   * @param {object} props
   */
  initialState(props) {
    let state = {
      awsAccountPending: false,
      awsAccounts: [],
      commonErrors: [],
      emailRecipient: "",
      emailRecipientErrors: [],
      groupId: "none",
      groupErrors: [],
      pending: false, // フォームを送信中かどうか
      postProcessName: "",
      postProcessNameErrors: [],
      service: Constants.SERVICE_EMAIL,
      slackChannelName: "",
      slackChannelNameErrors: [],
      slackLanguage: props.defaultLanguage,
      slackLanguageErrors: [],
      slackTimeZone: props.defaultTimeZone,
      slackTimeZoneErrors: [],
      sqsAwsAccountId: "",
      sqsAwsAccountIdErrors: [],
      sqsQueue: "",
      sqsQueueErrors: [],
      sqsRegion: "",
      sqsRegionErrors: [],
      webhookAuthorizationHeader: "",
      webhookAuthorizationHeaderErrors: [],
      webhookUrl: "",
      webhookUrlErrors: [],
    };

    // プロパティに編集対象の後処理が設定されている場合は、その値をロードします
    if (props.postProcess) {
      const postProcess = props.postProcess;
      state.service = postProcess.service;
      state.postProcessName = postProcess.name;

      if (postProcess.group_id) {
        state.groupId = postProcess.group_id.toString();
      } else {
        state.groupId = "";
      }

      switch (postProcess.service) {
        case Constants.SERVICE_EMAIL:
          state.emailRecipient = postProcess.parameters.recipients[0];
          break;
        case Constants.SERVICE_SLACK:
          state.slackChannelName = postProcess.parameters.channel_name;
          state.slackLanguage = postProcess.parameters.language;
          state.slackTimeZone = postProcess.parameters.time_zone;
          // Slack後処理の作成後にSlackとの連携が解除された場合、編集フォームでは
          // 送信ボタンが無効となります。
          // しかし、その理由が明示されないと不便なため、フォームを表示した時点で
          // サービスの選択状況に関するエラーメッセージが表示されるようにします。
          // これにより、Slackとの連携を行うことを促すエラーメッセージがサービス選択欄に
          // 表示されます。
          state.commonErrors = this.checkErrorsForService(state.groupId, Constants.SERVICE_SLACK);
          break;
        case Constants.SERVICE_SQS:
          state.sqsAwsAccountId = postProcess.parameters.aws_account_id.toString();
          state.sqsQueue = postProcess.parameters.queue;
          state.sqsRegion = postProcess.parameters.region;
          break;
        case Constants.SERVICE_WEBHOOK:
          state.webhookAuthorizationHeader = postProcess.parameters.authorization_header;
          state.webhookUrl = postProcess.parameters.url;
          break;
      }
    }

    return state;
  }

  /**
   * コンポーネントを描画します。
   * React内部から呼び出されます。
   */
  render() {
    return (
      <React.StrictMode>
        <PostProcessForm
          {...this.state}
          classesForPostProcessNameField={this.props.isDialog ? "col-xs-8" : "col-xs-6"}
          emitter={this.emitter}
          group={this.props.group}
          groupIds={this.props.groupIds}
          groups={this.props.groups}
          heading={this.props.isDialog ? this.heading : null}
          isSlackIntegrated={this.props.isSlackIntegrated}
          loadingImagePath={this.props.loadingImagePath}
          newRecord={this.isNewRecord()}
          regions={this.props.regions}
          services={this.props.services}
          sqsQueuesPath={this.props.sqsQueuesPath}
          timeZones={this.props.timeZones}
        />
      </React.StrictMode>
    );
  }

  /**
   * 現在選択されているグループで利用可能なAWSアカウントをサーバーから取得し、
   * ステートを更新します。
   *
   * 通信中は awsAccountPending ステートが true となります。
   *
   * @param {boolean} keepAwsAccount AWSアカウントの選択状態を維持するかどうか(編集時の初期表示用)
   */
  getAwsAccounts(keepAwsAccount = false) {
    if (this.state.groupId == "" || this.state.groupId == "none") {
      this.setState({
        sqsAwsAccountId: "",
        awsAccounts: [],
      });

      return;
    }

    jQuery
      .ajax({
        url: "/aws_accounts",
        method: "GET",
        data: { group_id: this.state.groupId },
        dataType: "json",
        beforeSend: () => {
          this.setState({
            awsAccountPending: true,
          });
        },
      })
      .done((data) => {
        const awsAccounts = data.aws_accounts.map((awsAccount) => [awsAccount.name, awsAccount.id]);
        let newState = {
          awsAccount: "",
          awsAccountPending: false,
          awsAccounts: awsAccounts,
        };
        if (keepAwsAccount && awsAccounts.indexOf(this.state.awsAccount) > -1) {
          newState.awsAccount = this.state.awsAccount;
        }
        this.setState(newState);
      })
      .fail(() => {
        this.setState({
          awsAccount: "",
          awsAccountPending: false,
          awsAccounts: [],
        });
        alert("Couldn't fetch AWS accounts.");
      });
  }

  /**
   * フォームの値が変更された際に呼び出されるイベントハンドラ。
   *
   * @param {string} key - 更新するステートのキー
   * @param {object} value - ステートに設定する値
   */
  handleChangeFormValue(key, value) {
    let newState = {};
    newState[key] = value;
    this.setState(newState);
  }

  /**
   * グループが変更された際に呼び出されるイベントハンドラ。
   *
   * @param {object} value - 新しい値
   */
  handleChangeGroup(value) {
    this.setState(
      {
        commonErrors: this.checkErrorsForService(value, this.state.service),
        groupId: value,
        sqsQueue: "",
      },
      this.getAwsAccounts
    );
  }

  /**
   * サービスが変更された際に呼び出されるイベントハンドラ。
   *
   * @param {object} value - 新しい値
   */
  handleChangeService(value) {
    this.setState({
      commonErrors: this.checkErrorsForService(this.state.groupId, value),
      service: value,
      sqsQueue: "",
    });
  }

  /**
   * サービスの選択状況についてエラーがあるかどうかをチェックして、エラーメッセージを返します。
   *
   * ここで扱うエラーは、以下のような「後処理において、グループIDとサービスの組み合わせ」が
   * 妥当化どうか、の判断に関わるものです。
   *
   * - グループ共通後処理では利用できないサービスが指定されていないか
   * - Slackとの外部サービス連携が未設定の状態でサービスとしてSlackが指定されていないか
   *
   * @param {string} groupId - グループID。グループ共通後処理の場合は空文字列。
   * @param {string} service - サービス識別子。
   * @return {string[]} エラーメッセージの配列。エラーがない場合は空の配列。
   */
  checkErrorsForService(groupId, service) {
    const errors = [];

    if (!this.isValidSharedService(groupId, service)) {
      // グループ共通後処理として利用できないサービスが選択された場合
      errors.push(I18n.t("errors.messages.invalid_service_for_shared_post_process"));
    } else if (service == Constants.SERVICE_SLACK && !this.props.isSlackIntegrated) {
      // Slackとの外部サービス連携が設定されていない状態でSlackが選択された場合
      if (this.props.isUserCanManageIntegrations) {
        errors.push(I18n.t("javascript.post_process_form.slack_not_connected_with_link_html"));
      } else {
        errors.push(I18n.t("javascript.post_process_form.slack_not_connected"));
      }
    }

    return errors;
  }

  /**
   * SQS用AWSアカウントIDの値が変更された際に呼び出されるイベントハンドラ。
   *
   * @param {object} value - 新しい値
   */
  handleChangeSqsAwsAccountId(value) {
    this.setState({ sqsAwsAccountId: value, sqsQueue: "" });
  }

  /**
   * SQS用リージョンの値が変更された際に呼び出されるイベントハンドラ。
   *
   * @param {object} value - 新しい値
   */
  handleChangeSqsRegion(value) {
    this.setState({ sqsRegion: value, sqsQueue: "" });
  }

  /**
   * 後処理の作成時に呼び出されるイベントハンドラ。
   */
  handleSubmitFormEvent() {
    // ステートを通信中にしてからAjaxを実行します
    this.setState({ pending: true }, this.postRequest);
  }

  /**
   * 対象の後処理が新しいレコードかどうかを返します。
   *
   * @return {boolean}
   */
  isNewRecord() {
    return this.props.postProcess && this.props.postProcess.id ? false : true;
  }

  /**
   * group と service の組み合わせが妥当かどうかを返します。
   *
   * @param {string} group
   * @param {string} service
   * @return {boolean}
   */
  isValidSharedService(group, service) {
    if (group == "" && service !== Constants.SERVICE_EMAIL) {
      return false;
    }

    return true;
  }

  /**
   * 後処理を作成または更新するPOSTリクエストを行います。
   */
  postRequest() {
    let data = {
      post_process: {
        service: this.state.service,
        name: this.state.postProcessName,
        group_id: this.state.groupId,
      },
    };

    // 選択されたサービスに応じてステートの内容をリクエストパラメーターに設定する
    switch (this.state.service) {
      case Constants.SERVICE_EMAIL:
        data.post_process.email_recipient = this.state.emailRecipient;
        break;
      case Constants.SERVICE_SLACK:
        data.post_process.slack_channel_name = this.state.slackChannelName;
        data.post_process.slack_language = this.state.slackLanguage;
        data.post_process.slack_time_zone = this.state.slackTimeZone;
        break;
      case Constants.SERVICE_SQS:
        data.post_process.sqs_aws_account_id = this.state.sqsAwsAccountId;
        data.post_process.sqs_region = this.state.sqsRegion;
        data.post_process.sqs_queue = this.state.sqsQueue;
        break;
      case Constants.SERVICE_WEBHOOK:
        data.post_process.webhook_authorization_header = this.state.webhookAuthorizationHeader;
        data.post_process.webhook_url = this.state.webhookUrl;
        break;
      default:
        throw new Error(`Couldn't create a new post process: Unknown service '${this.state.service}' specified.`);
    }

    if (this.isNewRecord()) {
      data._method = "post";
      if (this.props.isDialog) {
        // ダイアログモードの場合はサーバー側でflashメッセージを設定しないようにする
        data.no_flash = true;
      }
    } else {
      data._method = "patch";
    }

    jQuery
      .ajax({
        url: this.props.url,
        method: "POST",
        data: data,
        dataType: "json",
        beforeSend: () => {
          this.setState({
            commonErrors: [],
            groupErrors: [],
            postProcessNameErrors: [],
            emailRecipientErrors: [],
            slackChannelNameErrors: [],
            slackLanguageErrors: [],
            slackTimeZoneErrors: [],
            sqsAwsAccountIdErrors: [],
            sqsRegionErrors: [],
            sqsQueueErrors: [],
            webhookAuthorizationHeaderErrors: [],
            webhookUrlErrors: [],
          });
        },
      })
      .always(() => {
        this.setState({ pending: false });
      })
      .done((data) => {
        if (this.props.isDialog) {
          // ダイアログモードの場合はリクエスト成功時にモーダルウィンドウを閉じる
          if (this.props.dialogEventEmitter) {
            this.props.dialogEventEmitter.emit("closeDialog");
          }
          this.refreshPostProcesses();
        } else {
          // ダイアログモードでない場合はリクエスト成功時にレスポンスで指定されたURLへ遷移する
          window.location = data.url;
        }
      })
      .fail((jqXHR) => {
        // リクエストが失敗した場合はレスポンスからエラーメッセージを取り出してステートに設定する
        // errors.XXX が存在する場合、その値は常に Array であることに注意
        if (jqXHR.responseJSON) {
          const errors = jqXHR.responseJSON.errors;
          this.setState({
            commonErrors: errors.base || [],
            groupErrors: errors.group || [],
            postProcessNameErrors: errors.name || [],
            emailRecipientErrors: errors.email_recipient || [],
            slackChannelNameErrors: errors.slack_channel_name || [],
            slackLanguageErrors: errors.slack_language || [],
            slackTimeZoneErrors: errors.slack_time_zone || [],
            sqsAwsAccountIdErrors: errors.sqs_aws_account_id || [],
            sqsRegionErrors: errors.sqs_region || [],
            sqsQueueErrors: errors.sqs_queue || [],
            webhookAuthorizationHeaderErrors: errors.webhook_authorization_header || [],
            webhookUrlErrors: errors.webhook_url || [],
          });
        } else {
          const status = jqXHR.status;
          const statusText = jqXHR.statusText;
          this.setState({
            commonErrors: [`${status}: ${statusText}`],
          });
        }
      });
  }

  refreshPostProcesses() {
    jQuery
      .ajax({
        url: this.props.url,
        method: "GET",
        dataType: "json",
      })
      .done((data) => {
        if (this.props.handleUpdatePostProcesses) {
          this.props.handleUpdatePostProcesses(data.post_processes);
        }
      })
      .fail(() => {
        alert("Couldn't refresh post processes.");
      });
  }
}

export default PostProcessFormContainer;
