import React from "react";
import PropTypes from "prop-types";

import GroupSelector from "./GroupSelector.jsx";

import { AccessKeyIdInputField } from "./aws_account/AccessKeyIdInputField.jsx";
import { AwsAccountNameInputField } from "./aws_account/AwsAccountNameInputField.jsx";
import { EbsBackupCheckInputField } from "./aws_account/EbsBackupCheckInputField.jsx";
import { ErrorArea } from "./aws_account/ErrorArea.jsx";
import { SecretAccessKeyInputField } from "./aws_account/SecretAccessKeyInputField.jsx";
import { SubmitButton } from "./aws_account/SubmitButton.jsx";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.aws_account.iam_user";

export class NewAwsAccountIamUserForm extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {Object[]} groups - 選択肢となるグループの配列
   */
  static get propTypes() {
    return {
      groups: PropTypes.array.isRequired,
    };
  }

  /**
   * コンストラクタ
   *
   * @override
   */
  constructor(props) {
    super(props);

    this.state = this.initialState();

    this.handleChangeAccessKeyIdInput = this.handleChangeAccessKeyIdInput.bind(this);
    this.handleChangeSecretAccessKeyInput = this.handleChangeSecretAccessKeyInput.bind(this);
    this.handleChangeAwsAccountNameInput = this.handleChangeAwsAccountNameInput.bind(this);
    this.handleChangeEbsBackupCheckEnabled = this.handleChangeEbsBackupCheckEnabled.bind(this);
    this.handleChangeGroupSelector = this.handleChangeGroupSelector.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return (
      <React.StrictMode>
        <form onSubmit={this.handleSubmit}>
          <div className="ca-group__aws-account-form">
            <div className="ca-group__aws-account-form__row">
              <div className="ca-group__aws-account-form__left-col">
                <div className="ca-group__aws-account-form__heading">{I18n.t("step1", { scope: I18N_SCOPE })}</div>
              </div>
              <div className="ca-group__aws-account-form__right-col">
                <label className="ca-group__aws-account-form__label" htmlFor="GroupSelector">
                  {I18n.t("group", { scope: I18N_SCOPE })}
                  <span className="ca-group__aws-account-form__required-mark">*</span>
                </label>
                <div className="ca-group__aws-account-form__select">
                  <GroupSelector onChange={this.handleChangeGroupSelector} options={this.props.groups} />
                </div>
                <ErrorArea errors={this.state.errors.groupId} />
                <p className="ca-group__aws-account-form__note">{I18n.t("group_note", { scope: I18N_SCOPE })}</p>
              </div>
            </div>

            <div className="ca-group__aws-account-form__row">
              <div className="ca-group__aws-account-form__left-col">
                <div className="ca-group__aws-account-form__heading">{I18n.t("step2", { scope: I18N_SCOPE })}</div>
              </div>
              <div className="ca-group__aws-account-form__right-col">
                <p className="ca-group__aws-account-form__prompt">{I18n.t("prompt", { scope: I18N_SCOPE })}</p>
                <AccessKeyIdInputField
                  accessKeyId={this.state.accessKeyId}
                  disabled={!this.isGroupSelected()}
                  onChange={this.handleChangeAccessKeyIdInput}
                  errors={this.state.errors.accessKeyId}
                />
                <SecretAccessKeyInputField
                  secretAccessKey={this.state.secretAccessKey}
                  disabled={!this.isGroupSelected()}
                  onChange={this.handleChangeSecretAccessKeyInput}
                  errors={this.state.errors.secretAccessKey}
                />
                <AwsAccountNameInputField
                  awsAccountName={this.state.awsAccountName}
                  disabled={!this.isGroupSelected()}
                  onChange={this.handleChangeAwsAccountNameInput}
                  errors={this.state.errors.awsAccountName}
                />
                <EbsBackupCheckInputField
                  ebsBackupCheckEnabled={this.state.ebsBackupCheckEnabled}
                  disabled={!this.isGroupSelected()}
                  onChange={this.handleChangeEbsBackupCheckEnabled}
                  errors={this.state.errors.ebsBackupCheckEnabled}
                />
              </div>
            </div>
          </div>
          <div className="ca-btn-block marginT40">
            <div className="ca-btn-block__center">
              <SubmitButton
                disabled={!this.isSubmittable()}
                label={I18n.t("submit", { scope: I18N_SCOPE })}
                pending={this.state.isTransmitting}
                pendingLabel={I18n.t("pending", { scope: I18N_SCOPE })}
              />
              <ErrorArea errors={this.state.errors.base} />
            </div>
          </div>
        </form>
      </React.StrictMode>
    );
  }

  handleChangeAccessKeyIdInput(accessKeyId) {
    this.setState({ accessKeyId: accessKeyId });
  }

  handleChangeSecretAccessKeyInput(secretAccessKey) {
    this.setState({ secretAccessKey: secretAccessKey });
  }

  handleChangeAwsAccountNameInput(awsAccountName) {
    this.setState({ awsAccountName: awsAccountName });
  }

  handleChangeEbsBackupCheckEnabled(ebsBackupCheckEnabled) {
    this.setState({ ebsBackupCheckEnabled: ebsBackupCheckEnabled === "true" });
  }

  /**
   * GroupSelectorから渡された「選択されたグループ」をstateに格納します。
   *
   * GroupSelectorからは下記のような配列が渡されます。
   * [{ id: 1, name: "グループ名" }]
   *
   * @param {Object[]} groups - 現在選択されているグループの配列
   */
  handleChangeGroupSelector(groups) {
    if (groups[0]) {
      this.setState({ groupId: groups[0].id });
    } else {
      this.setState({ groupId: null });
    }
  }

  /**
   * ステートの初期値を返します。
   *
   * @private
   * @return {Object}
   * @property {string} accessKeyId アクセスキー入力欄の現在値
   * @property {string} awsAccountName AWSアカウント名入力欄の現在値
   * @property {boolean} ebsBackupCheckEnabled EBSバックアップ入力欄の現在値
   * @property {Object} errors 各入力欄の下に表示するエラーメッセージの配列をプロパティ値として持つオブジェクト
   * @property {number} groupId 現在選択されてるグループのID
   * @property {boolean} isTransmitting バリデーション用リクエストを送信中かどうか
   * @property {string} secretAccessKey シークレットアクセスキー入力欄の現在値
   */
  initialState() {
    return {
      accessKeyId: "",
      awsAccountName: "",
      ebsBackupCheckEnabled: true,
      errors: {
        accessKeyId: [],
        secretAccessKey: [],
        awsAccountName: [],
        base: [],
        ebsBackupCheckEnabled: [],
        groupId: [],
      },
      groupId: null,
      isTransmitting: false,
      secretAccessKey: "",
    };
  }

  /**
   * グループが選択済みかどうかを返します。
   *
   * @private
   * @return {boolean}
   */
  isGroupSelected() {
    return this.state.groupId != null;
  }

  /**
   * フォームの送信ボタンが有効かどうかを返します。
   *
   * @private
   * @return {boolean}
   */
  isSubmittable() {
    return (
      this.state.groupId != null &&
      this.state.accessKeyId != "" &&
      this.state.secretAccessKey != "" &&
      this.state.awsAccountName != ""
    );
  }

  /**
   * Ajaxを通して入力された値を送信します。
   */
  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      errors: {
        accessKeyId: [],
        awsAccountName: [],
        base: [],
        ebsBackupCheckEnabled: [],
        groupId: [],
        secretAccessKey: [],
      },
      isTransmitting: true,
    });

    let data = {
      account_type: "iam_user",
      aws_account: {
        access_key_id: this.state.accessKeyId,
        ebs_backup_check_enabled: this.state.ebsBackupCheckEnabled,
        name: this.state.awsAccountName,
        secret_access_key: this.state.secretAccessKey,
      },
    };

    jQuery
      .ajax({
        url: `/groups/${this.state.groupId}/aws_accounts`,
        method: "POST",
        dataType: "json",
        data: data,
      })
      .done((data) => {
        window.location = data.url;
      })
      .fail((data) => {
        let errors = data.responseJSON?.errors;
        if (!errors) {
          errors = { base: [I18n.t("error", { message: data.statusText, scope: I18N_SCOPE })] };
        }

        this.setState({
          errors: {
            accessKeyId: errors.access_key_id || [],
            secretAccessKey: errors.secret_access_key || [],
            awsAccountName: errors.name || [],
            base: errors.base || [],
            ebsBackupCheckEnabled: errors.ebs_backup_check_enabled || [],
            groupId: errors.group || [],
          },
          isTransmitting: false,
        });
      });
  }
}
