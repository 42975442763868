import React from 'react';
import PropTypes from 'prop-types';

import Dialog from './Dialog.jsx';
import DialogOpenButton from './DialogOpenButton.jsx';

const I18n = window.I18n; // i18n-js
const I18N_SCOPE = 'javascript.tfa_settings.tfa_settings';

/**
 * 二要素認証設定UIコンポーネント
 *
 * 二要素認証の現在の設定状況の表示と、ダイアログを開くボタンの表示を行います。
 * ユーザー自身がユーザー情報設定ページで利用する場合と、オーナーまたは管理者が
 * 組織のユーザー用のアカウント管理ページで利用する場合のいずれにも対応します。
 */
export default class TFASettings extends React.Component {
  /**
   * propTypes
   * @return {object}
   * @property {object} emitter EventEmitter
   * @property {bool} enabled 二要素認証が有効化されているかどうか
   * @property {bool} error エラーが発生したかどうか
   * @property {bool} showDialog ダイアログが表示状態かどうか
   * @property {?bool} simple UIをシンプルにするかどうか(組織ユーザー用の場合にtrue、デフォルトはfalse)
   */
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
      enabled: PropTypes.bool.isRequired,
      error: PropTypes.bool.isRequired,
      showDialog: PropTypes.bool.isRequired,
      simple: PropTypes.bool,
    });
  }

  /**
   * デフォルトのプロパティを返します。
   *
   * @public
   * @return {Object}
   * @property {bool} simple UIをシンプルにするかどうか(組織ユーザー用の場合にtrue)
   */
  static get defaultProps() {
    return({
      simple: false,
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);

    const options = { scope: I18N_SCOPE };
    this.headerText = I18n.t('heading', options);
    this.enabledText = I18n.t('enabled', options);
    this.disabledText = I18n.t('disabled', options);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return this.props.simple ? this.renderSimple() : this.renderRich();
  }

  /**
   * リッチなHTMLを返します。
   * サインイン中のユーザー自身用のユーザー情報設定ページで利用します。
   * @return {ReactElement}
   */
  renderRich() {
    return(
      <div>
        <table className="table vertical-middle no-borders">
          <tbody>
            <tr>
              <th>
                {this.headerText}
              </th>
              <td className="qa-tfa-settings-state">
                {this.props.enabled ? this.enabledText : this.disabledText}
              </td>
              <td className="text-right">
                <DialogOpenButton
                  emitter={this.props.emitter}
                  enabled={this.props.enabled}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <Dialog {...this.props} />
      </div>
    );
  }

  /**
   * シンプルなHTMLを返します。
   * オーナーまたは管理者向けの組織ユーザー用アカウント管理ページで利用します。
   * @return {ReactElement}
   */
  renderSimple() {
    return(
      <div className="ca-button-layout ca-button-layout--vertical">
        <div className="ca-button-layout__primary">
          <div style={{marginBottom: '1em'}} data-qa="tfa-settings-state">
            {this.props.enabled ? this.enabledText : this.disabledText}
          </div>
        </div>
        <div className="ca-button-layout__secondary">
          <DialogOpenButton
            emitter={this.props.emitter}
            enabled={this.props.enabled}
            simple={this.props.simple}
          />
        </div>
        <Dialog {...this.props} />
      </div>
    );
  }
}
