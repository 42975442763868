import EventEmitter from "events";
import React from "react";
import PropTypes from "prop-types";

import DeletePostProcessDialog from "./DeletePostProcessDialog.jsx";

const I18n = window.I18n;
const I18N_SCOPE = "post_processes.show";

/**
 * 後処理詳細画面の削除リンク
 */
export default class DeletePostProcessButton extends React.Component {
  /**
   * プロパティ定義を返します。
   * @public
   * @return {Object}
   * @property {string} backTo 後処理削除後に戻るページのURL
   * @property {Object} postProcess 後処理オブジェクト
   */
  static get propTypes() {
    return({
      backTo: PropTypes.string.isRequired,
      postProcess: PropTypes.object.isRequired,
    });
  }

  /**
   * コンストラクタ
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.state = {
      showDeletePostProcessDialog: false,
    };

    this.handleCloseDeletePostProcessDialog = this.handleCloseDeletePostProcessDialog.bind(this);
    this.handleOpenDeletePostProcessDialog = this.handleOpenDeletePostProcessDialog.bind(this);

    this.emitter = new EventEmitter();
    this.emitter.on("closeDialog", this.handleCloseDeletePostProcessDialog);
    this.emitter.on("openDialog", this.handleOpenDeletePostProcessDialog);
  }

  /**
   * 後処理削除ダイアログを閉じるハンドラ
   * stateの値を更新する
   *
   * @private
   */
  handleCloseDeletePostProcessDialog() {
    this.setState({ showDeletePostProcessDialog: false });
  }

  /**
   * 後処理削除ダイアログを開くハンドラ
   * stateの値を更新する
   *
   * @private
   */
  handleOpenDeletePostProcessDialog() {
    this.setState({ showDeletePostProcessDialog: true });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return (
      <React.StrictMode>
        <div>
          <a
            className="text-danger ca-post-process-detail-buttons__delete"
            onClick={() => {
              this.emitter.emit("openDialog");
            }}
          >
            <span className="fa fa-trash marginR5"></span> {I18n.t("delete", { scope: I18N_SCOPE })}
          </a>
          <DeletePostProcessDialog
            emitter={this.emitter}
            postProcess={this.props.postProcess}
            reloadEmitter={this.emitter}
            showDeletePostProcessDialog={this.state.showDeletePostProcessDialog}
          />
        </div>
      </React.StrictMode>
    );
  }
}
