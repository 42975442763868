import React from "react";

export const ToggleJobActiveButton: React.FC<{
  jobActive: boolean;
  onClick: () => void;
}> = (props) => {
  return (
    <>
      <div className={`toggle-switch ${props.jobActive ? "on" : "off"}`} onClick={props.onClick}>
        <span className="toggle-button"></span>
      </div>
    </>
  );
};
