import EventEmitter from 'events';
import PropTypes from 'prop-types';
import React from 'react';

import Constants from './Constants.js';
import Headroom from './PolicyLogTableHeadroom.jsx';
import Pagination from '../Pagination.jsx';
import Table from './PolicyLogTable.jsx';

/**
 * JSONで表現されたポリシーログをあらわす仮想的な型。
 * @typedef {Object} PolicyLog
 * @property {boolean} all_compliant ポリシーが守られているかどうか
 * @property {string} evaluation_time レビュー日時(ISO 8601拡張形式)
 * @property {string} evaluation_time_formatted 一覧用書式のレビュー日時(YYYY-MM-DD hh:mm)
 * @property {string} evaluation_time_in_relative 相対表記のレビュー日時(e.g. "7日前")
 * @property {number} id ポリシーログID
 * @property {string} policy_name ポリシー名
 * @property {string} policy_set_name ポリシーセット名
 * @property {string} review_timing レビュータイミング(periodic または configuration_changed)
 * @property {string} review_timing_text I18n表記のレビュータイミング(e.g. "24時間ごと")
 */

/**
 * ポリシーセット毎のポリシーログテーブルコンテナ
 *
 * ポリシーセット毎のポリシーログ一覧テーブルの起点となるコンポーネントで、以下の責務を持ちます。
 *
 * - ポリシーセット一覧情報JSONのサーバーからの取得
 * - フィルタリング条件による絞り込みイベントが発生した際の処理
 */
export default class PolicyLogTableContainer extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {boolean} aggregate ログをポリシー毎に集約するかどうか
   * @property {boolean} forPolicySet ポリシーセット毎のログ一覧かどうか
   * @property {number} perPage 1ページに表示する件数
   * @property {string} url ポリシーログ一覧を取得するURL(もしくはパス)
   */
  static get propTypes() {
    return({
      aggregate: PropTypes.bool.isRequired,
      forPolicySet: PropTypes.bool.isRequired,
      perPage: PropTypes.number,
      url: PropTypes.string.isRequired,
    });
  }

  /**
   * デフォルトのプロパティを返します。
   *
   * @public
   * @return {Object}
   * @property {number} perPage 1ページに表示する件数
   */
  static get defaultProps() {
    return({
      perPage: 30,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.handleChangeComplianceType = this.handleChangeComplianceType.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.prepareForFetchRecords = this.prepareForFetchRecords.bind(this);
    this.handleFetchRecordsDone = this.handleFetchRecordsDone.bind(this);
    this.handleFetchRecordsFail = this.handleFetchRecordsFail.bind(this);

    this.emitter = new EventEmitter;
    this.emitter.on(Constants.EVENT_CHANGE_COMPLIANCE_TYPE, this.handleChangeComplianceType);

    this.state = {
      complianceType: '', // 評価結果でフィルタリングする際の条件 (Constants.COMPLIANCE_TYPE.*)
      currentPage: 1, // 現在表示しているページの番号
      errorMessage: null, // エラーメッセージ (nullの場合は表示されない)
      pending: true, // 通信中かどうか
      policyLogs: [], // 現在表示しているページのポリシーログ情報(PolicyLog型の配列)
      totalCount: 0, // フィルタリング条件に合致する全件数
      totalPages: 1, // 全ページ数
    };
  }

  /**
   * コンポーネントがマウントされた際の処理を行います。
   * @public
   */
  componentDidMount() {
    this.fetchRecords();
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <section>
        <Headroom
          emitter={this.emitter}
          totalCount={this.isDataFetched() ? this.state.totalCount : null}
        />
        <Table
          pending={this.state.pending}
          policyLogs={this.state.policyLogs}
        />
        {this.isDataFetched() && this.isRecordExists() &&
          <Pagination
            callback={this.handleChangePage}
            currentPage={this.state.currentPage}
            perPage={this.props.perPage}
            totalItems={this.state.totalCount}
          />
        }
      </section>
    );
  }

  /**
   * サーバーから取得したポリシーログの配列を、ポリシー毎の最新ログを表示する際のソート条件でソートするための
   * ソート用関数。Array.prototype.sort関数の引数として利用します。
   *
   * 引数aおよびbのオブジェクトは以下の2つのプロパティを持っていることが期待されます。
   *
   * - all_compliant (boolean) - 評価結果
   * - evaluation_time (string) - 評価日時(文字列として大小が比較可能な値であること)
   *
   * @private
   * @param {Object} a サーバーから取得したポリシーログ
   * @param {Object} b サーバーから取得したポリシーログ
   * @return {number}
   */
  comparePolicyLogForAggregatedView(a, b) {
    if (a.all_compliant === false && b.all_compliant === true) {
      // aはbより小さい(評価結果がfalseのものを小とみなす)
      return -1;
    }
    if (a.all_compliant === true && b.all_compliant === false) {
      // aはbより大きい(評価結果がfalseのものを小とみなす)
      return 1;
    }
    if (a.evaluation_time > b.evaluation_time) {
      // aはbより小さい(評価結果が同じ場合はレビュー日時が新しいものを小とみなす)
      return -1;
    }
    if (a.evaluation_time < b.evaluation_time) {
      // aはbより大きい(評価結果が同じ場合はレビュー日時が新しいものを小とみなす)
      return -1;
    }
    // 評価結果とレビュー日時が完全に一致する場合の順序は未保証
    return 0;
  }

  /**
   * 現在のフィルタリング条件に該当するレコードをサーバーから非同期に取得します。
   * @private
   */
  fetchRecords() {
    const dataToSend = {
      not_aggregate: this.props.aggregate ? '' : '1',
      compliance_type: this.state.complianceType,
      page: this.state.currentPage,
      per: this.props.perPage,
    };
    const options = {
      beforeSend: this.prepareForFetchRecords,
      data: dataToSend,
      dataType: 'json',
      url: this.props.url,
    };
    jQuery.ajax(options).done(this.handleFetchRecordsDone).fail(this.failFetchRecords);
  }

  /**
   * フィルタリング条件「ポリシーログの結果」の値を変更するイベントを処理します。
   * @private
   * @param {string} value
   */
  handleChangeComplianceType(value) {
    this.setState({
      complianceType: value,
      currentPage: 1,
    }, () => this.fetchRecords());
  }

  /**
   * 表示するページを変更します。
   *
   * @private
   * @param {number} page 表示すべきページのページ番号
   */
  handleChangePage(page) {
    this.setState({
      currentPage: page,
    }, () => this.fetchRecords());
  }

  /**
   * サーバーからレコードを取得完了した場合に行う処理を実行します。
   * @private
   * @param {Object} data policy_sets/policy_logs#index が返すJSONレスポンス
   */
  handleFetchRecordsDone(data) {
    if (this.props.aggregate) {
      // ポリシー毎の最新ログだけを表示するモードの場合は、サーバーサイドでのソート結果を使わずに
      // 結果とレビュー日時でのソートをクライアントサイドで行う
      data.policy_logs.sort(this.comparePolicyLogForAggregatedView);
    }
    this.setState({
      pending: false,
      policyLogs: data.policy_logs,
      totalCount: data.meta.total_count,
      totalPages: data.meta.total_pages,
    });
  }

  /**
   * サーバーからレコードを取得失敗した場合に行う処理を実行します。
   * @private
   */
  handleFetchRecordsFail() {
    this.setState({
      currentPage: 1,
      errorMessage: "Error!",
      pending: false,
      policyLogs: [],
      totalCount: 0,
      totalPages: 1,
    });
  }

  /**
   * レコードの取得が完了しているかどうかを返します。
   *
   * @private
   * @return {boolean}
   */
  isDataFetched() {
    return !this.state.pending;
  }

  /**
   * 表示すべきレコードが1件以上存在するかどうかを返します。
   *
   * @private
   * @return {boolean}
   */
  isRecordExists() {
    return this.state.policyLogs.length > 0;
  }

  /**
   * サーバーからレコードを取得する前に行う処理を実行します。
   * @private
   */
  prepareForFetchRecords() {
    this.setState({
      errorMessage: null,
      pending: true,
      policyLogs: [],
      totalCount: 0,
      totalPages: 1,
    });
  }
}
