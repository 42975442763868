import React from "react";
import PropTypes from "prop-types";

import GroupSelector from "./GroupSelector.jsx";

import { StackInfoInputField } from "./aws_account/StackInfoInputField.jsx";
import { AwsAccountNameInputField } from "./aws_account/AwsAccountNameInputField.jsx";
import { SubmitButton } from "./aws_account/SubmitButton.jsx";
import { EbsBackupCheckInputField } from "./aws_account/EbsBackupCheckInputField.jsx";
import { IamRoleSetupButton } from "./aws_account/IamRoleSetupButton.jsx";
import { ErrorArea } from "./aws_account/ErrorArea.jsx";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.aws_account.iam_role";

export class NewAwsAccountIamRoleForm extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {Object[]} groups - 選択肢となるグループの配列
   */
  static get propTypes() {
    return {
      groups: PropTypes.array.isRequired,
    };
  }

  /**
   * コンストラクタ
   *
   * @override
   */
  constructor(props) {
    super(props);

    this.state = this.initialState();

    this.handleChangeStackInfoInput = this.handleChangeStackInfoInput.bind(this);
    this.handleChangeAwsAccountNameInput = this.handleChangeAwsAccountNameInput.bind(this);
    this.handleChangeEbsBackupCheckEnabled = this.handleChangeEbsBackupCheckEnabled.bind(this);
    this.handleChangeGroupSelector = this.handleChangeGroupSelector.bind(this);
    this.handleClickCreateIamRoleButton = this.handleClickCreateIamRoleButton.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return (
      <React.StrictMode>
        <form onSubmit={this.handleSubmit}>
          <div className="ca-group__aws-account-form">
            <div className="ca-group__aws-account-form__row">
              <div className="ca-group__aws-account-form__left-col">
                <div className="ca-group__aws-account-form__heading">{I18n.t("step1", { scope: I18N_SCOPE })}</div>
              </div>
              <div className="ca-group__aws-account-form__right-col">
                <label className="ca-group__aws-account-form__label" htmlFor="GroupSelector">
                  {I18n.t("group", { scope: I18N_SCOPE })}
                  <span className="ca-group__aws-account-form__required-mark">*</span>
                </label>
                <div className="ca-group__aws-account-form__select">
                  <GroupSelector onChange={this.handleChangeGroupSelector} options={this.props.groups} />
                </div>
                <ErrorArea errors={this.state.errors.groupId} />
                <p className="ca-group__aws-account-form__note">{I18n.t("group_note", { scope: I18N_SCOPE })}</p>
              </div>
            </div>

            <div className="ca-group__aws-account-form__row">
              <div className="ca-group__aws-account-form__left-col">
                <div className="ca-group__aws-account-form__heading">{I18n.t("step2", { scope: I18N_SCOPE })}</div>
              </div>
              <div className="ca-group__aws-account-form__right-col">
                <p className="ca-group__aws-account-form__prompt">{I18n.t("step2_note", { scope: I18N_SCOPE })}</p>
              </div>
            </div>

            <div className="ca-group__aws-account-form__row">
              <div className="ca-group__aws-account-form__left-col">
                <div className="ca-group__aws-account-form__heading">{I18n.t("step3", { scope: I18N_SCOPE })}</div>
              </div>
              <div className="ca-group__aws-account-form__right-col">
                <IamRoleSetupButton
                  disabled={!this.isGroupSelected() || this.state.isPendingAwsRedirection}
                  errors={this.state.errors.createIamRoleButton}
                  onClick={this.handleClickCreateIamRoleButton}
                />
              </div>
            </div>

            <div className="ca-group__aws-account-form__row">
              <div className="ca-group__aws-account-form__left-col">
                <div className="ca-group__aws-account-form__heading">{I18n.t("step4", { scope: I18N_SCOPE })}</div>
              </div>
              <div className="ca-group__aws-account-form__right-col">
                <p className="ca-group__aws-account-form__prompt">{I18n.t("step4_note", { scope: I18N_SCOPE })}</p>

                <StackInfoInputField
                  disabled={!this.isCreateIamRoleButtonClicked()}
                  onChange={this.handleChangeStackInfoInput}
                  errors={this.state.errors.stackInfo}
                />
                <AwsAccountNameInputField
                  awsAccountName={this.state.awsAccountName}
                  disabled={!this.isCreateIamRoleButtonClicked()}
                  onChange={this.handleChangeAwsAccountNameInput}
                  errors={this.state.errors.awsAccountName}
                />
                <EbsBackupCheckInputField
                  ebsBackupCheckEnabled={this.state.ebsBackupCheckEnabled}
                  disabled={!this.isCreateIamRoleButtonClicked()}
                  onChange={this.handleChangeEbsBackupCheckEnabled}
                  errors={this.state.errors.ebsBackupCheckEnabled}
                />
              </div>
            </div>
          </div>
          <div className="ca-btn-block marginT40">
            <div className="ca-btn-block__center">
              <SubmitButton
                disabled={!this.isSubmittable()}
                label={I18n.t("submit", { scope: I18N_SCOPE })}
                pending={this.state.isTransmitting}
                pendingLabel={I18n.t("pending", { scope: I18N_SCOPE })}
              />
              <ErrorArea errors={this.state.errors.base} />
            </div>
          </div>
        </form>
      </React.StrictMode>
    );
  }

  handleChangeStackInfoInput(stackInfo) {
    this.setState({ stackInfo: stackInfo });
  }

  handleChangeAwsAccountNameInput(awsAccountName) {
    this.setState({ awsAccountName: awsAccountName });
  }

  handleChangeEbsBackupCheckEnabled(ebsBackupCheckEnabled) {
    this.setState({ ebsBackupCheckEnabled: ebsBackupCheckEnabled === "true" });
  }

  /**
   * GroupSelectorから渡された「選択されたグループ」をstateに格納します。
   *
   * GroupSelectorからは下記のような配列が渡されます。
   * [{ id: 1, name: "グループ名" }]
   *
   * @param {Object[]} groups - 現在選択されているグループの配列
   */
  handleChangeGroupSelector(groups) {
    if (groups[0]) {
      this.setState({ groupId: groups[0].id });
    } else {
      this.setState({ groupId: null });
    }
  }

  /**
   * AWSのIAMロールセットアップ用CFnスタック作成ページを新しいウィンドウで開きます。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleClickCreateIamRoleButton(event) {
    event.preventDefault();

    this.setState({
      errors: {
        awsAccountName: [],
        base: [],
        createIamRoleButton: [],
        ebsBackupCheckEnabled: [],
        externalId: [],
        groupId: [],
        stackInfo: [],
      },
      isPendingAwsRedirection: true,
    });

    jQuery
      .ajax({
        url: "/iam_role_setup",
        method: "POST",
        dataType: "json",
        data: this.state.groupId ? { group_id: this.state.groupId } : {},
      })
      .done((data) => {
        this.setState({
          cfnTemplateCacheId: data.cfn_template_cache_id,
          externalId: data.external_id,
          isPendingAwsRedirection: false,
        });
        if (window.open(data.iam_role_setup_url, "_blank") == null) {
          window.console.log("IAM role setup URL window blocked.");
        }
      })
      .fail(() => {
        // 詳細なエラー情報はサーバーサイドで把握するため、ここでは取り扱わない
        const errors = this.state.errors;
        errors.createIamRoleButton = [I18n.t("internal_server_error", { scope: I18N_SCOPE })];
        this.setState({
          errors: errors,
          isPendingAwsRedirection: false,
        });
      });
  }

  /**
   * ステートの初期値を返します。
   *
   * cfnTemplateCacheId と externalId の値は、「IAMロールを作成」ボタン押下によって発生する
   * handleClickCreateIamRoleButton 関数内で設定されます。
   *
   * @private
   * @return {Object}
   * @property {string} awsAccountName AWSアカウント名入力欄の現在値
   * @property {?number} cfnTemplateCacheId CFnテンプレートJSONの生成に使ったCfnTemplateCacheモデルのID
   * @property {Object} errors 各入力欄の下に表示するエラーメッセージの配列をプロパティ値として持つオブジェクト
   * @property {?string} externalId 外部ID
   * @property {boolean} isPendingAwsRedirection AWSへのリダイレクト処理中かどうか(ボタンの2度押し予防のた
   * @property {boolean} isTransmitting バリデーション用リクエストを送信中かどうか
   * @property {string} stackInfo CloudAutomatorStackInfo入力欄の現在値
め)
   */
  initialState() {
    return {
      awsAccountName: "",
      cfnTemplateCacheId: null,
      ebsBackupCheckEnabled: true,
      errors: {
        awsAccountName: [],
        base: [],
        createIamRoleButton: [],
        ebsBackupCheckEnabled: [],
        externalId: [],
        groupId: [],
        stackInfo: [],
      },
      externalId: null,
      groupId: null,
      isPendingAwsRedirection: false,
      isTransmitting: false,
      stackInfo: "",
    };
  }

  /**
   * ダイアログを開いてから「IAMロールを作成ボタン」が1度でもクリック
   * されたかどうかを返します。
   *
   * @private
   * @return {boolean}
   */
  isCreateIamRoleButtonClicked() {
    return this.state.externalId != null;
  }

  /**
   * グループが選択済みかどうかを返します。
   *
   * @private
   * @return {boolean}
   */
  isGroupSelected() {
    return this.state.groupId != null;
  }

  /**
   * フォームの送信ボタンが有効かどうかを返します。
   *
   * @private
   * @return {boolean}
   */
  isSubmittable() {
    return (
      this.state.groupId != null &&
      this.state.cfnTemplateCacheId != null &&
      this.state.externalId != null &&
      this.state.stackInfo != "" &&
      this.state.awsAccountName != ""
    );
  }

  /**
   * Ajaxを通して入力された値を送信します。
   */
  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      errors: {
        awsAccountName: [],
        base: [],
        createIamRoleButton: [],
        ebsBackupCheckEnabled: [],
        externalId: [],
        groupId: [],
        stackInfo: [],
      },
      isTransmitting: true,
    });

    let data = {
      account_type: "iam_role",
      aws_account: {
        name: this.state.awsAccountName,
        cfn_template_cache_id: this.state.cfnTemplateCacheId,
        ebs_backup_check_enabled: this.state.ebsBackupCheckEnabled,
        iam_role_attributes: {
          external_id: this.state.externalId,
        },
      },
      stack_info: this.state.stackInfo,
    };

    jQuery
      .ajax({
        url: `/groups/${this.state.groupId}/aws_accounts`,
        method: "POST",
        dataType: "json",
        data: data,
      })
      .done((data) => {
        window.location = data.url;
      })
      .fail((data) => {
        let errors = data.responseJSON?.errors;
        if (!errors) {
          errors = { base: [I18n.t("error", { message: data.statusText, scope: I18N_SCOPE })] };
        }

        this.setState({
          errors: {
            awsAccountName: errors.name || [],
            base: errors.base || [],
            createIamRoleButton: [],
            ebsBackupCheckEnabled: errors.ebs_backup_check_enabled || [],
            externalId: errors.external_id || [],
            groupId: errors.group || [],
            stackInfo: errors.iam_role || [],
          },
          isTransmitting: false,
        });
      });
  }
}
