import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';

const I18n = window.I18n; // i18n-js

// ポリシーの文字列型パラメーター入力フィールドコンポーネント
class PolicyParameterStringField extends React.PureComponent {
  /**
   * デフォルトのプロパティ値を返します。
   * React内部から呼び出されます。
   *
   * @return {object}
   */
  static get defaultProps() {
    return({
      help: '',
      maxLength: null,
      readOnly: false,
      required: false
    });
  }

  static get propTypes() {
    return({
      emitter: PropTypes.object,
      help: PropTypes.string,
      initialValue: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      maxLength: PropTypes.number,
      name: PropTypes.string.isRequired,
      policyTemplateId: PropTypes.number.isRequired,
      readOnly: PropTypes.bool,
      required: PropTypes.bool
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      validationErrors: [],
      value: this.props.initialValue
    };
    this.handleChange = this.handleChange.bind(this);
  }

  render() {
    let requirementText = null;
    if (this.props.required) {
      requirementText = I18n.t('required', { scope: Constants.I18N_SCOPE });
    }

    let errorClass = '';
    if (this.hasValidationErrors()) {
      errorClass = 'has-error';
    }

    return(
      <div className={`form-group ${errorClass}`}>
        <div className="row">
          <div className="col-xs-6">
            <label className="control-label">
              {this.props.label} {requirementText}
            </label>
            <input
              className="form-control policy-parameter-string-field__input"
              onChange={this.handleChange}
              readOnly={this.props.readOnly}
              required={this.props.required}
              type="text"
              value={this.state.value}
            />
            {this.renderValidationErrors()}
            {this.renderHelp()}
          </div>
        </div>
      </div>
    );
  }

  renderHelp() {
    if (this.props.help === '') {
      return null;
    }

    return(
      <p className="help-block">
        {this.props.help}
      </p>
    );
  }

  renderValidationErrors() {
    if (this.hasValidationErrors()) {
      return(
        <ul className="help-block list-unstyled">
          {this.state.validationErrors.map(error => <li key={error}>{error}</li>)}
        </ul>
      );
    } else {
      return null;
    }
  }

  handleChange(event) {
    if (this.props.readOnly) {
      return;
    }

    const value = event.target.value;
    let validationErrors = [];
    this.validateMaxLength(value, validationErrors);

    this.setState({
      validationErrors: validationErrors,
      value: value
    }, () => {
      this.props.emitter.emit(
        Constants.EVENT_CHANGE_POLICY_PARAMETER,
        {
          policyTemplateId: this.props.policyTemplateId,
          name: this.props.name,
          type: Constants.PARAMETER_FIELD_TYPE_STRING,
          validity: !this.hasValidationErrors(),
          value: value
        }
      );
    });
  }

  /**
   * 入力内容にバリデーションエラーがあるかどうかを返します。
   *
   * @return {boolean}
   */
  hasValidationErrors() {
    return this.state.validationErrors.length > 0;
  }

  /**
   * 必要に応じて value に対して文字数のバリデーションを行います。
   * エラーメッセージは引数 errors に追加されます。
   *
   * @param {string} value
   * @param {array} errors
   */
  validateMaxLength(value, errors) {
    if (this.props.maxLength === null) {
      return;
    }

    if (value.length > this.props.maxLength) {
      errors.push(I18n.t('max_length_invalid', { scope: Constants.I18N_SCOPE, count: this.props.maxLength }));
    }
  }
}

export default PolicyParameterStringField;
