import React from "react";
import PropTypes from "prop-types";

/**
 * グループの権限をtooltip付アイコンで表示するコンポーネント
 *
 */
export default class PermissionIcon extends React.Component {
  /**
   * プロパティ定義を返します
   *
   * @public
   * @property {string} permission 権限種類
   * @property {string} permissionName tooltipに表示する権限名
   */
  static get propTypes() {
    return({
      permission: PropTypes.string.isRequired,
      permissionName: PropTypes.string.isRequired,
    });
  }

  render() {
    const {permission, permissionName} = this.props;
    const iconClass = 'ca-permission-icon__' + (permission || 'not_usable');

    let icon = '';
    if (permission === "read_only") {
      icon = 'fa fa-eye';
    } else if (permission === "read_write") {
      icon = 'fa fa-pencil-square-o';
    } else {
      icon = 'fa fa-ban';
    }

    return (
      <div className={iconClass}>
        <span
          data-placement="bottom"
          data-toggle="tooltip"
          data-original-title={permissionName}
        >
          <i className={icon} aria-hidden="true" />
        </span>
      </div>
    );
  }
}
