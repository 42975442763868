import EventEmitter from 'events';
import React from 'react';
import PropTypes from 'prop-types';
import Uppy from '@uppy/core';
import UppyAwsS3 from '@uppy/aws-s3';
import UppyFileInput from '@uppy/file-input';
import UppyStatusBar from '@uppy/status-bar';

import Constants from './Constants.js';
import ProfileImageUploader from './ProfileImageUploader.jsx';

// アップロード可能なファイルのMIMEタイプ
const ALLOWED_FILE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

// アップロード可能なファイルサイズの上限
const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1 MB

// Uppyのデバッグモード(ブラウザコンソールにログが表示される)
const UPPY_DEBUG = false;

// プロフィール画像を変更する際にフォームに含めるパラメーター名
const INPUT_NAME_FOR_CHANGE = 'user[profile_image]';

// プロフィール画像を削除する際にフォームに含めるパラメーター名
const INPUT_NAME_FOR_REMOVE = 'user[remove_profile_image]';

const I18n = window.I18n; // i18n-js
const jQuery = window.jQuery;

/**
 * プロフィール画像アップローダーコンテナ
 *
 * 以下の責務を持ちます。
 *
 * - プロフィール画像アップローダー全体に関わるイベントの処理
 * - Uppyの初期化およびUppyが発生させるイベントの処理
 * - コンポーネントが関連付けられているHTMLフォームに含まれるINPUT要素の操作
 *   - 画像がアップロードされた際にそれをサーバー側へ反映(change)するためのINPUT要素の追加
 *   - 画像がリセットされた際にそれをサーバー側へ反映(remove)するためのINPUT要素の追加
 */
export default class ProfileImageUploaderContainer extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {string} formSelector 対象のFORM要素のセレクタ
   * @property {Object} user 表示するユーザー(full_name, profile_image_url, initial)
   */
  static get propTypes() {
    return({
      formSelector: PropTypes.string.isRequired,
      user: PropTypes.object.isRequired,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.handleResetImage = this.handleResetImage.bind(this);
    this.handleSelectImage = this.handleSelectImage.bind(this);
    this.handleUpdatePreviewImage = this.handleUpdatePreviewImage.bind(this);
    this.handleUppyUploadSuccessEvent = this.handleUppyUploadSuccessEvent.bind(this);

    this.state = {
      profileImageUrl: props.user.profile_image_url,
    };
    this.emitter = this.initEventEmitter();
  }

  /**
   * コンポーネントマウント後の処理を行います。
   *
   * @public
   */
  componentDidMount() {
    this.uppy = this.initUppy();
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <React.StrictMode>
        <ProfileImageUploader
          emitter={this.emitter}
          profileImageUrl={this.state.profileImageUrl}
          user={this.props.user}
        />
      </React.StrictMode>
    );
  }

  /**
   * Uppyによってアップロードされたファイルの情報をShrine用のJSON文字列に変換して返します。
   *
   * 戻り値のJSONは、Shrineが「S3へのダイレクトアップロード結果」として期待するハッシュを
   * 元にしたフォーマットとなります。この文字列をActiveRecordの User オブジェクトの
   * profile_image 属性値として設定することで、ユーザーとアップロード画像の紐付けが完了します。
   * http://shrinerb.com/rdoc/files/doc/direct_s3_md.html#label-File+hash
   *
   * @private
   * @param {Object} file Uppyの upload-success イベントハンドラに渡されるファイル情報
   * @return {string} Shrineがリクエストパラメーターの値として期待するJSON文字列
   */
  convertUploadedFileObjToJSON(file) {
    // ストレージ上でファイルを特定するためのID
    const id = file.meta['key'].match(/^cache\/(.+)/)[1];
    const data = {
      id: id,
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      },
    };

    return JSON.stringify(data);
  }

  /**
   * Uppyによって動的に生成される非表示のファイルアップロード用INPUT要素を返します。
   *
   * @private
   * @return {?Element}
   */
  getFileInputElement() {
    return document.querySelector(`#${Constants.FILE_INPUT_TARGET_ID} .uppy-FileInput-input`);
  }

  /**
   * プロフィール画像のプレビューを変更します。
   * ここで行われた変更を永続的に反映するには、フォームが送信される必要があります。
   *
   * @private
   * @param {?string} url プレビュー画像のURL
   * @param {?string} uploadedFileJSON アップロードされた画像ファイル情報
   */
  handleUpdatePreviewImage(url, uploadedFileJSON = null) {
    this.setState({ profileImageUrl: url });

    const form = jQuery(this.props.formSelector);
    // プロフィール画像を削除するためのパラメーターをフォームから削除
    form.find(`input[name="${INPUT_NAME_FOR_REMOVE}"]`).remove();
    // プロフィール画像を変更するためのパラメーターをフォームに追加
    form.append(jQuery('<input>').attr({
      type: 'hidden',
      name: INPUT_NAME_FOR_CHANGE,
      value: uploadedFileJSON,
    }));
  }

  /**
   * プロフィール画像のリセットを行います。
   * ここで行われた変更を永続的に反映するには、フォームが送信される必要があります。
   *
   * @private
   */
  handleResetImage() {
    this.setState({ profileImageUrl: null });

    // ファイルアップロード用INPUT要素の値をリセットします
    jQuery(this.getFileInputElement()).val('');

    const form = jQuery(this.props.formSelector);
    // プロフィール画像を変更するためのパラメーターをフォームから削除
    form.find(`input[name="${INPUT_NAME_FOR_CHANGE}"]`).remove();
    // プロフィール画像を削除するためのパラメーターをフォームに追加
    form.append(jQuery('<input>').attr({
      type: 'hidden',
      name: INPUT_NAME_FOR_REMOVE,
      value: '1',
    }));
  }

  /**
   * プロフィール画像の選択を行います。
   *
   * @private
   */
  handleSelectImage() {
    // ファイルアップロード用INPUT要素をクリックして、ブラウザのファイル選択UIを開きます。
    jQuery(this.getFileInputElement()).click();
  }

  /**
   * Uppyの upload-success イベントを処理します。
   * 与えられたファイル情報をもとに、
   *
   * - プレビュー用のdata: URL
   * - サーバーに送信するファイル情報のJSON文字列
   *
   * を生成して、EventEmitterの EVENT_UPDATE_PREVIEW_IMAGE イベントを発生します。
   *
   * @private
   * @param {Object} file Uppyのupload-successイベントハンドラに渡されるファイル情報
   */
  handleUppyUploadSuccessEvent(file) {
    // アップロードされたファイルをもとにdata: URLを生成するためにFileReaderオブジェクトを
    // 利用します。ファイル読み込み処理はloadイベントを利用して非同期に行います。
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const dataURL = reader.result;
      const fileInfo = this.convertUploadedFileObjToJSON(file);
      this.emitter.emit(Constants.EVENT_UPDATE_PREVIEW_IMAGE, dataURL, fileInfo);
    }, false);
    reader.readAsDataURL(file.data);
  }

  /**
   * EventEmitterを初期化して返します。
   *
   * 各コンポーネント内で発生するイベントとイベントハンドラ関数の割り当てを行います。
   *
   * @private
   * @return {Object}
   */
  initEventEmitter() {
    const emitter = new EventEmitter;
    emitter.on(
      Constants.EVENT_RESET_IMAGE,
      this.handleResetImage
    );
    emitter.on(
      Constants.EVENT_SELECT_IMAGE,
      this.handleSelectImage
    );
    emitter.on(
      Constants.EVENT_UPDATE_PREVIEW_IMAGE,
      this.handleUpdatePreviewImage
    );

    return emitter;
  }

  /**
   * ファイルアップロード処理を管理する Uppy を初期化して返します。
   *
   * @private
   * @return {Object}
   */
  initUppy() {
    const uppy = Uppy({
      autoProceed: true,
      debug: UPPY_DEBUG,
      locale: {
        strings: {
          exceedsSize: I18n.t('exceeds_size', { scope: Constants.I18N_SCOPE }),
          youCanOnlyUploadFileTypes: I18n.t('allowed_file_types', { scope: Constants.I18N_SCOPE }),
          uppyServerError: I18n.t('server_error', { scope: Constants.I18N_SCOPE }),
        },
      },
      restrictions: {
        allowedFileTypes: ALLOWED_FILE_TYPES,
        maxFileSize: MAX_FILE_SIZE,
      },
    });

    // ダイレクトアップロード用プラグイン
    uppy.use(UppyAwsS3, {
      getUploadParameters: (file) => {
        return fetch(`/presign?filename=${file.name}`).then(response => response.json());
      },
      limit: 1,
    });

    // ファイルアップロード用INPUTタグプラグイン
    uppy.use(UppyFileInput, {
      allowMultipleFiles: false,
      inputName: 'user_profile_image_input', // フォーム内の既存の入力欄と重複しない値、テスト内で参照している
      pretty: false,
      target: `#${Constants.FILE_INPUT_TARGET_ID}`,
    });

    // 進捗状況表示プラグイン
    uppy.use(UppyStatusBar, {
      hideAfterFinish: true,
      hideCancelButton: true,
      hidePauseResumeButton: true,
      hideUploadButton: false,
      showProgressDetails: false,
      target: `#${Constants.STATUS_BAR_ID}`,
    });

    uppy.on('upload-success', this.handleUppyUploadSuccessEvent);
    uppy.on('info-visible', () => alert(uppy.getState().info.message));

    return uppy;
  }
}
