import React, { useMemo, useState } from "react";

import { DuplicableJob } from "./types";
import DuplicateJobDialog from "./DuplicateJobDialog";

const I18n = window.I18n; // i18n-js

/**
 * ジョブの複製ボタン
 *
 * ジョブの複製を行うためのモーダルダイアログの開閉を制御するためのボタンです。
 */
const DuplicateJobButton: React.FC<{
  job: DuplicableJob, // 対象のジョブ
}> = (props) => {
  // モーダルダイアログが開いているかどうか
  const [dialogOpened, setDialogOpened] = useState(false);

  const buttonTitle = useMemo(() => I18n.t("duplicate", { scope: "javascript.job_table" }), [I18n.locale]);

  return (
    <>
      <a
        href="#"
        onClick={() => setDialogOpened(true)}
        type="button"
      >
        <i className="far fa-copy"></i>
        {buttonTitle}
      </a>
      <DuplicateJobDialog
        isOpen={dialogOpened}
        job={props.job}
        onClose={() => setDialogOpened(false)}
      />
    </>
  );
};

export default DuplicateJobButton;
