/**
 * Toast UI表示用関数
 *
 * react-toastifyを利用してToast UI（ページ上に一時的なポップアップのように出現するメッセージ領域）を
 * 表示するための関数群を定義しています。各関数は、表示するメッセージの種類に応じて配色などのオプションが
 * 設定済みなので、一貫性を保つためにも基本的にカスタマイズせずに利用します。
 *
 * なお、このモジュールで定義されている関数でToast UIを表示する際は、関数を呼び出す側で以下のように
 * ToastContainerコンポーネントを描画しておく必要があります。
 *
 *   import { ToastContainer } from "react-toastify";
 *   import { showSuccessToast } from "./toast";
 *
 *   export const Parent: React.FC<{}> = () => {
 *     return (
 *       <>
 *         <ToastContainer ... />
 *         {showSuccessToast("...")}
 *       </>
 *     );
 *   };
 */

import { ToastOptions, toast } from "react-toastify";

/**
 * toast()に与えるオプションのうち種類を問わず共通するもの。
 * https://fkhadra.github.io/react-toastify/api/toast
 */
const commonOptions: ToastOptions = {
  closeOnClick: true,
  draggable: false,
  hideProgressBar: false,
  pauseOnHover: true,
  position: "top-center",
  progress: undefined,
  theme: "colored",
};

/**
 * エラーメッセージをToast UIとして表示します。
 */
export const showErrorToast = (message: string): void => {
  toast.error(message, Object.assign({}, { autoClose: false }, commonOptions));
};

/**
 * 成功メッセージをToast UIとして表示します。
 */
export const showSuccessToast = (message: string): void => {
  // react-toastifyのデフォルトのCSSではsuccessが緑色、infoが青色になっており、
  // ここでsuccessを使うとCAのテーマと合わないため、あえて成功時でもinfoを
  // 使うようにしています。
  toast.info(message, Object.assign({}, { autoClose: 5000 }, commonOptions));
};
