import React from 'react';
import PropTypes from 'prop-types';

/**
 * FontAwesomeアイコンコンポーネント
 */
export default class Icon extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {string} name アイコン名(FontAwesomeの "fa-XXX" のXXXの部分)
   * @property {?string} tooltip ツールチップ用文字列
   */
  static get propTypes() {
    return({
      name: PropTypes.string.isRequired,
      tooltip: PropTypes.string,
    });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    const classesForIcon = `fa fa-${this.props.name}`;

    if (this.props.tooltip == null) {
      return <i aria-hidden className={classesForIcon}></i>;
    }

    return (
      <span
        data-placement="bottom"
        data-toggle="tooltip"
        title={this.props.tooltip}
      >
        <i aria-hidden className={classesForIcon}></i>
      </span>
    );
  }
}
