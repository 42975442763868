import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import withBootstrapTooltip from '../withBootstrapTooltip.js';

const I18n = window.I18n; // i18n-js
const I18N_SCOPE = 'javascript.post_process_assignments_panel';

/**
 * ジョブ作成フォームにおける後処理作成時のダイアログオープンボタン
 */
class DialogOpenButton extends React.Component {
  /**
   * propTypes
   * @return {object}
   * @property {object} addPostProcessContainerEmitter EventEmitter
   * @property {string} postProcessType postProcessType 後処理割り当てパネルのclass属性値
   * @property {function(element: DOMNode)} tooltipRef
   */
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
      postProcessType: PropTypes.string.isRequired,
      tooltipRef: PropTypes.func
    });
  }

  /**
   * @override
   */
  constructor(props) {
    super(props);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <div ref={this.props.tooltipRef}>
        <button
          type="button"
          className={`ca-post-process-assignment-panel__add-button--${this.props.postProcessType}`}
          onClick={() => { this.props.emitter.emit(Constants.EVENT_OPEN_DIALOG); }}
          data-toggle="tooltip"
          data-container="body"
          title={I18n.t('add_post_process', { scope: I18N_SCOPE })}
        >
          <span className={`fa fa-plus-circle ca-post-process-assignment-panel__add-icon--${this.props.postProcessType}`}></span>
        </button>
      </div>
    );
  }
}

export default withBootstrapTooltip(DialogOpenButton);
