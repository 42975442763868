import PropTypes from 'prop-types';
import React from 'react';

import ComplianceTypeSelectBox from './ComplianceTypeSelectBox.jsx';
import TotalCountText from '../record_table/TotalCountText.jsx';

/**
 * テーブル上部のフィルタリング用UI。
 * 見た目の制御だけに責務を持ちます。
 */
export default class PolicyLogTableHeadroom extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {Object} emitter EventEmitter
   * @property {number?} totalCount フィルタリング条件に該当する全件数(nullの場合は非表示)
   */
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
      totalCount: PropTypes.number,
    });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <div className="ca-table-headroom" style={{marginBottom:'20px'}}>
        <div className="ca-table-headroom__primary">
          <div className="ca-search-box">
            <ComplianceTypeSelectBox emitter={this.props.emitter} />
            {this.props.totalCount != null &&
              <span className="ca-search-box__metainfo">
                <TotalCountText value={this.props.totalCount} />
              </span>
            }
          </div>
        </div>
        <div className="ca-table-headroom__secondary">
        </div>
      </div>
    );
  }
}
