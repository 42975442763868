import jQuery from "jquery";
import React, { useEffect, useMemo } from "react";
import ReactModal from "react-modal";

import { ToggleableJob } from "./types";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.toggle_job_modal";

export const ToggleJobModal: React.FC<{
  job: ToggleableJob;
  dialogMessage: string;
  showDialog: boolean;
  onCancelButtonClick: () => void;
}> = (props) => {
  const truncatedName = props.job.name.length > 100 ? props.job.name.slice(0, 100) + "..." : props.job.name;

  const jobId = useMemo(() => I18n.t("job_id", { scope: I18N_SCOPE }), [I18n.locale]);

  // Initialize
  useEffect(() => {
    ReactModal.setAppElement("body");
  }, []);

  return (
    <ReactModal
      className="ca-job-toggle-modal__content"
      isOpen={props.showDialog}
      onRequestClose={() => props.onCancelButtonClick()}
      overlayClassName="ca-job-toggle-modal__overlay"
      role="dialog"
    >
      <div>
        <p>{props.dialogMessage}</p>
        <p className="ca-job-toggle-modal__content__job-id">
          <strong>
            {jobId}:{props.job.id}
          </strong>
        </p>
        <p className="text-break">
          <strong>{truncatedName}</strong>
        </p>
        <ul className="ca-job-toggle-modal__caution">
          {props.job.show_downtime_caution && (
            <li className="ca-job-toggle-modal__caution__msg">
              <DowntimeCaution />
            </li>
          )}
        </ul>
      </div>
      <div className="ca-job-toggle-modal__content-buttons">
        <CancelButton onClick={() => props.onCancelButtonClick()} />
        <OkButton toggleJobPath={props.job.toggle_trigger_job_path} />
      </div>
    </ReactModal>
  );
};

const CancelButton: React.FC<{
  onClick: () => void;
}> = (props) => {
  return (
    <button className="btn btn-cancel btn-negative" onClick={props.onClick} type="button">
      <span className="fa fa-ban marginR5"></span>Cancel
    </button>
  );
};

/*
 * OKのボタン押下時はAJAXではなくformブロックでPOSTリクエストを行い
 * リダイレクト等の処理をすべてサーバーサイドに委譲する。
 */
const OkButton: React.FC<{
  toggleJobPath: string;
}> = (props) => {
  const csrfToken = jQuery("meta[name='csrf-token']").attr("content") as string;
  return (
    <form className="inline-block" method="post" action={props.toggleJobPath}>
      <input name="utf8" type="hidden" value="✓" />
      <input name="authenticity_token" type="hidden" value={csrfToken} />
      <button name="button" className="btn btn-danger btn-positive" type="submit">
        <span className="fa fa-check marginR5"></span>OK
      </button>
    </form>
  );
};

const DowntimeCaution: React.FC = () => {
  const downtimeCautionText = useMemo(() => I18n.t("downtime_caution", { scope: I18N_SCOPE }), [I18n.locale]);
  return <>{downtimeCautionText}</>;
};
