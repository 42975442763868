import { TriggerJob, CronJob, ScheduleJob, WebhookJob, SQSJob, ImmediateExecutionJob } from "./types";
import { CronTriggerDetails } from "./CronTriggerDetails";
import { HTTPTriggerDetails } from "./HTTPTriggerDetails";
import { SQSTriggerDetails } from "./SQSTriggerDetails";
import { ScheduleTriggerDetails } from "./ScheduleTriggerDetails";
import { ImmediateExecutionTriggerDetails } from "./ImmediateExecutionTriggerDetails";

type Props = {
  job: TriggerJob | undefined;
};

/**
 * ジョブワークフロー新規作成・編集画面で表示するジョブ詳細用のコンポーネント
 * @public
 */
export const WorkflowTriggerJobDetail = (props: Props): JSX.Element | null => {
  const triggerJob = props.job;

  if (triggerJob == undefined) {
    return null;
  }

  switch (triggerJob.rule_type) {
    case "cron":
      return CronTriggerDetails(triggerJob as CronJob);
    case "webhook":
      return HTTPTriggerDetails(triggerJob as WebhookJob);
    case "sqs_v2":
      return SQSTriggerDetails(triggerJob as SQSJob);
    case "schedule":
      return ScheduleTriggerDetails(triggerJob as ScheduleJob);
    case "immediate_execution":
      // TODO: 機能フラグ v27_job_workflow_improvement が不要になったら引数の triggerJob を削除する
      return ImmediateExecutionTriggerDetails(triggerJob as ImmediateExecutionJob);
    default:
      return null;
  }
};
