import React from 'react';
import PropTypes from 'prop-types';

const $ = window.jQuery;

/**
 * Ajax通信中に表示されるアニメーションコンポーネント
 * アニメーションの表示にSpin.jsを利用しています。
 *
 * このコンポーネントの描画結果はSPAN要素となります。
 */
class DashboardSpinner extends React.Component {
  /**
   * デフォルトのプロパティ値を返します。
   * React内部から呼び出されます。
   *
   * @return {object}
   */
  static get defaultProps() {
    return({
      // spin.jsに渡すオプション
      spinOptions: {
        lines: 9,
        length: 3,
        width: 3,
        radius: 5,
        top: '15px',
        left: '220px'
      }
    });
  }

  /**
   * propTypes
   * @property {}
   */
  static get propTypes() {
    return({
      spinOptions: PropTypes.object
    });
  }

  /**
   * オブジェクトを初期化します。
   */
  constructor(props) {
    super(props);
    this.element = null;
  }

  componentDidMount() {
    this.initializeSpinner();
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return(
      <span ref={(span) => { this.element = span; }}></span>
    );
  }

  /**
   * Spin.jsの初期化を行います。
   */
  initializeSpinner() {
    $(document).ajaxStart(() => {
      $(this.element).spin(this.props.spinOptions);
    }).ajaxComplete(() => {
      $(this.element).spin(false);
    });
  }
}

export default DashboardSpinner;
