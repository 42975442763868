import React from "react";
import { useToggle } from "react-use";

import { GoogleCloudAccount } from "./types";
import { EditDialog } from "./EditDialog";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.group_google_cloud_account.edit_button";

/** EditButtonのプロパティ */
type Props = {
  /** 編集対象のGoogle Cloudアカウント */
  account: GoogleCloudAccount;
  /** 更新完了後に実行させる処理 */
  onUpdated: () => void;
};

/**
 * 編集ボタン
 *
 * Google Cloudアカウント一覧に表示される編集ボタンおよびボタン押下時に表示される編集ダイアログ。
 */
const EditButton: React.FC<Props> = (props): JSX.Element => {
  /** ダイアログの開閉状態 */
  const [isDialogOpen, toggleDialog] = useToggle(false);

  return (
    <div className="inline-block">
      <Button onClick={(): void => toggleDialog(true)} />

      <EditDialog
        account={props.account}
        isOpen={isDialogOpen}
        onCancel={(): void => toggleDialog(false)}
        onUpdated={(): void => {
          props.onUpdated();
          toggleDialog(false);
        }}
      />
    </div>
  );
};

/**
 * 編集ボタン
 */
const Button: React.FC<{
  /** ボタン押下時に実行させる処理 */
  onClick: () => void;
}> = (props): JSX.Element => {
  return (
    <button type="button" className="btn" onClick={props.onClick}>
      <span className="fa fa-pencil marginR5" />
      <span className="font-12px">{I18n.t("edit", { scope: I18N_SCOPE })}</span>
    </button>
  );
};

export { EditButton };
