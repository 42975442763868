import React, { useState } from "react";

import { ErrorArea } from "./ErrorArea";
import { ErrorArea2 } from "../groups/ErrorArea2";

const I18n = window.I18n;
const I18N_SCOPE = "javascript.google_cloud_accounts.credentials_json_input_field";

type Props = {
  disabled: boolean;
  errors: string[];
  /** エラー表示に使うコンポーネントをErrorAreaではなくErrorArea2にする */
  useError2?: boolean;
  name: string;
  onChange: (newValue: string) => void;
  placeholder: string;
  required: boolean;
  value: string;
};

/**
 * Google Cloudのアクセスキー(JSON形式のクレデンシャル)入力フィールド
 *
 * LABELとTEXTAREAが縦に並んだ状態のフォームフィールドです。
 */
const CredentialsJsonInputField: React.FC<Props> = (props) => {
  const [value, setValue] = useState(props.value || "");

  return (
    <div className="form-group marginB20">
      <label className="ca-group__aws-account-form__label" htmlFor="GoogleCloudAccountForm-credentials-json">
        {I18n.t("label", { scope: I18N_SCOPE })}
        {props.required && <span className="ca-group__aws-account-form__required-mark">*</span>}
      </label>

      <textarea
        className="form-control"
        disabled={props.disabled}
        id="GoogleCloudAccountForm-credentials-json"
        name={props.name}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => {
          const newValue = event.target.value;
          setValue(newValue);
          props.onChange(newValue);
        }}
        placeholder={props.placeholder}
        style={{ height: "140px" }}
        value={value}
      />

      <p className="help-block">{I18n.t("hint", { scope: I18N_SCOPE })}</p>

      {!props.useError2 && <ErrorArea errors={props.errors} />}
      {props.useError2 && <ErrorArea2 errors={props.errors} />}
    </div>
  );
};

export { CredentialsJsonInputField };
