/**
 * TODO: 機能フラグ v27_job_workflow_improvement が不要になったらこのコンポーネントを削除する
 */

import EventEmitter from "events";
import React from "react";
import PropTypes from "prop-types";

import Constants from "./Constants.js";
import { JobWorkflowFirstJobDetail } from "./JobWorkflowFirstJobDetail";
import { ProviderIcon } from "../ProviderIcon";
import StepCircle from "./StepCircle.jsx";
import StepsHint from "./StepsHint.jsx";

const I18n = window.I18n; // i18n-js

/**
 * ジョブワークフロー作成フォームコンテナ
 */
export default class OldJobWorkflowEditFormContainer extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {object} group ジョブワークフローのグループ
   * @property {object} jobWorkflow 編集対象のジョブワークフロー
   * @property {string} jobWorkflowPath ジョブワークフロー作成リクエストの送信先パス
   * @property {boolean} jobWritable ジョブワークフローのグループに対してジョブの更新権限を付与されているかどうか
   * @property {string} migrateJobWorkflowPath ジョブワークフローのマイグレーションリクエストの送信先パス
   * @property {string, null} selectedTriggerJobId1
   *   先頭ジョブとして現在設定されているジョブID(編集モード用)
   * @property {string, null} selectedTriggerJobId2
   *   後続ジョブ2として現在設定されているジョブID(編集モード用)
   * @property {string, null} selectedTriggerJobId3
   *   後続ジョブ3として現在設定されているジョブID(編集モード用)
   * @property {string, null} selectedTriggerJobId4
   *   後続ジョブ4として現在設定されているジョブID(編集モード用)
   * @property {string, null} selectedTriggerJobId5
   *   後続ジョブ5として現在設定されているジョブID(編集モード用)
   * @property {string, null} selectedTriggerJobId6
   *   後続ジョブ6として現在設定されているジョブID(編集モード用)
   * @property {string, null} selectedTriggerJobId7
   *   後続ジョブ7として現在設定されているジョブID(編集モード用)
   * @property {string, null} selectedTriggerJobId8
   *   後続ジョブ8として現在設定されているジョブID(編集モード用)
   * @property {string, null} selectedTriggerJobId9
   *   後続ジョブ9として現在設定されているジョブID(編集モード用)
   * @property {string, null} selectedTriggerJobId10
   *   後続ジョブ10として現在設定されているジョブID(編集モード用)
   * @property {Object} triggerJobsForFirstJobs 先頭ジョブの選択肢となるジョブ群のオブジェクト
   * @property {Object} triggerJobsForFollowingJobs 後続ジョブの選択肢となるジョブ群のオブジェクト
   */
  static get propTypes() {
    return {
      group: PropTypes.object.isRequired,
      jobWorkflow: PropTypes.object,
      jobWorkflowPath: PropTypes.string.isRequired,
      jobWritable: PropTypes.bool.isRequired,
      migrateJobWorkflowPath: PropTypes.string.isRequired,
      selectedTriggerJobId1: PropTypes.string,
      selectedTriggerJobId2: PropTypes.string,
      selectedTriggerJobId3: PropTypes.string,
      selectedTriggerJobId4: PropTypes.string,
      selectedTriggerJobId5: PropTypes.string,
      selectedTriggerJobId6: PropTypes.string,
      selectedTriggerJobId7: PropTypes.string,
      selectedTriggerJobId8: PropTypes.string,
      selectedTriggerJobId9: PropTypes.string,
      selectedTriggerJobId10: PropTypes.string,
      selectedTriggerJobId11: PropTypes.string,
      selectedTriggerJobId12: PropTypes.string,
      selectedTriggerJobId13: PropTypes.string,
      selectedTriggerJobId14: PropTypes.string,
      selectedTriggerJobId15: PropTypes.string,
      selectedTriggerJobId16: PropTypes.string,
      selectedTriggerJobId17: PropTypes.string,
      selectedTriggerJobId18: PropTypes.string,
      selectedTriggerJobId19: PropTypes.string,
      selectedTriggerJobId20: PropTypes.string,
      triggerJobsForFirstJobs: PropTypes.object,
      triggerJobsForFollowingJobs: PropTypes.object,
    };
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    const firstJob = props.triggerJobsForFirstJobs[props.selectedTriggerJobId1];
    let isExpiredFirstJob = false;

    if (firstJob && firstJob.rule_type == "schedule") {
      isExpiredFirstJob = firstJob.expired_schedule_job;
    }

    this.state = {
      active: props.jobWorkflow.active ? "1" : "0",
      isExpiredFirstJob: isExpiredFirstJob, // 先頭ジョブがExpireしているスケジュールトリガーかどうか
      isLoadingJobs: false, // グループに属するジョブ情報の取得中かどうか
      isMigrating: false, // ワークフローのマイグレーション中かどうか
      isSubmitting: false, // フォームを送信中かどうか
      isSubmitted: false, // フォーム送信を完了したかどうか
      jobWorkflowStateVisibilityValue: firstJob.rule_type == "immediate_execution" ? "hidden" : "visible",
      name: props.jobWorkflow.name, // ジョブワークフロー名
      submitErrorMessage: "", // フォーム送信時のエラーメッセージ
      triggerJobId1: props.selectedTriggerJobId1,
      triggerJobId2: props.selectedTriggerJobId2,
      triggerJobId3: props.selectedTriggerJobId3,
      triggerJobId4: props.selectedTriggerJobId4,
      triggerJobId5: props.selectedTriggerJobId5,
      triggerJobId6: props.selectedTriggerJobId6,
      triggerJobId7: props.selectedTriggerJobId7,
      triggerJobId8: props.selectedTriggerJobId8,
      triggerJobId9: props.selectedTriggerJobId9,
      triggerJobId10: props.selectedTriggerJobId10,
      triggerJobId11: props.selectedTriggerJobId11,
      triggerJobId12: props.selectedTriggerJobId12,
      triggerJobId13: props.selectedTriggerJobId13,
      triggerJobId14: props.selectedTriggerJobId14,
      triggerJobId15: props.selectedTriggerJobId15,
      triggerJobId16: props.selectedTriggerJobId16,
      triggerJobId17: props.selectedTriggerJobId17,
      triggerJobId18: props.selectedTriggerJobId18,
      triggerJobId19: props.selectedTriggerJobId19,
      triggerJobId20: props.selectedTriggerJobId20,
    };

    this.handleChangeActive = this.handleChangeActive.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeTriggerJob = this.handleChangeTriggerJob.bind(this);
    this.handleClickDelete = this.handleClickDelete.bind(this);
    this.handleMigrate = this.handleMigrate.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);

    this.initEventEmitter();
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    let formClasses = "ca-job-workflow__form ca-job-workflow__form--edit";

    if (this.state.isExpiredFirstJob) {
      formClasses += " ca-job-workflow__form--expired-schedule";
    }

    return (
      <React.Fragment>
        <form className={formClasses} onSubmit={this.handleSubmitForm}>
          <section className="ca-job-workflow__form-fieldset-wrapper">
            <fieldset className="ca-job-workflow__form-fieldset">
              <h2 className="ca-job-workflow__form-fieldset-heading">
                <i aria-hidden className="fa fa-pencil-square-o marginR5"></i>
                {I18n.t("basic_settings", { scope: Constants.I18N_SCOPE })}
              </h2>
              <div className="ca-job-workflow__form-fields">
                <div
                  className="
                  form-group
                  ca-job-workflow__form-field
                "
                >
                  <label
                    htmlFor="ca-job-workflow-name-field"
                    className="
                      ca-job-workflow__form-label
                      ca-job-workflow__form-label--required
                    "
                  >
                    {I18n.t("name", { scope: Constants.I18N_SCOPE })}:
                  </label>
                  <div className="ca-job-workflow__form-control">
                    <input
                      className="form-control"
                      id="ca-job-workflow-name-field"
                      onChange={this.handleChangeName}
                      type="text"
                      value={this.state.name}
                    />
                  </div>
                </div>
                <div className="form-group ca-job-workflow__form-field">
                  <label htmlFor="ca-job-workflow-group-id-field" className="ca-job-workflow__form-label">
                    {I18n.t("group", { scope: Constants.I18N_SCOPE })}
                  </label>
                  <div className="ca-job-workflow__form-control">{this.props.group.name}</div>
                </div>
                <div
                  className="form-group ca-job-workflow__form-field"
                  style={{ visibility: this.state.jobWorkflowStateVisibilityValue }}
                >
                  <label
                    className="
                      ca-job-workflow__form-label
                      ca-job-workflow__form-label--required
                    "
                  >
                    {I18n.t("state", { scope: Constants.I18N_SCOPE })}
                  </label>
                  <div className="ca-job-workflow__form-control">
                    <label>
                      <input
                        type="radio"
                        name="state"
                        value="1"
                        checked={this.state.active == "1"}
                        className="marginR5"
                        onChange={() => this.setState({ active: "1" })}
                      />
                      {I18n.t("active", { scope: Constants.I18N_SCOPE })}
                    </label>
                    <label className="marginL20">
                      <input
                        type="radio"
                        name="state"
                        value="0"
                        checked={this.state.active == "0"}
                        className="marginR5"
                        onChange={() => this.setState({ active: "0" })}
                      />
                      {I18n.t("inactive", { scope: Constants.I18N_SCOPE })}
                    </label>
                  </div>
                </div>
              </div>
            </fieldset>
          </section>

          <section className="ca-job-workflow__form-fieldset-wrapper">
            <fieldset className="ca-job-workflow__form-fieldset">
              <div className="ca-job-workflow__head-wrapper">
                <h2 className="ca-job-workflow__form-fieldset-heading">
                  <i aria-hidden className="fa fa-dashboard marginR5"></i>
                  {I18n.t("job_settings", { scope: Constants.I18N_SCOPE })}
                </h2>
                {this.renderMigrateButton()}
              </div>
              <div className="ca-job-workflow__form-notice">
                <StepsHint />
              </div>
              {this.renderJobWorkflowTriggerJob()}
              <div className="ca-job-workflow__form-fields">
                {this.renderFirstStep()}
                {this.renderFollowingStep(2)}
                {this.renderFollowingStep(3)}
                {this.renderFollowingStep(4)}
                {this.renderFollowingStep(5)}
                {this.renderFollowingStep(6)}
                {this.renderFollowingStep(7)}
                {this.renderFollowingStep(8)}
                {this.renderFollowingStep(9)}
                {this.renderFollowingStep(10)}
                {this.renderFollowingStep(11)}
                {this.renderFollowingStep(12)}
                {this.renderFollowingStep(13)}
                {this.renderFollowingStep(14)}
                {this.renderFollowingStep(15)}
                {this.renderFollowingStep(16)}
                {this.renderFollowingStep(17)}
                {this.renderFollowingStep(18)}
                {this.renderFollowingStep(19)}
                {this.renderFollowingStep(20)}
              </div>
            </fieldset>
          </section>

          <section className="ca-job-workflow__form-fieldset-wrapper">
            <fieldset className="ca-job-workflow__form-fieldset">
              {this.state.submitErrorMessage && (
                <p className="ca-job-workflow__form-error-message">{this.state.submitErrorMessage}</p>
              )}

              <div className="ca-btn-block ca-btn-block--no-border">
                <div className="ca-btn-block__primary">
                  <button className="btn btn-primary btn-lg" disabled={!this.isFormFilled()} type="submit">
                    <i className="fa fa-check-circle fa-lg"></i>{" "}
                    {I18n.t(this.state.isSubmitting ? "submitting" : "update", { scope: Constants.I18N_SCOPE })}
                  </button>
                </div>
                <div className="ca-btn-block__secondary">
                  <a className="ca-btn-block__link text-danger" href="#" onClick={(e) => this.handleClickDelete(e)}>
                    <span className="fa fa-trash"></span>
                    {I18n.t("button_to_delete", { scope: Constants.I18N_SCOPE })}
                  </a>
                </div>
              </div>
            </fieldset>
          </section>
        </form>
      </React.Fragment>
    );
  }

  renderJobWorkflowTriggerJob() {
    const job =
      this.state.triggerJobId1 !== "" ? this.props.triggerJobsForFirstJobs[this.state.triggerJobId1] : undefined;
    return <JobWorkflowFirstJobDetail job={job} />;
  }

  renderFirstStep() {
    return (
      <div className="ca-job-workflow__form-field" data-qa={`step-1`}>
        <div className="ca-job-workflow__form-step-row">
          <StepCircle active={this.state.triggerJobId1 != ""} step={1} />
          <select
            className="form-control ca-job-workflow__form-control ca-job-workflow__form-step-select"
            onChange={(e) => this.handleChangeTriggerJob(1, e.target.value)}
            value={this.state.triggerJobId1}
          >
            <option value="">{I18n.t("blank_option", { scope: Constants.I18N_SCOPE })}</option>
            {Object.keys(this.props.triggerJobsForFirstJobs).map((id) => {
              const job = this.props.triggerJobsForFirstJobs[id];
              if (this.getJobWorkflowImprovementEnabled()) {
                // ワークフロートリガージョブはグレーアウトして表示する
                return (
                  <option key={job.id} value={job.id} disabled={job.action_type == "no_action"}>
                    {job.name}
                  </option>
                );
              } else {
                return (
                  <option key={job.id} value={job.id}>
                    {job.name}
                  </option>
                );
              }
            })}
          </select>

          <div>
            <a
              href="#"
              onClick={(e) => {
                this.handleChangeTriggerJob(1, "");
                e.preventDefault();
              }}
            >
              <i className="fa fa-trash-o ca-job-workflow__form-step-trash-icon"></i>
            </a>
          </div>

          {this.state.triggerJobId1 && (
            <React.Fragment>
              <div className="ca-job-workflow__form-step-action-type">
                <ProviderIcon
                  identifier={this.props.triggerJobsForFirstJobs[this.state.triggerJobId1].provider_identifier}
                />
                {this.props.triggerJobsForFirstJobs[this.state.triggerJobId1].action_type_i18n}
              </div>
              <div className="ca-job-workflow__form-step-link">
                <a
                  className="marginL10"
                  href={this.props.triggerJobsForFirstJobs[this.state.triggerJobId1].show_url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i aria-hidden className="fa fa-external-link"></i>{" "}
                  {I18n.t("link_to_job", { scope: Constants.I18N_SCOPE })}
                </a>
              </div>
            </React.Fragment>
          )}
          {this.getJobWorkflowImprovementEnabled() && (
            <div className="ca-job-workflow__form-step-msg">
              <i className="fa-solid fa-circle-exclamation marginR5"></i>
              <span>{I18n.t("workflow_trigger_job_select_after_migration", { scope: Constants.I18N_SCOPE })}</span>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderFollowingStep(step) {
    const currentValue = this.state[`triggerJobId${step}`];
    let rowClasses = "ca-job-workflow__form-step-row";
    if (step == 1) {
      rowClasses = `${rowClasses} ca-job-workflow__form-step-row--first`;
    } else if (step == 10) {
      rowClasses = `${rowClasses} ca-job-workflow__form-step-row--last`;
    }

    return (
      <div className="ca-job-workflow__form-field" data-qa={`step-${step}`}>
        <div className={rowClasses}>
          <StepCircle active={currentValue != ""} step={step} />

          <select
            className="form-control ca-job-workflow__form-control ca-job-workflow__form-step-select"
            value={currentValue}
            onChange={(e) => this.handleChangeTriggerJob(step, e.target.value)}
          >
            <option value="">{I18n.t("blank_option", { scope: Constants.I18N_SCOPE })}</option>

            {currentValue && (
              <option key={currentValue} value={currentValue}>
                {this.props.triggerJobsForFollowingJobs[currentValue].name}
              </option>
            )}

            {this.availableFollowingJobIds().map((id) => {
              const job = this.props.triggerJobsForFollowingJobs[id];
              return (
                <option key={job.id} value={job.id}>
                  {job.name}
                </option>
              );
            })}
          </select>

          <div>
            <a
              href="#"
              onClick={(e) => {
                this.handleChangeTriggerJob(step, "");
                e.preventDefault();
              }}
            >
              <i className="fa fa-trash-o ca-job-workflow__form-step-trash-icon"></i>
            </a>
          </div>

          {currentValue && (
            <React.Fragment>
              <div className="ca-job-workflow__form-step-action-type">
                <ProviderIcon identifier={this.props.triggerJobsForFollowingJobs[currentValue].provider_identifier} />
                {this.props.triggerJobsForFollowingJobs[currentValue].action_type_i18n}
              </div>
              <div className="ca-job-workflow__form-step-link">
                <a
                  className="marginL10"
                  href={this.props.triggerJobsForFollowingJobs[currentValue].show_url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i aria-hidden className="fa fa-external-link"></i>{" "}
                  {I18n.t("link_to_job", { scope: Constants.I18N_SCOPE })}
                </a>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  renderMigrateButton() {
    const isDisabled = this.props.jobWorkflow.active == "1" || this.state.isMigrating;
    const showMigrateButton = this.getJobWorkflowImprovementEnabled() && this.props.jobWritable;

    if (!showMigrateButton) {
      return null;
    }

    return (
      <div id="ca-job-workflow-migrate-button" onClick={(e) => this.handleMigrate(e)}>
        <a className="btn btn-danger" href="#" disabled={isDisabled}>
          <i className="fa-solid fa-rotate marginR5"></i>
          <span>{I18n.t("migrate_button", { scope: Constants.I18N_SCOPE })}</span>
        </a>
      </div>
    );
  }

  availableFollowingJobIds() {
    const ids = Object.keys(this.props.triggerJobsForFollowingJobs);
    return ids.filter((id) => {
      return (
        id != this.state.triggerJobId2 &&
        id != this.state.triggerJobId3 &&
        id != this.state.triggerJobId4 &&
        id != this.state.triggerJobId5 &&
        id != this.state.triggerJobId6 &&
        id != this.state.triggerJobId7 &&
        id != this.state.triggerJobId8 &&
        id != this.state.triggerJobId9 &&
        id != this.state.triggerJobId10 &&
        id != this.state.triggerJobId11 &&
        id != this.state.triggerJobId12 &&
        id != this.state.triggerJobId13 &&
        id != this.state.triggerJobId14 &&
        id != this.state.triggerJobId15 &&
        id != this.state.triggerJobId16 &&
        id != this.state.triggerJobId17 &&
        id != this.state.triggerJobId18 &&
        id != this.state.triggerJobId19 &&
        id != this.state.triggerJobId20
      );
    });
  }

  /**
   * 組織に対して機能フラグ v27_job_workflow_improvement が有効かどうかを返します。
   *
   * @private
   * @return {boolean}
   */
  getJobWorkflowImprovementEnabled() {
    const metaTag = document.querySelector('meta[name="v27_job_workflow_improvement"]');
    if (metaTag) {
      return metaTag.getAttribute("content") === "true";
    }

    return false;
  }

  /**
   * ワークフローの状態の現在値を変更します。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleChangeActive(event) {
    this.setState({ active: event.target.value });
  }

  /**
   * ジョブワークフロー名の現在値を変更します。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }

  /**
   * 指定されたステップのジョブを変更します。
   *
   * 指定されたのが先頭ジョブかつ、予定日時に未来の日時が無い
   * スケジュールトリガーだった場合は編集フォームに警告を表示します。
   *
   * @private
   * @param {string} step ステップ(1〜10)
   * @param {string} triggerJobId 変更後のジョブID
   */
  handleChangeTriggerJob(step, triggerJobId) {
    const key = `triggerJobId${step}`;
    this.setState({ [key]: triggerJobId });

    if (step != 1) {
      // 先頭ジョブ以外の場合
      return;
    }

    const job = this.props.triggerJobsForFirstJobs[triggerJobId];

    let active = this.state.active;
    let isExpiredFirstJob = false;
    let jobWorkflowStateVisibilityValue = "visible";

    if (job && job.rule_type == "schedule") {
      isExpiredFirstJob = job.expired_schedule_job;
    }

    if (job && job.rule_type == "immediate_execution") {
      // ジョブワークフローの状態は先頭ジョブと連動するため、
      // 編集中の (実行中ではない) 先頭ジョブが手動トリガーの場合は状態が常に「停止」になる。
      active = "0";
      // 先頭ジョブが手動トリガーの場合は、ユーザーが状態を認識する必要がないため、状態を表示しない
      jobWorkflowStateVisibilityValue = "hidden";
    }

    this.setState({
      active: active,
      isExpiredFirstJob: isExpiredFirstJob,
      jobWorkflowStateVisibilityValue: jobWorkflowStateVisibilityValue,
    });
  }

  /**
   * 削除処理を行います。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleClickDelete(event) {
    event.preventDefault();

    const result = window.confirm(I18n.t("confirm_delete", { scope: Constants.I18N_SCOPE }));
    if (result == false) {
      return;
    }

    window.jQuery
      .ajax({
        method: "DELETE",
        dataType: "json",
        url: this.props.jobWorkflowPath,
        beforeSend: () => {
          this.setState({
            isSubmitting: true,
            isSubmitted: false,
          });
        },
      })
      .done((data) => {
        this.setState(
          {
            // isSubmitting: false,
            isSubmitted: true,
          },
          () => {
            window.location.href = data.url;
          }
        );
      })
      .fail((data) => {
        const error = I18n.t("delete_failed", { scope: Constants.I18N_SCOPE });
        const json = data.responseJSON;
        const message = `${error}: ${json.error}`;
        this.setState({ isSubmitting: false });
        alert(message);
      });
  }

  /**
   * ワークフローを新ワークフローに変更します。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleMigrate(event) {
    event.preventDefault();

    this.setState({ isMigrating: true });

    if (this.props.jobWorkflow.active == "1") {
      alert(I18n.t("cannot_migrate_active_workflow", { scope: Constants.I18N_SCOPE }));
      this.setState({ isMigrating: false });
      return;
    }

    if (window.confirm(I18n.t("confirm_migrate_workflow", { scope: Constants.I18N_SCOPE }))) {
      window.jQuery
        .ajax({
          method: "PATCH",
          url: this.props.migrateJobWorkflowPath,
          dataType: "json",
        })
        .done(() => {
          window.location.reload(true);
        })
        .fail((data) => {
          alert(data.responseJSON.error);
        })
        .always(() => {
          this.setState({ isMigrating: false });
        });
    } else {
      this.setState({ isMigrating: false });
    }
  }

  /**
   * フォームを送信します。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleSubmitForm(event) {
    event.preventDefault();
    window.jQuery
      .ajax({
        method: "PATCH",
        data: {
          name: this.state.name,
          active: this.state.active,
          first_job_id: this.state.triggerJobId1,
          following_job_ids: [
            this.state.triggerJobId2,
            this.state.triggerJobId3,
            this.state.triggerJobId4,
            this.state.triggerJobId5,
            this.state.triggerJobId6,
            this.state.triggerJobId7,
            this.state.triggerJobId8,
            this.state.triggerJobId9,
            this.state.triggerJobId10,
            this.state.triggerJobId11,
            this.state.triggerJobId12,
            this.state.triggerJobId13,
            this.state.triggerJobId14,
            this.state.triggerJobId15,
            this.state.triggerJobId16,
            this.state.triggerJobId17,
            this.state.triggerJobId18,
            this.state.triggerJobId19,
            this.state.triggerJobId20,
          ],
        },
        dataType: "json",
        url: this.props.jobWorkflowPath,
        beforeSend: () => {
          this.setState({
            isSubmitting: true,
            isSubmitted: false,
          });
        },
      })
      .done((data) => {
        this.setState(
          {
            // isSubmitting: false,
            isSubmitted: true,
          },
          () => {
            window.location.href = data.url;
          }
        );
      })
      .fail((data) => {
        const error = I18n.t("update_failed", { scope: Constants.I18N_SCOPE });
        const json = data.responseJSON;
        const message = `${error} ${json.error}`;
        this.setState({ isSubmitting: false, submitErrorMessage: message });
      });
  }

  isFormFilled() {
    return (
      this.state.name != "" &&
      this.state.active != "" &&
      this.state.triggerJobId1 != "" &&
      this.state.triggerJobId2 != "" &&
      this.state.isMigrating == false
    );
  }

  _experimental_isFormFilled() {
    // 先頭ステップが選択されているかどうか
    const firstStepFilled = this.state.triggerJobId1 != "";
    // 後続ステップが1つでも選択されているかどうか
    const atLeastOneFollowingStepsFilled =
      this.state.triggerJobId2 != "" ||
      this.state.triggerJobId3 != "" ||
      this.state.triggerJobId4 != "" ||
      this.state.triggerJobId5 != "" ||
      this.state.triggerJobId6 != "" ||
      this.state.triggerJobId7 != "" ||
      this.state.triggerJobId8 != "" ||
      this.state.triggerJobId9 != "" ||
      this.state.triggerJobId10 != "";
    // 後続ステップがすべて空かどうか
    const allFollowingStepsEmpty =
      this.state.triggerJobId2 == "" &&
      this.state.triggerJobId3 == "" &&
      this.state.triggerJobId4 == "" &&
      this.state.triggerJobId5 == "" &&
      this.state.triggerJobId6 == "" &&
      this.state.triggerJobId7 == "" &&
      this.state.triggerJobId8 == "" &&
      this.state.triggerJobId9 == "" &&
      this.state.triggerJobId10 == "";

    if (this.state.name == "") {
      // 名前は必須
      return false;
    }

    if (firstStepFilled && allFollowingStepsEmpty) {
      // 先頭ジョブだけの指定は不可
      return false;
    }

    if (atLeastOneFollowingStepsFilled && !firstStepFilled) {
      // 後続ジョブが1つでも指定されていて先頭ジョブが未指定は不可
      return false;
    }

    return true;
  }

  /**
   * EventEmitterを初期化します。
   *
   * 各コンポーネント内で発生するイベントとイベントハンドラ関数の割り当てを行います。
   * 生成されたEventEmitterオブジェクトは this.emitter にアサインされます。
   *
   * @private
   */
  initEventEmitter() {
    this.emitter = new EventEmitter();
    this.emitter.on(Constants.EVENT_CHANGE_ACTIVE, this.handleChangeActive);
    this.emitter.on(Constants.EVENT_CHANGE_NAME, this.handleChangeName);
    this.emitter.on(Constants.EVENT_CHANGE_TRIGGER_JOB, this.handleChangeTriggerJob);
    this.emitter.on(Constants.EVENT_SUBMIT_FORM, this.handleSubmitForm);
  }
}
