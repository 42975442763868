import EventEmitter from 'events';
import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import UserTable from './UserTable.jsx';
import UserTableHeadroom from './UserTableHeadroom.jsx';

const I18n = window.I18n; // i18n-js

/**
 * ユーザーテーブルコンテナ
 *
 * ユーザー一覧テーブルの起点となるコンポーネントで、以下の責務を持ちます。
 *
 * - サーバーサイドからユーザー一覧情報のJSONをXHRで取得する
 * - キーワードによる絞り込みイベントが発生した際の処理(ユーザーの絞り込み)
 * - ソート用カラムおよびソート方向の変更操作が行われた際のステートの更新
 * - 各ユーザーの編集ボタンがクリックされた際の処理
 * - 各ユーザーの削除ボタンがクリックされた際の処理
 */
export default class UserTableContainer extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {string} currentUserType サインイン中のユーザーのユーザー種類
   * @property {string} editUserPath ユーザー編集ボタンのリンク先URLパス(ID部分は":id"とする)
   * @property {string} newUserPath ユーザー追加ボタンのリンク先URLパス
   * @property {string} usersPath ユーザー一覧を取得するためのURLパス
   * @property {number} usersPerPage 1ページに表示するユーザーの件数(オプション)
   */
  static get propTypes() {
    return({
      currentUserType: PropTypes.string.isRequired,
      editUserPath: PropTypes.string.isRequired,
      newUserPath: PropTypes.string.isRequired,
      usersPath: PropTypes.string.isRequired,
      usersPerPage: PropTypes.number,
    });
  }

  /**
   * デフォルトのプロパティを返します。
   *
   * @public
   * @return {Object}
   * @property {number} usersPerPage 1ページに表示するユーザーの件数
   */
  static get defaultProps() {
    return({
      usersPerPage: 30,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      errorMessage: null,
      pending: true,
      searchInput: '',
      // 現在のソート用カラム名
      sortColumn: Constants.COLUMN.CREATED_AT_TIME,
      // 現在のソート方向
      sortDirection: Constants.DIRECTION.DESC,
      users: [],
    };

    this.handleChangeCurrentPage = this.handleChangeCurrentPage.bind(this);
    this.handleChangeSearchInput = this.handleChangeSearchInput.bind(this);
    this.handleChangeSortColumn = this.handleChangeSortColumn.bind(this);

    this.initEventEmitter();
  }

  /**
   * コンポーネントがマウントされた際の処理を行います。
   * @public
   */
  componentDidMount() {
    this.fetchUsers();
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    const users = this.getFilteredUsers();

    return(
      <React.StrictMode>
        <UserTableHeadroom
          emitter={this.emitter}
          newUserPath={this.props.newUserPath}
          totalUsers={users.length}
        />
        <UserTable
          currentPage={this.state.currentPage}
          currentUserType={this.props.currentUserType}
          editUserPath={this.props.editUserPath}
          emitter={this.emitter}
          errorMessage={this.state.errorMessage}
          isPending={this.state.pending}
          searchInput={this.state.searchInput}
          sortColumn={this.state.sortColumn}
          sortDirection={this.state.sortDirection}
          users={users}
          usersPerPage={this.props.usersPerPage}
        />
      </React.StrictMode>
    );
  }

  /**
   * サーバーからユーザー一覧のJSONを取得して、コンポーネントの状態を更新します。
   *
   * @private
   */
  fetchUsers() {
    window.jQuery.ajax({
      dataType: 'json',
      url: this.props.usersPath,
      beforeSend: () => {
        this.setState({
          errorMessage: null,
          pending: true,
        });
      },
    })
    .done((data) => {
      this.setState({
        pending: false,
        users: data.users,
      });
    })
    .fail(() => {
      this.setState({
        errorMessage: I18n.t('fetch_failed', { scope: Constants.I18N_SCOPE }),
        pending: false,
        users: [],
      });
    });
  }

  /**
   * 全ユーザーを現在の絞り込み用キーワードの値で絞り込んだ配列を返します。
   * ソートおよびページングについては扱いません。
   *
   * @private
   * @return {Object[]}
   */
  getFilteredUsers() {
    if (this.state.searchInput.length < 1) {
      return this.state.users;
    }

    // キーワードを正規表現に変換する(大文字小文字を区別しない)
    const regexp = new RegExp(
      this.state.searchInput.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1"),
      'i'
    );
    // 氏名・部署・メールアドレスのいずれかがマッチするユーザーだけに絞り込む
    return this.state.users.filter((user) => {
      return(
        regexp.test(user.full_name)
        || regexp.test(user.department)
        || regexp.test(user.email)
      );
    });
  }

  /**
   * 現在表示中のページ番号が変更された際の処理を行います。
   *
   * @private
   * @param {string} value 変更後のページ番号
   */
  handleChangeCurrentPage(value) {
    const newValue = value < 1 ? 1 : value;
    this.setState({ currentPage: newValue });
  }

  /**
   * 絞り込み用キーワードが変更された際の処理を行います。
   *
   * @private
   * @param {string} value 入力された値
   */
  handleChangeSearchInput(value) {
    this.setState({
      currentPage: 1, // 絞り込みによって全体の件数が変化するため現在ページ番号もリセットする
      searchInput: value,
    });
  }

  /**
   * ソート用カラムおよび方向が変更された際の処理を行います。
   *
   * @private
   * @param {string} column ソート用カラム(Constants.COLUMNに含まれるプロパティ値のいずれか)
   * @param {string} direction ソート方向(Constants.DIRECTIONに含まれるプロパティ値のいずれか)
   */
  handleChangeSortColumn(column, direction) {
    this.setState({
      currentPage: 1, // ソート条件の変更によって全体の表示順が変化するため現在ページ番号もリセットする
      sortColumn: column,
      sortDirection: direction,
    });
  }

  /**
   * EventEmitterを初期化します。
   *
   * 各コンポーネント内で発生するイベントとイベントハンドラ関数の割り当てを行います。
   * 生成されたEventEmitterオブジェクトは this.emitter にアサインされます。
   *
   * @private
   */
  initEventEmitter() {
    this.emitter = new EventEmitter;
    this.emitter.on(Constants.EVENT_CHANGE_CURRENT_PAGE, this.handleChangeCurrentPage);
    this.emitter.on(Constants.EVENT_CHANGE_SEARCH_INPUT, this.handleChangeSearchInput);
    this.emitter.on(Constants.EVENT_CHANGE_SORT_COLUMN, this.handleChangeSortColumn);
  }
}
