import React, { useEffect, useState } from "react";

const Underscore = window._; // Underscore.js

/**
 * GroupNameInputFieldコンポーネントのプロパティ型
 */
type GroupNameInputFieldProps = {
  // 入力欄となるINPUTタグのclass属性値
  className?: string;
  // グループ名のデフォルト値
  defaultValue: string;
  // 入力欄が有効かどうか
  disabled?: boolean;
  // 入力欄となるINPUTタグのid属性値
  id: string;
  // 入力欄となるINPUTタグのname属性値
  name?: string;
  // グループ名の利用可否チェックが完了すると呼び出されるコールバック関数
  onAvailabilityChecked: (isAvailable: boolean) => void;
  // グループ名の現在値が変化すると呼び出されるコールバック関数
  onChange: (value: string) => void;
};

/**
 * グループ名が利用可能かどうかをチェックする関数。
 *
 * name に与えられたグループ名が組織内で利用可能かどうかを、以下のリクエストのステータスコードで判定し
 * その結果を callback に与えられた関数に渡して呼び出します。
 *
 *   POST /organization/available_group_names
 *
 */
const checkAvailability = (name: string, callback: (isAvailable: boolean) => void): void => {
  if (name == "") {
    // グループ名が空の場合はチェックを行わずに終了する
    return;
  }

  jQuery
    .ajax({
      url: "/organization/available_group_names",
      method: "POST",
      dataType: "json",
      data: { name: name },
    })
    .done(() => callback(true))
    .fail(() => callback(false));
};

/**
 * グループ名が利用可能かどうかをチェックする関数を、最短でも1秒に1回しか実行されないように
 * スロットリングした関数。
 *
 * この関数定義を GroupNameInputField コンポーネント内に置くと、コンポーネントの描画のたびに
 * 「新しい」スロットリングされた関数が生成されるため、スロットリング用の内部カウンタが描画をまたいで
 * 共有されず、結果としてスロットリングが働かないため注意。
 */
const throttledCheckAvailability = Underscore.throttle(checkAvailability, 1000);

/**
 * グループ名入力フィールド
 *
 * 以下の責務を持ちます。
 *
 * - グループ名の入力欄となるINPUTタグの表示
 * - グループ名が利用可能かどうかのリアルタイムチェック
 * - グループ名の変更時のコールバック関数の呼び出し
 * - グループ名の利用可否チェック結果を伝えるコールバック関数の呼び出し
 *
 */
export const GroupNameInputField: React.FC<GroupNameInputFieldProps> = ({
  className = "form-control input-long inline-block required",
  defaultValue,
  disabled = false,
  id,
  name = "name",
  onAvailabilityChecked,
  onChange,
}) => {
  // 現在の入力値
  const [value, setValue] = useState(defaultValue);

  // 描画完了時かつ defaultValue が変化している場合にのみ現在の入力値の利用可否をチェックする。
  // 利用不可なグループ名を入力した状態でAWSアカウントの登録方式を切り替えた際に、切り替え後も
  // 利用不可の表示を維持するためのもの。
  useEffect(() => {
    checkAvailability(value, onAvailabilityChecked);
  }, [defaultValue]);

  // 入力値が変化した際の処理
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    setValue(value);
    onChange(value);
    throttledCheckAvailability(value, onAvailabilityChecked);
  };

  return (
    <input
      className={className}
      disabled={disabled}
      id={id}
      name={name}
      onChange={handleOnChange}
      type="text"
      value={value}
    />
  );
};
