import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import withBootstrapTooltip from '../withBootstrapTooltip.js';

const I18n = window.I18n; // i18n-js

/**
 * 後処理一覧更新ボタン
 *
 * 後処理一覧を更新するボタンを表示するUIコンポーネントで、以下の責務を持ちます。
 *
 * - 後処理一覧更新ボタンをクリックした場合にEventEmitterに
 *   イベント(EVENT_CLICK_REFRESH_BUTTON)を通知する
 */
class RefreshPostProcessButton extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {object}
   * @property {object} emitter EventEmitterオブジェクト
   * @property {string} postProcessType 成功時の後処理か失敗時の後処理かをあらわす文字列
   *                                    "completed" もしくは "failed"
   * @property {bool} disabled 後処理更新ボタンの状態
   *                  true で更新ボタンが disabled 状態となる
   * @property {function(element: DOMNode)} tooltipRef
   */
  static get propTypes() {
    return({
      emitter: PropTypes.object.isRequired,
      postProcessType: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      tooltipRef: PropTypes.func
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);

    this.i18n_text = {
      refresh_post_process: I18n.t('refresh_post_process', { scope: Constants.I18N_SCOPE })
    };
  }

  /**
   * デフォルトのプロパティを返します。
   *
   * @public
   * @return {Object}
   */
  static get defaultProps() {
    return({
      disabled: false
    });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <div ref={this.props.tooltipRef}>
        <button
          type="button"
          className={`ca-post-process-assignment-panel__refresh-button--${this.props.postProcessType}`}
          disabled={this.props.disabled}
          onClick={this.handleClick}
          data-toggle="tooltip"
          title={this.i18n_text.refresh_post_process}
        >
          <span className={`${this.iconClass()} fa fa-refresh`}></span>
        </button>
      </div>
    );
  }

  /**
   * 後処理更新ボタンをクリックした際の処理を行います。
   *
   * @private
   */
  handleClick() {
    this.props.emitter.emit(
      Constants.EVENT_CLICK_REFRESH_BUTTON
    );
  }

  /**
   * 後処理更新ボタンのアイコンに適用するクラス名を返します。
   *
   * @private
   * @return {string}
   */
  iconClass() {
    if (this.props.disabled) {
      return("ca-post-process-assignment-panel__refresh-icon--disabled");
    } else {
      return(`ca-post-process-assignment-panel__refresh-icon--${this.props.postProcessType}`);
    }
  }
}

export default withBootstrapTooltip(RefreshPostProcessButton);
