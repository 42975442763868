import React from 'react';
import PropTypes from 'prop-types';

import AwsAccountSelector from '../AwsAccountSelector.jsx';

import Constants from './Constants.js';
import RegionSelector from './RegionSelector.jsx';

const I18n = window.I18n; // i18n-js

/**
 * リスト作成リクエストフォームUI
 */
export default class RequestForm extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   */
  static get propTypes() {
    return({
      awsAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
      currentAwsAccountId: PropTypes.string.isRequired,
      currentRegion: PropTypes.string.isRequired,
      disabled: PropTypes.bool.isRequired,
      emitter: PropTypes.object.isRequired,
      isSubmitted: PropTypes.bool.isRequired,
      regions: PropTypes.arrayOf(PropTypes.object).isRequired,
      submitErrorMessage: PropTypes.string.isRequired,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.handleChangeAwsAccountId = this.handleChangeAwsAccountId.bind(this);
    this.handleChangeRegion = this.handleChangeRegion.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.awsAccountBlankText = I18n.t('aws_account_blank_option', { scope: Constants.I18N_SCOPE });
    this.awsAccountLabelText = I18n.t('aws_account', { scope: Constants.I18N_SCOPE });
    this.headingText = I18n.t('heading_for_request_form', { scope: Constants.I18N_SCOPE });
    this.regionBlankText = I18n.t('region_blank_option', { scope: Constants.I18N_SCOPE });
    this.regionLabelText = I18n.t('region', { scope: Constants.I18N_SCOPE });
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <form
        className="ca-workspaces-lists-request-form panel"
        onSubmit={this.handleSubmit}
      >
        <h2 className="ca-workspaces-lists-request-form__heading">
          <i className="fa fa-cog fa-lg marginR5"></i>
          {this.headingText}
        </h2>
        <div className="ca-workspaces-lists-request-form__fields">
          <div className="ca-workspaces-lists-request-form__field">
            <label
              className="
                ca-workspaces-lists-request-form__field-label
                ca-workspaces-lists-group-selector__label--required
              "
              htmlFor="ca-workspaces-lists-request-form-aws-account"
            >
              {this.awsAccountLabelText}
            </label>
            <AwsAccountSelector
              blankText={this.awsAccountBlankText}
              callback={this.handleChangeAwsAccountId}
              current={this.props.currentAwsAccountId}
              disabled={this.props.disabled}
              htmlId="ca-workspaces-lists-request-form-aws-account"
              options={this.props.awsAccounts}
              selectClassName="ca-workspaces-lists-request-form__select"
              wrapperClassName=""
            />
          </div>
          <div className="ca-workspaces-lists-request-form__field">
            <label
              className="
                ca-workspaces-lists-request-form__field-label
                ca-workspaces-lists-group-selector__label--required
              "
              htmlFor="ca-workspaces-lists-request-form-region"
            >
              {this.regionLabelText}
            </label>
            <RegionSelector
              blankText={this.regionBlankText}
              callback={this.handleChangeRegion}
              current={this.props.currentRegion}
              disabled={this.props.disabled}
              htmlId="ca-workspaces-lists-request-form-region"
              options={this.props.regions}
              selectClassName="ca-workspaces-lists-request-form__select"
            />
          </div>
        </div>

        <div className="ca-btn-block no-borders">
          <div className="ca-btn-block__center">
            <button
              className="btn btn-primary btn-lg"
              disabled={!this.isSubmittable()}
              type="submit"
            >
              <i className="fa fa-check-circle fa-lg marginR5"></i>
              {I18n.t('submit', { scope: Constants.I18N_SCOPE })}
            </button>
          </div>
        </div>
      </form>
    );
  }

  /**
   * AWSアカウントの値が変化した場合の処理を行います。
   *
   * @private
   * @param {string} awsAccountId 選択されたAWSアカウントID
   */
  handleChangeAwsAccountId(awsAccountId) {
    this.props.emitter.emit(Constants.EVENT_CHANGE_CURRENT_AWS_ACCOUNT_ID, awsAccountId);
  }

  /**
   * リージョンの値が変化した場合の処理を行います。
   *
   * @private
   * @param {string} region 選択されたリージョン識別子
   */
  handleChangeRegion(region) {
    this.props.emitter.emit(Constants.EVENT_CHANGE_CURRENT_REGION, region);
  }

  /**
   * フォームの送信操作が行われた際の処理を行います。
   *
   * @private
   * @param {SyntheticEvent} event
   */
  handleSubmit(event) {
    event.preventDefault();
    if (this.isSubmittable()) {
      this.props.emitter.emit(Constants.EVENT_SUBMIT_REQUEST_FORM);
    }
  }

  /**
   * フォームが送信可能な状態かどうかを返します。
   *
   * @private
   * @return {boolean}
   */
  isSubmittable() {
    return !this.props.disabled
      && this.props.currentAwsAccountId != ''
      && this.props.currentRegion != '';
  }
}
