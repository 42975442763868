import PropTypes from 'prop-types';
import React from 'react';

import Constants from './Constants.js';

const I18n = window.I18n; // i18n-js

/**
 * ポリシーログ詳細へのリンク。
 */
export default class DetailLink extends React.Component {
  /**
   * プロパティ定義を返します。
   *
   * @public
   * @return {Object}
   * @property {string} url リンク先URL
   */
  static get propTypes() {
    return({
      url: PropTypes.string.isRequired,
    });
  }

  /**
   * コンポーネントを初期化します。
   *
   * @public
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    const options = { scope : `${Constants.I18N_SCOPE}.detail_link` };
    this.detailText = I18n.t('detail', options);
  }

  /**
   * @public
   * @return {ReactElement}
   */
  render() {
    return(
      <a
        className="btn"
        href={this.props.url}
      >
        <span
          aria-hidden="true"
          className="fa fa-bar-chart-o marginR5"
        ></span>
        <small>
          {this.detailText}
        </small>
      </a>
    );
  }
}
