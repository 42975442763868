import React from "react";
import PropTypes from "prop-types";

import Constants from "./Constants.js";
import ErrorMessages from "../ErrorMessages.jsx";

const I18n = window.I18n; // i18n-js

// Slackチャンネル名入力フィールド
//
// 入力欄の値が変更されると、emitterプロパティに指定されたEventEmitterに
// Constants.EVENT_CHANGE_FORM_VALUE イベントを送信します。
//
class SlackChannelNameField extends React.Component {
  /**
   * プロパティのバリデーション定義を返します。
   * React内部から呼び出されます。
   *
   * @return {object}
   * @property {object} emitter - EventEmitter
   * @property {Array<string>} errors - エラーメッセージ
   * @property {string} value - 現在値
   */
  static get propTypes() {
    return {
      emitter: PropTypes.object.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
      value: PropTypes.string.isRequired,
    };
  }

  /**
   * 表示すべきエラーメッセージがあるかどうかを返します。
   *
   * @return {boolean}
   */
  hasError() {
    return this.props.errors.length > 0;
  }

  /**
   * コンポーネントを描画します。
   * React内部から呼び出されます。
   *
   * @return {ReactElement}
   */
  render() {
    const errorClass = this.hasError() ? "error" : "";
    const label = I18n.t("activerecord.attributes.post_process.slack_channel_name");
    const prompt = I18n.t("slack_channel_name_prompt", { scope: Constants.I18N_SCOPE });

    return (
      <div className="form-group">
        <div className="post-process-setting">
          <div className="post-process-setting__label">
            <div className="post-process-setting__label__text">
              <label htmlFor="PostProcess-slackChannelName">{label}</label>
              <span className="required">*</span>
            </div>
          </div>
          <div className="post-process-setting__form">
            <div className="form-inline">
              <div className="form-group ca-slack-channel-name">
                <label className="sr-only" htmlFor="PostProcess-slackChannelName">
                  {label}
                </label>
                <div className="input-group">
                  <div className="input-group-addon post-process-setting__slack-channel-name-prefix">#</div>
                  <input
                    id="PostProcess-slackChannelName"
                    className={`form-control post-process-setting__slack-channel-name-input ${errorClass}`}
                    onChange={(event) => {
                      this.props.emitter.emit(
                        Constants.EVENT_CHANGE_FORM_VALUE,
                        "slackChannelName",
                        event.target.value
                      );
                    }}
                    type="text"
                    value={this.props.value}
                  />
                </div>
                <div className="post-process-setting__prompt">{prompt}</div>
                <ErrorMessages classes="marginT5" messages={this.props.errors} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SlackChannelNameField;
