import React from "react";
import PropTypes from "prop-types";

import Constants from "./Constants.js";
import ErrorMessages from "../ErrorMessages.jsx";
import { SelectFilterNote } from "../SelectFilterNote.tsx";

const I18n = window.I18n; // i18n-js
const I18N_SCOPE = `${Constants.I18N_SCOPE}.sqs_queue_select_field`;
const jQuery = window.jQuery;

/**
 * SQSキューセレクトボックス
 *
 * セレクトボックスの実装にはSelect2をjQueryプラグインの形で利用します。
 * renderメソッド内で表示するSELECTタグに対して、Reactのライフサイクル関数を通じてSelect2の
 * 適用や削除を行います。
 *
 * コンポーネントの内部状態(SQSキュー名)はReactコンポーネント内には保持せず、
 * this.$select2 にjQueryオブジェクトとして格納されるSelect2プラグインを適用したSELECT
 * 要素内に保持します。
 * したがって、現在選択されているSQSキュー名の変更や消去の際にはReactコンポーネントの書き換え
 * ではなく、Select2のAPIを通じて行います。
 *
 * 現在選択されているSQSキュー名の値が変更になった際は emitter に
 * EVENT_CHANGE_FORM_VALUE イベントを送信することで、上位コンポーネントへの反映を行います。
 */
class SqsQueueSelectField extends React.Component {
  static get propTypes() {
    return {
      awsAccountId: PropTypes.string.isRequired,
      emitter: PropTypes.object.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
      preselectedValue: PropTypes.string, // 表示完了時に選択状態とする選択肢の値
      region: PropTypes.string.isRequired,
      sqsQueuesPath: PropTypes.string.isRequired,
    };
  }

  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
    this.label = I18n.t("activerecord.attributes.post_process.sqs_queue");
  }

  componentDidMount() {
    this.initializeSelect2();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.children !== this.props.children) {
      // Reactによって本コンポーネントに内包されるDOMに変更があった場合には、それを
      // Select2オブジェクトにも伝える
      this.$select.trigger("change");
    }

    const awsAccountIdDidChange = this.props.awsAccountId != prevProps.awsAccountId;
    const regionDidChange = this.props.region != prevProps.region;
    if (awsAccountIdDidChange || regionDidChange) {
      // 上位のコンポーネントから渡されるAWSアカウントIDまたはリージョンの値が変更となった場合に
      // 現在選択中の値を消去する
      this.clearSelect2Value();
    }
  }

  componentWillUnmount() {
    this.deleteSelect2();
  }

  /**
   * エラーがあるかどうかを返します。
   *
   * @return {boolean}
   */
  hasError() {
    return this.props.errors.length > 0;
  }

  render() {
    const errorClass = this.hasError() ? "error" : "";
    const calloutMessage = I18n.t("sqs_queue_select_field.queue_restriction_message", { scope: Constants.I18N_SCOPE });

    return (
      <div className="form-group" data-qa="sqs-queue-select-field">
        <div className="post-process-setting">
          <div className="post-process-setting__label paddingT30">
            <div className="post-process-setting__label__text">
              <label htmlFor="PostProcess-sqsQueue">{this.label}</label>
              <span className="required">*</span>
            </div>
          </div>
          <div className={`post-process-setting__form ${errorClass}`}>
            {SelectFilterNote()}
            <select
              id="js-PostProcess-sqsQueueName"
              ref={(el) => (this.select = el)}
              defaultValue={""}
              disabled={!this.isSqsQueuesFetchable()}
            >
              {this.props.children}
            </select>
            <div className="ca-callout ca-callout-width-fit ca-callout--warning marginT10 display-inline-block">
              <small>{calloutMessage}</small>
            </div>
            <ErrorMessages messages={this.props.errors} />
          </div>
        </div>
      </div>
    );
  }

  /**
   * Select2で現在選択している値を消去して未選択の状態にします。
   */
  clearSelect2Value() {
    this.$select.val(null).trigger("change");
  }

  /**
   * Select2プラグインに関するデータをメモリ上から削除します。
   */
  deleteSelect2() {
    this.$select.off("change", this.handleOnChange);
    this.$select.select2("destroy");
  }

  /**
   * Select2プラグインに渡すオプションを返します。
   *
   * @return {object}
   */
  getOptionsForSelect2() {
    // this.props.sqsQueuesPath へのGETリクエストに追加するパラメーターを動的に生成する
    // (paramsについてはSelect2のAjaxサポート仕様を参照)
    const buildDataForAjax = (params) => {
      return {
        select2: true,
        aws_account_id: this.props.awsAccountId,
        region: this.props.region,
        prefix: params.term,
      };
    };

    const ajaxOptions = {
      url: this.props.sqsQueuesPath,
      dataType: "json",
      delay: 1000, // キー入力後1秒を経過するまでは通信を行わない
      data: buildDataForAjax,
      cache: false,
    };

    return {
      ajax: ajaxOptions,
      forceDropdownPosition: "below",
      language: I18n.locale,
      placeholder: I18n.t("placeholder", { scope: I18N_SCOPE }),
      theme: "kanrinmaru-v2",
      width: "400px",
    };
  }

  /**
   * 選択肢の値が変更された際の処理を行います。
   *
   * @param {{label: string, value: string}} option 選択された選択肢
   */
  handleOnChange(event) {
    // 上位のコンポーネントに新しいSQSキューの値を伝える
    this.props.emitter.emit(Constants.EVENT_CHANGE_FORM_VALUE, "sqsQueue", event.target.value);
  }

  /**
   * renderメソッドで表示したSELECT要素にjQueryのSelect2プラグインを適用し、
   * 必要な初期化を行います。
   * 対象のSELECT要素のjQueryオブジェクトが this.$select に保持されます。
   */
  initializeSelect2() {
    // this.select の値はrender関数で表示および設定されたSELECT要素
    this.$select = jQuery(this.select);
    this.$select.select2(this.getOptionsForSelect2());
    this.$select.on("change", this.handleOnChange);

    if (this.props.preselectedValue) {
      // preselectedValue プロパティに値が設定されている場合は、その値をプレフィックス
      // としてサーバーから選択肢を取得し、かつその値を選択状態とする。
      // これは、フォーム送信後にサーバー側でエラー等が発生してフォームが再表示された場合に
      // 直前のフォームで選択されていた値をそのまま選択状態に保つための処理。
      jQuery
        .ajax({
          url: this.props.sqsQueuesPath,
          dataType: "json",
          data: {
            select2: true,
            aws_account_id: this.props.awsAccountId,
            region: this.props.region,
            prefix: this.props.preselectedValue,
          },
        })
        .then((data) => {
          // Ajaxで取得した選択肢のうち既定値とするものを選択状態にする
          // https://select2.org/programmatic-control/add-select-clear-items#preselecting-options-in-an-remotely-sourced-ajax-select2
          // create the option and append to Select2
          const value = this.props.preselectedValue;
          const newOption = new Option(value, value, false, false);
          this.$select.append(newOption).trigger("change");
          // manually trigger the `select2:select` event
          this.$select.trigger({ type: "select2:select", params: { data: data } });
        });
    }
  }

  /**
   * SQSキューをサーバーサイドから取得可能な状態かどうかを返します。
   *
   * @return {boolean}
   */
  isSqsQueuesFetchable() {
    return this.props.awsAccountId != "" && this.props.region != "";
  }
}

export default SqsQueueSelectField;
