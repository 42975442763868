import EventEmitter from 'events';
import React from 'react';
import PropTypes from 'prop-types';

import Constants from './Constants.js';
import PasswordSettings from './PasswordSettings.jsx';

const jQuery = window.jQuery;

/**
 * パスワード設定コンテナコンポーネント。
 *
 * このコンポーネントはサーバーとの通信およびUIイベントのハンドリングのみを行い、
 * ルック＆フィールについては責務を持ちません。
 */
export default class PasswordSettingsContainer extends React.Component {
  /**
   * プロパティの定義を返します。
   *
   * @return {Object}
   * @property {string} updateUrl パスワードの更新時ににリクエストするURL
   */
  static get propTypes() {
    return({
      updateUrl: PropTypes.string.isRequired,
    });
  }

  /**
   * コンストラクタ。
   *
   * @override
   */
  constructor(props) {
    super(props);

    // イベント処理用ハンドラにインスタンスをbind
    this.handleChangeCurrentPassword = this.handleChangeCurrentPassword.bind(this);
    this.handleChangeNewPassword = this.handleChangeNewPassword.bind(this);
    this.handleChangeNewPasswordConfirmation = this.handleChangeNewPasswordConfirmation.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleUpdatePassword = this.handleUpdatePassword.bind(this);

    this.emitter = this.initEventEmitter();
    this.state = this.getInitialState();
  }

  /**
   * ステートの初期値を返します。
   *
   * @return {Object}
   * @property {boolean} completed パスワード更新が完了したかどうか
   * @property {string} currentPassword 現在のパスワードの入力欄の値
   * @property {boolean} dialogVisible モーダルダイアログが表示中かどうか
   * @property {string[]} errorMessages エラーメッセージの配列
   * @property {boolean} isLoading 通信中かどうか
   * @property {string} newPassword 新しいパスワードの入力欄の値
   * @property {string} newPasswordConfirmation 確認用パスワードの入力欄の値
   */
  getInitialState() {
    return(
      {
        completed: false,
        currentPassword: '',
        dialogVisible: false,
        errorMessages: [],
        isLoading: false,
        newPassword: '',
        newPasswordConfirmation: '',
      }
    );
  }

  /**
   * EventEmitterを初期化して返します。
   * 各コンポーネント内で発生するイベントとイベントハンドラ関数の割り当てを行います。
   *
   * @private
   * @return {EventEmitter}
   */
  initEventEmitter() {
    const emitter = new EventEmitter;

    emitter.on(Constants.EVENT_CHANGE_CURRENT_PASSWORD, this.handleChangeCurrentPassword);
    emitter.on(Constants.EVENT_CHANGE_NEW_PASSWORD, this.handleChangeNewPassword);
    emitter.on(Constants.EVENT_CHANGE_NEW_PASSWORD_CONFIRMATION, this.handleChangeNewPasswordConfirmation);
    emitter.on(Constants.EVENT_CLOSE_DIALOG, this.handleCloseDialog);
    emitter.on(Constants.EVENT_OPEN_DIALOG, this.handleOpenDialog);
    emitter.on(Constants.EVENT_UPDATE_PASSWORD, this.handleUpdatePassword);

    return emitter;
  }

  /**
   * @override
   * @return {ReactElement}
   */
  render() {
    return(
      <React.StrictMode>
        <PasswordSettings emitter={this.emitter} {...this.state} />
      </React.StrictMode>
    );
  }

  /**
   * 現在のパスワード入力欄の値を変更するイベントが発生した際の処理を行います。
   *
   * @private
   * @param {string} newValue 新しい値
   */
  handleChangeCurrentPassword(newValue) {
    this.setState({
      currentPassword: newValue,
    });
  }

  /**
   * 新しいパスワード入力欄の値を変更するイベントが発生した際の処理を行います。
   *
   * @private
   * @param {string} newValue 新しい値
   */
  handleChangeNewPassword(newValue) {
    this.setState({
      newPassword: newValue,
    });
  }

  /**
   * 確認用パスワード入力欄の値を変更するイベントが発生した際の処理を行います。
   *
   * @private
   * @param {string} newValue 新しい値
   */
  handleChangeNewPasswordConfirmation(newValue) {
    this.setState({
      newPasswordConfirmation: newValue,
    });
  }

  /**
   * モーダルダイアログを閉じるイベントが発生した際の処理を行います。
   *
   * @private
   */
  handleCloseDialog() {
    this.setState({
      completed: false,
      currentPassword: '',
      dialogVisible: false,
      newPassword: '',
      newPasswordConfirmation: '',
    });
  }

  /**
   * モーダルダイアログを開くイベントが発生した際の処理を行います。
   *
   * @private
   */
  handleOpenDialog() {
    this.setState({
      dialogVisible: true,
    });
  }

  /**
   * Ajaxリクエストを送り、その結果をStateに格納する。
   *
   * @private
   * @param {Object} settings jQuery.ajaxに渡すオプション
   * @param {Object} messages 結果として表示するメッセージを保持するコンテナ
   * @param {string} messages.success リクエスト成功時に表示するメッセージ
   * @param {string} messages.fail リクエスト失敗時に表示するメッセージ
   */
  handleUpdatePassword() {
    // TODO: Add blank check

    const options = {
      credentials: 'same-origin', // Cookieを送信する
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': jQuery('meta[name="csrf-token"]').attr('content') // RailsのCSRFチェックを通すため
      },
      body: JSON.stringify({
        user: {
          current_password: this.state.currentPassword,
          password: this.state.newPassword,
          password_confirmation: this.state.newPasswordConfirmation,
        },
      }),
    };
    this.setState({
      isLoading: true
    }, () => {
      fetch(this.props.updateUrl, options)
      .then(response => {
        if (response.status === 200) {
          this.setState({
            completed: true,
            currentPassword: '',
            errorMessages: [],
            isLoading: false,
            newPassword: '',
            newPasswordConfirmation: '',
          });
          return response;
        } else if (response.status === 422) {
          return response.json().then((json) => {
            this.setState({
              completed: false,
              errorMessages: json.errors,
              isLoading: false,
            });
          });
        } else {
          throw new Error(`Internal Server Error: status=${response.status}`);
        }
      });
    });
  }
}
