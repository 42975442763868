import React, { ReactElement } from "react";

type Props = {
  errors: string[];
};

/**
 * 入力フィールド下のエラーメッセージ表示
 */
const ErrorArea: React.FC<Props> = (props) => {
  if (props.errors.length < 1) {
    return <div className="error-area"></div>;
  }

  const errors = [] as ReactElement[];
  props.errors.forEach((error, index) => {
    errors.push(
      <li className="error" key={index}>
        <label className="error">{error}</label>
      </li>
    );
  });

  return (
    <div className="error-area">
      <ul>{errors}</ul>
    </div>
  );
};

export { ErrorArea };
